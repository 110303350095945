import { Vehicle, RepairTimeProvider } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { CalculatedCostEstimationPart, OfferDetails } from "../../../data/model"

export type OfferState = {
    costEstimationDetailsLoading: boolean
    note: string
    offerDetails: OfferDetails
    printVehicleImage: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    vehicle?: ExtendedVehicle
    workTask?: WorkTaskInfo
}

export type ExtendedVehicle = Vehicle & {
    registrationNoLabel?: string
}

export type ExtendedCalculatedCostEstimationPart = CalculatedCostEstimationPart & {
    isSelected?: boolean
    repairTimeProviderAvailabilities?: Array<RepairTimeProvider>
    itemRole?: number
}

export type SelectedWorkInfo = {
    isIncluded: boolean
}

export enum PartListItemActions {
    Details = 1,
    RepairTimes = 2,
}

export enum VisibilityStatus {
    allVisible = 1,
    noneVisible = 2,
    someVisible = 3,
}

export type ExpandedRepairTimes = {
    providerId: RepairTimeProvider
    expandedWorks: Array<number | string>
}

export enum EditWorkMode {
    editCustomWork,
    replaceWorkWithCustomWork,
    editRepairTime,
}
