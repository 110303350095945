import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"

function merge(getStylesFn: string, baseStyles: NestedCSSProperties): NestedCSSProperties {
    const { name } = getStyleTheme()
    let getStyles: any
    try {
        // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
        const pack = require(`./themes/${name}`)
        getStyles = pack ? pack[getStylesFn] : undefined
    } catch {}
    return getStyles ? getStyles(baseStyles) : baseStyles
}

export function getComponentStyles(styleOverwrite?: NestedCSSProperties): NestedCSSProperties {
    const { colors } = getStyleTheme()

    const styles: NestedCSSProperties = {
        position: "relative",
        $nest: {
            "&__header": {
                display: "flex",
                alignItems: "center",
            },
            "&__headline": {
                marginRight: "1rem",
            },
            "&__input__wrapper": {
                padding: ".571em 0 .571em .75em",
                width: "100%",
                border: "unset",
            },
            "&__search": {
                zIndex: 5,
                width: "100%",
                border: "none",
                borderRadius: "0 5px 5px 0",
                $nest: {
                    "&.has-result": {
                        $nest: {
                            ".btn:last-child": {
                                borderRadius: "0 4px 0 0",
                            },
                        },
                    },
                    ".input__inner": {
                        padding: "unset",
                        height: "20px",
                        $nest: {
                            ".input__field": {
                                fontSize: "1rem",
                            },
                        },
                    },
                    "::placeholder": {
                        opacity: 0.54,
                    },
                    ".btn:last-child": {
                        background: colors.highlight,
                        display: "unset !important",
                        padding: "0.6em 1.16em",
                        borderRadius: "0 4px 4px 0",
                        $nest: {
                            svg: {
                                opacity: 1,
                                fill: colors.light,
                                width: "1.2em",
                                height: "1.2em",
                            },
                        },
                    },
                    "&-loader": {
                        position: "absolute",
                        zIndex: 10,
                        right: "6rem",
                        top: "0.3rem",
                        width: "24px",
                        height: "24px",
                    },
                },
            },
            "&__search-container": {
                position: "relative",
                width: "100%",
                margin: "1rem 0 1rem",
                borderRadius: "5px",
                background: colors.light,
            },
            "&__search-resultlist": {
                minWidth: "100%",
                maxWidth: "100vw",
                position: "absolute",
                top: "38px",
                zIndex: 4,
                background: "#ffffff",
                border: `1px solid ${colors.highlight}`,
                borderRadius: "0 0 5px 5px",
                boxShadow: "1px 4px 8px rgba(0,0,0,0.3)",
                overflowY: "scroll",
                maxHeight: "30vh",
                $nest: {
                    "&::-webkit-scrollbar": {
                        backgroundColor: colors.light,
                        width: "10px",
                        borderBottomRightRadius: "5px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: colors.light,
                        borderBottomRightRadius: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        borderRadius: "16px",
                        border: `2px solid ${colors.light}`,
                        height: "30px",
                    },
                },
            },
            "&__field-container": {
                zIndex: 10,
                display: "flex",
                border: `1px solid ${colors.highlight}`,
                borderRadius: "5px",
                $nest: {
                    "&.has-result": {
                        boxShadow: "1px 4px 8px rgba(0,0,0,0.3)",
                        borderRadius: "5px 5px 0 0",
                    },
                },
            },
            "&__querytype": {
                zIndex: 10,
                $nest: {
                    "&.has-result": {
                        $nest: {
                            ">.btn": {
                                borderRadius: "5px 0 0 0",
                            },
                        },
                    },
                    ">.btn": {
                        height: "100%",
                        borderRadius: "5px 0 0 5px",
                        minWidth: "4em",
                    },
                    ">.popover": {
                        minWidth: "9em",
                    },
                    "&-item": {
                        display: "flex",
                        $nest: {
                            ".radio": {
                                marginRight: "0.25em",
                            },
                        },
                    },
                    "&-loader": {
                        zIndex: 10,
                        position: "absolute",
                        left: "1em",
                        top: "0.5rem",
                        width: "24px",
                        height: "24px",
                    },
                },
            },
            "&__history": {
                alignItems: "center",

                minHeight: "1.6rem",
                $nest: {
                    ".text": {
                        lineHeight: 1.3,
                    },
                },
            },
            "&__history-title": {
                marginRight: "0.5em",
            },
        },
    }

    return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
