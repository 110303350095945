import { IBundle } from "@tm/morpheus"
import Widget from "./components/widget"
import StartpageButton from "./components/startpage-button"
import WidgetFrame from "./components/widget-frame"
import Frame from "./components/frame"
import { initOffersBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Widget, StartpageButton, WidgetFrame, Frame],
    init: initOffersBundle,
}

export default bundle
