import { AsyncAction } from "@tm/morpheus"
import { Vehicle, RequestArticleListPayload, channel, TreeNode, SearchTreeProductGroup, GetSearchTreeRequest } from "@tm/models"
import { BundleActionType, BundleActions } from "../../business"
import { getBundleParams } from "../../utils"
import { TreeConfig, SearchTree } from "../widget/business"
import { Repositories } from "../../data"

type ComponentActionType =
    | BundleActionType
    | { type: "INITIALIZE"; payload: TreeConfig }
    | { type: "CHANGE_BREADCRUMBS"; payload: TreeNode[] }
    | { type: "SEARCH_TREE_LOADING" }
    | {
          type: "SEARCH_TREE_LOADED"
          payload: { nodes: TreeNode[]; topProductGroups: Array<SearchTreeProductGroup>; parentNode?: TreeNode }
      }

export type ComponentState = {
    tree: SearchTree
}

const DEFAULT_STATE: ComponentState = {
    tree: {
        mode: "fast-click-2.0",
        showJumpOffs: false,
        showTip: false,
        showSecondLevel: false,
        treeId: 0,
        groups: [],
        loading: false,
        breadcrumbs: [],
    },
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    switch (action.type) {
        case "INITIALIZE": {
            return {
                ...state,
                tree: {
                    ...DEFAULT_STATE.tree,
                    ...action.payload,
                    treeId: getBundleParams().vehicleTreeId || 0,
                },
            }
        }
        case "CHANGE_BREADCRUMBS": {
            const { tree } = state

            let breadcrumbs = action.payload
            let lastBreadcrumb = breadcrumbs.last()

            if (lastBreadcrumb) {
                // If the last breadcrumb is no group and has no child nodes
                // it should not appear as breadcrumb
                if (!lastBreadcrumb.isGroup && !lastBreadcrumb.hasChildNodes) {
                    breadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
                } else {
                    // Only keep last breadcrumb if it really is the last one from the tree
                    lastBreadcrumb = undefined
                }
            }

            tree.breadcrumbs = breadcrumbs
            tree.selectedNode = lastBreadcrumb

            return {
                ...state,
                tree: { ...tree },
            }
        }
        case "SEARCH_TREE_LOADING": {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    loading: true,
                },
            }
        }
        case "SEARCH_TREE_LOADED": {
            const { nodes, topProductGroups, parentNode } = action.payload

            const { tree } = state
            tree.loading = false

            if (parentNode) {
                // This also updates the groups array, since its the same object reference
                if (!parentNode.childNodes) {
                    parentNode.childNodes = nodes
                }
                if (!parentNode.topProductGroups) {
                    parentNode.topProductGroups = topProductGroups
                }
            }
            // Only do this for the first level (no parentNode)
            else {
                nodes.forEach((x) => (x.isGroup = true)) // Nodes from the first level are groups
                tree.groups = nodes
            }

            return {
                ...state,
                tree,
            }
        }
        default:
            break
    }
    return state
}

function initialize(tree: TreeConfig): ComponentActionType {
    return { type: "INITIALIZE", payload: tree }
}

function loadSearchTreeNodes(
    vehicle: Vehicle | undefined,
    parentNode?: TreeNode,
    onlyTopProductGroups?: boolean,
    forceLoad = false
): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { tree } = getState()

        // Don't load tree nodes if the tree is not initialized or already loaded
        if ((parentNode ? parentNode.childNodes && parentNode.topProductGroups : tree.groups.length) && !forceLoad) {
            return
        }

        dispatch(loadVehicleNodes(vehicle, tree.treeId, parentNode, tree.showSecondLevel, onlyTopProductGroups))
    }
}

function loadVehicleNodes(
    vehicle: Vehicle | undefined,
    treeId: number,
    parentNode?: TreeNode,
    loadSecondLevel?: boolean,
    onlyTopProductGroups?: boolean
): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch) => {
        if (!vehicle) {
            return
        }

        const request: GetSearchTreeRequest = {
            modelId: vehicle.tecDocTypeId,
            vehicleType: vehicle.vehicleType,
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: {
                        nodes: response.nodes,
                        topProductGroups: response.topProductGroups,
                        parentNode,
                    },
                }),
            () =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: {
                        nodes: [],
                        topProductGroups: [],
                    },
                })
        )

        dispatch({ type: "SEARCH_TREE_LOADING" })
    }
}

function changeBreadcrumbs(
    vehicle: Vehicle | undefined,
    breadcrumbs: TreeNode[],
    startSearch?: boolean
): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { tree } = getState()

        dispatch({ type: "CHANGE_BREADCRUMBS", payload: breadcrumbs })

        const activeParentNode = breadcrumbs.last()

        // If no breadcrumbs is selected / all are removed
        // just exit after updating the breadcrumbs
        if (!activeParentNode) {
            return
        }

        const isLastNode = !activeParentNode.hasChildNodes

        // Check if the child nodes (childNodes array is undefined)
        // or the top product groups (topProductGroups array is undefined) have to be loaded
        if (!activeParentNode.childNodes || !activeParentNode.topProductGroups) {
            dispatch(loadSearchTreeNodes(vehicle, activeParentNode, !!activeParentNode.childNodes || isLastNode))
        }

        // If is the last node in tree or the "startSearch" parameter is provided
        // request to open the article list
        if (isLastNode || startSearch) {
            const request: RequestArticleListPayload = {
                node: { treeId: tree.treeId, nodeId: activeParentNode.id, history: [] },
            }

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        }
    }
}

function applyProductGroups(productGroups: SearchTreeProductGroup[]): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { tree } = getState()

        if (tree.selectedNode) {
            // Clear the selectedNode by changing the breadcrumbs
            dispatch({ type: "CHANGE_BREADCRUMBS", payload: tree.breadcrumbs })
        }

        const request: RequestArticleListPayload = {
            productGroups: {
                ids: productGroups.map((x) => x.id).orderBy((x) => x),
                treeId: tree.treeId,
                nodeId: tree.breadcrumbs.last()?.id,
            },
        }

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    initialize,
    changeBreadcrumbs,
    applyProductGroups,
    loadSearchTreeNodes,
}
