import { Table, TableColumnData, CellContentPosition, TableRowData, Collapse } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { memo, useCallback, useLayoutEffect, useMemo } from "react"
import { SubArticleContainer } from "../SubArticleContainer"
import { TraderActionsColumn } from "./TraderActionsColumn"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export const TraderSelection = memo(() => {
    const { translateText } = useLocalization()
    const traderErpInfos = useDefaultArticleItemState((x) => x.traderErpInfos)
    const traderSelection = useDefaultArticleItemState((x) => x.traderSelection)
    const toggleTraderSelection = useDefaultArticleItemState((x) => x.toggleTraderSelection)

    const columns = useMemo<TableColumnData[]>(() => {
        return [
            { header: translateText(13467) } /* Bezeichnung */,
            { header: translateText(87) } /* Artikelnummer  */,
            { header: "%" },
            { header: translateText(13389) } /* Preis per  */,
            { header: translateText(13388) } /* VPE  */,
            { alignContent: CellContentPosition.right },
        ]
    }, [])

    useLayoutEffect(() => {
        if (traderSelection) {
            traderErpInfos?.loadTraderErpInfos()
        }
    }, [traderSelection])

    const rows = useMemo<TableRowData[]>(() => {
        return (traderErpInfos?.traderErpInfos ?? []).map((item) => {
            const { erpInformation } = item
            const rebate = erpInformation?.prices?.find((x) => x.rebate)?.rebate

            return {
                cells: [
                    { displayValue: item.erpSystem.description },
                    { displayValue: item.traderArticleNumber },
                    { displayValue: rebate },
                    { displayValue: erpInformation?.quantity?.quantityPackingUnit },
                    { displayValue: erpInformation?.quantity?.packagingUnit },
                    { displayValue: <TraderActionsColumn {...item} /> },
                ],
            } as TableRowData
        })
    }, [traderErpInfos?.traderErpInfos])

    const handleTransitionEnd = useCallback((node: HTMLElement) => {
        // 400ms is a magic try'n'error number. it was not possible to use the "correct" way with a transistionend event handler. no clue why.
        setTimeout(() => {
            const parent = getScrollableParent(node)
            const bottomEdge = node.offsetTop + node.offsetHeight
            const parentBottom = parent.clientHeight + parent.scrollTop

            if (parentBottom < bottomEdge) {
                parent.scrollBy({ top: bottomEdge - parentBottom })
            }
        }, 400)
    }, [])

    return (
        <Collapse in={traderSelection} mountOnEnter unmountOnExit addEndListener={handleTransitionEnd}>
            <SubArticleContainer title={translateText(13516)} onClose={toggleTraderSelection} isLoading={traderErpInfos?.isLoading}>
                <Table columns={columns} rows={rows} headerBackground="transparent" headerStyle="default" />
            </SubArticleContainer>
        </Collapse>
    )
})

function isScrollable(ele: HTMLElement) {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight

    const overflowYStyle = window.getComputedStyle(ele).overflowY
    const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1

    return hasScrollableContent && !isOverflowHidden
}

function getScrollableParent(ele: HTMLElement | null): HTMLElement {
    return !ele || ele === document.body ? document.body : isScrollable(ele) ? ele : getScrollableParent(ele.parentNode as HTMLElement)
}
