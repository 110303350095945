import { PartInfo, PartQuantity, ArticleInfoType, FittingPosition, Version, VatRate, LinkedItemsRole, OrderValidationMessage } from "@tm/models"
import { ExtendedCalculatedCostEstimationPart } from "../../components/cost-estimation/business"

export type CalculatedCostEstimationPart = {
    id: string
    version: Version
    parentId?: string
    parentVersion?: Version
    vehicleId: string
    articleInfo: PartInfo
    articleInfoType: ArticleInfoType
    quotationPrice: number
    cummulatedQuotationPrice: number
    garagePrice: number
    garagePriceWithSurcharge: number
    rebate: number
    surcharge?: number
    currencyCode: string
    currencySymbol: string
    quantity: PartQuantity
    memo: string
    foundBySearchTerm: string
    isIncluded: boolean
    isOrdered: boolean
    orderInformation: OrderInformation
    fittingSide: FittingPosition
    creationDate: Date
    isReplacementPart: boolean
    itemRole?: LinkedItemsRole
    // TODO: check refence and usage, why is an extended required in this layer?
    linkedItems?: ExtendedCalculatedCostEstimationPart[]
    vatRate?: VatRate
    isLinkedItem?: boolean // used for Pawn articles, the basket Service doesn't provide it.
}

export type OrderInformation = {
    orderRecipient: OrderRecipient
    orderNumber: string
    orderDate: Date
}

type OrderRecipient = {
    warehouseId: string
    warehouseName: string
    distributorId: number
    distributorName: string
}

export type ShowCostEstimationTotalNumbersResponse = {
    partsIncludedCount: number
    partsExcludedCount: number
    partsTotalCount: number
    partPositionsIncludedCount: number
    partPositionsExcludedCount: number
    partPositionsTotalCount: number
    worksIncludedCount: number
    worksExcludedCount: number
    worksTotalCount: number
}

export type SendCostEstimationRequest = {
    workTaskId: string
    distributorId: number
    telesalesCustomerNo?: string
}

export type SendCostEstimationResponse = {
    isBasketEmpty: boolean
    hasSucceeded: boolean
    message: OrderValidationMessage
}

export enum ESendCostEstimationMode {
    basket = 1,
    costEstitmation = 2,
}
