import { VatRate } from "@tm/models"
import { Paper } from "@tm/components"
import { saveCustomArticle } from "../../../../data/repositories/custom-items/articles"
import PartFields from "./part-fields"
import { BasketPart } from "../../../../models"

type Props = {
    part: BasketPart
    currencyCode: string
    hidePurchasePrice: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    onClosePartEditor?(id: string): void
    onEditPart?(
        part: BasketPart,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        vatRate?: VatRate,
        isCustomPart?: boolean
    ): void
}

export default function PartEditorComponent(props: Props) {
    const { part, hidePurchasePrice, showManufacturer, showSupplierArticleNumbers, showWholesalerArticleNumbers, currencyCode } = props

    function handleEditPrice(
        saveAsCustomArticle: boolean,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        category?: number,
        vatRate?: VatRate,
        isCustomPart?: boolean
    ) {
        props.onEditPart?.(part, rebate, surcharge, regularPrice, customPartNumber, customPartDescription, vatRate, isCustomPart)
        props.onClosePartEditor?.(part.partItem.id)
        if (saveAsCustomArticle && customPartDescription) {
            saveCustomArticle({ articleNumber: customPartNumber, description: customPartDescription, garagePrice: regularPrice, category, vatRate })
        }
    }

    return (
        <Paper>
            <PartFields
                defaultCurrencyCode={currencyCode}
                part={part}
                hidePurchasePrice={hidePurchasePrice}
                isEditMode
                showManufacturer={showManufacturer}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                onClose={() => props.onClosePartEditor?.(part.partItem.id)}
                onEditPart={handleEditPrice}
            />
        </Paper>
    )
}
