import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import ReplaceButtonMicro from "./micro/replace-button"
import { initFastServiceBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    components: [Main],
    micros: [ReplaceButtonMicro],
    init: initFastServiceBundle,
}

export default bundle
