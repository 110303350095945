import { StateCreator } from "zustand"
import _ from "lodash"
import { FastServiceStore } from "../.."
import { navigationData } from "../../../models"
import { StepNavigationSlice } from "./model"

function getInitialState() {
    return {
        stepNavigationState: {
            steps: _.cloneDeep(navigationData),
        },
    }
}

export const createStepNavigationSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], StepNavigationSlice> = (set) => ({
    ...getInitialState(),
    stepNavigationActions: {
        updateSteps: (activeSteps) => set((state) => updateStepsHandler(state, activeSteps), false, `Step active step`),
        completeStep: (stepName) => set((state) => completeStepHandler(state, stepName), false, `Step navigation complete: ${stepName}`),
        updateWorksSteps: (steps) => set((state) => updateWorksStepsHandler(state, steps), false, `Update works: ${steps}`),
        updateNextStep: (currentStepName) => set((state) => updateNextStep(state, currentStepName), false, `Update next step`),
        reset: () => set(getInitialState(), false, "Reset Step Navigation Slice"),
    },
})

function updateNextStep(slice: StepNavigationSlice, currentStepName: string) {
    const state = slice.stepNavigationState
    const activeSteps = state.steps.findIndex((x) => x.stepName === currentStepName) + 1
    return updateStepsHandler(slice, activeSteps)
}

function updateStepsHandler(slice: StepNavigationSlice, activeSteps: number): Partial<StepNavigationSlice> {
    const state = slice.stepNavigationState

    return {
        stepNavigationState: {
            steps: state.steps.map((x, i) => (i <= activeSteps ? { ...x, available: true } : { ...x })),
        },
    }
}

function completeStepHandler(slice: StepNavigationSlice, stepName: string): Partial<StepNavigationSlice> {
    const state = slice.stepNavigationState

    return {
        stepNavigationState: {
            steps: state.steps.map((x) => (x.stepName === stepName ? { ...x, completed: true } : { ...x })),
        },
    }
}

function updateWorksStepsHandler(slice: StepNavigationSlice, steps: string[]): Partial<StepNavigationSlice> {
    const state = slice.stepNavigationState

    return {
        stepNavigationState: {
            steps: state.steps.filter((step) => (step.specialStep ? steps?.includes(step.stepName) : true)),
        },
    }
}
