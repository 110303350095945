import { useEffect } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Headline, Icon, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { stepNavigationActions } from "../../data"
import { VehicleAndCustomer } from "../_shared"
import NextStep from "../_shared/nextStep"
import { getComponentStyles } from "../_shared/styles"

export default function Technician() {
    const { translateText } = useLocalization()
    const classNames = getComponentStyles()
    const currentStepName = "technician"

    const handleClick = () => {
        stepNavigationActions.updateNextStep(currentStepName)
    }

    useEffect(() => {
        stepNavigationActions.completeStep(currentStepName)
        stepNavigationActions.updateNextStep(currentStepName)
    }, [])

    return (
        <Scrollbar className={classNames.flexColumn}>
            <div className={classes(style.wrapper, classNames.wrapper)}>
                <VehicleAndCustomer headerText={translateText(689)} showServiceText />
                <div className={classes(classNames.flexColumn, classNames.flexCenter, classNames.flexOne)}>
                    <Icon className={classNames.bigIcon} name={currentStepName} path="voucher-kva-international" />
                    <div className={classNames.flexOne}>
                        <Headline className={classes(classNames.textCenter, classNames.marginBottomL)} size="l">
                            {" "}
                            {translateText(12646)}
                        </Headline>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12647)}</div>
                    </div>
                </div>
            </div>
            <NextStep onClick={handleClick} currentStepName={currentStepName} buttonTextId={3113} icon="arrows_down" active />
        </Scrollbar>
    )
}

const style = useStyle({
    wrapper: {
        padding: "0 1em",
    },
    ready: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    service: {
        marginTop: "0.5em",
    },
})(Technician)
