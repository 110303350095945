import { percent } from "csx"
import { PropsWithChildren, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useStyle } from "@tm/context-distribution"
import { bem, RouteComponentProps, withRouter } from "@tm/utils"
import List from "../list/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Summary from "../summary/component"

type Props = RouteComponentProps<any> & {
    repairTimesRoute: string
    detailsModalRoute?: string
    route: string
    vehicleSelectionRoute: string
}

function WrappedModule({ children }: PropsWithChildren<{}>) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "MODULE_OPENED",
            payload: {
                icon: "tire",
                title: "{{240}}",
            },
            broadcast: true,
        })
    }, [])

    return (
        <div className={style.content}>
            {children}
        </div>
    )
}

function Main({ repairTimesRoute, route, vehicleSelectionRoute, match, detailsModalRoute }: Props) {
    return (
        <div className={bem(style.wrapper, match.params.view)}>
            <Navigation route={route} />
            <ModuleManager />
            {
                match.params.view == "list" &&
                <WrappedModule>
                    <List detailsModalRoute={detailsModalRoute} repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            }
            {
                match.params.view == "summary" &&
                <WrappedModule>
                    <Summary vehicleSelectionRoute={vehicleSelectionRoute} />
                </WrappedModule>
            }
        </div >
    )
}

export default withRouter(Main)

const style = useStyle({
    wrapper: {
        flexBasis: percent(100),
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&--details": {
                flexBasis: "auto"
            }
        }
    },
    content: {
        flex: 1,
        display: "flex",
    }
})(Main)
