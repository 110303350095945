import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Box, CellContentPosition, Table, TableProps, TableRowData, Icon, Skeleton } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useVatRateText } from "@tm/utils"
import { AddCustomPartListRequest, CustomArticle } from "@tm/models"
import { useArticleNotes } from "../../../../../hooks/useArticleNotes"
import { getBundleParams } from "../../../../../utils"
import { useEditCustomArticle } from "../../../../../data/hooks/custom-basket-items/useCustomArticles"
import { CustomArticlesActions } from "./CustomArticlesActions"
import { RowToEdit } from "./RowToEdit"
import { VatRateSelectionItem } from "../../../../../data/model"

type Props = {
    articles: Array<CustomArticle>
    currencySymbol?: string
    customPartVatRates?: Array<VatRateSelectionItem>
    loading: boolean
    quantities: Map<string, number>
    selectedArticleIds: Array<string>
    workTask: WorkTaskInfo
    onAddCustomParts(request: AddCustomPartListRequest): Promise<unknown>
    onLoadMoreArticles(): void
    onQuantityChange(article: CustomArticle, quantity: number): void
    onToggleArticle(article: CustomArticle): void
}

const CATEGORY_OTHER = 3060

export const CustomArticlesList = memo(
    ({
        workTask,
        articles,
        currencySymbol,
        quantities,
        loading,
        customPartVatRates,
        selectedArticleIds,
        onAddCustomParts,
        onLoadMoreArticles,
        onQuantityChange,
        onToggleArticle,
    }: Props) => {
        const { enableArticleNotes } = getBundleParams()

        const { translateText, currency, number } = useLocalization()
        const { vatRateTypeDisplayShortText } = useVatRateText(translateText, number)
        const getArticleIds = useCallback(() => articles.map((x) => x.id).filter((x) => x), [articles])
        const { articleNotes, saveNote } = useArticleNotes(enableArticleNotes ? getArticleIds() : [])

        const [editedArticle, setEditedArticle] = useState<CustomArticle & { articleNote?: string }>()
        const { mutateAsync: saveCustomArticle } = useEditCustomArticle()

        useEffect(() => {
            if (editedArticle && customPartVatRates) {
                const defaultVatRate = customPartVatRates[0]

                if (!editedArticle.vatRate) {
                    setEditedArticle((prev) =>
                        prev
                            ? {
                                  ...prev,
                                  vatRate: {
                                      vatRate: defaultVatRate.vatValue,
                                      vatType: defaultVatRate.vatType,
                                  },
                              }
                            : prev
                    )
                }
            }
        }, [editedArticle, customPartVatRates])

        const findNoteByArticleId = useCallback((articleId?: string) => articleNotes.find((note) => note.noteId === articleId), [articleNotes])

        const handleEditClick = useCallback(
            (article: CustomArticle | undefined) => {
                setEditedArticle(article)

                if (article && selectedArticleIds.includes(article.id)) {
                    onToggleArticle(article)
                }
            },
            [selectedArticleIds, onToggleArticle]
        )

        const handleCloseEditor = useCallback(() => {
            setEditedArticle(undefined)
        }, [])

        const handleSaveArticleInformation = useCallback(async (): Promise<void> => {
            if (editedArticle) {
                await saveNote(editedArticle.id, editedArticle.articleNote)
                await saveCustomArticle(editedArticle)
            }
        }, [editedArticle, saveNote, saveCustomArticle])

        const renderArticleNumber = useCallback((article: CustomArticle) => {
            return article.articleNumber
        }, [])

        const renderDescription = useCallback((article: CustomArticle) => {
            return article.description
        }, [])

        const renderDefaultForCategory = useCallback(
            (article: CustomArticle) => {
                return article.isDefaultForCategory && article.category && article.category !== CATEGORY_OTHER ? (
                    <div title={translateText(12702)}>
                        <Icon name="filled-star" />
                    </div>
                ) : (
                    <Box width="50" />
                )
            },
            [translateText]
        )

        const renderCategory = useCallback(
            (article: CustomArticle) => {
                return translateText(article?.category || CATEGORY_OTHER)
            },
            [translateText]
        )

        const renderPrice = useCallback(
            (article: CustomArticle) => {
                return !!article.garagePrice && currency(article.garagePrice, currencySymbol || "")
            },
            [currency, currencySymbol]
        )

        const renderVat = useCallback(
            (article: CustomArticle) => {
                // TODO: Remove * 100 when the article service delivers the vatRate in the nuw format
                return !!article.vatRate?.vatRate && vatRateTypeDisplayShortText({ ...article.vatRate, vatRate: article.vatRate.vatRate * 100 })
            },
            [vatRateTypeDisplayShortText]
        )

        const renderActions = useCallback(
            (article: CustomArticle) => {
                return (
                    <CustomArticlesActions
                        workTask={workTask}
                        article={article}
                        articleNote={findNoteByArticleId(article.id)?.text}
                        onEditClick={handleEditClick}
                        quantity={quantities.get(article.id) || 1}
                        onQuantityChange={onQuantityChange}
                        isSelected={selectedArticleIds.includes(article.id)}
                        onToggleSelection={onToggleArticle}
                        onAddCustomParts={onAddCustomParts}
                    />
                )
            },
            [workTask, findNoteByArticleId, handleEditClick, quantities, onQuantityChange, selectedArticleIds, onToggleArticle, onAddCustomParts]
        )

        const CustomRow = useMemo(
            () => (
                <RowToEdit
                    activeArticle={editedArticle}
                    articleNote={findNoteByArticleId(editedArticle?.id)}
                    saveArticleInformation={handleSaveArticleInformation}
                    onArticleChange={setEditedArticle}
                    customPartVatRates={customPartVatRates}
                    closeEditor={handleCloseEditor}
                />
            ),
            [editedArticle, findNoteByArticleId, handleSaveArticleInformation, customPartVatRates, handleCloseEditor]
        )

        const tableData: TableProps = useMemo(
            () => ({
                columns: [
                    { header: translateText(87) },
                    { header: translateText(25) },
                    { alignContent: CellContentPosition.right },
                    { header: translateText(158) },
                    { header: translateText(12807), alignContent: CellContentPosition.right },
                    { header: translateText(1620), alignContent: CellContentPosition.right },
                    { alignContent: CellContentPosition.right },
                ],
                rows: articles.map(
                    (article): TableRowData => ({
                        id: article.id,
                        cells: [
                            { displayValue: renderArticleNumber(article), id: "1" },
                            { displayValue: renderDescription(article), id: "2" },
                            { displayValue: renderDefaultForCategory(article), id: "3" },
                            { displayValue: renderCategory(article), id: "4" },
                            { displayValue: renderVat(article), id: "5" },
                            { displayValue: renderPrice(article), id: "6" },
                            { displayValue: renderActions(article), id: "7" },
                        ],
                        active: article.id === editedArticle?.id,
                        customRow: CustomRow,
                        replaceCustomRow: article.id === editedArticle?.id,
                    })
                ),
            }),
            [
                translateText,
                articles,
                editedArticle,
                CustomRow,
                renderArticleNumber,
                renderDescription,
                renderDefaultForCategory,
                renderCategory,
                renderVat,
                renderPrice,
                renderActions,
            ]
        )

        return (
            <Box maxHeight={500}>
                <Table overflowY="auto" {...tableData} onScrollBottom={onLoadMoreArticles} />
                {loading && <Skeleton height={54} width="100%" />}
            </Box>
        )
    }
)
