import { ReactNode, Component } from "react"
import { withUserContext, WithUserContextProps, withUserSettings, WithUserSettingsProps } from "@tm/context-distribution"
import { Button, Checkbox, Loader, MoreMenu, Tag, Text } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import {
    Article,
    ArticleAttribute,
    AttributeComparisonModel,
    BundlesWithReplaceButtonMicro,
    DataSupplier,
    IMicros,
    IOeItemTemplate,
    OE,
    ProductGroup,
} from "@tm/models"
import Morpheus, { importMicro, TemplateRenderer, WithMicroProps } from "@tm/morpheus"
import { bindSpecialReactMethods } from "@tm/utils"
import { getBundleParams } from "../../../../utils"
import ArticleAttributes from "../../article-item/attribute-group"
import ArticleCell from "../../article-cell"
import ArticleImageComponent from "../../article-image"
import Prices from "./components/prices"
import { mapOePart } from "../../helper/oe-part"
import ArticleNumbers, { StyledStack } from "../../ArticleNumbers"

export type Props = LocalizationProps &
    WithMicroProps<IMicros> &
    WithUserContextProps &
    WithUserSettingsProps & {
        part: OE.OePart
        hideThumbnail?: boolean
        hiddenFakeActions?: { costEstimation?: boolean; details?: boolean; moreMenu?: boolean; selector?: boolean }
        collapsed?: boolean
        isSelected?: boolean
        isFastCalculator?: boolean
        isFastService?: boolean
        showAdditionalPrices?: boolean
        oeAttributesLoading?: boolean

        selectedArticleAttributes?: Array<string>
        vehicleRecordsComparisons?: Array<AttributeComparisonModel>

        replaceButtonBundle?: BundlesWithReplaceButtonMicro

        renderBasketButton?(quantity: number, onChangeQuantity: (quantity: number) => void): ReactNode // micro: dmg modules, fast-calc
        renderErpInfos?(quantity: number): ReactNode // micro: fast-calc

        onArticleSelect?(articleUniqueId: string): void
        onToggleCollapse?(part: OE.OePart): void
        onArticleAttributeSelect?(attribute: ArticleAttribute, productGroupId?: number, fittingPosition?: number): void
    }

type State = {
    quantity?: number
}

@importMicro
class OeItem extends Component<Props, State> implements IOeItemTemplate, TemplateRenderer {
    rendererIdentifier = {
        bundle: "parts",
        component: "oe-item",
    }

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = {}
    }

    getErpRequestData(part: OE.OePart, quantity: number): Partial<Article> {
        return {
            id: part.id,
            description: part.description,
            traderArticleNo: part.traderNumber,
            supplierArticleNo: part.number,
            supplier: {
                id: part.manufacturerId,
                name: part.manufacturerName,
            } as DataSupplier,
            productGroup: {} as ProductGroup,
            requestErpInfo: true,
            quantity,
        }
    }

    getQualifiedClassName(withPanel = true): string {
        let className = "article-list__item article-list__panel article-list__item--oe"
        if (withPanel) {
            className += " article-list__panel"
        }

        return className
    }

    handleToggleCollapse = () => {
        this.props.onToggleCollapse?.(this.props.part)
    }

    handleSelect = () => {
        this.props.onArticleSelect?.(this.props.part.id ?? "")
    }

    handleChangeQuantity(quantity: number) {
        this.setState({ quantity })
    }

    renderSelector = () => {
        if (this.props.onArticleSelect) {
            return <Checkbox checked={this.props.isSelected} onToggle={this.handleSelect} />
        }
        if (!this.props.hiddenFakeActions?.selector) {
            return <Checkbox disabled />
        }
    }

    renderAdditionalActionsAsRowWithoutMore() {
        return (
            <ArticleCell bemModifier="additional-actions">
                <Button disabled>{this.props.localization.translate(43)}</Button>
            </ArticleCell>
        )
    }

    renderAdditionalActions() {
        const { hiddenFakeActions } = this.props
        return (
            <>
                {!hiddenFakeActions?.costEstimation && this.renderCostEstimationButton()}
                {!hiddenFakeActions?.details && <Button disabled>{this.props.localization.translate(43)}</Button>}
                {!hiddenFakeActions?.moreMenu && <MoreMenu disabled items={[]} onMenuItemSelect={() => {}} />}
                {this.renderSelector()}
            </>
        )
    }

    renderBuyActions(showButtonText?: boolean) {
        const {
            localization: { translateText },
            part,
            isFastCalculator,
            isFastService,
            renderMicro,
            renderBasketButton,
            replaceButtonBundle,
        } = this.props
        const quantity = this.state.quantity || part.quantity || 1

        if (replaceButtonBundle) {
            return renderMicro!(replaceButtonBundle, "replace-button", { part })
        }

        if (renderBasketButton) {
            return renderBasketButton(quantity, (quantity) => this.setState({ quantity }))
        }

        if (isFastService) {
            return renderMicro!("fast-service", "replace-button", { part: part as any })
        }

        if (isFastCalculator) {
            return renderMicro!("fast-calculator-next", "replace-button", { part: part as any })
        }

        return renderMicro!("basket", "add-to-basket-oe-part", {
            part: mapOePart(part, quantity),
            quantity,
            buttonText: showButtonText ? translateText(133) : undefined,
            onChangeQuantity: this.handleChangeQuantity,
        })
    }

    renderCostEstimationButton() {
        const { part, isFastCalculator, renderMicro } = this.props
        const quantity = this.state.quantity || part.quantity || 1
        if (isFastCalculator) {
            return null
        }

        return renderMicro!("basket", "add-to-basket-oe-part", {
            layout: "cost-estimation",
            part: mapOePart(part, quantity),
            quantity,
        })
    }

    renderPrefix() {
        return (
            <ArticleCell bemModifier="prefix">
                <div style={{ visibility: this.props.collapsed == undefined ? "hidden" : undefined }}>
                    <Button
                        icon={this.props.collapsed ? "down" : "up"}
                        onClick={this.handleToggleCollapse}
                        layout={["ghost"]}
                        disabled={!this.props.onToggleCollapse}
                    />
                </div>
            </ArticleCell>
        )
    }

    renderThumbnail() {
        const { part, hideThumbnail } = this.props
        const oePartImage = "/styles/base/images/oe-part-logo.svg"
        return (
            <ArticleCell bemModifier="thumbnail" style={{ display: hideThumbnail ? "none" : undefined }}>
                <ArticleImageComponent
                    thumbnailClassName="article__thumbnail"
                    thumbnailUrl={part.thumbnailUrl ?? oePartImage}
                    thumbnailFallbackUrl={part.thumbnailUrl ? oePartImage : undefined}
                    internalArticleId={0}
                    enableLargeView={false}
                />
            </ArticleCell>
        )
    }

    renderSupplier() {
        const { part } = this.props
        return (
            <ArticleCell bemModifier="supplier">
                <Tag
                    className="supplier__name"
                    layout="ghost"
                    size="m"
                    value={{ value: part.manufacturerName ?? "OE", textModifiers: undefined }}
                    tooltip={part.manufacturerName}
                />
            </ArticleCell>
        )
    }

    renderNumbers() {
        const { part, userContext } = this.props
        return (
            <ArticleCell bemModifier="numbers">
                <StyledStack>
                    {!userContext?.parameter.hideDealerPartNumber && <ArticleNumbers wholesalerArticleNumber={part.traderNumber} hideCopyButton />}
                    <ArticleNumbers oeArticleNumber={part.number} hideCopyButton />
                </StyledStack>
            </ArticleCell>
        )
    }

    renderAddToCompilations() {
        const compilationMicro = this.props.renderMicro!("compilations", "add-to-compilation", { disabled: true })

        if (compilationMicro) {
            return <div className="btn-compilations">{compilationMicro}</div>
        }

        return null
    }

    renderDescription() {
        const { part } = this.props
        return (
            <ArticleCell bemModifier="description">
                <Text className="article__description" title={part.description}>
                    {part.description}
                </Text>
            </ArticleCell>
        )
    }

    renderIcons() {
        const { renderMicro, part, showAdditionalPrices } = this.props
        const quantity = this.state.quantity || part.quantity || 1

        if (!showAdditionalPrices || !part.traderNumber) {
            return null
        }

        return (
            <ArticleCell bemModifier="icon">
                {renderMicro!("erp", "erp-additional-prices", {
                    data: this.getErpRequestData(part, quantity) as Article,
                })}
            </ArticleCell>
        )
    }

    renderErpInformation() {
        const { part, renderMicro, renderErpInfos } = this.props
        const quantity = this.state.quantity || part.quantity || 1

        let erpInfo: ReactNode

        if (renderErpInfos) {
            return renderErpInfos(quantity)
        }

        if (part.traderNumber) {
            erpInfo = renderMicro!("erp", "erp-info", {
                data: this.getErpRequestData(part, quantity) as Article,
            })
        } else if (part.prices?.length) {
            erpInfo = <Prices prices={part.prices} quantity={quantity} />
        }
        return <ArticleCell bemModifier="erp-information">{erpInfo}</ArticleCell>
    }

    renderActions() {
        return (
            <ArticleCell bemModifier="actions">
                {this.renderBuyActions()}
                {this.renderAdditionalActions()}
            </ArticleCell>
        )
    }

    handleAttributeSelect(attribute: ArticleAttribute) {
        this.props.onArticleAttributeSelect?.(attribute, attribute.productGroupId, this.props.part.fittingPosition)
    }

    reanderAttributeCell() {
        const { part, oeAttributesLoading } = this.props

        if (!part.attributes && !oeAttributesLoading) {
            return
        }

        let className = ""
        if (oeAttributesLoading) {
            className += "loading"
        }

        return (
            <ArticleCell bemModifier="attributes" className={className}>
                {oeAttributesLoading && <Loader visible delay={0} />}
                {part.attributes && (
                    <ArticleAttributes
                        isCompact={this.props.userSettings?.articleListSettings?.viewOptions?.compactView}
                        attributes={part.attributes}
                        selected={this.props.selectedArticleAttributes ?? []}
                        productGroupId={part.productGroupId ?? -1}
                        toggleMoreAttributes={this.props.onToggleCollapse ? this.handleToggleCollapse : undefined}
                        vehicleRecordsComparisons={this.props.vehicleRecordsComparisons ?? []}
                        canFilterArticleAttributes
                        onSelect={this.props.onArticleAttributeSelect ? this.handleAttributeSelect : undefined}
                    />
                )}
            </ArticleCell>
        )
    }

    renderDefault() {
        return (
            <div className={this.getQualifiedClassName()}>
                {this.renderPrefix()}
                {this.renderThumbnail()}
                {this.renderSupplier()}
                {this.renderNumbers()}
                {this.renderDescription()}
                {this.renderIcons()}
                {this.renderErpInformation()}
                {this.renderActions()}
                {this.reanderAttributeCell()}
            </div>
        )
    }

    render() {
        const template = getBundleParams().templates?.oeItem
        let content = null

        if (template) {
            content = Morpheus.renderTemplate(template.bundle, template.template, this)
        }

        if (!content) {
            content = this.renderDefault()
        }

        return content
    }
}

export default withLocalization(withUserContext(withUserSettings(OeItem)))
