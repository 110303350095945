import { useEffect } from "react"
import { BundleComponent, connectComponent } from "@tm/morpheus"
import { withWorkTaskConflict, WithWorkTaskConflictProps } from "@tm/context-distribution"

function Resolver(props: WithWorkTaskConflictProps & { actions: typeof Actions }) {
    const {
        workTaskConflict,
        actions: { resetTabNavigation },
    } = props

    useEffect(() => {
        if (workTaskConflict) {
            resetTabNavigation()
            workTaskConflict?.onConfirm(false, true)
        }
    }, [workTaskConflict])

    return null
}

const Actions = {
    resetTabNavigation: () => ({ type: "RESET_MODULE_NAVIGATION" }),
}

const ConflictResolver: BundleComponent = {
    name: "conflict-resolver",
    component: connectComponent(Actions, withWorkTaskConflict(Resolver)),
    reduce: (state) => state,
    transmit: (action) => action,
}

export default ConflictResolver
