import { Article, IMicros } from "@tm/models"
import { FC, useCallback } from "react"
import { ReplaceButton } from "../../components/_shared"
import { bundleChannel } from "../../helpers/bundleChannel"

type Props = IMicros["fast-service"]["replace-button"] & {
    part: Article
}

const ReplaceButtonMicro: FC<Props> = (props) => {
    const { part } = props

    const handleOnClick = useCallback(() => {
        bundleChannel().publish("REPLACE_PART", { part })
    }, [part])

    return <ReplaceButton onClick={handleOnClick.bind(this)} />
}

export default ReplaceButtonMicro
