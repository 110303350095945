import { withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { Button, Checkbox, SelectionList, SelectionListItem, Text, Toolbar, Tooltip } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { CategoryType, SearchFilters } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods, parseQueryString, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { createRef, Component } from "react"

import SearchField from "../_shared/search-field"
import DirectSearchField from "../_shared/direct-search-field"
import { getBundleParams } from "../../utils"
import { SearchType } from "../../business"
import { Repositories } from "../../data"
import { AutosuggestFunction } from "../../data/repositories"
import { getCategoryTypeFromUrl } from "../../helper"
import { Actions, IActions, SearchState } from "./business"

type Props = LocalizationProps &
    RouteComponentProps<RouteParams> &
    WithWorkTaskProps & {
        state: SearchState
        actions: IActions
    }

type RouteParams = {
    view: string
    searchType: string
}

type State = {
    activeSearchFilter: SearchFilters
    extendedSearch: boolean
    previousQuery?: string
}

class SearchComponent extends Component<Props, State> {
    private searchFilterTooltipRef = createRef<Tooltip>()

    private unsubscribe: Function

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            activeSearchFilter: SearchFilters.All,
            extendedSearch: false,
        }

        this.unsubscribe = props.actions.init()
        props.actions.loadQueryHistory()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    UNSAFE_componentWillMount() {
        const { match, location, actions } = this.props
        const { searchType } = this.props.state
        let { query, searchFilter, extendedSearch } = parseQueryString(location.search)
        const extendedSearchStatus = extendedSearch == "true"
        // HOTFIX
        if (query) {
            query = query.toString().replace(/\+/g, " ")
        }

        actions.setSearchQuery(query ? query.toString() : "")

        if (getCategoryTypeFromUrl(match.url) == "directSearch") {
            if (query && searchType == SearchType.DIRECT) {
                actions.getResultCounts(false)
            }

            searchFilter = searchFilter ? parseInt(searchFilter.toString()) : NaN
            searchFilter = !isNaN(searchFilter) ? searchFilter : SearchFilters.All

            this.setState({
                activeSearchFilter: searchFilter,
                extendedSearch: extendedSearchStatus,
                previousQuery: `${query}`,
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { state, actions } = nextProps
        const url = parseQueryString(window.location.search) // Yes 'window.location' is on purpose! 'location' from 'nextProps' mysteriously isn't up to date. If you can fix this, feel free to edit this line of code :)

        if (url.query) {
            // HOTFIX
            url.query = url.query.toString().replace(/\+/g, " ")

            // If search query from url exists and is different, update search field value
            if (url.query != state.query) {
                actions.setSearchQuery(url.query.toString())
            }
        }

        if (!url.query && state.query) {
            // Reset query
            actions.setSearchQuery("")
        }

        // If search filter from url is different, update state
        if (url.searchFilter != this.state.activeSearchFilter) {
            url.searchFilter = url.searchFilter ? parseInt(url.searchFilter.toString()) : NaN
            url.searchFilter = !isNaN(url.searchFilter) ? url.searchFilter : SearchFilters.All

            this.setState({ activeSearchFilter: url.searchFilter })
        }
    }

    isSearchFilterActive(searchFilter: SearchFilters): boolean {
        const { activeSearchFilter } = this.state
        return (activeSearchFilter & searchFilter) == searchFilter
    }

    getFilterResultCount(searchFilter: SearchFilters): number {
        const { resultCounts } = this.props.state
        return resultCounts.filter((x) => x.filterType & searchFilter).reduce((prev, cur) => prev + cur.count, 0)
    }

    handleSearch(query: string) {
        const { actions, match } = this.props
        const { activeSearchFilter, previousQuery } = this.state
        const categoryType = getCategoryTypeFromUrl(match.url)
        let searchFilter = activeSearchFilter

        // reset the active filter if a new global search is expected
        if (query !== previousQuery) {
            searchFilter = SearchFilters.All
        }

        setTimeout(() => {
            // workaround
            TmaHelper.Shared.ByArticleAndUniParts.SubmitSearch(query, categoryType, searchFilter)
        }, 100)

        actions.searchArticles(query, categoryType, searchFilter)
    }

    toggleExtendedSearch = () => {
        this.setState((prev) => ({ extendedSearch: !prev.extendedSearch }))
    }

    private searchFilterTimeout: number

    handleSearchFilterChange(selected: Array<SelectionListItem>) {
        const {
            state: { query },
            actions: { searchArticles },
        } = this.props

        if (selected.length === 1) {
            this.searchFilterTooltipRef.current && this.searchFilterTooltipRef.current.hide()
        }

        let newFilter: SearchFilters = 0

        selected.forEach((x) => {
            const value = parseInt(x.query)
            newFilter |= value
        })

        // Set all search filters if all deselected or all selected
        // @ts-ignore
        if (newFilter == 0 || newFilter == 127 || newFilter == 1920) {
            newFilter = SearchFilters.All
        }

        this.setState({ activeSearchFilter: newFilter })

        clearTimeout(this.searchFilterTimeout)
        this.searchFilterTimeout = window.setTimeout(
            () => {
                searchArticles(query, getCategoryTypeFromUrl(this.props.match.url), newFilter)
            },
            selected.length > 1 ? 500 : 0
        )
    }

    renderSearchFilter() {
        const { activeSearchFilter } = this.state
        const { translate, translateText } = this.props.localization

        const categoryType = getCategoryTypeFromUrl(this.props.match.url)

        // Search filters are only available for DIRECT search
        if (categoryType !== "directSearch") {
            return
        }

        const mapSearchFilter = (value: SearchFilters, label: string): SelectionListItem => {
            return {
                query: value.toString(),
                name: label,
                resultCount: this.getFilterResultCount(value),
            }
        }

        const items: Array<SelectionListItem> = [
            mapSearchFilter(SearchFilters.OeReference, translateText(185)),
            mapSearchFilter(SearchFilters.UtilityNo, translateText(392)),
            mapSearchFilter(SearchFilters.ReplacementNo, translateText(393)),
            mapSearchFilter(SearchFilters.SupplierArticleNo, translateText(92)),
            mapSearchFilter(SearchFilters.TradeReference, translateText(91)),
            mapSearchFilter(SearchFilters.EuropeanArticleNumber, translateText(387)),
        ]

        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchFilters(
            items.map((item) => ({
                id: parseFloat(item.query),
                isSelected: this.isSearchFilterActive(parseFloat(item.query)),
                articleCount: item.resultCount || 0,
            }))
        )

        const content = (
            <div className="parts__search-filters">
                <Text size="s">{translate(1014)}:</Text>
                <SelectionList
                    size="m"
                    groups={[{ priority: "high", items }]}
                    selected={items.filter((x) => this.isSearchFilterActive(parseInt(x.query)))}
                    onChange={this.handleSearchFilterChange}
                    localization={this.props.localization}
                />
                <div className="parts__search-filters__checkbox-group">
                    <Checkbox checked={this.state.extendedSearch} label={translateText(12466)} onToggle={this.toggleExtendedSearch} />
                </div>
            </div>
        )

        return (
            <Tooltip ref={this.searchFilterTooltipRef} event="click" content={content} style="light">
                <Button icon="filter" size="m" isActive={activeSearchFilter != SearchFilters.All} />
            </Tooltip>
        )
    }

    renderSearchField(
        placeholder: string,
        minLengthForSearch: number,
        categoryType: CategoryType,
        extraButton?: JSX.Element,
        getSuggestions?: AutosuggestFunction
    ) {
        const { query, hint } = this.props.state

        if (categoryType === "directSearch") {
            return (
                <DirectSearchField
                    query={query}
                    placeholder={placeholder}
                    minLengthForSearch={minLengthForSearch}
                    onSearch={this.handleSearch}
                    hint={hint}
                    autofocus
                    activeCategory={categoryType}
                    extendedSearch={this.state.extendedSearch}
                    searchfilterResultCounts={this.props.state.resultCounts}
                    onSearchFilterChanged={this.handleSearchFilterChange}
                />
            )
        }

        return (
            <SearchField
                query={query}
                placeholder={placeholder}
                minLengthForSearch={minLengthForSearch}
                getSuggestions={getSuggestions}
                onSearch={this.handleSearch}
                hint={hint}
                extraButton={extraButton}
                autofocus
                activeCategory={categoryType}
                extendedSearch={this.state.extendedSearch}
            />
        )
    }

    renderVehiclePartsSearch() {
        const { vehicle } = this.props.state

        return this.renderSearchField(
            this.props.localization.translateText(998),
            getBundleParams().minimumSearchLength.vehicleSearch,
            "vehicleParts",
            undefined,
            vehicle ? (query) => Repositories.getProductGroupSuggestions(query, vehicle.tecDocTypeId) : undefined
        )
    }

    renderUniSearch() {
        return this.renderSearchField(
            this.props.localization.translateText(194),
            getBundleParams().minimumSearchLength.universalSearch,
            "universalParts",
            undefined,
            (query) => Repositories.getUniProductGroupSuggestions(query)
        )
    }

    renderDirectSearch() {
        return this.renderSearchField(
            this.props.localization.translateText(138),
            getBundleParams().minimumSearchLength.directSearch,
            "directSearch",
            this.renderSearchFilter()
        )
    }

    renderCategorySearchField() {
        const categoryType = getCategoryTypeFromUrl(this.props.match.url)

        switch (categoryType) {
            case "vehicleParts":
                return this.renderVehiclePartsSearch()
            case "universalParts":
                return this.renderUniSearch()
            case "directSearch":
                return this.renderDirectSearch()
            default:
                break
        }
    }

    render() {
        return <Toolbar className="tk-parts search">{this.renderCategorySearchField()}</Toolbar>
    }
}

export default connectComponent(Actions, withRouter(withLocalization(withWorkTask(SearchComponent))))
