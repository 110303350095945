import { useRef } from "react"
import { UpdateRepairShopRequest, useUser } from "@tm/context-distribution"
import { Box, LoadingContainer } from "@tm/components"
import { useLocalization } from "@tm/localization"
import CustomerDetailsForm from "./CustomerDetailsForm"
import { useGetReturnUrl } from "../../hooks/useGetReturnUrl"
import { useUpdateRepairShopContact } from "../../hooks/useUpdateRepairShopContact"
import { getReturnUrlRequest } from "../../lib/helpers/returnUrl"
import ErrorNotification from "../shared/ErrorNotification"

export default function Main() {
    const contentRef = useRef<HTMLDivElement>(null)
    const { userSettings } = useUser() ?? {}
    const { translateText, language } = useLocalization()

    const getReturnUrlMutation = useGetReturnUrl({
        translateText,
        onSuccess(returnUrl) {
            openPartslifeInNewWindow(returnUrl)
        },
    })

    const { isLoading, updateContactDetails } = useUpdateRepairShopContact({
        translateText,
        onSuccess(request) {
            getPartslifeReturnUrl(request)
        },
    })

    function onOpenPartslife(request: UpdateRepairShopRequest, fieldsChanged: boolean) {
        if (fieldsChanged) {
            updateContactDetails(request)
        } else {
            getPartslifeReturnUrl(request)
        }
    }

    function getPartslifeReturnUrl(shopDetails: UpdateRepairShopRequest) {
        const request = getReturnUrlRequest(shopDetails, userSettings?.repairShop?.id ?? "", language, translateText)
        getReturnUrlMutation.mutate(request)
    }

    const openPartslifeInNewWindow = (url: string) => {
        const navigationDom = document?.querySelector(".master")

        if (!contentRef?.current || !navigationDom) {
            return
        }

        const options = `height=${contentRef.current.offsetHeight - navigationDom.clientHeight},width=${
            contentRef.current.offsetWidth
        },location=0,resizable=1,scrollbars=1,left=${window.screenLeft + 15},top=${window.screenTop + 260},status=yes`

        window.open(url, "_blank", options)
    }

    return (
        <ErrorNotification>
            <Box display="flex" flex="1" ref={contentRef}>
                <LoadingContainer isLoading={userSettings === undefined || getReturnUrlMutation.isLoading || isLoading}>
                    {userSettings && <CustomerDetailsForm repairShop={userSettings.repairShop} openPartslife={onOpenPartslife} />}
                </LoadingContainer>
            </Box>
        </ErrorNotification>
    )
}
