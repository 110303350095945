import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getTitleTypeTextId } from "@tm/utils"
import { CostEstimationVoucherCustomer } from "@tm/models"
import { Typography, Grid, Stack, Card, CardContent } from "@tm/components"
import { FullWidthTextField } from "../../../../../../_shared/StyledComponents"

type Props = {
    customer?: CostEstimationVoucherCustomer
    repairShopLogo?: string
}

export default function CustomerOverview({ customer, repairShopLogo }: Props) {
    const { translateText } = useLocalization()
    const userSettings = useUser()?.userSettings
    const titleType = customer && customer.titleType && getTitleTypeTextId(customer.titleType)
    const { companyName, contactInfo, postalAddress } = userSettings?.repairShop || {}

    return (
        <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Card sx={{ maxWidth: "760px" }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {translateText(107)}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(102)} value={customer?.customerNumber} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(118)} value={titleType && translateText(titleType)} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(76)} value={customer?.academicTitle} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(119)} value={customer?.firstName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(104)} value={customer?.lastName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(108)} value={customer?.companyName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(111)} value={customer?.street} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(112)} value={customer?.zip} />
                        </Grid>
                        <Grid item xs={4}>
                            <FullWidthTextField label={translateText(845)} value={customer?.city} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Stack direction="row" spacing={2}>
                {repairShopLogo && <img src={`data:image;base64,${repairShopLogo}`} alt="Repairshop Logo" style={{ maxHeight: "90px" }} />}
                {userSettings && userSettings.repairShop && (
                    <Stack>
                        <Typography variant="h2">{companyName}</Typography>
                        <Typography variant="label">{postalAddress && postalAddress.street}</Typography>
                        <Typography variant="label">{postalAddress && `${postalAddress.zip}, ${postalAddress.city}`}</Typography>
                        <Typography variant="label">{contactInfo && contactInfo.phone}</Typography>
                        <Typography variant="label">{contactInfo && contactInfo.email}</Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
