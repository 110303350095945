import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { transmit } from "./business"

const saveButton: BundleComponent<unknown, typeof component> = {
    name: "action-buttons",
    component,
    transmit,
}

export default saveButton
