import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CostEstimationTotals } from "../../../data/model"
import Total from "./Total"
import { TotalsLoader } from "./TotalsLoader"

type Props = {
    totals?: CostEstimationTotals
    currencyCode: string
    showRepairTimesInHours: boolean
    totalsLoading: boolean
}

export function WorksTotals({ totals, totalsLoading, showRepairTimesInHours, currencyCode }: Props) {
    const { translateText, currency, number } = useLocalization()

    if (!totals) {
        return null
    }

    if (totalsLoading) {
        return <TotalsLoader />
    }

    const repairTimesUnits = showRepairTimesInHours ? ` ${translateText(60)}` : ""
    return (
        <Stack alignSelf="flex-end" px={2} spacing={0.5} width="400px">
            <Total label={translateText(259)} value={number(totals?.repairTimeTotal || 0, 2) + repairTimesUnits} valueBold />
            <Total label={translateText(1721)} value={currency(totals?.workCosts || 0, currencyCode)} valueBold />
            {!!totals?.alreadyTaxedWorkCosts && (
                <Total label={translateText(12850)} value={currency(totals.alreadyTaxedWorkCosts, currencyCode)} valueBold />
            )}
        </Stack>
    )
}
