import { useEffect, useState } from "react"
import { SidebarWidget } from "@tm/controls"
import { Typography, LinkButton } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { CategoryType, VehicleType } from "@tm/models"
import { useParams } from "react-router"
import { getBundleParams } from "../../utils"
import DirectSearchWidgetContent from "../_shared/direct-search-widget-content"
import UniSearchWidgetContent from "../_shared/uni-search-widget-content"
import { UniSearchWidgetState, IActions, Actions } from "../_shared/uni-search-widget-content/business"
import PartsSearchCategories from "../_shared/category-selection"
import { IActions as IQueryAction } from "../direct-search-widget/business"

type RouteParams = {
    workTaskId?: string
}

type ConfigParams = {
    universalListRoute: string
    universalSearchRoute: string
    directSearchRoute: string
    universalSearchText: string
    directSearchText: string
    tiresSearchText: string
}

type Props = ConfigParams & {
    state: UniSearchWidgetState
    actions: IActions
    className?: string
}

function SearchWidgetComponent(props: Props) {
    const {
        state,
        actions,
        directSearchRoute,
        universalListRoute,
        universalSearchRoute,
        state: { loading, nodes, queryHistory },
        className,
        directSearchText,
        universalSearchText,
        tiresSearchText,
    } = props
    const { translate, translateText } = useLocalization()
    const params = useParams<RouteParams>()
    const [activeCategory, setActiveCategory] = useState<CategoryType>("directSearch")

    useEffect(() => {
        if (activeCategory === "universalParts" && !state.initialized) {
            actions.loadUniversalPartsTreeNodes()
        }
    }, [activeCategory, state.initialized])

    function renderVehiclePartsSearch() {
        const url = renderRoute(getBundleParams().vehiclesRoute, {
            ...params,
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
            vehicleType: VehicleType.PassengerCar, // TODO
        })

        return (
            <div className="vehicle-parts">
                <Typography>{translate(1002)}</Typography>
                <LinkButton to={url}>{translate(862)}</LinkButton>
            </div>
        )
    }

    function renderContent() {
        switch (activeCategory) {
            case "vehicleParts":
                return renderVehiclePartsSearch()
            case "universalParts":
                const treeId = getBundleParams().universalTreeId
                if (!treeId) {
                    return
                }

                return (
                    <UniSearchWidgetContent
                        loading={loading}
                        nodes={nodes}
                        universalListRoute={universalListRoute}
                        universalSearchRoute={universalSearchRoute}
                        universalTreeId={treeId}
                        onApplyProductGroups={() => null}
                        onChangeBreadcrumbs={() => null}
                    />
                )
            case "directSearch":
                const finalActions = {
                    loadQueryHistory: actions.loadQueryHistory,
                    removeFromQueryHistory: actions.removeFromQueryHistory,
                }

                return (
                    <DirectSearchWidgetContent
                        directSearchRoute={directSearchRoute}
                        queryHistory={queryHistory}
                        actions={finalActions as IQueryAction}
                    />
                )
            default:
                break
        }
    }

    const finalClassName = `tk-parts sidebar-partssearch ${className || ""}`

    return (
        <SidebarWidget id="parts__sidebar-search-widget" className={finalClassName} icon="catalog" title={translateText(993)}>
            <PartsSearchCategories
                hideAreaTitle
                tabType="worktask"
                className="sidebar-category-selection"
                activeCategory={activeCategory}
                onChangeCategory={(category) => setActiveCategory(category)}
                disabledCategories={[]}
                availableCategories={["universalParts", "directSearch"]}
                directSearchText={translateText(directSearchText || 389)}
                universalSearchText={translateText(universalSearchText || 1009)}
                tiresSearchText={translateText(tiresSearchText || 240)}
            />

            {renderContent()}
        </SidebarWidget>
    )
}

export default connectComponent(Actions, SearchWidgetComponent)
