import { Box, Icon, IconButton, LinearProgress, Stack, Tooltip, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ForwardedRef, PropsWithChildren, forwardRef } from "react"

const Container = styled(Box)({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    padding: 16,
    marginLeft: 16,
})

type SubArticleContainerProps = PropsWithChildren<{
    title: string
    isLoading?: boolean
    onClose: () => void
}>

export const SubArticleContainer = forwardRef<HTMLDivElement, SubArticleContainerProps>((props, ref) => {
    const { title, isLoading, onClose, children } = props
    const { translateText } = useLocalization()
    return (
        <Container ref={ref}>
            <Stack direction="row" justifyContent="space-between" mb={1}>
                <Typography variant="h4">{title}</Typography>
                <Tooltip title={translateText(70)}>
                    <IconButton onClick={onClose} sx={{ p: 0 }}>
                        <Icon name="close" />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Box height={3} mb={1}>
                {isLoading && <LinearProgress sx={{ height: 3 }} />}
            </Box>

            {children}
        </Container>
    )
})
