import * as React from "react"
import { Article } from "@tm/models"
import ReplaceButton from "../../components/_shared/replaceButton"
import { bundleChannel } from "../../data/helpers"

type Props = {
    part: Article
}

const replaceButton: React.FC<Props> = (props) => {
    const { part } = props

    const onClick = React.useCallback(() => bundleChannel().publish("REPLACE_PART", { part }), [part])

    if (!part) {
        return null
    }

    // TODO: hack for the moment because functionality is not clear from svc
    return <ReplaceButton onClick={onClick} isInModal disabled={(part as any).disabled} />
}

export default replaceButton
