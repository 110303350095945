import { Container, IModelContainer } from "@tm/nexus"
// eslint-disable-next-line import/no-self-import
import { getCalculationData, getCalculationUrl, getHistory, getVehicleModels, getVinSuggestions, importToCostEstimation } from "."
import { HistoryEntry } from "../models"
import { CostEstimationRequest } from "./costEstimate-importRepairTimes"
import { GetCalculationDataRequest, GetCalculationDataResponse } from "./getCalculationData"
import { GetCarInfoResponse } from "./getCarInfo"
import { HistoryListRequest } from "./getHistoryList"
import { PrepareCalculationEurotaxRequest, PrepareCalculationEurotaxResponse } from "./prepareCalculation"

export * from "./costEstimate-importRepairTimes"
export * from "./getCalculationData"
export * from "./getCarInfo"
export * from "./getHistoryList"
export * from "./prepareCalculation"
export * from "./vinPiker-getVin"

export const EreKey = "dmg-eurotax"

export const initRepositories = () => {
    Container.register({
        name: EreKey,
        containerActions: {
            getVehicleModels,
            getHistory,
            getCalculationUrl,
            getCalculationData,
            importToCostEstimation,
            getVinSuggestions,
        },
    })
}

export const useContainer = () => Container.getInstance(EreKey) as EreContainer

type EreContainerActions =
    | { name: "getVehicleModels"; action: (tecDocNr?: number) => Promise<GetCarInfoResponse> }
    | { name: "getHistory"; action: (req: HistoryListRequest) => Promise<HistoryEntry[]> }
    | { name: "getCalculationUrl"; action: (req: PrepareCalculationEurotaxRequest) => Promise<PrepareCalculationEurotaxResponse> }
    | { name: "getCalculationData"; action: (req: GetCalculationDataRequest) => Promise<GetCalculationDataResponse> }
    | { name: "importToCostEstimation"; action: (req: CostEstimationRequest) => Promise<void> }
    | { name: "getVinSuggestions"; action: (query: string, modelId: number) => Promise<Array<string>> }

export type EreContainer = IModelContainer<void, EreContainerActions>
