import { PropsWithChildren, UIEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Scrollbar } from "@tm/controls"
import Morpheus from "@tm/morpheus"
import { useParams } from "react-router"
import { Box, Loader, styled } from "@tm/components"
import { MainState } from "."
import { MatchParams } from "../../data/models"
import Calculation from "../calculation/component"
import Extern from "../extern/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Settings from "../settings/component"
import Summary from "../summary/component"

type Props = {
    route: string
}

function WrappedModule({ children }: PropsWithChildren<Record<string, unknown>>) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: "MODULE_OPENED", payload: { icon: "damage-calculation", title: "{{3140}}" }, broadcast: true })
    }, [])

    return <>{children}</>
}

export default function Main({ route }: Props) {
    let scrollTimeoutId: number | undefined
    const { view } = useParams<MatchParams>()

    const loading = useSelector((state: MainState) => state.manager.checkCustomerLoading)

    const [priceInNav, setPriceInNav] = useState(false)

    const handleScroll = (e: UIEvent<HTMLElement> | UIEvent) => {
        window.clearTimeout(scrollTimeoutId)

        scrollTimeoutId = window.setTimeout(() => {
            setPriceInNav(view === "calculation" && (e.target as HTMLElement).scrollTop >= 125)
        }, 100)
    }

    useEffect(() => {
        Morpheus.closeView("1")
    }, [view])

    return (
        <StyledWrapper loading={loading}>
            <Navigation route={route} priceInNav={priceInNav} />
            <ModuleManager />

            {loading && <Loader className="loader" />}

            <Box className="content">
                <Scrollbar onScroll={handleScroll}>
                    {view === "extern" && (
                        <WrappedModule>
                            <Extern />
                        </WrappedModule>
                    )}
                    {view === "summary" && (
                        <WrappedModule>
                            <Summary />
                        </WrappedModule>
                    )}
                    {view === "calculation" && (
                        <WrappedModule>
                            <Calculation />
                        </WrappedModule>
                    )}
                    {view === "dat-settings" && (
                        <WrappedModule>
                            <Settings />
                        </WrappedModule>
                    )}
                </Scrollbar>
            </Box>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box, { shouldForwardProp: (props) => props !== "loading" })<{ loading: boolean | undefined }>(({ theme, loading }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...(loading && {
        pointerEvents: "none",
        ">:not(.loader)": {
            opacity: theme.opacity?.disabled,
        },
    }),
    ".loader": {
        position: "absolute",
        top: "20em",
        left: "50%",
        zIndex: 1,
    },
    ".content": {
        padding: theme.margin?.m,
        flex: 1,
        display: "flex",
    },
}))
