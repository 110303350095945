import { Box, Stack, Typography, Collapse } from "@tm/components"
import { useEffect, useState } from "react"
import { RequestArticleListPayload, channel } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Selections } from "./Selections"
import { UniBestsellerButton } from "./UniBestsellerButton"
import { useGetBestsellerSearchTree } from "./hooks/useGetBestsellerSearchTree"
import { BestsellerSearchtreeNode, CriterionFilters } from "../../../data/model"

import { useIsUniBestsellerActive } from "./hooks/useIsUniBestsellerActive"
import { getBundleParams } from "../../../utils"
import { SkeletonLoading } from "./SkeletonLoading"

export type NodeState = {
    id?: number
    state: { key: number; criterionFilter?: CriterionFilters }[]
}

type Props = {
    hide?: boolean
}

/**
 * Renders Unibestsellersearchtree only if its activaded by MDM, look at "useIsBestsellerActive"
 * @param props
 * @returns
 */
export function UniBestsellerSearchTree(props: Props) {
    const { hide } = props
    const bundleParams = getBundleParams()

    const hasModule = useIsUniBestsellerActive()
    const { translateText } = useLocalization()

    const [selectedNode, setSelectedNode] = useState<BestsellerSearchtreeNode | undefined>()
    const [selectedCriterias, setSelectedCriterias] = useState<CriterionFilters[] | undefined>(undefined)

    const { data, isLoading } = useGetBestsellerSearchTree(hasModule)
    const [enableCall, setEnableCall] = useState(false)

    useEffect(() => {
        if (data) {
            setSelectedNode(data.first())
        }
    }, [data])

    if (!hasModule) {
        return null
    }

    const handleSelection = (selectedId: number) => {
        setEnableCall(false)
        setSelectedCriterias(undefined)
        setSelectedNode(data?.find((e) => e.id === selectedId))
    }

    const onSelectionOpend = () => {
        setEnableCall(true)
    }

    const onSearch = () => {
        if (selectedCriterias && selectedNode && bundleParams?.universalBestsellerTreeId) {
            const querys: string[] = []
            selectedCriterias.forEach((criteria) => {
                if (criteria?.queryStr) {
                    querys.push(criteria.queryStr)
                }
            })
            const request: RequestArticleListPayload = {
                uniNode: {
                    nodeId: selectedNode.id,
                    treeId: bundleParams.universalBestsellerTreeId,
                    // we need a special char replacement for spaces,
                    // otherwise it is interpreted as + and is not correctly decoded in the article list
                    criteria: querys.map((v) => v.replace(" ", "$_$")),
                },
            }
            channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        }
    }

    return (
        <Box py={2}>
            <Collapse in={!hide}>
                <Stack pb={2}>
                    {isLoading && <SkeletonLoading />}
                    {!isLoading && (
                        <>
                            <Typography variant="body3" textTransform="uppercase">
                                {translateText(13650)}
                            </Typography>
                            <Stack direction="row" spacing={1} pt={1}>
                                {data?.map((node) => {
                                    return (
                                        <UniBestsellerButton
                                            selected={selectedNode?.id === node.id}
                                            key={node.id}
                                            id={node.id}
                                            thumbnailUrl={node.thumbnailUrl}
                                            onClick={handleSelection}
                                        />
                                    )
                                })}
                            </Stack>
                            <Box pt={2}>
                                <Typography variant="label">{selectedNode?.name}</Typography>
                                <Stack spacing={1} direction="row" alignItems="end">
                                    <Selections
                                        selectedCriteria={selectedCriterias}
                                        setSelectedCriterias={setSelectedCriterias}
                                        bestsellerSearchTreeNode={selectedNode}
                                        onSearch={onSearch}
                                        enableCall={enableCall}
                                        onSelectionOpend={onSelectionOpend}
                                    />
                                </Stack>
                            </Box>
                        </>
                    )}
                </Stack>
            </Collapse>
        </Box>
    )
}
