import { useCallback, useEffect, useLayoutEffect, useRef, useState, MouseEvent } from "react"
import { Location } from "history"
import { Link } from "react-router-dom"
import { Tooltip, styled } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Demo, Icon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModuleOptionType, ModuleTab, Vehicle, channel } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import {
    RouteComponentProps,
    classes,
    encodeUniqueId,
    equals,
    getUIA,
    getValue,
    renderRoute,
    useGetActivatableModules,
    usePrevious,
    withRouter,
} from "@tm/utils"

import { StylingFromConfig } from "../../data"
import { getNavigationSplitPosition } from "../../helpers"
import { Actions, DefaultTab, ExtendedModuleTab, IActions, ModuleNavigationState } from "./business"
import { getNextUrlComparable, getStyleFromConfig } from "./helpers"

type Props = RouteComponentProps<{ workTaskId: string }> &
    StylingFromConfig & {
        state: ModuleNavigationState
        actions: IActions
        worktaskDashboardTitle?: string
        activateDefaultTabs?: boolean
        defaultTabs?: { [key: string]: DefaultTab }
        workTaskRoute?: string
    }

const translationRegex = /\{\{(.*?)\}\}/
const isDigit = /^\d+$/

const StyledLink = styled(Link)<{ disabled: boolean; selected: boolean }>(({ theme, disabled, selected }) => ({
    ...(disabled
        ? {
              pointerEvents: "none",
              opacity: 0.5,
          }
        : {}),
    ...(selected
        ? {
              color: theme.palette.highlight.main,
          }
        : {}),
}))

function ModuleNavigationComponent(props: Props) {
    const { translateText, languageId } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const workTaskId = window.__NEXT_WORKTASKID__!
    const { userContext } = useUser() ?? {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const {
        worktaskDashboardTitle,
        state,
        actions: { rearangeTabs, init, rewriteTabUrl, splitTabs, closeTab },
        activateDefaultTabs,
        defaultTabs,
        location,
        history,
        match,
    } = props
    const { tabs, more, workTaskTab } = state
    const prevState = usePrevious(state)
    const [currentUrl, setCurrentUrl] = useState<string>(decodeURI(location.pathname) + location.search)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const dashboardButtonElement = useRef<HTMLDivElement>(null)
    const navigationElement = useRef<HTMLDivElement>(null)
    const wrapperElement = useRef<HTMLDivElement>(null)
    const moreElement = useRef<HTMLDivElement>(null)

    const resizeTimer = useRef<number>()
    const unregisterOutsideClick = useRef<() => void>()

    const handleResize = useCallback(() => {
        clearTimeout(resizeTimer.current)
        resizeTimer.current = window.setTimeout(() => {
            rearangeTabs()
        }, 200)
    }, [rearangeTabs])

    const handleTooltipShow = useCallback(() => {
        setShowTooltip(true)
    }, [setShowTooltip])

    const handleTooltipHide = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    useEffect(() => {
        // show defaultTabs when they're activated
        const updatedTabs = activateDefaultTabs ? defaultTabs || {} : {}
        const unsubscribe: Function = init(
            Object.keys(updatedTabs).map((key) => updatedTabs[key]),
            activateDefaultTabs
        )
        const currentUnregisterOutsideClick = unregisterOutsideClick.current
        return () => {
            clearTimeout(resizeTimer.current)
            currentUnregisterOutsideClick?.()
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        const unsubs: (() => void)[] = []
        if (workTaskId) {
            const wtChannel = channel("WORKTASK", workTaskId)
            unsubs.push(
                wtChannel.subscribe("MODULE/OPENED", (tab) => {
                    props.actions.openTab(tab)
                })
            )
        }

        return function unsubscribeChannels() {
            unsubs.forEach((unsub) => unsub())
            unsubs.splice(0)
        }
    }, [workTaskId, props.actions])

    // sometimes the wrapper width is changed without window resize.
    // For example when basket summay is reloaded and that's why the resizeObserver was added
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(wrapperElement.current as Element)

        const currentWrapperElement = wrapperElement.current
        return () => {
            if (currentWrapperElement) {
                resizeObserver.unobserve(currentWrapperElement as Element)
            }
        }
    }, [handleResize])

    const onLocationChangeRewriteTabUrl = useCallback(
        (pathname: string) => {
            const nextUrl = getNextUrlComparable(pathname)

            if (nextUrl && currentUrl.startsWith(nextUrl)) {
                rewriteTabUrl(currentUrl, pathname)
                setCurrentUrl(pathname)
            }
        },
        [currentUrl, rewriteTabUrl]
    )

    useEffect(() => {
        function handleHistoryChange(loc: Location) {
            setCurrentUrl(decodeURI(loc.pathname) + loc.search)
        }

        function handleLocationChange(loc: Location) {
            onLocationChangeRewriteTabUrl(loc.pathname + loc.search)
        }

        window.addEventListener("resize", handleResize)
        const unregisterHistoryListener = history.listen(handleHistoryChange)
        const unregisterLocationListener = history.listen(handleLocationChange)

        return () => {
            window.removeEventListener("resize", handleResize)
            unregisterHistoryListener()
            unregisterLocationListener()
        }
    }, [history, handleResize, onLocationChangeRewriteTabUrl])

    useLayoutEffect(() => {
        if (navigationElement?.current && !equals(prevState, state)) {
            const splitPos = getNavigationSplitPosition(navigationElement.current, moreElement.current)
            if (splitPos.pos !== state.splitPosition) {
                splitTabs(splitPos.pos)
            }
        }
    })

    function handleCloseTab(tab: ModuleTab, ev: MouseEvent) {
        ev.stopPropagation()
        ev.preventDefault()
        const componentUrl = getComponentUrl()

        if (decodeURI(tab.url).indexOf(decodeURI(currentUrl)) !== -1) {
            history.push(componentUrl)
        }

        closeTab(tab)
        rearangeTabs()
    }

    function getComponentUrl() {
        // don't use worktaskId para if not set, as example "open Usersettings"
        const worktaskObj = workTaskId ? { workTaskId: encodeUniqueId(workTaskId) } : {}
        const urlParms = { ...match.params, ...worktaskObj }
        return renderRoute(props.workTaskRoute ?? match.path, urlParms).replace(/\/$/, "")
        // TODO: Fix hotfix, this.props.match.path has an ending / which causes "ModuleComponent.prototype.handleClearStoresOnRouteChange" to clear the store (url is not the same anymore)
        return renderRoute(match.path, match.params).replace(/\/$/, "")
    }

    function renderIcon(icon: string | undefined) {
        if (!icon) {
            return null
        }

        if (icon.includes("//") || icon.startsWith("/")) {
            // No icon names with 2 slashes or starting with a slash I hope -> icon string is an external or internal url
            return <Image url={icon} className="tab__icon icon icon--m" />
        }

        if (icon === "voucher-kva" && languageId !== "1") {
            return <Icon name={`${icon}-international`} className="tab__icon" />
        }

        return <Icon name={icon} className="tab__icon" />
    }

    function checkDemoEnabled(item: ExtendedModuleTab) {
        return activatableModulesLoadable.response?.moduleGroups
            .find((moduleGroup) => moduleGroup.id === item.moduleGroupId)
            ?.modules.some(
                (module) =>
                    module.id.toLowerCase() === item.moduleId?.toLowerCase() &&
                    module.moduleOptions.some((moduleOption) => moduleOption.type === ModuleOptionType.Demo && moduleOption.active)
            )
    }

    function renderItem(item: ExtendedModuleTab, key: number) {
        const stateUrl = /%[\d\w]{2}/.test(currentUrl) ? decodeURIComponent(currentUrl) : currentUrl
        const itemUrl = /%[\d\w]{2}/.test(item.url) ? decodeURIComponent(item.url) : item.url

        const isSelected = getComponentUrl() !== stateUrl && itemUrl.indexOf(stateUrl) !== -1
        if (isSelected) {
            const [last] = channel("WORKTASK", workTaskId).last(1, "MODULE/SELECTED")
            if (!last || last.content.title !== item.title) {
                channel("WORKTASK", workTaskId).publish("MODULE/SELECTED", item)
            }
        }

        const className = `tab navigation__item ${isSelected ? "is-selected" : ""}`
        const tabClassNameExtensions = getStyleFromConfig("tab", props.style, { layout: "module" })
        const title = (item.title || "").replace(translationRegex, (s, num) => {
            return translateText(num)
        })

        const isDemoEnabled = checkDemoEnabled(item)
        const isEnabled = checkVehicleDependency(workTask?.vehicle, item.vehicleDependency)

        return (
            <StyledLink
                disabled={!isEnabled}
                selected={false}
                className={`${className}${tabClassNameExtensions}`}
                to={item.url}
                key={`${key}_${item.url}`}
                {...getUIA("ModulenavigationButton", item.url)}
            >
                {renderIcon(item.icon)}
                <div className="tab__content">
                    <div className="tab__title">{title}</div>
                </div>
                {isDemoEnabled && <Demo displayMode="edge" className="demo-icon" />}
                {!item.isDefault && (
                    <button type="button" className="tab__close" onClick={(ev) => handleCloseTab(item, ev)}>
                        <Icon name="close" />
                    </button>
                )}
            </StyledLink>
        )
    }

    function renderMoreItem(item: ExtendedModuleTab, key: number) {
        const className = `more__item`
        const tabClassNameExtensions = getStyleFromConfig("tab", props.style)
        const title = item.title.replace(translationRegex, (s, num) => {
            return translateText(num)
        })
        const isDemoEnabled = checkDemoEnabled(item)
        const isEnabled = checkVehicleDependency(workTask?.vehicle, item.vehicleDependency)
        return (
            <StyledLink
                disabled={!isEnabled}
                selected={item.isSelected}
                className={`${className}${tabClassNameExtensions}`}
                to={item.url}
                key={key}
                onClick={handleTooltipHide}
                {...getUIA("ModulenavigationButtonMore")}
            >
                {renderIcon(item.icon)}
                <div className="tab__content">
                    <div className="tab__title">{title}</div>
                </div>
                {isDemoEnabled && <Demo displayMode="edge" className="demo-icon" />}
                {!item.isDefault && (
                    <button type="button" className="tab__close" onClick={(ev) => handleCloseTab(item, ev)}>
                        <Icon name="close" />
                    </button>
                )}
            </StyledLink>
        )
    }

    const componentUrl = getComponentUrl()
    const isStartSelected = currentUrl === componentUrl
    const linkClassName = `tab ${isStartSelected ? "is-selected" : ""}`
    const tabClassNameExtensions = getStyleFromConfig("tab", props.style, { layout: "module" })
    const btnClassName = `more__btn tab ${more.length ? "is-visible" : ""}${tabClassNameExtensions}`

    const iconName = (workTaskTab && workTaskTab.icon) || "dashboard"
    let title = translateText(671)

    if (workTaskTab && workTaskTab.title) {
        title = workTaskTab.title

        if (translationRegex.test(title)) {
            title = title.replace(translationRegex, (s, num) => {
                return translateText(num)
            })
        }
    } else if (worktaskDashboardTitle) {
        title = isDigit.test(worktaskDashboardTitle) ? translateText(worktaskDashboardTitle) : worktaskDashboardTitle
    }

    return (
        <>
            <div className="worktask-modules__wrapper" ref={wrapperElement} {...getUIA("Modulenavigation")}>
                <Link to={componentUrl}>
                    <div className={classes(`${linkClassName}${tabClassNameExtensions}`)} ref={dashboardButtonElement}>
                        <Icon name={iconName} className="tab__icon" />
                        <div className="tab__content">
                            <div className="tab__title">{title}</div>
                        </div>
                    </div>
                </Link>
                <div className="worktask-modules tab-control">
                    <div className="worktask-modules__inner" ref={navigationElement}>
                        {tabs.map(renderItem)}
                    </div>
                    <div className="more__wrapper" ref={moreElement}>
                        <Tooltip
                            className="more__list"
                            onClose={handleTooltipHide}
                            onClickAway={handleTooltipHide}
                            open={showTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={<div>{more.map(renderMoreItem)}</div>}
                            variant="light"
                        >
                            <div>
                                <Button className={btnClassName} onClick={handleTooltipShow}>
                                    ...
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connectComponent(Actions, withRouter(ModuleNavigationComponent))

function checkVehicleDependency(vehicle?: Vehicle, path?: string) {
    if (!!path && !vehicle) {
        return false
    }

    if (!vehicle || !path) {
        return true
    }

    const tokens = path.split("||").map((x) => x.trim())
    if (!tokens.length) {
        return false
    }

    return tokens.reduce((prev, curr) => getValue(vehicle, curr.split(".")) || prev, false)
}
