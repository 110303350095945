import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { styled, Box, Typography, Icon, Divider, Stack, Button } from "@tm/components"
import { ArticleReferenceType } from "@tm/models"
import { ReferenceGroup } from "../References/ReferenceGroup"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font?.fontFamily?.secondary,
    fontSize: theme.typography.label.fontSize,
}))

export function AdditionalInformations() {
    const { translateText } = useLocalization()

    const additionalInformation = useDefaultArticleItemState((x) => x.displayArticle.additionalInformation)
    const { references, links, textInformation } = useMemo(() => additionalInformation ?? {}, [additionalInformation])

    const refsReplacement = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.ReplacementArticleNo), [references])
    const refsReplaced = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.ReplacedByArticleNo), [references])

    return (
        <>
            {(!!links?.length || !!textInformation?.length || !!refsReplacement?.length || !!refsReplaced?.length) && (
                <Box key="references" my={2}>
                    <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1}>
                        <Icon name="trader-icon" />
                        <Typography ml={1} variant="label" textTransform="uppercase" lineHeight={1}>
                            {translateText(526)}
                        </Typography>
                        <Box ml={1} flex={1}>
                            <Divider light />
                        </Box>
                    </Stack>
                    {(!!refsReplacement?.length || !!refsReplaced?.length) && (
                        <Stack direction="row" spacing={2} mb={1}>
                            {!!refsReplacement?.length && <ReferenceGroup references={refsReplacement} label={translateText(685)} />}
                            {!!refsReplaced?.length && <ReferenceGroup references={refsReplaced} label={translateText(686)} />}
                        </Stack>
                    )}
                    {(!!textInformation?.length || !!links?.length) && (
                        <Stack display="grid" gridTemplateColumns="1fr 1fr">
                            {!!textInformation?.length && (
                                <Stack gap={0.5}>
                                    {textInformation?.map(([title, values]) => (
                                        <ContentText key={`text_${title}`} variant="body2">
                                            <Stack display="grid" gap={1} gridTemplateColumns="160px auto">
                                                <Box>{title}</Box>
                                                <Stack direction="column">
                                                    {values.map((value) => (
                                                        <Box key={value}>{value}</Box>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </ContentText>
                                    ))}
                                </Stack>
                            )}
                            {!!links?.length && (
                                <Stack gap={0.5} direction="row" alignItems="flex-start">
                                    {links?.map((link) => (
                                        <Button
                                            key={`link_${link.name}`}
                                            startIcon={link.icon && <Icon name={link.icon} />}
                                            href={link.href}
                                            target="_blank"
                                            size="small"
                                            variant="contained"
                                            color="inherit"
                                        >
                                            {link.name}
                                        </Button>
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Box>
            )}
            {/* {informations?.map((info, index) => {
                return (
                    <Box key={`informationBox_${index}`} my={2}>
                        <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1}>
                            <Icon name={info.icon ?? "info"} />
                            <Typography ml={1} variant="label" textTransform="uppercase">
                                {info.title}
                            </Typography>
                            <Box ml="8px" flex={1}>
                                <Divider light />
                            </Box>
                        </Stack>

                        <Stack spacing="4px" ml="29px">
                            {info.content?.map((entry, index) => (
                                <ContentText key={`informationEntry_${info.type}_${index}`} variant="body2">
                                    {entry}
                                </ContentText>
                            ))}
                            {info.links && (
                                <Stack direction="row" spacing={1}>
                                    {info.links.map((link, index) => (
                                        <Button
                                            key={`externalInformation_${info.type}-${index}`}
                                            startIcon={<Icon name="external-link" />}
                                            href={link.href}
                                            target="_blank"
                                            size="small"
                                            variant="contained"
                                            color="inherit"
                                        >
                                            {link.name}
                                        </Button>
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                )
            })} */}
        </>
    )
}
