import { Box, Image, Tooltip, getContrastText, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCallback, useState } from "react"
import { useArticleListActions } from "../../ArticleListActions"
import { usePartsModuleState } from "../../PartsModuleState"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

const SupplierBox = styled(Box, { shouldForwardProp: (prop: string) => !["selected", "showLogo"].includes(prop) })<{
    selected?: boolean
    showLogo?: boolean
}>(({ theme, selected, onClick, showLogo }) => ({
    ...(!showLogo && {
        maxHeight: 40,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "2",
        borderRadius: theme.radius?.default,
        fontFamily: "Roboto Condensed",
        padding: 2,
        ...(selected && {
            padding: "2px 4px",
            backgroundColor: theme.palette.primary.main,
            color: getContrastText(theme.palette.primary.main),
            "&:hover": {
                color: getContrastText(theme.palette.primary.main),
            },
        }),
    }),
    ...(!!onClick && {
        cursor: "pointer",
    }),
}))

const SupplierImage = styled(Image)({
    maxWidth: 80,
    maxHeight: 32,
})

export function Supplier() {
    const { translateText } = useLocalization()

    const supplierFilters = usePartsModuleState((x) => x.filters.dataSupplierFilters)
    const { supplier, supplierLogo } = useDefaultArticleItemState((x) => x.displayArticle.supplier)
    const { toggleSupplier } = useArticleListActions()

    const [error, setError] = useState(false)

    const handleImageError = useCallback(() => {
        setError(true)
    }, [])

    const handleClick = useCallback(() => {
        toggleSupplier(supplier.id, true)
    }, [supplier.id, toggleSupplier])

    const showLogo = !error && !!supplierLogo
    const selected = supplierFilters.find((x) => x[0].id === supplier.id)?.[1] ?? false

    return (
        <Tooltip title={`${translateText(1048)}: ${supplier.name}`}>
            <SupplierBox onClick={handleClick} selected={selected} showLogo={showLogo}>
                {showLogo ? <SupplierImage onError={handleImageError} src={supplierLogo} loading="lazy" /> : supplier.name}
            </SupplierBox>
        </Tooltip>
    )
}
