import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Button, Checkbox, Divider, Icon, LoadingContainer, Menu, MenuItem, Stack, TextField, Typography, styled } from "@tm/components"
import { IconButton } from "../IconButton"
import { WatchListParms } from "../../models"

const CustomMenuEntry = styled(MenuItem)({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "6px",
})

export function Watchlist(props: WatchListParms) {
    const { isInWatchList, watchLists: initialWatchList, onToggleWatchList, onCreateWatchList, isToggling, isLoading, isCreating } = props
    const { translateText } = useLocalization()
    const [creationMode, setCreationMode] = useState(false)
    const [newListName, setNewListName] = useState("")
    const [watchLists, setWatchLists] = useState(initialWatchList)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setCreationMode(false)
        setAnchorEl(null)
    }

    const createWatchlist = () => {
        onCreateWatchList(newListName)
        setNewListName("")
        setCreationMode(false)
    }

    useEffect(() => {
        // Only set the watchlist when we have the new watchlist available
        if (initialWatchList?.length > 0) {
            setWatchLists(initialWatchList)
        }
    }, [initialWatchList])

    return (
        <>
            <IconButton onClick={handleClick}>
                <Icon name={isInWatchList ? "filled-star" : "vote-star"} />
            </IconButton>
            <Menu
                sx={{ maxHeight: "600px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <LoadingContainer isLoading={isToggling || isLoading || isCreating}>
                    {watchLists.map((listEntry) => {
                        return (
                            <CustomMenuEntry key={listEntry.id} onClick={() => onToggleWatchList(listEntry.id)}>
                                <Checkbox size="small" checked={listEntry.selected} onClick={() => onToggleWatchList(listEntry.id)} />
                                {listEntry.name}
                            </CustomMenuEntry>
                        )
                    })}
                </LoadingContainer>
                <Divider />
                {creationMode ? (
                    <MenuItem disableRipple onKeyDown={(e) => e.stopPropagation()}>
                        <Stack direction="row" spacing="4px">
                            <TextField
                                disabled={isCreating}
                                sx={{ marginTop: 0 }}
                                onChange={(e) => setNewListName(e.currentTarget.value)}
                                value={newListName}
                            />
                            <Button disabled={isCreating} onClick={handleClose} startIcon={<Icon name="cancel-x" />} />
                            <Button disabled={isCreating} onClick={createWatchlist} startIcon={<Icon name="check" />} color="success" />
                        </Stack>
                    </MenuItem>
                ) : (
                    <MenuItem>
                        <Box onClick={() => setCreationMode(true)} display="flex">
                            <Icon name="plus" sx={{ marginRight: "4px" }} /> <Typography>{translateText(1242)}</Typography>
                        </Box>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}
