import { IMicros } from "@tm/models"
import { useCallback } from "react"
import { ReplaceButton } from "../../components/calculation/components/_shared"
import { bundleChannel } from "../../data/helpers/bundleChannel"

type Props = IMicros["dmg-dat"]["replace-button"]

export default function ReplaceButtonMicro({ part }: Props) {
    const handleOnClick = useCallback(() => {
        bundleChannel().publish("REPLACE_PART", { part })
    }, [part])

    return <ReplaceButton onClick={handleOnClick} />
}
