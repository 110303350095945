import { OE, OePart } from "@tm/models"

export function mapOePart(part: OE.OePart, quantity: number, hidePrice?: boolean): OePart {
    return {
        additionalDescription: part.additionalDescription,
        vehicleManufacturerId: part.manufacturerId,
        vehicleManufacturerName: part.manufacturerName?.length ? part.manufacturerName : "OE", // vehicleManufacturerName cannot be undefined or empty
        description: part.description,
        oeArticleNumber: part.number ?? part.traderNumber,
        quantityValue: quantity,
        oePriceValue: !hidePrice && part.prices?.length ? part.prices[0].value : undefined,
        currencyCode: !hidePrice && part.prices?.length ? part.prices[0].currency : "",
        wholesalerArticleNumber: part.traderNumber,
    }
}
