import { useMicro } from "@tm/morpheus"
import { VoucherType, IMicros } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Collapsible } from "@tm/controls"
import { Box, Divider, Typography, Paper, Loader, Stack } from "@tm/components"
import VoucherHeader from "../_shared/VoucherDetailsHeader"
import VoucherTotals from "./components/CostEstimationTotals"
import OfferDetails from "./components/OfferDetails"
import CustomerOverview from "./components/CustomerOverview"
import { useCostEstimationDetails } from "../../../../business/hooks/useCostEstimationDetails"
import { useCostEstimationActionButtonsConnection } from "../../../../business/hooks/action-buttons-connection"
import Parts from "./components/parts"
import Works from "./components/works"
import CostEstimationTotalNumbers from "./components/CostEstimationTotalNumbers"
import VehilceImage from "./components/VehicleImage"
import OfferNote from "./components/OfferNote"

type Props = {
    voucherId: string | undefined
    isBigScreen: boolean
}

export default function CostEstimationDetails({ voucherId, isBigScreen }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { costEstimationDetails, costEstimationDetailsLoading, toggleSelected, selectAll, unselectAll, selectedIds } =
        useCostEstimationDetails(voucherId)
    useCostEstimationActionButtonsConnection(costEstimationDetails)

    if (costEstimationDetailsLoading) {
        return <Loader />
    }
    if (!costEstimationDetails || !costEstimationDetails.totals) {
        return <Typography>{translateText(323)}</Typography>
    }

    const { totals, totalNumbers, calculatedParts, calculatedWorks, repairTimeDivision, vehicle, workSchedule, validityPeriod, memo } =
        costEstimationDetails

    function renderCostEstimationTotalNumbers() {
        return (
            <CostEstimationTotalNumbers partsPositionCount={totalNumbers.partsPositionCount} worksPositionCount={totalNumbers.worksPositionCount} />
        )
    }

    const registrationDate = costEstimationDetails.vehicle?.initialRegistration
        ? new Date(costEstimationDetails.vehicle.initialRegistration)
        : undefined

    return (
        <Stack spacing={2} divider={<Divider light />}>
            <VoucherHeader
                currencyCode={totals.currencyCode || "EUR"}
                currencySymbol={totals.currencySymbol || "€"}
                orderDate={costEstimationDetails.creationDate}
                voucherNumber={costEstimationDetails.voucherNumber}
                totalRetailPrice={totals.totalQuotationPriceVatIncluded}
                type={VoucherType.CostEstimation}
            />
            <CustomerOverview customer={costEstimationDetails.customer} repairShopLogo={costEstimationDetails.repairShopLogo} />
            <Paper>
                <Collapsible
                    name={costEstimationDetails.vehicle?.displayName || translateText(99)}
                    initiallyOpened
                    renderHeaderAppendix={renderCostEstimationTotalNumbers}
                >
                    <Box px={2} pb={2}>
                        {renderMicro("crm", "vehicle-overview", {
                            readOnly: true,
                            description: costEstimationDetails.vehicle?.displayName || "",
                            plateId: costEstimationDetails.vehicle?.plateId || "",
                            initialRegistration: registrationDate,
                            mileAge: costEstimationDetails.vehicle?.mileage || 0,
                            vin: costEstimationDetails.vehicle?.vin || "",
                        })}
                        {vehicle?.vehicleImage && <VehilceImage vehicleImage={vehicle.vehicleImage} />}
                        <Parts
                            voucherId={voucherId}
                            calculatedParts={calculatedParts}
                            selectedIds={selectedIds}
                            totals={totals}
                            vehicleId={vehicle?.id}
                            isBigScreen={isBigScreen}
                            onSelectAll={selectAll}
                            onSelectPart={toggleSelected}
                            onUnselectAll={unselectAll}
                        />
                        <Works calculatedWorks={calculatedWorks} repairTimeDivision={repairTimeDivision} totals={totals} />
                    </Box>
                </Collapsible>
            </Paper>
            <VoucherTotals costEstimationTotals={totals} />
            <OfferDetails workSchedule={workSchedule} validityPeriod={validityPeriod} />
            <OfferNote note={memo} />
        </Stack>
    )
}
