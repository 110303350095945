import { Article } from "@tm/models"
import { useQuery } from "react-query"
import { Repositories } from "../../../data"

const ARTICLE_IMAGES_QUERY = "ARTICLE_IMAGES_QUERY"

export function useArticleImages(article: Article) {
    return useQuery({
        queryKey: [ARTICLE_IMAGES_QUERY, article.internalId],
        queryFn: () => Repositories.getArticleImages(article.internalId, article.vehicleLinkageId),
        enabled: !!article,
    })
}
