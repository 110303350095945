import { parseInt } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import { useArticleListSorting } from "../../hooks/useArticleListSorting"
import { useArticleSelectionActions } from "../../hooks/useArticleSelection"
import { ListFilter, ListParams, UniversalPartsStartParams } from "../../models"

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): ListParams<UniversalPartsStartParams> {
    const location = useLocation()
    const { searchType } = useParams<{ searchType: "uninode" | "unisearch" | "uniproductgroups" }>()

    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    const [attributes, setAttributes] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    const createStartParams = useCallback(() => {
        const searchParams = new URLSearchParams(location.search)
        let newStartParams: UniversalPartsStartParams = {
            type: "unisearch",
            query: "",
        }

        switch (searchType) {
            case "uninode": {
                const treeId = searchParams.get("treeId")
                const nodeId = searchParams.get("nodeId")
                if (!treeId || !nodeId) {
                    break
                }
                newStartParams = {
                    type: searchType,
                    treeId: parseInt(treeId),
                    nodeId: parseInt(nodeId),
                }
                break
            }
            case "uniproductgroups": {
                const productGroupIds = searchParams.get("productGroupIds")
                if (!productGroupIds) {
                    break
                }
                newStartParams = {
                    type: searchType,
                    productGroupIds: productGroupIds
                        .split(",")
                        .map((x) => parseInt(x))
                        .filter((x) => !Number.isNaN(x)),
                }
                break
            }
            case "unisearch":
            default: {
                const query = searchParams.get("query")
                if (!query) {
                    break
                }
                newStartParams = {
                    type: searchType,
                    query,
                }
                break
            }
        }
        return newStartParams
    }, [searchType, location.search])

    const [startParams, setStartParams] = useState(createStartParams)

    useEffect(
        function recreateStartParams() {
            setStartParams(createStartParams)
        },
        [createStartParams]
    )
    useEffect(
        function resetOnStartParamsChanged() {
            deselectAll()
            setAttributes((prev) => (prev.length ? [] : prev))
            setProductGroups((prev) => (prev.length ? [] : prev))
            setSuppliers((prev) => (prev.length ? [] : prev))
            setExtendedAssortment(false)
            clearSorting()
        },
        [deselectAll, startParams, clearSorting]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)
            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }
            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)
            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }
            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback((attribute: ListFilter) => {
        setAttributes((state) => {
            const existingIndex = state.findIndex((attr) => attr.id === attribute.id && attr.value === attribute.value)
            if (existingIndex === -1) {
                return [...state, attribute]
            }
            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes,
        showAvailable,
        extendedAssortment,
        noResult,
        setProductGroups,
        setSuppliers,
        setAttributes,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setNoResult,
        nextPage,
        sorting,
    }
}
