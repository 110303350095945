import { UpdateRepairShopRequest, useUser } from "@tm/context-distribution"
import { useCallback, useState } from "react"
import { errorStoreActions } from "../lib/state"

type Props = {
    translateText: (key: string | number) => string
    onSuccess: (request: UpdateRepairShopRequest) => void
}

export function useUpdateRepairShopContact({ translateText, onSuccess }: Props) {
    const { updateRepairShop } = useUser() ?? {}

    const [isLoading, setIsLoading] = useState(false)

    const updateContactDetails = useCallback(
        (request: UpdateRepairShopRequest) => {
            updateRepairShop?.(request)
                .then(() => {
                    onSuccess(request)
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (error) {
                        errorStoreActions.setError({ isError: true, message: translateText(787) })
                    }

                    setIsLoading(false)
                })
        },
        [onSuccess, updateRepairShop, translateText]
    )

    return {
        updateContactDetails,
        isLoading,
    }
}
