export enum EdsQueryParams {
    Vin = "vin",
}

export enum NavigationLinksEnum {
    GetGroups = "getGroups",
    GetNavigationTree = "getNavigationTree",
    GetVehicleInfo = "getVehicleInfo",
    GetGroupParts = "getGroupParts",
    GetUnits = "getUnits",
    GetUnitInfo = "getUnitInfo",
    GetUnitParts = "getUnitParts",
}

export type ErrorDto = {
    message: string
    requestId: string
}

export type LinkDto = {
    action: string
    label: string
    token: string
}

export type TokenRequest = {
    token: string
}

export enum NavigationDirection {
    Right = 1,
    Left = -1,
}

export type ResponseError = {
    message: string
}

export enum EdsErrorMessageIds {
    ModuleNotActive = 13415,
    VehicleNotFound = 13397,
}
