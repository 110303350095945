import { useState } from "react"
import { Box, Divider, Typography, styled } from "@tm/components"
import { useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { SelectedCalcStateSelector } from "../../../data/helpers"
import { CalcInput, CalculationContext } from "../../../data/model"
import { BorderedCollapsible } from "../../_shared"
import HeaderActionBox from "./HeaderActionBox"
import { MainActions } from "../../main/business"

export default function Header() {
    const { selectedCalcState } = useSelector(SelectedCalcStateSelector)
    const {
        definedVehicleTypeLabel,
        definedMaintenanceSystemsLabel,
        definedMaintenanceIntervalsLabel,
        changeMaintenanceIntervalInput,
        changeMaintenanceSystemInput,
        changeVehicleTypeInput,
        selectedServices,
    } = selectedCalcState?.context as CalculationContext
    const { translateText } = useLocalization()
    const [opened, setOpened] = useState(false)
    const dispatch = useDispatch()

    if (!definedVehicleTypeLabel) {
        return null
    }

    function renderHeaderAppendix() {
        if (opened) {
            return null
        }

        return (
            <Box display="flex" alignItems="center" sx={{ userSelect: "none" }}>
                <Typography variant="body3" fontWeight="600">
                    {definedVehicleTypeLabel}
                </Typography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {definedMaintenanceSystemsLabel}
                </LeftBorderedTypography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {definedMaintenanceIntervalsLabel}
                </LeftBorderedTypography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {translateText(12539)}
                </LeftBorderedTypography>
            </Box>
        )
    }

    function handleButtonClick(input: CalcInput) {
        dispatch(MainActions.handleInputSubmit(input))
    }

    return (
        <StyledCollapsible name={translateText(13476)} renderHeaderAppendix={renderHeaderAppendix} onChangeOpen={setOpened}>
            <Box padding="0 16px">
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="2em">
                    <HeaderActionBox
                        title={translateText(99)}
                        buttonIcon="edit_change"
                        onButtonClick={() => handleButtonClick(changeVehicleTypeInput)}
                    >
                        <Typography margin="auto 0" paddingRight="2em" variant="body3" fontWeight="600">
                            {definedVehicleTypeLabel}
                        </Typography>
                    </HeaderActionBox>
                    <HeaderActionBox
                        title={translateText(689)}
                        buttonIcon="edit_change"
                        onButtonClick={() => handleButtonClick(changeMaintenanceSystemInput)}
                    >
                        <Typography variant="body3" fontWeight="600" margin="auto 0">
                            {definedMaintenanceSystemsLabel}
                        </Typography>
                    </HeaderActionBox>
                    <HeaderActionBox
                        title={translateText(612)}
                        buttonIcon="edit_change"
                        onButtonClick={() => handleButtonClick(changeMaintenanceIntervalInput)}
                    >
                        <Typography variant="body3" fontWeight="600" margin="auto 0">
                            {definedMaintenanceIntervalsLabel}
                        </Typography>
                    </HeaderActionBox>
                </Box>
                <Box padding="9px">
                    <Typography paddingBottom="9px" display="block" textTransform="uppercase" variant="label">
                        {translateText(12539)}
                    </Typography>
                    <Box display="flex" gap="0.5em">
                        {selectedServices?.map((selectedService, index) => (
                            <>
                                {index % 2 === 1 && <Divider sx={{ borderWidth: "1px" }} orientation="vertical" flexItem />}
                                <Typography key={index} variant="h4">
                                    {selectedService}
                                </Typography>
                            </>
                        ))}
                    </Box>
                </Box>
            </Box>
        </StyledCollapsible>
    )
}

const LeftBorderedTypography = styled(Typography)({
    borderLeft: "2px solid #c3c3c3",
    paddingLeft: "0.5em",
    marginLeft: "0.5em",
})

const StyledCollapsible = styled(BorderedCollapsible)({
    ".collapsible__caption": {
        flex: "unset !important",
    },
})
