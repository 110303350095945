import {
    Advertisement,
    Article,
    ArticleAttributes,
    ArticleSearchHitInfo,
    AttributeComparisonModel,
    FittingPosition,
    GetProductGroupTopicIdsResponse,
    OE,
    OrderVoucherSupplierItem,
    ProductGroupFiltersModel,
    RepairTimeProvider,
    RequestArticleListPayload,
    WholesalerArticle,
} from "@tm/models"
import { ActionDispatch } from "@tm/morpheus"
import { clone, equals, uniqueId } from "@tm/utils"

import { AvailabilityFilterType, BundleActionType, FilterType, SearchType } from "../../../business"
import { Models } from "../../../data"
import { DefaultArticleListPageSize, getBundleParams } from "../../../utils"
import * as Actions from "./actions"
import { getArticleUniqueId, getFiltersFromRequestPlayload } from "./helpers"
import { InitialFilters, ListState } from "./model"
import { OeInformationConfiguration } from "../../ListV2/ArticleListConfiguration/useOeInformationConfiguration"

export { Actions, type IActions } from "./actions"
export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "ADVERTISEMENTS_LOADED"; payload: Array<Advertisement> }
    | { type: "ARTICLES_ERROR"; payload: Error }
    | {
          type: "ARTICLES_LOADED"
          payload: {
              result: Array<Article>
              wholesalerParts?: Array<WholesalerArticle>
              paging?: boolean
              withPaging?: boolean
          }
      }
    | { type: "ARTICLES_LOADING"; payload: { paging?: boolean } }
    | { type: "ARTICLE_SEARCH_HIT_INFO_ERROR"; payload: string }
    | { type: "ARTICLE_SEARCH_HIT_INFO_LOADED"; payload: { articleId: string; hitInfos: Array<ArticleSearchHitInfo> } }
    | { type: "ARTICLE_SEARCH_HIT_INFO_LOADING"; payload: string }
    | { type: "CHANGE_ARTICLE_QUANTITY"; payload: { article: Article; quantity: number } }
    | { type: "FAST_CALCULATOR_ALTERNATIVES_LOADED"; payload: Array<Article> }
    | { type: "FAST_CALCULATOR_ALTERNATIVES_LOADING" }
    | { type: "FILTERS_INITIALIZED"; payload: InitialFilters }
    | { type: "LOAD_ARTICLE_LIST"; payload: RequestArticleListPayload }
    | { type: "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED"; payload: boolean }
    | { type: "NEXT_PAGE" }
    | { type: "OE_REPLACEMENTS_LOADING" }
    | { type: "OE_REPLACEMENTS_LOADED"; payload: Array<Models.OeReplacementManufacturer> }
    | { type: "OE_REPLACEMENTS_ERROR" }
    | { type: "OE_INFORMATION_LOADING" }
    | { type: "OE_INFORMATION_LOADED"; payload: Array<Models.OeInformation> }
    | { type: "OE_INFORMATION_ERROR" }
    | { type: "OENS_ARTICLES_ERROR" }
    | { type: "OENS_ARTICLES_LOADED"; payload: Array<Models.OensArticle> }
    | { type: "OENS_ARTICLES_LOADING" }
    | { type: "OE_ATTRIBUTES_LOADING" }
    | { type: "PREVIOUSLY_ORDERED_ARTICLES_LOADED"; payload: Array<OrderVoucherSupplierItem> }
    | { type: "RESET_UNI_SEARCH" }
    | { type: "SET_DONTRESETONUNLOAD"; payload?: boolean }
    | { type: "RESET_STATE" }
    | { type: "SELECT_ARTICLE"; payload: string }
    | {
          type: "SELECT_OE_POSITION"
          payload: {
              position: OE.OePosition
              replacement?: OE.OeNumber
              attributes?: ArticleAttributes[]
              preventPartsReset?: boolean
          }
      }
    | { type: "SELECT_OE_NUMBER"; payload: { position: OE.OePosition; replacement?: OE.OeNumber } }
    | {
          type: "SET_CONFIG_PROPERTIES"
          payload: {
              groupParts: boolean
              standaloneMode: boolean
              compactView: boolean
              publishModuleInfo: boolean
              oeInformationConfiguration: OeInformationConfiguration
          }
      }
    | {
          type: "SET_PARTS_LIST_IMAGE"
          payload: { coordinates?: Array<Models.PartsListImageCoordinate>; image: Models.PartsListImage } | undefined
      }
    | { type: "SET_PRODUCTGROUP_REPAIRTIMES"; payload: { [key: number]: Array<RepairTimeProvider> } }
    | { type: "SET_PRODUCTGROUP_TOPICIDS"; payload: GetProductGroupTopicIdsResponse }
    | { type: "SET_SCROLL_TOP"; payload: number }
    | {
          type: "SET_UNI_NODE_INITIAL_PRODUCT_GROUP_FILTERS"
          payload: { productGroupFilters: Array<Models.ProductGroupFilter>; selectedCriteria?: Array<Models.UniParts.CriterionFilter> }
      }
    | { type: "TOGGLE_SELECT_ALL_ARTICLES"; payload: boolean }
    | { type: "VEHICLE_RECORDS_COMPARED"; payload: Array<AttributeComparisonModel> }
    | { type: "VEHICLE_RECORDS_LOADED"; payload: { [id: number]: ProductGroupFiltersModel } }
    | { type: "VEHICLE_RECORDS_UPDATED" }
    | { type: "ARTICLELIST_LOADING"; payload: boolean }
    | { type: "ARTICLELIST_LOADED"; payload: boolean }

const DEFAULT_STATE: ListState = {
    articleListIsLoading: false,
    searchType: SearchType.NONE,
    initialFilters: undefined,

    usedFilters: {
        productGroupIds: [],
        productGroupOptions: [],
        supplierIds: [],
        oeReferenceNos: [],
        articleAttributes: [],
        fittingPosition: FittingPosition.None,
        extendedAssortment: false,
        availability: AvailabilityFilterType.None,
        uniCriteria: [],
    },

    loadingFilters: FilterType.None,

    filters: {
        productGroups: [],
        suppliers: [],
        constructionYear: [],
        articleAttributes: [],
        uniCriteria: [],
    },

    uniNodeInitialProductGroupFilters: [],

    result: {
        selected: [],
        parts: [],
        wholesalerParts: [],
        page: {
            index: 1,
            size: DefaultArticleListPageSize,
        },
        hitInfo: {},
        hitInfoLoading: [],
        previouslyOrderedArticles: [],
        vehicleRecordsComparisons: [],
        productGroupTopicIds: {},
    },

    repairTimeAvailabilities: {},
    showArticleImages: true,
    vehicleRecords: {},
    attributeUpdated: false,
    maxArticleNumberToCompareReached: false,
    oeInformationConfiguration: {
        enableOeInformation: false,
    },
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): ListState {
    switch (action.type) {
        case "RESET_STATE": {
            return {
                ...DEFAULT_STATE,
                groupParts: state.groupParts,
                standaloneMode: state.standaloneMode,
                compactView: state.compactView,
                showArticleImages: state.showArticleImages,
            }
        }
        case "SET_DONTRESETONUNLOAD": {
            return {
                ...state,
                dontResetStateOnUnload: action.payload,
            }
        }
        case "SET_CONFIG_PROPERTIES": {
            const { groupParts, standaloneMode, compactView, oeInformationConfiguration } = action.payload

            return {
                ...state,
                groupParts,
                standaloneMode,
                compactView,
                result: {
                    ...state.result,
                    page: {
                        ...state.result.page,
                        size: getBundleParams().articleListPageSize,
                    },
                },
                oeInformationConfiguration,
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                usedFilters: {
                    ...state.usedFilters,
                    vehicle: action.payload,
                },
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customer: action.payload,
            }
        }
        case "SET_SEARCHTYPE": {
            const { oeReplacements, oeInformation } = DEFAULT_STATE

            return {
                ...state,
                searchType: action.payload,
                initialFilters: undefined,
                oeReplacements,
                oeInformation,
            }
        }
        case "FILTERS_INITIALIZED": {
            const {
                category,
                query,
                productGroupIds,
                oeReferenceNos,
                traderArticleNos,
                articleForDbAlternatives,
                fittingPosition,
                partsList,
                accessoryList,
                articles,
                foundBySearchTerm,
                foundByVehicleId,
                articleIdentifier,
                articleAttributes,
                oePositions,
                fastCalculator,
                offers,
                retryArticleIdentifierSearchWithoutProductGroup,
                hideOePrice,
            } = action.payload

            const selectedOePosition = oePositions?.find((x) => x.isSelected)
            const selectedOeReplacement = selectedOePosition?.replacements?.find((x) => x.isSelected)

            return {
                ...state,
                initialFilters: action.payload,
                usedFilters: {
                    ...state.usedFilters,
                    category,
                    query,
                    productGroupIds: productGroupIds || [],
                    oeReferenceNos: oeReferenceNos || [],
                    traderArticleNos: traderArticleNos && {
                        ...traderArticleNos,
                        wholesalerParts: traderArticleNos.wholesalerParts?.map((part) => ({
                            ...part,
                            itemId: part.itemId || uniqueId(),
                        })),
                    },
                    articleForDbAlternatives,
                    partsList,
                    accessoryList,
                    articles,
                    articleAttributes: (articleAttributes?.map((x) => (typeof x === "string" ? x : x.query)) as Array<string> | undefined) ?? [],
                    fittingPosition: fittingPosition || FittingPosition.None,
                    extendedAssortment: false,
                    availability: AvailabilityFilterType.None,
                    uniCriteria: [],
                    foundBySearchTerm,
                    foundByVehicleId,
                    articleIdentifier,
                    retryArticleIdentifierSearchWithoutProductGroup,
                    constructionYear: undefined,
                    offers,
                },
                result: {
                    ...DEFAULT_STATE.result,
                    page: {
                        ...DEFAULT_STATE.result.page,
                        size: getBundleParams().articleListPageSize,
                    },
                },
                oeResult: oePositions
                    ? {
                          loading: false,
                          positions: oePositions,
                          parts:
                              (selectedOeReplacement
                                  ? selectedOeReplacement.parts?.map((x) => ({ ...x, id: x.id ?? uniqueId() }))
                                  : selectedOePosition?.parts?.map((x) => ({ ...x, id: x.id ?? uniqueId() }))) ?? [],
                      }
                    : undefined,
                partToReplaceQuantity: action.payload.partToReplaceQuantity,
                fastCalculator: fastCalculator
                    ? {
                          alternatives: fastCalculator.alternatives
                              ? {
                                    request: fastCalculator.alternatives,
                                }
                              : undefined,
                          oeNrs: fastCalculator?.oeNrs,
                          isFastService: fastCalculator?.isFastService,
                      }
                    : undefined,
                hideOePrice,
            }
        }
        case "FILTERS_LOADING": {
            const { productGroups, suppliers, articleAttributes, uniCritera, constructionYear } = action.payload

            // We need the state.searchType check in the next Line because of how the speed in the universal article search is impacted by this.
            // This is a due to new changes on how we are using the loadingState to keep the articleList from rerendering too many times
            state = { ...state, articleListIsLoading: true, filters: { ...state.filters, isLoading: state.searchType !== SearchType.UNINODE } }

            if (productGroups) {
                state.loadingFilters |= FilterType.ProductGroup
            }

            if (suppliers) {
                state.loadingFilters |= FilterType.Supplier
            }

            if (articleAttributes) {
                state.loadingFilters |= FilterType.ArticleAttribute
            }

            if (uniCritera) {
                state.loadingFilters |= FilterType.UniCriterion
            }

            if (constructionYear) {
                state.loadingFilters |= FilterType.ConstructionYear
            }

            return state
        }
        case "FILTERS_LOADED": {
            const { productGroups, suppliers, articleAttributes, uniCriteria, constructionYear, resetFilters, requestedFilters } = action.payload

            state = { ...state, filters: { ...state.filters, isLoading: false } }

            if (productGroups) {
                state.loadingFilters &= ~FilterType.ProductGroup
                state.filters = { ...state.filters, productGroups }
            }

            if (suppliers) {
                state.loadingFilters &= ~FilterType.Supplier
                state.filters = { ...state.filters, suppliers }
            }

            if (articleAttributes) {
                state.loadingFilters &= ~FilterType.ArticleAttribute
                state.filters = { ...state.filters, articleAttributes }
            }

            if (uniCriteria) {
                state.loadingFilters &= ~FilterType.UniCriterion
                state.filters = { ...state.filters, uniCriteria }
                // find all incomming selected criterias and set them to the usedFilters
                const selected = uniCriteria.filter((uni) => uni.containsSelected)
                const selectedCriteria: Models.UniParts.CriterionFilter[] = []
                if (selected?.length) {
                    selected.forEach((sel) => {
                        sel.criterionFilters.forEach((crit) => {
                            if (crit.isSelected) {
                                selectedCriteria.push(crit)
                            }
                        })
                    })
                }
                state.usedFilters = { ...state.usedFilters, uniCriteria: selectedCriteria }
            }

            if (constructionYear) {
                state.loadingFilters &= ~FilterType.ConstructionYear
                state.filters = { ...state.filters, constructionYear }
            }

            if (resetFilters) {
                state.usedFilters = {
                    ...state.usedFilters,
                    availability: AvailabilityFilterType.None,
                }
            }

            state.filters = {
                ...state.filters,
                requestedFilters,
            }

            return state
        }
        case "FILTERS_ERROR": {
            return {
                ...state,
                loadingFilters: state.loadingFilters & ~FilterType.ProductGroup & ~FilterType.Supplier,
                filters: {
                    ...state.filters,
                    productGroups: [],
                    suppliers: [],
                    isLoading: false,
                },
                error: action.payload,
            }
        }
        case "FILTERS_CHANGED": {
            const {
                productGroupIds,
                supplierIds,
                articleAttributes,
                fittingPosition,
                extendedAssortment,
                uniCriteria,
                productGroupOptions,
                availability,
            } = action.payload

            state = {
                ...state,
                filters: { ...state.filters, isLoading: false },
                result: {
                    ...state.result,
                    page: {
                        ...state.result.page,
                        index: 1,
                    },
                },
            }

            if (productGroupIds) {
                state.usedFilters = { ...state.usedFilters, productGroupIds }

                // // reset article attribute filters on product group change
                // state.filters.articleAttributes = []
                // state.usedFilters.articleAttributes = []
            }

            if (supplierIds) {
                state.usedFilters = { ...state.usedFilters, supplierIds }
            }

            if (articleAttributes) {
                state.usedFilters = { ...state.usedFilters, articleAttributes }
            }

            if (fittingPosition != undefined) {
                state.usedFilters = { ...state.usedFilters, fittingPosition }
            }

            if (extendedAssortment != undefined) {
                state.usedFilters = { ...state.usedFilters, extendedAssortment }
            }

            if (availability != undefined) {
                state.usedFilters = { ...state.usedFilters, availability }
            }

            if (uniCriteria) {
                state.usedFilters = { ...state.usedFilters, uniCriteria }
            }

            if (productGroupOptions) {
                state.usedFilters = { ...state.usedFilters, productGroupOptions }
            }

            if (action.payload.constructionYear === 0) {
                state.usedFilters = { ...state.usedFilters, constructionYear: undefined }
            } else if (action.payload.constructionYear) {
                state.usedFilters = { ...state.usedFilters, constructionYear: action.payload.constructionYear }
            }

            return state
        }
        case "ARTICLES_LOADING": {
            return {
                ...state,
                result: {
                    ...state.result,
                    loading: true,
                    page: {
                        ...state.result.page,
                        loading: action.payload.paging,
                    },
                },
                error: undefined,
            }
        }
        case "ARTICLES_LOADED": {
            const { result, paging, withPaging, wholesalerParts } = action.payload

            return {
                ...state,
                result: {
                    ...state.result,
                    loading: false,
                    parts: paging && state.result.parts ? [...state.result.parts, ...result] : result,
                    wholesalerParts:
                        paging && state.result.wholesalerParts && wholesalerParts
                            ? [...state.result.wholesalerParts, ...wholesalerParts]
                            : wholesalerParts,
                    page: {
                        ...state.result.page,
                        loading: false,
                        endOfList: withPaging === true ? false : withPaging === false || result.length < state.result.page.size,
                    },
                },
                error: undefined,
            }
        }
        case "ARTICLES_ERROR": {
            return {
                ...state,
                result: {
                    ...state.result,
                    loading: false,
                    page: {
                        ...state.result.page,
                        loading: false,
                    },
                },
                error: action.payload,
            }
        }
        case "NEXT_PAGE": {
            return {
                ...state,
                loadingNextPage: true,
                result: {
                    ...state.result,
                    page: {
                        ...state.result.page,
                        index: state.result.page.index + 1,
                    },
                },
            }
        }
        case "VEHICLE_RECORDS_COMPARED": {
            return {
                ...state,
                result: {
                    ...state.result,
                    vehicleRecordsComparisons: action.payload,
                },
            }
        }
        case "SELECT_ARTICLE": {
            return {
                ...state,
                result: {
                    ...state.result,
                    selected:
                        state.result.selected.indexOf(action.payload) === -1
                            ? [...state.result.selected, action.payload]
                            : state.result.selected.filter((x) => x !== action.payload),
                },
            }
        }
        case "TOGGLE_SELECT_ALL_ARTICLES": {
            return {
                ...state,
                result: {
                    ...state.result,
                    selected: action.payload ? state.result.parts.map(getArticleUniqueId) : [],
                },
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { article, quantity } = action.payload
            const { parts } = state.result

            const newParts = parts.map((x) =>
                x.internalId === article.internalId &&
                x.vehicleLinkageId === article.vehicleLinkageId &&
                x.productGroup.id === article.productGroup.id
                    ? { ...x, quantity }
                    : x
            )

            return {
                ...state,
                result: {
                    ...state.result,
                    parts: newParts,
                },
            }
        }
        case "OENS_ARTICLES_LOADING": {
            return {
                ...state,
                oeResult: {
                    loading: true,
                    parts: [],
                },
            }
        }
        case "OE_ATTRIBUTES_LOADING": {
            return {
                ...state,
                oeResult: {
                    ...state.oeResult,
                    parts: state.oeResult?.parts ?? [],
                    oeAttributesLoading: true,
                },
            }
        }
        case "OENS_ARTICLES_LOADED": {
            return {
                ...state,
                oeResult: {
                    loading: false,
                    parts: action.payload.map((x) => ({
                        id: uniqueId(),
                        number: x.number,
                        traderNumber: x.wholesalerArticleNumber,
                        description: x.description ?? x.descriptionShort ?? "",
                        manufacturerId: x.manufacturer.id,
                        manufacturerName: x.manufacturer.name,
                        thumbnailUrl: x.manufacturer.thumbnail,
                        prices: x.price ? [{ currency: x.currencyCode, value: x.price }] : undefined,
                    })),
                },
            }
        }
        case "OENS_ARTICLES_ERROR": {
            return {
                ...state,
                oeResult: {
                    loading: false,
                    parts: [],
                },
            }
        }
        case "OE_REPLACEMENTS_LOADING": {
            return {
                ...state,
                oeReplacements: {
                    loading: true,
                    manufacturers: state.oeReplacements?.manufacturers ?? [],
                },
            }
        }
        case "OE_REPLACEMENTS_LOADED": {
            return {
                ...state,
                oeReplacements: {
                    loading: false,
                    manufacturers: action.payload,
                },
            }
        }
        case "OE_REPLACEMENTS_ERROR": {
            return {
                ...state,
                oeReplacements: {
                    loading: false,
                    manufacturers: [],
                },
            }
        }
        case "OE_INFORMATION_LOADING": {
            return {
                ...state,
                oeInformation: {
                    loading: true,
                    oeInformationList: state.oeInformation?.oeInformationList ?? [],
                },
            }
        }
        case "OE_INFORMATION_LOADED": {
            return {
                ...state,
                oeInformation: {
                    loading: false,
                    oeInformationList: action.payload,
                },
            }
        }
        case "OE_INFORMATION_ERROR": {
            return {
                ...state,
                oeInformation: {
                    loading: false,
                    oeInformationList: [],
                },
            }
        }
        case "SET_PARTS_LIST_IMAGE": {
            return {
                ...state,
                result: {
                    ...state.result,
                    selected: [],
                    partsListImage: action.payload,
                },
            }
        }
        case "SET_ARTICLE_LIST_COMPACT_VIEW": {
            return {
                ...state,
                compactView: action.payload,
            }
        }
        case "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES": {
            return {
                ...state,
                showArticleImages: action.payload,
            }
        }
        case "SET_UNI_NODE_INITIAL_PRODUCT_GROUP_FILTERS": {
            return {
                ...state,
                uniNodeInitialProductGroupFilters: action.payload.productGroupFilters,
                usedFilters: action.payload.selectedCriteria
                    ? { ...state.usedFilters, uniCriteria: action.payload.selectedCriteria }
                    : state.usedFilters,
            }
        }
        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: { ...state.repairTimeAvailabilities, ...action.payload },
            }
        }

        case "VEHICLE_RECORDS_LOADED": {
            return {
                ...state,
                vehicleRecords: { ...state.vehicleRecords, ...action.payload },
            }
        }
        case "VEHICLE_RECORDS_UPDATED": {
            break
        }
        case "SET_SCROLL_TOP": {
            return {
                ...state,
                scrollTop: action.payload,
            }
        }
        case "ARTICLE_SEARCH_HIT_INFO_LOADING": {
            if (state.result.hitInfoLoading.indexOf(action.payload) === -1) {
                return {
                    ...state,
                    result: {
                        ...state.result,
                        hitInfoLoading: [...state.result.hitInfoLoading, action.payload],
                    },
                }
            }
            break
        }
        case "ARTICLE_SEARCH_HIT_INFO_LOADED": {
            const hitInfo = { ...state.result.hitInfo }
            hitInfo[action.payload.articleId] = action.payload.hitInfos

            return {
                ...state,
                result: {
                    ...state.result,
                    hitInfoLoading: state.result.hitInfoLoading.filter((x) => x !== action.payload.articleId),
                    hitInfo,
                },
            }
        }
        case "ARTICLE_SEARCH_HIT_INFO_ERROR": {
            return {
                ...state,
                result: {
                    ...state.result,
                    hitInfoLoading: state.result.hitInfoLoading.filter((x) => x !== action.payload),
                },
            }
        }
        case "PREVIOUSLY_ORDERED_ARTICLES_LOADED": {
            return {
                ...state,
                result: {
                    ...state.result,
                    previouslyOrderedArticles: action.payload || [],
                },
            }
        }
        case "RESET_UNI_SEARCH": {
            return {
                ...state,
                usedFilters: {
                    ...state.usedFilters,
                    productGroupIds: state.initialFilters?.productGroupIds ?? [],
                    supplierIds: state.initialFilters?.supplierIds ?? [],
                    uniCriteria: [],
                    extendedAssortment: false,
                    availability: AvailabilityFilterType.None,
                },
                uniNodeInitialProductGroupFilters: [],
                result: {
                    ...state.result,
                    loading: true,
                    parts: [],
                    page: {
                        ...state.result.page,
                        index: 1,
                        loading: false,
                        endOfList: false,
                    },
                },
            }
        }
        case "SET_PRODUCTGROUP_TOPICIDS": {
            return {
                ...state,
                result: {
                    ...state.result,
                    productGroupTopicIds: {
                        ...state.result.productGroupTopicIds,
                        ...action.payload,
                    },
                },
            }
        }
        case "SELECT_OE_NUMBER": {
            // TODO: this will be moved inside the component
            if (!state.fastCalculator?.oeNrs) {
                break
            }

            const { replacement, position } = action.payload

            const { oeNrs } = state.fastCalculator

            const oeArticles = oeNrs.map((x) => {
                x = {
                    ...x,
                    isSelected: equals(x, position),
                }
                if (x.replacements) {
                    x.replacements = x.replacements.map((y) => ({
                        ...y,
                        isSelected: equals(y, replacement),
                    }))
                }
                return x
            })

            return {
                ...state,
                fastCalculator: {
                    alternatives: {
                        request: [],
                        loading: false,
                        parts: state.fastCalculator.alternatives?.parts ?? [],
                    },
                    oeNrs: oeArticles,
                },
                oeResult: {
                    ...state.oeResult,
                    parts:
                        replacement?.parts?.map((x) => ({
                            ...x,
                            id: x.id ?? uniqueId(),
                            manufacturerId: x.manufacturerId ?? (x.manufacturerName ? 1 : undefined),
                        })) ?? [],
                },
            }
        }
        case "SELECT_OE_POSITION": {
            // TODO: this will be moved inside the component
            if (!state.oeResult?.positions) {
                break
            }

            let { positions } = state.oeResult

            const isSame = (a: OE.OeNumber, b: OE.OeNumber) => {
                return a.number === b.number && a.description === b.description && a.additionalDescription === b.additionalDescription
            }

            positions = positions.map((position) => {
                position = {
                    ...position,
                    isSelected: isSame(position, action.payload.position),
                    ...(!!action.payload.attributes && {
                        parts: position.parts?.map((x) =>
                            !action.payload.replacement && x.number === action.payload.position?.number
                                ? { ...x, attributes: action.payload.attributes }
                                : x
                        ),
                    }),
                }

                if (position.replacements) {
                    position.replacements = position.replacements.map((replacement) => ({
                        ...replacement,
                        isSelected: position.isSelected && action.payload.replacement ? isSame(replacement, action.payload.replacement) : false,
                        ...(!!action.payload.attributes && {
                            parts: replacement.parts?.map((x) =>
                                x.number === action.payload.replacement?.number ? { ...x, attributes: action.payload.attributes } : x
                            ),
                        }),
                    }))
                }

                return position
            })

            const selectedPosition = positions?.find((x) => x.isSelected)
            const selectedReplacement = selectedPosition?.replacements?.find((x) => x.isSelected)

            // If search type is OE_POSITIONS reset search result and show loader
            if (state.searchType === SearchType.OE_POSITIONS && !action.payload.preventPartsReset) {
                state = {
                    ...state,
                    result: {
                        ...state.result,
                        loading: true,
                        parts: [],
                    },
                    error: undefined,
                }
            }

            return {
                ...state,
                oeResult: {
                    ...state.oeResult,
                    loading: false,
                    oeAttributesLoading: false,
                    positions,
                    parts:
                        (selectedReplacement
                            ? selectedReplacement.parts?.map((x) => ({
                                  ...x,
                                  id: x.id ?? uniqueId(),
                                  manufacturerId: x.manufacturerId ?? (x.manufacturerName ? 1 : undefined),
                              })) // default for manufacturer id if manufacturer name is supplied
                            : selectedPosition?.parts?.map((x) => ({
                                  ...x,
                                  id: x.id ?? uniqueId(),
                                  manufacturerId: x.manufacturerId ?? (x.manufacturerName ? 1 : undefined),
                              }))) ?? // default for manufacturer id if manufacturer name is supplied
                        [],
                },
            }
        }
        case "FAST_CALCULATOR_ALTERNATIVES_LOADING": {
            if (!state.fastCalculator?.alternatives) {
                break
            }

            return {
                ...state,
                fastCalculator: {
                    ...state.fastCalculator,
                    alternatives: {
                        ...state.fastCalculator.alternatives,
                        loading: true,
                        parts: undefined,
                    },
                },
            }
        }
        case "FAST_CALCULATOR_ALTERNATIVES_LOADED": {
            if (!state.fastCalculator?.alternatives) {
                break
            }

            return {
                ...state,
                fastCalculator: {
                    ...state.fastCalculator,
                    alternatives: {
                        ...state.fastCalculator.alternatives,
                        loading: false,
                        parts: action.payload,
                    },
                },
            }
        }
        case "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED": {
            return {
                ...state,
                maxArticleNumberToCompareReached: action.payload,
            }
        }
        case "ADVERTISEMENTS_LOADED": {
            if (!state.usedFilters.offers) {
                break
            }

            return {
                ...state,
                usedFilters: {
                    ...state.usedFilters,
                    offers: {
                        ...state.usedFilters.offers,
                        advertisements: action.payload,
                    },
                },
            }
        }
        case "ARTICLELIST_LOADING": {
            return {
                ...state,
                articleListIsLoading: true,
            }
        }

        case "ARTICLELIST_LOADED": {
            return {
                ...state,
                articleListIsLoading: false,
                loadingNextPage: false,
            }
        }
        default:
            break
    }

    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "FILTERS_CHANGED":
            if (action.wasReceived) {
                return
            }
            return action
        case "SET_SEARCHTYPE":
        case "FILTERS_LOADING":
        case "FILTERS_LOADED":
        case "FILTERS_ERROR":
        case "SET_SECTION_URL":
        case "SET_TAB_INFO":
        case "MODULE_OPENED":
        case "SHOW_SEARCH_HINT":
            return action
        default:
            break
    }
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType, ListState>, getState: () => ListState) {
    switch (action.type) {
        case "LOAD_ARTICLE_LIST":
            dispatch(Actions.setDontResetStateOnUnload(action.payload.dontResetStateOnUnload))
            if (action.payload.node) {
                dispatch(Actions.setSearchType(SearchType.NODE))
            } else if (action.payload.uniNode) {
                dispatch(Actions.setSearchType(SearchType.UNINODE))
            } else if (action.payload.direct) {
                dispatch(Actions.setSearchType(SearchType.DIRECT))
            } else if (action.payload.synonym) {
                dispatch(Actions.setSearchType(SearchType.SYNONYM))
            } else if (action.payload.uniSearch) {
                dispatch(Actions.setSearchType(SearchType.UNISEARCH))
            } else if (action.payload.productGroups) {
                dispatch(Actions.setSearchType(SearchType.PRODUCTGROUPS))
            } else if (action.payload.uniProductGroups) {
                dispatch(Actions.setSearchType(SearchType.UNIPRODUCTGROUPS))
            } else if (action.payload.oeReferences) {
                dispatch(Actions.setSearchType(SearchType.OE))
            } else if (action.payload.traderArticleNos) {
                dispatch(Actions.setSearchType(SearchType.TRADERARTICLENOS))
            } else if (action.payload.partsList) {
                dispatch(Actions.setSearchType(SearchType.PARTSLIST))
            } else if (action.payload.accessoryList) {
                dispatch(Actions.setSearchType(SearchType.ACCESSORYLIST))
            } else if (action.payload.articles && action.payload.articles.length) {
                dispatch(Actions.setSearchType(SearchType.PASSED_IN_ARTICLES))
            } else if (action.payload.articleIdentifier) {
                dispatch(Actions.setSearchType(SearchType.ARTICLE_IDENTIFIER))
            } else if (action.payload.offers) {
                dispatch(Actions.setSearchType(SearchType.OFFERS))
            } else {
                break // not a valid payload
            }

            dispatch(Actions.initializeFilters(getFiltersFromRequestPlayload(action.payload)))
            break
        case "SET_ARTICLE_LIST_COMPACT_VIEW":
        case "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES":
            dispatch(action)
            break
        case "FILTERS_CHANGED":
            const { searchType } = getState()

            // set wasReceived to prevent the action to be transmitted
            dispatch({ ...action, wasReceived: true })

            if (action.payload.availability !== undefined && Object.keys(action.payload).length === 1) {
                break
            }

            if (searchType === SearchType.UNINODE || searchType === SearchType.UNISEARCH || searchType === SearchType.UNIPRODUCTGROUPS) {
                dispatch(Actions.loadUniFilters())
                break
            }

            if (searchType === SearchType.OE_POSITIONS && (action.payload.productGroupIds || action.payload.extendedAssortment != undefined)) {
                const { oeResult: { positions } = {} } = getState()
                const position = positions?.find((x) => x.isSelected)
                const replacement = position?.replacements?.find((x) => x.isSelected)
                const callBack = position || replacement ? Actions.selectOePositionWithAttributes : undefined

                if (position) {
                    dispatch({ type: "SELECT_OE_POSITION", payload: { position, replacement, attributes: [] } })
                    dispatch({ type: "OE_ATTRIBUTES_LOADING" })
                }

                dispatch(Actions.loadFilters(false, callBack))
                break
            }

            // reload filters on change of filter change to show only valid supplier filters
            if (searchType !== SearchType.TRADERARTICLENOS && (action.payload.productGroupIds || action.payload.supplierIds)) {
                dispatch(Actions.loadFilters())
                break
            }

            dispatch(Actions.loadArticles())
            break
        default:
            break
    }
}
