import { Box, Button, Icon, ImageViewer as ImageViewerComponent, Popover, styled } from "@tm/components"
import { Article } from "@tm/models"
import { useArticleImages } from "../hooks/useArticleImages"

const CloseButton = styled(Button)({
    position: "absolute",
    right: 2,
    top: 2,
})
CloseButton.defaultProps = {
    variant: "text",
    startIcon: <Icon name="close" />,
}

type ImageViewerProps = {
    article: Article
    anchorEl?: Element | null
    onClose(): void
}

export function ImageViewer(props: ImageViewerProps) {
    const { article, anchorEl, onClose } = props

    const imagesQuery = useArticleImages(article)

    return (
        <Popover
            open
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            sx={{ ml: 1 }}
        >
            <CloseButton onClick={onClose} />
            <Box width={800} height={480}>
                <ImageViewerComponent images={imagesQuery.data || []} isLoading={imagesQuery.isLoading} />
            </Box>
        </Popover>
    )
}
