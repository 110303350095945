import { Stack } from "@tm/components"
import { notUndefinedOrNull } from "@tm/utils"
import PartsTable from ".."
import { ExtendedVoucherCalculatedPart } from "../../../../../../../../../../../data/model"

type Props = {
    part: ExtendedVoucherCalculatedPart
    isBigScreen: boolean
    onSelectPart(id: string): void
}

export default function PartFooter({ part, isBigScreen, onSelectPart }: Props) {
    if (!part.linkedItems?.length) {
        return null
    }

    return (
        <Stack p={0.5} spacing={1.5} flex={1}>
            <PartsTable
                calculatedParts={
                    part.linkedItems
                        ?.filter((item) => !!item.voucherPartId)
                        ?.map<ExtendedVoucherCalculatedPart>((item) => {
                            return { ...item, isLinkedItem: true, thumbnail: "", id: part.voucherPartId || "" }
                        })
                        .filter(notUndefinedOrNull) || []
                }
                selectedIds={[]}
                isBigScreen={isBigScreen}
                onSelectPart={onSelectPart}
            />
        </Stack>
    )
}
