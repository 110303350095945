import { Typography, TypographyProps, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErpPrice, PriceType } from "@tm/models"
import { mapPriceColor, mapPriceVariant, mapPriceFontWeight } from "../../../hooks/useArticleItem/mapper"

type Props = {
    price: ErpPrice
    variant?: TypographyProps["variant"]
}

export function PriceValue({ price, variant }: Props) {
    const { currency } = useLocalization()

    const theme = useTheme()
    const purchasePriceColor =
        price.type === PriceType.Purchase && theme.overwrites?.components?.partListV2?.articleItem?.erpContainer?.purchasePriceColor

    return (
        <Typography
            textAlign="right"
            color={purchasePriceColor || mapPriceColor(price.type)}
            variant={variant ?? mapPriceVariant(price.type)}
            fontWeight={mapPriceFontWeight(price.type)}
            whiteSpace="nowrap"
            lineHeight={1.2}
            sx={price.type === PriceType.Strikethrough ? { textDecoration: "line-through" } : undefined}
        >
            {currency(price.value, price.currencySymbol)}
        </Typography>
    )
}
