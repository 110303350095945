import { useState } from "react"
import { Box, Button, Dialog, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { LoaderSmall, StackRow } from "../StyledComponents"

type Props = {
    buttonTitleId: number
    dialogTextId: number
    disableButton?: boolean
    ids?: Array<string>
    showDialog?: boolean
    onConfirmRemove(ids?: Array<string>): void
}

export default function DeleteButton(props: Props) {
    const { dialogTextId, ids, buttonTitleId, disableButton, showDialog, onConfirmRemove } = props
    const { translateText } = useLocalization()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(showDialog || false)

    function handleConfirmClick() {
        onConfirmRemove(ids)
        setShowDeleteDialog(false)
    }

    return (
        <>
            <Button
                title={translateText(buttonTitleId)}
                onClick={() => setShowDeleteDialog(true)}
                startIcon={!showDeleteDialog ? <Icon name="delete" /> : undefined}
                disabled={disableButton}
                variant="text"
            >
                {showDeleteDialog && <LoaderSmall />}
            </Button>
            <Dialog open={showDeleteDialog} skin="warning" position="top" fullWidth>
                <StackRow spacing={2}>
                    <Typography>{translateText(dialogTextId)}</Typography>
                    <Box textAlign="center" display="flex" flexWrap="nowrap">
                        <Button variant="contained" onClick={() => setShowDeleteDialog(false)}>
                            {translateText(584)}
                        </Button>
                        <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleConfirmClick}>
                            {translateText(585)}
                        </Button>
                    </Box>
                </StackRow>
            </Dialog>
        </>
    )
}
