import { useState, useRef, useEffect, KeyboardEvent } from "react"
import { ButtonKeyDefinition, FieldValidationState, validateField, ModelState, TmaHelper } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { NumberSelect, Alert, Icon, Typography, Stack, Box } from "@tm/components"
import { Modal, Button, TextField, Switch } from "@tm/controls"
import { DirectBuyState } from "../business"
import SelectArticles from "./SelectArticles"
import { LoaderSmall } from "../../StyledComponents"

type Props = DirectBuyState & {
    disableInputs?: boolean
    showHints?: boolean
    addArticleToBasket(wholesalerArticleNo: string, quantity: number, useEqualArticlesFilter: boolean, eanNumberSearch: boolean): void
    onCloseSelectionModal?: () => void
    onOpenArticlePartsList(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number): void
}

export default function DirectBuyComponent(props: Props) {
    const { showSelectionModal, disableInputs, showHints, articles, message, addToBasketLoading, articleLoading, articleWithPartsList } = props
    const { translateText } = useLocalization()
    const { changeDirectBuyOptions, setUserSetting, userSettings } = useUser() ?? {}
    const [articleNumber, setArticleNumber] = useState<string>("")
    const [quantity, setQuantity] = useState<number>(1)
    const [modelState, setModelState] = useState<ModelState>()
    const articleNumberRef = useRef<TextField>(null)
    const addButtonRef = useRef<Button>(null)
    const [showMessage, setShowMessage] = useState<boolean>(false)

    const { showProductGroupSelection = false } = userSettings?.directBuyOptions.repairShopResponse || {}
    const { eanNumberSearchEnabled = false } = userSettings?.directBuyOptions || {}

    useEffect(() => {
        articleNumberRef.current?.focus()
    }, [])

    useEffect(() => {
        if (message) {
            setShowMessage(true)
        }
    }, [message])

    useEffect(() => {
        if (props.success) {
            setQuantity(1)
        }
        setArticleNumber("")
    }, [props.success])

    useEffect(() => {
        if (articleWithPartsList) {
            const { productGroup, supplierArticleNo, supplier } = articleWithPartsList
            props.onOpenArticlePartsList(productGroup.id, supplierArticleNo, supplier.id)
        }
    }, [articleWithPartsList])

    function addToBasket(quantity: number) {
        if (!articleNumber) {
            articleNumberRef.current?.focus()
        } else if (!articleLoading && !addToBasketLoading) {
            TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("basket", articleNumber)
            props.addArticleToBasket(articleNumber, quantity, !showProductGroupSelection, eanNumberSearchEnabled)
            articleNumberRef.current?.focus()
        }
    }

    function handleArticleNumberKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            setShowMessage(false)
            addToBasket(quantity)
        }
    }

    function handleArticleNumberChange(value: { articleNumber: string }) {
        setArticleNumber(value.articleNumber)
        setShowMessage(false)
    }

    function handleValidationStateChange(field: string, state: FieldValidationState) {
        const newModelState = modelState || {}
        if (state.messages.length) {
            newModelState[field] = state.messages
        } else {
            delete newModelState[field]
        }

        setModelState(newModelState)
    }

    function validateArticleNumber(value: string): FieldValidationState {
        let minChars = 2
        if (value.lastIndexOf("*") === value.length - 1) {
            minChars = 6
        }

        const normalizedValue = value.replace(/[^a-zA-Z0-9*]/g, "") // underscore "_" is not allowed
        const state = validateField(normalizedValue).min(minChars, translateText(1231))
        handleValidationStateChange("articleNumber", state)
        return state
    }

    function handleArticleNumberChangeConfirm(value: { articleNumber: string }) {
        setArticleNumber(value.articleNumber || "")
        setModelState({ articleNumber: value.articleNumber ? validateArticleNumber(value.articleNumber).messages : [] })
    }

    function handleQuantityChange(quantityValue: number) {
        setQuantity(quantityValue)
        addToBasket(quantityValue)
    }

    function handleAddToBasket() {
        addToBasket(quantity)
    }

    function handleCloseAlert() {
        setShowMessage(false)
    }

    function handleProductGroupSelectionChange(state: boolean) {
        changeDirectBuyOptions?.({ showProductGroupSelection: state })
    }

    function handleEanNumberSearchChange(state: boolean) {
        setUserSetting?.("EAN_NUMBER_SEARCH_ENABLED", state)
    }

    function renderModal() {
        return articles ? (
            <Modal className="direct-buy__article-details" onClose={props.onCloseSelectionModal} interruptOutsideClick>
                <SelectArticles articles={articles} quantity={quantity} onOpenArticlePartsList={props.onOpenArticlePartsList} />
            </Modal>
        ) : null
    }

    return (
        <>
            {showSelectionModal && showProductGroupSelection && renderModal()}
            <Stack flex={1}>
                <Typography variant="h3">{translateText(693)}</Typography>
                <Stack spacing={1}>
                    <Stack direction="row" spacing={1} height="45px">
                        <Stack direction="row">
                            <TextField
                                maxLength={50}
                                onKeyDown={handleArticleNumberKeyDown}
                                ref={articleNumberRef}
                                className="tk-basket direct-buy article_number"
                                path={["articleNumber"]}
                                model={{ articleNumber }}
                                onChange={handleArticleNumberChange}
                                onChangeConfirm={handleArticleNumberChangeConfirm}
                                disabled={disableInputs}
                                modelState={modelState}
                                placeholder={eanNumberSearchEnabled ? translateText(12560) : translateText(87)}
                            />
                            <NumberSelect
                                classNameWrapper="amount-field"
                                value={quantity}
                                maxAmount={9999}
                                minAmount={1}
                                onValueChange={handleQuantityChange}
                                disabled={disableInputs}
                            />
                            <Button
                                ref={addButtonRef}
                                className="tk-basket direct-buy add_button"
                                skin="highlight"
                                icon={!addToBasketLoading ? "cart" : undefined}
                                disabled={addToBasketLoading || disableInputs}
                                title={translateText(358)}
                                onClick={handleAddToBasket}
                                scaleIcon
                            >
                                {addToBasketLoading && <LoaderSmall />}
                            </Button>
                        </Stack>
                        <Stack spacing={0.2}>
                            <Switch status={showProductGroupSelection} onChange={handleProductGroupSelectionChange} label={translateText(3142)} />
                            <Switch status={eanNumberSearchEnabled} onChange={handleEanNumberSearchChange} label={translateText(12531)} />
                        </Stack>
                    </Stack>
                    <Box>
                        {showHints && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Icon name="search" />
                                <Typography variant="label">{translateText(1781)}</Typography>
                            </Stack>
                        )}
                        {showMessage && <Alert severity="info" size="small" title={message} onClose={handleCloseAlert} fullwidth={false} />}
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}
