import { styled, Stack, Divider, Typography } from "@tm/components"
import { useMemo } from "react"
import { AttributeValue } from "./AttributeValue"
import { ArticleAttributeParms } from "../../../models"

const AttributeLabel = styled(Typography, { shouldForwardProp: (prop: string) => !["isHighlighted"].includes(prop) })<{ isHighlighted?: boolean }>(
    ({ theme, isHighlighted }) => ({
        fontFamily: "Roboto Condensed", // theme.font?.fontFamily?.secondary,
        whiteSpace: "nowrap",
        paddingTop: 2,
        paddingBottom: 2,
        border: "1px solid transparent",
        borderRadius: theme.radius?.default ?? 3,
        ...(isHighlighted && {
            paddingLeft: 4,
            paddingRight: 4,
            borderColor: theme.palette.highlight.main,
            backgroundColor: theme.palette.highlight.light,
            fontWeight: 600,
        }),
    })
)
AttributeLabel.defaultProps = { variant: "label" }

type AttributeItemElementProps = {
    hasBackground?: boolean
    isHighlighted?: boolean
}

const AttributeItemElement = styled(Stack, {
    shouldForwardProp: (prop: string) => !["hasBackground", "isHighlighted"].includes(prop),
})<AttributeItemElementProps>(({ theme, hasBackground, isHighlighted }) => ({
    padding: "0 6px 0 4px",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",

    ...(isHighlighted && {
        "> div": {
            backgroundColor: theme.palette.highlight.light,
        },
    }),
    ...(hasBackground && {
        backgroundColor: "#f0f0f0",
    }),
}))

AttributeItemElement.defaultProps = {
    direction: "row",
    spacing: "6px",
}

export type AttributeItemProps = {
    hasBackground?: boolean
    hideDivider?: boolean
    hide?: boolean
    attribute: ArticleAttributeParms
    onClickAttributeValue?(id: number, value?: string): void
}

export function AttributeItem(props: AttributeItemProps) {
    const {
        hasBackground,
        hideDivider,
        attribute: { id, values, label },
        onClickAttributeValue,
        hide,
    } = props

    const handleClickValue = useMemo(
        () => (onClickAttributeValue ? (value: string) => onClickAttributeValue(id, value) : undefined),
        [onClickAttributeValue, id]
    )

    const isHighlighted = values?.length === 1 && values.some((x) => !x.value && x.isHighlighted)

    if (hide) {
        return null
    }

    return (
        <AttributeItemElement hasBackground={hasBackground} isHighlighted={isHighlighted}>
            {label && <AttributeLabel isHighlighted={isHighlighted}>{label}</AttributeLabel>}
            {values
                ?.filter((x) => !!x.value)
                .map((value) => (
                    <AttributeValue key={`${value.id}_${value.value}`} attribute={value} onClick={handleClickValue} />
                ))}
            {!hideDivider && <Divider orientation="vertical" flexItem />}
        </AttributeItemElement>
    )
}
