import { useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useRecoilState, useRecoilValue } from "recoil"
import { sortBy } from "lodash"
import { CHECK_DELAY, FILTERS_QUERY } from "."
import { FiltersRequest, FiltersResponse } from "../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../data/repositories/uni-parts"
import { mapDataSupplierFilter, mapProductGroupFilter } from "../../../helpers"
import { ListFilter, ListParams, UniversalPartsStartParams } from "../../../models"
import { ExtendedAssortmentAtom, SelectedAttributeFilterQueriesAtom, SelectedProductGroupIdsAtom, SelectedSupplierIdsAtom } from "../../../states"
import { useFilterStorageKey } from "../../../hooks/useFilterStorageKey"

export function useFiltersByProductGroups(params: ListParams<UniversalPartsStartParams>, isEnabled: boolean) {
    const requestTimerRef = useRef<number>()
    const requestDelay = useRef(0) // First request should be without any delay

    const { startParams } = params
    const storageKey = useFilterStorageKey(startParams)

    const [filtersRequest, setFiltersRequest] = useState<FiltersRequest>()
    const extendedAssortmentEnabled = useRecoilValue(ExtendedAssortmentAtom(storageKey))
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useRecoilState(SelectedProductGroupIdsAtom(storageKey))
    const selectedSupplierIds = useRecoilValue(SelectedSupplierIdsAtom(storageKey))
    const selectedAttributeFilterQueries = useRecoilValue(SelectedAttributeFilterQueriesAtom(storageKey))
    const [loadedFilters, setLoadedFilters] = useState<FiltersResponse>()

    const productGroups = useMemo(() => {
        if (params.startParams.type !== "uniproductgroups") {
            return
        }
        return params.startParams.productGroupIds.map((id) => ({ id, query: id.toString() } as ListFilter))
    }, [params.startParams])

    useEffect(
        function setInitialSelectedProductGroups() {
            if (productGroups?.length) {
                setSelectedProductGroupIds(productGroups.map((x) => x.id))
            }
        },
        [productGroups]
    )

    useEffect(
        function createFiltersRequest() {
            window.clearTimeout(requestTimerRef.current)

            if (!productGroups) {
                setFiltersRequest(undefined)
                return
            }

            const selectedCriteria = sortBy(selectedAttributeFilterQueries).map((x) => ({ query: x } as ListFilter))

            // Set all product groups in request as selected, if no product group is selected
            const newRequest: FiltersRequest = {
                selectedProductGroups: productGroups.map((x) => ({
                    ...mapProductGroupFilter(x),
                    isSelected: selectedProductGroupIds.length === 0 ? true : selectedProductGroupIds.includes(x.id),
                })),
                selectedSuppliers: loadedFilters?.supplierFilters.filter((x) => selectedSupplierIds.includes(x.id)).map(mapDataSupplierFilter) ?? [],
                selectedCriteria,
                extendedAssortment: !!params.extendedAssortment,
            }

            // Time delayed Request
            requestTimerRef.current = window.setTimeout(() => {
                setFiltersRequest(newRequest)
            }, requestDelay.current)

            // Any further request will be delayed (to prevent multiple requests when state changes quickly)
            requestDelay.current = CHECK_DELAY
        },
        [
            productGroups,
            params.extendedAssortment,
            extendedAssortmentEnabled,
            loadedFilters,
            selectedProductGroupIds,
            selectedSupplierIds,
            selectedAttributeFilterQueries,
        ]
    )

    return useQuery({
        queryKey: [FILTERS_QUERY, filtersRequest] as [string, FiltersRequest | undefined],
        queryFn: async ({ queryKey: [, requestFromKey] }) => {
            if (requestFromKey) {
                const response = await PartsRepository.getFiltersBySearchTree(requestFromKey)
                setLoadedFilters(response)
                return response
            }
        },
        enabled: isEnabled && !!filtersRequest,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })
}
