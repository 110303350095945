import { Button, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { style } from "typestyle"

import PartnerSearch from "../partner-search/component"
import { getComponentStyles, getPartnerSearchStyles } from "./styles"

type Props = {
    detailsRoute: string
    height?: number
}

export default function PartnerSearchWidget(props: Props) {
    const { translate } = useLocalization()
    const className = style(getComponentStyles())

    return (
        <Widget
            className={className}
            id="telesales__partner-search-widget"
            height={props.height}
            size="4x2"
            iconName="user"
            title={translate(839)}
            active
        >
            <PartnerSearch styles={getPartnerSearchStyles()} action={<Button linkTo={props.detailsRoute}>{translate(336)}</Button>} />
        </Widget>
    )
}
