import { useCallback, useEffect, useMemo, useRef } from "react"
import { useUser, useWorkTask, getStyleTheme } from "@tm/context-distribution"
import { Widget, WidgetSizes } from "@tm/controls"
import { Button, Typography, Box, SearchtreeTip, styled, SearchtreeTipV2 } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CategoryType, channel, getCurrentWorkTaskId, IMicros, SearchTreeProductGroup, TreeNode, VehicleType } from "@tm/models"
import Morpheus, { connectComponent, useMicro } from "@tm/morpheus"
import { encodeUniqueId, renderRoute, RouteComponentProps, StringDictionary, TmaHelper, uniqueId, useHeighAdjustment, withRouter } from "@tm/utils"
import { useHistory } from "react-router"
import { Repositories } from "../../data"
import { AutosuggestFunction } from "../../data/repositories"
import { getBundleParams } from "../../utils"
import CategorySelection, { NavigationLink } from "../_shared/category-selection"
import DirectSearchField from "../_shared/direct-search-field"
import PartsIndicator from "../_shared/parts-indicator"
import SearchField from "../_shared/search-field"
import Tree from "../_shared/tree"
import { Actions, IActions, TreeConfig, WidgetState } from "./business"
import { getAvailableCategories, getDisabledCategories } from "./helpers"

import SearchTreeWidget from "./components/SearchTreeWidget"
import { UniBestsellerSearchTree } from "../_shared/uni-search-top-products/component"
import useBreadCrumbHandler from "../../hooks/breadCrumbhandler/useBreadCrumbHandler"

type WidgetComponentConfigProps = {
    height?: number
    className?: string
    collapsible?: boolean
    trees: StringDictionary<TreeConfig>
    directSearchText: string
    universalSearchText: string
    calculatorRoute?: string
    tiresSearchText: string
    title?: string
    size: WidgetSizes
    alignHeightToWidgets?: string[]
    autofocusTimeout?: number
    navigationLinks?: NavigationLink[]
}
export type WidgetComponentProps = WidgetComponentConfigProps &
    RouteComponentProps<{ workTaskId?: string }> & {
        state: WidgetState
        actions: IActions
    }

function WidgetComponent(props: WidgetComponentProps) {
    const { translate, translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { workTask } = useWorkTask() || {}
    const history = useHistory()
    const { partsRoutes } = getBundleParams()
    const {
        actions,
        calculatorRoute,
        navigationLinks,
        state: { activeCategory, trees, queryHistory, activeCategoryManuallySelected },
    } = props
    const removeHeightAdjustment = useRef<() => void>()

    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    useEffect(() => {
        return () => {
            removeHeightAdjustment.current?.()
        }
    }, [])

    useEffect(() => {
        actions.loadQueryHistory()
    }, [actions])

    const availableCategories = useMemo(() => getAvailableCategories(props.trees), [props.trees])
    const disabledCategories = useMemo(
        () => getDisabledCategories(workTask?.vehicle, userContext.parameter.catalogLight),
        [workTask?.vehicle, userContext?.parameter?.catalogLight]
    )

    const handleSetActiveCategory = useCallback(
        (category: CategoryType) => {
            if (category === "tiresSearch" && isWM) {
                history.push(
                    renderRoute(partsRoutes?.tyresRoute.summary ?? "", {
                        workTaskId: encodeUniqueId(workTask?.id ?? getCurrentWorkTaskId() ?? uniqueId()),
                    })
                )
            }

            actions.setActiveCategory(workTask?.vehicle, category, true)
        },
        [workTask?.vehicle, actions, isWM]
    )

    useEffect(() => {
        if (
            activeCategory !== "vehicleParts" &&
            !activeCategoryManuallySelected &&
            availableCategories.includes("vehicleParts") &&
            !disabledCategories.includes("vehicleParts")
        ) {
            actions.setActiveCategory(workTask?.vehicle, "vehicleParts", false)
        } else if (!availableCategories.includes(activeCategory)) {
            const match = availableCategories.find((x) => !disabledCategories.includes(x))

            if (match) {
                actions.setActiveCategory(workTask?.vehicle, match, false)
            }
        } else if (activeCategory === "vehicleParts" && !workTask?.vehicle) {
            const nextCategory = availableCategories.find((x) => x !== "vehicleParts")
            nextCategory && actions.setActiveCategory(undefined, nextCategory, false)
        } else if (activeCategory === "predictive" && workTask?.vehicle?.vehicleType !== VehicleType.PassengerCar) {
            const match = availableCategories.first()
            if (match) {
                actions.setActiveCategory(workTask?.vehicle, match, false)
            }
        }
    }, [availableCategories, disabledCategories, activeCategory, workTask?.vehicle, actions, activeCategoryManuallySelected, handleSetActiveCategory])

    useEffect(() => {
        if (!props.trees || !Object.keys(props.trees).length) {
            console.error(`Parts Widget: the 'trees' dictionary was not passed as property in the app config or was empty`)
            return
        }

        actions.initializeTrees(props.trees, workTask?.vehicle)
        actions.loadSearchTreeNodes(workTask?.vehicle)
    }, [props.trees, workTask?.vehicle, workTask?.vehicle?.vehicleType, workTask?.vehicle?.tecDocTypeId, actions])

    useEffect(() => {
        return channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "UNI") {
                handleSetActiveCategory("universalParts")
            } else if (rez.key === "DIRECT") {
                handleSetActiveCategory("directSearch")
            }
        })
    }, [handleSetActiveCategory])

    const handleSearch = (query: string) => {
        if (workTask?.vehicle) {
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.AttachVehicle(workTask?.vehicle)
        }

        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext(activeCategory, query, workTask?.id, true)
        actions.searchArticles(query)
    }

    const handleChangeBreadcrumbs = (breadcrumbs: Array<TreeNode>, thumbnailClicked?: boolean) => {
        actions.changeBreadcrumbs(workTask?.vehicle, breadcrumbs, thumbnailClicked)
    }

    const handleApplyProductGroups = (productGroups: Array<SearchTreeProductGroup>) => {
        actions.applyProductGroups(productGroups, workTask?.vehicle)
    }

    const handleRequestCalculation = (productGroups: Array<SearchTreeProductGroup>) => {
        if (!productGroups.length || !calculatorRoute) {
            return
        }

        Morpheus.showView(
            "1",
            renderRoute(calculatorRoute, {
                ...props.match.params,
                productGroupIds: productGroups.map((x) => x.id).join(","),
            })
        )
    }

    const handleRef = (el: HTMLDivElement | null) => {
        const { alignHeightToWidgets } = props
        if (el && alignHeightToWidgets) {
            removeHeightAdjustment.current = useHeighAdjustment().setHeightAdjustment(el, alignHeightToWidgets)
        }
    }

    const renderSearchField = (
        placeholder: number,
        minLengthForSearch: number,
        getSuggestions?: AutosuggestFunction,
        tooltipText?: string,
        onSearch?: (query: string) => void
    ) => {
        return (
            <SearchField
                placeholder={translateText(placeholder)}
                minLengthForSearch={minLengthForSearch}
                getSuggestions={getSuggestions}
                onSearch={onSearch || handleSearch}
                activeCategory={activeCategory}
                tooltip={tooltipText}
                autofocusTimeout={props.autofocusTimeout}
                autofocus
            />
        )
    }

    const renderVehiclePartsSearch = () => {
        const { vehicle } = workTask || {}

        return renderSearchField(
            998,
            getBundleParams().minimumSearchLength.vehicleSearch,
            vehicle ? (query) => Repositories.getProductGroupSuggestions(query, vehicle.tecDocTypeId) : undefined,
            undefined,
            (query) => {
                handleSearch(query)
            }
        )
    }

    const renderUniSearch = () => {
        return renderSearchField(194, getBundleParams().minimumSearchLength.universalSearch, (query) =>
            Repositories.getUniProductGroupSuggestions(query)
        )
    }

    const renderDirectSearch = () => {
        return (
            <DirectSearchField
                searchfilterResultCounts={[]}
                placeholder={translateText(138)}
                minLengthForSearch={getBundleParams().minimumSearchLength.directSearch}
                onSearch={handleSearch}
                activeCategory={activeCategory}
                tooltip={translateText(1046)}
                autofocusTimeout={props.autofocusTimeout}
                autofocus
            />
        )
    }

    const handleTiresSearch = (query: string) => {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size: query })
    }

    const renderTiresSearch = () => {
        return renderSearchField(1828, getBundleParams().minimumSearchLength.tiresSearch, undefined, undefined, handleTiresSearch)
    }

    const renderCategorySearchField = () => {
        switch (activeCategory) {
            case "vehicleParts":
                return renderVehiclePartsSearch()
            case "universalParts":
                return renderUniSearch()
            case "directSearch":
                return renderDirectSearch()
            case "tiresSearch":
                return renderTiresSearch()
            default:
                return null
        }
    }

    const activeTree = trees[activeCategory]

    // FastCalculator is only available if enabled in the config and the vehicle has data
    const onRequestCalculation = calculatorRoute ? handleRequestCalculation : undefined
    const canRequestCalculation = !!workTask?.vehicle?.dataAvailabilities?.fastCalculator.hasAny

    const hasPredictiveRequirements = !!workTask?.vehicle?.initialRegistration && !!workTask.vehicle.mileAge

    const { hideTipTooltip } = getBundleParams()

    const theme = getStyleTheme()

    const StyledButton = styled(Button)(() => ({
        margin: "0 3px 3px 0",
    }))

    const getSizeOrDefault = (): WidgetSizes => {
        const { size } = props
        const hasSizeFormat = /\d+x\d+/
        if (size && hasSizeFormat.test(size)) {
            return size as WidgetSizes
        }

        return "4x4"
    }

    const hasNewSearchTree = getBundleParams().searchtreeWithBreadcrumbs && activeCategory === "vehicleParts"

    const { breadCrumbState } = useBreadCrumbHandler(undefined, activeCategory, workTask)

    const isSecondLevel = useMemo(() => {
        return breadCrumbState.breadCrumbs.length > 0
    }, [breadCrumbState.breadCrumbs])

    const showTip = useMemo(
        () =>
            !hideTipTooltip &&
            activeTree?.showTip &&
            activeCategory !== "directSearch" &&
            ((activeCategory === "vehicleParts" && workTask?.vehicle?.vehicleType !== VehicleType.CommercialVehicle) ||
                activeCategory === "universalParts"),
        [activeCategory, activeTree?.showTip, hideTipTooltip, workTask?.vehicle?.vehicleType]
    )

    return (
        <Widget
            id="parts__widget"
            className={`tk-parts parts-widget ${props.className || ""} ${activeCategory}`}
            size={getSizeOrDefault()}
            height={props.height}
            collapsible={props.collapsible}
            iconName="catalog"
            title={`${props.title ? translate(props.title) : translate(993)}`}
            active
            forwardedRef={handleRef}
        >
            <div className="upper">
                <div className="search-and-navigation">
                    <CategorySelection
                        hideAreaTitle={hasNewSearchTree}
                        activeCategory={activeCategory}
                        onChangeCategory={handleSetActiveCategory}
                        disabledCategories={disabledCategories}
                        availableCategories={availableCategories}
                        directSearchText={translateText(props.directSearchText || 389)}
                        universalSearchText={translateText(props.universalSearchText || 1009)}
                        tiresSearchText={translateText(props.tiresSearchText || 240)}
                        showPartsIndicatorIcon={hasPredictiveRequirements}
                        navigationLinks={navigationLinks}
                    />

                    {renderCategorySearchField()}
                </div>

                {showTip && (
                    <Box sx={{ position: "absolute", top: 70, right: 20, display: "flex" }}>
                        {hasNewSearchTree ? <SearchtreeTipV2 isSecondLevel={isSecondLevel} /> : <SearchtreeTip />}
                    </Box>
                )}
            </div>

            {activeCategory === "universalParts" && <UniBestsellerSearchTree hide={activeTree?.breadcrumbs?.length > 0} />}

            {activeTree && activeCategory !== "predictive" && (
                <>
                    {hasNewSearchTree ? (
                        <SearchTreeWidget searchTreeType={activeCategory} />
                    ) : (
                        <>
                            <Tree
                                mode={activeTree.mode}
                                treeId={activeTree.treeId}
                                loading={activeTree.loading}
                                groups={activeTree.groups}
                                breadcrumbs={activeTree.breadcrumbs}
                                selectedNode={activeTree.selectedNode}
                                onChangeBreadcrumbs={handleChangeBreadcrumbs}
                                onApplyProductGroups={handleApplyProductGroups}
                                showSecondLevel={activeTree.showSecondLevel}
                                onRequestCalculation={onRequestCalculation}
                                canRequestCalculation={canRequestCalculation}
                                treeContext={activeCategory}
                            />
                        </>
                    )}
                </>
            )}

            {activeCategory === "predictive" && <PartsIndicator calculatorRoute={calculatorRoute} />}

            {activeCategory === "directSearch" && (
                // lower and wrapper are used in the styles project. On some traders a Background image will be added in the styles
                <div className="lower">
                    <div className="wrapper" />

                    {!!queryHistory.length && (
                        <div className="history">
                            <Typography variant="body3" component="div">
                                {translate(1073)}
                            </Typography>
                            {queryHistory.map((query) => {
                                return (
                                    <StyledButton
                                        variant={theme.overwrites?.toolkits?.parts?.widget?.history?.variant ?? "contained"}
                                        size="small"
                                        onClick={() => handleSearch(query)}
                                        key={query}
                                    >
                                        {query}
                                    </StyledButton>
                                )
                            })}
                        </div>
                    )}
                </div>
            )}

            {activeCategory === "eurotaxSearch" && renderMicro?.("eurotax", "search", {})}
            {activeCategory === "tiresSearch" && getBundleParams().showTiresExtendedSearch && renderMicro?.("tyres", "tyres-search", {})}
            {activeCategory === "dtcSearch" && renderMicro?.("fast-dtc", "error-search", {})}
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(WidgetComponent))
