import { Component } from "react"
import { Text, Widget, Button, WidgetSizes } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods, withRouter, renderRoute, encodeUniqueId, uniqueId, RouteComponentProps } from "@tm/utils"
import { CategoryType, VehicleType } from "@tm/models"
import { getBundleParams } from "../../utils"
import CategorySelection from "../_shared/category-selection"
import DirectSearchWidgetContent from "../_shared/direct-search-widget-content"
import UniSearchWidgetContent from "../_shared/uni-search-widget-content"
import { UniSearchWidgetState, IActions, Actions } from "../_shared/uni-search-widget-content/business"

type RouteParams = {
    workTaskId?: string
}

type ConfigParams = {
    universalListRoute: string
    universalSearchTreeLink: string
    universalSearchRoute: string
    directSearchRoute: string
    universalSearchText: string
    directSearchText: string
    tiresSearchText: string
    size?: string
    hideCategories?: Array<CategoryType>
}

type Props = LocalizationProps &
    RouteComponentProps<RouteParams> &
    ConfigParams & {
        state: UniSearchWidgetState
        actions: IActions
        className?: string
    }

type State = {
    activeCategory?: CategoryType
}

class SearchWidgetComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        const hiddenCategories = this.props.hideCategories || []
        const availableCategories = (["vehicleParts", "universalParts", "directSearch"] as Array<CategoryType>).filter(
            (category) => !(hiddenCategories.indexOf(category) >= 0)
        )
        const activeCategory = availableCategories.indexOf("directSearch") >= 0 ? "directSearch" : availableCategories.first()

        this.state = {
            activeCategory,
        }
    }

    componentDidMount() {
        this.props.actions.loadQueryHistory()
    }

    handleTabSelect(activeCategory: CategoryType) {
        this.setState({ activeCategory })
    }

    renderVehiclePartsSearch() {
        const {
            localization: { translate },
            match: { params },
        } = this.props
        const url = renderRoute(getBundleParams().vehiclesRoute, {
            ...params,
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
            vehicleType: VehicleType.PassengerCar, // TODO
        })

        return (
            <>
                <div className="vehicle-parts search-content">
                    <Text modifiers="block">{translate(1002)}</Text>
                </div>
                <Button fakeButton linkTo={url} size="l" skin="highlight">
                    {translate(862)}
                </Button>
            </>
        )
    }

    renderUniSearch() {
        const {
            localization: { translateText },
            match: { params },
            universalSearchTreeLink,
        } = this.props
        const url = renderRoute(universalSearchTreeLink, {
            ...params,
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
        })

        return (
            <>
                <div className="search-content" />
                <Button fakeButton linkTo={url} size="l" skin="highlight">
                    {translateText(1838)}
                </Button>
            </>
        )
    }

    renderContent() {
        const {
            directSearchRoute,
            universalListRoute,
            universalSearchRoute,
            state: { loading, nodes, queryHistory },
        } = this.props

        switch (this.state.activeCategory) {
            case "vehicleParts":
                return this.renderVehiclePartsSearch()
            case "universalParts":
                const treeId = getBundleParams().universalTreeId
                if (!treeId) {
                    return
                }

                return (
                    <>
                        <UniSearchWidgetContent
                            loading={loading}
                            nodes={nodes}
                            universalListRoute={universalListRoute}
                            universalSearchRoute={universalSearchRoute}
                            universalTreeId={treeId}
                            onApplyProductGroups={() => null}
                            onChangeBreadcrumbs={() => null}
                        />
                        {this.renderUniSearch()}
                    </>
                )
            case "directSearch":
                return <DirectSearchWidgetContent directSearchRoute={directSearchRoute} queryHistory={queryHistory} />
            default:
                break
        }
    }

    getSize = (): WidgetSizes => {
        const { size } = this.props
        const hasSizeFormat = /\d+x\d+/
        if (size && hasSizeFormat.test(size)) {
            return size as WidgetSizes
        }

        return "4x2"
    }

    render() {
        if (!this.state.activeCategory) {
            return null
        }

        const { translateText } = this.props.localization
        const className = `tk-parts simple-search-widget ${this.props.className || ""}`
        const hiddenCategories = this.props.hideCategories || []
        const availableCategories = (["vehicleParts", "universalParts", "directSearch"] as Array<CategoryType>).filter(
            (category) => !(hiddenCategories.indexOf(category) >= 0)
        )

        return (
            <Widget id="parts__simple-search-widget" className={className} iconName="catalog" title={translateText(993)} size={this.getSize()} active>
                <CategorySelection
                    activeCategory={this.state.activeCategory}
                    onChangeCategory={this.handleTabSelect}
                    disabledCategories={[]}
                    availableCategories={availableCategories}
                    directSearchText={translateText(this.props.directSearchText || 389)}
                    universalSearchText={translateText(this.props.universalSearchText || 1009)}
                    tiresSearchText={translateText(this.props.tiresSearchText || 240)}
                />
                {this.renderContent()}
            </Widget>
        )
    }
}

export default connectComponent(Actions, withLocalization(withRouter(SearchWidgetComponent)))
