import { RouteComponentProps, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Text, Tooltip } from "@tm/controls"

type Props = RouteComponentProps<void> & {
    url: string
    title?: string
    tooltip?: string
}

function CrmButton({ url, history, title, tooltip }: Props) {
    const { translateText } = useLocalization()

    const isSelected = history.location.pathname === url

    return (
        <Tooltip content={tooltip || translateText(1251)}>
            <div
                className={`global-navigation tab tab--worktask crm-button ${isSelected ? "is-selected" : ""}`}
                onClick={() => {
                    isSelected ? history.goBack() : history.push(url)
                }}
            >
                <div className="tab__content">
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="-3, -5, 32, 32">
                        <path
                            fillRule="evenodd"
                            d="M6.115 2.307V5.53H4.83v15.727H2.254V5.53H0V2.307h6.115zm20.309 0V5.53H24.17v15.727h-2.576V5.53h-1.285V2.307h6.115zm-8.935 8.632l.825 1.65 1.36 2.721v3.383h-1.292a1.294 1.294 0 0 1-2.578.132l-.007-.132h-5.17a1.293 1.293 0 0 1-2.578.132l-.007-.132H6.75V15.31l1.36-2.722.825-1.649h8.554zm.893 5.17h-1.939V17.4h1.939v-1.293zm-3.232 0h-3.877V17.4h3.877v-1.293zm-5.17 0H8.042V17.4H9.98v-1.293zm6.71-3.878H9.734l-.467.934v.001l-.826 1.65h9.541l-.825-1.65-.467-.935zM15.405.216a2.636 2.636 0 0 1 2.97.621l-2.279.983.691 1.604 2.28-.982a2.634 2.634 0 0 1-1.589 2.586 2.632 2.632 0 0 1-2.97-.622l-1.916.826a2.63 2.63 0 0 1-1.588 2.586 2.632 2.632 0 0 1-2.97-.62l2.28-.984-.692-1.603-2.279.982a2.636 2.636 0 0 1 4.558-1.966l1.916-.825A2.637 2.637 0 0 1 15.405.216z"
                        />
                    </svg>
                    {!!title && (
                        <Text size="m" className="startpage-button__title">
                            {/^\d+/.test(title) ? translateText(title) : title}
                        </Text>
                    )}
                </div>
            </div>
        </Tooltip>
    );
}

export default withRouter(CrmButton)
