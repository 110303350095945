import { Article, ArticleAttribute, ArticlePrice, ArticleAttributes, ArticleIdentifier, PartItem, WholesalerPart, OePart } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { ExtendedVoucherCalculatedPart, ExtendedOrderVoucherItem, ReturnVoucherItem } from "../model"

function mapArticleAttributes(data: any): ArticleAttributes | undefined {
    if (!data) {
        return
    }

    const attributes: ArticleAttributes = {
        topAttributes: data.topAttributes || [],
        articleAttributes: data.articleAttributes || [],
        vehicleAttributes: data.vehicleAttributes || [],
        partsListAttributes: data.partsListAttributes || [],
    }

    // Identify duplicated attributes, e.g. multiple engine codes
    const identifyDuplicateAttributes = (attributes: Array<ArticleAttribute>) => {
        let prevAttributeId: number | undefined

        attributes.forEach((x) => {
            if (prevAttributeId === x.id) {
                x.isDuplicatedAttribute = true
            }
            prevAttributeId = x.id
        })
    }

    identifyDuplicateAttributes(attributes.topAttributes)
    identifyDuplicateAttributes(attributes.articleAttributes)
    identifyDuplicateAttributes(attributes.vehicleAttributes)
    identifyDuplicateAttributes(attributes.partsListAttributes)

    return attributes
}

function mapArticlePrice(data: any): ArticlePrice | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
        validTo: data.validTo ? new Date(data.validTo) : undefined,
    }
}

export function mapArticle(data: any): Article | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        id: uniqueId(),
        internalId: data.id,
        information: data.information || [],
        attributes: (data.attributes || []).map(mapArticleAttributes),
        references: data.references || [],
        prices: (data.prices || []).map(mapArticlePrice),
        options: data.options,
        vehicleInformation: data.vehicleInformation || [],
        traderInformation: data.traderInformation || [],
        supplier: data.supplier,
        productGroup: data.productGroup,
        status: data.status,
        packaging: data.packaging,
        quantity: data.quantity,
        initialQuantity: data.quantity,
    }
}

export function mapVoucherCatalogPartToArticleIdentifier(partItems: Array<ExtendedVoucherCalculatedPart>): Array<ArticleIdentifier> {
    const parts: Array<ArticleIdentifier> = []
    partItems.forEach((partItem) => {
        if (partItem.dataSupplier && partItem.articleNumber && partItem.productGroup && partItem.productGroup.id) {
            parts.push({
                supplierId: partItem.dataSupplier.id,
                supplierArticleNo: partItem.articleNumber,
                productGroupId: partItem.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapOrderCatalogPartToArticleIdentifier(partItems: Array<ExtendedOrderVoucherItem>): Array<ArticleIdentifier> {
    const parts: Array<ArticleIdentifier> = []
    partItems.forEach((partItem) => {
        if (partItem.articleInfo.dataSupplier && partItem.articleInfo.dataSupplierArticleNumber && partItem.articleInfo.productGroup?.id) {
            parts.push({
                supplierId: partItem.articleInfo.dataSupplier.id,
                supplierArticleNo: partItem.articleInfo.dataSupplierArticleNumber,
                productGroupId: partItem.articleInfo.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapReturnVoucherItemToArticleIdentifier(partItems: Array<ReturnVoucherItem>): Array<ArticleIdentifier> {
    const parts: Array<ArticleIdentifier> = []
    partItems.forEach((partItem) => {
        if (partItem.dataSupplier && partItem.dataSupplierArticleNumber && partItem.productGroup?.id) {
            parts.push({
                supplierId: partItem.dataSupplier.id,
                supplierArticleNo: partItem.dataSupplierArticleNumber,
                productGroupId: partItem.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapVoucherCalculatedPartToArticle(voucherPart: ExtendedVoucherCalculatedPart): Partial<Article> {
    return {
        id: voucherPart.voucherPartId,
        quantity: voucherPart.quantity.value,
        description: voucherPart.description,
        supplier: {
            id: voucherPart.dataSupplier && voucherPart.dataSupplier.id ? voucherPart.dataSupplier.id : -1,
            name: voucherPart.dataSupplier && voucherPart.dataSupplier.name ? voucherPart.dataSupplier.name : "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: voucherPart.articleNumber,
        traderArticleNo: voucherPart.wholesalerArticleNumber,
        productGroup: {
            id: voucherPart.productGroup && voucherPart.productGroup.id ? voucherPart.productGroup.id : -1,
            name: voucherPart.productGroup && voucherPart.productGroup.name ? voucherPart.productGroup.name : "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: voucherPart.additionalDescription,
        initialQuantity: voucherPart.quantity.value,
        showAddToBasket: true,
        requestErpInfo: !voucherPart.isLinkedItem,
    }
}

export function mapVoucherCalculatedPartToWholesalerPart(voucherPart: ExtendedVoucherCalculatedPart) {
    const part: Partial<WholesalerPart> = {
        quantityValue: voucherPart.quantity.value,
        description: voucherPart.description,
        wholesalerArticleNumber: voucherPart.wholesalerArticleNumber,
        additionalDescription: voucherPart.additionalDescription,
        manufacturerName: voucherPart.wholesalerArticleManufacturerName,
        productGroupName: voucherPart.wholesalerArticleProductGroupName,
    }
    return { part, id: voucherPart.id }
}

export function mapVoucherCalculatedPartToOePart(voucherPart: PartItem): Partial<OePart> {
    return {
        oeArticleNumber: voucherPart.articleNumber,
        description: voucherPart.description,
        additionalDescription: voucherPart.additionalDescription,
        vehicleManufacturerId: voucherPart.oeArticleVehicleManufacturer?.id,
        vehicleManufacturerName: voucherPart.oeArticleVehicleManufacturer?.name,
        quantityValue: voucherPart.quantity.value,
        productGroupId: voucherPart.productGroup?.id,
        currencyCode: voucherPart.currencyCode,
        oePriceValue: voucherPart.cummulatedQuotationPrice ?? voucherPart.quotationPrice,
        wholesalerArticleNumber: voucherPart.wholesalerArticleNumber,
    }
}

export function mapOrderedItemToArticle(orderedItem: ExtendedOrderVoucherItem): Partial<Article> {
    return {
        id: orderedItem.id,
        quantity: orderedItem.confirmedQuantity.value,
        description: orderedItem.articleInfo.description,
        supplier: {
            id: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.id : -1,
            name: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.name : "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: orderedItem.articleInfo.dataSupplierArticleNumber,
        traderArticleNo: orderedItem.articleInfo.wholesalerArticleNumber,
        productGroup: {
            id: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.id : -1,
            name: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.name : "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        thumbnail: orderedItem.articleInfo.thumbnailUrl,
        initialQuantity: orderedItem.confirmedQuantity.value,
        showAddToBasket: true,
        requestErpInfo: !orderedItem.isLinkedItem,
    }
}

export function mapOrderedItemToWholesalerPart(orderedItem: ExtendedOrderVoucherItem) {
    const part: Partial<WholesalerPart> = {
        quantityValue: orderedItem.confirmedQuantity.value,
        description: orderedItem.articleInfo.description,
        wholesalerArticleNumber: orderedItem.articleInfo.wholesalerArticleNumber,
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        manufacturerName: orderedItem.articleInfo.manufacturerName,
        productGroupName: orderedItem.articleInfo.productGroupName,
    }
    return { part, id: orderedItem.id }
}

export function mapOrderedItemToPartToOePart(orderedItem: ExtendedOrderVoucherItem): Partial<OePart> {
    return {
        description: orderedItem.articleInfo.description,
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        oeArticleNumber: orderedItem.articleInfo.oeArticleNumber,
        vehicleManufacturerName: orderedItem.articleInfo.vehicleManufacturer?.name,
        vehicleManufacturerId: orderedItem.articleInfo.vehicleManufacturer?.id,
        quantityValue: orderedItem.confirmedQuantity.value,
        productGroupId: orderedItem.articleInfo.productGroupId,
        oePriceValue: orderedItem.articleInfo.oePrice?.amount,
        currencyCode: orderedItem.articleInfo.oePrice?.currencyCode,
        wholesalerArticleNumber: orderedItem.articleInfo.wholesalerArticleNumber,
    }
}
