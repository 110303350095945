/* eslint-disable jsx-a11y/anchor-is-valid */
import { Headline, Text } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { Link } from "react-router-dom"
import { uniqueId, encodeUniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { b64EncodeUnicode } from "./helper"

type Props = {
    environment: string
}

export default function StartFooterComponent({ environment }: Props) {
    const { userContext } = useUser() || {}
    const { translate } = useLocalization()
    function postMsgOpenModule(e: React.MouseEvent, url: string) {
        e.preventDefault()
        window.parent.postMessage({ openExternalModule: { url } }, "*")
    }

    let baseUrl = ""
    let portalQuery = ""
    let traderParsed = ""
    let imprint = ""
    let className = "logoPanel_wm"
    let privacy = ""
    let termsAndConditions = ""
    let email = ""
    let phoneNumber = ""

    const portalDomain = environment === "test" ? "https://test.wmkat.de" : "https://wmkat.de"

    if (userContext && userContext.externalAuthenticationTokens.WMPortalToken !== undefined) {
        const [trader, custNo, username, pwd] = userContext.externalAuthenticationTokens.WMPortalToken.split("¬")
        traderParsed = trader
        baseUrl = `/external01?url=${escape(`${portalDomain}/Anmelden.aspx?btoaUTF8=`)}`
        portalQuery = `kdnr=${(trader === "trost" ? "T" : "") + custNo}&benutzer=${username}&passwort=${pwd}`
    }

    if (userContext?.system.traderDescription === "WM-NL") {
        className = "logoPanel_wm wm-nl"
    } else if (userContext?.system.traderDescription === "WM-Austria") {
        className = "logoPanel_wm wm-at"
    } else if (userContext?.system.traderDescription === "WMKAT+ DK") {
        className = "logoPanel_wm wm-dk"
    }

    /* imprint */
    if (userContext?.system.traderDescription === "WM-Austria") {
        imprint = `${portalDomain}/IndexImpressum/NextAt`
    } else {
        imprint = `${portalDomain}/IndexImpressum/Next`
    }

    /* privacy */

    if (userContext?.system.traderDescription === "WM-Austria") {
        privacy = `${portalDomain}/IndexDatenschutz/NextAt`
    }
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        privacy = "https://media.carparts-cat.com/webdata6/rdimages/571/1a_Informationen_Internet_dk_V8-da.pdf "
    } else {
        privacy = `${portalDomain}/IndexDatenschutz/Next`
    }

    /* terms and conditions */
    if (userContext.parameter?.urlAGB) {
        termsAndConditions = userContext.parameter.urlAGB
    } else {
        if (userContext?.system.traderDescription === "WM-Austria") {
            termsAndConditions = "https://pdf.wmkat.de/AGB%20AT_2020.pdf"
        }
        if (userContext?.system.traderDescription === "WMKAT+ DK") {
            termsAndConditions = "https://media.carparts-cat.com/webdata6/rdimages/571/Salgs-og_leveringsbetingelser_WM_Autodele.pdf"
        } else {
            termsAndConditions = "https://pdf.wmkat.de/AGB_WM_SE_2017_06.pdf"
        }
    }

    /* email */
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        email = "info@wm-autodele.dk"
    } else {
        email = "wmkat@wm.de"
    }

    /* phoneNumber */
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        phoneNumber = "+45 76 36 35 00"
    } else {
        phoneNumber = "+49 (0)541 9989 - 14530"
    }

    return (
        <div className="bnd-wm start-footer">
            <hr className="topBorder" />
            <div className="inner">
                <div className="col">
                    <div className={className} />
                </div>
                {userContext?.system.traderDescription !== "WM-Austria" && (
                    <div className="col">
                        <Headline size="s">{translate(355)}</Headline>
                        <Text size="s">
                            <a
                                href=""
                                rel="noopener noreferrer"
                                onClick={(e) => postMsgOpenModule(e, "https://www.wm.de/de/wm-kat-newsbereich.html")}
                            >
                                {translate(13198)}
                            </a>
                        </Text>
                        <Text size="s">
                            <a
                                href=""
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                    postMsgOpenModule(
                                        e,
                                        traderParsed === "trost"
                                            ? "https://www.wm.de/de/wmse/newsletter/trost.html"
                                            : "https://www.wm.de/de/wmse/newsletter/wm.html"
                                    )
                                }
                            >
                                {translate(13199)}
                            </a>
                        </Text>
                        <Text size="s">
                            <Link to={baseUrl + b64EncodeUnicode(`${portalQuery}&42=997`)} rel="noopener noreferrer">
                                {translate(1297)}
                            </Link>
                        </Text>
                        <Text size="s">
                            <Link to={baseUrl + b64EncodeUnicode(`${portalQuery}&42=998`)} rel="noopener noreferrer">
                                {translate(1204)}
                            </Link>
                        </Text>
                        <Text size="s">
                            <Link to={`${encodeUniqueId(uniqueId())}/blaetterkataloge`} rel="noopener noreferrer">
                                {translate(12709)}
                            </Link>
                        </Text>
                    </div>
                )}
                <div className="col">
                    <Headline size="s">{translate(13196)}</Headline>
                    <Text size="s">
                        <a href="" rel="noopener noreferrer" onClick={(e) => postMsgOpenModule(e, imprint)}>
                            {translate(1236)}
                        </a>
                    </Text>
                    <Text size="s">
                        <a href="" rel="noopener noreferrer" onClick={(e) => postMsgOpenModule(e, termsAndConditions)}>
                            {translate(831)}
                        </a>
                    </Text>
                    <Text size="s">
                        <a href="" rel="noopener noreferrer" onClick={(e) => postMsgOpenModule(e, privacy)}>
                            {translate(417)}
                        </a>
                    </Text>
                </div>
                <div className="col">
                    <Headline size="s">{translate(13197)}</Headline>
                    <Text size="s">
                        <img
                            className="wmContactsIcon"
                            alt="email"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4yMfEgaZUAAAEMSURBVChTbdFLK6ZhHAbwx2FhFBtWmOwmG3ZKydLKF1A2ko3NKJNSfAZlMTUbho1EWcw3sHNcOKxsaLKRSGLhNPldj1e9U6761XM/3af//y6q8oUf7PHAM39Zpof/8pUTnvjNMEN855BHRinTQH7e0l8ZD9BOZ8Ufsll50iT/GKGDU165IZus0kYW/KLY4Zxa1sn9uxkkG82THLGdj3vWyIJM3iBpIQtmytH7xrv5SDeWaCITFkh6yXiCOq5YoThjixouOeYbud7HlaZ4IU0pfpJTMmmMFJeip9kni+4Yp0ze4JoUlRa2kpYm9XTRWI6q0scFKToNmGOWRQ74NM3kZfNImZSObJKiKymKNzhMRQw2NkcoAAAAAElFTkSuQmCC"
                        />
                        {email}
                    </Text>
                    <Text size="s">
                        <span style={{ float: "left" }}>
                            <img
                                className="wmContactsIcon"
                                alt="phone"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4yMfEgaZUAAACiSURBVChTjdIxDgFBFIfxF1GIEoXSKehVEi6wEoXGBah1onEAbqDQqVzBqlQKlQPoFBrx/TcziexuZnzJr5iX97oxVwsn7LJXpCpSfJwxgg3hl+WMYHP8HqwRbAK/vNEgVhtv6GCpwT9toYMnOhrEauABHV3RhKpgin72ytXDCzq6IcHevWWFQgP4ozIjFOrijvzyBfoRpdUwwwFHLFA3M/sCoQguV7/LK7oAAAAASUVORK5CYII="
                            />
                            {phoneNumber}
                        </span>
                    </Text>
                    {userContext?.system.traderDescription !== "WMKAT+ DK" && (
                        <Text size="s">
                            <img
                                className="wmContactsIcon"
                                alt="printer"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMjHxIGmVAAAA8ElEQVQoU4WSMWrDMBSGY0vavPYUHQ1ZSvDQM2jNmuYALYFSGjJkyBBo6Q1iaAafoFMhs+nYLUvIlNKlSz2E5HsiKkI1ZPiQ9P73IfHsjrVWCUmSfEATUKZpeu9zjxOKotA07GgYaK0LwRhzSe0XJpIL/wQauz481TZw8FIsfMVCnucmy7ILspnsncChhjXsYcqzbiJG1OUW6alF+IHxqXiORgSZSFvYhhPkhjlMoqcMAx7IX2ArwjdUFG9Zn+E9YkHWZ13Bpwhv4AR4ZF9GzAPh1Y2VTQUzxnqllLoOodZDuBPh7ztweILwt2hjaa1VR6+ZbCEjAW+AAAAAAElFTkSuQmCC"
                            />
                            +49&nbsp;(0)541&nbsp;9989&nbsp;-&nbsp;14534
                        </Text>
                    )}
                </div>
            </div>
            <br />
        </div>
    )
}
