import Morpheus from "@tm/morpheus"
import { classes } from "@tm/utils"
import { useStyle } from "@tm/context-distribution"
import { Button, ButtonSkins } from "@tm/controls"

type Props = {
    onClick(): void
    disabled?: boolean
    skin?: ButtonSkins
}

export function ReplaceButton({ onClick, disabled, skin }: Props) {
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    return (
        <Button
            disabled={disabled}
            className={classes("replace", style.button, "add-to-basket__button", isWM ? "wm-replace" : "")}
            icon={isWM ? "wm-logo" : "replace"}
            skin={skin || "highlight"}
            onClick={onClick}
        />
    )
}

const style = useStyle({
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "6em",
    },
})(ReplaceButton)
