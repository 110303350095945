import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { styled, Box, Button, Dialog, Icon, Typography } from "@tm/components"
import { useReplaceUrlTags } from "@tm/context-distribution"
import { encodeUniqueId, renderRoute } from "@tm/utils"

export type VoucherType = "cis" | "external" | "order"
type Props = {
    orderNumber?: string
    orderVoucherId?: string
    voucherUrl?: string
    voucherType: VoucherType
    workTaskId: string
    onOpenVoucherUrl(url: string): void
}

const StyledIframe = styled("iframe")({
    height: "100%",
    width: "100%",
})
export default function VoucherButtonComponent(props: Props) {
    const { translateText } = useLocalization()
    const { voucherUrl, voucherType, orderNumber, orderVoucherId, workTaskId } = props
    const replacedVoucherUrl = useReplaceUrlTags(voucherUrl, { orderId: voucherType === "external" ? orderNumber : orderVoucherId })
    const [showExternalVoucher, setShowExternalVoucher] = useState(false)

    function handleOpenVoucherClick() {
        if (voucherType === "external" && replacedVoucherUrl) {
            setShowExternalVoucher(true)
        } else if (voucherUrl) {
            const openUrlParams = {
                workTaskId: encodeUniqueId(workTaskId),
                id: voucherType === "cis" ? orderNumber : orderVoucherId,
                subId: voucherType === "cis" ? "0" : undefined, // SubId is 0, as requested by STG
            }

            const url = renderRoute(voucherUrl, openUrlParams)
            props.onOpenVoucherUrl(url)
        }
    }

    return (
        <>
            <Button disabled={voucherType === "cis" ? !orderNumber : !orderVoucherId} onClick={handleOpenVoucherClick}>
                {translateText(1163)}
            </Button>
            <Dialog fullWidth maxWidth="md" open={showExternalVoucher} position="top">
                <Box>
                    <Box display="flex" justifyContent="space-between" align-items="center">
                        <Box>
                            <Icon name="voucher" height="1.5em" width="1.5em" />
                            <Typography variant="h4" component="span" pl={2}>
                                {translateText(292)}
                            </Typography>
                        </Box>
                        <Button
                            startIcon={<Icon name="close" height="1.5em" width="1.5em" />}
                            variant="text"
                            onClick={() => setShowExternalVoucher(false)}
                        />
                    </Box>
                    <Box height="100vh" maxHeight="1000px">
                        <StyledIframe src={replacedVoucherUrl} title="External vouchers" />
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
