import { Image, Box } from "@tm/components"
import { useCallback, useMemo, useState } from "react"
import { BonusSystemParams } from "../../../models"

type Props = {
    bonus: BonusSystemParams
}

export function BonusSystemImage({ bonus }: Props) {
    const [useDefaultTheme, setUseDefaultTheme] = useState(false)
    const [trySvgImage, setTrySvgImage] = useState(true)
    const [imageError, setImageError] = useState(false)

    const imageUrl = useMemo(() => {
        let url = bonus.imageUrl

        if (url) {
            if (useDefaultTheme) {
                url = url.replace(/(\/)\d+(\/)/, "$1-1$2")
            }

            if (trySvgImage) {
                url = `${url.substring(0, url.lastIndexOf("."))}.svg`
            }
        }

        return url
    }, [useDefaultTheme, trySvgImage, bonus.imageUrl])

    const handleImageLoadError = useCallback(() => {
        if (trySvgImage) {
            setTrySvgImage(false)
        } else if (!useDefaultTheme) {
            setTrySvgImage(true)
            setUseDefaultTheme(true)
        } else {
            setImageError(true)
        }
    }, [useDefaultTheme, trySvgImage])

    if (imageError || !imageUrl) {
        return null
    }

    return (
        <Box sx={{ width: "30px" }}>
            <Image width="62px" height="30px" src={imageUrl} title={bonus.name} onError={handleImageLoadError} />
        </Box>
    )
}
