

import { VehicleImage } from '@tm/components'
import { css, getStyleTheme, StyleProps, withStyle } from '@tm/context-distribution'
import { Vehicle, VehicleType } from '@tm/models'
import { bem, bindSpecialReactMethods } from '@tm/utils'
import { deg, em, rotateY } from "csx"
import { Component } from 'react';
import { connector } from '../../../data/helpers'
import { MainState } from '../../main'
import { IMainActions, MainActions } from '../../main/business'
import { MatchCodeSearchInput } from '../../_shared'


type Props = StoreProps & StyleProps<typeof stylesheet> & {
    actions: IMainActions
}

type StoreProps = {
    searchValue: string
    vehicle?: Vehicle
}
class SummaryHeader extends Component<Props> {

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleMatchCodeSearch(size: string) {
        const { actions } = this.props
        actions.updateSearchValue(size)
        const params = {
            size,
            season: undefined,
            carType: undefined
        }

        actions.loadTiresList(true)
        actions.changeStep("list", params)
    }


    render() {
        const { searchValue, vehicle, style } = this.props
        const isIcon = vehicle?.vehicleType == VehicleType.CommercialVehicle ||
            vehicle?.vehicleType == VehicleType.Motorcycle
        return (
            <div className={style.header}>
                <MatchCodeSearchInput
                    withTitle
                    value={searchValue}
                    onButtonClick={this.handleMatchCodeSearch}
                // onChange={this.props.actions.updateSearchValue}
                />
                {vehicle?.modelThumbnail &&
                    <VehicleImage
                        modelImage={vehicle.modelThumbnail}
                        modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                        vehicleType={vehicle.vehicleType}
                        className={bem(style.image, isIcon ? "" : "reverse")}
                    />
                }
            </div>
        )
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        header: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.margin.m
        },
        image: {
            width: "auto",
            height: em(3),
            marginLeft: em(7),
            $nest: {
                "&--reverse": {
                    transform: rotateY(deg(180)),
                }
            }
        }
    })
}

function mapStateToProps(state: MainState): StoreProps {
    return {
        searchValue: state.list.searchValue,
        vehicle: state.manager.vehicle
    }
}

export default connector(withStyle(stylesheet, SummaryHeader), mapStateToProps, MainActions)
