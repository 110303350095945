import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { FittingPosition, Vehicle } from "@tm/models"
import { isEqual, sortBy } from "lodash"
import { notUndefinedOrNull } from "@tm/utils"
import { useRecoilState } from "recoil"
import * as PartsRepository from "../../../../data/repositories/parts"
import { DataSupplierFilter, GetProductGroupAndSupplierFiltersRequest, ProductGroupFilter } from "../../../../data/model"
import { FilterActions, FiltersData, ListFilter, ListParams, VehiclePartsStartParams } from "../../models"
import { isPartOf, mapDataSupplierFilter, mapProductGroupFilter } from "../../helpers"
import { AttributeFiltersAtom, SelectedAttributeFilterQueriesAtom, SelectedProductGroupIdsAtom, SelectedSupplierIdsAtom } from "../../states"
import { useFilterStorageKey } from "../../hooks/useFilterStorageKey"

const FILTERS_QUERY = "ARTICLE_VEHICLE_FILTER_QUERY"
const CHECK_DELAY = 500

export function useFilters(
    vehicle: Vehicle | undefined,
    params: ListParams<VehiclePartsStartParams>,
    isEnabled: boolean
): FiltersData & FilterActions {
    const requestTimerRef = useRef<number>()
    const attributeTimerRef = useRef<number>()
    const requestDelay = useRef(0) // First request should be without any delay

    const { startParams, fittingPosition = FittingPosition.None, setFittingPosition } = params

    const storageKey = useFilterStorageKey(startParams)

    const [attributeFilters, setAttributeFilters] = useRecoilState(AttributeFiltersAtom(storageKey))
    const [extendedAssortmentEnabledByUser, setExtendedAssortmentEnabledByUser] = useState(!!params.extendedAssortment)
    const [showOnlyAvailable, setShowOnlyAvailable] = useState(!!params.showAvailable)
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useRecoilState(SelectedProductGroupIdsAtom(storageKey))
    const [selectedDataSupplierIds, setSelectedSupplierIds] = useRecoilState(SelectedSupplierIdsAtom(storageKey))
    const [selectedAttributeFilterQueries, setSelectedAttributeQueries] = useRecoilState(SelectedAttributeFilterQueriesAtom(storageKey))
    const [request, setRequest] = useState<GetProductGroupAndSupplierFiltersRequest>()

    const {
        data: loadedFilters,
        isLoading,
        isSuccess,
        isRefetching,
        remove: clearLoadedFilters,
    } = useQuery({
        queryKey: [FILTERS_QUERY, request] as [string, GetProductGroupAndSupplierFiltersRequest | undefined],
        queryFn: async ({ queryKey: [, requestFromKey] }) => {
            if (requestFromKey) {
                return PartsRepository.getFilters(requestFromKey)
            }
        },
        select(data) {
            if (!data) {
                return data
            }

            /** @todo Sorting should be done by service */
            return {
                ...data,
                productGroupFilters: data.productGroupFilters.orderBy((x) => x.priority ?? Infinity),
                dataSupplierFilters: data.dataSupplierFilters.orderBy((x) => x.priority ?? Infinity),
            }
        },
        enabled: !!isEnabled && !!request,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })

    const extendedAssortmentForced = useMemo<boolean>(() => {
        const anyExtendedProductGroupsIsSelected = loadedFilters?.productGroupFilters.some(
            (x) => !x.hasTopPrioritySuppliers && selectedProductGroupIds.includes(x.id)
        )
        const anyExtendedSupplierIsSelected = loadedFilters?.dataSupplierFilters.some(
            (x) => !x.isTopPriority && selectedDataSupplierIds.includes(x.id)
        )
        const noResult = isSuccess && !loadedFilters?.productGroupFilters.length && !loadedFilters?.dataSupplierFilters.length // TODO: clarify if extended assortment should really be enabled automatically in this case (example: no filter results for current search query)
        const noPriorityProductGroupFilter =
            isSuccess && !!loadedFilters?.productGroupFilters.length && loadedFilters.productGroupFilters.every((x) => !x.hasTopPrioritySuppliers)

        return anyExtendedProductGroupsIsSelected || anyExtendedSupplierIsSelected || noResult || noPriorityProductGroupFilter
    }, [loadedFilters?.productGroupFilters, selectedProductGroupIds, loadedFilters?.dataSupplierFilters, selectedDataSupplierIds, isSuccess])

    useEffect(
        function extendedAssortmentBecauseNoResult() {
            if (!!params.attributes.length && params.noResult && !params.extendedAssortment) {
                setExtendedAssortmentEnabledByUser(true)
            }
        },
        [params.noResult, params.extendedAssortment, params.attributes]
    )

    useEffect(
        function createRequest() {
            window.clearTimeout(requestTimerRef.current)

            if (!vehicle?.tecDocTypeId && ["node", "synonym", "productgroups"].includes(startParams.type)) {
                setRequest(undefined)
                return
            }

            const partialRequest: Partial<GetProductGroupAndSupplierFiltersRequest> = {
                vehicleType: vehicle?.vehicleType,
                modelId: vehicle?.tecDocTypeId,
                selectedDataSupplierIds,
                selectedProductGroupIds,
            }

            let fullRequest: GetProductGroupAndSupplierFiltersRequest

            switch (startParams.type) {
                case "node":
                    fullRequest = {
                        ...partialRequest,
                        treeId: startParams.treeId,
                        nodeId: startParams.nodeId,
                    }
                    break
                case "synonym":
                    fullRequest = {
                        ...partialRequest,
                        query: startParams.query,
                    }
                    break
                case "productgroups":
                    fullRequest = {
                        ...partialRequest,
                        productGroupIds: startParams.productGroupIds,
                    }
                    break
                default: {
                    setRequest(undefined)
                    return
                }
            }

            // Zeitverzögerter Request
            requestTimerRef.current = window.setTimeout(() => {
                setRequest(fullRequest)
            }, requestDelay.current)

            // Any further request will be delayed (to prevent multiple requests when state changes quickly)
            requestDelay.current = CHECK_DELAY
        },
        [startParams, vehicle?.vehicleType, vehicle?.tecDocTypeId, selectedDataSupplierIds, selectedProductGroupIds]
    )

    // Reset if the search params or the selected vehicle have changed
    useEffect(
        function reset() {
            clearLoadedFilters()
            setRequest(undefined)
            setSelectedAttributeQueries((prev) => (prev.length ? [] : prev))
            setSelectedProductGroupIds((prev) => (prev.length ? [] : prev))
            setSelectedSupplierIds((prev) => (prev.length ? [] : prev))
            setAttributeFilters((prev) => (prev.length ? [] : prev))
        },
        [vehicle?.tecDocTypeId, startParams, clearLoadedFilters]
    )

    const extendedAssortmentEnabled = extendedAssortmentEnabledByUser || extendedAssortmentForced
    useEffect(
        function transferSelectedFiltersToParams() {
            if (!loadedFilters) {
                return
            }

            let selectedProductGroupFilters: ProductGroupFilter[]
            if (!selectedProductGroupIds.length) {
                // If no product group is selected, only the "top" ones or, if the extended assortment is activated, all of them should be written to the params
                selectedProductGroupFilters = extendedAssortmentEnabled
                    ? loadedFilters.productGroupFilters
                    : loadedFilters.productGroupFilters.filter((filter) => filter.hasTopPrioritySuppliers)
            } else {
                selectedProductGroupFilters = loadedFilters.productGroupFilters.filter((filter) =>
                    selectedProductGroupIds.some((id) => id === filter.id)
                )
            }

            params.setProductGroups(selectedProductGroupFilters.map(mapProductGroupFilter))

            let selectedSupplierFilters: DataSupplierFilter[]
            if (!selectedDataSupplierIds.length) {
                // If no supplier filter is selected, only the "top" ones or, if the extended assortment is activated, all of them should be written to the params
                selectedSupplierFilters = extendedAssortmentEnabled
                    ? loadedFilters.dataSupplierFilters
                    : loadedFilters.dataSupplierFilters.filter((filter) => filter.isTopPriority)
            } else {
                selectedSupplierFilters = loadedFilters.dataSupplierFilters.filter((filter) => selectedDataSupplierIds.some((id) => id === filter.id))
            }

            params.setSuppliers(selectedSupplierFilters.map(mapDataSupplierFilter))

            params.setExtendedAssortment(extendedAssortmentEnabled)
        },
        [loadedFilters, extendedAssortmentEnabled]
    )

    useEffect(
        function transferAvailabilityToParams() {
            params.setAvailability(showOnlyAvailable)
        },
        [showOnlyAvailable]
    )

    useEffect(
        function checkAndSetAttributes() {
            if (params.attributes.length && !isPartOf(attributeFilters, params.attributes)) {
                params.attributes.forEach((attr) => {
                    if (!attributeFilters.includes(attr)) {
                        setAttributeFilters((state) => [...state, attr])
                    }
                })
            }
            const paramAttributeQueries = params.attributes.map((x) => x.query).filter(notUndefinedOrNull)
            if (!isEqual(sortBy(selectedAttributeFilterQueries), sortBy(paramAttributeQueries))) {
                setSelectedAttributeQueries(paramAttributeQueries)
            }
        },
        [params.attributes]
    )

    useEffect(
        function checkAttributesEqualityAndSetParams() {
            window.clearTimeout(attributeTimerRef.current)

            // Checked, otherwise "checkAndSetAttributes" will be executed and a loop would be produced
            const paramAttributeQueries = params.attributes.map((x) => x.query).filter(notUndefinedOrNull)
            if (!isEqual(sortBy(selectedAttributeFilterQueries), sortBy(paramAttributeQueries))) {
                attributeTimerRef.current = window.setTimeout(
                    () => params.setAttributes(attributeFilters.filter((x) => !!x.query && selectedAttributeFilterQueries.includes(x.query))),
                    CHECK_DELAY
                )
            }
        },
        [selectedAttributeFilterQueries]
    )

    const toggleProductGroup = useCallback((id: number, exclusive?: boolean) => {
        setSelectedProductGroupIds((state) => {
            if (exclusive) {
                if (state.length === 1 && state.includes(id)) {
                    return []
                }
                return [id]
            }

            if (state.includes(id)) {
                return state.filter((x) => x !== id)
            }

            return sortBy([...state, id])
        })
    }, [])

    const toggleSupplier = useCallback((id: number, exclusive?: boolean) => {
        setSelectedSupplierIds((state) => {
            if (exclusive) {
                if (state.length === 1 && state.includes(id)) {
                    return []
                }
                return [id]
            }

            if (state.includes(id)) {
                return state.filter((x) => x !== id)
            }

            return sortBy([...state, id])
        })
    }, [])

    const toggleExtendedAssortment = useCallback(() => {
        setExtendedAssortmentEnabledByUser((state) => !state)
    }, [])

    const toggleAvailability = useCallback(() => {
        setShowOnlyAvailable((state) => !state)
    }, [])

    const toggleAttribute = useCallback((attribute: ListFilter, exclusive?: boolean) => {
        setSelectedAttributeQueries((state) => {
            const { query } = attribute
            if (!query) {
                return state
            }
            if (exclusive) {
                if (state.length === 1 && state.some((x) => x === query)) {
                    return []
                }

                return [query]
            }

            if (state.includes(query)) {
                return state.filter((x) => x !== query)
            }

            return [...state, query]
        })
    }, [])

    const resetProductGroups = useCallback(() => setSelectedProductGroupIds((prev) => (prev.length ? [] : prev)), [])
    const resetSuppliers = useCallback(() => setSelectedSupplierIds((prev) => (prev.length ? [] : prev)), [])
    const resetAttributes = useCallback(() => setSelectedAttributeQueries((prev) => (prev.length ? [] : prev)), [])

    const productGroupFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.productGroupFilters) {
            return []
        }

        return loadedFilters.productGroupFilters.map((filter) => [mapProductGroupFilter(filter), selectedProductGroupIds.includes(filter.id)])
    }, [selectedProductGroupIds, loadedFilters?.productGroupFilters])

    const dataSupplierFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.dataSupplierFilters) {
            return []
        }

        return loadedFilters.dataSupplierFilters.map((filter) => [mapDataSupplierFilter(filter), selectedDataSupplierIds.includes(filter.id)])
    }, [selectedDataSupplierIds, loadedFilters?.dataSupplierFilters])

    const showFittingPositionsFilter = useMemo(
        () => params.productGroups.some((productGroup) => !!productGroup.hasFittingSideFilters),
        [params.productGroups]
    )

    return useMemo<FiltersData & FilterActions>(
        () => ({
            extendedAssortment: {
                enabled: extendedAssortmentEnabledByUser,
                forced: extendedAssortmentForced,
            },
            showOnlyAvailable,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters: attributeFilters.map((filter) => [filter, !!filter.query && selectedAttributeFilterQueries.includes(filter.query)]),
            groupedAttributeFilters: [],
            fittingPosition,
            isLoading,
            isRefetching,
            showExtendedAssortmentFilter: loadedFilters?.showExtendedAssortmentFilter ?? false,
            showFittingPositionsFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            setFittingPosition,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        }),
        [
            extendedAssortmentEnabledByUser,
            extendedAssortmentForced,
            showOnlyAvailable,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters,
            selectedAttributeFilterQueries,
            fittingPosition,
            isLoading,
            isRefetching,
            loadedFilters?.showExtendedAssortmentFilter,
            showFittingPositionsFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            setFittingPosition,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        ]
    )
}
