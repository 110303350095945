import { useState, useRef, useEffect, KeyboardEvent, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { VatRate, WorkCategory, WorkType } from "@tm/models"
import { ButtonKeyDefinition } from "@tm/utils"
import { NumberField, DoubleLabelSwitch } from "@tm/controls"
import { Divider, Stack, useMediaQuery, useTheme, Switch, TextField, Icon, Button, Typography, Tooltip, Select, MenuItem } from "@tm/components"
import { EditWorkMode } from "../../business"
import { ValueLabelStack } from "../../../ValueLabelStack"
import { VatRateSelectionItem } from "../../../../data/model"
import { StackRow, StyledNumberField } from "../../../StyledComponents"
import { VatRateSelector } from "../../../VatRateSelector"
import { useWorkCategoryItems } from "../../../../hooks/useWorkCategoryItems"

type Props = {
    calculatedPrice?: number
    currencyCode: string
    description?: string
    fixedPriceValue?: number
    hourlyRate?: number
    hourlyRates: Record<WorkCategory, number>
    icon?: string
    iconTooltip?: string
    isEditMode: boolean
    isFixedPrice?: boolean
    numberAndDescriptionMandatory?: boolean
    price?: number
    rebate?: number
    repairTimeDivision: number
    showRepairTimesInHours: boolean
    showSaveAsCustomRepairTimeButton?: boolean
    surcharge?: number
    time?: number
    title: string
    vatRates?: VatRateSelectionItem[]
    workCategory?: WorkCategory
    workNumber?: string
    workType?: WorkType
    onClose(): void
    onConfirm(
        saveAsCustomRepairTime: boolean,
        workNumber: string,
        categoryOfWork: WorkCategory,
        description: string,
        time?: number,
        hourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate,
        editWorkMode?: EditWorkMode
    ): void
}

const MAX_POSSIBLE_NUMBER = 99999.99

export default function WorkFieldsComponent(props: Props) {
    const { translateText } = useLocalization()
    const {
        hourlyRates,
        icon,
        title,
        iconTooltip,
        showRepairTimesInHours,
        numberAndDescriptionMandatory,
        workType,
        workNumber,
        description,
        workCategory,
        hourlyRate,
        repairTimeDivision,
        fixedPriceValue,
        time,
        calculatedPrice,
        showSaveAsCustomRepairTimeButton,
        rebate,
        surcharge,
        currencyCode,
        price,
        isFixedPrice,
        isEditMode,
        vatRates,
    } = props
    const rates = Object.values(hourlyRates)

    const workCategoryItems = useWorkCategoryItems(hourlyRates)
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const [actualWorkNumber, setWorkNumber] = useState<string | undefined>(workNumber)
    const [actualDescription, setDescription] = useState<string | undefined>(description)
    const [actualTime, setTime] = useState<number | undefined>(time)
    const [actualHourlyRate, setHourlyRate] = useState<number | undefined>(
        hourlyRate || (rates[WorkCategory.WorkshopWork] ? rates[WorkCategory.WorkshopWork] : rates[WorkCategory.NotCategorized])
    )
    const [actualFixedPrice, setFixedPrice] = useState<number | undefined>(fixedPriceValue)
    const [actualWorkCategory, setWorkCategory] = useState<WorkCategory | undefined>(workCategory || WorkCategory.WorkshopWork)
    const [actualRebate, setRebate] = useState(rebate ?? 0)
    const [actualSurcharge, setSurcharge] = useState(surcharge ?? 0)
    const [actualCalculatedPriceHourlyRate, setCalculatedPriceHourlyRate] = useState<number | undefined>(calculatedPrice)
    const [actualCalculatedPriceFixedPrice, setCalculatedPriceFixedPrice] = useState<number | undefined>(calculatedPrice)
    const [actualPrice, setPrice] = useState<number | undefined>(price)
    const [saveAsCustomRepairTime, setSaveAsCustomRepairTime] = useState(false)
    const [originalFieldsExpanded, setOriginalFieldsExpanded] = useState<boolean>(true)
    const [showHourlyRateFields, setShowHourlyRateFields] = useState<boolean>(!isFixedPrice)
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(true)
    const [selectedVatRate, setSelectedVatRate] = useState<VatRateSelectionItem | undefined>(undefined)

    const workNumberRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLInputElement>(null)
    const workCategoryRef = useRef<HTMLSelectElement>(null)
    const timeRef = useRef<NumberField>(null)
    const confirmationRef = useRef<HTMLButtonElement>(null)

    const repairTimesTextId = showRepairTimesInHours ? 84 : 1550

    useEffect(() => {
        let buttonDisabled = false
        if (
            !actualDescription ||
            (numberAndDescriptionMandatory && !actualWorkNumber) ||
            (showHourlyRateFields && (!actualHourlyRate || actualHourlyRate === 0 || !actualTime || actualTime === 0)) ||
            (!showHourlyRateFields && !actualFixedPrice)
        ) {
            buttonDisabled = true
        }

        setConfirmButtonDisabled(buttonDisabled)
    }, [numberAndDescriptionMandatory, actualWorkNumber, actualDescription, actualFixedPrice, actualTime, actualHourlyRate, showHourlyRateFields])

    const mappedVatRate: VatRate | undefined = useMemo(() => {
        return selectedVatRate
            ? {
                  vatRate: selectedVatRate?.vatValue,
                  vatType: selectedVatRate?.vatType,
              }
            : undefined
    }, [selectedVatRate])

    function calculateHourlyRatePrices(
        surcharge: number | undefined,
        hourlyRate: number | undefined,
        time: number | undefined,
        rebate: number | undefined
    ) {
        const newHourlyRate = (hourlyRate || 0) + (hourlyRate || 0) * ((surcharge || 0) / 100)
        const newPrice = (newHourlyRate * (time || 0)) / (repairTimeDivision > 0 ? repairTimeDivision : 1)
        setPrice(newPrice)
        setCalculatedPriceHourlyRate(newPrice - newPrice * ((rebate || 0) / 100))
    }

    function calculateFixedPricePrices(fixedPrice: number | undefined, rebate: number | undefined) {
        setCalculatedPriceFixedPrice((fixedPrice || 0) - (fixedPrice || 0) * ((rebate || 0) / 100))
    }

    function handleWorkNumberKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    function handleDescriptionKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            workCategoryRef.current?.focus()
        }
    }

    function handleWorkCategoryChange(category: WorkCategory) {
        setWorkCategory(category)
        const newHourlyRate = rates[category] ?? rates[WorkCategory.NotCategorized]
        setHourlyRate(newHourlyRate)
        calculateHourlyRatePrices(actualSurcharge, newHourlyRate, actualTime, actualRebate)
    }

    function handleHourlyRateKeyDown(e: KeyboardEvent) {
        if (e.key === (ButtonKeyDefinition.Enter || ButtonKeyDefinition.Tab)) {
            timeRef.current?.focus()
        }
    }

    function handleHourlyRateChange(value: number | undefined) {
        if (actualWorkCategory) {
            setHourlyRate(value || 0)
            calculateHourlyRatePrices(actualSurcharge, value, actualTime, actualRebate)
        }
    }

    function handleTimeChange(value: number | undefined) {
        setTime(value || 0)
        calculateHourlyRatePrices(actualSurcharge, actualHourlyRate, value, actualRebate)
    }

    function handleLastFieldKeyDown(e: KeyboardEvent) {
        if (e.key === (ButtonKeyDefinition.Enter || ButtonKeyDefinition.Tab)) {
            if (!actualWorkNumber) {
                workNumberRef.current?.focus()
            } else if (!actualDescription) {
                descriptionRef.current?.focus()
            } else {
                confirmationRef.current?.focus()
            }
        }
    }

    function handleChangeRebate(rebate: number) {
        setRebate(rebate || 0)
        calculateHourlyRatePrices(actualSurcharge, actualHourlyRate, actualTime, rebate)
        calculateFixedPricePrices(actualFixedPrice, rebate)
    }

    function handleChangeSurcharge(surcharge: number) {
        setSurcharge(surcharge || 0)
        calculateHourlyRatePrices(surcharge, actualHourlyRate, actualTime, actualRebate)
    }

    function handleFixedPriceChange(value: number | undefined) {
        setFixedPrice(value || 0)
        calculateFixedPricePrices(value, actualRebate)
    }

    function handleConfirmButtonClick() {
        let editWorkMode
        if (
            workType &&
            workType !== WorkType.CustomWork &&
            (workNumber !== actualWorkNumber || description !== actualDescription || workCategory !== actualWorkCategory)
        ) {
            editWorkMode = EditWorkMode.replaceWorkWithCustomWork
        } else if (workType && workType === WorkType.CustomWork && (actualWorkNumber || actualDescription)) {
            editWorkMode = EditWorkMode.editCustomWork
        } else if (workType && (actualWorkNumber || actualDescription)) {
            editWorkMode = EditWorkMode.editRepairTime
        }

        props.onConfirm(
            saveAsCustomRepairTime,
            actualWorkNumber || "",
            actualWorkCategory || WorkCategory.WorkshopWork,
            actualDescription || "",
            actualTime,
            showHourlyRateFields ? actualHourlyRate : undefined,
            !showHourlyRateFields ? actualFixedPrice : undefined,
            actualRebate,
            showHourlyRateFields ? actualSurcharge : undefined,
            mappedVatRate,
            editWorkMode
        )
    }

    function renderTitel() {
        return (
            <StackRow spacing={1} flex="0 0 auto">
                {icon && (
                    <Tooltip title={iconTooltip || ""} variant="light">
                        <Icon name={icon} />
                    </Tooltip>
                )}
                <Typography>{title}</Typography>
            </StackRow>
        )
    }

    function renderBasicInfo() {
        return (
            <StackRow spacing={1} flex="1 1 auto">
                <TextField
                    inputProps={{ maxLength: 50 }}
                    inputRef={workNumberRef}
                    label={translateText(58)}
                    size="large"
                    value={actualWorkNumber}
                    onChange={(e) => setWorkNumber(e.target.value)}
                    onKeyDown={handleWorkNumberKeyDown}
                />
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    inputRef={descriptionRef}
                    label={translateText(131)}
                    required
                    size="large"
                    value={actualDescription}
                    onChange={(e) => setDescription(e.target.value)}
                    onKeyDown={handleDescriptionKeyDown}
                />
                <Select
                    value={
                        actualWorkCategory
                            ? workCategoryItems.find((x) => x.category === actualWorkCategory)?.category
                            : workCategoryItems[0].category
                    }
                    onChange={(e) => handleWorkCategoryChange(e.target.value as WorkCategory)}
                    inputRef={workCategoryRef}
                    size="large"
                >
                    {workCategoryItems.map((item, idx) => {
                        return (
                            <MenuItem key={idx} value={item.category}>
                                {translateText(item.textId)}
                            </MenuItem>
                        )
                    })}
                </Select>
            </StackRow>
        )
    }

    function renderHourlyRateFields() {
        return (
            <StackRow spacing={1}>
                <StyledNumberField
                    label={`${translateText(12475)} %`}
                    floatingLabel
                    value={actualSurcharge}
                    minimum={0}
                    maximum={MAX_POSSIBLE_NUMBER}
                    stepSize={0.01}
                    nullable
                    onChange={handleChangeSurcharge}
                    onChangeConfirm={handleChangeSurcharge}
                />
                <StyledNumberField
                    onKeyDown={handleHourlyRateKeyDown}
                    floatingLabel
                    label={translateText(63)}
                    value={actualHourlyRate}
                    minimum={0}
                    maximum={MAX_POSSIBLE_NUMBER}
                    stepSize={0.01}
                    nullable
                    onChange={handleHourlyRateChange}
                    onChangeConfirm={handleHourlyRateChange}
                />
                <StyledNumberField
                    onKeyDown={handleLastFieldKeyDown}
                    ref={timeRef}
                    floatingLabel
                    label={translateText(repairTimesTextId)}
                    value={actualTime}
                    minimum={0}
                    maximum={MAX_POSSIBLE_NUMBER}
                    stepSize={0.01}
                    nullable
                    onChange={handleTimeChange}
                    onChangeConfirm={handleTimeChange}
                />
                <ValueLabelStack label={`${translateText(57)} ${currencyCode}`} value={actualPrice ?? 0} />
            </StackRow>
        )
    }

    function renderFixedPriceFields() {
        return (
            <StackRow spacing={1}>
                <StyledNumberField
                    onKeyDown={handleLastFieldKeyDown}
                    ref={timeRef}
                    label={translateText(repairTimesTextId)}
                    floatingLabel
                    value={actualTime}
                    minimum={0}
                    maximum={MAX_POSSIBLE_NUMBER}
                    stepSize={0.01}
                    nullable
                    onChange={handleTimeChange}
                    onChangeConfirm={handleTimeChange}
                />
                <StyledNumberField
                    onKeyDown={handleLastFieldKeyDown}
                    floatingLabel
                    label={translateText(246)}
                    value={actualFixedPrice}
                    minimum={0}
                    maximum={MAX_POSSIBLE_NUMBER}
                    stepSize={0.01}
                    nullable
                    onChange={handleFixedPriceChange}
                    onChangeConfirm={handleFixedPriceChange}
                />
            </StackRow>
        )
    }

    function renderOriginalFields() {
        return (
            <StackRow spacing={1}>
                <DoubleLabelSwitch
                    leftLabel={translateText(63)}
                    rightLabel={translateText(246)}
                    rightActive={isFixedPrice}
                    onChange={() => setShowHourlyRateFields(!showHourlyRateFields)}
                />
                {showHourlyRateFields ? renderHourlyRateFields() : renderFixedPriceFields()}
            </StackRow>
        )
    }

    function renderCalculationFields() {
        return (
            <StackRow spacing={1}>
                {originalFieldsExpanded && renderOriginalFields()}
                {isEditMode && (
                    <Button
                        onClick={() => setOriginalFieldsExpanded(!originalFieldsExpanded)}
                        startIcon={<Icon name={originalFieldsExpanded ? "arrow-right" : "arrow-left"} />}
                        variant="text"
                        size="large"
                    />
                )}
                <StyledNumberField
                    label={`${translateText(54)} %`}
                    floatingLabel
                    value={actualRebate}
                    minimum={0}
                    maximum={100}
                    stepSize={0.01}
                    nullable
                    onChange={handleChangeRebate}
                />
                <ValueLabelStack
                    label={`${translateText(57)} ${currencyCode}`}
                    value={showHourlyRateFields ? actualCalculatedPriceHourlyRate ?? 0 : actualCalculatedPriceFixedPrice ?? 0}
                />
                {vatRates && <VatRateSelector vatRates={vatRates} onChange={(newVatRate) => setSelectedVatRate(newVatRate)} />}
            </StackRow>
        )
    }

    function renderActions() {
        return (
            <StackRow spacing={0.5}>
                {showSaveAsCustomRepairTimeButton && (
                    <Switch
                        label={translateText(9)}
                        checked={saveAsCustomRepairTime}
                        labelPlacement="start"
                        size="small"
                        sx={{ mr: 0.5 }}
                        onChange={() => setSaveAsCustomRepairTime(!saveAsCustomRepairTime)}
                    />
                )}
                <Button title={translateText(70)} onClick={() => props.onClose()} startIcon={<Icon name="close" />} size="large" />
                <Button
                    ref={confirmationRef}
                    title={translateText(9)}
                    onClick={handleConfirmButtonClick}
                    startIcon={<Icon name="check" />}
                    color="success"
                    disabled={confirmButtonDisabled}
                    size="large"
                />
            </StackRow>
        )
    }

    if (!isBigScreen) {
        return (
            <Stack spacing={1}>
                <StackRow justifyContent="space-between">
                    {renderTitel()}
                    {renderActions()}
                </StackRow>
                {renderBasicInfo()}
                {renderCalculationFields()}
            </Stack>
        )
    }

    return (
        <StackRow spacing={2}>
            {renderTitel()}
            {renderBasicInfo()}
            <Divider orientation="vertical" flexItem />
            {renderCalculationFields()}
            <Divider orientation="vertical" flexItem />
            {renderActions()}
        </StackRow>
    )
}
