import { getStoredAuthorization, ajax, TmaHelper } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CostEstimationRequest } from "./model"

export * from "./model"

export const importToCostEstimation = (costEstimationRequest: CostEstimationRequest): Promise<void> => {
    const url = `${getServiceUrl()}/repairEstimation/Import`
    const authorization = getStoredAuthorization()

    costEstimationRequest.log = TmaHelper.AddCustomToBasket.GetUserInteractionLog()

    return ajax({ url, body: costEstimationRequest, method: "POST", authorization })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.costEstimationImportUrl
}
