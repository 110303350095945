import { useMutation } from "react-query"
import { PartslifeReturnUrlRequest } from "../lib/models/partslife"
import { errorStoreActions } from "../lib/state"
import { Repositories } from "../lib/repositories"

type Props = {
    translateText: (key: string | number) => string
    onSuccess: (returnUrl: string) => void
}

export function useGetReturnUrl({ translateText, onSuccess }: Props) {
    return useMutation({
        mutationFn: (request: PartslifeReturnUrlRequest) => Repositories.getReturnUrl(request),
        onSuccess(data) {
            if (data?.returnUrl) {
                onSuccess(data?.returnUrl)
            } else {
                errorStoreActions.setError({ isError: true, message: translateText(787) })
            }
        },
        onError() {
            errorStoreActions.setError({ isError: true, message: translateText(787) })
        },
    })
}
