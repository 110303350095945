import { useLocalization } from "@tm/localization"
import { renderRoute, encodeUniqueId, getAlertColor, copyToClipboard, openStreamPdf, useErpConfig } from "@tm/utils"
import {
    channel,
    ErpSystemConfig,
    ETransferMode,
    IMicros,
    MessageType,
    OrderResponseGroup,
    PostSendOrderActionType,
    RegisteredModels,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { useMicro } from "@tm/morpheus"
import { useUser } from "@tm/context-distribution"
import { Box, Divider, Paper, Typography, Button, Icon, LinkButton, Alert, Stack } from "@tm/components"
import { getBundleParams } from "../../../utils"
import { InternalOrderError, InternalOrderResult } from "../order-button/hooks/useOrderResult"
import VoucherButton, { VoucherType } from "./components/VoucherButton"

type Props = {
    cisVoucherUrl?: string
    externalVoucherUrl?: string
    results: InternalOrderResult[]
    showOrderConfirmationPdf?: boolean
    workTaskId: string
    technicalErrors?: InternalOrderError[]
    isOpenedAsDialog?: boolean
    onOpenVoucherUrl(url: string): void
    onClearOrderSent?(): void
}

export default function OrderCompleted(props: Props) {
    const { workTaskId, showOrderConfirmationPdf, cisVoucherUrl, externalVoucherUrl, results, technicalErrors, isOpenedAsDialog } = props
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const { userContext } = useUser() || {}
    const { orderVoucherUrl } = getBundleParams()
    const { erpSystemConfigs } = useErpConfig()

    const hasMultipleOrderSystems = React.useMemo(() => erpSystemConfigs && erpSystemConfigs.length > 1, [erpSystemConfigs])
    const openExternalOrderSystem = React.useMemo(() => {
        return results.some(
            (result) =>
                result.result.workTaskId === workTaskId &&
                result.result.postSendOrderActions?.some((action) => action.action === PostSendOrderActionType.OpenExternalUrl)
        )
    }, [results, workTaskId])

    let voucherType: VoucherType = "order"
    if (cisVoucherUrl) {
        voucherType = "cis"
    } else if (externalVoucherUrl) {
        voucherType = "external"
    }

    if ((!technicalErrors?.length && !results.length) || openExternalOrderSystem) {
        return null
    }

    function handlePrintButtonClick(orderId: string) {
        Container.getInstance<Array<number>>(RegisteredModels.Vouchers_ShowOrderPdf)
            .subscribe(orderId)
            .load()
            .then((orderPdf) => {
                if (orderPdf) {
                    openStreamPdf(orderPdf, translateText)
                }
            })
    }

    function handleCloseClick() {
        props.onClearOrderSent?.()
    }

    function renderOrderValidationMessages(order: OrderResponseGroup) {
        return (
            <>
                {order.orderValidationMessage && (
                    <Alert
                        title={order.orderValidationMessage.texts.toString()}
                        severity={getAlertColor(order.orderValidationMessage.type)}
                        iconColor={order.orderValidationMessage.type === MessageType.Error ? "error" : undefined}
                    />
                )}
                {order.itemValidationMessages?.map((itemError, idx) => {
                    return (
                        <Box mt={0.5} key={idx}>
                            <Alert
                                title={itemError.texts.toString()}
                                severity={getAlertColor(itemError.type)}
                                iconColor={itemError.type === MessageType.Error ? "error" : undefined}
                            />
                        </Box>
                    )
                })}
            </>
        )
    }

    function renderGoToStartButton() {
        return (
            <LinkButton color="highlight" to="/" startIcon={<Icon name="dashboard" />}>
                {translateText(671)}
            </LinkButton>
        )
    }

    function renderPrintbutton(order: OrderResponseGroup) {
        if (showOrderConfirmationPdf) {
            return (
                <Box sx={{ marginLeft: "4px" }}>
                    {renderMicro("cis", "print-order", {
                        orderId: order.orderNumber,
                    })}
                </Box>
            )
        }
        if (!externalVoucherUrl && !cisVoucherUrl) {
            return (
                <Button
                    disabled={!order.orderVoucherId}
                    startIcon={<Icon name="print" />}
                    variant="text"
                    title={translateText(49)}
                    onClick={() => order.orderVoucherId && handlePrintButtonClick(order.orderVoucherId)}
                />
            )
        }
    }

    function renderOrderSuccess(order: OrderResponseGroup, erpSystemConfig: ErpSystemConfig) {
        if (erpSystemConfig.displayMode === "81") {
            // "Manuelle Bestellung"
            return renderGoToStartButton()
        }
        if (erpSystemConfig.transferMode === ETransferMode.BasketTransferBoschOs3) {
            return null
        }

        let telesalesControls
        if (userContext.hasTelesales && !isOpenedAsDialog) {
            const { cisRoute } = getBundleParams()
            let cisButton
            if (cisRoute) {
                const url = renderRoute(cisRoute, { workTaskId: encodeUniqueId(workTaskId) })
                cisButton = (
                    <LinkButton to={url} startIcon={<Icon name="voucher" />}>
                        {translateText(1763)}
                    </LinkButton>
                )
            }

            telesalesControls = (
                <Box>
                    {cisButton}
                    {renderGoToStartButton()}
                </Box>
            )
        }

        const dashboard = `/${encodeUniqueId(workTaskId)}`

        return (
            <>
                <Paper>
                    <Stack direction="row" justifyContent="space-between" alignContent="center">
                        <Stack spacing={1}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h2">
                                    {translateText(292)} | {translateText(700)} {order.orderNumber}
                                </Typography>
                                <Button
                                    disabled={!order.orderNumber}
                                    startIcon={<Icon name="copy" />}
                                    variant="text"
                                    title={translateText(47)}
                                    onClick={(e: React.SyntheticEvent) => {
                                        e.stopPropagation()
                                        copyToClipboard(order.orderNumber)
                                        channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
                                    }}
                                />
                                {renderPrintbutton(order)}
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                {order.orderDate && (
                                    <Typography variant="body2">
                                        {translateText(774)} {order.orderDate.toLocaleDateString()} {translateText(699)}{" "}
                                        {order.orderDate.toLocaleTimeString()}.
                                    </Typography>
                                )}
                                {props.onClearOrderSent == null && dashboard && !isOpenedAsDialog && (
                                    <LinkButton to={dashboard} startIcon={<Icon name="dashboard" />} color="highlight">
                                        {translateText(760)}
                                    </LinkButton>
                                )}
                            </Box>
                        </Stack>
                        <VoucherButton
                            workTaskId={workTaskId}
                            voucherType={voucherType}
                            orderNumber={order.orderNumber}
                            orderVoucherId={order.orderVoucherId}
                            voucherUrl={externalVoucherUrl ?? cisVoucherUrl ?? orderVoucherUrl}
                            onOpenVoucherUrl={props.onOpenVoucherUrl}
                        />
                    </Stack>
                </Paper>
                {telesalesControls}
            </>
        )
    }

    function renderOrder(order: OrderResponseGroup, erpSystemConfig: ErpSystemConfig) {
        return (
            <Stack key={order.orderNumber + order.orderDate?.getTime() + order.orderValidationMessage?.id} spacing={0.5}>
                {renderOrderValidationMessages(order)}
                {order.hasSucceeded && renderOrderSuccess(order, erpSystemConfig)}
            </Stack>
        )
    }

    function renderTechnicalError({ technicalErrorText, erpSystemConfig }: InternalOrderError, index: number) {
        const message = (hasMultipleOrderSystems ? `${erpSystemConfig.description}: ` : "") + translateText(787)
        let description = `${translateText(788)}. `
        if (technicalErrorText) {
            description += technicalErrorText
        }

        return (
            <Alert key={index} title={message} severity="error" onClose={props.onClearOrderSent ? handleCloseClick : undefined}>
                {description}
            </Alert>
        )
    }

    function renderOrderResult({ result, erpSystemConfig }: InternalOrderResult, index: number) {
        if (result.basketIsNotUpToDate) {
            return null
        }

        const hasErrors = !result.allHaveSucceeded
        let message = hasMultipleOrderSystems ? `${erpSystemConfig.description}: ` : ""
        if (hasErrors) {
            message += translateText(787)
        } else if (erpSystemConfig.displayMode === "81" || erpSystemConfig.transferMode === ETransferMode.BasketTransferBoschOs3) {
            message += translateText(1777)
        } else {
            message += translateText(759)
        }

        let description
        if (hasErrors) {
            description = `${translateText(788)}. `
            if (result.technicalErrorText) {
                description += result.technicalErrorText
            }
        }

        return (
            <Box key={index}>
                <Box mb={0.5}>
                    <Alert title={message} severity={hasErrors ? "error" : "success"} onClose={props.onClearOrderSent ? handleCloseClick : undefined}>
                        {description}
                    </Alert>
                </Box>
                {result.orders?.map((order) => renderOrder(order, erpSystemConfig))}
            </Box>
        )
    }

    return (
        <Stack spacing={1}>
            {technicalErrors?.map(renderTechnicalError)}
            {results.map(renderOrderResult)}
            {!isOpenedAsDialog && <Divider />}
        </Stack>
    )
}
