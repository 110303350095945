import { Badge, Box, Button, Icon, Loader, Stack } from "@tm/components"
import { useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ArticleInfoType, ErpSystemConfigMode, IMicros, WorkTaskStatus, channel } from "@tm/models"
import Morpheus from "@tm/morpheus"
import {
    concat,
    decodeUniqueId,
    mapArticleToAddCatalogPartListRequest,
    mapArticleToAddWholesalerPartListRequest,
    mapArticleToReplaceByCatalogPartRequest,
    showWarehouseDataMissingError,
    useDefaultErpSystem,
    useExternalCatalogUrl,
} from "@tm/utils"
import { Suspense, useMemo, useRef, useState } from "react"
import { useParams } from "react-router"
import { useIsArticleAvailableForMultipleDistributors } from "../../../../erp/src/data/hooks/useArticleWithChangedTradeReference"
import { useErpInfo } from "../../../../erp/src/micros/_helpers/useErpInfo"
import PartnerSystemsPopover from "../../components/partner-systems-popover"
import { useErpInfoMulti } from "../../data/hooks/useErpInfoMulti"
import { useSupplierArticleQuantities } from "../../data/hooks/useSupplierArticleQuantities"
import { useWarehouse } from "../../data/hooks/useWarehouse"
import { getQuantityFromQuantitiyGroupsDto, useAddArticleExternalUrl } from "../../helpers"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { ApprovalWarningPrompt } from "../_shared/ApprovalWarningPrompt"
import { QuantityField } from "./components/QuantityField"

type Props = IMicros["basket"]["add-to-basket"]
type RouteParams = {
    workTaskId?: string // Used from the central ordering (article details)
}

function AddToBasketComponent(props: Props & { workTaskId: string }) {
    const { workTask, createWorkTask } = useWorkTask() ?? {}

    const {
        data: articles,
        advertisementCategoryId,
        articleInfoType,
        buttonText,
        customerId,
        foundBySearchTerm,
        hideBasketButton,
        hideQuantityField,
        partToReplaceId,
        searchType,
        variant,
        vehicleId = workTask?.vehicle?.id,
        openPopoverOnMultiplePartnerSystems,
        onMultiplePartnerSystemsPopoverClosed,
        vehicleImageBase64,
        workTaskId,
    } = props

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [multiplePartnerSystemsPopoverCurrentlyClosed, setMultiplePartnerSystemsPopoverCurrentlyClosed] = useState(false)

    const { addToBasketExternalSystemId, disableAddToBasketIfReplacementArticlesAvailable, enableAddingButtonsAfterErp } = getBundleParams()

    const { translateText, languageId } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { costEstimation, basket } = useWorkTaskBasketState(workTaskId)

    const { erpSystemConfig } = useDefaultErpSystem(props.erpSystemConfig)
    const { erpInfos, erpInfoLoaded } = useErpInfoMulti(
        articles,
        props.erpType,
        erpSystemConfig?.id,
        telesalesCustomerNo,
        vehicleId,
        foundBySearchTerm
    )
    const { erpConfig } = useErpInfo(articles[0], props.erpType || "list", undefined, undefined, foundBySearchTerm)

    const isAvailableForMultipleDistributors = useIsArticleAvailableForMultipleDistributors(articles[0], erpConfig.erpSystemConfigs ?? [])

    const warehouseData = useWarehouse(articles[0]?.id, erpSystemConfig?.id) // Note: currently only working correctly for only one article

    const basketMemo = useBasketMemo(workTask, searchType)
    const basketInfo = useSupplierArticleQuantities(workTaskId, articles[0], props.disabled) // Note: currently only working correctly for only one article

    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const externalBasketUrl = useAddArticleExternalUrl(
        // Note: currently only working correctly for only one article
        externalCatalogUrl,
        languageId,
        articles[0]?.quantity,
        articles[0]?.traderArticleNo,
        articles[0]?.supplierArticleNo,
        articles[0]?.supplier.id,
        articles[0]?.productGroup.id
    )

    const [articleGetsAddedToBasket, setArticleGetsAddedToBasket] = useState(false)

    const dialogRef = useRef<WarningPrompt>(null)

    const buttonWidth = useMemo(() => {
        if (variant === "small") {
            return "20px"
        }

        if (buttonText) {
            return "13em"
        }

        return "6em"
    }, [variant, buttonText])

    const buttonDisabled = useMemo(() => {
        return (
            (disableAddToBasketIfReplacementArticlesAvailable && erpInfos.some((x) => x.hasReplacementArticles)) ||
            props.disabled ||
            articles.some((x) => !x.showAddToBasket) ||
            !workTaskId ||
            (enableAddingButtonsAfterErp && !erpInfoLoaded) ||
            loadingExternalCatalogUrl
        )
    }, [
        disableAddToBasketIfReplacementArticlesAvailable,
        erpInfos,
        props.disabled,
        articles,
        workTaskId,
        enableAddingButtonsAfterErp,
        erpInfoLoaded,
        loadingExternalCatalogUrl,
    ])

    function handleQuantityChange(article: Article, newQuantity: number) {
        if (newQuantity !== article.quantity) {
            channel("WORKTASK").publish("BASKET/ARTICLE_QUANTITY_CHANGED", { article, quantity: newQuantity })
        }
    }

    function addArticlesToBasket(
        workTaskId: string,
        articles: Array<Article>,
        customerId?: string,
        vehicleId?: string,
        foundBySearchTerm?: string,
        articleInfoType?: ArticleInfoType,
        memo?: string,
        advertisementCategoryId?: string
    ) {
        setArticleGetsAddedToBasket(true)

        // TODO: Check where add-to-basket is used for wholesaler articles and migrate them to use the "add-to-basket-wholesaler-part" micro
        if (articleInfoType === ArticleInfoType.WholesalerArticle) {
            basket.actions
                .addWholesalerParts(
                    mapArticleToAddWholesalerPartListRequest(
                        articles,
                        workTaskId,
                        vehicleId,
                        customerId,
                        memo,
                        warehouseData.warehouse,
                        advertisementCategoryId,
                        erpSystemConfig?.id,
                        erpSystemConfig?.description,
                        erpInfos
                    )
                )
                .finally(() => setArticleGetsAddedToBasket(false))
        } else if (partToReplaceId && articles.length === 1) {
            // Note: currently only working correctly for only one article

            basket.actions
                .replacePartWithCatalogPart(
                    mapArticleToReplaceByCatalogPartRequest(
                        partToReplaceId,
                        articles[0],
                        workTaskId,
                        vehicleId,
                        customerId,
                        foundBySearchTerm,
                        memo,
                        warehouseData.warehouse,
                        advertisementCategoryId,
                        erpSystemConfig?.id,
                        erpSystemConfig?.description
                    )
                )
                .catch(() => setArticleGetsAddedToBasket(false)) // catch used since the alternative modal closes automatically
        } else {
            if (hideQuantityField) {
                articles.map((article) => {
                    // TODO: return value of map not used, check if this is a bug
                    const erpInfo = erpInfos?.find((erp) => erp.itemId === article.id)

                    return {
                        ...article,
                        quantity: erpInfo?.quantity?.division && erpInfo.quantity.division > 1 ? erpInfo.quantity.division : article.quantity || 1,
                    }
                })
            }
            basket.actions
                .addCatalogParts(
                    mapArticleToAddCatalogPartListRequest(
                        articles,
                        workTaskId,
                        vehicleId,
                        customerId,
                        foundBySearchTerm,
                        memo,
                        erpInfos,
                        warehouseData.warehouse,
                        advertisementCategoryId,
                        erpSystemConfig?.id,
                        erpSystemConfig?.description
                    )
                )
                .then((response) => props.onAddCatalogArticleToBasketFinished?.(response))
                .finally(() => setArticleGetsAddedToBasket(false))
        }
    }

    function addToBasket() {
        if (multiplePartnerSystemsPopoverCurrentlyClosed) {
            updateMultiplePartnerSystemsPopoverClosed(false)
        }

        addArticlesToBasket(
            workTaskId,
            articles,
            customerId,
            vehicleId,
            foundBySearchTerm,
            articleInfoType,
            basketMemo.position,
            advertisementCategoryId
        )

        // Reset quantity of articles back to initial after adding to the basket
        articles.forEach((article) => handleQuantityChange(article, article.initialQuantity))

        if (vehicleImageBase64) {
            costEstimation.actions.attachVehicleImage(vehicleImageBase64)
        }

        props.onAddToBasket?.(articles)
    }

    async function handleButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            warehouseData.refetchWarehouseData()
            return
        }

        if (erpInfos?.some((erpData) => erpData.itemOrderableInformation)) {
            if (onMultiplePartnerSystemsPopoverClosed) {
                updateMultiplePartnerSystemsPopoverClosed(true)
            }
            dialogRef.current?.show()
        } else if (externalBasketUrl) {
            if (createWorkTask && workTaskId && workTask?.statusValue === WorkTaskStatus.Undefined) {
                await createWorkTask({ workTaskId, skipRedirect: true })
            }

            Morpheus.showView("1", externalBasketUrl)
        } else if (
            erpConfig.mode === ErpSystemConfigMode.Partnersystems &&
            erpConfig.erpSystemConfigs &&
            erpConfig.erpSystemConfigs?.length > 1 &&
            !anchorEl &&
            !!openPopoverOnMultiplePartnerSystems &&
            isAvailableForMultipleDistributors
        ) {
            setAnchorEl(e.currentTarget)
        } else {
            addToBasket()
        }
    }

    function handleRemovePart(article: Article, basketIds: string[]) {
        if (!workTaskId) {
            return
        }

        basket.actions.removeParts(basketIds, true)
    }

    function renderBadge() {
        if (articleGetsAddedToBasket) {
            return (
                <Badge
                    title={translateText(1299)}
                    badgeContent={<Loader size="extrasmall" />}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        }

        if (!workTaskId || articles.length !== 1) {
            // Note: currently only working correctly for only one article
            return null
        }

        // The quantities should be checked for the specific erp system / warehouse of this basket button
        const { quantity, ids } = getQuantityFromQuantitiyGroupsDto(
            basketInfo?.articleQuantities,
            props.erpSystemConfig?.id,
            props.shownWithWarehouseSelector ? warehouseData.warehouse?.id : undefined
        )

        if (!quantity || !ids.length) {
            return
        }

        return (
            <Badge
                size="small"
                title={translateText(1299)}
                onClick={() => !basket.state.basketLoading && handleRemovePart(articles[0], ids)} // Note: currently only working correctly for only one article
                badgeContent={
                    <Stack direction="row">
                        {quantity}
                        <Icon name="close" width="12px" height="12px" sx={{ marginLeft: "2px" }} />
                    </Stack>
                }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{ position: "initial" }}
            />
        )
    }

    function updateMultiplePartnerSystemsPopoverClosed(isHidden: boolean) {
        if (!onMultiplePartnerSystemsPopoverClosed) {
            return
        }

        onMultiplePartnerSystemsPopoverClosed(isHidden)
        setMultiplePartnerSystemsPopoverCurrentlyClosed(isHidden)
    }

    function renderButton() {
        if (hideBasketButton) {
            return null
        }

        return (
            <>
                {renderBadge()}
                <Box minWidth={buttonWidth}>
                    <Button
                        title={translateText(937)}
                        variant={!buttonDisabled ? "bordered" : undefined}
                        onClick={(e) => handleButtonClick(e)}
                        disabled={buttonDisabled}
                        color={!buttonDisabled ? "highlight" : undefined}
                        startIcon={<Icon name={partToReplaceId ? "replace" : "cart"} />}
                        fullWidth
                        className="addToBasketButton"
                    >
                        {buttonText ? <>{buttonText}</> : ""}
                    </Button>
                </Box>
                <ApprovalWarningPrompt
                    ref={dialogRef}
                    erpInfos={erpInfos}
                    onCancel={() => updateMultiplePartnerSystemsPopoverClosed(false)}
                    onConfirm={addToBasket}
                />
            </>
        )
    }

    function renderQuantityField() {
        if (articles.length !== 1) {
            return null
        }
        const erpInfo = erpInfos?.find((erp) => erp.itemId === articles[0].id)

        // Note: currently only working correctly for only one article
        return (
            <QuantityField
                {...props}
                minQuantity={erpInfo?.quantity?.minQuantity}
                maxQuantity={erpInfo?.quantity?.maxQuantity}
                division={erpInfo?.quantity?.division}
                article={articles[0]}
                onQuantityChange={handleQuantityChange}
            />
        )
    }

    function renderPartnerSystemsPopover() {
        return (
            <>
                <PartnerSystemsPopover
                    anchorEl={anchorEl}
                    article={articles[0]}
                    closePopup={() => {
                        setAnchorEl(null)
                    }}
                />
            </>
        )
    }

    const disabled = articles.some((x) => !x.showAddToBasket)

    return (
        <div className="tk-basket" title={disabled ? translateText(938) : undefined}>
            <div className={concat(" ", "add-to-basket", disabled && "add-to-basket--disabled")}>
                {renderPartnerSystemsPopover()}
                {renderQuantityField()}
                {renderButton()}
            </div>
        </div>
    )
}

export default function Wrapper(props: Props) {
    const { workTaskId } = useWorkTask() ?? {}

    const params = useParams<RouteParams>()
    const decodedWorkTaskId = decodeUniqueId(params.workTaskId ?? "") // TODO: Check if workTaskId from useWorkTask couldn't be used here instead - WorkTaskId from params can be null (for example: Neimcke/PV Startpage -> Offers widget - see NEXT-13118)

    const id = workTaskId ?? decodedWorkTaskId ?? props.generatedWorktaskId
    if (!id) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToBasketComponent {...props} workTaskId={id} />
        </Suspense>
    )
}
