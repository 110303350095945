import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"

export type EdsStore = MainSlice

export const useEdsStore = create<EdsStore>()(
    devtools(
        (...a) => ({
            ...createMainSlice(...a),
        }),
        { name: "EDS Store", enabled: isDevtoolEnabled() }
    )
)

export const { resetStore, mainActions, vehicleActions, treeActions: treeNavigationActions, graphicActions } = useEdsStore.getState()
