import { Stack, Typography, Icon, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { formatDate } from "@tm/utils"
import { ReturnInfo } from "../../../../../../../../../data/model"

type Props = {
    returnInfo: ReturnInfo
    handleOpenReturnVoucherClick(): void
}
export default function ReturnInformationComponent({ returnInfo, handleOpenReturnVoucherClick }: Props) {
    const { translateText } = useLocalization()
    if (!returnInfo) {
        return null
    }

    return (
        <Stack>
            <Icon name="check-filled" size="xs" />
            <Stack>
                <Typography>
                    {translateText(13262)} {formatDate(returnInfo.returnDate, "d")}
                </Typography>
                <Typography variant="label">{translateText(699)}</Typography>
                <Typography>{returnInfo.returnDate.toLocaleTimeString()}</Typography>
                <Typography variant="label">{translateText(470)}</Typography>
            </Stack>
            <Button
                onClick={handleOpenReturnVoucherClick}
                target="_blank"
                size="small"
                startIcon={<Icon name="partslink24" />}
                disabled={!returnInfo.returnVoucherId}
                title={translateText(13268)}
            >
                {returnInfo.returnNumber}
            </Button>
        </Stack>
    )
}
