import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { CategoryType, channel, RequestArticleListPayload, TreeNode } from "@tm/models"
import { Searchtree, styled } from "@tm/components"
import { RouteComponentProps, withRouter, renderRoute, TmaHelper } from "@tm/utils"
import Morpheus from "@tm/morpheus"

import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocation, useRouteMatch } from "react-router"
import useBreadCrumbHandler from "../../hooks/breadCrumbhandler/useBreadCrumbHandler"
import { getBundleParams } from "../../utils"

const tmaSearchTreeNodeSelected = TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected

const Backdrop = styled("div")({
    backgroundColor: "Black",
    position: "absolute",
    opacity: "0.5",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1,
    translate: "transformY(50pX)",
})

type SearchTreeProps = {
    treeType: CategoryType
    className?: string
}

export type SearchTreeRefType = {
    open: () => void
}

export default forwardRef<SearchTreeRefType, SearchTreeProps>(function SearchTreeV2FC(props: SearchTreeProps, ref) {
    const user = useUser()
    const fastCalcModalRoute = getBundleParams().fastCalculatorModalRoute
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const { workTask } = useWorkTask() ?? {}
    const location = useLocation()
    const match = useRouteMatch()

    const showBestseller = !user?.userContext.parameter.hideBestsellers

    const { newBreadcrumbSelected, breadCrumbState, isLoading, restoreLastBreadCrumbs, resetSelectedNode } = useBreadCrumbHandler(
        location.search,
        props.treeType,
        workTask
    )

    useImperativeHandle(ref, () => {
        return {
            open: () => setIsExpanded(true),
        }
    })

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            channel("WORKTASK").subscribe("PARTS/REQUEST_LIST", async (request: RequestArticleListPayload) => {
                if (!request.uniNode && !request.node && !request.productGroups && !request.uniProductGroups) {
                    resetSelectedNode()
                }
            })
        )
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [breadCrumbState])

    if (!getBundleParams().searchtreeWithBreadcrumbs || (props.treeType !== "vehicleParts" && props.treeType !== "universalParts")) {
        return null
    }

    const updateArticleList = (node: TreeNode) => {
        if (!breadCrumbState.treeId) {
            return
        }

        const history = breadCrumbState.breadCrumbs?.map((b) => b.id) || []
        const request: RequestArticleListPayload = {
            [props.treeType === "universalParts" ? "uniNode" : "node"]: { treeId: breadCrumbState.treeId, nodeId: node.id, history },
        }
        history.push(node.id)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        setIsExpanded(false)
    }

    const handleNodeClick = (node: TreeNode, fastClick?: boolean) => {
        newBreadcrumbSelected(node)
        if ((!node.hasChildNodes && breadCrumbState.treeId) || fastClick) {
            TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected({
                nodeId: node.id,
                treeId: breadCrumbState.treeId,
                mode: fastClick ? "fast-click-2.0" : "buttons",
                categoryType: props.treeType,
            })

            updateArticleList(node)
        }
    }

    const closeAndCheckNode = () => {
        setIsExpanded(false)
        if (breadCrumbState.selectedNode) {
            if (breadCrumbState.selectedNode?.name === "OVERVIEW") {
                restoreLastBreadCrumbs()
            }
            updateArticleList(breadCrumbState.selectedNode)
        }
    }

    const handleBreadCrumbClick = (node: TreeNode) => {
        if (node.id === breadCrumbState.selectedNode?.id && isExpanded) {
            closeAndCheckNode()
        } else {
            setIsExpanded(true)
            newBreadcrumbSelected(node)
        }
    }

    const handleBackdropClick = () => {
        closeAndCheckNode()
    }

    const fastCalculation = (productGroupIds?: number[]) => {
        if (match.params && productGroupIds && fastCalcModalRoute) {
            Morpheus.showView("1", renderRoute(fastCalcModalRoute, { ...match.params, productGroupIds: productGroupIds.join(",") }))
        }
    }

    const applyProductGroups = (productGroupIds?: number[]) => {
        const { treeId, selectedNode, breadCrumbs } = breadCrumbState
        const { treeType } = props

        if (selectedNode && productGroupIds && productGroupIds?.length >= 1) {
            const history = breadCrumbs?.map((b) => b.id) || []
            const request: RequestArticleListPayload = {
                [treeType === "universalParts" ? "uniProductGroups" : "productGroups"]: {
                    ids: productGroupIds.orderBy((x) => x),
                    treeId,
                    nodeId: selectedNode.id,
                    history,
                },
            }

            tmaSearchTreeNodeSelected({
                nodeId: selectedNode.id,
                treeId,
                mode: "fast-click-2.0",
                categoryType: treeType,
            })

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
            setIsExpanded(false)
        }
    }

    return (
        <>
            <Searchtree
                className={props.className}
                tree={breadCrumbState.initialTree}
                selectedNode={breadCrumbState.selectedNode}
                breadCrumbs={breadCrumbState.breadCrumbs}
                onNodeClick={handleNodeClick}
                onBreadCrumbClick={handleBreadCrumbClick}
                variant="collapsable"
                loading={isLoading}
                isExpanded={isExpanded}
                withBackgroundColor={isExpanded}
                showBestseller={showBestseller}
                applyProductGroups={applyProductGroups}
                fastCalculation={fastCalculation}
                hideFastCalcButton={!fastCalcModalRoute}
                maxSelectableProductGroups={getBundleParams().maxSelectedCategoriesForFastCalculation}
                hideSecondLevel={props.treeType === "universalParts"}
            />
            {isExpanded && <Backdrop onClick={handleBackdropClick} />}
        </>
    )
})
