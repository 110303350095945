import { useUser } from "@tm/context-distribution"
import { ArticleAttributes, channel, ConfigParams, SystemType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute, RouteComponentProps, TmaHelper, uniqueId, withRouter } from "@tm/utils"
import { useEffect } from "react"
import { Repositories } from "../../data"
import { GetOeAttributesResponse } from "../../data/model"
import { getBundleParams } from "../../utils"
import * as Helpers from "../list/business/helpers"
import { getArticleDetailsRoute, getArticleListRoute, Route } from "./business"

export type Props = RouteComponentProps<RouteParams> & ConfigProps & {}

type ConfigProps = {
    handleDetailsRequest?: boolean
}

type RouteParams = {
    workTaskId?: string
}

function ManagerComponent(props: Props) {
    const { userContext } = useUser() || {}
    const { handleDetailsRequest, match, history } = props
    const { partsRoutes } = getBundleParams()

    useEffect(() => {
        const unsubscriptions: Array<Function> = []
        unsubscriptions.push(
            channel("WORKTASK").subscribe("PARTS/REQUEST_LIST", async (request) => {
                if (!!request.oePositions?.length && request.tecDocTypeId) {
                    const promises: Promise<GetOeAttributesResponse | ArticleAttributes[] | undefined>[] = []

                    request.oePositions?.forEach((oePosition) => {
                        oePosition.parts?.forEach((part) => {
                            if (!part.attributes && !!part.number) {
                                const req = Helpers.createGetOeAttributesRequest(request.tecDocTypeId!, part.number, [])
                                promises.push(Repositories.getOeAttributes(req).then((res) => (part.attributes = res?.articleAttributes)))
                            }
                        })
                    })

                    await Promise.allSettled(promises)
                }

                // -> usually request comes from external api post message => set search context
                if (request.inModal) {
                    if (!!request.uniSearch && request.uniSearch.query?.length) {
                        TmaHelper.UniParts.Search.SubmitExternal(request.uniSearch?.query)
                    } else if (request?.productGroups?.ids) {
                        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SubmitExternal(request.productGroups.ids.join(","))
                    } else {
                        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SubmitExternal(
                            request?.direct?.query || request?.synonym?.query || request?.general?.query
                        )
                    }
                }

                const listRoute = getArticleListRoute(request, partsRoutes)
                if (listRoute) {
                    openRoute(listRoute)
                }
            })
        )
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    useEffect(() => {
        const unsubscriptions: Array<Function> = []
        if (handleDetailsRequest !== false) {
            unsubscriptions.push(
                channel("WORKTASK").subscribe("PARTS/REQUEST_ARTICLE_DETAILS", (request) => {
                    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
                    const detailsRoute = getArticleDetailsRoute(request, !!articleDetailsInModal, partsRoutes)
                    if (detailsRoute) {
                        openRoute(detailsRoute)
                    }
                })
            )
        }

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [handleDetailsRequest])

    useEffect(() => {
        // if parts manager is used in another system (RD/SC we have to prevent these console.errors)
        if (userContext?.system.systemType != SystemType.Next) {
            return
        }

        if (!partsRoutes) {
            console.error(`Parts Manager: 'routes' was not passed as property in the app config`)
            return
        }

        if (!partsRoutes.vehicleParts || !partsRoutes.vehicleParts.list || !partsRoutes.vehicleParts.details) {
            console.error(`Parts Manager: 'routes.vehicleParts' was not passed as property in the app config or contained no list and details route`)
            return
        }

        if (!partsRoutes.universalParts || !partsRoutes.universalParts.list || !partsRoutes.universalParts.details) {
            console.error(
                `Parts Manager: 'routes.universalParts' was not passed as property in the app config or contained no list and details route`
            )
            return
        }

        if (!partsRoutes.directSearch || !partsRoutes.directSearch.list || !partsRoutes.directSearch.details) {
            console.error(`Parts Manager: 'routes.directSearch' was not passed as property in the app config or contained no list and details route`)
        }
    }, [userContext, partsRoutes])

    function openRoute(routeToOpen: Route) {
        let { params } = match

        if (!params.workTaskId || params.workTaskId === "undefined") {
            params = { ...params, workTaskId: encodeUniqueId(uniqueId()) }
        }

        let url = renderRoute(routeToOpen.route, params)
        if (routeToOpen.queryString) {
            url += `?${routeToOpen.queryString}`
        }

        if (routeToOpen.inModal) {
            Morpheus.showView("1", url)
        } else {
            history.push(url)
        }
    }

    return null
}

export default withRouter(ManagerComponent)
