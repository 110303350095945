import { RefObject, useEffect, useRef, useState } from "react"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, SuggestionFieldButtonGroup, SuggestionFieldProps, Table, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    BikeModel,
    CarModel,
    FindVehiclesResponse,
    RegistrationNoType,
    TruckModel,
    VehicleLookupConfig,
    VehicleSearchType,
    VehicleShortInfo,
    VehicleType,
} from "@tm/models"
import {
    CancelablePromise,
    encodeUniqueId,
    makeCancelable,
    renderRoute,
    uniqueId,
    useAvailableVehicleSearchOptionsRegNoTypes,
    isVin,
    convertAutosuggestHit,
} from "@tm/utils"
import { Icon } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../utils"
import { searchVehiclesByVin } from "../../business"
import { useModelsByQueryLoadable, useSelectedVehicleLookup } from "../../data/hooks"
import { Hit, loadVehicleSuggestions } from "../../data/repositories/data-picker"
import { findGsiVehicles } from "../../data/repositories/gsi"
import {
    getBikeSearchPlaceholder,
    getBikeSearchTooltip,
    getCommercialSearchPlaceholder,
    getCommercialSearchTooltip,
    getVehicleSearchPlaceholder,
    getVehicleSearchTooltip,
    handleDATRegistration,
    searchType,
    VrmLookupErrorTextIds,
} from "../../helpers"
import { VehicleData } from "../request-vin/business/model"
import RequestVinComponent from "../request-vin/component"
import { renderVehicleShortInfoAutosuggestColumns } from "./VehicleShortInfoAutosuggest"
import VrcScanButton from "./vrc-scan-button"
import { QuotaText } from "./QuotaText"
import { useVehicleSearchOptionsQuota } from "../../data/hooks/useVehicleSearchOptionsQuota"
import { AutosuggestHit } from "./AutosuggestHit"

type Props = {
    initialValue?: string
    forceShowTooltipOnHover?: boolean
    onStartSearch(query: string, regNoType?: RegistrationNoType, selectedVehicleLookupConfig?: VehicleLookupConfig): void
    onUniqueVehicleFound?(model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string): void
    onReset?(): void
    onAttachVehicleId?(vehicleId: string, query: string): void
    vehicleType: VehicleType
    disable?: boolean
    searchFieldRef?: RefObject<SuggestionFieldButtonGroup<unknown>>
    showVrcScanButton?: boolean
    placeholderPassengerCar?: string
    selectedSearchConfig?: VehicleLookupConfig
    disableEdsSearch?: boolean
}

type Suggestions = {
    loading: boolean
    vehicleModels: Array<Hit>
    customerVehicles: Array<VehicleShortInfo>
}

type Error = {
    message: string
    type?: "registrationError" | "searchError"
}

export default function VehicleSearchField(props: Props) {
    let suggestionPromiseVehicleModels: CancelablePromise<Array<Hit>>
    let suggestionPromiseCustomerVehicles: CancelablePromise<FindVehiclesResponse | undefined>

    const searchFieldRef = useRef<SuggestionFieldButtonGroup<unknown>>(null)

    const history = useHistory()
    const params = useParams<{ workTaskId?: string }>()
    const localization = useLocalization()
    const { translate, translateText } = localization

    const [query, setQuery] = useState(props.initialValue ?? "")
    const [searchQuery, setSearchQuery] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [wasAutocompleteSelect, setWasAutocompleteSelect] = useState(false)
    const [error, setError] = useState<Error>()
    const [suggestions, setSuggestions] = useState<Suggestions>({ loading: false, vehicleModels: [], customerVehicles: [] })
    const { quota, invalidateStore } = useVehicleSearchOptionsQuota(RegistrationNoType.DatVin)

    let regNoTypes = useAvailableVehicleSearchOptionsRegNoTypes()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(props.vehicleType)
    const { plateCode } = useCountryCodeToLicensePlate()

    const selectedRegNoType =
        props.selectedSearchConfig && !selectedVehicleLookup.lookupTypeId
            ? props.selectedSearchConfig.lookupTypeId
            : selectedVehicleLookup.lookupTypeId
    regNoTypes = selectedRegNoType !== undefined ? [selectedRegNoType] : regNoTypes

    const { response: { customerVehicles, models } = {} } = useModelsByQueryLoadable({
        vehicleType: props.vehicleType,
        query: searchQuery,
        selectedFilters: {},
        selectedRegistrationNoType: selectedRegNoType,
    })

    useEffect(() => {
        return () => {
            suggestionPromiseVehicleModels?.cancel()
            suggestionPromiseCustomerVehicles?.cancel()
        }
    }, [])

    useEffect(() => {
        setSuggestions({
            loading: false,
            vehicleModels: [],
            customerVehicles: [],
        })
    }, [props.vehicleType])

    useEffect(() => {
        if (!searchQuery || (!customerVehicles && !models)) {
            return
        }

        setSearchQuery(undefined) // Reset search query to prevent second run

        // only automatically attach the customer vehicle if there aren't any tecdoc models found to keep the option to select another vehicle for the user
        if (customerVehicles?.length === 1 && !models?.length) {
            props.onUniqueVehicleFound?.(customerVehicles[0], searchQuery)
        }
        // only automatically attach the tecdoc model if there aren't any customer vehicles found
        // and the tecdoc model wasn't found by the keyword search (which could return fuzzy results) if it wasn't an autocomplete selection
        else if (
            !customerVehicles?.length &&
            models?.length === 1 &&
            (models[0].dataSourceId !== RegistrationNoType.KeywordSearch || wasAutocompleteSelect)
        ) {
            props.onUniqueVehicleFound?.(models[0], searchQuery)
        } else {
            setLoading(false)
            props.onStartSearch(searchQuery, undefined, props.selectedSearchConfig)
        }
    }, [customerVehicles, models, searchQuery, wasAutocompleteSelect, props.onUniqueVehicleFound, props.onStartSearch])

    async function handleRequestSuggestions(requestQuery: string) {
        const requestQueryTrimmed = requestQuery.trim()

        if (!requestQueryTrimmed || requestQueryTrimmed.length < 2) {
            return
        }

        const loadSuggestionsCustomerVehicle = async () => {
            try {
                suggestionPromiseCustomerVehicles = makeCancelable(
                    findGsiVehicles({ query: requestQuery, searchType: VehicleSearchType.All, pageSize: 10 })
                )
                let { vehicles = [] } = (await suggestionPromiseCustomerVehicles.promise) ?? {}

                /** @todo: remove vehicleType filtering as soon as the service provides a way to supply the required vehicleType in the request */
                vehicles = vehicles.filter((x) => x.vehicleType === props.vehicleType)

                setSuggestions({
                    loading: false,
                    vehicleModels: [],
                    customerVehicles: vehicles,
                })
            } catch (result: any) {
                if (!result?.isCanceled) {
                    setSuggestions((prev) => ({ ...prev, loading: false }))
                }
            }
        }

        try {
            setSuggestions((prev) => ({ ...prev, loading: true }))

            suggestionPromiseVehicleModels = makeCancelable(loadVehicleSuggestions(requestQuery, selectedRegNoType, props.vehicleType))

            const suggestions = await suggestionPromiseVehicleModels.promise

            if (!suggestions.length) {
                loadSuggestionsCustomerVehicle()
            } else {
                setSuggestions((prev) => ({
                    ...prev,
                    loading: false,
                    vehicleModels: suggestions,
                }))
            }
        } catch (result: any) {
            if (!result?.isCanceled) {
                loadSuggestionsCustomerVehicle()
            }
        }
    }

    function startSearch(value: string) {
        const trimmedValue = value.trim()
        setQuery(trimmedValue)

        if (loading || !trimmedValue || trimmedValue.length < 2) {
            return
        }

        if (!props.onUniqueVehicleFound) {
            props.onStartSearch(trimmedValue, undefined, props.selectedSearchConfig)
            return
        }

        setLoading(true)
        setError(undefined)
        setSearchQuery(trimmedValue) // changing the search query will trigger the models to be loaded
    }

    function changeQuery(changeQuery: string) {
        const regex = /[^a-zA-Z0-9ßẞöäüÖÄÜ() ,.\-:_;+*#\[\]/\\]/g
        const illegalCharacters = query.match(regex)?.distinct().join("")
        // Replace all not allowed characters:
        // for example:
        // - % (creates invalid urls due to: https://github.com/ReactTraining/history/issues/505)
        // - ! (not supported by database)
        const cleanChangeQuery = changeQuery.replace(regex, "")

        setQuery(cleanChangeQuery)
        setError(illegalCharacters ? { message: translateText(12777).replace("{0}", illegalCharacters) } : undefined)

        if (!cleanChangeQuery) {
            setSuggestions({
                loading: false,
                vehicleModels: [],
                customerVehicles: [],
            })
        }
    }

    function resetQuery() {
        setQuery("")
        setError(undefined)

        setSuggestions({
            loading: false,
            vehicleModels: [],
            customerVehicles: [],
        })

        focus()

        props.onReset?.()
    }

    function handleSearchFieldConfirm(searchFieldQuery: string) {
        setWasAutocompleteSelect(false)
        startSearch(searchFieldQuery)
    }

    function handleSuggestionVehicleModelSelect(suggestion: Hit) {
        setSuggestions({
            loading: false,
            vehicleModels: [],
            customerVehicles: [],
        })
        setWasAutocompleteSelect(true)
        // [NEXT-10873] Start search directly when selecting an suggestion
        startSearch(suggestion.text)
    }

    function handleSuggestionCustomerVehicleSelect(suggestion: VehicleShortInfo) {
        if (!suggestion.id) {
            return
        }

        setSuggestions({
            loading: false,
            vehicleModels: [],
            customerVehicles: [],
        })

        props.onAttachVehicleId?.(suggestion.id, query)
    }

    function handleSearchButtonClick() {
        if (!query) {
            if (props.searchFieldRef) {
                props.searchFieldRef.current?.focus()
            } else {
                searchFieldRef.current?.focus()
            }

            return
        }

        startSearch(query)
    }

    function handleDATSearchButtonClick() {
        const { enableDATVinSearch } = getBundleParams()

        if (!enableDATVinSearch || loading) {
            return
        }

        setLoading(true)

        const { onStartSearch, onUniqueVehicleFound } = props

        searchVehiclesByVin(query, RegistrationNoType.DatVin, true).then(
            (response) => {
                if (onUniqueVehicleFound && response?.models.length === 1) {
                    onUniqueVehicleFound(response?.models[0], query)
                    invalidateStore()
                } else {
                    setLoading(false)
                    onStartSearch(query, RegistrationNoType.DatVin, undefined)
                }
            },
            (errorTextId) => {
                const { datRegistrationPath } = getBundleParams()

                setLoading(false)

                if (errorTextId === VrmLookupErrorTextIds.UserNotRegistered && datRegistrationPath) {
                    // User is not already registered at DAT
                    const datRegistrationRoute = renderRoute(datRegistrationPath, {
                        ...params,
                        workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
                    })

                    handleDATRegistration(datRegistrationRoute).then(
                        () => handleDATSearchButtonClick(),
                        () => setError({ message: `${translateText(1476)} ${translateText(1477)} ${translateText(1478)}`, type: "registrationError" })
                    )
                } else if (errorTextId === VrmLookupErrorTextIds.NoResult || errorTextId === VrmLookupErrorTextIds.ManufacturerNotSupported) {
                    setError({ message: translateText(errorTextId), type: "searchError" })
                } else {
                    setError({ message: translateText(errorTextId) })
                }
            }
        )
    }

    function handleEdsSearchButtonClick() {
        const { edsVinSearchRoute } = getBundleParams()

        if (!edsVinSearchRoute) {
            return
        }

        const queryParams = new URLSearchParams()
        queryParams.set("vin", query)

        const url = `${renderRoute(edsVinSearchRoute, {
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
        })}?${queryParams.toString()}`

        history.push(url)
    }

    function renderSearchButtons() {
        // Currently only show search button when a valid VIN is entered
        if (!isVin(query)) {
            return
        }

        return (
            <div className="search-area">
                <div className="search-area__hint">
                    <Icon name="info" />
                    <Text size="s" modifiers="sub">
                        {translate(1540)}
                    </Text>
                </div>
                {renderDatVinSearchButton()}
                {renderEdsVinSearchButton()}
            </div>
        )
    }

    function renderDatVinSearchButton() {
        const { enableDATVinSearch, datPricePerRequest } = getBundleParams()

        if (!enableDATVinSearch) {
            return
        }

        return (
            <>
                <QuotaText quota={quota} marginY="4px" />
                <Button
                    id="search-area__btn--vin"
                    className="search-area__btn"
                    onClick={handleDATSearchButtonClick}
                    disabled={loading}
                    icon="dat-vin-data"
                    size="l"
                >
                    {translate(1259)}
                    <br />
                    <Text size="xs">{translateText(1260).replace("{0}", datPricePerRequest || "2€")}</Text>
                </Button>
            </>
        )
    }

    function renderEdsVinSearchButton() {
        const { enableEdsVinSearch } = getBundleParams()

        if (!enableEdsVinSearch || props.disableEdsSearch) {
            return
        }

        return (
            <Button
                id="search-area__btn--eds-vin"
                className="search-area__btn"
                onClick={handleEdsSearchButtonClick}
                disabled={loading}
                icon="eds"
                size="l"
            >
                {translate(13391)}
                <br />
                <Text size="xs">{translateText(13392)}</Text>
            </Button>
        )
    }

    function renderDropdownPrefix() {
        if (!error?.message) {
            return renderSearchButtons()
        }

        const vehicleDummy: VehicleData = {
            tecDocModelId: 0,
            manufacturer: "",
            model: "",
            modelSeries: "",
            engineCode: "",
            tecDocTypeId: 0,
            initialRegistration: undefined,
            vin: "",
        }

        return (
            <>
                {renderSearchButtons()}
                <Text className="error" modifiers={["block", "danger"]}>
                    {error.message}
                </Text>
                {error.type === "searchError" && <RequestVinComponent query={query} vehicleData={vehicleDummy} />}
            </>
        )
    }

    function renderSuggestionColumns() {
        return [
            <Table.Column
                key="suggestions-item"
                renderItemContent={(suggestion: Hit) => (
                    <Table.Cell>{convertAutosuggestHit(suggestion.highlight ?? suggestion.text, AutosuggestHit)}</Table.Cell>
                )}
            />,
        ]
    }

    function getPlaceholder() {
        switch (props.vehicleType) {
            case VehicleType.PassengerCar:
                const translationRegex = /\{\{(.*?)\}\}/
                const placeholderPassengerCar = props.placeholderPassengerCar?.replace(translationRegex, (s, num) => translateText(num))
                return props.placeholderPassengerCar ? placeholderPassengerCar : getVehicleSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.Motorcycle:
                return getBikeSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchPlaceholder(regNoTypes, translateText)
            default:
                return undefined
        }
    }

    function getTooltip() {
        switch (props.vehicleType) {
            case VehicleType.PassengerCar:
                return getVehicleSearchTooltip(regNoTypes, translateText, searchType.VEHICLE_TYPE)
            case VehicleType.Motorcycle:
                return getBikeSearchTooltip(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchTooltip(regNoTypes, translateText)
            default:
                return undefined
        }
    }

    const { forceShowTooltipOnHover, disable: disabled } = props

    const useCustomerVehiclesAsSuggestions = suggestions.customerVehicles.length && !suggestions.vehicleModels.length

    const suggestProps: SuggestionFieldProps<Hit | VehicleShortInfo> = {
        loading: loading || suggestions.loading,
        value: query,
        disabled,
        forceShowTooltipOnHover,
        className: "vehicle-search-field",

        onChange: changeQuery,
        onChangeConfirm: handleSearchFieldConfirm,
        onSuggestionSelect: useCustomerVehiclesAsSuggestions ? handleSuggestionCustomerVehicleSelect : handleSuggestionVehicleModelSelect,
        onChangeReset: resetQuery,

        suggestions: useCustomerVehiclesAsSuggestions ? suggestions.customerVehicles : suggestions.vehicleModels,
        requestSuggestions: handleRequestSuggestions,
        renderTableColumns: useCustomerVehiclesAsSuggestions
            ? () => renderVehicleShortInfoAutosuggestColumns(suggestions.customerVehicles, localization, plateCode)
            : renderSuggestionColumns,
        suggestDelay: 500,

        dropdownPrefix: renderDropdownPrefix(),

        placeholder: getPlaceholder(),
        tooltip: regNoTypes ? getTooltip() : undefined,
        showClearTooltip: translateText(48),
    }

    return (
        <SuggestionFieldButtonGroup
            {...suggestProps}
            ref={props.searchFieldRef ?? searchFieldRef}
            handleSearchButtonClick={handleSearchButtonClick}
            additionalInputIcons={props.showVrcScanButton && props.vehicleType === VehicleType.PassengerCar && <VrcScanButton />}
        />
    )
}
