import { ActionDispatch, BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { combinedReducer, MainActionsType, reduce } from "./business"
import component from "./component"


const managerComponent: BundleComponent = {
    name: "main",
    component: withStoreProvider(component as any),
    reduce,
    receive: (action: MainActionsType, dispatch: ActionDispatch<MainActionsType>) => {
        if (action.type == "EUROTAX_RESET_STORE")
            dispatch({ type: "@@redux/INIT" })
    },
    transmit: (action: MainActionsType) => {
        if (action.type == "MODULE_OPENED")
            return action

    }
}


export * from  "./business"

export type MainState = ReturnType<typeof combinedReducer>

export default managerComponent