import { useLocalization } from "@tm/localization"
import { CustomWork, VatRate, WorkCategory, WorkType, NoteTypes } from "@tm/models"
import { Button, CellContentPosition, Icon, Table, TableCellData, TableColumnData, Tooltip, NotesButton, Loader, Typography } from "@tm/components"
import { getCategoryOfWorkDescriptionTextId, useVatRateText } from "@tm/utils"
import { WorkTaskInfo } from "@tm/context-distribution"
import WorkActions from "./WorkActions"
import { BasketWork } from "../../../../models"
import WorkPrice from "./WorkPrice"
import WorkTime from "./WorkTime"
import WorkEditor from "./WorkEditor"
import { CostEstimation, CustomerDefaults } from "../../../../data/model"
import { EditWorkMode } from "../../business"
import WorkFooter from "./WorkFooter"

type Props = {
    calculationLoading?: boolean
    creatingCostEstimation: boolean
    costEstimation?: CostEstimation
    repairTimeDivision: number
    showManufacturer: boolean
    showRepairTimesInHours: boolean
    showSupplierArticleNumbers: boolean
    useRepairTimeCalculation?: boolean
    workEstimationLoading: boolean
    workTask: WorkTaskInfo
    works: BasketWork[]
    onEditWork(
        work: BasketWork,
        repairTimeDivision: number,
        categoryOfWork: WorkCategory,
        description: string,
        editWorkMode: EditWorkMode,
        customerDefaults?: CustomerDefaults,
        workNumber?: string,
        time?: number,
        hourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate
    ): void
    onIncludeExcludeWork(part: BasketWork): void
    onOpenCloseWorkEditor(id: string): void
    onRemoveWorks(workIds: string[]): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
    onResetRepairTimes(work: BasketWork): void
    onSelectWork(workId: string): void
    onShowHideIncludes(workIds: string[]): void
}

export default function WorksTableComponent(props: Props) {
    const {
        calculationLoading,
        creatingCostEstimation,
        works,
        showManufacturer,
        showSupplierArticleNumbers,
        useRepairTimeCalculation,
        showRepairTimesInHours,
        workEstimationLoading,
        workTask,
        costEstimation,
        repairTimeDivision,
    } = props

    const { translate, translateText, number, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText, number)

    function renderRebate(rebate?: number) {
        if (!rebate) {
            return null
        }
        return `${number(rebate, 0)} %`
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: "" }]
        if (showSupplierArticleNumbers) {
            columns.push({ header: <Typography variant="body2">{translate(58)}</Typography> })
        }

        columns.push({ header: <Typography variant="body2">{translate(25)}</Typography> }, { header: "" }, { header: "" })

        if (showManufacturer) {
            columns.push({ header: <Typography variant="body2">{translate(278)}</Typography> })
        }

        const repairTimesTextId = showRepairTimesInHours ? 84 : 1550
        columns.push(
            { header: <Typography variant="body2">{translate(59)}</Typography> },
            { header: <Typography variant="body2">{translate(63)}</Typography>, alignContent: CellContentPosition.right },
            { header: <Typography variant="body2">{translate(repairTimesTextId)}</Typography>, alignContent: CellContentPosition.right }
        )

        if (useRepairTimeCalculation) {
            columns.push({
                header: <Typography variant="body2">{`${translate(repairTimesTextId)} ${translate(85)}`}</Typography>,
                alignContent: CellContentPosition.right,
            })
        }

        columns.push(
            { header: <Typography variant="body2">{translate(54)}</Typography>, alignContent: CellContentPosition.right },
            { header: <Typography variant="body2">{translate(706)}</Typography>, alignContent: CellContentPosition.right },
            { header: <Typography variant="body2">{translate(57)}</Typography>, alignContent: CellContentPosition.right },
            { header: "", alignContent: CellContentPosition.right }
        )
        return columns
    }

    function getModuleCellData(work: BasketWork): TableCellData[] {
        const { workItem, estimatedWork, calculatedWork, states } = work
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Button
                        disabled={!workItem.includedWorks?.some((includedWork) => includedWork.isVisible)}
                        startIcon={<Icon name={states.isExpanded ? "up" : "down"} />}
                        title={translateText(61)}
                        onClick={() => props.onShowHideIncludes([workItem.id])}
                        variant="text"
                    />
                ),
                id: "work_1",
            },
        ]

        if (showSupplierArticleNumbers) {
            cellData.push({
                displayValue: (
                    <Typography variant="body2">
                        {estimatedWork?.displayNumber || workItem.displayNumber || estimatedWork?.providerWorkId || workItem.providerWorkId}
                    </Typography>
                ),
                id: "work_2",
            })
        }

        cellData.push(
            { displayValue: <Typography variant="body2">{estimatedWork?.description || workItem.description}</Typography>, id: "work_3" },
            {
                displayValue: (workItem.type === WorkType.CustomWork || workItem.type === WorkType.CustomMainWork) && (
                    <Tooltip variant="light" title={workItem.type === WorkType.CustomWork ? translateText(920) : translateText(1473)}>
                        <Icon name={workItem.type === WorkType.CustomWork ? "individual-repairtimes" : "article-related-repairtimes"} />
                    </Tooltip>
                ),
                id: "work_4",
            },
            {
                displayValue:
                    estimatedWork?.note || workItem.note ? (
                        <NotesButton
                            articleNotes={[
                                { type: NoteTypes.VEHICLE_ARTICLE, message: estimatedWork?.note || workItem.note, title: translateText(12874) },
                            ]}
                            hasNote={!!workItem.note}
                            iconOnly
                        />
                    ) : undefined,
                id: "work_5",
            }
        )

        if (showManufacturer) {
            cellData.push({ displayValue: <Typography variant="body2">{workItem.providerName}</Typography>, id: "work_6" })
        }

        cellData.push(
            {
                displayValue: (
                    <Typography variant="body2" title={translateText(getCategoryOfWorkDescriptionTextId(workItem.categoryShortCode))}>
                        {workItem.categoryShortCode}
                    </Typography>
                ),
                id: "work_7",
            },
            {
                displayValue: (
                    <Typography variant="body2">
                        <WorkPrice work={work} loading={workEstimationLoading || calculationLoading} />
                    </Typography>
                ),
                id: "work_8",
            },
            {
                displayValue: (
                    <Typography variant="body2">
                        <WorkTime
                            loading={workEstimationLoading || calculationLoading}
                            timeValue={calculatedWork?.repairTime ?? estimatedWork?.displayTime?.value}
                            hasErrors={estimatedWork?.hasCalculationError}
                        />
                    </Typography>
                ),
                id: "work_9",
            }
        )
        if (useRepairTimeCalculation) {
            cellData.push({
                displayValue: (
                    <Typography variant="body2">
                        <WorkTime
                            loading={workEstimationLoading || calculationLoading}
                            timeValue={calculatedWork?.repairTimeCalculated ?? estimatedWork?.displayTime?.calculatedValue}
                            hasErrors={estimatedWork?.hasCalculationError}
                        />
                    </Typography>
                ),
                id: "work_10",
            })
        }

        const vatRate = workItem.vatRates?.find((v) => v.isSelected)
        cellData.push(
            { displayValue: renderRebate(calculatedWork?.rebate || workItem.rebate), id: "work_11" },
            {
                displayValue: vatRate ? (
                    <Typography variant="body2">
                        {vatRateTypeDisplayShortText({
                            vatRate: calculatedWork?.vatRate.vatRate || vatRate.vatValue,
                            vatType: calculatedWork?.vatRate.vatType || vatRate.vatType,
                        })}
                    </Typography>
                ) : (
                    "-"
                ),
                id: "work_12",
            },
            {
                displayValue: calculationLoading ? (
                    <Loader size="small" />
                ) : (
                    <Typography variant="body2">
                        {currency(calculatedWork?.offerPrice || 0, workItem.currencySymbol || workItem.currencyCode)}
                    </Typography>
                ),
                id: "work_13",
            },
            {
                displayValue: (
                    <Typography variant="body2">
                        <WorkActions
                            disableControls={!!creatingCostEstimation || !!workEstimationLoading || work.states.isBeingUpdated}
                            work={work}
                            onRemoveWorks={props.onRemoveWorks}
                            onIncludeExcludeWork={props.onIncludeExcludeWork}
                            onSelectWork={props.onSelectWork}
                            onOpenWorkEditor={props.onOpenCloseWorkEditor}
                            onResetRepairTimes={props.onResetRepairTimes}
                        />
                    </Typography>
                ),
                id: "work_14",
            }
        )
        return cellData
    }

    const displayData = works?.map((work, index) => ({
        cells: getModuleCellData(work),
        id: `${index}`,
        customRow: work.states.isEditorOpen && (
            <WorkEditor
                costEstimation={costEstimation}
                showRepairTimesInHours={showRepairTimesInHours}
                work={work}
                workTask={workTask}
                repairTimeDivision={repairTimeDivision}
                onCloseWorkEditor={props.onOpenCloseWorkEditor}
                onEditWork={props.onEditWork}
                onReplaceWorkWithCustomWork={props.onReplaceWorkWithCustomWork}
            />
        ),
        replaceCustomRow: work.states.isEditorOpen,
        active: false,
        extendedContent: <WorkFooter work={work} showSupplierArticleNumbers={showSupplierArticleNumbers} />,
    }))

    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" rowCap={4} />
}
