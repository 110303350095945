import { ajax, getStoredAuthorization } from "@tm/utils"
import { TokenRequest, NavigationLinksEnum } from "../../models"
import { getBundleParams } from "../../utils"

export function getTokenResponse<T>(methodName: NavigationLinksEnum, token: string): Promise<T | undefined> {
    const url = `${getBundleParams().edsServiceUrl}/${methodName}`
    const authorization = getStoredAuthorization()
    const body: TokenRequest = { token }
    return ajax<T>({ url, body, authorization, method: "POST" })
}
