import { getStoredAuthorization, ajax } from "@tm/utils"
import { ChangeItemsResponse, ImportOrderVoucherItemsRequest, Item, OrderItem, OrderResponse } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { mapShowCentralOrder } from "../../mapper"
import {
    SendWorkTaskOrderRequest,
    ShowWorkTasksResponse,
    SendCentralOrderRequest,
    ShowWorkTasksRequest,
    ShowCentralOrderRequest,
    ShowCentralOrderResponse,
    ChangeItemDistributorRequest,
    ChangeQuantityResponse,
} from "../../model"
import { getBasketServiceUrl } from "../.."
import { notifyBasketChanges } from "../../../helpers"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Order`
}

// TODO: Delete when no longer used from the registered models
export function showItem(id: string): Promise<OrderItem> {
    const url = `${getServiceUrl()}/ShowItem`
    const authorization = getStoredAuthorization()
    const body = { id }

    return new Promise<OrderItem>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(response && response.item), reject)
    )
}

export function showWorkTasks(body: ShowWorkTasksRequest) {
    const url = `${getServiceUrl()}/ShowWorkTasks`
    const authorization = getStoredAuthorization()
    return ajax<ShowWorkTasksResponse>({ url, body, authorization }, undefined, undefined, true)
}

export function showCentralOrder(body: ShowCentralOrderRequest): Promise<ShowCentralOrderResponse> {
    const url = `${getServiceUrl()}/ShowCentralOrder`
    const authorization = getStoredAuthorization()

    return new Promise<ShowCentralOrderResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((response) => {
            response = mapShowCentralOrder(response)
            response ? resolve(response) : reject()
        }, reject)
    )
}

// TODO: Check all endpoints with workTask?: WorkTaskInfo as parameter. A workaround for notifyBasketChanges should be possible from hook
export function removeItemList(workTaskId: string, idList: Array<string>, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/RemoveItemList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, idList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function removeAllWorkTaskItems(workTaskId: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/RemoveAllWorkTaskItems`
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function changeItemQuantity(item: Item, quantityValue: number, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/ChangeItemQuantity`
    const authorization = getStoredAuthorization()
    const body = { item, quantityValue }

    return ajax<ChangeQuantityResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function excludeItemList(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeItemList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function includeItemList(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeItemList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function attachItemMemo(item: Item, memo: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/AttachItemMemo`
    const authorization = getStoredAuthorization()
    const body = { item, memo }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function editItemMemo(item: Item, memo: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/EditItemMemo`
    const authorization = getStoredAuthorization()
    const body = { item, memo }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function removeItemMemo(item: Item, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/RemoveItemMemo`
    const authorization = getStoredAuthorization()
    const body = { item }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function sendWorkTaskOrder(body: SendWorkTaskOrderRequest) {
    const url = `${getServiceUrl()}/SendWorkTaskOrder`
    const authorization = getStoredAuthorization()
    return ajax<OrderResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}

export function sendCentralOrder(body: SendCentralOrderRequest) {
    const url = `${getServiceUrl()}/SendCentralOrder`
    const authorization = getStoredAuthorization()

    return ajax<Array<OrderResponse>>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}

export function changeItemDistributor(body: ChangeItemDistributorRequest) {
    const url = `${getServiceUrl()}/ChangeItemDistributor`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function importOrderVoucherItems(body: ImportOrderVoucherItemsRequest) {
    const url = `${getServiceUrl()}/ImportVoucherItems`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}
