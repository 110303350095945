import { Loader, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TreeNode } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { forwardRef } from "react"
import { SearchTreeProps } from ".."
import { isClassicalSearchTree } from "../../../../helper"
import Node from "../components/node"

const SearchTree = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    columnGap: "1em",
    rowGap: "15px",
    padding: "2em 1em 1em",
})

const StyledNode = styled(Node)<{ iconColor: string; isClipped: boolean }>(({ iconColor, theme, isClipped }) => {
    const iconFillColorMain = (theme.palette as any)[iconColor]?.main || undefined
    const iconFillColorHover = (theme.palette as any)[iconColor]?.light || undefined
    const nameMaxWidth = isClipped ? 56 : "none"

    return {
        display: "flex",
        flexDirection: "column",
        "&.tree__node": {
            height: "auto",
        },
        ".tree__node__thumbnail-wrapper": {
            height: "auto",
        },
        ".icon": {
            width: 36,
            height: 36,
            fill: iconFillColorMain,
        },
        ":hover .icon": {
            fill: iconFillColorHover,
        },
        ".tree__node__name": {
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis",
            minHeight: "2.6em",
            whiteSpace: "nowrap",
            display: "inline-block",
            maxWidth: nameMaxWidth,
        },
    }
})

type ClassicFastClickSearchTreeProps = Pick<SearchTreeProps, "groups" | "loading" | "treeId" | "clipped"> & {
    onSelect(node: TreeNode): void
}

const ClassicFastClickSearchTree = forwardRef<HTMLDivElement, ClassicFastClickSearchTreeProps>((props, ref) => {
    const { loading, groups, treeId, onSelect } = props
    const { translateText } = useLocalization()
    const iconColor = Morpheus.getParams("parts").treeIconColor

    return (
        <SearchTree ref={ref}>
            {groups.map((node) => (
                <StyledNode
                    isClipped={props.clipped ?? false}
                    iconColor={iconColor}
                    treeId={treeId}
                    node={node}
                    key={node.id}
                    size="s"
                    onSelect={onSelect}
                    thumbnailTitle={translateText(1003)}
                    hideThumbnailOnly={isClassicalSearchTree(treeId)}
                    dontUseIconWidth
                />
            ))}
            {loading && <Loader />}
        </SearchTree>
    )
})

export default ClassicFastClickSearchTree
