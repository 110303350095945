import { PayloadAction, ActionDispatch } from "@tm/morpheus"
import { NavigationState } from "./model"
import { BundleActionType } from "../../../business"

export * from "./model"

export type ComponentActionType = BundleActionType | { type: "CLOSE_WORKTASK"; payload: string }

export type SectionType = "list" | "details"

const DEFAULT_STATE: NavigationState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "EXPORT_URI_CHANGED": {
            return {
                ...state,
                exportUri: action.payload.uri,
                exportLabel: action.payload.exportLabel,
            }
        }
        default:
            break
    }

    return state
}

export function transmit(action: PayloadAction<ComponentActionType>): PayloadAction<ComponentActionType> | undefined {
    switch (action.type) {
        case "TOGGLE_PRINT_VEHICLE_IMAGE":
        case "TOGGLE_SHOW_ARTICLE_NUMBERS":
        case "TOGGLE_SHOW_MANUFACTURERS":
        case "TOGGLE_SHOW_WHOLESALER_ARTICLE_NUMBERS":
        case "START_CREATE_COST_ESTIMATION":
        case "CLOSE_WORKTASK":
            return action
        default:
            break
    }
}
