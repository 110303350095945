import { useCallback, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { StatusSwitch } from "@tm/controls"
import { Button, Typography, Stack, Icon, Modal, SelectorPaper } from "@tm/components"
import { IMicros, OrderItem, DataPool, FittingPosition, channel, RequestArticleDetailsPayload, PartsBundleParams } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { notUndefinedOrNull } from "@tm/utils"
import { BasketPart } from "../../../../../../models"
import DeleteButton from "../../../../DeleteButton"
import { ArticleComparison } from "../../../../../../../../parts/src/components/list/components/comparison"
import { openPartDetailsInModal } from "../../../../../../../../parts/src/utils/modalHelper"
import { getArticleUniqueId } from "../../../../../../../../parts/src/components/list/business/helpers"

// TODO: Check if Compilations can be refactored and if this mapper is required since it could be from the beginnning be mapped in a new property of the BasketItem
export function mapBasketPartItemToOrderItem(workTaskId: string, part: BasketPart): OrderItem {
    const { partItem, bonusSystemsWithoutPoints } = part
    return {
        articleInfo: {
            description: partItem.articleInformation.description,
            additionalDescription: partItem.articleInformation.additionalDescription,
            wholesalerArticleNumber: partItem.articleInformation.wholesalerArticleNumber,
            dataSupplierArticleNumber: partItem.articleInformation.articleNumber,
            dataSupplier: {
                id: partItem.articleInformation.supplierId ?? -1,
                name: partItem.articleInformation.supplierName ?? "",
                dataPool: DataPool.TecDoc,
            },
            productGroup: {
                id: partItem.articleInformation.productGroupId ?? -1,
                name: partItem.articleInformation.productGroupName ?? "",
            },
            thumbnailUrl: partItem.articleInformation.thumbnailUrl,
            availableBonusSystems: bonusSystemsWithoutPoints,
            productGroupId: partItem.articleInformation.productGroupId,
            // vehicleManufacturer: {
            //     id: partItem.oeArticleInfo?.vehicleManufacturerId ?? -1,
            //     name: partItem.oeArticleInfo?.vehicleManufacturerName ?? "",
            // },
            // oePrice: {
            //     amount: partItem.oeArticleInfo?.oePrice ?? 0,
            //     currencyCode: partItem.oeArticleInfo?.currencyCode ?? "",
            //     currencySymbol: partItem.oeArticleInfo?.currencySymbol ?? "",
            // },
            // articleNumber: partItem.customArticleInfo?.articleNumber,
            // manufacturerName: partItem.wholesalerArticleInfo?.manufacturerName,
            // productGroupName: partItem.wholesalerArticleInfo?.productGroupName,
        },
        articleInfoType: partItem.articleInformation.articleInfoType,
        fittingSide: partItem.fittingSide ?? FittingPosition.None,
        foundBySearchTerm: partItem.foundBySearchTerm ?? "",
        id: partItem.id,
        isIncludedInOrder: partItem.orderItem?.isIncluded ?? false,
        isOrderable: partItem.orderItem?.isOrderable ?? false,
        memo: partItem.orderItem?.memo ?? "",
        quantity: { ...partItem.quantity, packagingUnit: "" },
        version: partItem.version,
        workTaskId,
        advertisementCategoryId: partItem.orderItem?.advertisementCategoryId,
        customerId: partItem.customerId,
        itemRole: partItem.itemRole,
        linkedItems: part.linkedItems?.map((linkedItem) => mapBasketPartItemToOrderItem(workTaskId, linkedItem)),
        mainPartItemId: partItem.mainPartId,
        replacedPartInfo: partItem.orderItem?.replacedPartInfo,
        vehicleId: partItem.vehicleId,
    }
}

enum OrderStatus {
    allInlcuded = 1,
    noneIncluded = 2,
    someIncluded = 3,
}

type Props = {
    allSelected: boolean
    disableControls?: boolean
    selectedParts: Array<BasketPart>
    workTaskId: string
    hideAddToBasketButtonInArticleComparison?: boolean
    onSelectAll(): void
    onUnselectAll(): void
    onIncludeExcludeParts(parts: BasketPart[]): void
    onRemoveParts(partIds: string[]): void
    onUnselectBasketPart(id: string): void
}

export default function PartsSelection(props: Props) {
    const {
        workTaskId,
        allSelected,
        selectedParts,
        disableControls,
        hideAddToBasketButtonInArticleComparison,
        onSelectAll,
        onUnselectAll,
        onIncludeExcludeParts,
        onRemoveParts,
        onUnselectBasketPart,
    } = props
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [openCompare, setOpenCompare] = useState(false)
    const itemCount = selectedParts.length
    const articleCount = selectedParts.map((part) => part.partItem.quantity.value).reduce((sum, current) => sum + current)
    const selectedIds = selectedParts.map((part) => part.partItem.id)
    const selectedPartsIncludedInOrder = selectedParts.some((part) => part.partItem.orderItem?.isIncluded && part.erpInfoResponse?.isOrderable)
    const selectedPartsNotOrderable = selectedParts.some((part) => !part.erpInfoResponse?.isOrderable || !part.partItem.orderItem?.isOrderable)
    const partsBeingUpdated = selectedParts.some((part) => part.states.isBeingUpdated)
    const orderStatus = getPartsOrderStatus()

    function getPartsOrderStatus(): OrderStatus {
        if (selectedParts.every((part) => part.partItem.orderItem?.isIncluded && part.erpInfoResponse?.isOrderable)) {
            return OrderStatus.allInlcuded
        }
        if (selectedParts.every((part) => !part.partItem.orderItem?.isIncluded || !part.erpInfoResponse?.isOrderable)) {
            return OrderStatus.noneIncluded
        }
        return OrderStatus.someIncluded
    }

    function renderUpdateOrderStatusSwitch() {
        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (selectedPartsIncludedInOrder && orderStatus === OrderStatus.allInlcuded) {
            status = "checked"
        } else if (selectedPartsIncludedInOrder && orderStatus === OrderStatus.someIncluded) {
            status = "checked-not-equal"
        } else if (!selectedPartsIncludedInOrder && orderStatus === OrderStatus.noneIncluded) {
            status = "unchecked"
        } else if (!selectedPartsIncludedInOrder && orderStatus === OrderStatus.someIncluded) {
            status = "unchecked-not-equal"
        }

        return (
            <StatusSwitch
                label={translateText(292)}
                alignLabel="left"
                status={status}
                disabled={disableControls || selectedPartsNotOrderable || partsBeingUpdated}
                onChange={() => onIncludeExcludeParts(selectedParts)}
            />
        )
    }

    // TODO: The compialtions service should also be refactored, so the types are similar to the ones from the refactoring
    const renderAddToCompilation = useCallback(() => {
        const compilationMicro = renderMicro("compilations", "add-to-compilation-basket", {
            articles: selectedParts.map((part) => mapBasketPartItemToOrderItem(workTaskId, part)),
        })

        if (compilationMicro) {
            return <>{compilationMicro}</>
        }

        return null
    }, [selectedParts, renderMicro])

    const disabledComparisionButton = useMemo(() => {
        return selectedParts.length <= 1 || selectedParts.some((part) => !part.article)
    }, [selectedParts])

    function handleRequestArticleDetails(request: RequestArticleDetailsPayload) {
        const { partsRoutes } = Morpheus.getParams<PartsBundleParams>("parts")

        if (!workTaskId) {
            openPartDetailsInModal(request, {}, partsRoutes)
        } else {
            channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
        }
    }

    function handleUnselectArticle(articleUniqueId: string) {
        const basketPart = selectedParts.find((part) => part.article && getArticleUniqueId(part.article) === articleUniqueId)
        if (basketPart) {
            onUnselectBasketPart(basketPart.partItem.id)
        }
    }

    return (
        <>
            <SelectorPaper>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="label">
                        {translateText(701)}: {itemCount} {translateText(479)}, {articleCount} {translateText(90)}
                    </Typography>
                    <Button onClick={() => (allSelected ? onUnselectAll() : onSelectAll())} size="small">
                        {translateText(allSelected ? 703 : 702)}
                    </Button>
                    <Button onClick={() => setOpenCompare(true)} disabled={disabledComparisionButton} size="small">
                        {translateText(1817)}
                    </Button>
                    {renderAddToCompilation()}
                    {renderUpdateOrderStatusSwitch()}
                    <DeleteButton
                        ids={selectedIds}
                        buttonTitleId={624}
                        disableButton={disableControls}
                        dialogTextId={834}
                        onConfirmRemove={(ids) => ids && onRemoveParts(ids)}
                    />
                    <Button
                        variant="text"
                        startIcon={<Icon name="cancel-x" />}
                        size="large"
                        title={translateText(317)}
                        onClick={() => onUnselectAll()}
                    />
                </Stack>
            </SelectorPaper>
            <Modal open={openCompare} onOutsideClick={() => setOpenCompare(false)}>
                <ArticleComparison
                    partsToCompare={selectedParts.map((part) => part.article).filter(notUndefinedOrNull)}
                    previouslyOrderedArticles={[]}
                    onUnselectArticle={handleUnselectArticle}
                    showArticleImages
                    onRequestArticleDetails={handleRequestArticleDetails}
                    hideAddToBasketButton={hideAddToBasketButtonInArticleComparison}
                />
            </Modal>
        </>
    )
}
