import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenArticleList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openArticleList } = data

    if (openArticleList) {
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", openArticleList)
    }
}
