import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()

    const styles: NestedCSSProperties = {
        $nest: {
            "&__actions": {
                position: "absolute",
                bottom: 0,
                display: "flex",
            },
            "&__input__wrapper": {
                padding: ".5em 0 .5em .75em",
            },
            "&__search-resultlist": {
                top: "36px",
            },
            "&__search": {
                $nest: {
                    ".btn:last-child": {
                        padding: "0.5em 0.5em",
                        backgroundColor: colors.primary,
                        $nest: {
                            ".icon": {
                                fill: colors.light,
                            },
                        },
                    },
                },
            },
        },
    }

    return deepAssign(baseStyles, styles)
}
