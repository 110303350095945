import { Stack } from "@tm/components"
import { usePartsModuleState } from "../../../PartsModuleState"
import { CostEstimationButton } from "./CostEstimationButton"
import { AddOeToBasket } from "./AddOeToBasket"

export default function OeTransactions() {
    const { hideCostEstimationButton, highlightCostEstimationButton } = usePartsModuleState((x) => x.options)

    return (
        <Stack spacing={0.5} direction="row" justifyContent="end">
            <AddOeToBasket />

            {!hideCostEstimationButton && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <CostEstimationButton onAddToCostEstimation={() => {}} highlightCostEstimationButton={highlightCostEstimationButton} disabled />
            )}
        </Stack>
    )
}
