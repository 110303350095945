import { useRef, useMemo, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Dialog } from "@tm/controls"
import { style } from "typestyle"
import { Alert, Stack, Typography } from "@tm/components"
import { useHistory } from "react-router"
import { GlobalOrderOptionsComponent } from "../_shared/order-options/global"
import WorkTasks from "./components/WorkTasks"
import { PartsListConfigProps } from "../_shared/parts-list/component"
import { WorkTaskOrderButtonConfigProps } from "../_shared/order-button/worktask"
import { useGlobalOrderOptionsState } from "../../hooks/useGlobalOrderOptionsState"
import { useCentralOrderWorkTasks } from "../../data/hooks/useCentralOrderWorkTasks"

const dialogClassName = style({
    $nest: {
        ".dialog-prompt__pre-text": {
            fontSize: "1.2rem",
            lineHeight: 1.3,
            marginBottom: "1rem",
        },
        ".dialog-prompt__text": {
            fontSize: "1rem",
            lineHeight: 1.3,
            marginBottom: "1rem",
        },
        ".dialog-prompt__close": {
            display: "none",
        },
        ".dialog-prompt__buttons .icon": {
            display: "none",
        },
    },
})

type Props = {
    partListProps: PartsListConfigProps
    workTaskOrderButtonProps: WorkTaskOrderButtonConfigProps
}

let originPush: any

/**
 * CentralOrderComponent, all handling for the central Order
 * @memberof Basket
 * @param props
 * @returns
 */
export default function CentralOrderComponent(props: Props) {
    const { translateText } = useLocalization()
    const history = useHistory()
    const [pushArgs, setPushArgs] = useState<any>()
    const dialogRef = useRef<Dialog>(null)
    const { globalOrderOptionsEnabled, centralOrderParameters } = useGlobalOrderOptionsState()
    const { workTasks } = useCentralOrderWorkTasks().state

    const { includedInOrder } = useMemo(() => {
        const included: Array<string> = []
        workTasks.forEach((w) => {
            if (w.includedInOrder) {
                included.push(w.workTask.workTaskId)
            }
        })
        return { includedInOrder: included }
    }, [workTasks])

    function handleConfirmLeave() {
        originPush && pushArgs && originPush(pushArgs[0], pushArgs[1])
    }

    function handleCancelLeave() {
        setPushArgs(undefined)
    }

    function historyPush(...args: any[]) {
        if (args[0].indexOf("/order?") === -1) {
            // details url begins with "?""
            setPushArgs(args)
            dialogRef.current?.show()
        } else {
            originPush && originPush(args[0], args[1])
        }
    }

    function revertOriginPush() {
        if (originPush) {
            history.push = originPush
        }
    }

    useEffect(() => {
        if (!originPush) {
            originPush = history.push
        }
        history.push = historyPush
        return revertOriginPush
    }, [])

    return (
        <Stack spacing={1} flex={1} margin={2}>
            <Stack direction="row" justifyContent={globalOrderOptionsEnabled ? "space-between" : "flex-end"}>
                {globalOrderOptionsEnabled && <GlobalOrderOptionsComponent />}
                <Stack spacing={1} mr={2}>
                    <Typography variant="body2">
                        {translateText(13132).replace("{0}", includedInOrder.length.toString()).replace("{1}", workTasks.length.toString())}
                    </Typography>
                    {centralOrderParameters.useOrderCollection && (
                        <Alert
                            severity="info"
                            title={translateText(13131).replace("{0}", centralOrderParameters.orderPagingSize.toString())}
                            size="small"
                        />
                    )}
                </Stack>
            </Stack>
            {/* TODO: props.ordersUrl cannot be used for WorkTasks' ordersUrl since some catalogs like WM and default don't retunr the same order id as in vouchers */}
            <WorkTasks {...props} />
            <Dialog
                skin="warning"
                className={dialogClassName}
                ref={dialogRef}
                preText={translateText(2005)}
                text={translateText(2006)}
                confirmButtonText={translateText(584)}
                cancelButtonText={translateText(585)}
                iconName="warning-dark"
                onConfirm={handleCancelLeave}
                onCancel={handleConfirmLeave}
            />
        </Stack>
    )
}
