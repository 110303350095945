import { IBundle } from "@tm/morpheus"
import OrderHistoryTotals from "./components/order-history-totals"
import VouchersList from "./components/vouchers-list"
import Widget from "./components/widget"
import { initVouchersBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Widget, OrderHistoryTotals, VouchersList],
    init: initVouchersBundle,
}

export default bundle
