import { useMemo } from "react"
import { Article, ArticleReference, NoteTypes } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { getBundleParams } from "../../../../utils"
import { AdditionalInformationLink, ArticleAttributes, ArticleReferenceParms, DisplayArticle } from "../../models"
import { useVehicleRecordsData } from "../useVehicleRecords"
import { getIconFromMediaType, mapArticleReferences, mapAttributes } from "./mapper"

export function useDisplayArticle(article: Article, supplierLogo?: string): DisplayArticle {
    const partsUiConfig = getBundleParams()
    const { attributes: vehicleRecordsAttributes } = useVehicleRecordsData()
    const hideDealerPartNumber = useUser()?.userContext.parameter.hideDealerPartNumber

    // Hersteller infos
    const supplier = useMemo<DisplayArticle["supplier"]>(
        () => ({
            supplier: article.supplier,
            supplierLogo,
        }),
        [article.supplier, supplierLogo]
    )

    return useMemo(() => {
        // Artikelbilder
        const image: DisplayArticle["image"] = {
            thumbnailUrl: article.thumbnail,
            thumbnailDescription: undefined,
            thumbnailFallbackUrl: undefined,
            enableLargeView: false,
            customThumbnailComponent: undefined,
            startIndex: undefined,
            onClick: undefined,
        }

        // Bekommt man sicher auch noch hübscher hin, weiß grade nicht wie :D
        let copyButtonTrader = false
        let copyButtonSupplier = false
        if (typeof partsUiConfig.showCopyNumberButton === "object" || Array.isArray(partsUiConfig.showCopyNumberButton)) {
            copyButtonTrader = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--trader")
            copyButtonSupplier = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--supplier")
        }

        // Artikelnummer
        const numbers: DisplayArticle["numbers"] = {
            hideDealerPartNumber,
            traderArticleNo: article.traderArticleNo,
            supplierArticleNo: article.supplierArticleNo,
            copyButtonTrader,
            copyButtonSupplier,
        }

        // Bonussystem
        const bonus: DisplayArticle["bonus"] = {
            id: article.availableBonusSystems?.[0].id,
            name: article.availableBonusSystems?.[0].name,
            imageUrl: article.availableBonusSystems?.[0].imageUrl,
            value: article.availableBonusSystems?.[0].value,
        }

        // Artikelbeschreibung
        const description: DisplayArticle["description"] = {
            position: "bottom",
            traderDescriptopnPosition: "bottom",
            additionalDescriptions: article.additionalReferenceArticleInformation?.headInformation,
            missingVehicleConnectionTitle: undefined,
            description: article.description,
        }

        // Artikelattribute TOP
        const attributes: DisplayArticle["attributes"] = article.attributes.map((attributes) => {
            const result: ArticleAttributes = {
                topArticleAttributes: mapAttributes([...attributes.topAttributes], article.productGroup.id, vehicleRecordsAttributes),
                additionalAttributes: mapAttributes(
                    [...attributes.vehicleAttributes, ...attributes.partsListAttributes, ...attributes.articleAttributes],
                    article.productGroup.id,
                    vehicleRecordsAttributes
                ),
            }

            return result
        })

        // split References to IMS Informations and default References, should be changed in Service if it is possible
        const imsReferences: ArticleReferenceParms[] = []
        const rawArticleReferences: ArticleReference[] = []

        article.references.forEach((ref) => {
            if (ref?.modificationState) {
                imsReferences.push(mapArticleReferences(ref))
            } else {
                rawArticleReferences.push(ref)
            }
        })

        // Alle weiteren Referzenzen
        const references: DisplayArticle["references"] = rawArticleReferences.map(mapArticleReferences)

        const additionalInformation: DisplayArticle["additionalInformation"] = {
            references: imsReferences,
            links: article.additionalReferenceArticleInformation?.links.reduce<AdditionalInformationLink[]>((p, c) => {
                return [
                    ...p,
                    ...c.member.map<AdditionalInformationLink>((member) => ({
                        href: member.content,
                        name: member.description,
                        icon: getIconFromMediaType(member.mediaType),
                    })),
                ]
            }, []),
            textInformation: article.additionalReferenceArticleInformation?.textInformation.map((group) => [
                group.name,
                group.member.map((x) => x.description),
            ]),
            headInformation: article.additionalReferenceArticleInformation?.headInformation,
            // informations: [{ content: ["TODO: AdditionalInformation"], title: "TODO: AdditionalInformation", type: 1 }],
        }

        // TODO rest mappen
        const notes: DisplayArticle["notes"] = {
            noteId: NoteTypes.ARTICLE,
            isLoading: false,
            hasVehicle: true,
            notes: [],
        }

        return {
            numbers,
            image,
            supplier,
            bonus,
            description,
            attributes,
            references,
            additionalInformation,
            notes,
        }
    }, [article, partsUiConfig.showCopyNumberButton, supplier, vehicleRecordsAttributes, hideDealerPartNumber])
}
