import { RepairTimeProvider, CustomWork, CustomPart } from "@tm/models"
import { ExtendedOePart, Part } from "../../../../data/models"
import { mapCustomPart } from "../../../../data/repositories/costEstimate/mappers/mapCustomPart"
import { CostEstimationRequest } from "../../../../data/repositories/costEstimate/model"
import { MainState } from "../../../main"

export function createCostEstimationRequest(state: MainState, workTaskId: string, memo?: string): CostEstimationRequest {
    const {
        calculation: { items, works },
        manager: { vehicle },
    } = state

    const selectedItems = items.filter((x) => x.isSelected)
    const repairTimes = works.filter((x) => x.isSelected && !x.isFixPrice)

    const parts: Part[] = []
    const oeArticles: ExtendedOePart[] = []
    const customParts: CustomPart[] = []
    const customWorks: CustomWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { warehouseId, ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeArticles.push({ ...item.oeArticle, memo, oePriceValue: item.oeArticle.oePriceValue || undefined })
        }
    })

    works
        ?.filter((x) => x.isFixPrice)
        .forEach((work) => {
            customWorks.push({
                customWorkNumber: work.providerWorkId,
                hourlyRate: work.hourlyRate,
                categoryOfWork: work.categoryOfWork,
                description: work.description,
                fixedPriceValue: work.total,
                time: work.calculatedTime,
            })
        })

    return {
        workTaskId,
        customParts,
        vehicleId: vehicle?.id ?? "",
        oeParts: oeArticles,
        works: repairTimes,
        customWorks,
        parts,
        repairTimesProvider: RepairTimeProvider.Dat,
    }
}
