import { getModuleFromUserContext, useUser, useWorkTask } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { UserModuleType } from "@tm/models"
import { getBundleParams } from "../../utils"

type Props = {
    displayBehavior: "dashboard" | "startpage" | "both"
}

export default function PartslifeWidget(props: Props) {
    const { workTaskId } = useWorkTask() || {}
    const { translateText } = useLocalization()
    const { userContext } = useUser()

    const moduleDisplay: typeof props.displayBehavior = getModuleFromUserContext(userContext, UserModuleType.Partslife)?.displayBehavior

    if (!(props.displayBehavior === moduleDisplay || moduleDisplay === "both")) {
        return null
    }

    function onClick() {
        const route = getBundleParams().moduleModalUrl
        if (!workTaskId || !route) {
            return
        }

        const comparePageUrl = renderRoute(route, {
            workTaskId: encodeUniqueId(workTaskId),
        })

        Morpheus.showView("1", comparePageUrl)
    }

    return <Widget size="1x1" iconName="partslife" title={translateText(13584)} onClick={onClick} />
}
