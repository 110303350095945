import { classes, css, getStyleTheme, StyleProps, withStyle, withUserContext, WithUserContextProps } from "@tm/context-distribution"
import { Loader, Text, Widget } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { ETyresSeason, SystemType, TyresSeason, Vehicle } from "@tm/models"
import { bindSpecialReactMethods } from "@tm/utils"
import { Component } from "react"
import { batch } from "react-redux"
import { connector, createSpecificQuery } from "../../../data/helpers"
import { Tire } from "../../../data/models"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { IMainActions, MainActions } from "../../main/business"
import DriveRightTiresTable from "../../_shared/driveRTable"
import { mapTyres } from "../business"
import { getBundleParams } from "../../../utils"


type Props = LocalizationProps & StoreProps & StyleProps<typeof stylesheet> & WithUserContextProps & {
    actions: IMainActions
    onVehicleRedirect(): void
    className?: string
}

type StoreProps = {
    driveRightTires: MainState["summary"]["driveRightTires"]
    vehicle?: Vehicle,
    selectedFilters: MainState["summary"]["selectedFilters"]
}

class Suggestions extends Component<Props> {

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleDriveTireSelect(item: Tire, isFrontTires: boolean, season?: TyresSeason) {
        const { selectedFilters, actions } = this.props
        const { carType } = selectedFilters
        const query = item[isFrontTires ? 'sizeFront' : 'sizeRear'] + " " + createSpecificQuery(item[isFrontTires ? 'loadIndexFront' : 'loadIndexRear'], item[isFrontTires ? 'speedIndexFront' : 'speedIndexRear'])
       
        if(!season) {         
            season = selectedFilters.season?.value as TyresSeason | undefined  

            if(!item[isFrontTires ? 'seasonSummerFront' : 'seasonSummerRear'] || !item[isFrontTires ? 'seasonWinterFront' : 'seasonWinterRear']){               
                season = item[isFrontTires ? 'seasonSummerFront' : 'seasonSummerRear'] ? ETyresSeason.summer : ETyresSeason.winter
            }    
        }

        const params = { size: query.trim(), season, carType: carType?.map(x => x.value) }

        const seasonFilter = Statics.seasons.find(x => x.query == season)

        let tireItemSize = item[isFrontTires ? 'sizeFront' : 'sizeRear'] + " "
        if(item[isFrontTires ? 'loadIndexFront' : 'loadIndexRear'])
            tireItemSize += item[isFrontTires ? 'loadIndexFront' : 'loadIndexRear']
        if(item[isFrontTires ? 'speedIndexFront' : 'speedIndexRear'])
            tireItemSize += item[isFrontTires ? 'speedIndexFront' : 'speedIndexRear']

        batch(() => {
            seasonFilter && actions.updateSummaryFilters('season', seasonFilter)

            actions.updateSearchValue(tireItemSize)
            actions.loadTiresList(false, false, false, true)

            actions.changeStep("list", params)
        })

    }

    render() {
        const { vehicle, driveRightTires: { frontTires, rearTires, error, loading, }, style, className, localization: { translateText }, userContext } = this.props
        const tyres = mapTyres(frontTires, rearTires)

        if (userContext?.system.systemType != SystemType.Next && !vehicle)
            return (
                <div className={style.wrapper}>
                    <div className="article-list__panel article-list__status">
                        <Text >{translateText(100)}</Text></div>
                </div>
            )
        if (!vehicle)
            return (<div className={style.wrapper}>
                <div className="article-list__panel article-list__status">
                    <Widget iconName="vehiclesearch" title={translateText(12720)} highlight indicator className="vehicle-widget" onClick={this.props.onVehicleRedirect} />
                </div>
            </div>)

        if (loading)
            return (
                <div className={style.wrapper}>
                    <div className="article-list__panel article-list__status"><Loader /></div>
                </div>
            )

        if (!frontTires.length || !rearTires.length || error)
            return (
                <div className={style.wrapper}>
                    <div className="article-list__panel article-list__status">
                        <Text modifiers={["block"]} >{translateText(163)}</Text></div>
                </div>
            )

        return (
            <div className={classes(className, style.wrapper)}>
                <DriveRightTiresTable
                    onTireSelect={this.handleDriveTireSelect}
                    items={tyres}
                />
            </div>
        )
    }
}

function mapStateToProps(state: MainState): StoreProps {
    return {
        driveRightTires: state.summary.driveRightTires,
        selectedFilters: state.summary.selectedFilters,
        vehicle: state.manager.vehicle
    }
}

function stylesheet() {
    const { isFiltersOnRightSide } = getBundleParams()
    const theme = getStyleTheme()
    return css({
        wrapper: {
            display: "flex",
            flex: 1,
            marginLeft: isFiltersOnRightSide ? 0 : theme.margin.m,
            marginRight: isFiltersOnRightSide ? theme.margin.m : 0,
            $nest: {
                ".article-list__status": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    flex: 1,
                }
            }
        }
    })
}

export default connector(withLocalization(withUserContext(withStyle(stylesheet, Suggestions))), mapStateToProps, MainActions)

