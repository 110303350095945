import { em, important, rem } from "csx"
import { MouseEvent, ReactNode, memo, useEffect, useMemo, useState } from "react"
import { Icon } from "@tm/components"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Text } from "@tm/controls"
import { WorkTypes } from "../../../data/repositories/fastcalculator-getMaintenancePlan/model"
import { scrollPosition } from "../../../helpers/subject"
import { ActiveButtons } from "../../../models"
import DefectButton from "./defectButtons"

type Props = {
    title: string
    onStatusButtonClick?: (buttonId: ActiveButtons) => void
    selectedStatusButton: ActiveButtons
    reandOnlyIcons?: ReactNode
    disableStatusButtonClick?: boolean
    className?: string
    readonly?: boolean
    workType?: number
}

function TitleHeader({
    readonly,
    title,
    reandOnlyIcons,
    selectedStatusButton,
    disableStatusButtonClick,
    className,
    onStatusButtonClick,
    workType,
}: Props) {
    const style = useMemo(() => getStyle(), [])
    const [activeButton, setActiveButton] = useState<ActiveButtons>(selectedStatusButton)

    let icon = ""

    switch (workType) {
        case WorkTypes.additionalWork: {
            icon = "additional-work"
            break
        }
        case WorkTypes.followUpWork: {
            icon = "follow-up-work"
            break
        }
        default:
            break
    }

    useEffect(() => {
        if (activeButton !== selectedStatusButton) {
            setActiveButton(selectedStatusButton)
        }
    }, [selectedStatusButton])

    const handleStatusButtonClick = (e: MouseEvent<HTMLDivElement>, buttonType: ActiveButtons) => {
        e.preventDefault()
        e.stopPropagation()

        if (disableStatusButtonClick) {
            return
        }

        const selectedButtonType = buttonType === activeButton ? "none" : buttonType

        setActiveButton(selectedButtonType)
        onStatusButtonClick?.(selectedButtonType)
        handleClick(e, buttonType)
    }

    const handleClick = (e: MouseEvent<HTMLDivElement>, buttonType: ActiveButtons) => {
        const selector = e.currentTarget.closest(".panel")
        if (!selector) {
            return
        }

        setTimeout(() => {
            if (buttonType === "defectButton") {
                scrollPosition.next((selector as HTMLDivElement).offsetTop)
            } else {
                selector.nextSibling && scrollPosition.next((selector.nextSibling as HTMLDivElement).offsetTop)
            }
        }, 50)
    }

    return (
        <div className={style.panelheader}>
            {icon && <Icon name={icon} />}
            <Text className={classes(style.title, icon ? style.marginLeftOneEm : style.marginLeftTwoEm)} size="xl" modifiers="strong">
                {title}
            </Text>
            <div className={classes(style.customButtons, className)}>
                {reandOnlyIcons && reandOnlyIcons}
                <div>
                    <DefectButton
                        onClick={handleStatusButtonClick}
                        isActive={activeButton === "defectButton"}
                        buttonType="defectButton"
                        readOnly={readonly}
                    />
                    <DefectButton
                        onClick={handleStatusButtonClick}
                        isActive={activeButton === "repairedButton"}
                        buttonType="repairedButton"
                        readOnly={readonly}
                    />
                    <DefectButton
                        onClick={handleStatusButtonClick}
                        isActive={activeButton === "checkButton"}
                        buttonType="checkButton"
                        readOnly={readonly}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(TitleHeader)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        panelheader: {
            display: "flex",
            flex: 1,
            alignItems: "center",
        },
        bigButton: {
            padding: em(0.2),
            marginLeft: em(0.5),
            $nest: {
                "svg.icon.btn__icon": {
                    width: rem(2.5),
                    height: rem(2.5),
                },
                "&.is-active svg": {
                    fill: important(theme.colors.primary),
                },
            },
        },
        customButtons: {
            display: "flex",
            alignItems: "center",
            flex: 0.4,
            justifyContent: "flex-end",
        },
        title: {
            flex: 0.6,
        },
        marginLeftOneEm: {
            marginLeft: em(1),
        },
        marginLeftTwoEm: {
            marginLeft: em(2),
        },
    })(TitleHeader)
}
