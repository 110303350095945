import { Box } from "@tm/components"
import { useSelector } from "react-redux"
import { ICalculationItem } from "../../../../data/model"
import { calculationSelector } from "../../../../selectors"
import { ArticleItem } from "./components"

export default function ArticleList() {
    const { items } = useSelector(calculationSelector)

    return (
        <Box className="tk-parts">
            <Box className="list">
                <Box padding={(theme) => theme.spacing(0.5, 1)} className="article-list calculation-items">
                    {items?.map((item: ICalculationItem) => (
                        <ArticleItem key={item.uniqueId} item={item} />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
