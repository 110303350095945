import { Box } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CostEstimationVoucherTotals, WorkItem } from "@tm/models"
import WorksTotals from "./components/WorksTotals"
import WorksTable from "./components/WorksTable"

type Props = {
    calculatedWorks?: WorkItem[]
    totals?: CostEstimationVoucherTotals
    repairTimeDivision: number
}
export default function Works({ totals, repairTimeDivision, calculatedWorks }: Props) {
    const { translateText } = useLocalization()

    return (
        <Collapsible name={translateText(1668)} initiallyOpened skin="dark">
            <Box px={2} pb={1}>
                <WorksTable showRepairTimesInHours={repairTimeDivision === 1} works={calculatedWorks} />
                {totals && <WorksTotals costEstimationTotals={totals} showRepairTimesInHours={repairTimeDivision === 1} />}
            </Box>
        </Collapsible>
    )
}
