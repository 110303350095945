import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { OE } from "@tm/models"
import { isEqual } from "lodash"
import { OeInformationItemState } from "./models"
import { ArticleItemStateProvider, useArticleItemState } from "."
import { OeInformationArticle } from "../../OeInformation"
import { mapOeArticleDtoFromOePart } from "../../../helpers"
import { mapArticleAttributes, mapOePrices } from "../../../hooks/useArticleItem/mapper"
import { useHandleAddOeToBasket } from "../../../hooks/useArticleItem/useHandleAddOeToBasket"
import { useHandleRemoveFromBasket } from "../../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { useOeBasketQuantities } from "../../../hooks/useBasketQuantities"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"

type Props = {
    article: OeInformationArticle
}

export function OeInformationItemStateProvider({ article: { manufacturer, parts }, children }: PropsWithChildren<Props>) {
    const {
        oeInformation: { showOePrice, displayVehicleOeCriteria, transferToShoppingCart },
    } = useArticleListConfiguration()

    const [part, setPart] = useState<OE.OePart | undefined>()

    const { basketQuantities, updateBasketQuantities } = useOeBasketQuantities(parts, !!transferToShoppingCart)

    const [quantity, setQuantity] = useState<number>(part?.quantity || 1)
    const handleAddToBasketInternal = useHandleAddOeToBasket(part)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(() => {
        if (!part || !transferToShoppingCart) {
            return undefined
        }

        const refArticle = mapOeArticleDtoFromOePart(part)

        return basketQuantities.find((q) => isEqual(q.article, refArticle))
    }, [basketQuantities, part])

    const postAddToBasket = useCallback(() => {
        if (!part) {
            return
        }
        updateBasketQuantities([part])
    }, [part, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            postAddToBasket()
        },
        [handleAddToBasketInternal, quantity, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (part && basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateBasketQuantities([part])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateBasketQuantities, part])

    const handleChangeQuantity = useCallback((value: number) => setQuantity(value), [])

    useEffect(() => {
        const selectedPart = parts?.first()
        if (!selectedPart) {
            return
        }
        setPart(selectedPart)
        setQuantity(selectedPart.quantity && selectedPart.quantity > 0 ? selectedPart.quantity : 1)
    }, [parts])

    const handlePartChange = useCallback(
        (id: string) => {
            const selectedPart = parts?.find((p) => p.id === id)

            if (selectedPart) {
                setPart(selectedPart)
            }
        },
        [setPart, parts]
    )

    if (!part) {
        return null
    }

    const attributes = mapArticleAttributes(part, displayVehicleOeCriteria)

    const oePrices = mapOePrices(part, showOePrice)

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: OeInformationItemState = {
        type: "oeInformation",
        showAddToBasket: !!transferToShoppingCart,
        isVehicleDependent: false, // TODO once article list v2 opens in modal
        quantity,
        originalQuantity: quantity,
        basketQuantity,
        article: part,
        parts,
        oeArticleNo: part.number,
        thumbnailUrl: manufacturer.thumbnail ?? "/styles/base/images/oe-part-logo.svg",
        description: part.description,
        attributes,
        oePrices,
        handleChangeQuantity,
        handleAddToBasket,
        handleRemoveFromBasket,
        handlePartChange,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}

export function useOeInformationItemState(): OeInformationItemState
export function useOeInformationItemState<TReturnType>(selector: (value: OeInformationItemState) => TReturnType): TReturnType
export function useOeInformationItemState<TReturnType>(
    selector?: (value: OeInformationItemState) => TReturnType
): OeInformationItemState | TReturnType {
    return useArticleItemState<OeInformationItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
