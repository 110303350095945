import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { withRouter, renderRoute, encodeUniqueId, RouteComponentProps } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { Button, Box, Card, Typography, CardContent, Grid, Icon } from "@tm/components"
import { CustomerLogo } from "./components/CustomerLogo"
import { CustomerGeneralInformation } from "./components/CustomerGeneralInformation"
import { Fields } from "./components/Fields"

type Props = RouteComponentProps & {
    customerListRoute: string
    customerDetailsRoute: string
}

const CustomerOverviewComponent = (props: Props) => {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer

    const handleOpenCustomerList = () => {
        let url
        if (customer) {
            const customerId = encodeUniqueId(customer.id)
            url = renderRoute(props.customerDetailsRoute, { ...props.match.params, customerId })
        } else {
            url = renderRoute(props.customerListRoute, { ...props.match.params })
        }

        Morpheus.showView("1", url)
    }

    return (
        <Box mb={2} width="100%" className="tk-crm customer-overview">
            <Grid container spacing={3} mb={1}>
                <Grid item xs={6}>
                    <Box>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1} mb={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="h2">{translateText(107)}</Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        <Button
                                            startIcon={customer ? <Icon name="edit_change" /> : <Icon name="menu" />}
                                            onClick={handleOpenCustomerList}
                                        />
                                    </Grid>
                                </Grid>
                                <Fields />
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <div className="customer-info">
                        <CustomerLogo />
                        <CustomerGeneralInformation />
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default withRouter(CustomerOverviewComponent)
