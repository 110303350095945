import { BundleComponent } from "@tm/morpheus"
import { reduce, receive, transmit } from "./business"
import component from "./component"

const costEstimation: BundleComponent<unknown, typeof component> = {
    name: "cost-estimation",
    reduce,
    component,
    receive,
    transmit,
}

export default costEstimation
