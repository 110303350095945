import { Button, ButtonSkins } from "@tm/controls"
import Morpheus from "@tm/morpheus"
import { classes } from "@tm/utils"
import { Box, styled } from "@tm/components"

type Props = {
    onClick(): void
    disabled?: boolean
    skin?: ButtonSkins
}

export default function ReplaceButton({ onClick, disabled, skin }: Props) {
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    return (
        <StyledBox>
            <Button
                disabled={disabled}
                className={classes("replace", "add-to-basket__button", isWM ? "wm-replace" : "normal-replace")}
                icon={isWM ? "wm-logo" : "replace"}
                skin={skin || "highlight"}
                onClick={onClick}
            />
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    ".wm-replace": {
        width: "auto",
        minWidth: "13em",
        marginLeft: "auto",
        display: "flex",
        justifyContent: "center",
    },

    ".normal-replace": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "6em",
    },
})
