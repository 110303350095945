import { useLocalization } from "@tm/localization"
import { Grid, Icon, Typography, lighten, styled, useTheme } from "@tm/components"
import { CostEstimationTotals } from "../../../../data/model"
import { StyledBox } from "../../../StyledComponents"

type Props = {
    totals: CostEstimationTotals
    currencyCode: string
    showRepairTimesInHours: boolean
}

const LightContrastTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

const LightColoredIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

export default function CostEstimationDetails({ totals, showRepairTimesInHours, currencyCode }: Props) {
    const { translateText, currency, number } = useLocalization()

    const theme = useTheme()
    const styledBoxBackgroundColor =
        theme.overwrites?.components?.basketSummary?.costEstimationDetails?.backgroundColor || lighten(theme.palette.common.black, 0.3)
    const styledBoxPadding = theme.spacing(1.5, 2)

    return (
        <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding}>
            <Grid container spacing={1} alignItems="center" sx={{ rowGap: 1 }}>
                <Grid item xs={5.5} />
                <Grid item xs={2} textAlign="right">
                    <LightContrastTypography variant="label">{translateText(89)}</LightContrastTypography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3.5} textAlign="right">
                    <LightContrastTypography variant="label">{translateText(694)}</LightContrastTypography>
                </Grid>
                <Grid item xs={1}>
                    <LightColoredIcon name="cart-summary" />
                </Grid>
                <Grid item xs={4.5}>
                    <LightContrastTypography>{translateText(90)}</LightContrastTypography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                    <LightContrastTypography>
                        {totals.partPositionsInTotal ? number(totals.partPositionsInTotal, 2) : undefined}
                    </LightContrastTypography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3.5} textAlign="right">
                    <LightContrastTypography>{currency(totals.partCosts, currencyCode)}</LightContrastTypography>
                </Grid>
                <Grid item xs={1}>
                    <LightColoredIcon name="repairtimes-summary" />
                </Grid>
                <Grid item xs={4.5}>
                    <LightContrastTypography>{translateText(83)}</LightContrastTypography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                    <LightContrastTypography>{!!totals.repairTimeTotal && number(totals.repairTimeTotal, 2)}</LightContrastTypography>
                </Grid>
                <Grid item xs={1}>
                    <LightContrastTypography>{!!totals.repairTimeTotal && showRepairTimesInHours && translateText(60)}</LightContrastTypography>
                </Grid>
                <Grid item xs={3.5} textAlign="right">
                    <LightContrastTypography>{currency(totals.workCosts, currencyCode)}</LightContrastTypography>
                </Grid>
            </Grid>
        </StyledBox>
    )
}
