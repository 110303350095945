import { Article, ArticleListSortingMode, AvailabilityStatus, ErpPrice, PriceType } from "@tm/models"
import { useMemo } from "react"
import { useRecoilValueLoadable } from "recoil"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { ArticleGroupParams, ListParams } from "../models"
import { ErpInfosState } from "../states"
import { ArticleErpInfo, ArticleErpInfoFactory } from "./useErpInfos"
import { useDefaultErpSystem } from "./useDefaultErpSystem"

/** Filters and sorts the supplied article groups if necessary. */
export function useDisplayFilter(
    articleGroups: ArticleGroupParams[],
    listParams: ListParams
): {
    articleGroups: ArticleGroupParams[]
    /** When `stalling` is `true` the article list should not be rendered yet. */
    stalling: boolean
    /** When `isFrontendFiltered` is `true` the article groups aren't including all articles from the service. */
    isFrontendFiltered: boolean
} {
    const {
        sorting: { productGroups: articleSorting },
    } = listParams

    const defaultErpSystemId = useDefaultErpSystem()?.id

    const { defaultMode, sortingPriceType, availabilitySortingOrder } = useArticleListConfiguration().sorting

    const anySortingActive = Object.entries(articleSorting).some(
        ([productGroupId, sorting]) =>
            articleGroups.some((group) => group.productGroup.id === parseInt(productGroupId)) && sorting && sorting !== "default"
    )

    const erpDataLoadable = useRecoilValueLoadable(ErpInfosState)
    const erpData = useMemo(
        () => (erpDataLoadable.state === "hasValue" ? erpDataLoadable.contents : []),
        [erpDataLoadable.contents, erpDataLoadable.state]
    )

    const availabilityFilteredArticleGroups = useMemo(() => {
        if (!listParams.showAvailable || !articleGroups.length || !erpData.length || !defaultErpSystemId) {
            return articleGroups
        }

        return articleGroups
            .map<ArticleGroupParams>((group) => ({
                ...group,
                articles: filterArticlesByAvailability(group.articles, erpData, defaultErpSystemId),
            }))
            .filter((x) => !!x.articles.length)
    }, [listParams.showAvailable, articleGroups, erpData, defaultErpSystemId])

    const sortedArticleGroups = useMemo(() => {
        if (!anySortingActive || !availabilityFilteredArticleGroups.length || !erpData.length || !defaultErpSystemId) {
            return availabilityFilteredArticleGroups
        }

        return availabilityFilteredArticleGroups.map<ArticleGroupParams>((group) => {
            const sorting = articleSorting[group.productGroup.id]

            if (!sorting || group.incomplete) {
                return group
            }

            return {
                ...group,
                articles: sortArticles(group.articles, sorting, erpData, defaultErpSystemId, sortingPriceType, availabilitySortingOrder),
            }
        })
    }, [anySortingActive, availabilityFilteredArticleGroups, articleSorting, erpData, sortingPriceType, availabilitySortingOrder, defaultErpSystemId])

    // When a default sorting is set and active, while erp data are loading display no articles at all, otherwise an unsorted list would be displayed for a short time
    const stalling = useMemo<boolean>(
        () => !!defaultMode && anySortingActive && (!erpData?.length || !erpData.some((x) => x.state === "success" || x.state === "error")),
        [defaultMode, anySortingActive, erpData]
    )

    return {
        articleGroups: sortedArticleGroups,
        stalling,
        isFrontendFiltered: !!listParams.showAvailable,
    }
}

function filterArticlesByAvailability(articles: Article[], erpData: ArticleErpInfo[], erpSystemId: number): Article[] {
    return articles.filter((article) => {
        const erpInfo = getArticleErpInfo(article, erpData, erpSystemId)
        return erpInfo?.state === "loading" || (erpInfo?.state === "success" && erpInfo.response.availability.type === AvailabilityStatus.Available)
    })
}

function sortArticles(
    articles: Article[],
    sorting: ArticleListSortingMode | undefined,
    erpData: ArticleErpInfo[],
    erpSystemId: number,
    sortingPriceType: PriceType | undefined,
    availabilitySortingOrder: AvailabilityStatus[]
): Article[] {
    switch (sorting) {
        case "price":
        case "priceDescending": {
            if (!sortingPriceType) {
                return articles
            }

            return getPriceSortedArticles(articles, erpData, erpSystemId, sortingPriceType, sorting === "priceDescending")
        }
        case "availability": {
            if (!availabilitySortingOrder.length) {
                return articles
            }

            return getAvailabilitySortedArticles(articles, erpData, erpSystemId, availabilitySortingOrder)
        }
        case "erpSortNumber": {
            return getSortNumberSortedArticles(articles, erpData, erpSystemId)
        }
        default:
            return articles
    }
}

function skipSorting(articleA: Article, articleB: Article): boolean {
    // Do not sort articles if they are ...
    return (
        articleA.productGroup.id !== articleB.productGroup.id // ... from different product groups
    )
}

function getArticleErpInfo(article: Article, erpData: ArticleErpInfo[], erpSystemId: number): ArticleErpInfo | undefined {
    return erpData.find((info) => info.key.id === ArticleErpInfoFactory.createKeyFromArticle(article, erpSystemId).id)
}

function getPrice(prices: ErpPrice[] | undefined, priceType: PriceType): ErpPrice | undefined {
    return prices?.find((price) => price.type === priceType)
}

function getPriceSortedArticles(
    articles: Article[],
    erpData: ArticleErpInfo[],
    erpSystemId: number,
    sortingPriceType: PriceType,
    descending?: boolean
): Article[] {
    const sortedArticles = [...articles]

    /** @todo Use toSorted here when we support it for transpilation */
    sortedArticles.sort((a, b) => {
        if (skipSorting(a, b)) {
            return 0
        }

        const aErpInfo = getArticleErpInfo(a, erpData, erpSystemId)
        const bErpInfo = getArticleErpInfo(b, erpData, erpSystemId)

        const aErpInfoResponse = aErpInfo?.state === "success" ? aErpInfo.response : undefined
        const bErpInfoResponse = bErpInfo?.state === "success" ? bErpInfo.response : undefined

        if (aErpInfoResponse && !bErpInfoResponse) {
            return -1
        }

        if (!aErpInfoResponse && bErpInfoResponse) {
            return 1
        }

        if (aErpInfoResponse && bErpInfoResponse) {
            const aPrice = getPrice(aErpInfoResponse.prices, sortingPriceType)
            const bPrice = getPrice(bErpInfoResponse.prices, sortingPriceType)

            if (aPrice && !bPrice) {
                return -1
            }

            if (!aPrice && bPrice) {
                return 1
            }

            if (aPrice && bPrice) {
                return descending ? bPrice.value - aPrice.value : aPrice.value - bPrice.value
            }
        }

        return 0
    })

    return sortedArticles
}

function getAvailabilitySortedArticles(
    articles: Article[],
    erpData: ArticleErpInfo[],
    erpSystemId: number,
    availabilitySortingOrder: AvailabilityStatus[]
): Article[] {
    const sortedArticles = [...articles]

    /** @todo Use toSorted here when we support it for transpilation */
    sortedArticles.sort((a, b) => {
        if (skipSorting(a, b)) {
            return 0
        }

        const aErpInfo = getArticleErpInfo(a, erpData, erpSystemId)
        const bErpInfo = getArticleErpInfo(b, erpData, erpSystemId)

        const aErpInfoResponse = aErpInfo?.state === "success" ? aErpInfo.response : undefined
        const bErpInfoResponse = bErpInfo?.state === "success" ? bErpInfo.response : undefined

        if (aErpInfoResponse && !bErpInfoResponse) {
            return -1
        }

        if (!aErpInfoResponse && bErpInfoResponse) {
            return 1
        }

        if (aErpInfoResponse && bErpInfoResponse) {
            const aAvailability = aErpInfoResponse.availability.type
            const bAvailability = bErpInfoResponse.availability.type

            if (aAvailability && !bAvailability) {
                return -1
            }

            if (!aAvailability && bAvailability) {
                return 1
            }

            if (aAvailability && bAvailability) {
                const aSort = availabilitySortingOrder.indexOf(aAvailability)
                const bSort = availabilitySortingOrder.indexOf(bAvailability)

                return aSort - bSort
            }
        }

        return 0
    })

    return sortedArticles
}

function getSortNumberSortedArticles(articles: Article[], erpData: ArticleErpInfo[], erpSystemId: number): Article[] {
    const sortedArticles = [...articles]

    /** @todo Use toSorted here when we support it for transpilation */
    sortedArticles.sort((a, b) => {
        if (skipSorting(a, b)) {
            return 0
        }

        let aErpInfo = getArticleErpInfo(a, erpData, erpSystemId)
        let bErpInfo = getArticleErpInfo(b, erpData, erpSystemId)

        aErpInfo = aErpInfo?.state === "success" ? aErpInfo : undefined
        bErpInfo = bErpInfo?.state === "success" ? bErpInfo : undefined

        if (aErpInfo && !bErpInfo) {
            return -1
        }

        if (!aErpInfo && bErpInfo) {
            return 1
        }

        if (aErpInfo && bErpInfo) {
            const aSortNumber = aErpInfo.sortNumber
            const bSortNumber = bErpInfo.sortNumber

            if (aSortNumber && !bSortNumber) {
                return -1
            }

            if (!aSortNumber && bSortNumber) {
                return 1
            }

            if (aSortNumber && bSortNumber) {
                return aSortNumber - bSortNumber
            }
        }

        return 0
    })

    return sortedArticles
}
