import { Alert } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    isItemAlert?: boolean
}

export default function ErrorAlert({ isItemAlert }: Props) {
    const { translateText } = useLocalization()

    return <Alert size="small" severity="error" title={translateText(787)} variant={isItemAlert ? "outlined" : "filled"} />
}
