import { Button, Checkbox, Icon, IconButton, Loader, NotesButton, Stack } from "@tm/components"
import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { NoteTypes } from "@tm/models"
import { Overwrite } from "@tm/utils"
import { memo, useCallback, useRef, useState } from "react"
import { CustomItemQuantityField } from "./CustomItemQuantityField"

type BaseProps<TItem> = {
    item: TItem
    onDelete(item: TItem): Promise<void>
    onEditClick(item: TItem | undefined): void
    onAddToBasket(item: TItem): Promise<unknown>
    isSelected: boolean
    onToggleSelection(item: TItem): void
}

type HasNoteProps = {
    itemNote?: string
}

type HasQuantityFieldProps<TItem> = {
    quantity: number
    onQuantityChange(item: TItem, quantity: number): void
    onAddToBasket(item: TItem, quantity: number): Promise<unknown>
}

type Props<TItem> =
    | BaseProps<TItem>
    | Overwrite<BaseProps<TItem>, HasNoteProps>
    | Overwrite<BaseProps<TItem>, HasQuantityFieldProps<TItem>>
    | Overwrite<BaseProps<TItem>, HasNoteProps & HasQuantityFieldProps<TItem>>

export const CustomItemActions = memo(<TItem,>(props: Props<TItem>) => {
    const { item, onDelete, onEditClick, onAddToBasket, isSelected, onToggleSelection } = props
    const withQuantityField = "quantity" in props
    const quantity = withQuantityField ? props.quantity : undefined

    const { translateText, languageId } = useLocalization()

    const warningRef = useRef<WarningPrompt>(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isBeingAddedToBasket, setIsBeingAddedToBasket] = useState(false)

    const handleDelete = useCallback(() => {
        setIsDeleting(true)
        warningRef.current?.show()
    }, [])

    const handleEdit = useCallback(() => {
        onEditClick(item)
    }, [item, onEditClick])

    const handleAddToBasket = useCallback(() => {
        setIsBeingAddedToBasket(true)

        // MLE: It's safe to use the "not-null assertion" here, because
        // if "quantity" isn't set in the props "onAddToBasket" is also not expecting that second argument
        // Maybe improve this if you know how.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onAddToBasket(item, quantity!).finally(() => {
            setIsBeingAddedToBasket(false)
        })
    }, [item, onAddToBasket, quantity])

    const handleDialogPromptConfirm = useCallback(() => {
        onDelete(item).catch(() => {
            // resetting the isDeleting state is only required if the deletion failed
            // because otherwise the whole item will be removed and this component no longer exists
            setIsDeleting(false)
        })
    }, [item, onDelete])

    const handleDialogPromptClose = useCallback(() => {
        setIsDeleting(false)
    }, [])

    const handleToggleSelection = useCallback(() => {
        onToggleSelection(item)
    }, [item, onToggleSelection])

    return (
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            {"itemNote" in props && !!props.itemNote && (
                <NotesButton
                    articleNotes={[
                        {
                            type: NoteTypes.CUSTOM_ARTICLE,
                            message: props.itemNote,
                            title: translateText(12874),
                        },
                    ]}
                    hasNote={!!props.itemNote}
                    iconOnly
                />
            )}
            <IconButton title={translateText(122)} onClick={handleEdit}>
                <Icon name="edit_change" />
            </IconButton>
            <IconButton title={translateText(624)} onClick={handleDelete} disabled={isDeleting}>
                {isDeleting ? <Loader size="small" /> : <Icon name="delete" />}
            </IconButton>
            {withQuantityField && <CustomItemQuantityField item={item} quantity={props.quantity} onQuantityChange={props.onQuantityChange} />}
            <Button
                title={translateText(937)}
                variant={!isBeingAddedToBasket ? "bordered" : undefined}
                onClick={handleAddToBasket}
                disabled={isBeingAddedToBasket}
                color={!isBeingAddedToBasket ? "highlight" : undefined}
                startIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                sx={{ minWidth: "6em" }}
            />
            <WarningPrompt
                text={translateText(833)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={warningRef}
                onConfirm={handleDialogPromptConfirm}
                onClose={handleDialogPromptClose}
                doNotCloseOnConfirm
            />
            <Checkbox checked={isSelected} onChange={handleToggleSelection} />
        </Stack>
    )
})
