import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(baseStyles: NestedCSSProperties) {
    const { margin } = getStyleTheme()

    const styles: NestedCSSProperties = {
        width: "100%",
        maxWidth: "55em",
        margin: "0 auto",
        padding: "3em 0 2em",
        $nest: {
            "&__partnerless": {
                marginTop: margin.l,
            },
        },
    }

    return deepAssign(baseStyles, styles)
}
