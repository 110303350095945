import { Stack } from "@tm/components"
import { BasketWork } from "../../../../models"
import IncludedWorks from "./IncludedWorksTable"
import ErrorAlert from "../../../_shared/ErrorAlert"

type Props = {
    work: BasketWork
    showSupplierArticleNumbers: boolean
}
export default function WorkFooter({ work, showSupplierArticleNumbers }: Props) {
    const { estimatedWork, workItem, states } = work
    const includedWorks = estimatedWork?.includedWorks || workItem.includedWorks
    const visibleIncludedWorks = includedWorks?.filter((includedWork) => includedWork.isVisible)

    return (
        <Stack p={0.5} spacing={0.5} flex={1}>
            {estimatedWork?.hasCalculationError && <ErrorAlert isItemAlert />}
            {states.isExpanded && visibleIncludedWorks?.length && (
                <IncludedWorks includedWorks={visibleIncludedWorks} showSupplierArticleNumbers={showSupplierArticleNumbers} />
            )}
        </Stack>
    )
}
