import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { useVatRateText } from "@tm/utils"
import { CellContentPosition, Table, TableCellData, TableColumnData } from "@tm/components"
import { ExtendedVoucherCalculatedPart } from "../../../../../../../../../../data/model"
import ArticleThumbnail from "../../../../../_shared/ArticleThumbnail"
import ArticleNumbers from "../../../../../_shared/ArticleNumbers"
import ArticleDescription from "../../../../../_shared/ArticleDescription"
import PartActions from "../../../../../_shared/PartActions"
import PartType from "./components/PartType"
import { ArticleQuantities } from "../../../../../_shared/ArticleQuantities"
import { ValueLabelStack } from "../../../../../_shared/ValueLabelStack"
import PartFooter from "./components/PartFooter"

type Props = {
    voucherId?: string
    calculatedParts?: ExtendedVoucherCalculatedPart[]
    selectedIds: string[]
    isBigScreen: boolean
    costEstimationVehicleId?: string
    onSelectPart(id: string): void
}

export default function PartsTable({ calculatedParts, selectedIds, isBigScreen, voucherId, costEstimationVehicleId, onSelectPart }: Props) {
    const { translateText, number, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText, number)

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: "" }, // Thumbnail
            { header: "" }, // Manufacturer
            { header: "" }, // ArticleNumbers
            { header: "" }, // Description
            { header: "" }, // PartType
            { header: "", alignContent: CellContentPosition.left }, // RetailPrice
            { header: "" }, // Quantity
            { header: "" }, // Rebate
            { header: "" }, // VatRate
            { header: "", alignContent: CellContentPosition.right }, // TotalPrice
            { header: "", alignContent: CellContentPosition.right }, // Actions
        ]

        return columns
    }

    function getCellData(calculatedPart: ExtendedVoucherCalculatedPart): TableCellData[] {
        const {
            quantity,
            mainPartItemId,
            thumbnail,
            articleType,
            articleNumber,
            dataSupplier,
            oeArticleVehicleManufacturer,
            wholesalerArticleManufacturerName,
            wholesalerArticleNumber,
            productGroup,
            description,
            additionalDescription,
            rebate,
            quotationPrice,
            cummulatedQuotationPrice,
            currencyCode,
            currencySymbol,
            vatRate,
        } = calculatedPart
        let manufacturer = ""
        if (dataSupplier) {
            manufacturer = dataSupplier.name
        } else if (oeArticleVehicleManufacturer) {
            manufacturer = oeArticleVehicleManufacturer.name
        } else if (wholesalerArticleManufacturerName) {
            manufacturer = wholesalerArticleManufacturerName
        }
        const isLinkedItem = !!mainPartItemId
        const cellData: TableCellData[] = [
            {
                displayValue: <ArticleThumbnail articleInfoType={articleType} mainPartItemId={mainPartItemId} thumbnailUrl={thumbnail} />,
                id: "part_1",
            },
            { displayValue: manufacturer, id: "part_2" },
            {
                displayValue: (
                    <ArticleNumbers
                        articleType={articleType}
                        articleNumber={articleNumber}
                        productGroupId={productGroup?.id}
                        supplierId={dataSupplier?.id}
                        wholesalerArticleNumber={wholesalerArticleNumber}
                        isLinkedItem={isLinkedItem}
                    />
                ),
                id: "part_3",
            },
            {
                displayValue: (
                    <ArticleDescription
                        description={description}
                        additionalDescription={additionalDescription}
                        productGroupName={productGroup?.name}
                        isBigScreen={isBigScreen}
                        isLinkedItem={isLinkedItem}
                    />
                ),
                id: "part_4",
            },
            { displayValue: <PartType articleInfoType={calculatedPart.articleType} />, id: "part_5" },
            {
                displayValue: quotationPrice && (
                    <ValueLabelStack label={translateText(1620)} value={currency(quotationPrice, currencySymbol || currencyCode)} alignValueRight />
                ),
                id: "part_6",
            },
            {
                displayValue: <ArticleQuantities confirmedQuantityValue={quantity.value} isBigScreen={isBigScreen} hideReturnQuantity />,
                id: "part_7",
            },
            {
                displayValue: rebate && <ValueLabelStack label={translateText(54)} value={`${number(rebate, 0)}%`} />,
                id: "part_8",
            },
            {
                displayValue: vatRate && <ValueLabelStack label={translateText(706)} value={vatRateTypeDisplayShortText(vatRate)} alignValueRight />,
                id: "part_9",
            },
            {
                displayValue: cummulatedQuotationPrice && (
                    <ValueLabelStack
                        label={translateText(1620)}
                        value={currency(cummulatedQuotationPrice, currencySymbol || currencyCode)}
                        alignValueRight
                    />
                ),
                id: "part_10",
            },
            {
                displayValue: (
                    <PartActions
                        voucherId={voucherId}
                        voucherType={VoucherType.CostEstimation}
                        costEstimationVehicleId={costEstimationVehicleId}
                        calculatedPart={calculatedPart}
                        isSelected={selectedIds?.includes(calculatedPart.id) || false}
                        isBigScreen={isBigScreen}
                        onSelectPart={onSelectPart}
                    />
                ),
                id: "part_11",
            },
        ]

        return cellData
    }
    function getDisplayData() {
        return calculatedParts?.map((part, index) => ({
            cells: getCellData(part),
            id: `${index}`,
            customRow: false,
            active: false,
            extendedContent: <PartFooter part={part} isBigScreen={isBigScreen} onSelectPart={onSelectPart} />,
        }))
    }

    return <Table headerBackground="transparent" columns={getColumns()} rows={getDisplayData()} overflowY="unset" />
}
