import { CustomPart } from "@tm/models"
import { ExtendedOePart } from "../../../models"

export function mapCustomPart(oePart: ExtendedOePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.quantityValue,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}
