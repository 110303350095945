import { BookingResponse, DrivemotiveLoginResponse } from "../../models"
import { MainSlice } from "./model"

export function setDrivemotiveToken(state: MainSlice, drivemotiveToken: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            drivemotiveToken,
        },
    }
}

export function setShowLoginDialog(state: MainSlice, showLoginDialog: boolean): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            showLoginDialog,
        },
    }
}

export function drivemotiveLogin(state: MainSlice, loginResponse: DrivemotiveLoginResponse): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            drivemotiveToken: loginResponse.token,
            showLoginDialog: false,
        },
    }
}

export function setBookings(state: MainSlice, bookingsResponse: BookingResponse): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            bookings: bookingsResponse.bookings,
        },
    }
}

export function changeStep(state: MainSlice, navigationStep: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            navigationStep,
        },
    }
}
