import { useLocalization } from "@tm/localization"
import { Grid, Typography, styled, useTheme } from "@tm/components"
import { CostEstimationTotals } from "../../../../data/model"
import { StyledBox } from "../../../StyledComponents"

type Props = {
    totals: CostEstimationTotals
    currencyCode: string
}

const HighlightContrastTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.overwrites?.components?.basketSummary?.costEstimationTotals?.color ||
        theme.palette.getContrastText(
            theme.overwrites?.components?.basketSummary?.costEstimationTotals?.backgroundColor ||
                theme.overwrites?.components?.basketSummary?.component?.backgroundColor ||
                theme.palette.highlight.main
        ),
    opacity: 1,
}))

export default function Totals({ totals, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()

    const theme = useTheme()
    const basketSummaryTheme = theme.overwrites?.components?.basketSummary
    const highlightColor =
        basketSummaryTheme?.costEstimationTotals?.backgroundColor || basketSummaryTheme?.component?.backgroundColor || theme.palette.highlight.main

    const styledBoxPadding = theme.spacing(1.5, 2)

    return (
        <StyledBox backgroundColor={highlightColor} padding={styledBoxPadding}>
            <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                {totals.offerPriceVatExcluded && (
                    <>
                        <Grid item xs={6}>
                            <HighlightContrastTypography>
                                {translateText(62)}
                                <HighlightContrastTypography variant="label">{` ${translateText(694)}`}</HighlightContrastTypography>
                            </HighlightContrastTypography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5} textAlign="right">
                            <HighlightContrastTypography>{currency(totals.offerPriceVatExcluded, currencyCode)}</HighlightContrastTypography>
                        </Grid>
                    </>
                )}
                {totals.offerPriceVatIncluded && (
                    <>
                        <Grid item xs={6}>
                            <HighlightContrastTypography>
                                {translateText(62)}
                                <HighlightContrastTypography variant="label">{` ${translateText(263)}`}</HighlightContrastTypography>
                            </HighlightContrastTypography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5} textAlign="right">
                            <HighlightContrastTypography>{currency(totals.offerPriceVatIncluded, currencyCode)}</HighlightContrastTypography>
                        </Grid>
                    </>
                )}
                <Grid item xs={6} />
                <Grid item xs={6} textAlign="right">
                    <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                </Grid>
            </Grid>
        </StyledBox>
    )
}
