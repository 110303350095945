import { useLocalization } from "@tm/localization"
import { WorkSchedule, ValidityPeriod } from "@tm/models"
import { Typography, Grid, Card, CardContent } from "@tm/components"
import { FullWidthTextField } from "../../../../../../_shared/StyledComponents"

type Props = {
    workSchedule?: WorkSchedule
    validityPeriod?: ValidityPeriod
}

export default function OfferDetails({ workSchedule, validityPeriod }: Props) {
    const { translateText, date } = useLocalization()
    const startDate = validityPeriod ? new Date(validityPeriod.fromDate) : undefined
    const endDate = validityPeriod && validityPeriod.toDate ? new Date(validityPeriod.toDate) : undefined
    const dueDate = workSchedule && workSchedule.expectedCompletionDate ? new Date(workSchedule.expectedCompletionDate) : undefined

    return (
        <Card sx={{ position: "relative", overflow: "initial" }}>
            <CardContent>
                <Typography variant="h2" mb={0.5}>
                    {translateText(43)}
                </Typography>
                <Grid container spacing={0.5} mb={1} columns={6} flex={1} flexWrap="wrap">
                    <Grid item sm={1}>
                        <FullWidthTextField label={translateText(97)} value={workSchedule?.referenceNumber} />
                    </Grid>
                    <Grid item sm={2}>
                        <FullWidthTextField label={translateText(34)} value={workSchedule?.performingEmployee} />
                    </Grid>
                    <Grid item sm={1}>
                        <FullWidthTextField value={startDate ? date(startDate, "d") : ""} label={`${translateText(343)} ${translateText(344)}`} />
                    </Grid>
                    <Grid item sm={1}>
                        <FullWidthTextField value={endDate ? date(endDate, "d") : ""} label={`${translateText(343)} ${translateText(95)}`} />
                    </Grid>
                    <Grid item sm={1}>
                        <FullWidthTextField value={dueDate ? date(dueDate, "d") : ""} label={`${translateText(64)} / ${translateText(65)}`} />
                    </Grid>
                    <Grid item sm={1} />
                    <Grid item sm={2}>
                        <FullWidthTextField label={`${translateText(342)} / ${translateText(93)}`} value={workSchedule?.employeeInCharge} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
