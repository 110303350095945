import { AddCustomPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"

export function useAddCustomPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: addCustomPartList } = useMutation(
        (request: { addCustomPartListRequest: AddCustomPartListRequest; isExternalCall?: boolean }) =>
            Data.addCustomPartList(request.addCustomPartListRequest),
        {
            onSuccess: (response, request) => {
                if (response) {
                    handleBasketUpdateWorkflow(request.addCustomPartListRequest.workTaskId, response)
                }
                if (request.isExternalCall) {
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                }
            },
        }
    )

    const addCustomParts = useCallback(
        (request: AddCustomPartListRequest, isExternalCall?: boolean) => {
            return addCustomPartList({ addCustomPartListRequest: request, isExternalCall })
        },
        [addCustomPartList]
    )

    return addCustomParts
}
