import { EFilterNames, ETyresSeason } from "@tm/models"
import { PrintImage, TyreAxles } from "../../models"
import { DriveRightTiresRespone, TyresCritsResponse } from "../../repositories"
import { TireBrands } from "../../repositories/tires-tiresBrands/model"

export type TyresWheelsSlice = TyresWheelsActions & TyresWheelsState

export type TyresWheelsActions = {
    tyresWheelsActions: {
        reset: () => void
        selectTab: (tab: WheelsAndTyresTab) => void
        completeCollapsible: (tabName: string, tabType: string, collapsibleName: string) => void
        setTyresLoading: () => void
        setTyresError: () => void
        setTyresLoaded: (data: DriveRightTiresRespone, tecDocUsed: number, tiresBrands: TireBrands) => void
        updateSelectedFilters: (tabName: string, tabType: string, selectedFilters: SelectedFilters) => void
        updateTyresImages: (image: PrintImage, path: TabIdentifier, replaceImage: boolean) => void
        updateCollapsibleData: (tabName: string, collapsibleName: string, data: CollapsibleData) => void
        setTyresCritsLoading: () => void
        setTyresCritsLoaded: (tyresCrits: TyresCritsResponse) => void
        setTyresCritsError: () => void
        updateSpareWheel: (hasSpareWheel: boolean) => void
        updateShowBonus: (showBonus: boolean) => void
        setSelectedTireType: (tireType: TireType) => void
        updateSafetyStatus: (safetyStatus: number, path: TabIdentifier) => void
        updateDescription: (description: string, path: TabIdentifier) => void
        updateTyreWear: (tyreWear: TyreWear, path: TabIdentifier) => void
        setTyreSpecification: (tyreSpecification: TyreAxles) => void
    }
}

export type TabIdentifier = {
    tabType: string
    collapsibleName: string
}

export enum TireType {
    SpareTire,
    RearTire,
}

export type TyresWheelsState = {
    tyresWheels: {
        tabs: WheelsAndTyresTab[]
        selectedTab: WheelsAndTyresTab
        tiresData: DriveRightTiresRespone & { loading: boolean }
        tecDocUsed: number
        tireBrands: TireBrands
        loadingCrits: boolean
        tyresCrits: Partial<TyresCritsResponse>
        spareWheel?: boolean
        showBonus?: boolean
        selectedTire?: TireType
        selectedTyreSpecification?: TyreAxles
    }
}

export type WheelsAndTyresTab = {
    index: number
    name: string
    translation: number
    isComplete: boolean
    collapsibleItems: CollapsibleInfo[]
    type: string
    selectedFilters: SelectedFilters
}

export type CollapsibleInfo = {
    name: string
    translateTextId: number
    completed: boolean
    selectedImages?: PrintImage[]
    infoData: CollapsibleData
    safetyStatus?: number
    description?: string
    tyreWear?: TyreWear
}

export type SelectedFilters = {
    width: string
    height: string
    inch: string
    loadIndex: string
    speedIndex: string
    size: string
    manufacturerId: string
    manufacturer: string
    tyreModel: string
    season: string
    rimSize: string
    offsetValue?: string
    isWinter?: boolean
    isSummer?: boolean
}

export type CollapsibleData = {
    rimCondition?: "aluminium" | "steel" | "none"
    profileDepth?: number
    dot?: number
    tyrePressure?: number
    brakeSystem?: Date
}

export type TyreWear = {
    left?: boolean
    center?: boolean
    right?: boolean
}

export const ExtendEFilterNames = {
    ...EFilterNames,
    size: "size",
    rimSize: "rimSize",
    manufacturerId: "manufacturerId",
}

export enum WheelsAndTyresTabNames {
    FrontLeft = "frontLeft",
    FrontRight = "frontRight",
    RearLeft = "rearLeft",
    RearRight = "rearRight",
    SpareWheel = "spareWheel",
}

export const ExtendETyreSeason = {
    ...ETyresSeason,
    spareTyre: 3, // spare tyres are in season because HP seems to handle them like that
}
