import { Typography, TypographyProps } from "@tm/components"
import OverflowTooltip from "./OverflowTooltip"

type Props = TypographyProps & {
    title: string
    content: string
}

const OverflowTypography: React.FC<Props> = (props) => {
    const { title, content, ref /** extract 'ref' here to not pass it to the typography */, ...typoProps } = props

    return (
        <OverflowTooltip title={title}>
            <Typography {...typoProps} maxLines={1}>
                {content}
            </Typography>
        </OverflowTooltip>
    )
}

export default OverflowTypography
