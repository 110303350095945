
import * as React from "react"
import { useDispatch } from "react-redux"

export const WrappedModule: React.FC<any> = ({ children }) => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: "MODULE_OPENED", payload: { icon: "group", title: "Eurotax - {{571}}" }, broadcast: true, })
    }, [])
    return children
}
