import { useUser } from "@tm/context-distribution"
import { Headline, Text } from "@tm/controls"

export const CustomerGeneralInformation = () => {
    const userSettings = useUser()?.userSettings

    if (!userSettings?.repairShop) {
        return null
    }

    const { companyName, contactInfo, postalAddress } = userSettings.repairShop

    return (
        <div className="general-info">
            <Headline>{companyName}</Headline>
            <Text size="s">{postalAddress?.street}</Text>
            <Text size="s">{postalAddress?.zip && postalAddress.city && `${postalAddress.zip}, ${postalAddress.city}`}</Text>
            <Text size="s">{contactInfo?.phone}</Text>
            <Text size="s">{contactInfo?.email}</Text>
        </div>
    )
}
