import { Box, Icon, Stack, styled } from "@tm/components"
import { AttributeItem } from "./AttributeItem"
import { AttributesBlock } from "."
import { useOeInformationItemState } from "../ContextProviderStates/OeInformationItemState"

const AttributesIcon = styled(Box)(() => ({
    alignItems: "center",
    marginRight: "10px",
    marginLeft: "5px",
    whiteSpace: "nowrap",
}))

export function OeInfoAttributes() {
    const attributes = useOeInformationItemState((x) => x.attributes)

    if (!attributes?.length) {
        return null
    }

    return (
        <Stack spacing={2} mt={1} mb={0.5}>
            {attributes?.map(({ topArticleAttributes, additionalAttributes }, blockIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stack direction="column" key={`OeAttribute_${blockIndex}`} alignItems="start" mr={1} ml={1}>
                    {topArticleAttributes && topArticleAttributes?.length > 0 && (
                        <Stack direction="row" alignItems="start" width="100%">
                            <AttributesIcon>
                                <Icon name="article" sx={{ verticalAlign: "middle" }} />
                            </AttributesIcon>
                            <AttributesBlock>
                                {topArticleAttributes?.map((attr, attrIndex) => (
                                    <AttributeItem
                                        key={`${attr.id}:${attr.key}`}
                                        attribute={attr}
                                        hideDivider={attrIndex + 1 === topArticleAttributes.length}
                                    />
                                ))}
                            </AttributesBlock>
                        </Stack>
                    )}

                    {additionalAttributes && additionalAttributes?.length > 0 && (
                        <Stack direction="row" alignItems="start" width="100%">
                            <AttributesIcon>
                                <Icon name="car" sx={{ verticalAlign: "middle" }} />
                            </AttributesIcon>
                            <AttributesBlock>
                                {additionalAttributes.map((attr, attrIndex) => (
                                    <AttributeItem
                                        key={`${attr.id}:${attr.key}`}
                                        hideDivider={attrIndex + 1 === additionalAttributes?.length}
                                        hide={false}
                                        attribute={attr}
                                    />
                                ))}
                            </AttributesBlock>
                        </Stack>
                    )}
                </Stack>
            ))}
        </Stack>
    )
}
