import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { useExternalCatalogUrl } from "@tm/utils"
import { useMemo } from "react"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { ArticleListType, ListOptions } from "../models"
import { getBundleParams } from "../../../utils"

/** @todo Merge somehow with `ArticleListConfiguration` */
export function useListOptions(type: ArticleListType): ListOptions {
    const { addToBasketExternalSystemId } = Morpheus.getParams("basket")
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const { hideCostEstimationButton } = getBundleParams()

    const { showDataSupplierLogos, highlightCostEstimationButton, hasWatchList } = useArticleListConfiguration()

    return useMemo(() => {
        switch (type) {
            default:
                return {
                    showDocumentsInline: false,
                    showDocumentsInCompact: false,
                    openDocumentsAsModal: true,
                    showReferenceLinksInCompact: false,
                    showDataSupplierLogos,
                    highlightCostEstimationButton,
                    externalCatalogUrl,
                    hasWatchList,
                    hideCostEstimationButton,
                }
        }
    }, [externalCatalogUrl, highlightCostEstimationButton, showDataSupplierLogos, hasWatchList, type, hideCostEstimationButton])
}
