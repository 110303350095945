import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()

    const styles: NestedCSSProperties = {
        $nest: {
            "&__search-container": {
                width: "100%",
            },
            "&__search": {
                $nest: {
                    ".btn:last-child": {
                        background: colors.highlight,
                        $nest: {
                            ".icon": {
                                fill: colors.light,
                            },
                        },
                    },
                },
            },
            "&__history": {
                marginTop: "0",
                flexWrap: "wrap",
                alignItems: "flex-start",
                flex: "0 0 auto !important",
                $nest: {
                    "&-title": {
                        lineHeight: "1.6rem",
                    },
                    ".tag": {
                        marginBottom: "0.25em",
                    },
                },
            },
            "&__actions": {
                position: "absolute",
                bottom: 0,
                display: "flex",
            },
        },
    }

    return deepAssign(baseStyles, styles)
}
