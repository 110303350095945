import { MouseEvent, useState, useRef, useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, uniqueId, getStorage, RouteComponentProps, withRouter, useKeyValue, plateCountryCodeMapper } from "@tm/utils"
import { SearchField, Table, LicensePlateField, InputGroup, Toolbar, Popover, Button } from "@tm/controls"
import { Box, Typography, Badge, BadgeProps, Icon, Switch, SwitchProps, Button as MuiButton, Stack, Tooltip } from "@tm/components"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { Actions, IActions } from "./business"
import { SearchState, WorkTaskListItem } from "./business/model"
import { getWorkTaskLabellingFromListItem } from "../../helpers"
import { getBundleParams } from "../../utils"
import { CompleteWorkTaskButtonComponent } from "../_shared/CompleteWorkTaskButton"
import { getLicensePlateCountryCode } from "../../helper/countrycode"

type Props = RouteComponentProps<void> & {
    state: SearchState
    actions: IActions
    icon?: string
    title?: string
    searchOnType?: boolean
    hideTimeColumn?: boolean
    showOnlyUserWorkTasksByDefault?: boolean
}

export const LOCAL_STORAGE_KEY = "WORKTASK_DELETE_DIALOG_DEACTIVATED"
const MAX_LOCAL_STORAGE_ENTRY_AGE = 60 * 60 * 24 * 1000

function SearchComponent(props: Props) {
    const localization = useLocalization()
    const { translateText, translate, date } = localization
    const { createWorkTask } = useWorkTask() || {}
    const { state, actions, icon, title, searchOnType, hideTimeColumn, history, showOnlyUserWorkTasksByDefault } = props

    const [deletionPromptOpen, setDeletionPromptOpen] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [query, setQuery] = useState<string>()
    const [showWithOrderOnly, setShowWithOrderOnly] = useKeyValue({ key: "WORKTASK_SHOW_WITH_ORDER_ONLY", ownedByRepairShop: false })
    const onlyWithOrder = showWithOrderOnly ? showWithOrderOnly === "true" : false
    const [showOnlyUserWorkTasks, setShowOnlyUserWorkTasks] = useKeyValue({ key: "WORKTASK_SHOW_ONLY_USER_WORKTASKS", ownedByRepairShop: false })
    const onlyUserWorkTasks = showOnlyUserWorkTasks ? showOnlyUserWorkTasks === "true" : showOnlyUserWorkTasksByDefault
    const _viewBoxRef = useRef<HTMLDivElement>(null)
    const _searchTimeout = useRef<number>()
    const [viewOptionsExpanded, setViewOptionsExpanded] = useState(false)
    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()
    useEffect(() => {
        const localStorageEntry = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)
        /* delete localstorage entry after 24h if the user reload the page -> NEXT-15870 */
        if (localStorageEntry && +localStorageEntry < Date.now() - MAX_LOCAL_STORAGE_ENTRY_AGE) {
            getStorage("localStorage").removeItem(LOCAL_STORAGE_KEY)
        }

        document.addEventListener("keydown", (event) => {
            event.altKey && !event.key.includes("Alt") && setOpen(false)
        })
    }, [])

    function getRowKey(workTask: WorkTaskListItem) {
        return workTask.workTaskId
    }

    function handleToggleHistory() {
        if (!open) {
            setQuery(undefined)

            setTimeout(() => {
                actions.findWorktasks("", onlyWithOrder, onlyUserWorkTasks)
            }, 100)
        }
        setOpen(!open)
    }

    function handleLoadWorkTask(workTask: WorkTaskListItem) {
        setOpen(false)
        history.push(`/${encodeUniqueId(workTask.workTaskId)}`)
    }

    function handleSearchChange(query: string) {
        clearTimeout(_searchTimeout.current)
        setQuery(query)

        if (!query || (searchOnType && query.length > 1)) {
            _searchTimeout.current = window.setTimeout(() => {
                actions.findWorktasks(query, onlyWithOrder, onlyUserWorkTasks)
            }, 500)
        }
    }

    function handleSearch() {
        if (!searchOnType && query && query.length > 1) {
            clearTimeout(_searchTimeout.current)
            actions.findWorktasks(query, onlyWithOrder, onlyUserWorkTasks)
        }
    }

    function handleShowWithOrderOnlyChange() {
        clearTimeout(_searchTimeout.current)

        _searchTimeout.current = window.setTimeout(() => {
            actions.findWorktasks(query, !onlyWithOrder, onlyUserWorkTasks)
        }, 500)

        setShowWithOrderOnly?.((!onlyWithOrder).toString())
    }

    function handleShowMyOrdersChange() {
        clearTimeout(_searchTimeout.current)

        _searchTimeout.current = window.setTimeout(() => {
            actions.findWorktasks(query, onlyWithOrder, !onlyUserWorkTasks)
        }, 500)

        setShowOnlyUserWorkTasks?.((!onlyUserWorkTasks).toString())
    }

    function handleWorktaskDelete() {
        actions.findWorktasks(query, onlyWithOrder, onlyUserWorkTasks)
    }

    async function handleClickCreateNewWorktask(workTask: WorkTaskListItem, e: Event) {
        e.stopPropagation()
        const workTaskId = uniqueId()
        await createWorkTask?.({
            workTaskId,
            customer: workTask.customer?.customerId,
            vehicle: workTask.vehicle?.vehicleId,
        })
        setOpen(false)
        history.push(`/${encodeUniqueId(workTaskId)}`)
    }

    function handleScrollBottom() {
        if (open) {
            actions.findWorktasksNextPage(query, onlyWithOrder, onlyUserWorkTasks)
        }
    }

    function renderActionCell(workTask: WorkTaskListItem) {
        return (
            <Table.Cell>
                {(workTask.vehicle || workTask.customer) && (
                    <Button icon="add" onClick={(e) => handleClickCreateNewWorktask(workTask, e)} title={translateText(12442)} />
                )}
                <CompleteWorkTaskButtonComponent
                    onPromptToggle={setDeletionPromptOpen}
                    workTaskId={workTask.workTaskId}
                    onWorkTaskCompleted={handleWorktaskDelete}
                />
            </Table.Cell>
        )
    }

    function renderNumberCell(workTask: WorkTaskListItem) {
        const { vehiclePlateId, voucherNo, voucherType, vehicle } = workTask
        const vehicleCountryCode = plateCountryCodeMapper(vehicle?.countryCode || defaultPlateCode)

        return (
            <Table.Cell>
                {voucherType && (
                    <Typography title={`${voucherType} ${voucherNo}`} variant="body3">
                        {voucherType} {voucherNo}
                    </Typography>
                )}
                {vehiclePlateId && (
                    <LicensePlateField
                        size="s"
                        showCountryCode={!!vehicleCountryCode}
                        shortCountryCode={vehicleCountryCode || ""}
                        readonly
                        value={vehiclePlateId}
                    />
                )}
            </Table.Cell>
        )
    }

    function renderTotalNumbersCell(workTask: WorkTaskListItem) {
        const { partPositionsTotal, workPositionsTotal } = workTask

        return (
            <Table.Cell>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="article" />
                    <Badge color="primary" size="small" badgeContent={partPositionsTotal || "0"} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="repairtimes" />
                    <Badge color="primary" size="small" badgeContent={workPositionsTotal || "0"} />
                </Stack>
            </Table.Cell>
        )
    }

    function renderOrderStatusCell(workTask: WorkTaskListItem) {
        const { transmittedOrderStatus } = workTask

        let icon = ""
        let title = ""
        let color: BadgeProps["color"]

        switch (transmittedOrderStatus) {
            case 0:
                icon = "add"
                title = translateText(1888)
                break
            case 1:
                icon = "paperplane"
                title = translateText(1887)
                color = "secondary"
                break
            case 2:
                icon = "partially-available"
                title = translateText(1886)
                color = "warning"
                break
            case 3:
                icon = "check"
                title = translateText(1885)
                color = "success"
                break
        }

        return (
            <Table.Cell>
                <Box>
                    {transmittedOrderStatus >= 0 && (
                        <Tooltip title={title}>
                            <Box>
                                {transmittedOrderStatus >= 0 ? <Icon name="order-history" /> : <Icon name="article" />}
                                <Badge
                                    color={color}
                                    size="small"
                                    badgeContent={<Icon name={icon} height="12px" width="12px" />}
                                    sx={{ right: "16px" }}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </Table.Cell>
        )
    }

    function renderDetailsCell(workTask: WorkTaskListItem) {
        const labelling = getWorkTaskLabellingFromListItem(workTask, localization)
        return (
            <Table.Cell>
                <Typography>{labelling.title}</Typography>
                {!!labelling.info && <Typography variant="body3">{labelling.info}</Typography>}
            </Table.Cell>
        )
    }

    function renderAuditCell(workTask: WorkTaskListItem) {
        const { modifiedDate } = workTask

        return <Table.Cell>{modifiedDate && <Typography>{date(modifiedDate, "t")}</Typography>}</Table.Cell>
    }

    function renderTableColumns(): Array<any> {
        const columns = []

        if (!hideTimeColumn) {
            columns.push(<Table.Column className="creation-info" renderItemContent={renderAuditCell} />)
        }

        columns.push(
            <Table.Column className="total-numbers" renderItemContent={renderTotalNumbersCell} />,
            <Table.Column className="details" renderItemContent={renderDetailsCell} />,
            <Table.Column className="numbers" renderItemContent={renderNumberCell} />,
            <Table.Column className="order-status" renderItemContent={renderOrderStatusCell} />,
            <Table.Column className="actions" renderItemContent={renderActionCell} />
        )

        return columns
    }

    function renderGroupHeadline(workTask: WorkTaskListItem): any {
        return <Typography>{workTask.modifiedDate ? date(workTask.modifiedDate, "d") : "-"} </Typography>
    }

    function handleOutsideClick(event: MouseEvent) {
        if (_viewBoxRef.current && !_viewBoxRef.current.contains(event.target as Node) && open && !deletionPromptOpen) {
            setOpen(false)
        }
    }

    function handleViewOptionsClick() {
        setViewOptionsExpanded(!viewOptionsExpanded)
    }

    function ViewOption(props: SwitchProps) {
        return <Switch labelPlacement="start" size="medium" typographySx={{ marginRight: "auto" }} {...props} />
    }

    function renderViewOptions(switchProps?: SwitchProps) {
        return (
            <>
                <ViewOption
                    {...switchProps}
                    label={translateText(143)}
                    onChange={handleShowWithOrderOnlyChange}
                    labelPlacement="start"
                    checked={onlyWithOrder}
                />
                <ViewOption
                    {...switchProps}
                    label={translateText(13125)}
                    onChange={handleShowMyOrdersChange}
                    labelPlacement="start"
                    checked={onlyUserWorkTasks}
                />
            </>
        )
    }

    function renderViewbox() {
        const { loading, workTasks } = state

        const className = `worktask-search ${open ? "worktask-search--open" : ""}`

        if (!open) {
            return null
        }
        return (
            <div className={className} onClick={handleOutsideClick}>
                <div className="card">
                    <div className="card__inner" ref={_viewBoxRef}>
                        <div className="header">
                            <Typography variant="h2" mt={2} mr={2}>
                                {translate(369)}
                            </Typography>
                            <Toolbar title={translateText(135)}>
                                <InputGroup>
                                    <SearchField
                                        size="l"
                                        className="input-search"
                                        placeholder={translateText(337)}
                                        value={query}
                                        onChange={handleSearchChange}
                                        onChangeConfirm={handleSearch}
                                        showClear
                                        loading={loading}
                                    />
                                    <MuiButton
                                        startIcon={<Icon name="search" />}
                                        className="btn-search"
                                        onClick={handleSearch}
                                        disabled={!query || query.length < 2}
                                    />
                                </InputGroup>
                            </Toolbar>
                            <Toolbar title={translateText(222)}>
                                <div style={{ position: "relative" }}>
                                    <MuiButton onClick={handleViewOptionsClick}>{translateText(177)}</MuiButton>
                                    <Popover
                                        active={viewOptionsExpanded}
                                        className="view-options"
                                        onOutsideInteraction={() => setViewOptionsExpanded(false)}
                                    >
                                        {renderViewOptions({ formControlLabelSx: (theme) => ({ display: "flex", paddingRight: theme.spacing(2) }) })}
                                    </Popover>
                                </div>
                            </Toolbar>
                        </div>
                        <MuiButton size="large" className="close" startIcon={<Icon name="close" />} onClick={handleToggleHistory} />
                        <Table
                            data={workTasks}
                            columns={renderTableColumns()}
                            groupBy={renderGroupHeadline}
                            onClickRow={deletionPromptOpen ? undefined : handleLoadWorkTask}
                            getRowKey={getRowKey}
                            onScrollBottom={handleScrollBottom}
                            scrollable
                        />
                    </div>
                </div>
            </div>
        )
    }

    function renderButtonText() {
        if (!title) {
            return null
        }

        return <div className="text--m">{/^\d+/.test(title) ? translateText(title) : title}</div>
    }

    const { nextLight, hideWorktaskSearchButton } = getBundleParams()
    if (nextLight || hideWorktaskSearchButton) {
        return null
    }

    return (
        <>
            <Button
                size="l"
                layout={["ghost"]}
                icon={icon || "menu"}
                title={translateText(369)}
                onClick={handleToggleHistory}
                className={`worktask-search-btn tab tab--worktask${title ? " has-text" : ""}`}
            >
                {renderButtonText()}
            </Button>
            {renderViewbox()}
        </>
    )
}

export default connectComponent(Actions, withRouter(SearchComponent))
