import { Button, Tag } from "@tm/controls"
import { styled } from "@tm/components"
import { useActions } from "@tm/morpheus"
import { CalcInput } from "../../../data/model"
import { MainActions } from "../../main/business"
import { getButtonSkin } from "../../../data/helpers"
import { ECalcInputType } from "../../../data/enums"
import { getCalcStateIcon } from "../../../data/helpers/getCalcInputIcon"

type Props = {
    className?: string
    item: CalcInput
}

export default function CalcInputButton(props: Props) {
    const { className, item } = props
    const actions = useActions(MainActions, "handleInputSubmit")

    if (!item) {
        return <></>
    }

    const icon = getCalcStateIcon(item.icon)

    const handleChange = (event: MouseEvent) => {
        event.stopPropagation()
        actions.handleInputSubmit(item)
    }

    if (item.type == ECalcInputType.SwitchOeGroup) {
        return (
            <Tag
                onClick={() => handleChange}
                label={item.description}
                value={item.label}
                layout="holo"
                skin={item.isDisabled ? "primary" : undefined}
            />
        )
    }
    return (
        <StyledButton className={className} disabled={item.isDisabled} onClick={handleChange} skin={getButtonSkin(item.btnStyle)} icon={icon}>
            {item.label}
        </StyledButton>
    )
}

const StyledButton = styled(Button)({
    "&.showAlternatives": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "6em",
        marginLeft: "auto",
    },
})
