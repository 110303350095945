import { important, px } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { classes } from "@tm/utils"
import { SelectedCalcStateSelector } from "../../../data/helpers"
import { CalcConsumable, CalculationContext } from "../../../data/model"
import { ArticleCell, ArticleInput, BorderedCollapsible, CalcInputComponent } from "../../_shared"

export default function ConsumablesList() {
    const style = useMemo(() => getStyle(), [])
    const { selectedCalcState } = useSelector(SelectedCalcStateSelector)
    const consumablesList = (selectedCalcState?.context as CalculationContext | undefined)?.consumables

    const renderCosumableItem = (item: CalcConsumable) => {
        return (
            <div className={style.articleItemEelement} key={item.id}>
                <div className={classes("article-list__item", "article-list__panel", style.articleItem)} key={item.id}>
                    {item.articleInput && !item.articleInput.isInvalid && (
                        <ArticleCell bemModifier="numbers" className={style.flex}>
                            <ArticleInput item={item.articleInput} genArtLabel={item.genArtLabel} />
                        </ArticleCell>
                    )}

                    {item.labelInput && (
                        <ArticleCell bemModifier="numbers" className={style.flex}>
                            <CalcInputComponent item={{ ...item.labelInput, label: item.genArtLabel }} className={style.consumables} />
                        </ArticleCell>
                    )}

                    <ArticleCell bemModifier="description" />

                    <ArticleCell bemModifier="actions" className={style.specifications}>
                        <CalcInputComponent item={item.specifications} />
                    </ArticleCell>

                    <ArticleCell bemModifier="erp-information">
                        <CalcInputComponent item={item.price} />
                    </ArticleCell>

                    <ArticleCell bemModifier="actions">
                        <CalcInputComponent item={item.quantity} />
                        <CalcInputComponent item={item.removeInput} />
                    </ArticleCell>
                </div>
            </div>
        )
    }

    if (!consumablesList?.consumables?.length) {
        return null
    }

    return (
        <>
            <BorderedCollapsible name={consumablesList?.label ?? ""} initiallyOpened>
                <div className="article-list">{consumablesList.consumables.map(renderCosumableItem)}</div>
            </BorderedCollapsible>
            <div className={style.hint}>
                <div className={style.colorPrimary}>
                    <Icon className={style.bulbColor} name="bulb" /> {`${consumablesList?.hint.title || ""}: `}
                </div>
                <div>{consumablesList?.hint.label || ""}</div>
            </div>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        articleItem: { padding: ".5em .5em .25em ", minHeight: "0 !important" },
        specifications: {
            flex: 1,
            $nest: {
                "&>div": {
                    flex: 1,
                },
            },
        },
        consumables: {
            display: "flex",
            alignSelf: "stretch",
            flex: 1,
            $nest: {
                ".suggest--new .scrollbar__view": {
                    position: important("absolute"),
                    marginRight: "-23px",

                    $nest: {
                        ".fancy-list__item": {
                            marginBottom: px(4),
                            backgroundColor: theme.colors.light,
                            boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                        },
                    },
                },
                ".suggest--new": {
                    flex: 1,
                },
                ".suggest": {
                    flex: 1,
                },
            },
        },
        anotherConsumable: {
            display: "flex",
            flex: 1,
            $nest: {
                ".suggest": {
                    flex: 1,
                },
            },
        },
        flex: {
            flex: 0.6,
        },
        hint: {
            display: "flex",
            alignItems: "center",
            padding: ".5em",
            gap: ".35em",
        },
        colorPrimary: {
            color: theme.colors.primary,
            display: "flex",
            alignItems: "center",
            gap: ".35em",
        },
        bulbColor: {
            fill: "currentColor",
        },
        articleItemEelement: {
            margin: "1em 0",
            $nest: {
                "&:last-child": {
                    marginBottom: 0,
                },
            },
        },
    })(ConsumablesList)
}
