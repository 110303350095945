import { FastCalculation, Vehicle, CarModelDetailsResponse, WorkCategory, ECalcOrigin } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { ClickButtonRequest } from "../../../data/repositories/fastcalculator-clickButton/model"
import { SubmitInputRequest } from "../../../data/repositories/fastcalculator-submitInput/model"
import { SelectSelectionItemRequest } from "../../../data/repositories/fastcalculator-selectSelection/model"
import { FastCalcRequest } from "../../../data/repositories/fastcalculator-init/model"
import { CalcState, CalcStateButton, CalcInput, CalcSelectionItem, BasketCalcStateContext, CalcDropDownItem } from "../../../data/model"
import { ECalcWorkflow, ECalcState, ESearchLink, ECalcButtonState } from "../../../data/enums"
import { SubmitDropdownItemClickRequest } from "../../../data/repositories/fastcalculator-dropdown/model"
import { SubmitInitialDataRequest } from "../../../data/repositories/fastcalculator-initialData/model"
import { CalcProductGroupsRequest } from "../../../data/repositories/fastcalculator-calcProductGroups/model"
import { InitialDataCalcStateData } from "../../../data/repositories"
import { ArticleData, AddPartFromArticleListRequest } from "../../../data/repositories/fastcalculator-addPartFromArticleList/model"

export function createInitCalculationRequest(
    vehicle: Vehicle,
    fromWidget: boolean,
    origin: ECalcOrigin,
    calcId?: string,
    isWorkshop?: boolean
): FastCalcRequest {
    return {
        calcWorkflow: ECalcWorkflow.Hyperion,
        ignoreCache: false,
        preferePreviewCalcStates: fromWidget,
        calcId: calcId || "",
        vehicleId: vehicle.id,
        historySize: 10,
        eTag: "",
        sprNr: 4,
        kTypNr: vehicle.tecDocTypeId,
        invalidInputs: [],
        commands: [],
        calcOrigin: isWorkshop ? ECalcOrigin.SvcConnect : origin,
    }
}

export function createButtonInputRequest(
    calcButton: CalcStateButton,
    parentCalcState: CalcState,
    vehicle: Vehicle,
    kTypNr: number,
    selectedCalcState: CalcState,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    requestQueueId?: string
): ClickButtonRequest {
    return {
        calcButton,
        parentCalcState,
        preferePreviewCalcStates: false,
        vehicleId: vehicle.id,
        calcOrigin,
        requestQueueId,
        kTypNr,
        ...((calcButton.type == ECalcButtonState.StartCalculation || calcButton.type == ECalcButtonState.StartNewCalculation) && {
            initialDataCalcState: {
                ...selectedCalcState,
                context: undefined,
            },
            initialDataCalcStateContext: {
                type: ECalcState.InitialData,
                initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate),
            },
        }),
    }
}

export function createSubmitInputRequest(
    calcInput: CalcInput,
    parentCalcState: CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): SubmitInputRequest {
    return {
        calcInput,
        parentCalcState,
        preferePreviewCalcStates: parentCalcState.type == ECalcState.FastCockpitPreviewNext,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createSelectionItemClickRequest(
    items: CalcSelectionItem[],
    parentCalcState: CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): SelectSelectionItemRequest {
    return {
        items,
        parentCalcState,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createSubmitDropdownItemClickRequest(
    calcInput: CalcInput,
    calcDropdownItem: CalcDropDownItem,
    parentCalcState: CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): SubmitDropdownItemClickRequest {
    return {
        calcInput,
        calcDropdownItem,
        parentCalcState,
        preferePreviewCalcStates: parentCalcState.type == ECalcState.FastCockpitPreviewNext,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createImportCalculationRequest(
    calcStates: CalcState,
    defaultHourlyRate?: number,
    worktask?: WorkTaskInfo,
    memo?: string
): FastCalculation.ImportFastCalculationRequest | undefined {
    const context = calcStates.context as BasketCalcStateContext | undefined
    if (!worktask?.vehicle || !context) {
        return
    }

    const works: Array<FastCalculation.RepairTime> = []
    const customWorks: Array<FastCalculation.CustomWork> = []

    const customOeArticles = context.oeParts.filter((x) => !x.oeArticleNumber)
    const customParts = context.customParts.map((x) => ({
        ...x,
        articleNumber: x.articleNumber ? x.articleNumber : x.articleNumber.match(/[^\s]+/)?.[0] ?? "",
    }))

    context.works.forEach((x) => {
        // Hourly rate set individually on work or globally for the whole calculation
        const hourlyRate = x.alternativeHourlyRate ?? context.labourRate / 100 // Labour rate has a factor of 100

        if (hourlyRate != defaultHourlyRate) {
            // If hourly rate is different then the default the work has to be a custom one
            customWorks.push({
                hourlyRate,
                customWorkNumber: x.providerWorkId ?? "",
                description: x.description,
                time: x.time ?? 0,
            })
        } else if (!x.providerWorkId) {
            customWorks.push({
                hourlyRate,
                customWorkNumber: "",
                description: x.description,
                time: x.time ?? 0,
            })
        } else {
            works.push({
                description: x.description,
                providerWorkId: x.providerWorkId ?? "",
                time: x.time ?? 0,
                categoryOfWork: WorkCategory.WorkshopWork,
            })
        }
    })

    return {
        workTaskId: worktask.id,
        vehicleId: worktask.vehicle.id,
        parts: context.parts.map((x) => {
            const { garagePrice, ...rest } = x
            return {
                ...rest,
                ...(!x.wholesalerArticleNumber && { garagePrice: x.garagePrice }),
                ...(memo && { memo }),
            }
        }),
        oeParts: context.oeParts
            .filter((x) => x.oeArticleNumber)
            .map((x) => ({
                ...x,
                quantityValue: x.quantityValue ?? 1, // TODO: for now default to quantity of 1 but really the calculation should not be "importable" as long as the quantities of all oe parts are not set
                oePriceValue: x.oePriceValue || undefined, // replace oePriceValue of 0 with undefined, otherwise there is an error during import
                currencyCode: x.currencyCode ?? context.wkz,
            })),
        customParts: [
            ...customOeArticles.map((x) => {
                return { ...x, quantityValue: x.quantityValue || 1, garagePrice: x.oePriceValue }
            }),
            ...customParts.map((x) => ({
                ...x,
                quantityValue: x.quantityValue ?? 1, // TODO: for now default to quantity of 1 but really the calculation should not be "importable" as long as the quantities of all custom parts are not set
            })),
        ],
        works,
        customWorks,
        repairTimesProvider: context.repairTimesProvider,
        repairTimeDivision: 1, // TODO: division depends on the repairTimesProvider (HaynesPro = 1) and should be supplied by the FastCalculator service
    }
}

export function createInitialDataRequest(
    selectedCalcState: CalcState,
    vehicle: Vehicle,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    preferePreviewCalcStates = false
): SubmitInitialDataRequest {
    return {
        preferePreviewCalcStates,
        kTypNr: vehicle.tecDocTypeId,
        historySize: 10,
        initialDataCalcState: {
            ...selectedCalcState,
            context: undefined,
        },
        initialDataCalcStateContext: {
            type: ECalcState.InitialData,
            initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate),
        },
        calcOrigin,
    }
}

export function createCalcProductGroupsRequest(
    productGroupIds: Array<number>,
    vehicle: Vehicle,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    origin?: string
): CalcProductGroupsRequest {
    let calcOriginLabel = "Origin:Searchtree"
    let rewritedCalcOrigin = calcOrigin
    if (origin && +origin) {
        calcOriginLabel = `Origin:${ECalcOrigin[+origin]}`
        rewritedCalcOrigin = ECalcOrigin.Next | +origin
    }

    return {
        kTypNr: vehicle.tecDocTypeId,
        calcKeyShards: [vehicle.id, calcOriginLabel, productGroupIds.join(",")],
        initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate),
        genArtNrs: productGroupIds,
        calcOrigin: rewritedCalcOrigin,
    }
}

function createInitialData(
    vehicle: Vehicle,
    languageId: string,
    vehicleId: string,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number
): InitialDataCalcStateData {
    return {
        cylinders: carModel?.modelDetails?.cylinderCount ?? 0,
        eTypArt: vehicle.vehicleType,
        engineCode: vehicle.engineCode ?? "",
        engineCodes: carModel?.modelDetails?.engineCodes ?? [],
        isLonglife: vehicle.longlife,
        kTypNr: vehicle.tecDocTypeId,
        labourRate: defaultHourlyRate ? defaultHourlyRate * 100 : undefined,
        mileage: vehicle.mileAge ?? 0,
        monthYearDateFormat: languageId == "1" ? "dd.MM.yyyy" : "MM.dd.yyyy",
        numberLocal: "",
        registrationDate: vehicle.initialRegistration ? new Date(vehicle.initialRegistration.setHours(12)) : undefined,
        nextVehicleId: vehicleId,
        vin: vehicle.vin,
    }
}

export function createAddPartFromArticleListRequest(
    article: ArticleData,
    searchType: ESearchLink,
    parentCalcState: CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): AddPartFromArticleListRequest {
    return {
        ...article,
        parentCalcState,
        searchType,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createMemo({ vehicle, customer }: WorkTaskInfo): string | undefined {
    const memoParts = []

    if (vehicle) {
        if (vehicle.plateId) {
            memoParts.push(vehicle.plateId)
        } else {
            memoParts.push(vehicle.manufacturer, vehicle.modelSeries, vehicle.model)
        }
    }

    if (customer?.firstName) {
        memoParts.push(customer.firstName)
    }

    if (customer?.lastName) {
        memoParts.push(customer.lastName)
    }

    if (memoParts.length) {
        return memoParts.join(" ")
    }
}
