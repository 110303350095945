import React, { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Loader, Typography } from "@tm/components"
import { BasketWork } from "../../../../models"

type Props = {
    work: BasketWork
    loading?: boolean
}

export default function WorkPriceComponent({ loading, work }: Props) {
    const { workItem, calculatedWork } = work
    const { currency, translate } = useLocalization()

    const hourlyRate = useMemo(() => {
        if (calculatedWork?.hourlyRateWithSurcharge) {
            return calculatedWork.hourlyRateWithSurcharge
        }
        if (workItem.hourlyRate) {
            return workItem.hourlyRate
        }
        return 0
    }, [workItem.hourlyRate, calculatedWork?.hourlyRateWithSurcharge])

    if (loading) {
        return <Loader size="small" />
    }

    return (
        <>
            {workItem.fixedPriceValue ? (
                <Box textAlign="end">
                    <Typography variant="label">{translate(246)}</Typography> <br />{" "}
                    {currency(workItem.fixedPriceValue, workItem.currencySymbol || workItem.currencyCode)}{" "}
                </Box>
            ) : (
                currency(hourlyRate, workItem.currencySymbol || workItem.currencyCode)
            )}
        </>
    )
}
