import { useCallback, useState } from "react"
import {
    Box,
    Button,
    Dialog,
    MuiTable as Table,
    MuiTableBody as TableBody,
    MuiTableHead as TableHead,
    MuiTableRow as TableRow,
    Tooltip,
    Typography,
    styled,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CisCustomerState, CisFindCustomerDto } from "@tm/models"

import { getBundleParams } from "../../../../utils"
import { OverflowTooltipTableCell, StyledCell } from "./TableCells"

type PartnerListProps = {
    items: Array<CisFindCustomerDto>
    onSelectItem(partner: CisFindCustomerDto): void
}

const StyledRow = styled(TableRow)(({ theme }) => {
    return {
        "&:nth-of-type(odd)": {
            backgroundColor: "#f7f7f7",
        },
        cursor: "Pointer",
        "&>td:first-of-type": {
            paddingLeft: "8px",
        },
        "&:hover": {
            "&>td": {
                padding: "7px 4px 8px 6px",
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                borderTop: `1px solid ${theme.palette.primary.main}`,
                "&:first-of-type": {
                    paddingLeft: "4px",
                    borderLeft: `4px solid ${theme.palette.primary.main}`,
                },
                "&:last-child": {
                    paddingRight: "5px",
                    borderRight: `1px solid ${theme.palette.primary.main}`,
                },
            },
        },
        "&.blocked": {
            "&>td": {
                color: "black",
                opacity: "0.25",
            },
        },
    }
})

export function PartnerList(props: PartnerListProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<CisFindCustomerDto>()
    const { items, onSelectItem } = props
    const { translateText } = useLocalization()
    const { activateOtherSalesOfficeHint } = getBundleParams()

    const displayInfo = items.some((i) => i.information)
    const displayContact = items.some((i) => i.contactFirstName || i.contactLastName)
    const displayPhone = items.some((i) => i.phone)
    const displayAdditionalName = items.some((i) => i.companyNameAddition1)

    const isBlocked = (item: CisFindCustomerDto & { state?: number; stateDescription?: string }) => {
        return item?.state === CisCustomerState.TSMBlocked
    }

    const getContactName = (item: CisFindCustomerDto) => {
        return `${item.contactFirstName || ""} ${item.contactLastName || ""}`.trim()
    }

    const handleOtherSalesOfficeLogic = (item: CisFindCustomerDto) => {
        if (!isBlocked(item)) {
            onSelectItem(item)
        } else {
            setSelectedItem(item)
            setModalOpen(true)
        }
    }

    const handleOnSelectItem = (item: CisFindCustomerDto) => {
        if (activateOtherSalesOfficeHint) {
            handleOtherSalesOfficeLogic(item)
            return
        }

        onSelectItem(item)
    }

    const handelClickedModalOkButton = () => {
        if (selectedItem) {
            setModalOpen(false)
            onSelectItem(selectedItem)
        }
    }

    const handleCloseModal = useCallback(() => {
        setModalOpen(false)
    }, [])

    //  full width visible Next-16026:
    // - Partnernummer
    // - Zip Code
    // - City
    // - Phonenumber
    return (
        <>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <StyledCell>{translateText(1760) /* Partnernummer */}</StyledCell>
                        {displayContact && <StyledCell>{translateText(950) /* Ansprechpartner */}</StyledCell>}
                        <StyledCell>{translateText(108) /* Firma */}</StyledCell>
                        {displayAdditionalName && <StyledCell>{translateText(821) /* "Name2" */}</StyledCell>}
                        <StyledCell>{translateText(111) /* Straße Nr */} </StyledCell>
                        <StyledCell>{translateText(112) /* Zip Code */}</StyledCell>
                        <StyledCell>{translateText(113) /* City */}</StyledCell>
                        {displayPhone && <StyledCell>{translateText(110) /* Telefon */}</StyledCell>}
                        {displayInfo && <StyledCell>{translateText(796) /* Information */}</StyledCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <Tooltip key={item.partnerNo} title={item.stateDescription || ""} width={500}>
                            <StyledRow onClick={() => handleOnSelectItem(item)} className={isBlocked(item) ? "blocked" : ""}>
                                <StyledCell>{item.customerNumberToShow || item.partnerNo} </StyledCell>
                                {displayContact && <StyledCell>{getContactName(item)}</StyledCell>}
                                <OverflowTooltipTableCell tooltipContent={isBlocked(item) ? "" : item.company}>
                                    {item.company}
                                </OverflowTooltipTableCell>
                                {displayAdditionalName && (
                                    <OverflowTooltipTableCell tooltipContent={isBlocked(item) ? "" : item.companyNameAddition1}>
                                        {item.companyNameAddition1}
                                    </OverflowTooltipTableCell>
                                )}
                                <OverflowTooltipTableCell tooltipContent={isBlocked(item) ? "" : item.street}>{item.street}</OverflowTooltipTableCell>
                                <StyledCell>{item.zip}</StyledCell>
                                <StyledCell>{item.city}</StyledCell>
                                {displayPhone && <StyledCell>{item.phone}</StyledCell>}
                                {displayInfo && <StyledCell>{item.information}</StyledCell>}
                            </StyledRow>
                        </Tooltip>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={modalOpen} onOutsideClick={handleCloseModal}>
                <Box>
                    <Typography>
                        {translateText(13020)}
                        <br />
                        {translateText(13021)}
                        <br />
                        <br />
                        {translateText(13022)}
                    </Typography>
                </Box>

                <Box pt={3} px={3} textAlign="center">
                    <Button variant="contained" color="success" onClick={handleCloseModal}>
                        {translateText(317)}
                    </Button>
                    <Button variant="outlined" sx={{ marginLeft: 5 }} onClick={() => handelClickedModalOkButton()}>
                        {translateText(13023)}
                    </Button>
                </Box>
            </Dialog>
        </>
    )
}
