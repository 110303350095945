import { channel, getWorkTaskId, ModuleTab } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { decodeUniqueId, encodeUniqueId } from "@tm/utils"
import { ComponentActionType, DefaultTab, ModuleNavigationState } from "."
import { loadState } from "../../../data"

export function init(defaultTabs: DefaultTab[], activateDefaultTabs?: boolean): AsyncAction<ComponentActionType, ModuleNavigationState> {
    return (dispatch, getState) => {
        const unsubs: (() => void)[] = []
        let hasUnsubscribed = false
        activateDefaultTabs && dispatch({ type: "INIT", payload: { activateDefaultTabs } })
        getWorkTaskId((workTaskId) => {
            if (!workTaskId || hasUnsubscribed) {
                return
            }

            const state = getState()
            state.activateDefaultTabs = activateDefaultTabs
            const defaultTabsWithName = defaultTabs.filter((tab) => !!tab.name)
            const encodedWorktaskId = encodeUniqueId(workTaskId)
            if (state.workTaskId !== encodedWorktaskId) {
                dispatch({ type: "CHANGE_WORKTASK", payload: [encodedWorktaskId, defaultTabsWithName] })
                if (!state.loading) {
                    dispatch({ type: "STATE_LOADING" })
                    loadState(`${encodedWorktaskId}__module-navigation__`).then((navState) =>
                        dispatch({ type: "STATE_LOADED", payload: navState.value })
                    )
                }
            }

            const wtChannel = channel("WORKTASK", workTaskId)
            unsubs.push(wtChannel.subscribe("MODULE/CLOSED", (id) => dispatch({ type: "CLOSE_TAB_BY_ID", payload: id })))
            // shift it to the component, because we had the problem that the first tab is not loaded
            // unsubs.push(
            //     wtChannel.subscribe("MODULE/OPENED", (tab) => {
            //         dispatch({ type: "MODULE_OPENED", payload: tab })
            //     })
            // )
        })
        return function unsubscribeChannels() {
            unsubs.forEach((unsub) => unsub())
            unsubs.splice(0)
            hasUnsubscribed = true
        }
    }
}

export function closeTab(tab: ModuleTab): AsyncAction<ComponentActionType, ModuleNavigationState> {
    return (dispatch, getState) => {
        const { workTaskId } = getState()
        workTaskId && channel("WORKTASK", decodeUniqueId(workTaskId)).publish("MODULE/CLOSED", tab.url)

        dispatch({
            type: "CLOSE_TAB",
            payload: tab,
        })
    }
}

export function openTab(tab: ModuleTab): AsyncAction<ComponentActionType, ModuleNavigationState> {
    return (dispatch, getState) => {
        dispatch({ type: "MODULE_OPENED", payload: tab })
    }
}

export function rearangeTabs(): ComponentActionType {
    return {
        type: "REARANGE_TABS",
    }
}

export function splitTabs(pos: number): ComponentActionType {
    return {
        type: "SPLIT_TABS",
        payload: pos,
    }
}

export function rewriteTabUrl(previousUrl: string, newUrl: string) {
    return {
        type: "REWRITE_TAB_URL",
        payload: {
            oldUrl: previousUrl,
            newUrl,
        },
    }
}
