import { CalculationHistoryStatus } from "../data/models"

export type StepsNavigation = {
    indexNumber: number
    stepName: string
    available: boolean
    badge: string | undefined
    completed: boolean | undefined
    specialStep?: boolean
    icon: string
    calculationStatus?: CalculationHistoryStatus
}

export const navigationData: StepsNavigation[] = [
    { icon: "start", indexNumber: 0, stepName: "start", available: true, badge: undefined, completed: true },
    { icon: "maintenance-plan", indexNumber: 1, stepName: "maintenancePlan", available: true, badge: undefined, completed: false },
    {
        icon: "technician",
        indexNumber: 2,
        stepName: "technician",
        available: false,
        badge: "technician",
        completed: false,
        calculationStatus: CalculationHistoryStatus.ReadyToStart,
    },
    {
        icon: "engine-compartment",
        indexNumber: 3,
        stepName: "engineCompartment",
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        calculationStatus: CalculationHistoryStatus.InProgress,
    },
    { icon: "under_vehicle", indexNumber: 4, stepName: "underVehicle", available: false, badge: "technician", completed: false, specialStep: true },
    { icon: "wheel-and-car", indexNumber: 5, stepName: "tiresWheels", available: false, badge: undefined, completed: false },
    { icon: "interior", indexNumber: 6, stepName: "interior", available: false, badge: "technician", completed: false, specialStep: true },
    { icon: "exterior", indexNumber: 7, stepName: "exterior", available: false, badge: "technician", completed: false, specialStep: true },
    { icon: "general-vehicle", indexNumber: 8, stepName: "general", available: false, badge: "technician", completed: false, specialStep: true },
    { icon: "maintenance-overview", indexNumber: 9, stepName: "maintenanceReview", available: false, badge: "technician", completed: false },
    { icon: "maintenance-complete", indexNumber: 10, stepName: "maintenanceComplete", available: false, badge: "technician", completed: false },
    {
        icon: "inspector",
        indexNumber: 11,
        stepName: "tester",
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.ReadyForExam,
    },
    {
        icon: "inspection",
        indexNumber: 12,
        stepName: "inspection",
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.InExamination,
    },
    { icon: "test-drive", indexNumber: 13, stepName: "testDrive", available: false, badge: "inspector", completed: false },
    { icon: "list-of-defects", indexNumber: 14, stepName: "checkWork", available: false, badge: "inspector", completed: false },
    { icon: "maintenance-complete", indexNumber: 15, stepName: "testComplete", available: false, badge: "inspector", completed: false },
    {
        icon: "end",
        indexNumber: 16,
        stepName: "end",
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.Finished,
    },
]

export const allNavigationArray = [
    "start",
    "maintenancePlan",
    "technician",
    "engineCompartment",
    "underVehicle",
    "tiresWheels",
    "interior",
    "exterior",
    "general",
    "maintenanceReview",
    "maintenanceComplete",
    "tester",
    "inspection",
    "testDrive",
    "checkWork",
    "testComplete",
    "end",
]
