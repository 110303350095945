import { memo } from "react"
import { ArticleNumber, Box, Button, Checkbox, Divider, Grid, Icon, Image, Stack, styled } from "@tm/components"
import { useOeArticleItemState } from "../ContextProviderStates/OeArticleItemState"
import OeTransactions from "../Transactions/OeTransactions"
import { IconButton } from "../../IconButton"

const ArticleWrapper = styled(Box)({
    marginBottom: 8,
})

const ArticleContainer = styled(Box)({
    margin: "8px 0 0 0",
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
})

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

const GridItem = styled(Grid)({
    position: "relative",
    paddingRight: "12px",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
})

const ManufacturerBox = styled(Box)({
    "&&": {
        width: "60px",
        padding: "5px",
        border: "solid 1px #b4b4b4",
        borderRadius: "3px",
        display: "flex",
        marginBottom: "8px !important",
    },
})

export const OeArticleItem = memo(() => {
    const thumbnailUrl = useOeArticleItemState((x) => x.thumbnailUrl)
    const manufacturerThumbnail = useOeArticleItemState((x) => x.manufacturerThumbnail)
    const oeArticleNo = useOeArticleItemState((x) => x.oeArticleNo)
    const wholesalerArticleNumber = useOeArticleItemState((x) => x.wholesalerArticleNumber)

    return (
        <ArticleWrapper>
            <ArticleContainer>
                <Grid container direction="row" wrap="nowrap" alignItems="start">
                    <Grid item alignItems="center">
                        <Button sx={{ marginTop: "14px" }} variant="text" startIcon={<Icon name="up" />} />
                    </Grid>

                    <GridItem item flexBasis="90px" overflow="hidden">
                        <Stack spacing={1} alignItems="flex-start">
                            <Image type="article" src={thumbnailUrl} loading="lazy" height="60px" width="60px" />
                            {manufacturerThumbnail && (
                                <ManufacturerBox>
                                    <Image type="manufacturer" src={manufacturerThumbnail} loading="lazy" width="50px" height="20px" />
                                </ManufacturerBox>
                            )}
                        </Stack>
                    </GridItem>

                    <GridItem item xs p={1} overflow="hidden">
                        <Stack spacing={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Stack style={{ alignItems: "center" }} direction="row">
                                    <ArticleNumber articleNumber={wholesalerArticleNumber} articleNumberType="wholesaler" />
                                    <Divider style={{ marginLeft: "6px", marginRight: "6px" }} orientation="vertical" flexItem />
                                    <ArticleNumber articleNumber={oeArticleNo} articleNumberType="oe" />
                                </Stack>
                            </Stack>
                        </Stack>
                    </GridItem>

                    <Grid item flex="0 0 270px" justifyContent="end" paddingX={1.5} paddingY={1}>
                        <Stack spacing={2}>
                            <Stack direction="row" justifyContent="end" spacing={1}>
                                <IconButton disabled>
                                    <Icon name="ellipsis" />
                                </IconButton>
                                <Checkbox size="small" sx={{ padding: 0 }} disabled />
                            </Stack>
                            <OeTransactions />
                        </Stack>
                    </Grid>
                </Grid>
            </ArticleContainer>
        </ArticleWrapper>
    )
})
