import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { equals, validateField, ModelState } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { PostalAddress, ContactPerson, ContactInfo, UpdateRepairShopRequest, Location as LocationType } from "@tm/context-distribution"
import { Button } from "@tm/controls"

import { Street } from "../../../../data/model"
import { getBundleParams } from "../../../../utils"
import { LocationFormComponent } from "./LocationForm"

type Props = {
    companyName?: string
    postalAddress?: PostalAddress
    contactPerson?: ContactPerson
    contactInfo?: ContactInfo
    location?: LocationType
    taxNumber?: string
    addressSuggestions?: Array<Street>
    addressSuggestionsLoading: boolean
    modelState?: ModelState
    updateRepairShop(data: UpdateRepairShopRequest): void
    loadAddressSuggestions(street?: string, zip?: string, city?: string): void
    resetAddressSuggestions(): void
    resetModelState(): void
}

export const Location = memo<Props>(
    ({
        postalAddress,
        companyName,
        contactPerson,
        contactInfo,
        location,
        taxNumber,
        addressSuggestionsLoading,
        addressSuggestions,
        resetModelState,
        updateRepairShop,
        modelState,
        loadAddressSuggestions,
        resetAddressSuggestions,
    }) => {
        const { translateText } = useLocalization()
        const [repairShop, setRepairShop] = useState<UpdateRepairShopRequest>({
            companyName,
            postalAddress: postalAddress || {},
            contactPerson: contactPerson || {},
            contactInfo: contactInfo || {},
            location: location || undefined,
            taxNumber: taxNumber || undefined,
        })
        const [selectedSuggestion, setSelectedSuggestion] = useState<Street>()

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, companyName }))
        }, [companyName])

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, postalAddress }))
        }, [postalAddress])

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, contactPerson }))
        }, [contactPerson])

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, contactInfo }))
        }, [contactInfo])

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, location }))
        }, [location])

        useEffect(() => {
            setRepairShop((prevState) => ({ ...prevState, taxNumber }))
        }, [taxNumber])

        function handleChange(model: UpdateRepairShopRequest) {
            setRepairShop(model)
            resetModelState()
        }

        const validate = useCallback(
            (repairShop: UpdateRepairShopRequest): ModelState => {
                return {
                    companyName: validateField(repairShop.companyName).min(2, translateText(289)).max(100, translateText(13232)).messages,
                    "contactInfo.webPage": validateField(repairShop.contactInfo && repairShop.contactInfo.webPage).max(1000, translateText(13232))
                        .messages,
                    "postalAddress.street": validateField(repairShop.postalAddress && repairShop.postalAddress.street)
                        .min(2, translateText(289))
                        .max(100, translateText(13232)).messages,
                    "postalAddress.city": validateField(repairShop.postalAddress && repairShop.postalAddress.city)
                        .min(2, translateText(289))
                        .max(100, translateText(13232)).messages,
                    "postalAddress.zip": validateField(repairShop.postalAddress && repairShop.postalAddress.zip)
                        .min(2, translateText(289))
                        .max(20, translateText(13232)).messages,
                    "postalAddress.country": validateField(repairShop.postalAddress && repairShop.postalAddress.country)
                        .min(2, translateText(289))
                        .max(100, translateText(13232)).messages,
                    "contactPerson.firstName": validateField(repairShop.contactPerson && repairShop.contactPerson.firstName).max(
                        100,
                        translateText(13232)
                    ).messages,
                    "contactPerson.lastName": validateField(repairShop.contactPerson && repairShop.contactPerson.lastName)
                        .required(translateText(37))
                        .max(100, translateText(13232)).messages,
                    "contactInfo.email": validateField(repairShop.contactInfo && repairShop.contactInfo.email).max(1000, translateText(13232))
                        .messages,
                    "contactInfo.phone": validateField(repairShop.contactInfo && repairShop.contactInfo.phone).max(50, translateText(13232)).messages,
                    "contactInfo.fax": validateField(repairShop.contactInfo && repairShop.contactInfo.fax).max(50, translateText(13232)).messages,
                    taxNumber: validateField(repairShop.taxNumber).max(50, translateText(13232)).messages,
                }
            },
            [translateText]
        )

        const realModelState = modelState || validate(repairShop)

        function handleAddressChange(model: UpdateRepairShopRequest) {
            if (!getBundleParams().disableAddressSuggestions && model.postalAddress) {
                loadAddressSuggestions(model.postalAddress.street, model.postalAddress.zip, model.postalAddress.city)
            }

            if (
                !model.postalAddress ||
                !model.postalAddress.street ||
                !model.postalAddress.zip ||
                !model.postalAddress.city ||
                !(
                    (selectedSuggestion &&
                        model.postalAddress.street.indexOf(selectedSuggestion.name) === 0 &&
                        model.postalAddress.zip === selectedSuggestion.postcode &&
                        model.postalAddress.city === selectedSuggestion.region) ||
                    (postalAddress?.street &&
                        model.postalAddress.street.indexOf(postalAddress.street) === 0 &&
                        model.postalAddress.zip === postalAddress.zip &&
                        model.postalAddress.city === postalAddress.city)
                )
            ) {
                setSelectedSuggestion(undefined)
                model.location = undefined
            }

            setRepairShop(model)
            resetModelState()
        }

        function handleSave() {
            resetModelState()
            updateRepairShop(repairShop)
        }

        function handleReset() {
            setRepairShop({
                companyName,
                postalAddress: postalAddress || {},
                contactPerson: contactPerson || {},
                contactInfo: contactInfo || {},
                location: location || undefined,
                taxNumber: taxNumber || undefined,
            })
            resetModelState()
        }

        function handleSelectSuggestion(street: Street) {
            resetAddressSuggestions()
            setSelectedSuggestion(street)
            setRepairShop((prevState) => {
                return {
                    ...prevState,
                    postalAddress: {
                        ...prevState.postalAddress,
                        street: street.name,
                        zip: street.postcode,
                        city: street.region,
                    },
                    location: {
                        latitude: street.latitude,
                        longitude: street.longitude,
                    },
                }
            })

            resetModelState()
        }

        const dirty = useMemo((): boolean => {
            return (
                repairShop.companyName !== companyName ||
                !equals(repairShop.postalAddress, postalAddress || {}) ||
                !equals(repairShop.contactPerson, contactPerson || {}) ||
                !equals(repairShop.contactInfo, contactInfo || {}) ||
                !equals(repairShop.location, location || undefined) ||
                repairShop.taxNumber !== taxNumber
            )
        }, [
            companyName,
            contactInfo,
            contactPerson,
            location,
            postalAddress,
            taxNumber,
            repairShop.companyName,
            repairShop.contactInfo,
            repairShop.contactPerson,
            repairShop.location,
            repairShop.postalAddress,
            repairShop.taxNumber,
        ])

        const allEntriesValid = useMemo((): boolean => {
            let valid = true

            Object.keys(realModelState).forEach((key) => {
                const value = realModelState[key]
                if (value && value.length) {
                    valid = false
                }
            })

            return valid
        }, [realModelState])

        return (
            <>
                <div className="user-settings__location__actions">
                    <Button onClick={handleSave} disabled={!dirty || !allEntriesValid} skin="success">
                        {translateText(9)}
                    </Button>
                    <Button onClick={handleReset} disabled={!dirty} skin="danger">
                        {translateText(48)}
                    </Button>
                </div>
                <LocationFormComponent
                    repairShop={repairShop}
                    modelState={realModelState}
                    addressSuggestions={addressSuggestions}
                    addressSuggestionsLoading={addressSuggestionsLoading}
                    resetAddressSuggestions={resetAddressSuggestions}
                    handleChange={handleChange}
                    handleAddressChange={handleAddressChange}
                    handleSelectSuggestion={handleSelectSuggestion}
                />
            </>
        )
    }
)
