import { Button, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CostEstimationItem } from "../../../../data/model"

type Props = {
    cisVoucherUrl?: string
    costEstimationItem?: CostEstimationItem
    onOpenOrderNumber(): void
}

export default function PartOrderInfoComponent({ cisVoucherUrl, costEstimationItem, onOpenOrderNumber }: Props) {
    const { translateText, date } = useLocalization()

    if (!costEstimationItem?.isOrdered || !costEstimationItem?.orderInformation) {
        return <></>
    }

    const orderDate = new Date(costEstimationItem.orderInformation.orderDate)

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
                <Icon name="check-filled" color="success" height="1.5em" width="1.5em" />
                <Typography variant="body2">
                    {translateText(698)} {date(orderDate, "d")} {translateText(699)} {orderDate.toLocaleTimeString()}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="label">{translateText(700)} </Typography>
                <Button onClick={onOpenOrderNumber} target="_blank" startIcon={<Icon name="partslink24" />} disabled={!cisVoucherUrl} size="small">
                    {costEstimationItem.orderInformation.orderNumber}
                </Button>
            </Stack>
        </Stack>
    )
}
