import { ArticleNumber, Box, Divider, Stack } from "@tm/components"
import { useCallback } from "react"
import { useUser } from "@tm/context-distribution"
import { ArticleNumberCopyButton } from "./ArticleNumberCopyButton"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

export function ArticleNumbers() {
    const { onClickCopyArticleNumber, traderArticleNo, hideDealerPartNumber, copyButtonSupplier, supplierArticleNo, copyButtonTrader, oeArticleNo } =
        useDefaultArticleItemState((x) => x.displayArticle.numbers)

    const handleClickDetailsIntern = useDefaultArticleItemState((x) => x.handleClickDetails)

    const positionChangeEArtNrHArtNr = useUser()?.userContext?.parameter.positionChangeEArtNrHArtNr

    const handleClickDetails = useCallback(() => {
        handleClickDetailsIntern()
    }, [handleClickDetailsIntern])

    const handleCopyToClipboard = useCallback(() => {
        if (traderArticleNo) {
            onClickCopyArticleNumber?.(traderArticleNo)
        }
    }, [onClickCopyArticleNumber, traderArticleNo])

    return (
        <Stack
            direction={positionChangeEArtNrHArtNr ? "row-reverse" : "row"}
            spacing={1}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
        >
            {/* TODO: calculate numbers minwidth */}
            {oeArticleNo && <ArticleNumber articleNumber={oeArticleNo} onClick={handleClickDetails} articleNumberType="oe" />}

            {!oeArticleNo && traderArticleNo && !hideDealerPartNumber && (
                <Box minWidth="130px">
                    <Stack direction="row" spacing={1}>
                        <ArticleNumber
                            size="medium"
                            articleNumber={traderArticleNo}
                            omitDefaultStyles={positionChangeEArtNrHArtNr}
                            onClick={handleClickDetails}
                            articleNumberType="wholesaler"
                        />
                        {copyButtonTrader && <ArticleNumberCopyButton copyToClipboard={handleCopyToClipboard} />}
                    </Stack>
                </Box>
            )}

            {supplierArticleNo && (
                <Stack direction="row" spacing={1}>
                    <ArticleNumber
                        articleNumber={supplierArticleNo}
                        onClick={handleClickDetails}
                        articleNumberType="supplier"
                        omitDefaultStyles={positionChangeEArtNrHArtNr}
                    />
                    {copyButtonSupplier && <ArticleNumberCopyButton copyToClipboard={handleCopyToClipboard} />}
                </Stack>
            )}
        </Stack>
    )
}
