import { renderRoute, createQueryString, mapVoucherTypeFromUrl, mapVoucherTypeForUrl } from "@tm/utils"
import { VoucherType } from "@tm/models"
import { Box, useMediaQuery, useTheme } from "@tm/components"
import { useHistory, useParams } from "react-router"
import CostEstimationDetails from "./components/CostEstimations"
import OrderDetails from "./components/Orders"
import ReturnDetails from "./components/Returns"
import { getBundleParams } from "../../../../utils"
import { VoucherRouteParams } from "../../../../business"
import { useDefaultVoucherType } from "../../../../hooks/useDefaultVoucherType"

type Props = {
    hideVehicleLogos?: boolean
}

export default function VoucherDetails({ hideVehicleLogos }: Props) {
    const history = useHistory()
    const matchParams = useParams<VoucherRouteParams>()
    const defaultVoucherType = useDefaultVoucherType()
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const voucherType = mapVoucherTypeFromUrl(matchParams.type)
    const voucherId = matchParams.id && decodeURIComponent(matchParams.id)

    const { vouchersDetailsRoute, articleSearchUrl } = getBundleParams()
    function openArticleDirectSearchUrl(query: string) {
        if (articleSearchUrl) {
            const url = renderRoute(articleSearchUrl, { ...matchParams }) + createQueryString({ query })
            history.push(url)
        }
    }

    function openVoucher(orderId: string, type: VoucherType) {
        const params = {
            workTaskId: matchParams.workTaskId,
            type: mapVoucherTypeForUrl(type),
            id: orderId,
        }

        const url = renderRoute(vouchersDetailsRoute, params)
        history.push(url)
    }

    function renderVoucherDetails() {
        if (voucherType === VoucherType.CostEstimation) {
            return <CostEstimationDetails voucherId={voucherId} isBigScreen={isBigScreen} />
        }
        if (voucherType === VoucherType.Order) {
            return (
                <OrderDetails
                    voucherId={voucherId}
                    hideVehicleLogos={hideVehicleLogos}
                    theme={theme}
                    isBigScreen={isBigScreen}
                    openArticleDirectSearchUrl={openArticleDirectSearchUrl}
                    onShowReturn={(returnId) => {
                        openVoucher(returnId, VoucherType.Return)
                    }}
                />
            )
        }
        if (voucherType === VoucherType.Return) {
            return (
                <ReturnDetails
                    voucherId={voucherId}
                    onShowOrder={(orderId) => {
                        openVoucher(orderId, VoucherType.Order)
                    }}
                />
            )
        }
    }

    if (!defaultVoucherType) {
        return null
    }

    return (
        <Box flex={1} p={2} overflow="auto">
            {renderVoucherDetails()}
        </Box>
    )
}
