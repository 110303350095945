import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Container } from "@tm/nexus"
import { Omit, renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { UserModuleType } from "@tm/models"
import TriangledLabel, { TriProp } from "./triangled-label"
import TextLink, { TextLinkProps } from "./text-link"

type Props = {
    handleClose: () => void
}

export default function PopoverMenu({ handleClose }: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    const { translateText } = useLocalization()
    const userContext = useUser()?.userContext

    const getRouteWithWorkTask = (pathname: string) => {
        return renderRoute(`/:workTaskId/${pathname}`, { workTaskId: encodeUniqueId(workTaskId || uniqueId()) })
    }

    const handleLogout = () => {
        Container.getInstance("Portal").callAction("logout")
    }

    const captionProps: TextLinkProps = {
        size: "l",
        className: "popover__menu-entry popover__menu-entry--caption",
        closeMenu: handleClose,
    }

    const triangleProps: Omit<TriProp, "name" | "to"> = {
        className: "popover__menu-entry",
        closeMenu: handleClose,
    }

    const userSettings = "/usersettings"

    return (
        <div className="tk-wm popover__menu">
            <TextLink to={userSettings} {...captionProps}>
                {translateText(141)}
            </TextLink>
            <TriangledLabel to={`${userSettings}/language`} {...triangleProps} name={translateText(139)} />
            <TriangledLabel to={`${userSettings}/location`} {...triangleProps} name={translateText(28)} />
            <TriangledLabel to={`${userSettings}/prices`} {...triangleProps} name={translateText(140)} />
            <TriangledLabel to={`${userSettings}/hourly-rates`} {...triangleProps} name={translateText(911)} />
            {userContext?.modules?.some((x) => x.type === UserModuleType.DemoActivation) && (
                <TriangledLabel to={`${userSettings}/module`} {...triangleProps} name={translateText(1832)} />
            )}
            <TextLink to={getRouteWithWorkTask("cis/summary")} {...captionProps}>
                {translateText(1201)}
            </TextLink>
            <TriangledLabel to={getRouteWithWorkTask("cis/summary")} {...triangleProps} name={translateText(569)} />
            <TriangledLabel to={getRouteWithWorkTask("cis/vouchers/2")} {...triangleProps} name={translateText(1501)} />
            <TriangledLabel to={getRouteWithWorkTask("cis/tours")} {...triangleProps} name={translateText(1502)} />
            <TextLink to="/external01?url=https%3A%2F%2Fwmschulungen-integrated.trainingsfinder.de" {...captionProps}>
                {translateText(1202)}
            </TextLink>
            {userContext?.externalModules
                ?.filter((e) => e.parameter?.some((p) => p.key === "DisplayBehavior" && p.value === "526"))
                .map((e) => (
                    <TextLink key={e.id} to={`/external02/${e.id}`} {...captionProps}>
                        {e.catalogDescription || e.description}
                    </TextLink>
                ))}
            <TextLink onClick={handleLogout} {...captionProps}>
                {translateText(367)}
            </TextLink>
        </div>
    )
}
