import { useCallback, useEffect } from "react"
import { withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { encodeUniqueId, ModulePropsMultiWidget, renderRoute, RouteComponentProps, uniqueId, useMultiWidgetState, withRouter } from "@tm/utils"

import { TreeNode } from "@tm/models"
import { Box, SearchtreeTip, SearchtreeTipV2, Typography } from "@tm/components"
import SearchField from "../_shared/search-field"
import Tree from "../_shared/tree"
import { getBundleParams } from "../../utils"
import { Repositories } from "../../data"
import { SearchTree } from "../widget/business"
import { Actions, ComponentState, IActions } from "./business"
import SearchTreeWidget from "../widget/components/SearchTreeWidget"

type Props = RouteComponentProps<{ workTaskId?: string }> &
    WithWorkTaskProps & {
        state: ComponentState
        actions: IActions
        className?: string
        showComponentKey?: string
        vehicleSearchRoute: string
        tree: SearchTree
        moduleProps?: ModulePropsMultiWidget
    }

function VehicleSearchWidgetComponent(props: Props) {
    const { showComponentKey, state, vehicleSearchRoute, history, match, actions } = props
    const [selectedMultiWidgetTab] = useMultiWidgetState(props.moduleProps)
    const { translateText } = useLocalization()
    const { tree } = state
    const { initialize, loadSearchTreeNodes, applyProductGroups, changeBreadcrumbs } = actions
    const vehicle = props.workTask?.vehicle

    useEffect(() => {
        initialize(props.tree)
    }, [])

    useEffect(() => {
        if (tree.treeId && vehicle && showComponentKey && selectedMultiWidgetTab === showComponentKey) {
            initialize(props.tree)
            loadSearchTreeNodes(vehicle)
        }
    }, [vehicle?.tecDocTypeId, tree.treeId, selectedMultiWidgetTab])

    const handleChangeBreadcrumbs = useCallback(
        (breadcrumbs: Array<TreeNode>, thumbnailClicked?: boolean) => {
            changeBreadcrumbs(vehicle, breadcrumbs, thumbnailClicked)
        },
        [vehicle, changeBreadcrumbs]
    )

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    function handleSearch(query: string) {
        const url = renderRoute(vehicleSearchRoute, {
            ...match.params,
            workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()),
        })
        history.push(`${url}?query=${query}`)
    }

    const { showNewTooltip } = Morpheus.getParams("parts")

    const className = `tk-parts vehicle-search-widget ${props.className || ""}`

    return (
        <Widget id="parts__vehicle-search-widget" className={className} iconName="catalog" title={translateText(993)} size="6x3" active>
            <Typography className="label">{translateText(1094)}</Typography>

            <Box className="upper">
                <SearchField
                    placeholder={translateText(998)}
                    minLengthForSearch={getBundleParams().minimumSearchLength.vehicleSearch}
                    getSuggestions={vehicle ? (query) => Repositories.getProductGroupSuggestions(query, vehicle.tecDocTypeId) : undefined}
                    onSearch={handleSearch}
                />

                <Box sx={{ position: "absolute", right: 20, display: "flex" }}>{showNewTooltip ? <SearchtreeTipV2 /> : <SearchtreeTip />}</Box>
            </Box>

            <>
                {getBundleParams().searchtreeWithBreadcrumbs ? (
                    <SearchTreeWidget searchTreeType="vehicleParts" />
                ) : (
                    <>
                        {tree && (
                            <Tree
                                mode={tree.mode}
                                treeId={tree.treeId}
                                loading={tree.loading}
                                groups={tree.groups}
                                breadcrumbs={tree.breadcrumbs}
                                selectedNode={tree.selectedNode}
                                onChangeBreadcrumbs={handleChangeBreadcrumbs}
                                onApplyProductGroups={applyProductGroups}
                                showSecondLevel={tree.showSecondLevel}
                                treeContext="vehicleParts"
                            />
                        )}
                    </>
                )}
            </>
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(withWorkTask(VehicleSearchWidgetComponent)))
