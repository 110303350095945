import { FC, useEffect } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Headline, Icon, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import NextStep from "../_shared/nextStep"
import { VehicleAndCustomer } from "../_shared"
import { getComponentStyles } from "../_shared/styles"
import { stepNavigationActions } from "../../data"

const Tester: FC = () => {
    const { translateText } = useLocalization()
    const classNames = getComponentStyles()
    const currentStepName = "tester"

    useEffect(() => {
        stepNavigationActions.completeStep(currentStepName)
        stepNavigationActions.updateNextStep(currentStepName)
    }, [])

    return (
        <Scrollbar className={classNames.flexColumn}>
            <div className={classes(style.wrapper, classNames.wrapper)}>
                <VehicleAndCustomer showServiceText headerText={translateText(12537)} />
                <div className={classes(classNames.flexColumn, classNames.flexCenter, classNames.flexOne)}>
                    <Icon className={classNames.bigIcon} name="inspector" />
                    <div className={classNames.flexOne}>
                        <Headline className={classes(classNames.textCenter, classNames.marginBottomL)} size="l">
                            {" "}
                            {translateText(12907)}
                        </Headline>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12908)}</div>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12909)}</div>
                    </div>
                </div>
            </div>
            <NextStep currentStepName={currentStepName} icon="arrows_down" buttonTextId={3113} active />
        </Scrollbar>
    )
}

export default Tester

const style = useStyle({
    wrapper: {
        padding: "0 1em",
    },
})(Tester)
