import { em } from "csx"
import { Button } from "@tm/controls"
import { styled } from "@tm/components"

type Props = {
    onClick(): void
    isInModal?: boolean
    disabled?: boolean
    disableMargin?: boolean
}

function ReplaceButton({ onClick, isInModal, disabled, disableMargin }: Props) {
    return <StyledButton className="price" icon="replace" skin="highlight" onClick={onClick} disabled={disabled} isInModal disableMargin />
}

export default ReplaceButton

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "isInModal" && prop !== "disableMargin",
})<Props>(({ isInModal, disableMargin }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: (isInModal && em(6.25)) || em(6),
    marginLeft: (disableMargin && "initial") || "auto",
    marginRight: (isInModal && em(0.5)) || "unset",
}))
