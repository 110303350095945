import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { WarningPrompt } from "@tm/controls"
import { Box, Button, TextField, Icon } from "@tm/components"

type Props = {
    note: string
    disabled: boolean
    onUpdateNote(note: string): void
    onModifyNote(note: string): void // Update only Redux state
}

export default function NoteComponent(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<WarningPrompt>(null)
    const { note, disabled } = props

    function handleCostEstimationNoteChange(note: string) {
        props.onModifyNote(note)
    }

    function handleCostEstimationNoteChangeConfirm(note: string) {
        props.onUpdateNote(note)
    }

    function handleClearNote() {
        dialogRef.current?.show()
    }

    function handleWarningConfirm() {
        props.onUpdateNote("")
        props.onModifyNote("")
    }

    return (
        <Box display="flex">
            <TextField
                disabled={disabled}
                inputProps={{ maxLength: 2000 }}
                placeholder={translateText(1722)}
                value={note}
                multiline
                minRows={5}
                onChange={(e) => {
                    handleCostEstimationNoteChange(e.target.value)
                }}
                onBlur={(e) => {
                    handleCostEstimationNoteChangeConfirm(e.target.value)
                }}
                sx={{
                    width: "100%",
                }}
            />
            <Box display="flex" justifyContent="center" pl="8px" flexDirection="column">
                <Button size="large" onClick={handleClearNote} disabled={!note}>
                    <Icon name="close" height="15px" width="15px" />
                </Button>
            </Box>
            <WarningPrompt
                text={translateText(833)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                onConfirm={handleWarningConfirm}
            />
        </Box>
    )
}
