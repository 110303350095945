import { Icon, IconButton, Tooltip } from "@tm/components"
import { ArticleAction } from "../../../models"

type Props = {
    action: ArticleAction
}

export function IconAction({ action: { tooltip, text, handler, icon, isHighlight } }: Props) {
    return (
        <Tooltip title={tooltip ?? text}>
            <IconButton onClick={handler}>
                <Icon name={icon} color={isHighlight ? "primary" : undefined} />
            </IconButton>
        </Tooltip>
    )
}
