import { useLocalization } from "@tm/localization"
import { useDefaultErpSystem } from "@tm/utils"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { mergeTeccomPrices, mergeWarehouses } from "../../../../../../erp/src/micros/_helpers/useErpInfo"
import { useTeccom } from "../../../../../../erp/src/micros/_helpers/useTeccom"
import { ErpInfosState } from "../../states"
import { ArticleErpInfo } from "../useErpInfos"

export function useArticleExtendedDeliveryInfo(articleErpInfo?: ArticleErpInfo) {
    const { translateText } = useLocalization()

    const setErpInfo = useSetRecoilState(ErpInfosState)

    const { erpSystemConfig } = useDefaultErpSystem()
    const erpInfo = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined
    const { teccom, loadTeccom, teccomLoading } = useTeccom(erpInfo?.isTeccomRequestAvailable, articleErpInfo?.request, erpSystemConfig?.id)

    useEffect(() => {
        if (!teccom) {
            return
        }

        setErpInfo((prev) => {
            return prev.map<ArticleErpInfo>((item) => {
                // If the item is not the one we are looking for, we return it as is
                if (item.request.itemId !== teccom.itemId) {
                    return item
                }

                // If the item has no ERP data loaded (yet), we return it as is
                const articleErpInfoResponse = item.state === "success" ? item.response : undefined
                if (!articleErpInfoResponse) {
                    return item
                }

                // Finally we merge the ERP data with the teccom data
                return {
                    ...item,
                    response: {
                        ...articleErpInfoResponse,
                        availability: teccom.specialProcurementErpInformation?.availability ?? articleErpInfoResponse.availability,
                        orderOptions: teccom.specialProcurementErpInformation?.orderOptions ?? articleErpInfoResponse.orderOptions,
                        prices: mergeTeccomPrices(articleErpInfoResponse.prices, teccom.prices),
                        warehouses: mergeWarehouses(translateText, articleErpInfoResponse.warehouses, teccom),
                    },
                }
            })
        })
    }, [teccom, setErpInfo, translateText])

    return { teccom, loadTeccom, teccomLoading }
}
