import { Button, Popover, Stack } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getUIA } from "@tm/utils"
import { useCallback, useState, MouseEvent, PropsWithChildren } from "react"

type CostEstimationOptionsProps = PropsWithChildren<{
    disabled: boolean
}>

export function CostEstimationOptions({ children, disabled }: CostEstimationOptionsProps) {
    const { translateText } = useLocalization()

    const [viewOptionsAnchorElement, setViewOptionsAnchorElement] = useState<HTMLElement>()

    const handleOpenViewOptions = useCallback((event: MouseEvent<HTMLElement>) => setViewOptionsAnchorElement(event.currentTarget), [])
    const handleCloseViewOptions = useCallback(() => setViewOptionsAnchorElement(undefined), [])

    return (
        <Toolbar title={`${translateText(222)} / ${translateText(49)}`}>
            <Button onClick={handleOpenViewOptions} disabled={disabled} {...getUIA("CostestimationNavbarDisplayoptions")}>
                {translateText(844)}
            </Button>
            <Popover
                open={!!viewOptionsAnchorElement}
                anchorEl={viewOptionsAnchorElement}
                onClose={handleCloseViewOptions}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                {...getUIA("CostestimationNavbarDisplayoptionsContainer")}
            >
                <Stack py={1} px={2}>
                    {children}
                </Stack>
            </Popover>
        </Toolbar>
    )
}
