import { StateCreator } from "zustand"
import { DrivemotiveStore } from ".."
import { setShowLoginDialog, setDrivemotiveToken, drivemotiveLogin, setBookings, changeStep } from "./mainActions"
import { MainSlice, MainState } from "./model"
import { NavigationSteps } from "../../models"

const initialState: MainState = {
    main: {
        drivemotiveToken: "",
        showLoginDialog: false,
        bookings: [],
        navigationStep: NavigationSteps.Summary,
    },
}

export const createMainSlice: StateCreator<DrivemotiveStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
    mainActions: {
        setDrivemotiveToken: (token) => set((state) => setDrivemotiveToken(state, token)),
        setShowLoginDialog: (showLoginDialog) => set((state) => setShowLoginDialog(state, showLoginDialog)),
        drivemotiveLogin: (loginResponse) => set((state) => drivemotiveLogin(state, loginResponse)),
        setBookings: (bookingsResponse) => set((state) => setBookings(state, bookingsResponse)),
        changeStep: (step) => set((state) => changeStep(state, step)),
    },
    resetStore: () => set(reset, false, "Reset Main Slice"),
})

function reset(): Partial<MainSlice> {
    return {
        ...initialState,
    }
}
