import { FastServiceSelection, TyreSpecification, Work } from "../../models"

export type InitialData = {
    eTypArt: number
    dateFormat: string
    monthYearDateFormat?: string
    numberLocal?: string
    kTypNr: number
    vin?: string
    nextVehicleId: string
    mileage?: number
    isLonglife?: boolean
    engineCode?: string
    labourRate?: number
    cylinders?: number
    registrationDate?: Date
    engineCodes?: string[]
    calculateIncludedAdditionalWorks: boolean
    baseCalculationCalcId?: string
}

export type GetMaintenancePlanRequest = {
    kTypNr: number
    vehicleId: string
    initialData: InitialData
}

export type FastServiceResponse = {
    calcId: string
    eTag: string
    cockpitContext: CockpitContext
}

export type CockpitContext = {
    type: number
    selections: FastServiceSelection[]
    additionalWorkSelections: FastServiceSelection[]
    followupWorkSelections: FastServiceSelection[]
    results: FastServiceWorkTimes[]
    dateFormat: string
    numberLocal: string
    wkz: string
    monthYearDateFormat: string
    works: Work[]
    tireSpecifications: TyreSpecification[]
}

export type FastServiceWorkTimes = {
    label: string
    provider: number
    repairTimeId: string
    value: number
    workId: string
    workType?: WorkTypes | number
}

export type SelectedData = {
    selectedIds: string[]
    sectionId: string
}

export enum WorkTypes {
    none = 0,
    service = 1,
    additionalWork = 2,
    followUpWork = 3,
}
