import { useLocalization } from "@tm/localization"
import {
    ArticleReferenceType,
    AttributeFilterModel,
    CategoryType,
    channel,
    ConstructionYear,
    FittingPosition,
    RegistrationNoType,
    SearchFilters,
    UserModuleType,
    Vehicle,
    VehicleType,
} from "@tm/models"
import { useCallback } from "react"

import { useUser } from "@tm/context-distribution"
import { Models } from "./data"
import { getBundleParams } from "./utils"

export function getCategoryTypeFromUrl(url: string): CategoryType {
    // TODO: this has to be rethinked
    // TODO: this should not be in parts and dublicated 3 times

    if (url.includes("/parts/vehicles")) {
        return "vehicleParts"
    }
    if (url.includes("/parts/universal")) {
        return "universalParts"
    }
    if (url.includes("parts-offers")) {
        return "offerParts"
    }

    return "directSearch"
}

export function getVehicleTreeIdFromVehicleType(vehicleType: VehicleType) {
    if (vehicleType === VehicleType.CommercialVehicle) {
        return getBundleParams().commercialTreeId
    }
    return getBundleParams().vehicleTreeId
}

export function getTreeIdFromCategoryType(treeType: CategoryType | undefined, vehicleType: VehicleType | undefined): number | undefined {
    switch (treeType) {
        case "vehicleParts":
            return vehicleType ? getVehicleTreeIdFromVehicleType(vehicleType) : undefined
        case "universalParts":
            return getBundleParams().universalTreeId
        default:
            break
    }
}

export function publishModuleInfo(view: string, info: string) {
    const unsub = channel("GLOBAL").subscribe(
        "WORKTASK/ID_CHANGED",
        (data) => {
            if (data.id) {
                channel("WORKTASK", data.id).publish("MODULE/CHANGED", { id: "parts", name: "{{99}}", view, info })
                unsub()
            }
        },
        true
    )
}

function getUncutFilterGroupHeights(): { overallHeight: number; filterHeights: number[] } | undefined {
    const filterHeightsCombined = document.querySelector<HTMLElement>(".filters--clipped")
    const filterHeights: number[] = []

    if (!filterHeightsCombined) {
        return
    }

    const padding = 6

    for (let i = 0; i < filterHeightsCombined.children.length; i++) {
        const filterChildren = filterHeightsCombined.children[i] as HTMLElement // a filter group
        const header = filterChildren.querySelector<HTMLElement>(".collapsible__header") // the filter group´s header
        const filterContent = filterChildren.querySelectorAll<HTMLElement>(".collapsible__content > *").item(0) // the filter group´s content if there is no searchbar, otherwise: the searchbar
        const filterContent2 = filterChildren.querySelectorAll<HTMLElement>(".collapsible__content > *").item(1) // the filter group´s content if there is a searchbar otherwise: null
        filterHeights[i] = (header?.offsetHeight || 0) + (filterContent?.offsetHeight || 0) + (filterContent2?.offsetHeight || 0) + padding // the size of that filter (content and header combined) if it wasnt cut
    }

    return {
        overallHeight: filterHeightsCombined.clientHeight,
        filterHeights,
    }
}

function setFilterGroupCutHeight(filterHeights: number[], cutHeight: number, numberOfFiltersGettingCut: number, willBeCut: boolean[]): number {
    for (let i = 0; i < filterHeights.length; i++) {
        for (let k = 0; k < filterHeights.length; k++) {
            if (filterHeights[k] < cutHeight && willBeCut[k]) {
                numberOfFiltersGettingCut--
                cutHeight += (cutHeight - filterHeights[k]) / numberOfFiltersGettingCut
                willBeCut[k] = false
            }
        }
    }
    return cutHeight
}

function cutFilterGroupHeights(filterHeights: number[], cutHeight: number): number[] {
    for (let i = 0; i < filterHeights.length; i++) {
        if (filterHeights[i] > cutHeight) {
            filterHeights[i] = cutHeight
        }
    }
    return filterHeights
}

function assignFilterHeights(filterHeightsCombined: HTMLElement, filterHeights: number[]) {
    for (let i = 0; i < filterHeightsCombined.children.length; i++) {
        // assigns the filters their height in the same order their heights are read at getFilterSetHeights() (from top to bottom), this way every filter gets their own height
        ;(filterHeightsCombined.children[i] as HTMLElement).style.height = `${filterHeights[i]}px`
    }
}

export function adaptFilterGroupHeights() {
    window.setTimeout(() => {
        const filterHeightsCombinedAndFilterHeights = getUncutFilterGroupHeights()

        if (!filterHeightsCombinedAndFilterHeights) {
            return
        }

        const combinedFilterHeights = filterHeightsCombinedAndFilterHeights.overallHeight
        let { filterHeights } = filterHeightsCombinedAndFilterHeights
        let cutHeight = combinedFilterHeights / filterHeights.length
        const willBeCut: boolean[] = []

        for (let i = 0; i < filterHeights.length; i++) {
            willBeCut[i] = true // initially, it is assumed that every filter has more elements then it needs, to fill its space
        }

        const numberOfFiltersGettingCut = willBeCut.length
        cutHeight = setFilterGroupCutHeight(filterHeights, cutHeight, numberOfFiltersGettingCut, willBeCut)
        filterHeights = cutFilterGroupHeights(filterHeights, cutHeight)

        const filterHeightsCombined: HTMLElement | null = document.querySelector(".filters--clipped")

        if (!filterHeightsCombined) {
            return
        }

        assignFilterHeights(filterHeightsCombined, filterHeights)
    }, 0)
}

export function isSearchbarActive(index: number): boolean {
    const filter = document.querySelector<HTMLElement>(".filters")
    const filterChildren = []

    if (!filter?.children) {
        return false
    }

    for (let i = 0; i < filter.children.length; i++) {
        filterChildren[i] = filter.children[i] as HTMLElement
    }

    if (!filterChildren[index]) {
        return false
    }

    const content = filterChildren[index].querySelectorAll<HTMLElement>(".collapsible__content > *")

    if (content[0] && content[0].querySelectorAll<HTMLElement>(".filter__search-field > *")) {
        return true
    }

    if (content[1] && content[1].querySelectorAll<HTMLElement>(".filter__search-field > *")) {
        return true
    }

    return false
}

export function isClassicalSearchTree(treeId: number | undefined): boolean {
    const classicalSearchTrees = [9]
    return treeId != undefined && classicalSearchTrees.includes(treeId)
}

export function getInitialProductGroupFilters(
    productGroups: Array<Models.ProductGroupFilter>,
    extendedAssortment = false
): Array<Models.ProductGroupFilter> {
    let filters: Array<Models.ProductGroupFilter> = []

    if (extendedAssortment) {
        filters = productGroups
    }

    if (!filters.length) {
        filters = productGroups.filter((x) => x.isTopPriority)
    }
    if (!filters.length) {
        filters = productGroups.filter((x) => x.hasTopPrioritySuppliers)
    }
    if (!filters.length) {
        filters = productGroups
    }

    return filters
}

export function getInitialDataSupplierFilters(
    suppliers: Array<Models.DataSupplierFilter>,
    extendedAssortment = false
): Array<Models.DataSupplierFilter> {
    let filters: Array<Models.DataSupplierFilter> = []

    if (extendedAssortment) {
        filters = suppliers
    }

    if (!filters.length) {
        filters = suppliers.filter((x) => x.showOnTop)
    }
    if (!filters.length) {
        filters = suppliers.filter((x) => x.isTopPriority)
    }
    if (!filters.length) {
        filters = suppliers
    }

    return filters
}

export function getSearchFilterText(searchFilter: SearchFilters, translate: (id: number) => string) {
    switch (searchFilter) {
        case SearchFilters.EuropeanArticleNumber:
            return translate(387) // EAN
        case SearchFilters.SupplierArticleNo:
            return translate(87) // Artikelnummer
        case SearchFilters.ReplacementNo:
            return translate(393) // Ersatznummern
        case SearchFilters.UtilityNo:
            return translate(392) // Gebrauchsnummern
        case SearchFilters.TradeReference:
            return translate(1360) // Handelsreferenz
        case SearchFilters.Manufacturer:
            return translate(91) // Händlerartikelnummer
        case SearchFilters.OeReference:
            return translate(1359) // Vergleichsnummern
        default:
            break
    }
}

export function constructionYearToString(data?: ConstructionYear) {
    if (!data) {
        return ""
    }
    return `${data.month}/${data.year}`
}

export function constructionYearToDate(data: ConstructionYear): Date | undefined {
    const { year, month } = data
    if (isNaN(year) || isNaN(month)) {
        return undefined
    }

    return new Date(year, month - 1)
}

export function getImageTypeByVehicleType(vehicleType: VehicleType): "car" | "bike" | "manufacturer" | undefined {
    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return "car"
        case VehicleType.Motorcycle:
            return "bike"
        case VehicleType.CommercialVehicle:
            return "manufacturer"
        default:
            break
    }
}

export const ALL_AXLES: FittingPosition = FittingPosition.FrontAxle | FittingPosition.RearAxle

export function getModuleVehicleRecordMode(): { mode: "DatVinOnly" | "SelfLearning"; hideAxleSwitch: boolean } | undefined {
    const module = window.userContext.modules?.find((m) => m.type === UserModuleType.VehicleRecords)
    if (!module) {
        return
    }
    return {
        mode: module.parameters?.some((p) => p.key === "VehicleRecordMode" && p.value === "241002") ? "DatVinOnly" : "SelfLearning",
        hideAxleSwitch: !!module.parameters?.some((p) => p.key === "Version" && p.value === "2"),
    }
}

export function hasVehicleRecordDatVinOnly() {
    return getModuleVehicleRecordMode()?.mode === "DatVinOnly"
}

export function shouldCompareWithVehicleRecords(vehicle?: Vehicle) {
    return getBundleParams().vehicleRecordsEnabled && (!hasVehicleRecordDatVinOnly() || vehicle?.registrationTypeId === RegistrationNoType.DatVin)
}

export function useGetFittingSideText() {
    const { translateText } = useLocalization()

    return useCallback(
        (position: FittingPosition) => {
            switch (position) {
                case FittingPosition.FrontAxle:
                    return translateText(353)
                case FittingPosition.RearAxle:
                    return translateText(354)
                default:
                    return ""
            }
        },
        [translateText]
    )
}

export function showDefaultAttributeSelect(options: AttributeFilterModel[]) {
    if (getModuleVehicleRecordMode()?.hideAxleSwitch) {
        return false
    }
    return !!options.some((o) => o.fittingSide)
}

export function useUniSearchStatus() {
    const { userContext } = useUser()
    const { uniPartsIsExternalSystem } = getBundleParams()

    if (uniPartsIsExternalSystem) {
        return true
    }

    return userContext.modules?.some((module) => module.description === "UnipartsPremium")
}
