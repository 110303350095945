import { uniqueId } from "@tm/utils"
import { OrderVoucherList, PartItem, WorkItem } from "@tm/models"
import {
    ShowCostEstimationDetailsResponse,
    ExtendedVoucherCalculatedPart,
    ShowOrdersResponse,
    ShowOrderDetailsResponse,
    OrderVoucherItemsByWorkTask,
    ExtendedOrderVoucherItem,
} from "../model"

function mapExtendedVoucherCalculatedPart(data: any): ExtendedVoucherCalculatedPart | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        id: uniqueId(),
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket service
        rebate: data.rebate ? data.rebate * 100 : undefined,
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
        vatRate: {
            ...data.vatRate,
            vatRate: data.vatRate.vatRate * 100,
        },
    }
}

function mapExtendedVoucherCalculatedWork(data: any): WorkItem | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
        rebate: data.rebate ? data.rebate * 100 : undefined,
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
        vatRate: {
            ...data.vatRate,
            vatRate: data.vatRate.vatRate * 100,
        },
    }
}

export function mapShowCostEstimationDetailsResponse(data: any): ShowCostEstimationDetailsResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        calculatedParts: (data.calculatedParts || []).map(mapExtendedVoucherCalculatedPart),
        calculatedWorks: (data.calculatedWorks || []).map(mapExtendedVoucherCalculatedWork),
    }
}

export function mapPartItem(part: ExtendedVoucherCalculatedPart): PartItem {
    // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
    const vatRate = part.vatRate ? { ...part.vatRate, vatRate: part.vatRate.vatRate / 100 } : undefined

    return {
        ...part,
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
        rebate: part.rebate ? part.rebate / 100 : 0,
        vatRate,
    }
}

export function mapWorkItem(work: WorkItem) {
    // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
    const vatRate = work.vatRate ? { ...work.vatRate, vatRate: work.vatRate.vatRate / 100 } : undefined
    return {
        ...work,
        // TODO (NEXT-27019): Remove when the Voucher Service is refactored like the basket servic
        rebate: work.rebate ? work.rebate / 100 : 0,
        vatRate,
    }
}

/** ****************************************************************************************************************************************
 * The orderNumber shall not be mapped, sometimes a date time Stamp is stored as orderId https://jira.dvse.de/browse/NEXT-24102
 **************************************************************************************************************************************** */

function mapOrderVoucherList(data: any): OrderVoucherList | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
    }
}

export function mapShowOrdersResponse(data: any): ShowOrdersResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orders: (data.orders || []).map(mapOrderVoucherList),
    }
}

function mapExtendedOrderVoucherItem(data: any): ExtendedOrderVoucherItem | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        returnableUntil: data.returnableUntil ? new Date(data.returnableUntil) : undefined,
        returnInfo: data.returnInfo
            ? {
                  ...data.returnInfo,
                  returnDate: data.returnInfo?.returnDate ? new Date(data.returnInfo.returnDate) : undefined,
              }
            : undefined,
        vehicle: data.vehicle
            ? {
                  ...data.vehicle,
                  initialRegistration: data.vehicle?.initialRegistration ? new Date(data.vehicle.initialRegistration) : undefined,
              }
            : undefined,
    }
}

function mapOrderVoucherItemsByWorkTask(data: any): OrderVoucherItemsByWorkTask | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderVoucherItems: (data.orderVoucherItems || []).map(mapExtendedOrderVoucherItem),
    }
}

export function mapShowOrderDetailsResponse(data: any): ShowOrderDetailsResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
        orderItemsByWorkTask: (data.orderItemsByWorkTask || []).map(mapOrderVoucherItemsByWorkTask),
    }
}
