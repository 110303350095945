import { AsyncAction } from "@tm/morpheus"
import { encodeUniqueId } from "@tm/utils"
import { ComponentActionType } from "."
import { loadState } from "../../../data"
import { ModuleNavigationState } from "../../module-navigation/business"

export type IActions = typeof Actions

export const Actions = {
    loadOpenTabs,
    resetTabNavigation,
    removeTabsFromNavigation,
}

function loadOpenTabs(workTaskId: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "OPEN_TABS_LOADING" })

        loadState<ModuleNavigationState>(`${encodeUniqueId(workTaskId)}__module-navigation__`).then((state) => {
            dispatch({ type: "OPEN_TABS_LOADED", payload: state && state.value.tabs })
        })
    }
}

function resetTabNavigation(): ComponentActionType {
    return { type: "RESET_MODULE_NAVIGATION" }
}

function removeTabsFromNavigation(tabs: string[]): ComponentActionType {
    return { type: "REMOVE_TABS", payload: tabs }
}
