import { AddRepairTimeListRequest, FittingGroupRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { bundleChannel } from "../../../../business"

export function useAddRepairTimeList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: addRepairTimeList } = useMutation((request: AddRepairTimeListRequest) => Data.addRepairTimeList(request), {
        onSuccess: (response, request) => {
            if (response) {
                handleBasketUpdateWorkflow(request.workTaskId, response)
                bundleChannel().publish("ENABLE_COST_ESTIMATION", {})
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                const container = Container.getInstance(RegisteredModels.Basket_HasRepairTimes)
                const containerPG = Container.getInstance(RegisteredModels.Basket_HasRepairTimesForProductGroup)

                request.repairTimes
                    .filter((time) => time.providerWorkId)
                    .forEach((time) => {
                        container
                            .subscribe({
                                workTaskId: request.workTaskId,
                                repairTimesProvider: request.provider,
                                repairTimeProviderWorkId: time.providerWorkId,
                            })
                            .loadIfRequired()

                        time.productGroupIds?.forEach((productGroupId) => {
                            const fittingGroupRequest: FittingGroupRequest = {
                                fittingSide: time.fittingSide,
                                productGroupId,
                            }

                            containerPG
                                .subscribe({
                                    workTaskId: request.workTaskId,
                                    request: fittingGroupRequest,
                                })
                                .loadIfRequired()
                        })
                    })
            }
        },
    })

    const addRepairTimes = useCallback(
        (request: AddRepairTimeListRequest) => {
            return addRepairTimeList(request)
        },
        [addRepairTimeList]
    )

    return addRepairTimes
}
