import { Box, Typography } from "@tm/components"

type Props = {
    content: string
}

export function PriceAddition({ content }: Props) {
    return (
        <>
            <Typography variant="label" gridColumn="1 / span 2" lineHeight={1.2} textAlign="right">
                {content}
            </Typography>
            <Box /> {/* The typography will span two columns, so we need to render an empty box to fill the last column */}
        </>
    )
}
