import { GetArticlesRequest, getCurrentWorkTaskId, GetUniArticlesResponse } from "@tm/models"
import { ajax, getStoredAuthorization, TmaHelper } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetArticleListByMatchCodeRequest } from "./"
import { mapArticleListResponse } from "./mapper"
import { GetArticleListByMatchCodeResponse, GetArticlesListMappedResponse } from "./model"
export * from "./model"

export async function getArticleListByMatchCode(request: GetArticleListByMatchCodeRequest) {
    const url = `${getServiceUrl()}/GetArticleListByMatchCode`
    const authorization = getStoredAuthorization()
    const body = request

    const response = await ajax<GetArticleListByMatchCodeResponse>({ url, body, authorization, method: "POST" })

    if (!response) {
        throw new Error("Empty Response")
    }

    sendTmaInfo(url, request, response)

    if (!response.uniArticles?.length) {
        throw new Error("No results")
    }
    
    return mapArticleListResponse(response)
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.articleListServiceUrlByQuery
}

function sendTmaInfo(url: string, request: GetArticleListByMatchCodeRequest, response: GetArticleListByMatchCodeResponse) {
    setTimeout(() => {
        TmaHelper.UniParts.List.Results(request as GetArticlesRequest, response as GetUniArticlesResponse, getCurrentWorkTaskId() ?? "" , url, undefined)
    }, 200)
}

