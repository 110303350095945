import { RegisteredModels, FastCalculation } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useInvalidateWorkTaskBasketQueries } from "../workflow/useInvalidateWorkTaskBasketQueries"

export function useImportFastCalculation() {
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const { mutateAsync: importFastCalculation } = useMutation(
        (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => Data.importFastCalculation(importFastCalculationRequest),
        {
            onSuccess: (_, request) => {
                invalidateAllWorkTaskBasketRequests(request.workTaskId)
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const importVouchers = useCallback(
        (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => {
            return importFastCalculation(importFastCalculationRequest)
        },
        [importFastCalculation]
    )

    return importVouchers
}
