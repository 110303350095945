import { useLocalization } from "@tm/localization"
import { WorkItem } from "@tm/models"
import { Button, CellContentPosition, Icon, Table, TableCellData, TableColumnData } from "@tm/components"
import { getCategoryOfWorkDescriptionTextId, useVatRateText } from "@tm/utils"
import { useState } from "react"
import HourlyRate from "./HourlyRate"
import IncludedWorksTable from "./IncludedWorksTable"

type Props = {
    works?: WorkItem[]
    showRepairTimesInHours: boolean
}

export default function WorksTable({ works, showRepairTimesInHours }: Props) {
    const { translate, translateText, number, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText, number)
    const [expandedRows, setExpandedRow] = useState<string[]>([])

    function handleShowHideIncludes(workId: string) {
        if (expandedRows.includes(workId)) {
            setExpandedRow(expandedRows.filter((e) => e !== workId))
        } else {
            setExpandedRow([...expandedRows, workId])
        }
    }

    function getColumns() {
        const repairTimesTextId = showRepairTimesInHours ? 84 : 1550
        const columns: TableColumnData[] = [
            { header: "" }, // showIncludes
            { header: translate(58) }, // provicer work id
            { header: translate(25) }, //  description
            { header: translate(278) }, // Provider
            { header: translate(59) }, // Category
            { header: translate(63), alignContent: CellContentPosition.right }, // Hourly rate
            { header: translate(repairTimesTextId), alignContent: CellContentPosition.right }, // Net time
            { header: `${translate(repairTimesTextId)} ${translate(85)}`, alignContent: CellContentPosition.right }, // Gross time
            { header: translate(54), alignContent: CellContentPosition.right }, // Rebate
            { header: translate(706), alignContent: CellContentPosition.right }, // vatRate
            { header: translate(57), alignContent: CellContentPosition.right }, // workPriceCalculated
        ]

        return columns
    }

    function getModuleCellData(work: WorkItem): TableCellData[] {
        const {
            vatRate,
            includedWorks,
            providerWorkId,
            description,
            currencyCode,
            currencySymbol,
            repairTimeProviderName,
            categoryOfWorkShortCode,
            workPriceCalculated,
            rebate,
            time,
            timeCalculated,
        } = work
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Button
                        disabled={!includedWorks.length}
                        startIcon={<Icon name={expandedRows.includes(providerWorkId) ? "up" : "down"} />}
                        title={translateText(61)}
                        onClick={() => handleShowHideIncludes(providerWorkId)}
                        variant="text"
                    />
                ),
                id: "work_1",
            },
            { displayValue: providerWorkId, id: "work_2" },
            { displayValue: description, id: "work_3" },
            { displayValue: repairTimeProviderName, id: "work_4" },
            {
                displayValue: <div title={translateText(getCategoryOfWorkDescriptionTextId(categoryOfWorkShortCode))}>{categoryOfWorkShortCode}</div>,
                id: "work_5",
            },
            {
                displayValue: <HourlyRate workItem={work} />,
                id: "work_6",
            },
            { displayValue: time ? number(time) : "-", id: "work_7" },
            { displayValue: timeCalculated ? number(timeCalculated) : "-", id: "work_8" },
            { displayValue: !!rebate && `${number(rebate, 0)}%`, id: "work_9" },
            { displayValue: vatRate && vatRateTypeDisplayShortText(vatRate), id: "work_10" },
            { displayValue: currency(workPriceCalculated, currencySymbol || currencyCode), id: "work_11" },
        ]

        return cellData
    }

    const displayData = works?.map((work, index) => ({
        cells: getModuleCellData(work),
        id: `${index}`,
        active: false,
        extendedContent: expandedRows.includes(work.providerWorkId) && <IncludedWorksTable includedWorks={work.includedWorks} />,
    }))

    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" rowCap={4} />
}
