import * as React from "react"
import { Button, Popover } from "@tm/controls"
import { registerOutsideClick } from "@tm/utils"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"
import { getOverflowMenuStyles } from "../styles"
import { getRect, useResizeObserver } from "../../../helpers"

type OverflowMenuProps<TItem> = {
    mainItemStyles: NestedCSSProperties
    moreItemStyles: NestedCSSProperties
    items: Array<TItem>
    selected?: TItem
    renderOverflowAppendix?(overflowItems: Array<TItem>): React.ReactNode
    renderItem(item: TItem, className: string, onResize?: (entry: ResizeObserverEntry) => void, isSelected?: boolean): React.ReactNode
    getItemKey(item: TItem): string | number
    onArrange(split: number): void
}

export function OverflowMenu<TItem extends { id: string }>(props: OverflowMenuProps<TItem>) {
    const { items, renderOverflowAppendix } = props
    const [showMorePopover, setShowMorePopover] = React.useState(false)
    const [shouldCalculate, setShouldCalculate] = React.useState(false)
    const [itemWidths, setItemWidths] = React.useState<[TItem, number?][]>([])
    const [tempItemWidths, setTempItemWidths] = React.useState<[TItem, number?][] | undefined>()
    const [mainContainerWidth, setMainContainerWidth] = React.useState<number>(0)
    const [mainNavigationWidth, setMainNavigationWidth] = React.useState<number>(0)
    const [moreContainerWidth, setMoreContainerWidth] = React.useState<number>(0)
    const [splitPosition, setSplitPosition] = React.useState(-1)
    const [classNames, setClassNames] = React.useState<[string, string]>()

    const [itemTimer, setItemTimer] = React.useState<any>()
    const moreNavRef = React.createRef<HTMLDivElement>()
    const [moreRef, setMoreRef] = React.useState<HTMLDivElement>()

    const [containerBoundsRef, unsubContainerBoundsRef] = useResizeObserver<HTMLDivElement>(handleResizeContainer)
    const [navBoundsRef, unsubNavBoundsRef] = useResizeObserver<HTMLDivElement>(handleResizeNavigation)
    const [moreBoundsRef, unsubMoreBoundsRef] = useResizeObserver<HTMLDivElement>(handleResizeMoreContainer)

    React.useEffect(() => {
        if (items.length !== itemWidths.length) {
            setItemWidths(
                items.map((x) => {
                    const found = itemWidths.find((y) => y[0].id === x.id)
                    return [x, found ? found[1] : undefined]
                })
            )
        }
    }, [items.length])

    if (!classNames) {
        setClassNames([style(props.mainItemStyles), style(props.moreItemStyles)])
    }
    React.useEffect(
        () => () => {
            unsubContainerBoundsRef && unsubContainerBoundsRef()
            unsubNavBoundsRef && unsubNavBoundsRef()
            unsubMoreBoundsRef && unsubMoreBoundsRef()
        },
        []
    )

    React.useEffect(() => {
        if (!showMorePopover || !moreNavRef.current) {
            return
        }
        const unregister = registerOutsideClick(moreNavRef.current, handleClickOutside, true)
        return unregister
    }, [moreNavRef])

    React.useEffect(() => {
        setShowMorePopover(false)
    }, [items])

    React.useEffect(() => {
        if (itemWidths.length <= 1) {
            setSplitPosition(-1)
        } else if (!itemWidths.some((x) => x[1] === undefined) && mainContainerWidth && moreContainerWidth) {
            setShouldCalculate(true)
        }
    }, [itemWidths, mainContainerWidth])

    React.useEffect(() => {
        if (!shouldCalculate) {
            return
        }
        let width = 0
        let i: number
        let broke = false
        for (i = 0; i < itemWidths.length; i++) {
            const itemWidth = itemWidths[i][1]!
            if (!itemWidth) {
                break
            }
            width += itemWidth
            if (width > mainContainerWidth) {
                width -= itemWidth
                if (width + moreContainerWidth > mainContainerWidth!) {
                    i--
                    width -= itemWidth
                }
                setSplitPosition(i)
                props.onArrange(i)
                broke = true
                break
            } else if (i === itemWidths.length - 1) {
                setSplitPosition(-1)
                props.onArrange(-1)
                broke = true
                break
            }
        }
        if (!broke && itemWidths.length > i && !!itemWidths[i][1] && width + moreContainerWidth <= mainContainerWidth + itemWidths[i][1]!) {
            setSplitPosition(i + 1)
            props.onArrange(i + 1)
        }
        setShouldCalculate(false)
    }, [shouldCalculate])

    React.useEffect(() => {
        clearTimeout(itemTimer)
        setItemTimer(
            setTimeout(() => {
                if (tempItemWidths) {
                    setItemWidths(tempItemWidths)
                    setTempItemWidths(undefined)
                }
            }, 50)
        )
    }, [tempItemWidths])

    function handleResizeItem(item: TItem, entry: ResizeObserverEntry) {
        if (!entry.target || !entry.contentRect) {
            return
        }
        const rect = getRect(entry.target, "margin")
        const sameList = (tempItemWidths || []).map((x) => x[0].id).join("|") === itemWidths.map((x) => x[0].id).join("|")
        const list = sameList ? tempItemWidths || [] : [...(itemWidths || [])]
        const index = list.findIndex((x) => x[0]?.id === item.id)
        const itemWidth = list[index]
        if (itemWidth && rect.width > 0 && rect.width !== itemWidth[1]) {
            itemWidth[1] = rect.width
            setTempItemWidths(list)
        }
    }

    function handleResizeNavigation(entry: ResizeObserverEntry) {
        if (entry) {
            setMainNavigationWidth(entry.contentRect.width)
        }
    }

    function handleResizeContainer(entry: ResizeObserverEntry) {
        if (entry) {
            setMainContainerWidth(entry.contentRect.width)
        }
    }

    function handleResizeMoreContainer(entry: ResizeObserverEntry) {
        if (entry && entry.contentRect.width !== moreContainerWidth) {
            setMoreContainerWidth(entry.contentRect.width)
        }
    }

    function renderItem(item: TItem) {
        const isMoreItem = splitPosition !== -1 && items.findIndex((x) => x === item) >= splitPosition
        const className = isMoreItem ? classNames![1] : classNames![0]
        return props.renderItem(item, className, !isMoreItem ? handleResizeItem.bind(null, item) : undefined, props.selected === item)
    }

    function handleClickMore() {
        setShowMorePopover(!showMorePopover)
    }

    function handleClickOutside(event: Event) {
        if (event.composedPath && !event.composedPath().some((x) => x === moreRef)) {
            setShowMorePopover(false)
        }
    }

    function handleMoreRef(el: HTMLDivElement) {
        if (!el || moreRef === el) {
            return
        }
        moreBoundsRef(el)
        setMoreRef(el)
    }

    function renderMore() {
        const styles: any = {
            visibility: items?.length > 1 ? undefined : "hidden",
            pointerEvents: items?.length > 1 ? undefined : "none",
            left: `${mainNavigationWidth}px`,
        }
        return (
            <div className={`${className}__more`} ref={handleMoreRef} style={styles}>
                <Button
                    className={`${className}__btn ${showMorePopover ? `${className}__btn--active` : ""}`}
                    onClick={handleClickMore}
                    icon="pv-more"
                />
                <Popover active={showMorePopover} className={`${className}__popover`}>
                    <div className={`${className}__more__content`} ref={moreNavRef}>
                        {splitPosition !== -1 && items.slice(splitPosition).map(renderItem)}
                    </div>
                    {renderOverflowAppendix && renderOverflowAppendix(items.slice(splitPosition))}
                </Popover>
            </div>
        )
    }

    const className = style(getOverflowMenuStyles())
    const itemsToRender = items.slice(0, splitPosition !== -1 ? splitPosition : undefined)

    return (
        <div className={`${className} overflowMenu`}>
            <div className={`${className}__main overflowMenu__main`} ref={containerBoundsRef}>
                <div className={`${className}__main__content`} ref={navBoundsRef}>
                    {itemsToRender.map(renderItem)}
                </div>
            </div>
            {renderMore()}
        </div>
    )
}
