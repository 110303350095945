import { Stack, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { OE } from "@tm/models"
import { getBundleParams } from "../../../../utils"
import { OensArticleData } from "../../models"
import { ArticleItem } from "../ArticleItem"
import { ArticleGroupHeader } from "../../../list/components/ArticleGroupHeader/ArticleGroupHeader"

const OeWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.highlight.light,
}))

type ComponentsProps = {
    oeArticles: OE.OePart[]
}

function OeArticleComponent({ oeArticles }: ComponentsProps) {
    const { translateText } = useLocalization()
    return (
        <OeWrapper>
            <ArticleGroupHeader title={translateText(803)} />
            <Stack direction="row" marginBottom="0.75em" />
            {oeArticles?.map((oe) => {
                return <ArticleItem key={oe.id} variant="OE" article={oe} />
            })}
        </OeWrapper>
    )
}

type OeArticleProps = {
    oensArticlesData: OensArticleData
    query?: string
}

export function OeArticle(props: OeArticleProps) {
    const { oensArticlesData } = props
    if (!getBundleParams().enableOENS) {
        return null
    }

    const { isLoading, oeParts } = oensArticlesData

    if (isLoading || !oeParts?.length) {
        return null
    }

    return <OeArticleComponent oeArticles={oeParts} />
}
