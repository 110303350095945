import { PureComponent } from "react"
import { renderRoute, encodeUniqueId, uniqueId, RouteComponentProps, withRouter } from "@tm/utils"
import { Loader, Button, Text, Badge, Icon } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { channel, SearchTreeNode, SearchTreeProductGroup, TreeNode } from "@tm/models"
import SearchField from "../search-field"
import { SearchTree } from "../../widget/business"
import Tree from "../tree"
import { getBundleParams } from "../../../utils"
import { Repositories } from "../../../data"

type Props = LocalizationProps &
    RouteComponentProps<{ workTaskId?: string }> & {
        universalTreeId: number
        universalListRoute: string
        universalSearchRoute: string
        loading: boolean
        nodes?: Array<SearchTreeNode>
        tree?: SearchTree
        onChangeBreadcrumbs(breadcrumbs: Array<TreeNode>, thumbnailClicked?: boolean): void
        onApplyProductGroups(productGroups: Array<SearchTreeProductGroup>): void
        hideBestseller?: boolean
    }

type State = {
    currentShortcutKey: string
}

class UniSearchWidget extends PureComponent<Props, State> {
    unsubscribe: () => void

    constructor(props: Props) {
        super(props)
        this.state = { currentShortcutKey: "" }
    }

    componentDidMount() {
        this.unsubscribe = channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "UNI") {
                this.setState({ currentShortcutKey: rez.key + Math.random() })
            }
        })
    }

    componentWillUnmount() {
        this.unsubscribe?.()
    }

    handleUniSearch = (query: string) => {
        const {
            universalSearchRoute,
            history,
            match: {
                params,
                params: { workTaskId },
            },
        } = this.props
        const url = renderRoute(universalSearchRoute, {
            ...params,
            workTaskId: workTaskId || encodeUniqueId(uniqueId()),
        })
        history.push(`${url}?query=${query}`)
    }

    renderTreeTip() {
        const { translateText } = this.props.localization
        const tipString = translateText(1040).split("{0}")
        const tipStringStart = tipString[0]
        const tipStringEnd = tipString[1]

        return (
            <div className="tip-wrapper">
                <Text className="caption" modifiers={["strong"]}>{`${translateText(813)}: `}</Text>
                <Text>
                    {tipStringStart}
                    <Badge skin="highlight" value={<Icon name="next" />} />
                    {tipStringEnd}
                </Text>
            </div>
        )
    }

    renderTree(params: { workTaskId?: string | undefined }) {
        const { loading, nodes, universalListRoute, universalTreeId, tree } = this.props
        if (tree?.mode === "hide") {
            return null
        }

        return (
            <>
                {tree?.mode === "buttons" ? (
                    <div className="nodes">
                        <Loader visible={loading} />
                        {nodes &&
                            nodes.map((node) => {
                                let url = renderRoute(universalListRoute, params)
                                url = `${url}/uninode?treeId=${universalTreeId}&nodeId=${node.id}`
                                return (
                                    <Button linkTo={url} key={node.id}>
                                        {node.name}
                                    </Button>
                                )
                            })}
                    </div>
                ) : (
                    tree && (
                        <Tree
                            mode={tree.mode}
                            treeId={tree.treeId}
                            loading={tree.loading}
                            groups={tree.groups}
                            breadcrumbs={tree.breadcrumbs}
                            selectedNode={tree.selectedNode}
                            onChangeBreadcrumbs={this.props.onChangeBreadcrumbs}
                            onApplyProductGroups={this.props.onApplyProductGroups}
                            showSecondLevel={tree.showSecondLevel}
                            treeContext="universalParts"
                            hideBestseller={this.props.hideBestseller}
                        />
                    )
                )}
            </>
        )
    }

    render() {
        const { match, tree } = this.props
        const { translateText } = this.props.localization

        let { params } = match
        if (!params.workTaskId) {
            params = { ...params, workTaskId: encodeUniqueId(uniqueId()) }
        }

        return (
            <>
                <div className="upper">
                    <SearchField
                        key={this.state.currentShortcutKey}
                        placeholder={translateText(194)}
                        minLengthForSearch={getBundleParams().minimumSearchLength.universalSearch}
                        onSearch={this.handleUniSearch}
                        autofocus
                        autofocusTimeout={100}
                        getSuggestions={(query) => Repositories.getUniProductGroupSuggestions(query)}
                    />
                    {tree?.mode === "fast-click-2.0" && this.renderTreeTip()}
                </div>

                {this.renderTree(params)}
            </>
        )
    }
}

export default withLocalization(withRouter(UniSearchWidget))
