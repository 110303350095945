import { useLocalization } from "@tm/localization"
import { CellContentPosition, Table, TableCellData, TableColumnData } from "@tm/components"
import { CostEstimationVoucherIncludedWork } from "@tm/models"

type Props = {
    includedWorks: Array<CostEstimationVoucherIncludedWork>
}

export default function IncludedWorksTable({ includedWorks }: Props) {
    const { translate } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translate(58) }, // providerWorkId
            { header: translate(61), alignContent: CellContentPosition.left, minWidth: 1500 }, // description
        ]

        return columns
    }

    function getModuleCellData(includedWork: CostEstimationVoucherIncludedWork): TableCellData[] {
        const cellData: TableCellData[] = [
            { displayValue: includedWork.providerWorkId, id: "includedWork_1" },
            { displayValue: includedWork.description, id: "includedWork_2" },
        ]

        return cellData
    }

    const displayData = includedWorks?.map((includedWork, index) => ({
        cells: getModuleCellData(includedWork),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" rowCap={1} />
}
