import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { getTitleTypeTextId } from "@tm/utils"
import { TextField } from "@tm/controls"
import { Grid } from "@tm/components"

export const Fields = () => {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer

    const titleType = customer && customer.titleType && getTitleTypeTextId(customer.titleType)
    const fieldProps = { floatingLabel: true, readonly: true }

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={2}>
                <TextField
                    {...fieldProps}
                    className="customer-number"
                    label={translateText(102)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["customerNo"]}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField {...fieldProps} className="title-type" label={translateText(118)} value={titleType && translateText(titleType)} />
            </Grid>
            <Grid item xs={2}>
                <TextField {...fieldProps} className="academic-title" label={translateText(76)} model={customer} path={["academicTitle"]} />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    {...fieldProps}
                    className="first-name"
                    label={translateText(119)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["firstName"]}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    {...fieldProps}
                    className="last-name"
                    label={translateText(104)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["lastName"]}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    {...fieldProps}
                    className="company"
                    label={translateText(108)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["companyName"]}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    {...fieldProps}
                    className="street"
                    label={translateText(111)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["street"]}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField {...fieldProps} className="zip-code" label={translateText(112)} maxLength={5} model={customer} path={["zip"]} />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    {...fieldProps}
                    className="city"
                    label={translateText(845)}
                    layout={["holo"]}
                    maxLength={200}
                    model={customer}
                    path={["city"]}
                />
            </Grid>
        </Grid>
    )
}
