import { ajax, createQueryString, getStoredAuthorization } from "@tm/utils"
import { Item } from "../model"
import { getBundleParams } from "../../utils"

export type DtcRequest = {
    tecDocTypeId: number
    manufacturerId: number
    errorCodes: string[]
}

export function getErrorDatas(request: DtcRequest) {
    const url = `${getBaseUrl()}/GetDTCInfo${createQueryString(request)}`
    const authorization = getStoredAuthorization()

    return new Promise<Array<Item>>((resolve, reject) =>
        ajax({ url, authorization }).then((response: any) => {
            if (response) {
                resolve(mapDtcData(response, request.errorCodes))
            } else {
                reject()
            }
        }, reject)
    )
}

function mapDtcData(data: any, searchedErrorCodes: string[]): Array<Item> {
    const newData: Item[] = []
    const items: Array<Item> = data?.items

    searchedErrorCodes.forEach((errorCode: string) => {
        const item = items?.find((i) => i.code === errorCode)
        if (item) {
            newData.push(item)
            return
        }
        newData.push({ code: errorCode, noResult: true, infos: [] })
    })

    return newData
}

const getBaseUrl = (): string => {
    return getBundleParams().fastDTCUrl
}
