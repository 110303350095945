import { Article, ArticleSearchHitInfo } from "@tm/models"
import { useCallback, useMemo } from "react"
import { atom, useRecoilState } from "recoil"
import { isEqual } from "lodash"
import * as PartsRepository from "../../../../data/repositories/parts"
import { GetArticleSearchHitInfoRequest } from "../../../../data/model"
import { usePartsModuleState } from "../../PartsModuleState"

const ArticleFoundListAtom = atom<[GetArticleSearchHitInfoRequest, ArticleSearchHitInfo[] | null, boolean][]>({
    key: "ArticleFoundList",
    default: [],
})

export function useArticleFoundBy(article: Article) {
    const [list, setList] = useRecoilState(ArticleFoundListAtom)
    const startParams = usePartsModuleState((x) => x.params.startParams)

    const request = useMemo(() => {
        if (startParams.type !== "direct") {
            return
        }
        const { searchFilter, query } = startParams
        const {
            supplierArticleNo,
            supplier: { id: supplierId },
        } = article

        return { query, searchFilter, supplierArticleNo, supplierId } as GetArticleSearchHitInfoRequest
    }, [article, startParams])

    const load = useCallback(async () => {
        if (!request) {
            return
        }
        const entryRequest = list.find((x) => isEqual(request, x[0]))?.[0] ?? request
        setList([...list.filter((x) => x[0] !== entryRequest), [entryRequest, [], true]])
        const result = await PartsRepository.getArticleSearchHitInfo(request)
        setList([...list.filter((x) => x[0] !== entryRequest), [entryRequest, result, false]])
    }, [list, request, setList])

    const result = useMemo(() => {
        const entry = list.find((x) => isEqual(request, x[0]))
        return { foundBy: entry?.[1], isLoading: entry?.[2], load }
    }, [list, request, load])

    if (startParams.type !== "direct") {
        return
    }

    return result
}
