import { useLocalization } from "@tm/localization"
import { CostEstimationVoucherTotals, VatRateType } from "@tm/models"
import { Stack, Divider, Box } from "@tm/components"
import Total from "./Total"

type Props = {
    costEstimationTotals?: CostEstimationVoucherTotals
}

export default function CostEstimationTotals({ costEstimationTotals }: Props) {
    const { translateText, currency, number } = useLocalization()

    if (!costEstimationTotals) {
        return null
    }

    const vatRateReplacement = costEstimationTotals.vatAmounts?.find((vat) => vat.vatType === VatRateType.Replacement)

    return (
        <Stack pr={2} justifyContent="flex-end" width="35%" alignSelf="flex-end">
            <Total
                label={`${translateText(1720)} (${translateText(12830)})`}
                value={
                    costEstimationTotals.totalPartsCosts
                        ? currency(costEstimationTotals.totalPartsCosts, costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol)
                        : "-"
                }
            />
            {costEstimationTotals.customerRebateParts && (
                <Total
                    label={`${translateText(54)} (${number(costEstimationTotals.customerRebateParts * 100, 2)}%)`}
                    value={
                        costEstimationTotals.customerRebatePartsAmount
                            ? `-${currency(
                                  costEstimationTotals.customerRebatePartsAmount,
                                  costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol
                              )}`
                            : "-"
                    }
                />
            )}
            <Total
                label={`${translateText(1721)} (${translateText(12830)})`}
                value={currency(costEstimationTotals.totalWorksCosts, costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol)}
            />
            {costEstimationTotals.customerRebateWorks && costEstimationTotals.customerRebateWorksAmount && (
                <Total
                    label={`${translateText(54)} (${number(costEstimationTotals.customerRebateWorks * 100, 2)}%)`}
                    value={`-${currency(
                        costEstimationTotals.customerRebateWorksAmount,
                        costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol
                    )}`}
                />
            )}
            <Divider />
            <Total
                label={translateText(650)}
                value={currency(
                    costEstimationTotals.totalQuotationPriceVatExcluded,
                    costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol
                )}
            />
            <Divider />
            {costEstimationTotals.vatAmounts &&
                costEstimationTotals.vatAmounts
                    .filter((vat) => vat.vatType !== VatRateType.Replacement)
                    .map((vatAmount) => (
                        <Box key={vatAmount.vatType}>
                            <Total
                                label={`${translateText(651)} ${number((vatAmount ? vatAmount.vatRate : 0) * 100, 2)}%`}
                                value={currency(vatAmount.vatAmount, costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol)}
                            />
                        </Box>
                    ))}
            {vatRateReplacement && (
                <Total
                    label={translateText(12827)}
                    value={currency(vatRateReplacement.vatAmount, costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol)}
                />
            )}
            {/* When totalAlreadyTaxedItems is 0 must be rendered */}
            {costEstimationTotals.totalAlreadyTaxedItems !== undefined && costEstimationTotals.totalAlreadyTaxedItems >= 0 && (
                <Total
                    label={translateText(12828)}
                    value={currency(
                        costEstimationTotals.totalAlreadyTaxedItems,
                        costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol
                    )}
                />
            )}
            <Divider />
            <Total
                label={translateText(62)}
                labelBold
                isHeader
                value={currency(
                    costEstimationTotals.totalQuotationPriceVatIncluded,
                    costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol
                )}
                valueBold
            />
        </Stack>
    )
}
