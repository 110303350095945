export enum WheelSelectionSteps {
	CARSELECTION = 0,
	WHEELSLIST = 1,
	WHEELDETAILS = 2,
	RDKSLIST = 3,
	RDKSDETAILS = 4,
	TIRESLIST = 5,
	TIREDETAILS = 6,
	OVERVIEW = 7,
	NONE = -1
}

// TODO convert hardcoded ids to labels also
export const FilterCriterias = {
	rimOffset: 73,
	rimWidth: 74,
	rimDiameter: 2217,
	rimSupplier: -100,
	rimAttribute1: -101,
	rimAttribute2: -102,
	rimAttribute3: -103,
	rimColor: -104,
	rimSort: -105,
	rimAttribute4: -106,
	applicationId: -110,
	OE: -111,
	VSA: -112
}

export enum RimCertification {
	OE = "13400",
	ASA = "13401"
}

export const vehicleInfoDetailsMap: Record<number, number> = {
	1: 13425, //Engine output
	2: 13432, //Capacity
	3: 13434, //Fuel type
	4: 13431, //Maximum speed
	6: 13426, //Axle load
	7: 13427 //Drive axles
}