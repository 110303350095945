import { Button, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { createQueryString, renderRoute } from "@tm/utils"
import { useCallback } from "react"
import { useHistory, useRouteMatch } from "react-router"
import { useUniSearchStatus } from "../../../../helper"
import { usePartsRoutes } from "../../hooks/usePartsRoutes"
import { ArticleListType } from "../../models"

type Props = {
    searchType: ArticleListType
}

type Text = {
    text: number
    button: number
}

type TextAdditional = Text & {
    text2: number
    button2: number
}

const texts: Record<ArticleListType, Text | TextAdditional> = {
    direct: {
        text: 1074,
        button: 1009,
    },
    universal: {
        text: 1007,
        button: 87,
    },
    vehicle: {
        text: 1007,
        button: 87,
        text2: 1008,
        button2: 1009,
    },
}

export function SearchSwap(props: Props) {
    const { translateText } = useLocalization()
    const { searchType } = props
    const history = useHistory()
    const routeMatch = useRouteMatch<{ section: string; view: string }>()
    const routes = usePartsRoutes()
    const searchParams = new URLSearchParams(history.location.search)
    const currentSearchQuery = searchParams.get("query") || ""
    const currentTexts = texts[searchType]
    const uniSearchActive = useUniSearchStatus()

    // the argument is based on the current SearchType not on the target SearchType. e.g. when we are in the direct search, we need the route for universalSearch. The argument is only needed for additional buttons next to the default button.
    const handleRedirect = useCallback(
        (overWriteSearchtype?: ArticleListType) => {
            let url = ""
            switch (overWriteSearchtype ?? searchType) {
                case "direct": {
                    if (!routes.universalParts) {
                        return
                    }
                    url = routes.universalParts.list
                    break
                }
                default: {
                    if (!routes.directSearch) {
                        return
                    }
                    url = routes.directSearch.list
                    break
                }
            }
            history.push(renderRoute(url, routeMatch.params) + createQueryString({ query: currentSearchQuery }))
        },
        [history, routes, routeMatch.params, searchType, currentSearchQuery]
    )

    return (
        <Typography variant="h2">
            {`${translateText(currentTexts.text)} `}
            <Button size="medium" sx={{ margin: "0 5px" }} onClick={() => handleRedirect()}>
                {translateText(currentTexts.button)}
            </Button>
            {"text2" in currentTexts && uniSearchActive && (
                <>
                    {translateText(currentTexts.text2)}
                    <Button size="medium" sx={{ margin: "0 5px" }} onClick={() => handleRedirect("direct")}>
                        {translateText(currentTexts.button2)}
                    </Button>
                </>
            )}
            ?
        </Typography>
    )
}
