import { Article, OE, ArticleQuantities, ArticleQuantitiesRequest, OeArticleDto } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"

import { useCallback, useEffect } from "react"
import { RecoilState, useRecoilState } from "recoil"
import { isEqual, sortBy } from "lodash"
import { isSameOeArticle, mapOeArticleQuantitiesRequest, mapSupplierArticleQuantitiesRequest } from "../helpers"
import { ArticleQuantitiesState, OeArticleQuantitiesState } from "../states"
import {
    getShowQuantitiesByOeArticle,
    getShowQuantitiesBySupplierArticle,
} from "../../../../../basket/src/data/repositories/basket/article-quantities"

function useGenericBasketQuantities<TArticle, TArticleDto>(
    articles: TArticle[],
    isEnabled: boolean,
    initialState: (workTaskId: string | undefined) => RecoilState<ArticleQuantities<TArticleDto>[]>,
    mapQuantitiesRequest: (article: TArticle, workTaskId: string) => ArticleQuantitiesRequest<TArticleDto>,
    getShowQuantities: (request: ArticleQuantitiesRequest<TArticleDto>[]) => Promise<ArticleQuantities<TArticleDto>[]>,
    isSameArticle: (a: TArticleDto, b: TArticleDto) => boolean
) {
    const { workTaskId } = useWorkTask() ?? {}
    const [basketQuantities, setBasketQuantities] = useRecoilState<ArticleQuantities<TArticleDto>[]>(initialState(workTaskId))

    // remove items on Unmount
    useEffect(() => () => setBasketQuantities([]), [setBasketQuantities])

    const loadQuantities = useCallback(
        async (requests: ArticleQuantitiesRequest<TArticleDto>[]) => {
            if (requests.length) {
                const response = await getShowQuantities(requests)
                setBasketQuantities((state) => {
                    const newList = [
                        ...state.filter((x) => !requests.some((item) => isSameArticle(item.article, x.article))),
                        ...response.filter((x) => !!x.articleQuantities),
                    ]
                    if (!isEqual(sortBy(newList), sortBy(state))) {
                        return newList
                    }
                    return state
                })
            }
        },
        [setBasketQuantities]
    )

    useEffect(() => {
        if (!workTaskId || !isEnabled) {
            return
        }
        const requests = articles
            .map((article) => mapQuantitiesRequest(article, workTaskId))
            .filter((req) => !basketQuantities.some((q) => isSameArticle(q.article, req.article)))

        loadQuantities(requests)
    }, [articles, workTaskId, isEnabled])

    const updateBasketQuantities = useCallback(
        async (articles: TArticle[]) => {
            if (!workTaskId) {
                return
            }
            const requests = articles.map((article) => mapQuantitiesRequest(article, workTaskId))

            loadQuantities(requests)
        },
        [loadQuantities, workTaskId]
    )

    return { basketQuantities, updateBasketQuantities }
}

export function useBasketQuantities(articles: Article[], isEnabled: boolean) {
    return useGenericBasketQuantities(
        articles, //
        isEnabled,
        ArticleQuantitiesState,
        mapSupplierArticleQuantitiesRequest,
        getShowQuantitiesBySupplierArticle,
        isEqual
    )
}

export function useOeBasketQuantities(articles: OE.OePart[], isEnabled: boolean) {
    return useGenericBasketQuantities(
        articles, //
        isEnabled,
        OeArticleQuantitiesState,
        mapOeArticleQuantitiesRequest,
        getShowQuantitiesByOeArticle,
        isSameOeArticle
    )
}
