import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { MaintenancePlanSlice, MaintenancePlanState, SelectionKeys } from "./model"
import {
    setMaintenanceData,
    setMaintenanceDataError,
    setMaintenanceDataLoading,
    updateVehicleField,
    setUpdateSelections,
    setVehicle,
    setCalcState,
    deleteSelectionKey,
    setBaseCalculationId,
} from "./actions"

function getInitialState(): MaintenancePlanState {
    return {
        maintenancePlan: {
            maintenancePlanLoading: false,
            vehicleFields: {
                calculateIncludedAdditionalWorks: false,
            },
            selections: [],
            additionalWorks: [],
            followupWorks: [],
            moreInfo: [],
            selectedService: [],
            selectionsToSend: {},
            workTimes: {
                inspectionWorkTimes: [],
                additionalWorkTimes: [],
                followUpWorkTimes: [],
            },
            baseCalculationCalcId: "",
        },
    }
}

export const createMaintenancePlanSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], MaintenancePlanSlice> = (set, get) => ({
    ...getInitialState(),
    maintenancePlanActions: {
        updateVehicleField: (path, value) => set((state) => updateVehicleField(state, path, value), false, "Set update field"),
        setVehicle: (vehicle, modelDetails, shouldReset) =>
            set((state) => setVehicle(state, get(), vehicle, modelDetails, shouldReset), false, "Set Vehicle"),
        setMaintenanceData: (maintenanceData) => set((state) => setMaintenanceData(state, maintenanceData), false, "Set maintenance data"),
        setMaintenanceDataLoading: () => set((state) => setMaintenanceDataLoading(state), false, "Set maintenance data loading"),
        setMaintenanceDataError: () => set((state) => setMaintenanceDataError(state), false, "Set maintenance data error"),
        setUpdateSelections: (selectionKey: SelectionKeys, path: string, value: string) =>
            set((state) => setUpdateSelections(state, selectionKey, path, value), false, "Set update selections"),
        setCalcState: (calcState) => set((state) => setCalcState(state, calcState), false, "Set calculation state"),
        setBaseCalculationId: (calcId) => set((state) => setBaseCalculationId(state, calcId), false, "Set base calculation id"),
        deleteSelectionKey: (selectionKey) => set((state) => deleteSelectionKey(state, selectionKey), false, "Delete selection key"),
        reset: () => set(getInitialState(), false, "Reset Maintenance Plan Slice"),
    },
})
