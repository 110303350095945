import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type Props = {
    vehicleImage: string
}

export default function VehilceImage({ vehicleImage }: Props) {
    const { translateText } = useLocalization()
    return (
        <Collapsible name={translateText(1633)} initiallyOpened skin="dark">
            <div className="vehicle-image">
                <img src={`data:image;base64,${vehicleImage}`} alt="Vehicle" />
            </div>
        </Collapsible>
    )
}
