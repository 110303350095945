import { Component, MouseEvent } from "react"
import * as ReactDOM from "react-dom"
import { withChangeUserSetting, WithChangeUserSettingProps, withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { Button, Collapsible, Loader, Toolbar } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { CategoryType, SearchTreeProductGroup, TreeNode } from "@tm/models"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods, parseQueryString, renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import Tree from "../_shared/tree"
import { FilterType } from "../../business"
import { adaptFilterGroupHeights, getTreeIdFromCategoryType, isClassicalSearchTree } from "../../helper"
import { Actions, IActions, SearchTreeState } from "./business"
import { getBundleParams } from "../../utils"

type ConfigProps = {
    treeType?: CategoryType
    mountingPoint?: string
    showSecondLevel?: boolean
    calculatorRoute?: string
    /* eslint-disable react/no-unused-prop-types */
    mode?: "fast-click" | "fast-click-2.0"
}

type RouteProps = {
    searchType?: string
}

type Props = ConfigProps &
    RouteComponentProps<RouteProps> &
    LocalizationProps &
    WithChangeUserSettingProps &
    WithWorkTaskProps & {
        state: SearchTreeState
        actions: IActions
    }

type State = {
    treeVisible: boolean
}

class SearchTreeComponent extends Component<Props, State> {
    private unsubscribe: Function

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            // If the search tree is mounted on an empty article list (no searchType)
            // open it to help the user
            treeVisible: !props.mountingPoint || !props.match.params.searchType,
        }
        this.unsubscribe = props.actions.init()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    UNSAFE_componentWillMount() {
        const {
            treeType,
            showSecondLevel,
            state: { groups, breadcrumbs },
            actions: { setConfigProps, loadSearchTreeNodes, changeBreadcrumbs },
            location,
        } = this.props

        if (!treeType) {
            console.error(`Parts SearchTree: 'treeType' was not passed as property in the app config`)
            return
        }

        setConfigProps(treeType, showSecondLevel)

        if (!groups.length) {
            loadSearchTreeNodes()
        } else if (!breadcrumbs.length) {
            const { nodeId } = parseQueryString(location.search)

            if (nodeId) {
                const selectedGroup = groups.find((x) => x.id.toString() === nodeId)

                if (selectedGroup) {
                    changeBreadcrumbs([...breadcrumbs, selectedGroup])
                }
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const {
            state: nextState,
            userSettings: nextUserSettings,
            actions: { loadSearchTreeNodesWithTreeParams, changeBreadcrumbs, resetSelection },
            treeType,
            showSecondLevel,
        } = nextProps

        const { state: thisState, userSettings: thisUserSettings } = this.props

        const { nodeId: nextNodeId, productGroupIds: nextProductGroupIds } = parseQueryString(nextProps.location.search)
        const { nodeId: thisNodeId, productGroupIds: thisProductGroupIds } = parseQueryString(this.props.location.search)

        if (
            treeType === "vehicleParts" &&
            ((nextState.vehicle && !thisState.vehicle) ||
                (nextState.vehicle && thisState.vehicle && nextState.vehicle.tecDocTypeId !== thisState.vehicle.tecDocTypeId))
        ) {
            loadSearchTreeNodesWithTreeParams(treeType, showSecondLevel)
        } else if (nextState.groups.length && !nextState.breadcrumbs.length && !thisState.breadcrumbs.length) {
            if (nextNodeId) {
                const selectedGroup = nextState.groups.find((x) => x.id.toString() === nextNodeId)

                if (selectedGroup) {
                    changeBreadcrumbs([...nextState.breadcrumbs, selectedGroup], undefined, treeType, showSecondLevel)
                }
            }
        }

        // Reset breadcrumbs if the searchtype isn't a node anymore
        if ((thisNodeId || thisProductGroupIds) && !nextNodeId && !nextProductGroupIds) {
            resetSelection()
        }

        if (
            (!thisUserSettings && nextUserSettings) ||
            (thisUserSettings && !thisUserSettings.articleListSettings && nextUserSettings && nextUserSettings.articleListSettings) ||
            (thisUserSettings && thisUserSettings.articleListSettings)
        ) {
            this.loadUserSettings(nextProps)
        }
    }

    isClassicalSearchTree() {
        const { treeType, vehicle } = this.props.state
        const treeId = getTreeIdFromCategoryType(treeType, vehicle?.vehicleType)
        return isClassicalSearchTree(treeId)
    }

    /** Only use "mode" through this function and not directly from props */
    getMode(props?: Props) {
        let { mode } = props ?? this.props

        // Classical search tree (e.g. "NKW/Commercial") only supports "fast-click-2.0"
        if (this.isClassicalSearchTree()) {
            mode = "fast-click-2.0"
        }

        return mode
    }

    loadUserSettings(props: Props) {
        const { userSettings, actions, state } = props

        if (userSettings && userSettings.articleListSettings && userSettings.articleListSettings.filterStates) {
            const clipped = (userSettings.articleListSettings.filterStates.clippedFilters & FilterType.TreeNode) === FilterType.TreeNode

            if (clipped !== state.clipped) {
                actions.setClipped(clipped)
            }
        }
    }

    updateClippedSetting() {
        const { setUserSetting, userSettings } = this.props

        if (userSettings && userSettings.articleListSettings && userSettings.articleListSettings.filterStates) {
            setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                filterStates: {
                    ...userSettings.articleListSettings.filterStates,
                    clippedFilters: userSettings.articleListSettings.filterStates.clippedFilters ^ FilterType.TreeNode,
                },
            })
        }
    }

    handleToggleTreeVisibility(treeVisible: boolean) {
        const {
            state: { breadcrumbs },
            mountingPoint,
            userSettings,
            actions,
        } = this.props

        if (treeVisible && breadcrumbs.length && userSettings?.articleListSettings?.resetSearchTree) {
            actions.resetSelection()
        }

        this.setState({ treeVisible })

        // If the tree is being expanded ...
        if (treeVisible && mountingPoint) {
            const mountingPointElement = document.querySelector(mountingPoint)
            if (!mountingPointElement) {
                return
            }

            // .. get the parent scrollbar and scroll to top if necessary
            const scrollBar = mountingPointElement.parentElement
            if (!scrollBar) {
                return
            }

            if (scrollBar.scrollTop > 0) {
                scrollBar.scrollTop = 0
            }

            scrollBar.onscroll = () => {
                if (scrollBar.scrollTop > 0) {
                    this.handleToggleTreeVisibility(false)
                    scrollBar.onscroll = null
                }
            }
        }
    }

    handleChangeBreadcrumbs(breadcrumbs: Array<TreeNode>, thumbnailClicked?: boolean) {
        const {
            actions: { changeBreadcrumbs },
            treeType,
        } = this.props
        const mode = this.getMode()

        TmaHelper.Shared.ByArticleAndUniParts.SearchTreeChanged(treeType!, mode, thumbnailClicked)

        changeBreadcrumbs(breadcrumbs, thumbnailClicked)

        const activeParentNode = breadcrumbs.last()
        const isLastNode = activeParentNode && !activeParentNode.hasChildNodes

        // If the mode is FastClick 1.0
        // or the thumbnail or last node is clicked
        // close it after selecting a breadcrumb (which makes the article list load)
        if (mode === "fast-click" || thumbnailClicked || isLastNode) {
            this.setState({ treeVisible: false })
        }
    }

    handleApplyProductGroups(productGroups: Array<SearchTreeProductGroup>) {
        const {
            actions: { applyProductGroups },
        } = this.props
        applyProductGroups(productGroups)
        this.handleToggleTreeVisibility(false)
    }

    handleRequestCalculation(productGroups: Array<SearchTreeProductGroup>) {
        const { calculatorRoute, match } = this.props

        if (!calculatorRoute || !productGroups.length) {
            return
        }

        Morpheus.showView("1", renderRoute(calculatorRoute, { ...match.params, productGroupIds: productGroups.map((x) => x.id).join(",") }))
    }

    handleToggleClip(e: MouseEvent<HTMLElement>) {
        e.stopPropagation()
        const {
            state: { clipped },
            actions,
        } = this.props
        actions.setClipped(!clipped)
        this.setState({ treeVisible: false })
        this.updateClippedSetting()
    }

    renderHeaderAppendix() {
        const { clipped, loading } = this.props.state

        return (
            <>
                {clipped && <Loader visible={loading} />}
                <Button className="multi-btn__icon" layout={["ghost"]} icon="paperclip" onClick={this.handleToggleClip} />
            </>
        )
    }

    renderNavigationButton(disabled?: boolean) {
        const { translateText } = this.props.localization
        const { treeVisible } = this.state

        return (
            <Toolbar className="tk-parts" title={translateText(1015)}>
                <Collapsible
                    layout="holo"
                    asDropDown
                    closeDropdownOnOutsideClick={false}
                    name={translateText(158)}
                    onChangeOpen={this.handleToggleTreeVisibility}
                    disabled={disabled}
                    initiallyOpened={treeVisible}
                    active={treeVisible}
                    renderHeaderAppendix={this.renderHeaderAppendix}
                />
            </Toolbar>
        )
    }

    renderSearchTree(asCollapsible?: boolean, className?: string) {
        const {
            calculatorRoute,
            showSecondLevel,
            localization: { translateText },
        } = this.props

        const { treeType, loading, groups, breadcrumbs, selectedNode, vehicle } = this.props.state
        const mode = this.getMode()
        const { treeVisible } = this.state

        if (!className) {
            className = ""
        }

        const treeId = getTreeIdFromCategoryType(treeType ?? this.props.treeType, vehicle?.vehicleType)

        // FastCalculator is only available if enabled in the config and the vehicle has data
        const onRequestCalculation = calculatorRoute ? this.handleRequestCalculation : undefined
        const canRequestCalculation = !!this.props.workTask?.vehicle?.dataAvailabilities?.fastCalculator.hasAny

        const tree = (
            <Tree
                mode={mode}
                treeId={treeId}
                loading={!asCollapsible ? loading : false}
                groups={groups}
                breadcrumbs={breadcrumbs}
                selectedNode={selectedNode}
                onChangeBreadcrumbs={this.handleChangeBreadcrumbs}
                onApplyProductGroups={this.handleApplyProductGroups}
                showSecondLevel={showSecondLevel}
                onRequestCalculation={onRequestCalculation}
                canRequestCalculation={canRequestCalculation}
                treeVisible={treeVisible}
                treeContext={treeType}
                clipped={this.props.state.clipped}
            />
        )

        if (asCollapsible) {
            return (
                <Collapsible
                    className={className}
                    name={translateText(158)}
                    initiallyOpened
                    renderHeaderAppendix={this.renderHeaderAppendix}
                    onChangeOpen={adaptFilterGroupHeights}
                >
                    {tree}
                </Collapsible>
            )
        }

        className += "tk-parts searchtree-wrapper"
        if (!treeVisible) {
            className += " is-hidden"
        }

        return <div className={className}>{tree}</div>
    }

    render() {
        //* this is import if the uiConfig is not clean, when all catalogs are switched, we can remove it! */
        if (getBundleParams().searchtreeWithBreadcrumbs && this.props.state.treeType === "vehicleParts") {
            return null
        }

        const { mountingPoint } = this.props

        if (!mountingPoint) {
            return this.renderSearchTree()
        }

        if (this.props.state.clipped) {
            const clippedMountingPoint = document.querySelector("#article-list-filters .filters")

            if (clippedMountingPoint) {
                return ReactDOM.createPortal(this.renderSearchTree(true, "filter filter--categories"), clippedMountingPoint)
            }

            return null
        }

        const mountingPointElement = document.querySelector(mountingPoint)

        return (
            <>
                {this.renderNavigationButton(!mountingPointElement)}
                {mountingPointElement && ReactDOM.createPortal(this.renderSearchTree(), mountingPointElement)}
            </>
        )
    }
}

export default connectComponent(Actions, withRouter(withChangeUserSetting(withLocalization(withWorkTask(SearchTreeComponent)))))
