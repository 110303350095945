import { Box, Icon, MenuItem, Tooltip, useTheme } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { GetProductGroupTopicIdsResponse, RepairTimeProvider } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, getUIA, renderRoute } from "@tm/utils"
import { useState } from "react"
import { useRouteMatch } from "react-router"
import { getBundleParams } from "../../../../utils"
import { GroupHeaderIconButton } from "./GroupHeaderIconButton"

type Props = {
    productGroupId: number
    productGroupTopicIds: GetProductGroupTopicIdsResponse
}

export function TechnicalDataButtons(props: Props) {
    const { productGroupId, productGroupTopicIds } = props

    const theme = useTheme()
    const { showButtonLables, buttonVariant } = theme.overwrites?.components?.partlist?.groupHeader ?? {}

    const { tecrmiTdGenartsRoute, haynesProTdGenartsRoute } = getBundleParams()

    const { translateText } = useLocalization()
    const { params } = useRouteMatch()
    const { workTask } = useWorkTask() ?? {}

    const [showTooltip, setShowTooltip] = useState(false)

    const haynesProAvailable = !!haynesProTdGenartsRoute && productGroupTopicIds[productGroupId]?.[RepairTimeProvider.HaynesProCar]
    const tecRmiAvailable = !!tecrmiTdGenartsRoute && workTask?.vehicle?.dataAvailabilities?.technicalData?.tecRMI
    const showTechnicalAsTooltip = haynesProAvailable && tecRmiAvailable

    function getUrl(baseRoute: string, additionalParams: Record<string, string | number>): string {
        let url = renderRoute(baseRoute, {
            ...params,
            ...additionalParams,
        })

        if (window.location.search.includes("modal")) {
            url += createQueryString({ hasModalBack: true })
        }

        return url
    }

    function handleHaynesProTechnicalDataClick() {
        const topicId = productGroupTopicIds[productGroupId]?.[RepairTimeProvider.HaynesProCar]?.[0]

        if (!haynesProTdGenartsRoute || topicId === undefined) {
            return
        }

        Morpheus.showView("1", getUrl(haynesProTdGenartsRoute, { topicId }))
    }

    function handleTecrmiTechnicalDataClick() {
        if (!tecrmiTdGenartsRoute) {
            return
        }

        Morpheus.showView("1", getUrl(tecrmiTdGenartsRoute, { productGroupId }))
    }

    if (showTechnicalAsTooltip) {
        return (
            <Tooltip
                variant="light"
                color="primary"
                title={
                    <Box>
                        <MenuItem
                            onClick={() => {
                                setShowTooltip(false)
                                handleHaynesProTechnicalDataClick()
                            }}
                            {...getUIA("ArticelListHeaderTechnicalDataHaynes")}
                        >
                            {translateText(12352)}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setShowTooltip(false)
                                handleTecrmiTechnicalDataClick()
                            }}
                            {...getUIA("ArticelListHeaderTechnicalDataTecrmi")}
                        >
                            {translateText(12804)}
                        </MenuItem>
                    </Box>
                }
                disableFocusListener
                disableTouchListener
                disableHoverListener
                onClickAway={() => setShowTooltip(false)}
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                {...getUIA("ArticelListHeaderTechnicalDataTooltip")}
            >
                <GroupHeaderIconButton
                    startIcon={<Icon name="tech-data" />}
                    variant="outlined"
                    onClick={() => setShowTooltip(!showTooltip)}
                    {...getUIA("ArticelListHeaderTechnicalData")}
                />
            </Tooltip>
        )
    }

    return (
        <>
            {haynesProAvailable && (
                <Tooltip title={`${translateText(12352)} - ${translateText(941)}`}>
                    <GroupHeaderIconButton
                        onClick={handleHaynesProTechnicalDataClick}
                        startIcon={<Icon name="tech-data" />}
                        variant={buttonVariant || "outlined"}
                        className="article-group__description__left__td-button"
                        {...getUIA("ArticelListHeaderTechnicalDataHaynes")}
                    >
                        {showButtonLables && `${translateText(12352)} - ${translateText(941)}`}
                    </GroupHeaderIconButton>
                </Tooltip>
            )}
            {tecRmiAvailable && (
                <Tooltip title={`${translateText(12804)} - ${translateText(941)}`}>
                    <GroupHeaderIconButton
                        onClick={handleTecrmiTechnicalDataClick}
                        startIcon={<Icon name="tech-data" />}
                        variant={buttonVariant || "outlined"}
                        className="article-group__description__left__td-button"
                        {...getUIA("ArticelListHeaderTechnicalDataTecrmi")}
                    >
                        {showButtonLables && `${translateText(12804)} - ${translateText(941)}`}
                    </GroupHeaderIconButton>
                </Tooltip>
            )}
        </>
    )
}
