import { ShowCarModelDetailsResponse, Vehicle } from "@tm/models"
import { CalcState, FastServiceSelection, Fields, Item, MaintenanceData, RecordWorks, RepairTime, TyreSpecification } from "../../models"

export type MaintenancePlanSlice = MaintenancePlanActions & MaintenancePlanState

export type MaintenancePlanActions = {
    maintenancePlanActions: {
        reset: () => void
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateVehicleField: (path: string, value: any) => void
        setVehicle: (vehicle: Vehicle, modelDetails: ShowCarModelDetailsResponse, shouldReset?: boolean) => void
        setMaintenanceData: (maintenanceData: MaintenanceData) => void
        setMaintenanceDataLoading: () => void
        setMaintenanceDataError: () => void
        setUpdateSelections: (selectionKey: SelectionKeys, path: string, value: string) => void
        setCalcState: (calcState: CalcState) => void
        setBaseCalculationId: (calcId: string) => void
        deleteSelectionKey: (selectionKey: SelectionKeys) => void
    }
}

export type MaintenancePlanState = {
    maintenancePlan: {
        vehicleFields: Fields
        maintenancePlanLoading?: boolean
        selections?: FastServiceSelection[]
        workTimes: WorkTimes
        additionalWorks?: FastServiceSelection[]
        followupWorks?: FastServiceSelection[]
        moreInfo?: FastServiceSelection[]
        selectionsToSend?: { [key: string]: { [key: string]: string[] } }
        userSelections?: { [ket: string]: { [key: string]: string[] } }
        works?: RecordWorks
        selectedService: Item[]
        stateVehicle?: Vehicle
        modelDetails?: ShowCarModelDetailsResponse
        calcState?: CalcState
        baseCalculationCalcId?: string
        tireSpecifications?: TyreSpecification[]
    }
}

export enum SelectionKeys {
    Selections = "selections",
    AdditionalWorks = "additionalWorks",
    FollowUps = "followupWorks",
}

export type WorkTimes = {
    inspectionWorkTimes: RepairTime[]
    additionalWorkTimes: RepairTime[]
    followUpWorkTimes: RepairTime[]
}
