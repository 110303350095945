import { Box, Button } from "@tm/components"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleSelection } from "../components/ArticleSelection"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { useUniversalPartsModuleState } from "./UniversalPartsModuleState"

export function UniversalPartsList() {
    const { productGroups, setForProductGroups } = useUniversalPartsModuleState((x) => x.params.sorting)
    const { isLoading, isFetchingNextPage, isLoaded, isFailed, articleGroups, loadNextPage, hasNextPage, isStalling, isFrontendFiltered } =
        useUniversalPartsModuleState((x) => x.articles)
    const { translateText } = useLocalization()

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            setForProductGroups([productGroupId], value)
        },
        [setForProductGroups]
    )

    if ((!isFetchingNextPage && isLoaded && !isLoading && !articleGroups.length) || isFailed) {
        return <NoSearchResult searchType="universal" />
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection maxArticleNumberToCompareReached showArticleComparision />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={productGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                    />
                ))}

            {(((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling) && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={(!isLoading || isFetchingNextPage) && !isStalling ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
