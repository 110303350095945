import { useLocalization } from "@tm/localization"
import { DatePicker, Icon, MenuItemWidget, SearchButton, Select, Stack, Typography } from "@tm/components"
import { CisFilters } from "../../../business/model"
import { today } from "../../../business/helper"
import { useInvoices } from "../../../data/hooks/useInvoices"

type Props = {
    filters?: CisFilters
    setFilters(filters: CisFilters): void
    resetFilters(): void
}

export default function InvoicesFilterComponent(props: Props) {
    const { language, translateText } = useLocalization()
    const { filters, setFilters, resetFilters } = props

    const { invoicesData, invoicesLoading } = useInvoices(filters)

    function handleChangeDateFrom(dateFrom: Date | null) {
        setFilters({ ...filters, dateFrom: dateFrom || undefined })
    }

    function handleChangeDateTo(dateTo: Date | null) {
        setFilters({ ...filters, dateTo: dateTo || undefined })
    }

    function handleButtonClick() {
        setFilters({ ...filters })
    }

    function handleProcessingStateChange(queryType: DropdownInputViewProps) {
        setFilters({
            ...filters,
            processingStateId: queryType.id,
        })
    }

    let processingStatesItems: Array<DropdownInputViewProps> = []
    if (invoicesData?.processingStates) {
        processingStatesItems = invoicesData?.processingStates.map((processingState) => ({
            id: processingState.id,
            description: processingState.description,
            htmlColorCode: processingState.htmlColorCode,
        }))
    }

    const selectedProcessingState = processingStatesItems[0]

    return (
        <Stack direction="row" spacing={1}>
            {processingStatesItems && (
                <Stack direction="column">
                    <Typography variant="label">{translateText(212)}</Typography>
                    <Select
                        color="primary"
                        value={selectedProcessingState || ""}
                        variant="filled"
                        size="large"
                        disabled={!processingStatesItems.length}
                        onChange={(e) => handleProcessingStateChange(e.target.value as DropdownInputViewProps)}
                        renderValue={(val) => (val as DropdownInputViewProps).description}
                        sx={{ minWidth: "8.5rem" }}
                    >
                        {processingStatesItems.map((item) => {
                            return (
                                <MenuItemWidget key={item.description} value={item}>
                                    {item.description}
                                </MenuItemWidget>
                            )
                        })}
                    </Select>
                </Stack>
            )}

            <Stack direction="row" alignItems="flex-end">
                <Stack direction="column">
                    <Typography variant="label">{translateText(98)}</Typography>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateFrom}
                        value={filters?.dateFrom || null}
                        maxDate={filters?.dateTo || today}
                        size="large"
                        label={translateText(344)}
                    />
                </Stack>

                <Stack direction="row">
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateTo}
                        value={filters?.dateTo || null}
                        minDate={filters?.dateFrom || undefined}
                        maxDate={today}
                        size="large"
                        label={translateText(95)}
                    />
                    <SearchButton disabled={invoicesLoading} onClick={handleButtonClick} sx={{ marginRight: "4px" }} />
                    <SearchButton startIcon={<Icon name="refresh" />} onClick={resetFilters} />
                </Stack>
            </Stack>
        </Stack>
    )
}

type DropdownInputViewProps = {
    id?: number
    description: string
    htmlColorCode?: string
}
