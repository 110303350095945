import { Box, styled } from "@tm/components"
import { ContactPerson, useCisCustomer, useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, TitleType, UserContext, UserModuleType, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { concat, createQueryString, RouteComponentProps, useModelDetails, withRouter } from "@tm/utils"
import { Suspense, useEffect, useState } from "react"
import { getBundleParams } from "../../utils"
import { LoginRequest } from "../../data"
import { GetMvcPlatformId, getCatalogNumber, getMvcSessionId } from "../../data/helpers/common"
import ImportAdditionalDetails from "../_shared/importAdditionalData"
import { RepairShopDetails } from "../_shared/importAdditionalData/components"

type RouteProps = {
    workTaskId: string
    // ^article-feedback
    productGroupId?: string
    supplierId?: string
    supplierName?: string
    supplierArticleNo?: string
    traderArticleNo?: string
    productGroupName?: string
    // The following props are only filled in the part repairtimes context and ^negative-sale
    position?: string
    quantityValue?: string
    itemId?: string
    articleDescription?: string
    topicId?: string
}

type Props = RouteComponentProps<RouteProps> & {
    className?: string
    title: string
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    externalSystemKey: string | number
    withoutVehicle?: boolean
    observeViewportIntersection?: boolean
    useNativeIFrame?: boolean
    refreshOnUrlChanged?: boolean
    importAdditionalData?: boolean
}

const FeedbackFrameComponent = (props: Props) => {
    const className = `module frame ${props.className || ""}`

    return (
        <Suspense
            fallback={
                <div className={className}>
                    <Loader />
                </div>
            }
        >
            <Component {...props} />
        </Suspense>
    )
}

const StyledWrapper = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
}))

export default withRouter(FeedbackFrameComponent)

function Component(props: Props) {
    const { loginUrl, loginRequest, externalSystemKey, id, withoutVehicle, match, importAdditionalData } = props

    const [loading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [showImportAdditionalData, setShowImportAdditionalData] = useState(importAdditionalData)
    const [sendAdditionalInfo, setSendAdditionalInfo] = useState<boolean>(false)

    const { sendCisCustomerEmail } = getBundleParams()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer(sendCisCustomerEmail)

    const { translateText, languageId, date } = useLocalization()
    const { userContext, userSettings } = useUser() || {}
    const { vehicle, id: workTaskId, statusValue } = useWorkTask()?.workTask || {}

    const modelDetailsResponse = useModelDetails({ vehicleType: vehicle?.vehicleType, modelId: vehicle?.tecDocTypeId })

    useEffect(() => {
        const unsubscribeFeedbackResult = channel("GLOBAL").subscribe("MVC/FEEDBACK_RESULT", ({ success }) => {
            if (props.id === "article-feedback" || props.id === "negative-sale" || props.id === "vehicle-feedback") {
                if (success) {
                    Morpheus.closeView("1")
                } else {
                    setErrorMessage(translateText(1706))
                }
            }
        })

        return () => {
            unsubscribeFeedbackResult?.()
        }
    }, [])

    function getTitle(title: TitleType | undefined) {
        switch (title) {
            case TitleType.Mister:
                return translateText(145)
            case TitleType.Miss:
                return translateText(146)
            case 3:
                return translateText(108)
            default:
                return ""
        }
    }

    const getRequestParameter = (): LoginRequest | null => {
        if (!userContext || (!withoutVehicle && !vehicle?.tecDocTypeId)) {
            return null
        }

        const catNr = getCatalogNumber(userContext)

        const request: LoginRequest = {
            ...loginRequest,
            platformId: GetMvcPlatformId(userContext.system.systemType), // This is the id used by the MVC Application to identify NEXT
            sectionId: loginRequest?.sectionId,
            languageId, // MLE - 22.05.19: changed from "language" to "languageId"
            mdmSessionId: userContext?.id,
            h_session: getMvcSessionId(workTaskId),
            catNr,
            clientId: catNr,
            supplierNumber: match.params.supplierId || "",
            supplierName: match.params.supplierName || "",
            supplierArticleNumber: match.params.supplierArticleNo?.replace(/%252F/g, "/") || "",
            wholesalerArticleNumber: match.params.traderArticleNo || "",
            productGroupName: match.params.productGroupName || "",
        }

        if (externalSystemKey) {
            request.externalSystemKey = externalSystemKey
        }

        if (vehicle) {
            request.kTypeId =
                vehicle.vehicleType === VehicleType.CommercialVehicle
                    ? vehicle.tecDocTypeId - getBundleParams().commercialVehicleOffset
                    : vehicle.tecDocTypeId
            request.engineCode = vehicle.engineCode
            request.typeArt = vehicle.vehicleType
            request.vinNumber = vehicle.vin
            request.regNo = vehicle.plateId
            request.registrationNo = vehicle.registrationNo
            request.mileage = vehicle.mileAge
            request.initialRegistration = vehicle.initialRegistration && date(vehicle.initialRegistration, "d")
        }

        if (modelDetailsResponse?.modelDetails?.registrationNos?.length && !getBundleParams().disableRegNos) {
            request.registrationNos = modelDetailsResponse.modelDetails.registrationNos.join(",")
        }

        if (importAdditionalData) {
            request.vehInfoEnabled = sendAdditionalInfo

            if (userSettings?.repairShop) {
                const { repairShop } = userSettings
                const addressArray = (repairShop.postalAddress && Object.values(repairShop.postalAddress)) || []
                const title = getTitle(repairShop.contactPerson?.title)
                const contactPersonName =
                    (repairShop.contactPerson && [title, repairShop.contactPerson.firstName, repairShop.contactPerson.lastName]) || []

                request.repairShopAddress = concat(", ", ...addressArray)
                request.repairShopPhone = repairShop.contactInfo?.phone
                request.repairShopEmail = repairShop.contactInfo?.email
                request.repairShopContact = contactPersonName.join(" ")
            }
        }

        if (sendCisCustomerEmail && !!cisCustomer) {
            const emails: string[] = []

            cisCustomer?.salesOutlets?.forEach((c) => {
                const tempEmails = c.contacts
                    ?.find((x) => x.jobDescription === "VKH_EMail_Fax")
                    ?.electronicAddresses?.filter((e) => e.type === 1 && !!e.value)
                    ?.flatMap((e) => e.value.toLowerCase())

                if (tempEmails?.length) {
                    emails.push(...tempEmails)
                }
            })

            if (emails.length) {
                request.emailRecipients = emails.join(",")
            }

            request.customerNo = cisCustomer.customerNo
        } else if (userContext?.account?.customerNo) {
            request.customerNo = userContext.account.customerNo
        }

        return request
    }

    const handleVehicleAcceptance = (sendAdditionalInfo: boolean) => {
        setShowImportAdditionalData(false)
        setSendAdditionalInfo(sendAdditionalInfo)
    }

    if (showImportAdditionalData && statusValue && vehicle) {
        return (
            <StyledWrapper>
                <ImportAdditionalDetails
                    vehicle={vehicle!}
                    modelDetails={modelDetailsResponse?.modelDetails}
                    onVehicleAcceptance={handleVehicleAcceptance}
                />
            </StyledWrapper>
        )
    }

    if (sendCisCustomerEmail && cisCustomerLoading) {
        return null
    }

    const request = getRequestParameter()

    if (!request) {
        return null
    }

    const url = `${loginUrl}${createQueryString(request)}`
    const className = `module frame ${props.className || ""}`

    if (errorMessage) {
        return (
            <Text modifiers="danger" size="l">
                {errorMessage}
            </Text>
        )
    }

    if (props.useNativeIFrame) {
        return (
            <>
                <iframe
                    style={{ ...(loading && { display: "none" }) }}
                    src={url}
                    className={className}
                    id={`iframe_${id}`}
                    onLoad={() => setLoading(false)}
                />
                {loading && (
                    <div className={className}>
                        <Loader />
                    </div>
                )}
            </>
        )
    }

    return (
        <IFrame
            url={url}
            className={className}
            contextDependent
            id={`iframe_${id}`}
            observeViewportIntersection={props.observeViewportIntersection}
            refreshOnUrlChanged={props.refreshOnUrlChanged}
            onLoad={() => setLoading(false)}
        >
            {loading ? <Loader /> : <></>}
        </IFrame>
    )
}
