import { useEffect, useMemo } from "react"
import { Box, Button, Icon, Tip, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { Toolbar } from "@tm/controls"
import { useHistory, useParams } from "react-router"
import { changeStepWithHistory, useFastServiceStore } from "../../data"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"
import { MatchParams, viewTranslate } from "../../models"
import { PupUpButton } from "../_shared"
import { FullScreenType } from "../module-manager/business/model"

declare let document: Document & FullScreenType

type Props = {
    route: string
    onFullScreenChange: () => void
}

export default function NavigationComponent({ route, onFullScreenChange }: Props) {
    const active = useFastServiceStore((state) => state.navigation.active)
    const isFullScreen = useFastServiceStore((state) => state.isFullScreen)
    const { translateText } = useLocalization()
    const { totalRepairTimesFormatted, repairTimesIncludesString } = useRepairTimes()
    const history = useHistory()
    const matchParams = useParams<MatchParams>()
    const { view } = matchParams
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints

    const hideSaveButton = useMemo(
        () =>
            ["start", "maintenancePlan", "technician", "maintenanceComplete", "tester", "maintenanceReview", "testComplete", "end"].some(
                (tabName) => tabName === view
            ),
        [view]
    )

    useEffect(() => {
        if (active && active !== matchParams.view) {
            const rrparams = { ...matchParams, view: active }
            const url = renderRoute(route, rrparams)

            history.push(url)
        } else if (!active && active !== matchParams.view) {
            changeStepWithHistory("start")
        }
    }, [active])

    const handleReset = () => {
        useFastServiceStore.getState().reInitializeStore()
    }

    const handleSave = () => {
        view && changeStepWithHistory(view, true)
    }

    return (
        <ModuleToolar>
            {!isTouchDevice && <FastServiceLogo className="logo-fast-service" />}
            <StyledHeader>{view && translateText(viewTranslate.get(view)!.toString())} </StyledHeader>

            <PupUpButton />

            <StyledToolbar title={translateText(361)}>
                <Button startIcon={<Icon name="refresh" />} onClick={handleReset}>
                    {isTouchDevice ? "" : translateText(48)}
                </Button>

                <Button
                    disabled={!(document.fullscreenEnabled || document.webkitFullscreenEnabled)}
                    startIcon={<Icon name={`${isFullScreen ? "fullscreen-exit" : "fullscreen"}`} />}
                    onClick={onFullScreenChange}
                >
                    {isTouchDevice ? "" : translateText(12604)}
                </Button>
            </StyledToolbar>
            {totalRepairTimesFormatted && (
                <Typography
                    sx={{
                        marginLeft: "auto",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {`${translateText(12677)} ${totalRepairTimesFormatted} ${repairTimesIncludesString}`}
                </Typography>
            )}
            {!hideSaveButton && (
                <StyledSaveButtonWrapper>
                    <Tip text={translateText(13502)} enableTipOnTouchDevice={!!isTouchDevice} />
                    <Button color="success" sx={{ height: "max-content", alignSelf: "center", marginLeft: "1em" }} onClick={handleSave}>
                        {translateText(9)}
                    </Button>
                </StyledSaveButtonWrapper>
            )}
        </ModuleToolar>
    )
}

const ModuleToolar = styled(Box)({
    background: "transparent",
    padding: "0 1.5rem",
    marginTop: ".5em",
    marginBottom: ".5rem",
    display: "flex",
    position: "relative",
    zIndex: "2",
    flex: "0.075",
})

const StyledHeader = styled(Box)({
    fontSize: "1.25rem",
    lineHeight: "1.2em",
    fontWeight: "400",
    opacity: ".87",
    display: "flex",
    alignItems: "center",
    padding: "0 2rem 0 0",
})

const FastServiceLogo = styled(Box)({
    height: "3.3em",
    width: "7em",
    marginRight: "1em",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
})

const StyledToolbar = styled(Toolbar)({
    "& .toolbar__content": {
        flexWrap: "wrap",
    },
})

const StyledSaveButtonWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginRight: "1em",
})
