import { Typography, styled } from "@tm/components"
import { memo } from "react"
import { useOeInformationItemState } from "../ContextProviderStates/OeInformationItemState"

const StyledPrice = styled(Typography)({
    textAlign: "right",
    variant: "body2",
    fontWeight: 500,
    fontSize: 15,
    whiteSpace: "nowrap",
    lineHeight: 1,
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "1em",
})

export const OePrices = memo(() => {
    const oePrices = useOeInformationItemState((x) => x.oePrices)

    if (!oePrices?.length) {
        return null
    }

    return (
        <>
            {oePrices.map((price, idx) => (
                <StyledPrice key={`${price.price}_${idx}`} color={price.priceColor}>
                    {price.price}
                </StyledPrice>
            ))}
        </>
    )
})
