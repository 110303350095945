import { Article } from "@tm/models"

import { BundleActionType } from "../../../business"
import { AddingToolsState } from "./model"

export * from "./actions"
export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "ARTICLE_LOADING" }
    | { type: "ARTICLE_LOADED"; payload: Array<Article> }
    | { type: "ADDING_ARTICLE_TO_BASKET" }
    | { type: "ARTICLE_ADDED_TO_BASKET"; payload: string }
    | { type: "ARTICLE_ADD_TO_BASKET_ERROR"; payload: string }
    | { type: "ARTICLE_WITH_PARTS_LIST"; payload: Article }
    | { type: "MULTIPLE_ARTICLES_FOUND" }
    | { type: "SELECTION_MODAL_CLOSED" }

const DEFAULT_STATE: AddingToolsState = {
    directBuyState: {
        articles: null,
        message: "",
        articleLoading: false,
        addToBasketLoading: false,
        success: null,
        showSelectionModal: false,
    },
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): AddingToolsState {
    switch (action.type) {
        case "ARTICLE_LOADING": {
            return {
                ...state,
                directBuyState: {
                    ...DEFAULT_STATE.directBuyState,
                    articleLoading: true,
                    addToBasketLoading: true,
                },
            }
        }
        case "ARTICLE_LOADED": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    articleLoading: false,
                    articles: action.payload,
                },
            }
        }
        case "ADDING_ARTICLE_TO_BASKET": {
            return {
                ...state,
                directBuyState: {
                    ...DEFAULT_STATE.directBuyState,
                    addToBasketLoading: true,
                },
            }
        }
        case "ARTICLE_ADDED_TO_BASKET": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    articleLoading: false,
                    addToBasketLoading: false,
                    message: action.payload,
                    success: true,
                },
            }
        }
        case "MULTIPLE_ARTICLES_FOUND": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    articleLoading: false,
                    addToBasketLoading: false,
                    showSelectionModal: true,
                },
            }
        }
        case "SELECTION_MODAL_CLOSED": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    showSelectionModal: false,
                },
            }
        }
        case "ARTICLE_ADD_TO_BASKET_ERROR": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    articleLoading: false,
                    addToBasketLoading: false,
                    message: action.payload,
                    success: false,
                },
            }
        }
        case "ARTICLE_WITH_PARTS_LIST": {
            return {
                ...state,
                directBuyState: {
                    ...state.directBuyState,
                    articleLoading: false,
                    addToBasketLoading: false,
                    articleWithPartsList: action.payload,
                },
            }
        }
        default:
            break
    }

    return state
}
