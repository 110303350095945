import { Box, ImageViewer, Image as ComponentsImage, Tooltip, styled, tooltipClasses } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useRef, useState } from "react"
import { ImageViewer as ArticleImageViewer } from "../ImageViewer"
import { usePartsViewOptions } from "../../hooks/usePartsViewOptions"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "60vw",
    },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "62px",
    height: "62px",
    zIndex: 2,
    ":hover": {
        borderColor: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
    img: {
        objectFit: "contain",
    },
}))

export function Image() {
    const handleClickImage = useDefaultArticleItemState((x) => x.handleClickImage)
    const handleCloseImage = useDefaultArticleItemState((x) => x.handleCloseImage)
    const showImageViewer = useDefaultArticleItemState((x) => x.showImageViewer)
    const article = useDefaultArticleItemState((x) => x.article)
    const {
        thumbnailUrl,
        thumbnailDescription,
        thumbnailFallbackUrl,
        articleImages,
        startIndex,
        enableLargeView,
        customThumbnailComponent,
        loading,
        onLoadImages,
    } = useDefaultArticleItemState((x) => x.displayArticle.image)

    const [useFallbackThumbnail, setUseFallbackThumbnail] = useState(false)
    const [open, setOpen] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const {
        partsViewSettings: { showArticleImages },
    } = usePartsViewOptions()

    if (!showArticleImages) {
        return null
    }

    const handleThumbnailLoadError = () => {
        if (thumbnailFallbackUrl) {
            setUseFallbackThumbnail(true)
        }
    }

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        if (onLoadImages) {
            onLoadImages()
        }
        setOpen(true)
    }

    const renderImages = () => {
        return <ImageViewer images={articleImages ?? []} onClose={handleTooltipClose} startIndex={startIndex} isLoading={loading ?? false} />
    }

    let content = (
        <ImageContainer ref={containerRef}>
            <ComponentsImage
                type="article"
                src={!useFallbackThumbnail ? thumbnailUrl : thumbnailFallbackUrl ?? ""}
                title={thumbnailDescription}
                onClick={handleClickImage}
                onError={handleThumbnailLoadError}
                loading="lazy"
                height="60px"
                width="60px"
            />
            {customThumbnailComponent}
        </ImageContainer>
    )

    if (enableLargeView) {
        content = (
            <StyledTooltip
                color="primary"
                variant="light"
                open={open}
                placement="right"
                title={renderImages()}
                disableHoverListener
                onClick={handleTooltipOpen}
                onClickAway={handleTooltipClose}
            >
                {content}
            </StyledTooltip>
        )
    }

    return (
        <div>
            {content}
            {showImageViewer && <ArticleImageViewer article={article} anchorEl={containerRef.current} onClose={handleCloseImage} />}
        </div>
    )
}
