import { channel, getCurrentWorkTaskId } from "@tm/models"
import { encodeUniqueId, renderRoute, RouteComponentProps, TmaHelper, uniqueId, withRouter } from "@tm/utils"
import { useEffect } from "react"
import { bundleChannel } from "../../data/channels"

type Props = RouteComponentProps<any> & {
    tyresListUrl: string
}

function ManagerComponent({ tyresListUrl, match, history }: Props) {
    useEffect(() => {
        const unsubscribe = channel("GLOBAL").subscribe("TYRES/SEARCH_TYRE_SIZE", ({ size, carType, season, source }) => {
            if (!season || !carType) {
                season = undefined
                carType = undefined
            }

            const newSearch = [size, carType, season].filter(Boolean).join("&")

            bundleChannel().publish("LOAD_LIST", { byMatchCode: !season && !carType, carType, season, matchCode: size })
            const listURL = renderRoute(tyresListUrl, {
                ...match.params,
                workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()),
                search: newSearch
            })
            history.push(listURL)

            setTimeout(() => {
                TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext(source ?? "tyres", size ?? "", getCurrentWorkTaskId())
            }, 50)
        })

        return unsubscribe
    }, [match.params, history])

    return null

}

export default withRouter(ManagerComponent)