import { AtomEffect, atom, atomFamily } from "recoil"
import {
    Article,
    GetProductGroupTopicIdsResponse,
    OeArticleQuantities,
    RegisteredModels,
    SupplierArticleQuantities,
    ViewStateContainer,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { ListFilter, PartsViewSettings, VehicleRecordsData } from "./models"
import { ArticleErpInfo } from "./hooks/useErpInfos"

const syncViewStateEffect: AtomEffect<Record<string, boolean>> = ({ node, onSet, setSelf, trigger }) => {
    const container = Container.getInstance(RegisteredModels.ViewState) as ViewStateContainer
    onSet((visibilities) => container.action("saveViewState")({ key: node.key, value: visibilities }))
    if (trigger === "get") {
        setSelf(
            container
                .action("loadViewState")(node.key)
                .then((response) => response?.value ?? {})
        )
    }
}

export const FilterTypeVisibilitiesState = atomFamily<Record<string, boolean>, string | undefined>({
    key: "parts.filters.FilterTypeVisibilitiesState",
    default: {},
    effects: [syncViewStateEffect],
})

export const ErpInfosState = atom<ArticleErpInfo[]>({
    key: "parts.hooks.useErpInfos",
    default: [],
})

export const ArticleQuantitiesState = atomFamily<SupplierArticleQuantities[], string | undefined>({
    key: "parts.hooks.useSupplierArticleQuantities",
    default: [],
})

export const OeArticleQuantitiesState = atomFamily<OeArticleQuantities[], string | undefined>({
    key: "parts.hooks.useOeBasketQuantities",
    default: [],
})

export const ProductGroupTopicIdsState = atomFamily<GetProductGroupTopicIdsResponse, string | undefined>({
    key: "parts.hooks.useSupplierArticleQuantities",
    default: {},
})

export const SelectedArticlesState = atom<Article[]>({
    key: "parts.hooks.selectedArticleState",
    default: [],
})

export const PartsViewSettingsState = atom<PartsViewSettings>({
    key: "parts.viewSettings",
    default: {
        compactView: true,
        showArticleImages: true,
        showVehicleRecordsFilters: true,
        quantitySuggestionEnabled: true,
    },
})

export const ShowPurchasePriceState = atom<boolean>({
    key: "parts.showPurchasePrice",
    default: true,
})

export const SupplierLogosState = atom<Record<number, string>>({ key: "parts.supplierLogos", default: {} })

export const VehicleRecordsState = atomFamily<VehicleRecordsData, string | undefined>({
    key: "parts.vehicleRecordsData",
    default: { isLoading: false, productGroupFilters: [], attributes: [] },
})

export const SelectedProductGroupIdsAtom = atomFamily<number[], string>({
    key: "parts.filters.SelectedProductGroupIds",
    default: [],
})
export const SelectedSupplierIdsAtom = atomFamily<number[], string>({
    key: "parts.filters.SelectedSupplierIds",
    default: [],
})
export const SelectedAttributeFilterQueriesAtom = atomFamily<string[], string>({
    key: "parts.filters.SelectedAttributeFilterQueries",
    default: [],
})
export const FixedAttributeFilterQueriesAtom = atomFamily<string[], string>({
    key: "parts.filters.FixedAttributeFilterQueries",
    default: [],
})
export const AttributeFiltersAtom = atomFamily<ListFilter[], string>({
    key: "parts.filters.AttributeFilters",
    default: [],
})
export const ExtendedAssortmentAtom = atomFamily<boolean, string>({
    key: "parts.filters.ExtendedAssortment",
    default: false,
})
