import { ChangeItemsResponse, channel } from "@tm/models"
import { useWorkTaskBasketStore } from "./useWorkTaskBasketStore"

export function useBasketUpdateWorkflow(updatePartErpInfo: (partId: string, quantityValue?: number) => Promise<unknown>) {
    const [
        invalidateWorkTaskBasket,
        invalidateWorkEstimation,
        invalidateBasketErpInfo,
        invalidateBasketCalculation,
        invalidateBonusPointsCalculation,
        invalidateUpdateOrder,
    ] = useWorkTaskBasketStore((store) => [
        store.invalidateWorkTaskBasket,
        store.invalidateWorkEstimation,
        store.invalidateBasketErpInfo,
        store.invalidateBasketCalculation,
        store.invalidateBonusPointsCalculation,
        store.invalidateUpdateOrder,
    ])

    async function handleBasketUpdateWorkflow(workTaskId: string, response: ChangeItemsResponse, quantityValue?: number) {
        const { basketUpdateWorkflow, changedItems } = response
        channel("WORKTASK", workTaskId).publish("BASKET/CLEAR_ORDER_SENT_CONTAINER", {})

        if (basketUpdateWorkflow.updateWorkTaskBasket) {
            invalidateWorkTaskBasket(workTaskId)
        }

        if (basketUpdateWorkflow.updateErpInfo) {
            invalidateBasketErpInfo(workTaskId)
        }

        if (basketUpdateWorkflow.updateItemErpInfo && changedItems?.length) {
            await Promise.allSettled(changedItems.map((item) => updatePartErpInfo(item.id, quantityValue)))
        }

        if (basketUpdateWorkflow.updateWorkEstimation) {
            await invalidateWorkEstimation(workTaskId)
        }

        if (basketUpdateWorkflow.updateWorkTaskBasketCalculation) {
            invalidateBasketCalculation(workTaskId)
        }

        if (basketUpdateWorkflow.updateBonusPointCalculation) {
            invalidateBonusPointsCalculation(workTaskId)
        }

        if (basketUpdateWorkflow.updateOrder) {
            invalidateUpdateOrder(workTaskId)
        }
    }
    return handleBasketUpdateWorkflow
}
