import { StyleConfig } from "../../data"

export const getNextUrlComparable = (pathname: string) => {
    const fragments = pathname.split("/")
    if (fragments.length <= 2) {
        return null
    }

    let basePath = fragments.slice(0, 3).join("/")

    // TODO: PLEASE REFACTOR, JRE 03.11.2022 Hacky way to get the correct basepath even if it must be a 3-fragments one
    const threeFragsBasePath = ["sd", "td", "tdw", "gpi", "repair-times", "damage-calculation", "ext", "truck"]
    if (fragments.length > 3 && threeFragsBasePath.indexOf(fragments[2].toLowerCase()) !== -1) {
        basePath = fragments.slice(0, 4).join("/")
    }

    return basePath
}

export const getStyleFromConfig = (prefix: string, style?: StyleConfig, defaultStyle?: { layout?: string; skin?: string }) => {
    let typeVariations = defaultStyle && defaultStyle.layout ? ` ${prefix}--${defaultStyle.layout}` : ""
    let skin = defaultStyle && defaultStyle.skin ? ` ${prefix}--${defaultStyle.skin}` : ""

    if (style) {
        if (typeof style.layout === "object") {
            typeVariations = " "

            style.layout.forEach((variation) => {
                typeVariations += ` ${prefix}--${variation}`
            })
        } else if (style.layout) {
            typeVariations = style.layout
        }

        if (style && style.skin) {
            skin = ` ${prefix}--${style.skin}`
        }
    }

    return typeVariations + skin
}
