export type BookingRequest = {
    token: string
    limit: number
    offset?: number
    priceFrom?: number
    priceTo?: number
    search?: string
    sort?: string
}

export type BookingResponse = {
    bookings: Booking[]
    isLoading: boolean
}

export type Booking = {
    date_created: string
    status: string
    first_name: string
    last_name: string
    email: string
    id: number
    price_range_from: number
    price_range_to: number
    services: Service[]
    vehicle: Vehicle
}

export type Service = {
    services_id: ServicesId
}

export type ServicesId = {
    title: string
    id: number
}

export type Vehicle = {
    vin: string
    number_plate: string
    first_registration: string
    vehicle_type: VehicleType
}

export type VehicleType = {
    model: string
    manufacturer: string
    model_id: number
    manufacturer_id: number
    type_id: number
    tecdoc_type_id: number
    type: string
    ccm: number
    fuel: string
    power_hp: number
    construction_from: string
    construction_to: string
}

type Status = {
    name: string
    color: string
}

export const statusTypes: Status[] = [
    {
        name: "confirmed",
        color: "#c2e617",
    },
    {
        name: "cancelled",
        color: "#ffeb3b",
    },
    {
        name: "waiting-for-price",
        color: "orange",
    },
    {
        name: "done",
        color: "#89CFF0",
    },
]
