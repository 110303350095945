import { Stack } from "@tm/components"
import { AddToBasketAmountField } from "./AddToBasketAmountField"
import { AddToBasketButton } from "./AddToBasketButton"

type AddToBasketProps = {
    onAddToBasket(): void
    onRemoveFromBasket(): void
    handleChangeQuantity: (value: number, loadErpInfo?: boolean) => void
    originalQuantity: number
    division: number
    basketQuantity?: number
    hideBasketQuantity?: boolean
    hasSuggestedQuantity?: boolean
}

export function AddToBasket(props: AddToBasketProps) {
    const {
        onAddToBasket,
        onRemoveFromBasket,
        handleChangeQuantity,
        originalQuantity,
        division,
        basketQuantity,
        hideBasketQuantity,
        hasSuggestedQuantity,
    } = props

    return (
        <Stack direction="row">
            <AddToBasketAmountField
                originalQuantity={originalQuantity}
                division={division}
                handleChangeQuantity={handleChangeQuantity}
                hasSuggestedQuantity={hasSuggestedQuantity}
            />
            <AddToBasketButton
                onAddToBasket={onAddToBasket}
                onRemoveFromBasket={onRemoveFromBasket}
                basketQuantity={basketQuantity}
                hideBasketQuantity={hideBasketQuantity}
            />
        </Stack>
    )
}
