import { AddCatalogPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { reloadSupplierArticleQuantities } from "../../.."

export function useAddCatalogPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: addCatalogPartList } = useMutation(
        (addCatalogPartListRequest: AddCatalogPartListRequest) => Data.addCatalogPartList(addCatalogPartListRequest),
        {
            onSuccess: (response, request) => {
                if (response) {
                    handleBasketUpdateWorkflow(request.workTaskId, response)
                }

                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

                // The Add to basket buttons require the quantites added in the basket for the badge
                request.catalogParts.forEach((article) => {
                    if (article.dataSupplierArticleNumber && article.dataSupplierId && article.productGroupId) {
                        reloadSupplierArticleQuantities(request.workTaskId, article)
                    }
                })
            },
        }
    )
    const addCatalogParts = useCallback(
        (request: AddCatalogPartListRequest) => {
            return addCatalogPartList(request)
        },
        [addCatalogPartList]
    )

    return addCatalogParts
}
