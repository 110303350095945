import { Icon, Stack, Tooltip, Typography, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useInvalidateWorkTaskBasketQueries } from "../../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

type Props = {
    workTaskId: string
    basketWithErrors?: boolean
    costEstimationWithErrors?: boolean
}

export default function ErrorButton({ basketWithErrors, costEstimationWithErrors, workTaskId }: Props) {
    const { translateText } = useLocalization()
    const { invalidateAllWorkTaskBasketRequests, invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()

    function handleOpenArticleSearchClick() {
        if (basketWithErrors) {
            invalidatePartsRequests(workTaskId)
        } else if (costEstimationWithErrors) {
            invalidateAllWorkTaskBasketRequests(workTaskId)
        }
    }

    function renderErrorTitle() {
        return (
            <Stack spacing={1} p={0.5} alignContent="center">
                <Typography>{translateText(787)}</Typography>
                <Button startIcon={<Icon name="refresh" />} onClick={handleOpenArticleSearchClick}>
                    {translateText(13490)}
                </Button>
            </Stack>
        )
    }

    return (
        <Tooltip title={renderErrorTitle()} variant="light" color="primary">
            <Icon name="alert_W_warning" />
        </Tooltip>
    )
}
