import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(): NestedCSSProperties {
    const { opacity, margin } = getStyleTheme()
    return {
        $nest: {
            ".toolbar": {
                $nest: {
                    "&:before": {
                        content: "none",
                    },
                    "&__title": {
                        marginLeft: "2px",
                        marginBottom: margin.s,
                        fontSize: "0.7rem",
                        opacity: opacity.secondary,
                    },
                },
            },
            "&__search": {
                width: "20em",
                height: "45px",
                $nest: {
                    ".input__inner": {
                        padding: "0.25em 0.5em",
                    },
                    ".input__icons .icon": {
                        width: "1.5rem",
                        height: "1.5rem",
                    },
                    "::placeholder": {
                        opacity: 0.54,
                    },
                    ".btn:last-child": {
                        background: "#e2e2e2",
                        margin: "-0.25em -0.5em -0.25em 0",
                        padding: "0.5em",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    },
                },
            },
            "&__dropdown": {
                height: "45px",
                minWidth: "7rem",
                $nest: {
                    ">.btn": {
                        height: "100%",
                        $nest: {
                            ">.btn__content": {
                                textAlign: "left",
                            },
                        },
                    },
                },
            },
        },
    }
}
