import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(): NestedCSSProperties {
    // const { colors, margin } = getStyleTheme()

    return {
        $nest: {
            ".widget__footer": {
                marginTop: 0,
            },
        },
    }
}

export function getPartnerSearchStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()
    return {
        marginBottom: margin.m,
        height: "100%",
        alignItems: "baseline",
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&__header": {
                display: "none",
            },
            "&__history": {
                flex: "0 0 auto",
            },
        },
    }
}
