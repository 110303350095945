import {
    Article,
    ArticleAttribute,
    ArticleIdentifier,
    CategoryType,
    FittingPosition,
    GetAdvertisementRequest,
    GetAdvertisementResponse,
    GetProductGroupTopicIdsRequest,
    GetProductGroupTopicIdsResponse,
    HasRepairTimesRequest,
    HasRepairTimesResponse,
    OE,
    OrderVouchersShowSupplierArticleByVehicleRequest,
    OrderVouchersShowSupplierArticleByVehicleResponse,
    ProductGroupFiltersModel,
    RegisteredModels,
    RepairTimeProvider,
    RequestArticleListPayload,
    SearchFilters,
    SupplierArticle,
    Vehicle,
    VehicleRecordsContainer,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { getRepairTimeProvidersByNames, StringDictionary, TmaHelper } from "@tm/utils"
import { batch } from "react-redux"

import { addQueryToHistory } from "../../_shared/helper/queryhistory"
import { AvailabilityFilterType, BundleActions, SearchType, TabInfo } from "../../../business"
import { Models, Repositories } from "../../../data"
import * as Mappers from "../../../data/mapper"
import { CriterionFilter } from "../../../data/model/uni-parts"
import { getInitialDataSupplierFilters, shouldCompareWithVehicleRecords } from "../../../helper"
import { getBundleParams } from "../../../utils"
import { ComponentActionType, InitialFilters } from "."
import * as Helpers from "./helpers"
import { getInitialProductGroupFilters, getUsedProductGroupFilterIds } from "./helpers"
import { ListState } from "./model"
import { openPartsSearch } from "../../../utils/modalHelper"

import { getSearchTypeDependentArticleTableProps } from "../components/utils"
import { OeInformationRequest } from "../../../data/model"
import { RouteParams } from "../wrapper"
import { OeInformationConfiguration } from "../../ListV2/ArticleListConfiguration/useOeInformationConfiguration"

function setConfigProperties(
    groupParts: boolean,
    standaloneMode: boolean,
    compactView: boolean,
    publishModuleInfo: boolean,
    oeInformationConfiguration: OeInformationConfiguration
): ComponentActionType {
    return {
        type: "SET_CONFIG_PROPERTIES",
        payload: { groupParts, standaloneMode, compactView, publishModuleInfo, oeInformationConfiguration },
    }
}

export function setSearchType(searchType: SearchType): ComponentActionType {
    return { type: "SET_SEARCHTYPE", payload: searchType }
}

export function setDontResetStateOnUnload(dontResetStateOnUnload?: boolean): ComponentActionType {
    return { type: "SET_DONTRESETONUNLOAD", payload: dontResetStateOnUnload }
}

export function resetState(): ComponentActionType {
    return { type: "RESET_STATE" }
}

export function initializeFilters(initialData: InitialFilters): AsyncAction<ComponentActionType, ListState> {
    const { extendedAssortmentDefaultOnDirectSearch } = getBundleParams()
    return (dispatch, getState) => {
        dispatch({ type: "FILTERS_INITIALIZED", payload: initialData })
        const state = getState()

        if (
            state.searchType === SearchType.UNISEARCH ||
            state.searchType === SearchType.UNINODE ||
            state.searchType === SearchType.UNIPRODUCTGROUPS
        ) {
            // Dispatch "FILTERS_CHANGED" to reset all selected filters (also in "filters" component)
            dispatch({
                type: "FILTERS_CHANGED",
                payload: {
                    productGroupIds: [],
                    supplierIds: [],
                    extendedAssortment: false,
                    availability: AvailabilityFilterType.None,
                    isLoading: true,
                },
            })
            // Reset all data related to the (last) universal search
            dispatch({ type: "RESET_UNI_SEARCH" })
            dispatch(loadUniFilters())
        } else if (
            state.searchType === SearchType.TRADERARTICLENOS ||
            state.searchType === SearchType.PARTSLIST ||
            state.searchType === SearchType.ACCESSORYLIST ||
            state.searchType === SearchType.PASSED_IN_ARTICLES ||
            state.searchType === SearchType.ARTICLE_IDENTIFIER
        ) {
            dispatch(loadArticles())
        } else if (state.searchType === SearchType.OFFERS) {
            dispatch(loadAdvertisement())
        } else {
            dispatch(loadFilters(true))
        }

        if (state.fastCalculator?.alternatives?.request?.length) {
            dispatch(loadFastCalculatorAlternatives(state.fastCalculator.alternatives.request))
        }

        if (state.standaloneMode) {
            const changedAttributes: Array<string> = []
            const loadedAttributes: Array<Models.ArticleAttributeFilter> = []

            if (initialData.articleAttributes) {
                initialData.articleAttributes.forEach((x) => {
                    if (typeof x === "string") {
                        changedAttributes.push(x)
                    } else {
                        changedAttributes.push(x.query)
                        loadedAttributes.push({
                            ...x,
                            description: x.description ?? "",
                            text: x.text ?? "",
                            group: "",
                            isSelected: true,
                        })
                    }
                })
            }

            if (initialData.engineCode) {
                changedAttributes.push(`33|${initialData.engineCode}`)
                loadedAttributes.push({
                    description: "",
                    group: "",
                    id: 33,
                    isSelected: true,
                    name: "Motorcode",
                    query: `33|${initialData.engineCode}`,
                    text: initialData.engineCode,
                })
            }

            if (initialData.equipmentCode) {
                initialData.equipmentCode.split(",").forEach((code) => {
                    changedAttributes.push(`1197|${code}`)
                    loadedAttributes.push({
                        description: "",
                        group: "",
                        id: 1197,
                        isSelected: true,
                        name: "für PR-Nummer",
                        query: `1197|${code}`,
                        text: code,
                    })
                })
            }

            const defaultExtendedAssortment = (extendedAssortmentDefaultOnDirectSearch && state.searchType === SearchType.DIRECT) || false

            // notify other components about the filter change
            dispatch({
                type: "FILTERS_CHANGED",
                payload: {
                    query: initialData.query,
                    // productGroupIds not necessary because they will be changed after loadProductGroupAndSupplierFilters service request
                    productGroupIds: initialData.productGroupIds,
                    supplierIds: initialData.supplierIds,
                    oeReferenceNos: initialData.oeReferenceNos || [],
                    fittingPosition: initialData.fittingPosition || FittingPosition.None,
                    articleAttributes: changedAttributes,
                    extendedAssortment: defaultExtendedAssortment,
                    constructionYear: 0,
                    availability: AvailabilityFilterType.None,
                    isLoading: true,
                },
            })

            dispatch({
                type: "FILTERS_LOADED",
                payload: {
                    articleAttributes: loadedAttributes,
                    isLoading: true,
                },
            })
        }
    }
}

export function reInitialiseGroupAndSupplierFilters(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        const request = Helpers.createFiltersRequest(state)
        const pseudoHash = JSON.stringify(request)

        if (!request || state.filters.requestedFilters === pseudoHash) {
            return
        }
        dispatch({ type: "FILTERS_LOADING", payload: { productGroups: true, suppliers: true, constructionYear: true, isLoading: true } })
        Repositories.getFilters(request).then(
            (result) => {
                dispatch({
                    type: "FILTERS_LOADED",
                    payload: {
                        productGroups: result.productGroupFilters,
                        suppliers: result.dataSupplierFilters,
                        constructionYear: result.constructionYearFilters,
                        showExtendedAssortment: result.showExtendedAssortmentFilter,
                        isLoading: false,
                        requestedFilters: pseudoHash,
                    },
                })
                dispatch({ type: "ARTICLELIST_LOADED", payload: false })
            },
            (error) => dispatch({ type: "FILTERS_ERROR", payload: error })
        )
    }
}

export function loadFilters(
    resetFilters = false,
    callback?: (position: OE.OePosition, replacement?: OE.OeNumber) => AsyncAction<ComponentActionType, ListState, void>
): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        /**
         * NEXT-17896
         * Fixes the problem that a second request were sent, although the first one wasn't ready and the usedFilters were different from the filters
         */
        if (state.loadingFilters) {
            return
        }

        const request = Helpers.createFiltersRequest(state, resetFilters)
        if (!request) {
            return
        }

        dispatch({ type: "FILTERS_LOADING", payload: { productGroups: true, suppliers: true, constructionYear: true } })

        Repositories.getFilters(request).then(
            (result) => {
                dispatch({
                    type: "FILTERS_LOADED",
                    payload: {
                        productGroups: result.productGroupFilters,
                        suppliers: result.dataSupplierFilters,
                        constructionYear: result.constructionYearFilters,
                        showExtendedAssortment: result.showExtendedAssortmentFilter,
                        requestedFilters: JSON.stringify(request),
                    },
                })

                if (!result.productGroupFilters.length || !result.dataSupplierFilters.length) {
                    if (state.searchType === SearchType.SYNONYM) {
                        dispatch({ type: "ARTICLES_LOADED", payload: { result: [] } })
                        return
                    }
                }

                const selectedSuppliers = result.dataSupplierFilters.filter((x) => x.isSelected)

                dispatch({
                    type: "FILTERS_CHANGED",
                    payload: {
                        productGroupIds: result.productGroupFilters.filter((x) => x.isSelected).map((x) => x.id),
                        supplierIds: selectedSuppliers.map((x) => x.id),
                        constructionYear: result.constructionYearFilters.find((x) => x.isSelected)?.id,
                        extendedAssortment:
                            (selectedSuppliers.length ? selectedSuppliers : getInitialDataSupplierFilters(result.dataSupplierFilters)).every(
                                (x) => !x.showOnTop
                            ) || undefined,
                        isLoading: true,
                    },
                })

                dispatch(loadArticles(false, false, callback))
            },
            (error) => dispatch({ type: "FILTERS_ERROR", payload: error })
        )
    }
}

export function loadUniFilters(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        if (state.searchType === SearchType.UNINODE) {
            if (!state.usedFilters.category?.treeId || !state.usedFilters.category?.nodeId) {
                return
            }

            if (!state.uniNodeInitialProductGroupFilters?.length) {
                // First the product group ids for this "treeId" and "nodeId" have to be loaded ...
                Repositories.UniParts.getProductGroupsBySearchTree({
                    treeId: state.usedFilters.category.treeId,
                    nodeId: state.usedFilters.category.nodeId,
                }).then(({ productGroupFilters, selectedCriteria }) => {
                    if (productGroupFilters.length) {
                        // also consider the initial criteria filter of the url, these were introduced for the component "Uni-Search-Top-Products"
                        const queryCrits = (state.initialFilters?.uniCriteriaQuery?.map((q) => ({ query: q })) as CriterionFilter[]) || []
                        // ... then they are saved at the state
                        dispatch({
                            type: "SET_UNI_NODE_INITIAL_PRODUCT_GROUP_FILTERS",
                            payload: {
                                productGroupFilters,
                                selectedCriteria: [...selectedCriteria, ...queryCrits],
                            },
                        })
                        // ... and then call the function again
                        dispatch(loadUniFilters())
                    } else {
                        dispatch({ type: "ARTICLES_LOADED", payload: { result: [] } })
                    }
                })

                return
            }
        } else if (state.searchType === SearchType.UNIPRODUCTGROUPS) {
            if (!state.initialFilters?.productGroupIds?.length) {
                return
            }

            if (!state.uniNodeInitialProductGroupFilters?.length) {
                const productGroupFilters: Array<Models.ProductGroupFilter> = state.initialFilters.productGroupIds
                    .distinct() // just in case there are duplicates
                    .map((x) => ({ id: x }))

                // ... then they are saved at the state
                dispatch({ type: "SET_UNI_NODE_INITIAL_PRODUCT_GROUP_FILTERS", payload: { productGroupFilters } })

                // ... and then call the function again
                dispatch(loadUniFilters())

                return
            }
        }

        const request = Helpers.createUniFiltersRequest(state)
        if (!request) {
            dispatch({ type: "ARTICLES_ERROR", payload: { name: "INVALID_REQUEST", message: "" } })
            return
        }

        const promise = "query" in request ? Repositories.UniParts.getFiltersByQuery(request) : Repositories.UniParts.getFiltersBySearchTree(request)

        dispatch({ type: "FILTERS_LOADING", payload: { productGroups: true, suppliers: true, uniCritera: true } })

        TmaHelper.UniParts.List.FilterChanged({ criterionFilters: (request.selectedCriteria as CriterionFilter[]) ?? [] })

        promise
            .then(
                (result) => {
                    result.criterionFilterGroups.forEach((crit) => {
                        // find the selected criteria in the request
                        const selectedCriteria = request.selectedCriteria?.filter((sel) => crit.key === sel.id)
                        if (selectedCriteria) {
                            // Set criterion groups with selected criterions to priority 1
                            crit.priority = 1

                            // Take the old filters, to allow multi selection
                            // find in the old / (all) filters the criteria group for the selected
                            const olcCrit = state.filters.uniCriteria.find((x) => x.key === crit.key)
                            if (olcCrit) {
                                crit.criterionFilters = olcCrit.criterionFilters
                                // deselect all criterias, and only select the correct ones from result
                                crit.criterionFilters.forEach((critFilter) => {
                                    critFilter.isSelected = false
                                })
                                selectedCriteria.forEach((selected) => {
                                    // find the selected criteria in this group and set it to selected
                                    // this need to be set, because the backend trust the frontend to reduce the database calls
                                    const toBeselectedCriteria = crit.criterionFilters.find((x) => x.value === selected.value)
                                    if (toBeselectedCriteria) {
                                        toBeselectedCriteria.isSelected = true
                                    }
                                })
                            }
                        }
                    })

                    dispatch({
                        type: "FILTERS_LOADED",
                        payload: {
                            productGroups: result.productGroupFilters,
                            suppliers: result.supplierFilters,
                            uniCriteria: result.criterionFilterGroups,
                            showExtendedAssortment: result.showExtendedAssortmentFilter,
                            requestedFilters: JSON.stringify(request),
                        },
                    })
                },
                () =>
                    dispatch({
                        type: "FILTERS_LOADED",
                        payload: { productGroups: [], suppliers: [], uniCriteria: [], showExtendedAssortment: false },
                    })
            )
            .finally(() => {
                dispatch(loadUniArticles())
            })
    }
}

export function loadAdvertisement(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        const { offers } = state.usedFilters
        if (offers) {
            const request: GetAdvertisementRequest = {
                advertisementId: offers.advertisementCategory.id,
            }

            Container.getInstance<GetAdvertisementResponse>(RegisteredModels.ERP_GetAdvertisement)
                .subscribe(request)
                .load()
                .then((response) => {
                    if (response.advertisements.length) {
                        dispatch({ type: "ADVERTISEMENTS_LOADED", payload: response.advertisements })
                        dispatch(loadArticles())
                    }
                })
        }
    }
}

export function loadArticles(
    paging?: boolean,
    activateExtendedAssortmentOnNoResult?: boolean,
    callback?: (position: OE.OePosition, replacement?: OE.OeNumber) => AsyncAction<ComponentActionType, ListState, void>
): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        const { traderArticleNos, partsList, accessoryList, articles, vehicle, articleIdentifier, articleForDbAlternatives, offers } =
            state.usedFilters
        if (offers?.advertisements) {
            const proms: [Promise<StringDictionary<Article[]>> | undefined, Promise<StringDictionary<Article[]>> | undefined] = [undefined, undefined]
            const startIndex = state.result.page.size * (state.result.page.index - 1)
            const endIndex = state.result.page.size * state.result.page.index
            const lastPage = endIndex >= offers.advertisements.length
            const pagedAds = offers.advertisements.slice(startIndex, endIndex)

            const wholesalerArticleNumbers = pagedAds.filter((ads) => ads.wholesalerArticleNumber).map((x) => x.wholesalerArticleNumber)
            if (wholesalerArticleNumbers.length) {
                dispatch({ type: "ARTICLES_LOADING", payload: { paging } })
                proms[0] = Repositories.getArticlesByWholesalerArticleNos(wholesalerArticleNumbers, undefined, true)
            }

            const supplierArticleNumbers: Array<SupplierArticle> = []
            pagedAds.forEach((ad) => {
                if (ad.dataSupplierId && ad.dataSupplierArticleNumber) {
                    supplierArticleNumbers.push({
                        supplierId: ad.dataSupplierId,
                        supplierArticleNo: ad.dataSupplierArticleNumber,
                    })
                }
            })
            if (supplierArticleNumbers.length) {
                dispatch({ type: "ARTICLES_LOADING", payload: { paging } })
                proms[1] = Repositories.getArticlesBySupplierArticleNos(supplierArticleNumbers)
            }

            if (!proms[0] && !proms[1]) {
                dispatch({ type: "ARTICLES_LOADED", payload: { result: [], paging } })
                dispatch(setSearchType(SearchType.HIDE))
                return
            }

            dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

            Promise.all(proms).then(
                ([wholesalerArticleResponse, supplierArticlesResponse]) => {
                    const { articles, wholesalerParts } = Helpers.getArticlesFromTraderArticleDictionary(
                        wholesalerArticleResponse,
                        offers.advertisements
                    )
                    articles.push(...Helpers.getArticlesFromSupplierArticleDictionary(supplierArticlesResponse))

                    dispatch({
                        type: "ARTICLES_LOADED",
                        payload: { result: articles, wholesalerParts, paging, withPaging: !lastPage },
                    })
                    dispatch(loadProductGroupRepairTimes(articles))
                    dispatch(loadProductGroupRelatedArticleData(articles, state))
                    dispatch({ type: "ARTICLELIST_LOADED", payload: false })
                },
                (error) => {
                    dispatch({ type: "ARTICLES_ERROR", payload: error })
                }
            )

            return
        }
        // if traderArticleNos is filled, use a different endpoint
        if (traderArticleNos) {
            const proms: [Promise<Models.GetArticlesByWholesalerArticleNosResponse> | undefined, Promise<Article[]> | undefined] = [
                undefined,
                undefined,
            ]

            if (traderArticleNos.wholesalerParts?.length) {
                const nos = traderArticleNos.wholesalerParts.map((x) => x.wholesalerArticleNumber)
                proms[0] = Repositories.getArticlesByWholesalerArticleNosWithFilters({
                    wholesalerArticleNumbers: nos,
                    modelId: vehicle?.tecDocTypeId,
                    selectedProductGroupIds: state.usedFilters.productGroupIds,
                    selectedDataSupplierIds: state.usedFilters.supplierIds,
                    hideDuplicatesWithDifferentProductGroups: traderArticleNos.hideDuplicatesWithDifferentProductGroups,
                })
            }

            if (articleForDbAlternatives) {
                proms[1] = Repositories.getArticles({
                    searchFilter: SearchFilters.All,
                    query: articleForDbAlternatives.supplierArticleNo,
                    productGroupIds: articleForDbAlternatives.productGroup?.id ? [articleForDbAlternatives.productGroup.id] : undefined,
                    oeManufacturerIds: articleForDbAlternatives.supplier?.manufacturerId
                        ? [articleForDbAlternatives.supplier.manufacturerId]
                        : undefined,
                })
            }

            if (!proms[0] && !proms[1]) {
                dispatch({ type: "ARTICLES_LOADED", payload: { result: [], paging, withPaging: false } })
                dispatch(setSearchType(SearchType.HIDE))
                return
            }

            dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

            Promise.all(proms).then(
                ([traderArticleResponse, additionalArticlesResponse]) => {
                    const { articles, wholesalerParts } = Helpers.getArticlesFromTraderArticleDictionary(
                        traderArticleResponse?.results,
                        traderArticleNos.wholesalerParts,
                        !!state.usedFilters.productGroupIds.length || !!state.usedFilters.supplierIds.length
                    )

                    Helpers.mergeDbAlternatives(
                        articles,
                        additionalArticlesResponse,
                        articleForDbAlternatives,
                        traderArticleNos.hideDuplicatesWithDifferentProductGroups
                    )

                    if (!articles.length && traderArticleNos.hideArticleListWithoutResults) {
                        dispatch({ type: "ARTICLES_LOADED", payload: { result: [], paging, withPaging: false } })
                        dispatch(setSearchType(SearchType.HIDE))
                    } else {
                        dispatch({
                            type: "ARTICLES_LOADED",
                            payload: { result: articles, wholesalerParts, paging, withPaging: false },
                        })

                        if (traderArticleResponse && !articleForDbAlternatives && getBundleParams().showFiltersForArticleListByTraderArticleNos) {
                            dispatch({
                                type: "FILTERS_LOADED",
                                payload: {
                                    productGroups: traderArticleResponse.productGroupFilters,
                                    suppliers: traderArticleResponse.dataSupplierFilters,
                                },
                            })

                            dispatch({
                                type: "FILTERS_CHANGED",
                                payload: {
                                    productGroupIds: traderArticleResponse.productGroupFilters.filter((x) => x.isSelected).map((x) => x.id),
                                    supplierIds: traderArticleResponse.dataSupplierFilters.filter((x) => x.isSelected).map((x) => x.id),
                                    isLoading: true,
                                },
                            })
                        }

                        dispatch(loadProductGroupRepairTimes(articles))
                        dispatch(loadProductGroupRelatedArticleData(articles, state))
                        dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                        if (vehicle) {
                            dispatch(compareVehicleRecords(vehicle, articles, paging))
                        }
                    }
                },
                (error) => {
                    dispatch({ type: "ARTICLES_ERROR", payload: error })
                }
            )

            return
        }
        if (partsList) {
            dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

            Repositories.getPartsList(partsList).then(
                (response) => {
                    dispatch({
                        type: "ARTICLES_LOADED",
                        payload: { result: response.articles, paging, withPaging: false },
                    })
                    dispatch(loadProductGroupRelatedArticleData(response.articles, state))
                    dispatch(loadProductGroupRepairTimes(response.articles))
                    dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                    if (response.partsListImage) {
                        dispatch({
                            type: "SET_PARTS_LIST_IMAGE",
                            payload: { coordinates: response.imageCoordinates, image: response.partsListImage },
                        })
                    } else {
                        dispatch({ type: "SET_PARTS_LIST_IMAGE", payload: undefined })
                    }

                    if (vehicle && articles) {
                        dispatch(compareVehicleRecords(vehicle, response.articles, paging))
                    }
                },
                (error) => {
                    dispatch({ type: "ARTICLES_ERROR", payload: error })
                }
            )

            return
        }
        if (accessoryList) {
            dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

            Repositories.getAccessoryList(accessoryList).then(
                (response) => {
                    dispatch({ type: "ARTICLES_LOADED", payload: { result: response, paging, withPaging: false } })
                    dispatch(loadProductGroupRepairTimes(response))
                    dispatch(loadProductGroupRelatedArticleData(response, state))
                    dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                    if (vehicle && articles) {
                        dispatch(compareVehicleRecords(vehicle, response, paging))
                    }
                },
                (error) => {
                    dispatch({ type: "ARTICLES_ERROR", payload: error })
                }
            )

            return
        }
        if (articles) {
            dispatch({ type: "ARTICLES_LOADED", payload: { result: articles, paging, withPaging: false } })
            dispatch(loadProductGroupRepairTimes(articles))
            dispatch(loadProductGroupRelatedArticleData(articles, state))
            dispatch({ type: "ARTICLELIST_LOADED", payload: false })

            if (vehicle && articles) {
                dispatch(compareVehicleRecords(vehicle, articles, paging))
            }
            return
        }
        if (articleIdentifier) {
            const proms: [Promise<Article[]> | undefined, Promise<Article[]> | undefined] = [undefined, undefined]

            if (articleIdentifier.length) {
                proms[0] = Repositories.getArticlesByArticleNoWithOptVehicle(articleIdentifier)
            }

            if (articleForDbAlternatives) {
                proms[1] = Repositories.getArticles({
                    searchFilter: SearchFilters.All,
                    query: articleForDbAlternatives.supplierArticleNo,
                    productGroupIds: articleForDbAlternatives.productGroup?.id ? [articleForDbAlternatives.productGroup.id] : undefined,
                    oeManufacturerIds: articleForDbAlternatives.supplier?.manufacturerId
                        ? [articleForDbAlternatives.supplier.manufacturerId]
                        : undefined,
                })
            }

            if (!proms[0] && !proms[1]) {
                dispatch({ type: "ARTICLES_LOADED", payload: { result: [], paging, withPaging: false } })
                dispatch(setSearchType(SearchType.HIDE))
                return
            }

            dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

            Promise.all(proms).then(
                async ([articles = [], additionalArticlesResponse]) => {
                    if (state.usedFilters.retryArticleIdentifierSearchWithoutProductGroup) {
                        const notFoundIdentifiers: Array<SupplierArticle> = []
                        articleIdentifier.forEach((ident) => {
                            if (
                                !articles.some(
                                    (art) =>
                                        ident.supplierId === art.supplier.id &&
                                        ident.supplierArticleNo === art.supplierArticleNo &&
                                        ident.productGroupId === art.productGroup.id
                                )
                            ) {
                                notFoundIdentifiers.push({
                                    supplierId: ident.supplierId,
                                    supplierArticleNo: ident.supplierArticleNo,
                                })
                            }
                        })

                        // If no articles can be found with the productGroupId in the identifier, request articles without it.
                        if (notFoundIdentifiers.length) {
                            const response = await Repositories.getArticlesBySupplierArticleNos(notFoundIdentifiers)
                            articles.push(...Helpers.getArticlesFromSupplierArticleDictionary(response))
                        }
                    }

                    Helpers.mergeDbAlternatives(articles, additionalArticlesResponse, articleForDbAlternatives, false)

                    dispatch({ type: "ARTICLES_LOADED", payload: { result: articles, paging, withPaging: false } })
                    dispatch(loadProductGroupRepairTimes(articles))
                    dispatch(loadProductGroupRelatedArticleData(articles, state))
                    dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                    if (vehicle && articles.length) {
                        dispatch(compareVehicleRecords(vehicle, articles, paging))
                    }
                },
                (error) => {
                    dispatch({ type: "ARTICLES_ERROR", payload: error })
                }
            )

            return
        }

        const request = Helpers.createArticlesRequest(state)

        if (callback && state.oeResult) {
            const selectedPosition = state.oeResult.positions?.find((x) => x.isSelected)
            const selectedReplacement = selectedPosition?.replacements?.find((x) => x.isSelected)
            selectedPosition && dispatch(callback(selectedPosition, selectedReplacement))
        }

        if (!request) {
            dispatch({ type: "ARTICLES_LOADED", payload: { result: [], paging, withPaging: false } })
            dispatch({ type: "ARTICLELIST_LOADED", payload: false })
            return
        }

        if (request.query) {
            dispatch(setTabInfo("{{389}}", `"${request.query}"`))

            if (state.searchType === SearchType.DIRECT && !paging) {
                const { enableOEReplacements, enableOENS } = getBundleParams()

                const { enableOeInformation, showReplacementChain } = state.oeInformationConfiguration

                if (
                    enableOeInformation &&
                    !state.initialFilters?.oePositions?.length &&
                    (!state.initialFilters?.isOeReplacement || !state.oeInformation?.oeInformationList?.length)
                ) {
                    dispatch(loadOeInformation(request.query, request.oeInformationWithoutCar))
                }

                if (!showReplacementChain && enableOEReplacements) {
                    dispatch(loadOeReplacements(request.query))
                }

                if (enableOENS) {
                    dispatch(loadOensArticles(request.query))
                }
            }
        }

        dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

        Repositories.getArticles(request).then(
            (articles) => {
                const state = getState()

                if (state.partToReplaceQuantity) {
                    articles = articles.map((x) => ({ ...x, quantity: state.partToReplaceQuantity || x.quantity }))
                }

                // Store the search query in history
                if (state.searchType === SearchType.DIRECT && request.query && articles.length) {
                    addQueryToHistory(request.query)
                }

                if (activateExtendedAssortmentOnNoResult && !articles.length && !state.usedFilters.extendedAssortment) {
                    dispatch({ type: "FILTERS_CHANGED", payload: { extendedAssortment: true } })
                    dispatch(loadArticles(paging))
                    return
                }

                // if no articles found for oe reference search, enable all suppliers
                if (state.searchType === SearchType.OE && !articles.length && state.filters.suppliers.length > state.usedFilters.supplierIds.length) {
                    dispatch({
                        type: "FILTERS_CHANGED",
                        payload: { supplierIds: state.filters.suppliers.map((x) => x.id) },
                    })
                    dispatch(loadArticles(paging))
                    return
                }

                let filteredArticles = articles
                if (state.fastCalculator?.alternatives?.request.length) {
                    // Filter out alternatives already supplied by the fast calculator
                    filteredArticles = articles.filter(
                        (x) =>
                            !state.fastCalculator?.alternatives?.request.some(
                                (y) =>
                                    y.productGroupId === x.productGroup.id &&
                                    y.supplierArticleNo === x.supplierArticleNo &&
                                    y.supplierId === x.supplier.id
                            )
                    )
                }

                dispatch({ type: "ARTICLES_LOADED", payload: { result: filteredArticles, paging } })
                dispatch(loadProductGroupRepairTimes(articles))
                dispatch(loadProductGroupRelatedArticleData(articles, state))
                dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                if (vehicle && articles) {
                    dispatch(compareVehicleRecords(vehicle, articles, paging))
                }
            },
            (error) => dispatch({ type: "ARTICLES_ERROR", payload: error })
        )
    }
}

function loadOeReplacements(query: string): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        if (!query) {
            return
        }

        dispatch({ type: "OE_REPLACEMENTS_LOADING" })

        Repositories.getOeReplacements(query).then(
            (payload) => dispatch({ type: "OE_REPLACEMENTS_LOADED", payload }),
            () => dispatch({ type: "OE_REPLACEMENTS_ERROR" })
        )
    }
}

function loadOeInformation(query: string, oeInformationWithoutCar?: boolean): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        const {
            usedFilters: { vehicle },
        } = state

        if (!query) {
            return
        }

        dispatch({ type: "OE_INFORMATION_LOADING" })
        const request: OeInformationRequest = {
            oeNumber: query,
            modelId: oeInformationWithoutCar ? undefined : vehicle?.tecDocTypeId,
        }

        Repositories.getOeInformation(request).then(
            (payload) => dispatch({ type: "OE_INFORMATION_LOADED", payload }),
            () => dispatch({ type: "OE_INFORMATION_ERROR" })
        )
    }
}

function loadOensArticles(query: string): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        if (!query) {
            return
        }

        dispatch({ type: "OENS_ARTICLES_LOADING" })

        Repositories.getOensArticles(query).then(
            (response) => {
                dispatch({ type: "OENS_ARTICLES_LOADED", payload: response })
                dispatch({ type: "ARTICLELIST_LOADED", payload: false })

                if (response.length) {
                    addQueryToHistory(query)
                }
            },
            () => dispatch({ type: "OENS_ARTICLES_ERROR" })
        )
    }
}

function loadFastCalculatorAlternatives(
    request: Array<ArticleIdentifier & { id?: string; fittingPosition?: FittingPosition }>
): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        if (!request.length || getState().fastCalculator?.alternatives?.loading) {
            return
        }

        dispatch({ type: "FAST_CALCULATOR_ALTERNATIVES_LOADING" })

        Repositories.getArticlesByArticleNoWithOptVehicle(request.map((x) => ({ ...x, id: undefined }))).then(
            (articles) => {
                articles.forEach((x) => {
                    const relatedRequestItem = request.find(
                        (y) => y.productGroupId === x.productGroup.id && y.supplierArticleNo === x.supplierArticleNo && y.supplierId === x.supplier.id
                    )
                    if (!relatedRequestItem) {
                        return
                    }

                    x.id = relatedRequestItem.id ?? x.id // Set the id of each article to the id supplied in the result
                    x.fittingSide = x.fittingSide ?? relatedRequestItem.fittingPosition // Set the fitting position of each article to the fitting position supplied (only if undefined)
                })

                dispatch({ type: "FAST_CALCULATOR_ALTERNATIVES_LOADED", payload: articles })
            },
            () => dispatch({ type: "FAST_CALCULATOR_ALTERNATIVES_LOADED", payload: [] })
        )
    }
}

function setTabInfo(moduleView: string, moduleFilter?: string): ComponentActionType {
    return { type: "SET_TAB_INFO", payload: { moduleView, moduleFilter } }
}

export function loadUniArticles(paging?: boolean): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        const request = Helpers.createUniArticlesRequest(state)
        if (!request) {
            dispatch({ type: "ARTICLES_ERROR", payload: { name: "INVALID_REQUEST", message: "" } })
            return
        }

        const isQuerySearch = "query" in request
        const promise = isQuerySearch ? Repositories.UniParts.getArticlesByQuery(request) : Repositories.UniParts.getArticlesBySearchTree(request)

        dispatch(setTabInfo("{{1009}}", isQuerySearch ? `"${request.query}"` : undefined))

        dispatch({ type: "ARTICLES_LOADING", payload: { paging } })

        promise.then(
            (response) => {
                let articles = response.data

                if (state.partToReplaceQuantity) {
                    articles = articles.map((x) => ({ ...x, quantity: state.partToReplaceQuantity || x.quantity }))
                }

                dispatch({ type: "ARTICLES_LOADED", payload: { result: articles, paging } })
                dispatch({ type: "ARTICLELIST_LOADED", payload: false })
            },
            (error) => dispatch({ type: "ARTICLES_ERROR", payload: error })
        )
    }
}

function loadNextArticles(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        if (state.result.loading || state.result.page.endOfList) {
            return
        }

        dispatch({ type: "NEXT_PAGE" })

        if (
            state.searchType === SearchType.UNINODE ||
            state.searchType === SearchType.UNISEARCH ||
            state.searchType === SearchType.UNIPRODUCTGROUPS
        ) {
            dispatch(loadUniArticles(true))
            return
        }

        dispatch(loadArticles(true))
    }
}

function selectPart(articleUniqueId: string): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        if (
            state.result.selected.length < getBundleParams().maximumPartsToCompare ||
            state.result.selected.some((part) => part === articleUniqueId)
        ) {
            dispatch({ type: "SELECT_ARTICLE", payload: articleUniqueId })
            dispatch({ type: "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED", payload: false })
        } else {
            dispatch({ type: "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED", payload: true })
        }
    }
}

function toggleSelectAllParts(selectAll: boolean): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.result.parts.length < getBundleParams().maximumPartsToCompare || !selectAll) {
            dispatch({ type: "TOGGLE_SELECT_ALL_ARTICLES", payload: selectAll })
            dispatch({ type: "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED", payload: false })
        } else {
            dispatch({ type: "MAXIMUM_ARTICLE_NUMBER_TO_COMPAPRE_REACHED", payload: true })
        }
    }
}

function setListUrl(categoryType: CategoryType, url: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "MODULE_OPENED", payload: { icon: "catalog", title: "{{993}}" } })
        dispatch({ type: "SET_SECTION_URL", payload: { categoryType, sectionType: "list", url } })
    }
}

function searchArticles(
    query: string,
    searchType: "direct" | "synonym" | "universalParts",
    routeParams: RouteParams,
    searchFilter?: SearchFilters,
    partToReplaceId?: string,
    inModal?: boolean,
    isOeReplacement?: boolean
): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        const payload: RequestArticleListPayload = { forceReload: true, partToReplaceId, inModal, isOeReplacement }

        const tabInfo: TabInfo = { moduleFilter: `"${query}"` }

        switch (searchType) {
            case "direct":
                payload.direct = { query, searchFilter }
                tabInfo.moduleView = "{{389}}"
                break
            case "synonym":
                payload.synonym = { query }
                tabInfo.moduleView = "{{389}}"
                break
            case "universalParts":
                payload.uniSearch = { query }
                tabInfo.moduleView = "{{1009}}"
                break
            default:
                return
        }

        dispatch({ type: "SET_TAB_INFO", payload: tabInfo })
        const { partsRoutes } = getBundleParams()
        openPartsSearch(payload, routeParams, partsRoutes)
    }
}

function toggleUniAttributeFilter(criterion: CriterionFilter): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()

        // remove attribute from filters if it is currently active
        const uniCriteria = state.usedFilters.uniCriteria.filter((x) => x.id.toString() !== criterion.id.toString() && x.value !== criterion.value)

        // add attribute to filters if it was not currently active and select them
        if (uniCriteria.length === state.usedFilters.uniCriteria.length) {
            uniCriteria.push({
                ...criterion,
                isSelected: true,
            })
        }

        dispatch({ type: "FILTERS_CHANGED", payload: { uniCriteria } })
        dispatch(loadUniFilters())
    }
}

function toggleAttributeFilter(attribute: ArticleAttribute, addToSidefilters = true): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        const { filters, usedFilters } = state

        // only works for vehicle related article list
        if (!usedFilters.vehicle) {
            return
        }

        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()

        let attributeDeselected = true

        const query = `${attribute.id}|${attribute.key || attribute.value}`

        const existingAttribute = usedFilters.articleAttributes.some((x) => x === query)
        // remove all attributes from the same type
        let articleAttributes = usedFilters.articleAttributes.filter((x) => x.split("|")[0] !== query.split("|")[0])
        // remove attribute from filters if it is currently active
        if (existingAttribute) {
            articleAttributes = articleAttributes.filter((x) => x !== query)
        }
        // add attribute to filters if it was not currently active
        else {
            attributeDeselected = false
            articleAttributes.push(query)
        }
        dispatch({ type: "FILTERS_CHANGED", payload: { articleAttributes } })
        dispatch(loadArticles(false, true))

        // break if the attribute was deselected
        if (!addToSidefilters || attributeDeselected || filters.articleAttributes.some((x) => x.query === query)) {
            return
        }

        const filter: Models.ArticleAttributeFilter = {
            id: attribute.id,
            group: "",
            description: attribute.description,
            isSelected: true,
            name: attribute.abbreviation,
            query,
            unit: attribute.unit,
            priority: 0,
            text: attribute.text || attribute.value,
        }

        dispatch({ type: "FILTERS_LOADED", payload: { articleAttributes: [...filters.articleAttributes.filter((f) => f.id !== filter.id), filter] } })
    }
}

function loadProductGroupRepairTimes(articles: Array<Article>): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        const productGroupIds = Helpers.getProductGroupsIdsFromArticles(articles)

        const rtProviders = getBundleParams().awProviders.map((x) => x.id)
        const providers = getRepairTimeProvidersByNames(rtProviders)

        if (!state.usedFilters.vehicle || !providers.length || !productGroupIds.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: state.usedFilters.vehicle.tecDocTypeId,
            productGroupIds,
            vehicleType: state.usedFilters.vehicle.vehicleType,
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response.productGroups })
                }
            })
    }
}

function showSearchHint(hint: string): ComponentActionType {
    return { type: "SHOW_SEARCH_HINT", payload: hint }
}

function compareVehicleRecords(vehicle: Vehicle, parts: Array<Article> | undefined, paging?: boolean): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        if (!shouldCompareWithVehicleRecords(vehicle)) {
            return
        }

        const state = getState()

        if (!parts) {
            parts = state.result.parts
        } else if (paging) {
            parts = [...state.result.parts, ...parts]
        }

        const request = Mappers.VehicleRecords.mapCompareWithVehicleRecordsRequest(vehicle.id, parts)
        if (request.attributes.length) {
            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
            container
                .action("compareWithVehicleRecords")(request)
                .then((response) => {
                    dispatch({ type: "VEHICLE_RECORDS_COMPARED", payload: response.attributes })
                })
        }
    }
}

function loadProductGroupRelatedArticleData(articles: Array<Article>, state: ListState): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        const { vehicle } = state.usedFilters

        if (!vehicle || !articles.length) {
            return
        }

        const productGroupIds = Helpers.getProductGroupsIdsFromArticles(articles)

        const { canFilterArticleAttributes } = getSearchTypeDependentArticleTableProps(vehicle.id, state.usedFilters, state.searchType)
        if (canFilterArticleAttributes) {
            dispatch(showVehicleRecords(vehicle, productGroupIds, state.usedFilters.fittingPosition))
        }
        dispatch(loadPreviouslyOrderedArticles(vehicle, productGroupIds))
        dispatch(getProductGroupTopicIds(vehicle, productGroupIds))
    }
}

function getProductGroupTopicIds(vehicle: Vehicle, productGroupIds: Array<number>): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const { haynesProTdGenartsRoute, hasToolRental } = getBundleParams()

        if (haynesProTdGenartsRoute || hasToolRental) {
            const state = getState()
            const productGroupIdsToLoad = productGroupIds.filter((id) => !Object.prototype.hasOwnProperty.call(state.result.productGroupTopicIds, id))

            if (!productGroupIdsToLoad.length) {
                return
            }

            const request: GetProductGroupTopicIdsRequest = {
                modelId: vehicle.tecDocTypeId,
                vehicleType: vehicle.vehicleType,
                repairTimeProvider: [
                    ...(haynesProTdGenartsRoute ? [RepairTimeProvider.HaynesProCar] : []),
                    ...(hasToolRental ? [RepairTimeProvider.MobilityMarketToolRental] : []),
                ],
                productGroupIds: productGroupIdsToLoad,
            }

            Container.getInstance<GetProductGroupTopicIdsResponse>(RegisteredModels.RepairTimes_TechnicalData_GetProductGroupTopicIds)
                .subscribe(request)
                .load()
                .then((response) => {
                    dispatch({ type: "SET_PRODUCTGROUP_TOPICIDS", payload: response })
                })
        }
    }
}

function showVehicleRecords(
    vehicle: Vehicle,
    productGroupIds: string | Array<number>,
    fittingSide?: FittingPosition
): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        if (!getBundleParams().vehicleRecordsEnabled) {
            return
        }

        const request = Mappers.VehicleRecords.mapShowVehicleRecordsFilter(vehicle, productGroupIds, fittingSide)
        const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
        container
            .subscribe(request)
            .load()
            .then((response) => {
                const pgFilter: { [id: number]: ProductGroupFiltersModel } = {}
                response.productGroupFilters.forEach((productGroupFilterTop) => {
                    pgFilter[productGroupFilterTop.id] = productGroupFilterTop
                })

                dispatch({ type: "VEHICLE_RECORDS_LOADED", payload: pgFilter })
            })
    }
}

function loadPreviouslyOrderedArticles(vehicle: Vehicle, productGroupIds: Array<number>): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        const request: OrderVouchersShowSupplierArticleByVehicleRequest = {
            vehicleId: vehicle.id,
            productGroupFilterIds: productGroupIds.join(","),
        }

        const container = Container.getInstance<OrderVouchersShowSupplierArticleByVehicleResponse>(
            RegisteredModels.Vouchers_ShowSupplierArticleByVehicle
        )
        container
            .subscribe(request)
            .load()
            .then((response) => {
                dispatch({
                    type: "PREVIOUSLY_ORDERED_ARTICLES_LOADED",
                    payload: response && response.orderedSupplierArticles,
                })
            })
    }
}

function setScrollTop(scrollTop: number): ComponentActionType {
    return { type: "SET_SCROLL_TOP", payload: scrollTop }
}

function getArticleSearchHitInfo(article: Article): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()

        if (
            !state.usedFilters.query ||
            state.searchType !== SearchType.DIRECT ||
            state.result.hitInfo.hasOwnProperty(article.id) ||
            state.result.hitInfoLoading.indexOf(article.id) > -1
        ) {
            return
        }

        dispatch({ type: "ARTICLE_SEARCH_HIT_INFO_LOADING", payload: article.id })

        const request: Models.GetArticleSearchHitInfoRequest = {
            searchFilter: state.usedFilters.query.searchFilter,
            query: state.usedFilters.query.text,
            supplierArticleNo: article.supplierArticleNo,
            supplierId: article.supplier.id,
        }

        Repositories.getArticleSearchHitInfo(request).then(
            (hitInfos) => dispatch({ type: "ARTICLE_SEARCH_HIT_INFO_LOADED", payload: { articleId: article.id, hitInfos } }),
            () => dispatch({ type: "ARTICLE_SEARCH_HIT_INFO_ERROR", payload: article.id })
        )
    }
}

function resetArticleAttributes(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        dispatch({ type: "FILTERS_CHANGED", payload: { articleAttributes: [] } })
        dispatch(loadArticles())
    }
}

function deselectArticleAttribute(query: string): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        const articleAttributes = getState().usedFilters.articleAttributes.filter((x) => x !== query)
        dispatch({ type: "FILTERS_CHANGED", payload: { articleAttributes } })
        dispatch(loadArticles())
    }
}

function deselectProductGroups(query: number): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        const productGroupIds = getState().usedFilters.productGroupIds.filter((x) => x !== query)
        dispatch({ type: "FILTERS_CHANGED", payload: { productGroupIds } })
        dispatch(loadArticles())
    }
}

function deselectSupplier(query: number): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        const supplierIds = getState().usedFilters.supplierIds.filter((x) => x !== query)
        dispatch({ type: "FILTERS_CHANGED", payload: { supplierIds } })
        dispatch(loadArticles())
    }
}

function deselectConstructionYear(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        dispatch({ type: "FILTERS_CHANGED", payload: { constructionYear: undefined } })
        dispatch(loadArticles())
    }
}

function deselectOeReferenceNos(query: string): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        const oeReferenceNos = getState().usedFilters.oeReferenceNos.filter((x) => x !== query)
        dispatch({ type: "FILTERS_CHANGED", payload: { oeReferenceNos } })
        dispatch(loadArticles())
    }
}

function deselectAvailability(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        dispatch({ type: "FILTERS_CHANGED", payload: { availability: AvailabilityFilterType.None } })
        dispatch(loadArticles())
    }
}

function deselectFittingPosition(): AsyncAction<ComponentActionType, ListState> {
    return (dispatch) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        dispatch({ type: "FILTERS_CHANGED", payload: { fittingPosition: FittingPosition.None } })
        dispatch(loadArticles())
    }
}

function toggleDataSupplierFilter(supplierId: number): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        let {
            usedFilters: { supplierIds },
        } = state

        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()

        if (supplierIds.includes(supplierId)) {
            supplierIds = supplierIds.filter((x) => x !== supplierId)
        } else {
            supplierIds = [...supplierIds, supplierId]
        }

        dispatch({ type: "FILTERS_CHANGED", payload: { supplierIds } })

        if (
            state.searchType === SearchType.UNINODE ||
            state.searchType === SearchType.UNISEARCH ||
            state.searchType === SearchType.UNIPRODUCTGROUPS
        ) {
            dispatch(loadUniFilters())
        } else {
            dispatch(loadFilters())
        }
    }
}

function selectOePosition(position: OE.OePosition, replacement?: OE.OeNumber): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()

        const state = getState()

        if (state.searchType === SearchType.OE_POSITIONS) {
            batch(() => {
                dispatch({ type: "SELECT_OE_POSITION", payload: { position, replacement, attributes: [] } })

                dispatch(loadFilters(true, selectOePositionWithAttributes))
            })
            return
        }

        if (state.searchType === SearchType.PRODUCTGROUPS) {
            dispatch({ type: "SELECT_OE_NUMBER", payload: { position, replacement } })
            return
        }

        if (state.searchType === SearchType.DIRECT) {
            Helpers.doNewQuerySearchFromOePosition(position, state, replacement)
        }
    }
}

export function selectOePositionWithAttributes(position: OE.OePosition, replacement?: OE.OeNumber): AsyncAction<ComponentActionType, ListState> {
    return (dispatch, getState) => {
        const state = getState()
        const {
            usedFilters: { vehicle },
        } = state

        if (!vehicle || !position) {
            return
        }

        let productGroupIds = getUsedProductGroupFilterIds(state)

        if (!productGroupIds?.length) {
            productGroupIds = getInitialProductGroupFilters(state)?.map((x) => x.id)
        }

        dispatch({ type: "OE_ATTRIBUTES_LOADING" })

        const req = Helpers.createGetOeAttributesRequest(vehicle.tecDocTypeId, (replacement ?? position).number, productGroupIds)
        Repositories.getOeAttributes(req).then((attributes) =>
            dispatch({
                type: "SELECT_OE_POSITION",
                payload: { position, replacement, attributes: attributes?.articleAttributes, preventPartsReset: true },
            })
        )
    }
}

function changeQuantity(article: Article, quantity: number): ComponentActionType {
    return { type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    changeQuantity,
    compareVehicleRecords,
    deselectArticleAttribute,
    deselectAvailability,
    deselectConstructionYear,
    deselectFittingPosition,
    deselectOeReferenceNos,
    deselectProductGroups,
    deselectSupplier,
    getArticleSearchHitInfo,
    initializeFilters,
    loadArticles,
    loadNextArticles,
    loadFilters,
    loadProductGroupRepairTimes,
    resetState,
    resetArticleAttributes,
    searchArticles,
    selectOePosition,
    selectPart,
    setConfigProperties,
    setListUrl,
    setScrollTop,
    setSearchType,
    showSearchHint,
    showVehicleRecords,
    toggleAttributeFilter,
    toggleDataSupplierFilter,
    toggleSelectAllParts,
    toggleUniAttributeFilter,
    selectOePositionWithAttributes,
    reInitialiseGroupAndSupplierFilters,
    loadOeInformation,
}
