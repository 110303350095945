
import { ajax } from "@tm/utils"
import { mapStatics2CarsJsonToPreyssCars } from "./mapper"
import { PreyssCar } from "../../model"

export function load3DAvailableCars() {
	const url = '//media.carparts-cat.com/webdata1/mvcimages/MVC3DConfigurator/resources/cars/cars.json'
	return new Promise<PreyssCar[]>((resolve, reject) =>
		ajax({ url }).then(
			response => resolve(mapStatics2CarsJsonToPreyssCars(response)),
			reject
		)
	)
}