import { RepairTimeProviderConfig } from "@tm/models"
import { useEffect } from "react"
import { atom, useSetRecoilState } from "recoil"
import { useWorkTaskBasketState } from "./useWorkTaskBasketState"
import { BasketErp, BasketOrderGroup } from "../../models"

export const basketPartsLoadingIds = atom<string[]>({ key: "basket_central_order_loading_ids", default: [] })
export const basketOrderGroups = atom<Record<string, BasketOrderGroup[]>>({ key: "basket_central_order_orderGroups", default: {} })
export const basketErp = atom<Record<string, BasketErp>>({
    key: "basket_central_order_erp",
    default: {},
})

export function useCentralOrderBasketState(workTaskId: string, repairTimeProviders?: { [key: string]: RepairTimeProviderConfig }) {
    const workTaskBasketState = useWorkTaskBasketState(workTaskId, repairTimeProviders)
    const setBasketLoadingIds = useSetRecoilState(basketPartsLoadingIds)
    const setBasketOrderGroups = useSetRecoilState(basketOrderGroups)
    const setBasketErp = useSetRecoilState(basketErp)

    useEffect(() => {
        setBasketLoadingIds((prev) => {
            if (workTaskBasketState.workTaskBasketLoading) {
                if (!prev.includes(workTaskId)) {
                    return [...prev, workTaskId]
                }
                return prev
            }
            return prev.filter((id) => id !== workTaskId)
        })
    }, [workTaskId, setBasketLoadingIds, workTaskBasketState.workTaskBasketLoading])

    useEffect(() => {
        setBasketErp((prev) => {
            if (!workTaskBasketState.erp) {
                return prev
            }
            return {
                ...prev,
                [workTaskId]: workTaskBasketState.erp,
            }
        })
    }, [workTaskId, setBasketErp, workTaskBasketState.erp])

    useEffect(() => {
        setBasketOrderGroups((prev) => {
            if (!workTaskBasketState.basket.state.basketOrderGroups) {
                return prev
            }
            return { ...prev, [workTaskId]: workTaskBasketState.basket.state.basketOrderGroups }
        })
    }, [setBasketOrderGroups, workTaskBasketState.basket.state.basketOrderGroups, workTaskId])
    return workTaskBasketState
}
