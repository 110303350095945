import { Button, Icon, LinkButton } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type CostEstimationActionsProps = {
    costEstimationUrl: string
    costEstimationIsActive: boolean
    detailsIsActive: boolean
}

export function CostEstimationAreas({ costEstimationUrl, costEstimationIsActive, detailsIsActive }: CostEstimationActionsProps) {
    const { translateText } = useLocalization()

    return (
        <Toolbar title={translateText(176)}>
            <LinkButton to={costEstimationUrl} startIcon={<Icon name="menu" />} color={costEstimationIsActive ? "primary" : undefined}>
                {translateText(6)}
            </LinkButton>
            <Button disabled={!detailsIsActive} startIcon={<Icon name="details" />} color={detailsIsActive ? "primary" : undefined}>
                {translateText(43)}
            </Button>
        </Toolbar>
    )
}
