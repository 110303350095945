import { Box, Grid, styled } from "@tm/components"
import { PropsWithChildren, forwardRef, useImperativeHandle, useRef } from "react"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { Filters } from "./Filters"

export type ArticleListWrapperRefType = {
    scrollToTop: () => void
}

const ArticleFilters = styled(Filters)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
}))

export const ArticleListWrapper = forwardRef<ArticleListWrapperRefType, PropsWithChildren<Record<string, unknown>>>(({ children }, ref) => {
    const { filtersAtLeftSide } = useArticleListConfiguration()

    const scrollerRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
        ref,
        () => ({
            scrollToTop() {
                scrollerRef.current?.scrollTo({ top: 0 })
            },
        }),
        []
    )

    return (
        <Grid display="grid" gridTemplateColumns={filtersAtLeftSide ? "auto 1fr" : "1fr auto"} overflow="hidden" pl={1}>
            {filtersAtLeftSide && <ArticleFilters />}
            <Box ref={scrollerRef} sx={{ overflowY: "scroll" }} paddingX={1}>
                {children}
            </Box>
            {!filtersAtLeftSide && <ArticleFilters />}
        </Grid>
    )
})
