import { useEffect, useMemo, useState } from "react"
import { style } from "typestyle"
import {
    Box,
    Dialog,
    Button,
    Typography,
    Table as ComponentsTable,
    TableColumnData,
    TableRowData,
    useTheme,
    SortDirection,
    TableColumnHeaderSortContent,
    Stack,
    Icon,
    LicensePlate,
    LinkButton,
    Loader,
} from "@tm/components"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"

import { useLocalization } from "@tm/localization"
import { ConfigParams, CustomerShortInfo, RegistrationNoType } from "@tm/models"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { RouteComponentProps, TmaHelper, encodeUniqueId, notUndefinedOrNull, parseQueryString, renderRoute, withRouter } from "@tm/utils"
import { bundleChannel } from "../../business"
import { useSetApplyButtonState } from "../../data/hooks/useApplyButtonState"
import { useElementSize } from "../../helpers"
import { Actions, CustomerListState, IActions } from "./business"
import { styles } from "./styles"

const className = style(styles)

type CustomerListProps = RouteComponentProps & {
    state: CustomerListState
    actions: IActions
    customerPath: string
}

function CustomerListFunc(props: CustomerListProps) {
    const { translate, translateText } = useLocalization()
    const {
        location,
        history,
        match,
        customerPath,
        state: {
            list,
            loading,
            options: { orderBy, orderByDescending, query },
            selected,
            initialized,
        },
        actions: { start, selectCustomer, deleteCustomer, loadNextPage, sortList },
    } = props
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const [containerRef, [containerWidth]] = useElementSize()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()

    const [customerToDelete, setCustomerToDelete] = useState<CustomerShortInfo>()
    const setApplyButtonState = useSetApplyButtonState()
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)
    const theme = useTheme()

    const sortDirection = useMemo<SortDirection | undefined>(() => {
        if (orderByDescending === true) {
            return "desc"
        }
        if (orderByDescending === false) {
            return "asc"
        }
    }, [orderByDescending])

    useEffect(() => {
        const query = parseQueryString(location.search).query as string
        start(query)
    }, [location.search])

    useEffect(() => {
        setApplyButtonState({ dataChanged: false, valid: !!selected })
        return bundleChannel().subscribe("APPLY_CHANGES", () => {
            openSelectedCustomer()
        })
    }, [selected])

    useEffect(() => {
        if (list.length === 0) {
            setApplyButtonState({ dataChanged: false, valid: false })
        }
    }, [list])

    function handleScrollBottom() {
        if (list.length > 0) {
            loadNextPage()
        }
    }

    function handleSort(sortField: string) {
        let desc = !orderByDescending

        if (orderBy !== sortField) {
            desc = false
        }

        sortList(sortField, desc)
    }

    function handleRequestCustomerDetails(customer: CustomerShortInfo) {
        const customerId = encodeUniqueId(customer.id)
        const detailsUrl = renderRoute(`${customerPath}/details/:customerId`, { ...match.params, customerId })

        history.push(detailsUrl)
    }

    function handleRowDoubleClick(data: TableRowData) {
        const customer = list.find((x) => x.id === data.id)
        if (customer) {
            handleRequestCustomerDetails(customer)
        }
    }

    function openSelectedCustomer() {
        if (!selected) {
            return
        }
        attachToWorkTask?.({ customer: selected.id })
        TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
        if (Morpheus.anyOpenView("1")) {
            history.push(renderRoute("/:workTaskId", match.params))
            Morpheus.closeView("1")
        }
    }

    function handleDeleteCustomer(customer: CustomerShortInfo) {
        setCustomerToDelete(customer)
    }

    function handleCancelDeleteCustomer() {
        setCustomerToDelete(undefined)
    }

    function handleConfirmDeleteCustomer() {
        if (customerToDelete) {
            deleteCustomer(customerToDelete)
            setCustomerToDelete(undefined)
        }
    }

    function renderEmpty() {
        const detailsUrl = renderRoute(`${customerPath}/details`, match.params)

        if (query) {
            return (
                <div className={`${className}__no-result`}>
                    <h2>{translate(198)}</h2>
                </div>
            )
        }

        return (
            <div className={`${className}__no-result`}>
                <h2>{translate(286)}</h2>
                <p>{translate(287)}</p>
                <LinkButton to={detailsUrl}>{translate(3)}</LinkButton>
            </div>
        )
    }

    function handleSelect(data: TableRowData) {
        const customer = list.find((x) => x.id === data.id)
        if (customer && selected !== customer) {
            selectCustomer(customer)
        } else {
            selectCustomer(undefined)
        }
    }

    function renderTableHeadItem(text: string | JSX.Element, sortField?: string) {
        if (!sortField) {
            return <Box>{text}</Box>
        }

        return (
            <TableColumnHeaderSortContent onClick={() => handleSort(sortField)} sortOrder={orderBy === sortField ? sortDirection : undefined}>
                {text}
            </TableColumnHeaderSortContent>
        )
    }

    function renderVehicle(data: CustomerShortInfo) {
        return (
            <Stack alignItems="flex-start">
                {!!data.plateId && <LicensePlate size="medium" countryCode={plateCode} value={data.plateId} />}
                {data.model && <Box>{[data.manufacturer, data.modelSeries, data.model].join(" ")}</Box>}
            </Stack>
        )
    }

    function renderActions(data: CustomerShortInfo) {
        return (
            <Stack direction="row" gap={0.5}>
                <Button
                    onClick={handleDeleteCustomer.bind(null, data)}
                    title={translateText(69)}
                    color={deleteButtonColorError ? "error" : undefined}
                >
                    <Icon name="delete" size="16px" />
                </Button>
                <Button color="primary" onClick={handleRequestCustomerDetails.bind(null, data)}>
                    {translateText(43)}
                </Button>
            </Stack>
        )
    }

    const columns = useMemo<TableColumnData[]>(() => {
        if (containerWidth <= 1600) {
            return [
                {
                    header: (
                        <Stack>
                            {renderTableHeadItem(translateText(156), "customerno")}
                            {renderTableHeadItem(translateText(13674), "refcustomerno")}
                        </Stack>
                    ),
                },
                {
                    header: (
                        <Stack>
                            {renderTableHeadItem(translateText(155), "lastname")}
                            {renderTableHeadItem(translateText(108))}
                        </Stack>
                    ),
                },
                {
                    header: (
                        <Stack>
                            {renderTableHeadItem(translateText(111), "street")}
                            {renderTableHeadItem(`${translateText(112)}, ${translateText(113)}`, "zip")}
                        </Stack>
                    ),
                },
                {
                    header: (
                        <Stack>
                            {renderTableHeadItem(translateText(110), "phone")}
                            {renderTableHeadItem(translateText(109))}
                        </Stack>
                    ),
                },
                { header: renderTableHeadItem(translateText(147)) },
                { header: renderTableHeadItem(translateText(166)) },
                {},
            ]
        }
        return [
            { header: renderTableHeadItem(translateText(156), "customerno") },
            { header: renderTableHeadItem(translateText(13674), "refcustomerno") },
            { header: renderTableHeadItem(translateText(155), "lastname") },
            { header: renderTableHeadItem(translateText(108)) },
            { header: renderTableHeadItem(translateText(111), "street") },
            { header: renderTableHeadItem(`${translateText(112)}, ${translateText(113)}`, "zip") },
            { header: renderTableHeadItem(translateText(110), "phone") },
            { header: renderTableHeadItem(translateText(109)) },
            { header: renderTableHeadItem(translateText(147)) },
            { header: renderTableHeadItem(translateText(166)) },
            {},
        ]
    }, [containerWidth, orderBy, sortDirection])

    const rows = useMemo<TableRowData[]>(
        () =>
            list.map<TableRowData>((customer) => ({
                id: customer.id,
                active: customer === selected,
                cells:
                    containerWidth <= 1600
                        ? [
                              {
                                  id: "customerNo",
                                  displayValue: (
                                      <Stack>
                                          <Box>{customer.customerNo || <>&ndash;</>}</Box>
                                          <Box>{customer.refCustomerNo || <>&ndash;</>}</Box>
                                      </Stack>
                                  ),
                              },
                              {
                                  id: "name",
                                  displayValue: (
                                      <Stack>
                                          <Box>{[customer.lastName, customer.firstName].filter(notUndefinedOrNull).join(" ") || <>&ndash;</>}</Box>
                                          <Box>{customer.companyName || <>&ndash;</>}</Box>
                                      </Stack>
                                  ),
                              },
                              {
                                  id: "street",
                                  displayValue: (
                                      <Stack>
                                          <Box>{customer.street || <>&ndash;</>}</Box>
                                          <Box>{[customer.zip, customer.city].filter(notUndefinedOrNull).join(" ") || <>&ndash;</>}</Box>
                                      </Stack>
                                  ),
                              },
                              {
                                  id: "phone",
                                  displayValue: (
                                      <Stack>
                                          <Box>{customer.phone || <>&ndash;</>}</Box>
                                          <Box>{customer.email || <>&ndash;</>}</Box>
                                      </Stack>
                                  ),
                              },
                              { id: "vehicleCount", displayValue: customer.vehicleCount || <>&ndash;</> },
                              { id: "lastVehicle", displayValue: renderVehicle(customer) || <>&ndash;</> },
                              { id: "actions", displayValue: renderActions(customer) },
                          ]
                        : [
                              { id: "customerNo", displayValue: customer.customerNo || <>&ndash;</> },
                              { id: "refNo", displayValue: customer.refCustomerNo || <>&ndash;</> },
                              {
                                  id: "name",
                                  displayValue: [customer.lastName, customer.firstName].filter(notUndefinedOrNull).join(" ") || <>&ndash;</>,
                              },
                              { id: "company", displayValue: customer.companyName || <>&ndash;</> },
                              { id: "street", displayValue: customer.street || <>&ndash;</> },
                              { id: "city", displayValue: [customer.zip, customer.city].filter(notUndefinedOrNull).join(" ") || <>&ndash;</> },
                              { id: "phone", displayValue: customer.phone || <>&ndash;</> },
                              { id: "email", displayValue: customer.email || <>&ndash;</> },
                              { id: "vehicleCount", displayValue: customer.vehicleCount || <>&ndash;</> },
                              { id: "lastVehicle", displayValue: renderVehicle(customer) || <>&ndash;</> },
                              { id: "actions", displayValue: renderActions(customer) },
                          ],
            })),
        [list, selected, containerWidth]
    )

    const headerBackground = theme.palette.grey[200]

    return (
        <Box m={2} className={className} ref={containerRef} sx={{ overflow: "hidden", display: "grid" }}>
            <Dialog open={!!customerToDelete} skin="warning" position="top">
                <Typography component="span" pl={2}>
                    {translateText(148)}
                </Typography>
                <Box pl={2} textAlign="center" display="initial">
                    <Button variant="contained" onClick={handleCancelDeleteCustomer}>
                        {translateText(584)}
                    </Button>
                    <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleConfirmDeleteCustomer}>
                        {translateText(585)}
                    </Button>
                </Box>
            </Dialog>
            {initialized && !list.length && loading && <Loader />}
            {initialized && !list.length && !loading && renderEmpty()}
            {list.length && (
                <ComponentsTable
                    headerStyle="default"
                    onRowClick={handleSelect}
                    onRowDoubleClick={handleRowDoubleClick}
                    headerBackground={headerBackground}
                    columns={columns}
                    rows={rows}
                    scrollbar="system"
                    fontSize={14}
                    onScrollBottom={handleScrollBottom}
                />
            )}
        </Box>
    )
}

export default connectComponent(Actions, withRouter(CustomerListFunc))
