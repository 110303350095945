export enum CredentialInputKeys {
    Email = "email",
    Password = "password",
}

export type DrivemotiveLoginRequest = {
    email: string
    password: string
}

export type DrivemotiveLoginResponse = {
    token: string
}
