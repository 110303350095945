import { AsyncAction } from "@tm/morpheus"
import { RequestArticleListPayload, channel, CategoryType, TreeNode, SearchTreeProductGroup, GetSearchTreeRequest } from "@tm/models"
import { getBundleParams } from "../../../utils"
import { BundleActionType, BundleActions } from "../../../business"
import { Models, Repositories } from "../../../data"
import { getTreeIdFromCategoryType, getVehicleTreeIdFromVehicleType } from "../../../helper"
import { SearchTreeState } from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "SET_CLIPPED"; payload: boolean }
    | { type: "SET_CONFIG_PROPS"; payload: { treeType: CategoryType; showSecondLevel: boolean | undefined } }
    | { type: "SEARCH_TREE_LOADING" }
    | {
          type: "SEARCH_TREE_LOADED"
          payload: {
              nodes: Array<TreeNode>
              topProductGroups: Array<SearchTreeProductGroup>
              parentNode?: TreeNode
          }
      }
    | { type: "CHANGE_BREADCRUMBS"; payload: Array<TreeNode> }
    | { type: "RESET_SELECTION" }

function setClipped(clipped: boolean): ComponentActionType {
    return { type: "SET_CLIPPED", payload: clipped }
}

function setConfigProps(treeType: CategoryType, showSecondLevel: boolean | undefined): ComponentActionType {
    return { type: "SET_CONFIG_PROPS", payload: { treeType, showSecondLevel } }
}

function loadVehicleNodes(
    parentNode?: TreeNode,
    onlyTopProductGroups?: boolean,
    searchTreeShowSecondLevel?: boolean
): AsyncAction<ComponentActionType, SearchTreeState> {
    return (dispatch, getState) => {
        const { showSecondLevel, vehicle } = getState()

        if (!vehicle) {
            return
        }
        const treeId = getVehicleTreeIdFromVehicleType(vehicle.vehicleType)
        if (!treeId) {
            return
        }

        const request: GetSearchTreeRequest = {
            modelId: vehicle.tecDocTypeId,
            vehicleType: vehicle.vehicleType,
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel: showSecondLevel ?? searchTreeShowSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: { nodes: response.nodes, topProductGroups: response.topProductGroups, parentNode },
                }),
            () => dispatch({ type: "SEARCH_TREE_LOADED", payload: { nodes: [], topProductGroups: [] } })
        )

        dispatch({ type: "SEARCH_TREE_LOADING" })
    }
}

function loadUniPartsNodes(
    parentNode?: TreeNode,
    onlyTopProductGroups?: boolean,
    searchTreeShowSecondLevel?: boolean
): AsyncAction<ComponentActionType, SearchTreeState> {
    return (dispatch, getState) => {
        const { showSecondLevel } = getState()
        const treeId = getBundleParams().universalTreeId

        if (!treeId) {
            return
        }

        const request: Models.UniParts.SearchTreeRequest = {
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel: showSecondLevel ?? searchTreeShowSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.UniParts.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: { nodes: response.nodes, topProductGroups: response.topProductGroups, parentNode },
                }),
            () => dispatch({ type: "SEARCH_TREE_LOADED", payload: { nodes: [], topProductGroups: [] } })
        )

        dispatch({ type: "SEARCH_TREE_LOADING" })
    }
}
function loadSearchTreeNodesWithTreeParams(searchTreeType?: CategoryType, showSecondLevel?: boolean) {
    return loadSearchTreeNodes(undefined, undefined, searchTreeType, showSecondLevel)
}
function loadSearchTreeNodes(
    parentNode?: TreeNode,
    onlyTopProductGroups?: boolean,
    searchTreeType?: CategoryType,
    showSecondLevel?: boolean
): AsyncAction<ComponentActionType, SearchTreeState> {
    return (dispatch, getState) => {
        const { treeType } = getState()

        switch (treeType ?? searchTreeType) {
            case "vehicleParts":
                dispatch(loadVehicleNodes(parentNode, onlyTopProductGroups, showSecondLevel))
                break
            case "universalParts":
                dispatch(loadUniPartsNodes(parentNode, onlyTopProductGroups, showSecondLevel))
                break
            default:
                break
        }
    }
}

function changeBreadcrumbs(
    breadcrumbs: Array<TreeNode>,
    startSearch?: boolean,
    searchTreeType?: CategoryType,
    showSecondLevel?: boolean
): AsyncAction<ComponentActionType, SearchTreeState> {
    return (dispatch, getState) => {
        const { treeType, vehicle } = getState()

        const treeId = getTreeIdFromCategoryType(treeType ?? searchTreeType, vehicle?.vehicleType)
        if (!treeId) {
            return
        }

        dispatch({ type: "CHANGE_BREADCRUMBS", payload: breadcrumbs })

        const activeParentNode = breadcrumbs.last()

        // If no breadcrumbs is selected / all are removed
        // just exit after updating the breadcrumbs
        if (!activeParentNode) {
            return
        }

        const isLastNode = !activeParentNode.hasChildNodes

        // Check if the child nodes (childNodes array is undefined)
        // or the top product groups (topProductGroups array is undefined) have to be loaded
        if (!activeParentNode.childNodes || !activeParentNode.topProductGroups) {
            dispatch(loadSearchTreeNodes(activeParentNode, !!activeParentNode.childNodes || isLastNode, searchTreeType, showSecondLevel))
        }

        // If is the last node in tree
        //    or the "startSearch" parameter is provided
        //    or the "synchronizeArticleList" parameter is provided
        // request to open the article list
        if (isLastNode || startSearch) {
            const history = breadcrumbs.map((b) => b.id)
            switch (treeType) {
                case "vehicleParts": {
                    const request: RequestArticleListPayload = {
                        node: { treeId, nodeId: activeParentNode.id, history },
                    }

                    channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
                    break
                }
                case "universalParts": {
                    const request: RequestArticleListPayload = {
                        uniNode: { treeId, nodeId: activeParentNode.id, history },
                    }

                    channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
                    break
                }
                default:
                    break
            }
        }
    }
}

function applyProductGroups(productGroups: Array<SearchTreeProductGroup>): AsyncAction<ComponentActionType, SearchTreeState> {
    return (dispatch, getState) => {
        const { treeType, selectedNode, breadcrumbs, vehicle } = getState()

        if (selectedNode) {
            // Clear the selectedNode by changing the breadcrumbs
            dispatch({ type: "CHANGE_BREADCRUMBS", payload: breadcrumbs })
        }

        switch (treeType) {
            case "vehicleParts": {
                const request: RequestArticleListPayload = {
                    productGroups: {
                        ids: productGroups.map((x) => x.id).orderBy((x) => x),
                        treeId: getTreeIdFromCategoryType(treeType, vehicle?.vehicleType),
                        nodeId: breadcrumbs.last()?.id,
                    },
                }

                channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
                break
            }
            case "universalParts": {
                const request: RequestArticleListPayload = {
                    uniProductGroups: { ids: productGroups.map((x) => x.id).orderBy((x) => x) },
                }
                channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
                break
            }
            default:
                break
        }
    }
}

function resetSelection(): ComponentActionType {
    return { type: "RESET_SELECTION" }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setClipped,
    setConfigProps,
    loadSearchTreeNodes,
    changeBreadcrumbs,
    applyProductGroups,
    resetSelection,
    loadSearchTreeNodesWithTreeParams,
}
