import { useEffect, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Badge, Headline, Icon, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { bem, classes } from "@tm/utils"
import { maintenanceReviewActions, stepNavigationActions, useFastServiceStore } from "../../data"
import { SelectedItems } from "../../data/models"
import { ActiveButtons } from "../../models"
import { CustomPanel, NextStep, TitleHeader } from "../_shared"
import { getComponentStyles } from "../_shared/styles"

export default function MaintenanceReview() {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const { activeFilterButton, selectedWorks } = useFastServiceStore((state) => state.maintenanceReview)
    const activeStep = useFastServiceStore((state) => state.navigation.active)
    const classNames = getComponentStyles()

    const handleFilterButtonsClick = (stateButton: ActiveButtons) => {
        maintenanceReviewActions.filterData(stateButton)
    }

    useEffect(() => {
        if (activeStep) {
            stepNavigationActions.updateNextStep(activeStep)
            stepNavigationActions.completeStep(activeStep)
        }
    }, [activeStep])

    const renderBadgeIcons = (value: SelectedItems) => {
        return (
            <div className={style.buttonsWrapper}>
                {value.selectedGenArts && (
                    <div className={style.readOnlyButton}>
                        <Icon name="article" />
                        <Badge skin="dark" value={value.selectedGenArts.length} />
                    </div>
                )}
                {value.selectedRepairTimes && (
                    <div className={style.readOnlyButton}>
                        <Icon name="repairtimes" />
                        <Badge skin="dark" value={value.selectedRepairTimes.length} />
                    </div>
                )}
                {/* {value.selectedConsumables && <Icon name={"repairtimes"}><Badge skin="dark" value={value.selectedRepairTimes?.length} /></Icon>} */}
            </div>
        )
    }

    const renderPanelTitle = (title: string, data: SelectedItems) => {
        return (
            <>
                <TitleHeader
                    title={title}
                    reandOnlyIcons={renderBadgeIcons(data)}
                    selectedStatusButton={data.selectedStatusButton}
                    className={style.header}
                    disableStatusButtonClick
                />
            </>
        )
    }

    const renderPanels = ([label, data]: [string, SelectedItems], idx: number) => {
        if (data.selectedStatusButton === "none") {
            return null
        }

        return <CustomPanel key={idx} title={renderPanelTitle(label, data)} initiallyClosed />
    }

    const renderFilterButtons = () => {
        return (
            <div className={classes(classNames.flexContainerWithGap, style.filterButtonsWrapper)}>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "none")}
                    className={bem(style.filtersButtons, activeFilterButton === "none" && "selected")}
                    size="l"
                >
                    Alle
                </Text>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "defectButton")}
                    className={bem(style.filtersButtons, activeFilterButton === "defectButton" && "selected")}
                    size="l"
                >
                    Mangel
                </Text>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "repairedButton")}
                    className={bem(style.filtersButtons, activeFilterButton === "repairedButton" && "selected")}
                    size="l"
                >
                    Reparatur
                </Text>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "checkButton")}
                    className={bem(style.filtersButtons, activeFilterButton === "checkButton" && "selected")}
                    size="l"
                >
                    OK
                </Text>
            </div>
        )
    }

    const renderContent = () => {
        if (!selectedWorks.size) {
            return (
                <div className="article-list__panel article-list__status">
                    <div className="article-list__no-result">
                        <Headline size="xs">{translateText(12775)}</Headline>
                        <Text size="s" modifiers={["block"]}>
                            {translateText(918)}
                        </Text>
                    </div>
                </div>
            )
        }

        return (
            <>
                {Array.from(selectedWorks, ([key, items], idx) => (
                    <div key={idx} className={style.wrapper}>
                        <Headline size="xs" className={style.headline}>
                            {translateText(key)}
                        </Headline>
                        {Object.entries(items).map(renderPanels)}
                    </div>
                ))}
            </>
        )
    }

    return (
        <>
            {renderFilterButtons()}
            <Scrollbar>
                <div className={classes(classNames.wrapperMargins, classNames.wrapperMinHeight)}>{renderContent()}</div>

                {activeStep === "maintenanceReview" && <NextStep currentStepName="maintenanceReview" buttonTextId={414} icon="arrows_down" active />}
                {activeStep === "inspection" && <NextStep currentStepName="inspection" buttonTextId={3113} icon="arrows_down" active />}
            </Scrollbar>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            padding: "0 1em",
        },
        headline: {
            paddingTop: theme.margin.m,
            paddingBottom: theme.margin.m,
        },
        buttonsWrapper: {
            display: "flex",
        },
        readOnlyButton: {
            position: "relative",
            marginRight: theme.margin.s,
        },
        header: {
            justifyContent: "space-between",
        },
        filterButtonsWrapper: {
            justifyContent: "flex-end",
        },
        filtersButtons: {
            cursor: "pointer",
            borderBottom: "3px solid",
            borderColor: theme.colors.dark,
            padding: theme.margin.xs,
            borderRadius: theme.radius?.default,
            opacity: theme.opacity.secondary,
            $nest: {
                "&:hover": {
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                    opacity: theme.opacity.primary,
                },
                "&--selected": {
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                    opacity: theme.opacity.primary,
                },
            },
        },
    })(MaintenanceReview)
}
