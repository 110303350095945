import { ChangeEvent, KeyboardEvent, FocusEvent, useCallback, useEffect, useRef, useState, useMemo, CSSProperties } from "react"
import { TextField, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import AmountInfo from "./AmountInfo"

const MAX_AMOUNT_DEFAULT = 999
const MAX_AMOUNT_FROM_10 = 9999

type AddToBasketAmountFieldProps = {
    originalQuantity: number
    division: number
    handleChangeQuantity: (value: number, loadErpInfo?: boolean) => void
    hasSuggestedQuantity?: boolean
}

const AmountField = styled(TextField)({
    marginTop: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ".MuiInputBase-formControl": {
        paddingRight: 0,
    },
    ".MuiInputAdornment-positionEnd": {
        marginLeft: 0,
    },
})

export function AddToBasketAmountField(props: AddToBasketAmountFieldProps) {
    const { division, originalQuantity, handleChangeQuantity, hasSuggestedQuantity } = props

    const { translateText } = useLocalization()

    const [quantity, setQuantity] = useState(division ? originalQuantity.ceil(division) : originalQuantity)

    const inputRef = useRef<HTMLInputElement>()
    const keyPressed = useRef<string>()

    useEffect(() => {
        const ceilQuantity = originalQuantity.ceil(division)
        setQuantity(ceilQuantity)
        handleChangeQuantity(ceilQuantity, false)
    }, [originalQuantity, division, handleChangeQuantity])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.value) {
                setTimeout(() => {
                    e.target.value = originalQuantity.toString()
                    e.target.select()
                }, 0)
                return
            }

            setQuantity(parseInt(e.target.value))
        },
        [originalQuantity]
    )

    const handleBlur = useCallback(() => {
        if (keyPressed.current === "Escape") {
            setQuantity(originalQuantity.ceil(division))
            return
        }

        let noZeroQuantity = quantity || 1

        if (division > 1) {
            noZeroQuantity = noZeroQuantity.ceil(division)
        }

        setQuantity(noZeroQuantity)

        if (originalQuantity !== noZeroQuantity) {
            handleChangeQuantity(noZeroQuantity)
        }
    }, [handleChangeQuantity, originalQuantity, division, quantity])

    const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
        e.target.select()
    }, [])

    const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        keyPressed.current = e.code
        switch (e.code) {
            case "Escape":
            case "NumpadEnter":
            case "Enter": {
                ;(e.target as HTMLInputElement).blur()
                break
            }
            default:
        }
    }, [])

    const maxAmountValue = useMemo(() => {
        let maxValue = MAX_AMOUNT_DEFAULT
        if (division >= 10) {
            maxValue = MAX_AMOUNT_FROM_10
        }
        return maxValue - (maxValue % division)
    }, [division])

    const amountInfo = useMemo(() => {
        if (division > 1) {
            return translateText(13627)
        }

        if (hasSuggestedQuantity) {
            return translateText(13670)
        }
    }, [division, hasSuggestedQuantity, translateText])

    const inputStyle = useMemo<CSSProperties>(
        () => ({
            boxSizing: "border-box",
            height: 30,
            padding: 8,
            ...(amountInfo ? { paddingRight: 0, width: 58 } : { paddingRight: 4, width: 82 }),
        }),
        [amountInfo]
    )

    return (
        <AmountField
            inputRef={inputRef}
            type="number"
            inputProps={{
                min: division,
                max: maxAmountValue,
                step: division,
                style: inputStyle,
                onScroll: (e) => e.stopPropagation(),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                endAdornment: !!amountInfo && <AmountInfo text={amountInfo} />,
            }}
            value={quantity}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    )
}
