import { Article } from "@tm/models"
import { useMemo } from "react"
import { usePartsModuleState } from "../../PartsModuleState"
import { useDisableReason } from "./useDisableReason"
import { ArticleOptions } from "../../models"

export function useArticleOptions(article: Article): ArticleOptions {
    const options = usePartsModuleState((x) => x.options)
    const disableReason = useDisableReason(article)

    return useMemo(
        () => ({
            ...options,
            disableReason,
        }),
        [options, disableReason]
    )
}
