import { Article } from "@tm/models"
import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import { DefectGenart, Genart } from "../../models"
import { EndPageSlice, EndPageState } from "./model"
import { FSArticle } from "../../repositories/fastcalculator-calculation/mapper"

function getInitialState(): EndPageState {
    return {
        defectGenarts: [],
        selectedArticles: [],
        selectedOeArticles: [],
        articlesLoading: false,
        selectedRepairTimes: [],
        printLoading: false,
    }
}

export const createEndPageSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], EndPageSlice> = (set) => ({
    ...getInitialState(),
    reset: () => set(getInitialState(), false, "Reset End Page Slice"),
    setUpdateEndPageDefects: (genArt: Genart, id: string) =>
        set((state) => setUpdateEndPageDefects(state, genArt, id), false, "Update end page defects"),
    setUpdateStatusGenart: (status: number, id: string) => set((state) => setUpdateStatusGenart(state, status, id), false, "Update status genart"),
    setUpdateSelectedArticles: (article: Article) => set((state) => setUpdateSelectedArticles(state, article), false, "Update selected articles"),
    setUpdateArticleQuantity: (article: Article, quantity: number) =>
        set((state) => setUpdateArticleQuantity(state, article, quantity), false, "Set article quantity"),
    setUpdateOeArticle: (fsArticle: FSArticle, quantity: number, price: number, designation: string) =>
        set((state) => setUpdateOeArticle(state, fsArticle, quantity, price, designation), false, "Update OE article"),
    setReplaceArticle: (oldArticle, newArticle) => set((state) => setReplaceArticle(state, oldArticle, newArticle), false, "Replace article"),
    setPrintLoading: () => set((state) => setPrintLoading(state), false, "Set pdf print loading true"),
    setPrintFailed: () => set((state) => setPrintFailed(state), false, "Pdf print failed"),
    setPrintLoaded: (printUrl) => set((state) => setPrintLoaded(state, printUrl), false, "Pdf print loaded"),
    setLoadedArticles: (articles: Article[]) => set((state) => setLoadedArticles(state, articles), false, "Set Loaded articles"),
    setLoadingArticles: () => set((state) => setLoadingArticles(state), false, "Set loading articles"),
    setErrorArticles: () => set((state) => setErrorArticles(state), false, "Set error articles"),
})

function setLoadedArticles(state: EndPageSlice, articles: Article[]): Partial<EndPageSlice> {
    return {
        selectedArticles: articles,
        articlesLoading: false,
    }
}

function setErrorArticles(state: EndPageSlice): Partial<EndPageSlice> {
    return {
        articlesLoading: false,
    }
}

function setLoadingArticles(state: EndPageSlice): Partial<EndPageSlice> {
    return {
        articlesLoading: true,
    }
}

function setPrintLoaded(state: EndPageSlice, printUrl: string): Partial<EndPageSlice> {
    return {
        printLoading: false,
        pdfUrl: printUrl,
    }
}

function setPrintFailed(state: EndPageSlice): Partial<EndPageSlice> {
    return {
        printLoading: false,
    }
}

function setPrintLoading(state: EndPageSlice): Partial<EndPageSlice> {
    return {
        printLoading: true,
    }
}

function setUpdateSelectedArticles(state: EndPageSlice, article: Article): Partial<EndPageSlice> {
    const newArticles = addOrRemoveItem([...state.selectedArticles], article)
    return {
        selectedArticles: newArticles,
    }
}

function setUpdateArticleQuantity(state: EndPageSlice, article: Article, quanity: number): Partial<EndPageSlice> {
    const selectedArticleList = [...state.selectedArticles]
    const changedArticle = selectedArticleList.find((a) => a.id === article.id)
    if (changedArticle) {
        changedArticle.quantity = quanity
    }

    return {
        selectedArticles: selectedArticleList,
    }
}

function setUpdateOeArticle(
    state: FastServiceStore,
    fsArticle: FSArticle,
    quanity: number,
    price: number,
    designation: string
): Partial<EndPageSlice> {
    const selectedOeArticleList = state.worksState.selectedItems
    const changedOeArticle = Object.entries(selectedOeArticleList)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_, value]) =>
            Object.values(value)
                .map((x) => x.selectedGenArts)
                .flat()
                .filter((x) => !!x)
        )
        ?.flat()

    if (changedOeArticle) {
        changedOeArticle.forEach((oeArticle) => {
            if (oeArticle?.fsArticle && oeArticle.fsArticle.id === fsArticle.id) {
                oeArticle.fsArticle.quantity = quanity
                oeArticle.fsArticle.price = price
                oeArticle.fsArticle.oeLabel = designation
            }
        })
    }

    return {
        selectedOeArticles: [selectedOeArticleList],
    }
}

function setReplaceArticle(state: EndPageSlice, oldArticle: Article, newArticle: Article): Partial<EndPageSlice> {
    const filteredArticles = state.selectedArticles.filter((a) => a.id !== oldArticle.id)
    const newArticleList = addOrRemoveItem([...filteredArticles], newArticle)

    return {
        selectedArticles: newArticleList,
    }
}

function setUpdateStatusGenart(state: EndPageSlice, status: number, id: string): Partial<EndPageSlice> {
    const newItems = state.defectGenarts.map((defect) => ({ ...defect, ...(defect.id === id && { status, id }) }))

    return {
        defectGenarts: newItems,
    }
}

function setUpdateEndPageDefects(state: EndPageSlice, genArt: Genart, id: string): Partial<EndPageSlice> {
    const item = { ...genArt, status: 0, id }

    const comparator = (x: DefectGenart, y: DefectGenart) => x.id === y.id && x.genArtNr === y.genArtNr
    const newItems = addOrRemoveItem([...state.defectGenarts], item, comparator)

    return {
        defectGenarts: newItems,
    }
}
