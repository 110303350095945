import {
    PartItem,
    WorkItem,
    CostEstimationVoucherIncludedWork,
    CreateCostEstimationRequest,
    MileType,
    RegistrationNoType,
    VatRateType,
    VatRate,
    ArticleInfoType,
    VatAmount,
} from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { CostEstimation, IncludedWorkItem, ArticleInformation, CostEstimationTotals } from "../model"
import { OfferState } from "../../components/cost-estimation/business"
import { BasketPart, BasketWork } from "../../models"

function mapVatRate(vatRate?: VatRate): VatRate | undefined {
    return {
        vatType: vatRate?.vatType ?? VatRateType.None,
        // TODO: Remove this mapper when the voucher service supports percentages in the requests
        vatRate: vatRate?.vatRate ? vatRate.vatRate / 100 : 0,
    }
}

function mapSupplier(articleInformation: ArticleInformation) {
    return articleInformation.supplierId && articleInformation.supplierName
        ? {
              id: articleInformation.supplierId,
              name: articleInformation.supplierName,
          }
        : undefined
}

function mapBasketPartsToPartItems(costEstimationParts?: BasketPart[]): PartItem[] {
    const voucherParts: PartItem[] = []
    costEstimationParts?.forEach((part) => {
        const { partItem, calculatedPart } = part
        const { articleInformation, costEstimationItem } = partItem
        if (costEstimationItem?.isIncluded) {
            voucherParts.push({
                additionalDescription: articleInformation.additionalDescription,
                articleType: articleInformation.articleInfoType,
                cummulatedQuotationPrice: calculatedPart?.offerPriceTotal ?? 0,
                currencyCode: costEstimationItem?.currencyCode ?? "-",
                currencySymbol: costEstimationItem?.currencySymbol ?? "-",
                dataSupplier: mapSupplier(partItem.articleInformation),
                articleNumber: articleInformation.articleNumber,
                description: articleInformation.description,
                productGroup: {
                    id: articleInformation.productGroupId,
                    name: articleInformation.productGroupName,
                },
                quantity: partItem.quantity,
                quotationPrice: calculatedPart?.regularPriceWithSurcharge ?? 0, // Quickfix for NEXT-6454 and NEXT-14739 in the Service the property quotationPrice must be renamed to garagePriceWithSurcharge
                rebate: costEstimationItem?.rebate ? costEstimationItem?.rebate / 100 : 0, // TODO Basket V2: Remove this mapper when the voucher service supports percentages in the requests
                vatRate: mapVatRate(calculatedPart?.vatRate),
                wholesalerArticleNumber: articleInformation.wholesalerArticleNumber,
                oeArticleVehicleManufacturer:
                    articleInformation.articleInfoType === ArticleInfoType.OeArticle ? mapSupplier(articleInformation) : undefined,
                wholesalerArticleManufacturerName:
                    articleInformation.articleInfoType === ArticleInfoType.WholesalerArticle ? articleInformation.supplierName : undefined,
                wholesalerArticleProductGroupName:
                    articleInformation.articleInfoType === ArticleInfoType.WholesalerArticle ? articleInformation.productGroupName : undefined,
                itemRole: partItem.itemRole,
                linkedItems: mapBasketPartsToPartItems(part.linkedItems),
                mainPartItemId: partItem.mainPartId,
            })
        }
    })

    return voucherParts
}

function mapIncludedWorkItemsToVoucherIncludedWorks(includedWorks?: IncludedWorkItem[]): CostEstimationVoucherIncludedWork[] {
    const includedVoucherWorks: CostEstimationVoucherIncludedWork[] = []
    includedWorks?.forEach((includedWork) => {
        includedVoucherWorks.push({
            categoryOfWork: includedWork.category,
            description: includedWork.description,
            providerWorkId: includedWork.providerWorkId,
            time: includedWork.displayTime?.value ?? 0,
        })
    })
    return includedVoucherWorks
}

function mapBasketWorksToWorkItems(works?: BasketWork[]): WorkItem[] {
    const voucherWorks: WorkItem[] = []

    works?.forEach((work) => {
        const { workItem, states, calculatedWork, estimatedWork } = work
        if (work.workItem.isIncluded) {
            voucherWorks.push({
                categoryOfWork: workItem.category,
                categoryOfWorkDescription: "",
                categoryOfWorkShortCode: workItem.categoryShortCode,
                currencyCode: workItem.currencyCode,
                currencySymbol: workItem.currencySymbol ?? "-",
                description: estimatedWork?.description ?? workItem.description,
                fixedPriceValue: workItem.fixedPriceValue,
                hourlyRate: calculatedWork?.hourlyRateWithSurcharge, // Quickfix for NEXT-14739 in the Service the property hourlyRate must be renamed to hourlyRateWithSurcharge
                includedWorks: mapIncludedWorkItemsToVoucherIncludedWorks(workItem.includedWorks),
                providerWorkId: workItem.providerWorkId,
                rebate: workItem.rebate ? workItem.rebate / 100 : 0, // TODO: Remove this mapper when the voucher service supports percentages in the requests
                repairTimeProvider: workItem.provider,
                repairTimeProviderName: workItem.providerName,
                time: estimatedWork?.displayTime?.value,
                timeCalculated: calculatedWork?.repairTimeCalculated ?? estimatedWork?.displayTime?.calculatedValue,
                workPriceCalculated: calculatedWork?.offerPrice ?? 0,
                workType: workItem.type,
                printIncludedWorks: states.isExpanded || false,
                vatRate: mapVatRate(calculatedWork?.vatRate),
            })
        }
    })

    return voucherWorks
}

// TODO: Remove this mapper when the voucher service supports percentages in the requests
function mapVatAmounts(vatAmounts?: VatAmount[]): VatAmount[] {
    const mappedVatAmounts: VatAmount[] = []
    vatAmounts?.forEach((vatAmount) => {
        mappedVatAmounts.push({
            ...vatAmount,
            vatRate: vatAmount.vatRate / 100,
        })
    })
    return mappedVatAmounts
}

export function mapCreateCostEstimationRequest(
    workTask: WorkTaskInfo,
    state: OfferState,
    repairTimeDivision: number,
    currencyCode: string,
    currencySymbol: string,
    registrationNoLabel?: string,
    costEstimation?: CostEstimation,
    costEstimationParts?: BasketPart[],
    works?: BasketWork[],
    totals?: CostEstimationTotals
): Omit<CreateCostEstimationRequest, "id"> {
    const { vehicle } = workTask
    return {
        workTaskId: workTask.id,
        workTaskNumber: workTask.no,
        customer: workTask.customer
            ? {
                  id: workTask.customer.id,
                  city: workTask.customer.city ?? "",
                  title: workTask.customer.title,
                  academicTitle: workTask.customer.academicTitle,
                  titleType: workTask.customer.titleType,
                  companyName: workTask.customer.companyName ?? "",
                  country: workTask.customer.country ?? "",
                  customerNumber: workTask.customer.customerNo ?? "",
                  eMailAddress: workTask.customer.email ?? "",
                  firstName: workTask.customer.firstName ?? "",
                  lastName: workTask.customer.lastName ?? "",
                  phone: workTask.customer.phone ?? "",
                  street: workTask.customer.street ?? "",
                  zip: workTask.customer.zip ?? "",
              }
            : undefined,
        vehicle: vehicle
            ? {
                  displayName: `${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`,
                  id: vehicle.id,
                  initialRegistration: vehicle.initialRegistration,
                  mileType: vehicle.mileType ?? MileType.Kilometer,
                  mileage: vehicle.mileAge,
                  plateId: vehicle.plateId ?? "",
                  vin: vehicle.vin ?? "",
                  vehicleImage: costEstimation?.vehicleImage,
                  registrationNo: vehicle.registrationNo ?? "",
                  registrationNoLabel: registrationNoLabel ?? "",
                  registrationNoType: vehicle.registrationTypeId ?? RegistrationNoType.Kba,
              }
            : undefined,
        calculatedParts: mapBasketPartsToPartItems(costEstimationParts),
        calculatedWorks: mapBasketWorksToWorkItems(works),
        repairTimeDivision,
        workSchedule: {
            employeeInCharge: state.offerDetails.clientAdvisor,
            performingEmployee: state.offerDetails.mechanic,
            expectedCompletionDate: state.offerDetails.dueDate,
            referenceNumber: state.offerDetails.referenceNumber,
        },
        validityPeriod: {
            fromDate: state.offerDetails.startDate ? state.offerDetails.startDate : new Date(),
            toDate: state.offerDetails.endDate,
        },
        totals: {
            currencyCode,
            currencySymbol,
            vatAmounts: mapVatAmounts(totals?.vatAmounts),
            totalPartsCosts: totals?.partCosts ?? 0,
            totalAlreadyTaxedItems: totals?.alreadyTaxedTotalCosts,
            totalQuotationPriceVatExcluded: totals?.offerPriceVatExcluded ?? 0,
            totalQuotationPriceVatIncluded: totals?.offerPriceVatIncluded ?? 0,
            totalWorksCosts: totals?.workCosts ?? 0,
            totalWorkTime: totals?.repairTimeTotal ?? 0,
            hasFixedPrice: false,
        },
        memo: state.note,
        printOptions: {
            showSupplierArticleNumbers: state.showSupplierArticleNumbers,
            showWholesalerArticleNumbers: state.showWholesalerArticleNumbers,
            showManufacturer: state.showManufacturer,
            showVehicleImage: state.printVehicleImage,
        },
    }
}
