import { AsyncAction } from "@tm/morpheus"
import { NavigationState, ComponentActionType } from "."
import * as Data from "../../../data"
import { PostCreateActionType } from "../../../business"

function loadExportInfos(workTaskId: string): AsyncAction<ComponentActionType, NavigationState> {
    return (dispatch) => {
        Data.getExportVoucherInfos(workTaskId).then((vi) => {
            dispatch({ type: "EXPORT_URI_CHANGED", payload: { uri: vi.uri, exportLabel: vi.exportLabel } })
        })
    }
}

function closeWorkTask(workTaskId: string): AsyncAction<ComponentActionType, NavigationState> {
    return (dispatch) => {
        dispatch({ type: "CLOSE_WORKTASK", payload: workTaskId })
    }
}

function printCostEstimation(): ComponentActionType {
    return { type: "START_CREATE_COST_ESTIMATION", payload: { postCreateAction: PostCreateActionType.ShowPdf } }
}

function sendCostEstimation(): ComponentActionType {
    return { type: "START_CREATE_COST_ESTIMATION", payload: { postCreateAction: PostCreateActionType.SendChatMessage } }
}

function generateCostEstimationLink(): ComponentActionType {
    return { type: "START_CREATE_COST_ESTIMATION", payload: { postCreateAction: PostCreateActionType.PublishLink } }
}

function toggleShowArticleNumbers(state: boolean): ComponentActionType {
    return { type: "TOGGLE_SHOW_ARTICLE_NUMBERS", payload: { state } }
}

function toggleShowWholesalerArticleNumbers(state: boolean): ComponentActionType {
    return { type: "TOGGLE_SHOW_WHOLESALER_ARTICLE_NUMBERS", payload: { state } }
}

function toggleShowManufacturers(state: boolean): ComponentActionType {
    return { type: "TOGGLE_SHOW_MANUFACTURERS", payload: { state } }
}

function togglePrintVehicleImage(state: boolean): ComponentActionType {
    return { type: "TOGGLE_PRINT_VEHICLE_IMAGE", payload: { state } }
}

export type IActions = typeof Actions

export const Actions = {
    loadExportInfos,
    closeWorkTask,
    printCostEstimation,
    sendCostEstimation,
    generateCostEstimationLink,
    toggleShowArticleNumbers,
    toggleShowWholesalerArticleNumbers,
    toggleShowManufacturers,
    togglePrintVehicleImage,
}
