import { ajax, getStoredAuthorization } from "@tm/utils"
import { NavigationTreeResponse, GroupsTreeResponse, TokenRequest } from "../../models"
import { getBundleParams } from "../../utils"

export function getNavigationTree(token: string): Promise<NavigationTreeResponse | undefined> {
    const url = `${getBaseUrl()}/GetNavigationTree`
    const authorization = getStoredAuthorization()
    const body: TokenRequest = { token }
    return ajax<NavigationTreeResponse>({ url, body, authorization, method: "POST" })
}

export function getGroups(token: string): Promise<GroupsTreeResponse | undefined> {
    const url = `${getBaseUrl()}/GetGroups`
    const authorization = getStoredAuthorization()
    const body: TokenRequest = { token }
    return ajax<GroupsTreeResponse>({ url, body, authorization, method: "POST" })
}

function getBaseUrl(): string {
    return getBundleParams().edsServiceUrl
}
