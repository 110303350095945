import { memo } from "react"
import { ModelState } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { UpdateRepairShopRequest } from "@tm/context-distribution"
import { Box } from "@tm/components"
import { TextField, Text, DropdownField } from "@tm/controls"

import { Street } from "../../../../data/model"
import AddressSuggestBox from "../AddressSuggestBox"
import { useTelesalesModule } from "../../../../data/hooks/useTelesalesModule"

type Props = {
    addressSuggestions?: Array<Street>
    addressSuggestionsLoading: boolean
    modelState: ModelState
    repairShop: UpdateRepairShopRequest
    resetAddressSuggestions(): void
    handleChange(model: UpdateRepairShopRequest): void
    handleAddressChange(model: UpdateRepairShopRequest): void
    handleSelectSuggestion(street: Street): void
}

export const LocationFormComponent = memo<Props>(
    ({
        addressSuggestionsLoading,
        addressSuggestions,
        handleSelectSuggestion,
        modelState,
        handleAddressChange,
        resetAddressSuggestions,
        repairShop,
        handleChange,
    }) => {
        const { translateText } = useLocalization()
        const telesalesModule = useTelesalesModule()

        const contactPersonGenderOptions = {
            1: translateText(145),
            2: translateText(146),
        }

        return (
            <>
                <div className="user-settings__area__row">
                    <TextField model={repairShop} path={["companyName"]} onChange={handleChange} modelState={modelState} label={translateText(108)} />
                    <TextField
                        model={repairShop}
                        path={["contactInfo", "webPage"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(822)}
                    />
                </div>
                <div className="user-settings__area__row">
                    <AddressSuggestBox
                        list={addressSuggestions}
                        loading={addressSuggestionsLoading}
                        onSelect={handleSelectSuggestion}
                        resetAddressSuggestions={resetAddressSuggestions}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["postalAddress", "street"]}
                        onChange={handleAddressChange}
                        modelState={modelState}
                        className="user-settings__location__address-field address-field"
                        label={translateText(111)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["postalAddress", "zip"]}
                        onChange={handleAddressChange}
                        modelState={modelState}
                        className="user-settings__location__address-field address-field"
                        label={translateText(112)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["postalAddress", "city"]}
                        onChange={handleAddressChange}
                        modelState={modelState}
                        className="user-settings__location__address-field address-field"
                        label={translateText(113)}
                    />
                </div>
                <div className="user-settings__area__row">
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["contactInfo", "phone"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(110)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["contactInfo", "fax"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(121)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["contactInfo", "email"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(109)}
                    />
                </div>
                <div className="user-settings__area__row">
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["postalAddress", "country"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(114)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["taxNumber"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(13231)}
                    />
                </div>
                <div className="user-settings__area__row">
                    <Text>{translateText(950)}:</Text>
                </div>
                <div className="user-settings__area__row">
                    <DropdownField
                        items={contactPersonGenderOptions}
                        model={repairShop}
                        path={["contactPerson", "title"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(118)}
                        floatingLabel
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["contactPerson", "firstName"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(119)}
                    />
                    <TextField
                        autoComplete="0"
                        model={repairShop}
                        path={["contactPerson", "lastName"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={translateText(104)}
                    />
                </div>
                {telesalesModule && (
                    <div className="user-settings__area__row">
                        <TextField
                            autoComplete="0"
                            model={repairShop}
                            path={["contactPerson", "phone"]}
                            onChange={handleChange}
                            modelState={modelState}
                            label={translateText(110)}
                        />
                        <TextField
                            autoComplete="0"
                            model={repairShop}
                            path={["contactPerson", "email"]}
                            onChange={handleChange}
                            modelState={modelState}
                            label={translateText(109)}
                        />
                        <Box flex={1} />
                    </div>
                )}
            </>
        )
    }
)
