import { ActionDispatch, PayloadAction } from "@tm/morpheus"
import { ShowCostEstimationDetailsResponse, Vehicle } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { OfferState } from "./model"
import { ShowCostEstimationTotalNumbersResponse } from "../../../data/model"
import { BundleActionType, bundleChannel } from "../../../business"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "COST_ESTIMATION_DETAILS_LOADED"; payload: ShowCostEstimationDetailsResponse }
    | { type: "COST_ESTIMATION_NOTE_MODIFIED"; payload: string }
    | { type: "COST_ESTIMATION_TOTAL_NUMBERS_LOADED"; payload: { totalNumbers: ShowCostEstimationTotalNumbersResponse } }
    | { type: "COST_ESTIMATION_TOTAL_NUMBERS_LOADING" }
    | { type: "EXPAND_WORKS"; payload: { workIds: Array<string> } }
    | { type: "OFFER_DETAILS_CLEARED" }
    | { type: "SET_WORKTASK_INFO"; payload: WorkTaskInfo }
    | { type: "SHOW_HIDE_WORK_INCLUDES"; payload: { workId: string } }
    | { type: "VEHICLE_OVERVIEW_LOADED"; payload: Array<Vehicle> }

const DEFAULT_STATE: OfferState = {
    costEstimationDetailsLoading: false,
    offerDetails: {
        mechanic: "",
        clientAdvisor: "",
        referenceNumber: "",
    },
    note: "",
    showSupplierArticleNumbers: true,
    showWholesalerArticleNumbers: true,
    showManufacturer: true,
    printVehicleImage: true,
}

// TODO: state is mutated in this reducer! A big refactoring should be done.
export function reduce(state = DEFAULT_STATE, action: ComponentActionType): OfferState {
    switch (action.type) {
        case "SET_WORKTASK_INFO": {
            return {
                ...state,
                workTask: action.payload,
                vehicle: action.payload.vehicle,
            }
        }
        case "COST_ESTIMATION_DETAILS_LOADED": {
            const { clientAdvisorName, mechanicName, dueDate, endDate, startDate, memo, referenceNumber, version } = action.payload
            return {
                ...state,
                costEstimationDetailsLoading: false,
                offerDetails: {
                    clientAdvisor: clientAdvisorName,
                    dueDate,
                    endDate,
                    mechanic: mechanicName,
                    referenceNumber,
                    startDate,
                    version,
                },
                note: memo,
            }
        }
        case "COST_ESTIMATION_NOTE_MODIFIED":
            return {
                ...state,
                note: action.payload,
            }

        case "VEHICLE_OVERVIEW_LOADED": {
            const vehicles = action.payload
            if (!vehicles) {
                break
            }
            const vehicle = vehicles[0]

            return {
                ...state,
                vehicle,
            }
        }
        case "OFFER_DETAILS_CLEARED": {
            return {
                ...state,
                offerDetails: {
                    mechanic: "",
                    clientAdvisor: "",
                    referenceNumber: "",
                    startDate: undefined,
                    endDate: undefined,
                    dueDate: undefined,
                },
            }
        }
        case "TOGGLE_SHOW_ARTICLE_NUMBERS": {
            return {
                ...state,
                showSupplierArticleNumbers: action.payload.state,
            }
        }
        case "TOGGLE_SHOW_WHOLESALER_ARTICLE_NUMBERS": {
            return {
                ...state,
                showWholesalerArticleNumbers: action.payload.state,
            }
        }
        case "TOGGLE_PRINT_VEHICLE_IMAGE": {
            return {
                ...state,
                printVehicleImage: action.payload.state,
            }
        }
        case "TOGGLE_SHOW_MANUFACTURERS": {
            return {
                ...state,
                showManufacturer: action.payload.state,
            }
        }
        // case "SHOW_HIDE_WORK_INCLUDES": {
        //     const { workId } = action.payload
        //     const works = state.works.map((w) => ({
        //         ...w,
        //         isExpanded: w.id === workId ? !w.isExpanded : w.isExpanded,
        //     }))
        //     return {
        //         ...state,
        //         works,
        //     }
        // }
        // case "EXPAND_WORKS": {
        //     const { workIds } = action.payload
        //     const works = state.works.map((w) => ({
        //         ...w,
        //         isExpanded: !!workIds.find((id) => id === w.repairTimeProviderWorkId && w.includedWorks.length),
        //     }))

        //     return {
        //         ...state,
        //         works,
        //     }
        // }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType, OfferState>, getState: () => OfferState) {
    switch (action.type) {
        case "VEHICLE_OVERVIEW_LOADED":
        case "TOGGLE_SHOW_ARTICLE_NUMBERS":
        case "TOGGLE_SHOW_WHOLESALER_ARTICLE_NUMBERS":
        case "TOGGLE_PRINT_VEHICLE_IMAGE":
        case "TOGGLE_SHOW_MANUFACTURERS": {
            dispatch(action)
            break
        }
        case "START_CREATE_COST_ESTIMATION": {
            bundleChannel().publish("CREATE_COST_ESTIMATION", {
                postCreateAction: action.payload.postCreateAction,
            })

            break
        }
        default:
            break
    }
}

export function transmit(action: PayloadAction<ComponentActionType>): PayloadAction<ComponentActionType> | undefined {
    switch (action.type) {
        case "CREATE_COST_ESTIMATION": {
            return action
        }
        default:
            break
    }
}
