import { getStoredAuthorization, ajax } from "@tm/utils"
import { CreateCostEstimationRequest } from "@tm/models"
import {
    ShowAllRequest,
    ShowByArticleRequest,
    ShowByCustomerRequest,
    ShowByVehiclesRequest,
    ShowCostEstimationDetailsResponse,
    ShowCostEstimationsResponse,
} from "../../model"
import { mapShowCostEstimationDetailsResponse } from "../../mapper"
import { getBundleParams } from "../../../utils"

function getServiceUrl(): string {
    return `${getBundleParams().vouchersServiceUrl}/Vouchers`
}

export function createCostEstimation(body: CreateCostEstimationRequest): Promise<void> {
    const url = `${getServiceUrl()}/Create`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function showCostEstimationsByVehicles(body: ShowByVehiclesRequest) {
    const url = `${getServiceUrl()}/ShowByVehicles`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true).then(
        (response) => response?.costEstimations
    )
}

export function showCostEstimationsByCustomer(body: ShowByCustomerRequest) {
    const url = `${getServiceUrl()}/ShowByCustomer`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showCostEstimationsByArticle(body: ShowByArticleRequest) {
    const url = `${getServiceUrl()}/ShowByArticle`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showAllCostEstimations(body: ShowAllRequest) {
    const url = `${getServiceUrl()}/ShowAll`
    const authorization = getStoredAuthorization()

    return ajax<ShowCostEstimationsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) => response?.costEstimations)
}

export function showCostEstimationDetails(id: string) {
    const url = `${getServiceUrl()}/ShowDetails`
    const authorization = getStoredAuthorization()
    const body = { id }

    return ajax<ShowCostEstimationDetailsResponse>({ url, body, authorization }, undefined, undefined, true).then((response) =>
        mapShowCostEstimationDetailsResponse(response)
    )
}

export function showCostEstimationPdf(costEstimationId: string): Promise<Array<number> | undefined> {
    const url = `${getServiceUrl()}/ShowDetailsPdf`
    const authorization = getStoredAuthorization()
    const body = { id: costEstimationId }

    return ajax({ url, body, authorization, responseType: "blob" })
}

export function deleteCostEstimations(costEstimationIds: Array<string>) {
    const url = `${getServiceUrl()}/Delete`
    const authorization = getStoredAuthorization()
    const body = { ids: costEstimationIds }

    return ajax({ url, body, authorization, method: "POST" })
}
