import { Box, FastClickBadge, styled } from "@tm/components"
import { withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { Button, Icon, Text } from "@tm/controls"
import { TreeNode, VehicleType } from "@tm/models"
import { classes, getUIA } from "@tm/utils"
import * as React from "react"

type Props = WithWorkTaskProps & {
    treeId: number | undefined
    node: TreeNode
    className?: string
    isSelected?: boolean
    size: "s" | "m" | "l" | "xl"
    indentLvl?: number
    thumbnailTitle?: string
    hideThumbnailOnly?: boolean
    underline?: boolean
    hideChildIndicator?: boolean
    dontUseIconWidth?: boolean

    onSelect?(node: TreeNode): void
    onDeselect?(node: TreeNode): void
    onThumbnailClick?(node: TreeNode): void
}

const HoverBox = styled(Box)<{ hover: boolean; isSelected?: boolean }>(({ theme, hover, isSelected }) => ({
    display: "flex",
    alignItems: "center",
    height: "100%",
    ...(isSelected && {
        "div:first-of-type > svg": {
            fill: theme.palette.primary.main,
        },
    }),
    ...(hover && {
        "&:hover": {
            "div:first-of-type > svg": {
                fill: theme.palette.primary.main,
            },
            "div:nth-of-type(2)": {
                backgroundColor: theme.palette.highlight.light,
            },
        },
    }),
}))

const BreadCrumbWrapper = styled(Box)<{ underline?: boolean }>(({ theme, underline }) => ({
    ...(underline && {
        "::after": {
            content: '" "',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "1px",
            backgroundColor: "#e2e2e2",
        },
    }),
}))

class SearchTreeNode extends React.PureComponent<Props> {
    handleSelect = () => {
        const { node, onSelect } = this.props
        onSelect && onSelect(node)
    }

    handleDeselect = (e: React.MouseEvent<HTMLElement>) => {
        const { node, onDeselect } = this.props
        if (!onDeselect) {
            return
        }

        e.stopPropagation()
        onDeselect(node)
    }

    handleThumbnailClick = (e: React.MouseEvent<HTMLElement>) => {
        const { node, onThumbnailClick } = this.props
        if (!onThumbnailClick) {
            return
        }

        e.stopPropagation()
        onThumbnailClick(node)
    }

    renderThumbnail() {
        const { treeId, node, size, onThumbnailClick, thumbnailTitle, hideThumbnailOnly, workTask, isSelected, dontUseIconWidth } = this.props

        let className = "tree__node__thumbnail-wrapper"
        let title

        if (onThumbnailClick) {
            className += " is-clickable"
            title = thumbnailTitle
        }

        // This part is needed for the Icons of bike NodeTree
        // TODO: this should be changed: add new icons to statics/media server
        let iconName: string
        if (node.id === 2099699 && workTask?.vehicle?.vehicleType === VehicleType.Motorcycle) {
            iconName = "bike-drive-system"
        } else if (node.id === 2100194 && workTask?.vehicle?.vehicleType === VehicleType.Motorcycle) {
            iconName = "bike-steering"
        } else if ((node.id === 2102819 || node.id === 2103053) && workTask?.vehicle?.vehicleType === VehicleType.Motorcycle) {
            iconName = "bike-body-parts"
        } else if (node.id === 2103673 && workTask?.vehicle?.vehicleType === VehicleType.Motorcycle) {
            iconName = "bike-accessories"
        } else if (node.id === 2098259 && workTask?.vehicle?.vehicleType === VehicleType.Motorcycle) {
            iconName = "bike-shock-absorber"
        } else {
            iconName = `${treeId}_${node.id}`
        }

        const thumbnailWidth = !dontUseIconWidth ? (size === "xl" ? 30 : 25) : undefined

        return (
            <div className={className} title={title} onClick={this.handleThumbnailClick} {...getUIA("SearchtreeIcon")}>
                <HoverBox hover={!!onThumbnailClick} isSelected={isSelected}>
                    {!hideThumbnailOnly && treeId !== undefined && !!document.getElementById(`${treeId}_${node.id}`) && (
                        <Box width={thumbnailWidth}>
                            <Icon size={size} name={iconName} />
                        </Box>
                    )}
                    {onThumbnailClick && <FastClickBadge />}
                </HoverBox>
            </div>
        )
    }

    render() {
        const { node, isSelected, size, indentLvl, onSelect, onDeselect, onThumbnailClick, underline, hideChildIndicator } = this.props

        const className = classes(
            "tree__node",
            this.props.className,
            `tree__node--${size}`,
            !onDeselect && node.hasChildNodes && "tree__node--has-child-nodes",
            onSelect && !onThumbnailClick && "is-clickable",
            isSelected && "is-selected"
        )

        return (
            <BreadCrumbWrapper
                underline={underline}
                className={className}
                onClick={this.handleSelect}
                pl={indentLvl === 1 ? "10px" : indentLvl === 2 ? "35px" : undefined}
                {...getUIA("SearchtreeNode", node.id)}
            >
                {this.renderThumbnail()}

                <Text
                    className={classes("tree__node__name", onSelect && "is-clickable")}
                    size={size}
                    modifiers={size === "xl" ? ["strong"] : undefined}
                    title={node.name}
                    {...getUIA("SearchtreeLabel")}
                >
                    {node.name}
                </Text>

                {onDeselect ? (
                    <Button className="tree__node__remove" icon="close" layout={["ghost"]} onClick={this.handleDeselect} />
                ) : (
                    node.hasChildNodes && !hideChildIndicator && <Icon className="tree__node__child-nodes-indicator" name="down" />
                )}
            </BreadCrumbWrapper>
        )
    }
}
export default withWorkTask(SearchTreeNode)
