import { useMemo } from "react"
import { AvailabilityStatus, Availability as AvailabilityType, ErpInformation, ErpSystemConfig } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { styled } from "@tm/components"
import { useErpConfig } from "@tm/utils"
import ErpAvailability from "../../../../../../../erp/src/_shared/availability-wrapper"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const Availability = styled(ErpAvailability)({ "> div": { display: "block" } })

export function Availabilities() {
    const { translateText } = useLocalization()
    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const { useOrderByDistributor } = useErpConfig()

    const erpInfos = useMemo(() => {
        const list: {
            availability: ErpInformation["availability"]
            requestedQuantity?: number
            warehouses?: ErpInformation["warehouses"]
            tour?: ErpInformation["tour"]
            erpSystem?: ErpSystemConfig
        }[] = []
        const defaultErpInfo = articleErpInfos.default?.state === "success" ? articleErpInfos.default : undefined
        if (defaultErpInfo) {
            list.push({ ...defaultErpInfo.response, erpSystem: defaultErpInfo.erpSystem })
        } else {
            list.push({ availability: { type: AvailabilityStatus.Unknown, shortDescription: translateText(12684) } })
        }
        const alternativeErpInfo = articleErpInfos.alternative?.state === "success" ? articleErpInfos.alternative : undefined
        if (alternativeErpInfo) {
            list.push({ ...alternativeErpInfo.response, erpSystem: alternativeErpInfo.erpSystem })
        }
        return list
    }, [articleErpInfos])

    return (
        <>
            {erpInfos.map((info, index) => (
                <Availability key={`availability_${index}`} {...info} erpSystem={useOrderByDistributor ? info.erpSystem : undefined} />
            ))}
        </>
    )
}
