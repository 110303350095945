import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"
import { mapShowPartsListByTextToRequestArticleList } from "../business"

export default function handleInvokeDirectSearch(this: PostMessageControllerComponent, data: PostMessageRequest) {
    // TODO: when the part list fully supports oe numbers, some messages might not use showPartsByText but a new one
    if (data.invokeDirectSearch && data.invokeDirectSearch.searchText) {
        const request = mapShowPartsListByTextToRequestArticleList(data.invokeDirectSearch.searchText)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
