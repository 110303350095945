import { Box, Button, CircularProgress, Icon, Tooltip, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { useMemo } from "react"
import { useArticleExtendedDeliveryInfo } from "../../../hooks/useArticleItem/useArticleExtendedDeliveryInfo"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const Loader = styled(CircularProgress)({
    svg: { margin: 0 },
})

// Extended delivery also known as TecCom or Special Order
export function ExtendedDelivery() {
    const { translateText } = useLocalization()

    const { hideExtendedDeliveryComponent } = useArticleListConfiguration()
    const defaultArticleErpInfo = useDefaultArticleItemState((x) => x.articleErpInfos.default)
    const { teccom: data, loadTeccom: load, teccomLoading: loading } = useArticleExtendedDeliveryInfo(defaultArticleErpInfo)
    const hasData = !!data

    const tooltipText = useMemo(() => concat(" ", translateText(1080), hasData && `(${translateText(276)})`), [hasData, translateText])

    if (hideExtendedDeliveryComponent || !load) {
        return null
    }

    return (
        <Box alignSelf="flex-end">
            <Tooltip title={tooltipText}>
                <Button
                    size="small"
                    startIcon={
                        loading ? (
                            <Loader size={12} color="inherit" />
                        ) : (
                            <Icon name={hasData ? "extended-delivery-request-confirmed" : "extended-delivery-request"} />
                        )
                    }
                    onClick={load}
                    disabled={loading}
                >
                    {translateText(13645)}
                </Button>
            </Tooltip>
        </Box>
    )
}
