import { useLocalization } from "@tm/localization"
import { Typography, Loader, Stack, Divider } from "@tm/components"
import { CostEstimationTotals } from "../../../data/model"

type Props = {
    calculationLoading: boolean
    costEstimationTotals?: CostEstimationTotals
}

export default function TotalNumbers({ calculationLoading, costEstimationTotals }: Props) {
    const { translateText } = useLocalization()

    if (calculationLoading) {
        return <Loader size="small" />
    }

    if (!costEstimationTotals) {
        return null
    }

    function renderTotals(descriptionTextId: number, total: number, totalExcluded: number) {
        return (
            <Stack direction="row" spacing={0.5}>
                <Typography variant="label" fontWeight="bold">
                    {`${translateText(descriptionTextId)} ${total || 0}`}
                </Typography>
                <Stack direction="row">
                    <Typography variant="label">{`${translateText(479)} (`}</Typography>
                    <Typography variant="label" fontWeight="bold" mr={0.5}>
                        {totalExcluded || 0}
                    </Typography>
                    <Typography variant="label">{` ${translateText(872)})`}</Typography>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" sx={{ height: "auto" }} />}>
            {renderTotals(1667, costEstimationTotals.partPositionsInTotal, costEstimationTotals.partPositionsExcluded)}
            {renderTotals(1668, costEstimationTotals.workPositionsInTotal, costEstimationTotals.workPositionsExcluded)}
        </Stack>
    )
}
