import { useWorkTask } from "@tm/context-distribution"
import { BundleComponent } from "@tm/morpheus"

import { useState, useEffect } from "react"
import { RegisteredModels, channel } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId } from "@tm/utils"
import { resetStore, useEdsStore } from "../../data/state"

const EdsWorktaskManager = () => {
    const worktask = useWorkTask()?.workTask
    const { workTaskId, triggerWorkTaskConflict } = useWorkTask() ?? {}

    const moduleOpened = useEdsStore((state) => state.main.moduleOpened)
    const [workTaskHasEdsTab, setWorkTaskHasEdsTab] = useState(false)

    const [worktaskHasValue, setWorktaskHasValue] = useState(false)
    const [worktaskHasVehicle, setWorktaskHasVehicle] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        const unsub = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
            if (module.includes("eds")) {
                resetStore()
            }
        })

        return unsub
    }, [workTaskId])

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        // Handle the case when user comes back to worktask but EDS is not on focus, but the tab is open.
        Container.getInstance(RegisteredModels.ViewState)
            .action("loadViewState")(`${encodeUniqueId(workTaskId)}__module-navigation__`)
            .then((state) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setWorkTaskHasEdsTab(!!state.value?.tabs?.filter((t: any) => t.title === "EDS")?.length || false)
            })
    }, [workTaskId])

    function ShowConflictModal(id: string) {
        triggerWorkTaskConflict?.({
            currentWorkTaskId: id,
            data: {
                conflictedTabs: ["EDS"],
            },
            options: {
                showNewWorkTask: false,
                showNewCustomerVehicle: false,
            },
            onConfirm: (newWorkTask) => {
                if (!newWorkTask) {
                    resetStore()
                }
            },
        })
        setWorktaskHasVehicle(true)
    }

    useEffect(() => {
        if (!workTaskId || worktaskHasVehicle !== false || (!moduleOpened && !workTaskHasEdsTab)) {
            return
        }

        channel("WORKTASK", workTaskId).subscribeOnce("VEHICLE/LOADED", () => {
            ShowConflictModal(workTaskId)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workTaskId, worktaskHasVehicle, moduleOpened, workTaskHasEdsTab])

    useEffect(() => {
        if (!worktaskHasValue && worktask) {
            setWorktaskHasValue(true)
            setWorktaskHasVehicle(!!worktask.vehicle)
        }
    }, [worktask, worktaskHasValue])

    useEffect(() => {
        if (worktaskHasValue && worktask) {
            setWorktaskHasVehicle(!!worktask.vehicle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worktask?.vehicle])

    return null
}

export const managerComponent: BundleComponent = {
    name: "manager",
    component: EdsWorktaskManager,
}

export default managerComponent
