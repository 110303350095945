import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CategoryType, Vehicle, TreeNode, TreeNodeInfo } from "@tm/models"
import { useEffect, useMemo, useState } from "react"
import { atom, atomFamily, useRecoilState } from "recoil"
import { Repositories } from "../../data"
import { getSearchTreeNodeInfos } from "../../data/repositories"
import { getTreeIdFromCategoryType } from "../../helper"

export type BreadcrumbsState = {
    selectedNode: TreeNode | undefined
    selectedChilds: TreeNode[] | undefined
    initialTree: TreeNode[]
    history: string[]
    breadCrumbs: TreeNode[]
    treeId?: number
    prevBreadCrumbs?: TreeNode[]
}

export const BreadCrumbAtom = atomFamily<BreadcrumbsState, string>({
    key: "parts.breadcrumbs",
    default: { selectedNode: undefined, initialTree: [], history: [], breadCrumbs: [], selectedChilds: [], treeId: undefined },
})

export const StoreLanguageIdAtom = atom<string | undefined>({
    key: "parts.breadcrumbs.languageStore",
    default: undefined,
})

/**
 * undefined location, reset history and selected node!
 * @param location
 * @param treeType
 * @returns
 */
export default function useBreadCrumbHandler(location?: string, treeType?: CategoryType, workTask?: WorkTaskInfo) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRestoring, setIsRestoring] = useState<boolean>(false)
    const [stateWasRestored, setstateWasRestored] = useState<boolean>(false)
    const { languageId } = useLocalization()
    const [breadCrumbState, setBreadCrumbState] = useRecoilState(BreadCrumbAtom(`${treeType}.${workTask?.id || "global"}.${workTask?.vehicle?.id}`))
    const [storeLanguageId, setStoreLanguageId] = useRecoilState(StoreLanguageIdAtom)

    const hasLanguageChanged = useMemo(() => !!storeLanguageId && storeLanguageId !== languageId, [languageId, storeLanguageId])

    useEffect(() => {
        // Empty store only if the language has really been changed
        if (storeLanguageId && languageId !== storeLanguageId) {
            setBreadCrumbState((prev) => ({ ...prev, initialTree: [] }))
        }
    }, [languageId, setBreadCrumbState, storeLanguageId])

    // request map, save all requestet childnodes!
    useEffect(() => {
        if (languageId) {
            const newTreeId = getTreeIdFromCategoryType(treeType, workTask?.vehicle?.vehicleType)
            if (!newTreeId) {
                console.debug("TreeID is Empty!")
            }

            if (!isLoading && newTreeId && (breadCrumbState.initialTree.length <= 0 || hasLanguageChanged)) {
                setIsLoading(true)
                requestTreeNodes(newTreeId, workTask?.vehicle, undefined, true)
                    .then((resp) => {
                        if (!resp) {
                            setIsLoading(false)
                            return
                        }
                        const initialTree: TreeNode[] = []
                        initialiseTree(resp.nodes, initialTree)
                        setBreadCrumbState((prev) => {
                            return { ...prev, initialTree, treeId: newTreeId }
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                        if (storeLanguageId !== languageId) {
                            setStoreLanguageId(languageId)
                        }
                    })
            }
        }
    }, [workTask?.id, hasLanguageChanged, breadCrumbState])

    useEffect(() => {
        if (location && workTask?.id) {
            const urlParms = new URLSearchParams(location)
            const val = urlParms.get("history")
            if (val && !stateWasRestored) {
                const nodeIds = val.split("+")
                setBreadCrumbState((prev) => {
                    return { ...prev, history: nodeIds, selectedNode: undefined, breadCrumbs: [] }
                })
            }
        }
    }, [location, workTask?.id])

    useEffect(() => {
        if (
            breadCrumbState.history.length >= 1 &&
            breadCrumbState.breadCrumbs.length === 0 &&
            breadCrumbState.initialTree.length > 0 &&
            breadCrumbState.treeId &&
            !stateWasRestored &&
            location &&
            workTask?.id &&
            !hasLanguageChanged
        ) {
            setIsRestoring(true)
            if (breadCrumbState.history.length === 1) {
                changeSelectedTreeNode({ id: parseInt(breadCrumbState.history[0]), name: "", hasChildNodes: false })
                setstateWasRestored(true)
                setIsRestoring(false)
            } else {
                getSearchTreeNodeInfos({ treeId: breadCrumbState.treeId, nodeIds: breadCrumbState.history })
                    .then((data) => {
                        const newList: TreeNode[] = []
                        const reverse = [...data.searchTreeNodes].reverse()
                        mergeBranchToTree(breadCrumbState.initialTree, reverse, newList)

                        const breadC: TreeNode[] = []
                        getBreadcrumbsFromRestoredTree(breadC, newList)
                        breadC.reverse()

                        setBreadCrumbState({
                            ...breadCrumbState,
                            initialTree: newList,
                            breadCrumbs: breadC,
                            selectedNode: breadC.last(),
                            selectedChilds: breadC.last()?.childNodes,
                            history: [],
                        })
                    })
                    .finally(() => {
                        setstateWasRestored(true)
                        setIsRestoring(false)
                    })
            }
        }
    }, [breadCrumbState.history, breadCrumbState.initialTree, hasLanguageChanged])

    const restoreLastBreadCrumbs = () => {
        if (breadCrumbState.prevBreadCrumbs?.length) {
            const lastBreadCrumbs = breadCrumbState.prevBreadCrumbs
            setBreadCrumbState({
                ...breadCrumbState,
                prevBreadCrumbs: [],
                breadCrumbs: lastBreadCrumbs,
                selectedNode: lastBreadCrumbs.last(),
                selectedChilds: lastBreadCrumbs.last()?.childNodes,
            })
        }
    }

    const requestTreeNodes = async (
        treeId: number,
        vehicle?: Vehicle,
        activeParentNodeId?: number,
        loadSecondLevel?: boolean,
        loadOnlyTopProductGroups?: boolean
    ) => {
        if (treeType === "universalParts") {
            return Repositories.UniParts.getSearchTree({
                treeId,
                nodeId: activeParentNodeId,
                loadSecondLevel: false,
                loadOnlyTopProductGroups,
            })
        }

        if (!vehicle) {
            return null
        }

        return Repositories.getSearchTree({
            modelId: vehicle.tecDocTypeId,
            vehicleType: vehicle.vehicleType,
            treeId,
            nodeId: activeParentNodeId,
            loadSecondLevel,
            loadOnlyTopProductGroups,
        })
    }

    const findSelected = (selected: TreeNode, tree: TreeNode[], breadCrumbs: TreeNode[], level: number, newList: TreeNode[]) => {
        let found = false
        let childNodes: TreeNode[] = []
        level += 1
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            let marker = false
            // check if rekursiv call nessesary
            childNodes = []
            if (node.hasChildNodes && Array.isArray(node.childNodes)) {
                // merken das in diesem Knoten das Child gefunden wurde
                marker = findSelected(selected, node.childNodes, breadCrumbs, level, childNodes)
            }
            // pruefen ob das ausgewaelte aus dem Baum das gesuchte ist, oder es der Elternknoten ist von dem gesuchten
            if (node.id === selected.id || marker) {
                const spreadNode = marker ? node : selected
                const tempNode: TreeNode = {
                    ...spreadNode,
                    hasChildNodes: node.hasChildNodes,
                    isGroup: node.isGroup,
                    thumbnailUrl: node.thumbnailUrl,
                    name: node.name,
                    isSelected: node.id === selected.id ? !selected.isSelected : true,
                    level,
                    childNodes: node.id === selected.id ? selected.childNodes : childNodes,
                }
                breadCrumbs.push(tempNode)
                newList.push(tempNode)
                found = true
            } else {
                newList.push({ ...node, isSelected: false, level, childNodes })
            }
        }
        return found
    }

    // Request new Childs
    const getNextChilds = async (crumb?: TreeNode) => {
        const treeId = getTreeIdFromCategoryType(treeType, workTask?.vehicle?.vehicleType)
        if (treeId && (treeType === "universalParts" || workTask?.vehicle)) {
            return requestTreeNodes(treeId, workTask?.vehicle, crumb?.id, true)
        }
    }

    /**
     * Deselect all nodes
     * @param tree Input tree
     * @param newList Output Tree
     */
    const initialiseTree = (tree: TreeNode[], newList: TreeNode[]) => {
        let childNodes: TreeNode[] = []
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            childNodes = []
            if (node.hasChildNodes && node.childNodes) {
                initialiseTree(node.childNodes, childNodes)
            }

            newList.push({
                ...node,
                isSelected: false,
                vehicleType: workTask?.vehicle?.vehicleType,
                childNodes,
            })
        }
    }

    // create a tree from restored node Branch
    const restoredBranchToTree = (branch: TreeNodeInfo[]) => {
        const node = branch.pop()
        if (!node) {
            return []
        }

        const tempNode: TreeNode = {
            id: node.id,
            hasChildNodes: branch.length > 0,
            thumbnailUrl: node.image,
            name: node.name,
            restored: true,
            isSelected: true,
            childNodes: restoredBranchToTree(branch),
        }

        return [tempNode]
    }

    /**
     * find selected Nodes from Tree
     * @param breadCrumbs returned Breadcrumbs in reverse order
     * @param tree complete Searchtree
     */
    const getBreadcrumbsFromRestoredTree = (breadCrumbs: TreeNode[], tree: TreeNode[]) => {
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            if (node.isSelected) {
                if (node.childNodes && node.childNodes?.length > 0) {
                    getBreadcrumbsFromRestoredTree(breadCrumbs, node.childNodes)
                }
                breadCrumbs.push(node)
            }
        }
    }

    const createMergeTreeNode = (node: TreeNodeInfo, branch: TreeNodeInfo[]): TreeNode => {
        return {
            id: node.id,
            hasChildNodes: branch.length > 0,
            thumbnailUrl: node.image,
            name: node.name,
            restored: true,
            isSelected: true,
            childNodes: branch.length > 0 ? restoredBranchToTree(branch) : [],
        }
    }

    /**
     * Merge restored linear Branch into initial Tree
     * @param tree initial tree
     * @param branch restored branch, only nodeInfos
     * @param newList list will filled over the process, its recrusiv
     * @returns
     */
    const mergeBranchToTree = (tree: TreeNode[], branch: TreeNodeInfo[], newList: TreeNode[]) => {
        let childNodes: TreeNode[] = []
        const selected = branch.pop()
        if (!selected) {
            return false
        }

        if (tree.length === 0) {
            newList.push(createMergeTreeNode(selected, branch))
        }

        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            // check if rekursiv call nessesary
            childNodes = []
            if (node.id === selected.id) {
                if (node.hasChildNodes && Array.isArray(node.childNodes)) {
                    mergeBranchToTree(node.childNodes, branch, childNodes)
                    newList.push({ ...node, isSelected: true, childNodes, restored: true })
                } else {
                    newList.push(createMergeTreeNode(selected, branch))
                }
                // merken das in diesem Knoten das Child gefunden wurde
            } else {
                newList.push({ ...node, isSelected: false })
            }
        }
    }

    /**
     * select new Treenode, if this node has no Childs in the tree but has childTrees, request next nodes if node has Childs
     * @param crumb selected crumb, if undefined, reset selected Tree
     * @returns void
     */
    const changeSelectedTreeNode = (crumb?: TreeNode) => {
        const breadCrumbsReverse: TreeNode[] = []
        const newList: TreeNode[] = []
        setIsLoading(true)
        // Reset tree if no crumb Selected
        if (!crumb) {
            const deselectedChildList: TreeNode[] = []
            initialiseTree(breadCrumbState.initialTree, deselectedChildList)
            setBreadCrumbState({
                ...breadCrumbState,
                history: [],
                initialTree: deselectedChildList,
                breadCrumbs: [],
                selectedNode: undefined,
                selectedChilds: [],
            })
            setIsLoading(false)
            return
        }

        // breadCrumb liste ist verdreht in der Reihnfolge,
        // liegt an der Rekursion da das letzte Element zuerst auf das Array kommt
        if ((crumb.hasChildNodes && !crumb?.childNodes) || (crumb.childNodes && crumb.childNodes?.length <= 0) || crumb.restored) {
            getNextChilds(crumb)
                .then((newChilds) => {
                    if (newChilds?.nodes) {
                        const selected: TreeNode = {
                            ...crumb,
                            childNodes: newChilds.nodes,
                            topProductGroups: newChilds.topProductGroups,
                            restored: false,
                        }
                        findSelected(selected, breadCrumbState.initialTree, breadCrumbsReverse, 0, newList)
                        const breadCrumbs = breadCrumbsReverse.reverse()
                        setBreadCrumbState((prev) => {
                            return {
                                ...breadCrumbState,
                                prevBreadCrumbs: prev.breadCrumbs,
                                initialTree: newList,
                                breadCrumbs,
                                selectedNode: selected,
                                selectedChilds: newChilds.nodes,
                            }
                        })
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            const deselectedChildList: TreeNode[] = []
            let selectedNode = crumb
            if (crumb.childNodes) {
                initialiseTree(crumb.childNodes, deselectedChildList)
                selectedNode = { ...selectedNode, childNodes: deselectedChildList }
            }
            findSelected(selectedNode, breadCrumbState.initialTree, breadCrumbsReverse, 0, newList)
            const breadCrumbs = breadCrumbsReverse.reverse()
            setBreadCrumbState((prev) => {
                return {
                    ...breadCrumbState,
                    prevBreadCrumbs: prev.breadCrumbs,
                    initialTree: newList,
                    breadCrumbs,
                    selectedNode,
                    selectedChilds: selectedNode.childNodes,
                }
            })
            setIsLoading(false)
        }
    }

    const resetSelectedNode = () => {
        changeSelectedTreeNode()
    }

    return {
        restoreLastBreadCrumbs,
        breadCrumbState,
        newBreadcrumbSelected: changeSelectedTreeNode,
        isLoading: isLoading || isRestoring,
        resetSelectedNode,
    }
}
