import { Article, ArticleReferenceType, SearchFilters } from "@tm/models"
import { useCallback } from "react"
import { useArticleListActions } from "../ArticleListActions"
import { ArticleReferenceParms } from "../models"
import { useHandleStartDirectSearch } from "./useHandleStartDirectSearch"

export function useHandleClickReference(article: Article, quantity: number) {
    const { showArticleDetails } = useArticleListActions()
    const handleStartDirectSearch = useHandleStartDirectSearch()

    const handleClickReference = useCallback(
        (reference: ArticleReferenceParms) => {
            switch (reference.referenceType) {
                case ArticleReferenceType.ReplacementArticleNo:
                case ArticleReferenceType.ReplacedByArticleNo:
                    showArticleDetails(article.productGroup.id, article.supplier.id, reference.label, quantity)
                    break
                case ArticleReferenceType.UtilityNo:
                    handleStartDirectSearch(reference.label, SearchFilters.UtilityNo | SearchFilters.OeReference | SearchFilters.TradeReference)
                    break
                case ArticleReferenceType.EuropeanArticleNo:
                    handleStartDirectSearch(reference.label, SearchFilters.EuropeanArticleNumber | SearchFilters.TradeReference)
                    break
                case ArticleReferenceType.OeReferenceNo:
                    handleStartDirectSearch(reference.label, SearchFilters.OeAndTradeReference)
                    break
                default:
                    handleStartDirectSearch(reference.label, SearchFilters.All)
                    break
            }
        },
        [article.productGroup.id, article.supplier.id, handleStartDirectSearch, quantity, showArticleDetails]
    )

    return handleClickReference
}
