import { Suspense, useState } from "react"
import { WorkTaskInfo, useReplaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { OrderingType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { renderRoute, ajax, useExternalCatalogUrl } from "@tm/utils"
import { useHistory, useParams } from "react-router"
import { Box, Button, Dialog, Icon, Loader, Stack, Typography } from "@tm/components"
import { Actions, IActions } from "./business"
import { getBundleParams } from "../../utils"
import TransferButtons from "../_shared/transfer-buttons"
import { ESendCostEstimationMode } from "../../data/model"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useCreateCostEstimationVoucher } from "../../data/hooks/useCreateCostEstimationVoucher"

type Props = ConfigProps & {
    actions: IActions
}

type ConfigProps = {
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
}

/**
 * Action Buttons for Basket Bundle
 * @memberof Basket
 * @param props
 * @returns Action button Component
 */
function ActionButtonsComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { actions, orderButtonTextId, orderButtonIcon, orderRoute, orderRoutePreActionExternalSystemId, workTask } = props

    const { hideBasket } = getBundleParams()
    const history = useHistory()
    const params = useParams()
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId: orderRoutePreActionExternalSystemId, telesalesCustomerNo })
    const replacedExternalCatalogUrl = useReplaceUrlTags(externalCatalogUrl, {})
    const { costEstimation, workTaskBasketCalculation } = useWorkTaskBasketState(workTask.id)
    const { creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask)
    const { hasItems, costEstimationLoading } = costEstimation.state

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [sendingPreAction, setSendingPreAction] = useState<boolean>(false)

    const disableButtons = costEstimationLoading || !hasItems

    function handleRemoveAllItems() {
        setShowDeleteDialog(false)
        costEstimation.actions.resetCostEstimation()
    }

    function openOrderUrl() {
        const url = renderRoute(orderRoute, params)

        if (!orderRoutePreActionExternalSystemId) {
            history.push(url)
        } else if (replacedExternalCatalogUrl) {
            setSendingPreAction(true) // semicolon needed
            // Call to external system before redirecting to basket
            ajax({ url: replacedExternalCatalogUrl, method: "GET" }).then(
                () => {
                    history.push(url)
                },
                () => {
                    setSendingPreAction(false)
                }
            )
        }
    }

    return (
        <Toolbar>
            <Stack direction="row" spacing={0.5}>
                <Dialog open={showDeleteDialog} skin="warning" position="top" fullWidth>
                    <Stack spacing={2}>
                        <Typography>{translateText(989)}</Typography>
                        <Typography variant="body2">{translateText(990)}</Typography>
                    </Stack>
                    <Box pl={2} textAlign="center" display="initial">
                        <Button variant="contained" onClick={() => setShowDeleteDialog(false)}>
                            {translateText(584)}
                        </Button>
                        <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleRemoveAllItems}>
                            {translateText(585)}
                        </Button>
                    </Box>
                </Dialog>
                <Button disabled={disableButtons} onClick={() => setShowDeleteDialog(true)}>
                    {translateText(48)}
                </Button>
                {creatingCostEstimationVoucher ? (
                    <Loader size="small" />
                ) : (
                    <Button color="success" disabled={disableButtons} onClick={actions.saveCostEstimation}>
                        {translateText(9)}
                    </Button>
                )}
                <TransferButtons workTaskId={workTask.id} disabled={disableButtons} mode={ESendCostEstimationMode.costEstitmation} />
                {userContext?.parameter.orderingType !== OrderingType.CentralOrderingWithoutWorkTaskOrder && !hideBasket && (
                    <Button
                        color="highlight"
                        endIcon={<Icon name={orderButtonIcon || "orders"} />}
                        onClick={openOrderUrl}
                        disabled={!workTaskBasketCalculation || sendingPreAction}
                    >
                        {translateText(orderButtonTextId || "50")}
                    </Button>
                )}
            </Stack>
        </Toolbar>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <ActionButtonsComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
