import { ajax } from "@tm/utils"
import { PreyssRim } from "../../model"
import { mapStatics2RimJsonToPreyssRims } from "./mapper"

export function load3DAvailableRims() {
	const url = '//media.carparts-cat.com/webdata1/mvcimages/MVC3DConfigurator/resources/rims/rims.json'
	return new Promise<PreyssRim[]>((resolve, reject) =>
		ajax({ url }).then(
			response => resolve(mapStatics2RimJsonToPreyssRims(response)),
			reject
		)
	)
}

