import * as React from "react"
import { Text } from "@tm/controls"
import { useStyle } from "@tm/context-distribution"
import { em } from "csx"
import { FastServiceSelection } from "../../data/models"

type Props = {
    items: FastServiceSelection[]
}

const MultiAppendix: React.FunctionComponent<Props> = ({ items }) => {
    return (
        <div className={style.wrapper}>
            {items.map((elem, idx) => (
                <>
                    <Text key={idx} size="xl">
                        {`${elem.label}: `}
                    </Text>
                    <div className={style.leftText}>
                        {elem.items
                            .filter((x) => x.isSelected)
                            .map((text, index) => (
                                <Text modifiers={["strong"]} key={index} size="xl">
                                    {text.label}
                                </Text>
                            ))}
                    </div>
                </>
            ))}
        </div>
    )
}

export default MultiAppendix

const style = useStyle({
    wrapper: {
        display: "grid",
        gridTemplateColumns: "20% 1fr",
        rowGap: em(0.5),
        columnGap: em(0.5),
        width: "100%",
    },
    leftText: {
        display: "flex",
        flexDirection: "column",
        marginRight: em(0.5),
        flex: 1,
        marginBottom: em(0.25),
    },
})(MultiAppendix)
