import { ChangeEvent, Dispatch, memo, SetStateAction } from "react"
import { TextField, Icon, IconButton, SelectTextField, MenuItem, Stack } from "@tm/components"
import { Note as ArticleNoteInterface, CustomArticle } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleNote } from "../../../article-note"
import { EditorButtons } from "./EditorButtons"
import { useCustomArticlesCategories } from "../../../../../data/hooks/useCustomArticlesCategories"
import { VatRateSelectionItem } from "../../../../../data/model"
import { StackRow } from "../../../../StyledComponents"
import { VatRateSelector } from "../../../../VatRateSelector"

type Props = {
    articleNote?: ArticleNoteInterface
    activeArticle?: CustomArticle & { articleNote?: string }
    customPartVatRates?: Array<VatRateSelectionItem>
    onArticleChange: Dispatch<SetStateAction<(CustomArticle & { articleNote?: string | undefined }) | undefined>>
    saveArticleInformation(): Promise<void>
    closeEditor(): void
}

export const RowToEdit = memo<Props>(({ activeArticle, customPartVatRates, articleNote, saveArticleInformation, closeEditor, onArticleChange }) => {
    const { translateText } = useLocalization()
    const { categories } = useCustomArticlesCategories()

    if (!activeArticle?.id) {
        return null
    }

    const onChangeArticleNumber = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            articleNumber: value,
        })
    }

    const onChangeArticleDescription = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            description: value,
        })
    }

    const handleCategoryChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            category: parseInt(value),
        })
    }

    const handleArticleNoteChange = (note: string) => {
        onArticleChange({
            ...activeArticle,
            articleNote: note,
        })
    }

    const handleSelectVatRateType = (newVatRate: VatRateSelectionItem) => {
        onArticleChange({
            ...activeArticle,
            vatRate: {
                vatRate: newVatRate.vatValue,
                vatType: newVatRate.vatType,
            },
        })
    }

    const handleChangeDefaultCategory = () => {
        onArticleChange({
            ...activeArticle,
            isDefaultForCategory: !activeArticle.isDefaultForCategory,
        })
    }

    const handleGaragePriceChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!value) {
            onArticleChange({
                ...activeArticle,
                garagePrice: undefined,
            })
            return
        }

        let parsedValue = value
        if (/^\d+(,\d{0,2})?$/.test(value)) {
            parsedValue = parsedValue.replace(/,/g, ".")
        }

        onArticleChange({
            ...activeArticle,
            garagePrice: Math.round(Number(parsedValue) * 1e2) / 1e2,
        })
    }

    return (
        <StackRow justifyContent="space-between" alignItems="flex-start" paddingY={1} spacing={1}>
            <Stack flex={1} spacing={0.5}>
                <StackRow spacing={2}>
                    <TextField
                        sx={{ mt: 0 }}
                        id="articleNumber"
                        fullWidth
                        label={translateText(87).toUpperCase()}
                        value={activeArticle.articleNumber}
                        onChange={onChangeArticleNumber}
                    />
                    <TextField
                        fullWidth
                        id="description"
                        required
                        error={!activeArticle.description}
                        label={translateText(25).toUpperCase()}
                        value={activeArticle.description}
                        onChange={onChangeArticleDescription}
                    />
                    <IconButton
                        disabled={!activeArticle.category || activeArticle.category === 3060}
                        onClick={handleChangeDefaultCategory}
                        title={translateText(12702).toUpperCase()}
                    >
                        {activeArticle.isDefaultForCategory ? <Icon name="filled-star" /> : <Icon name="vote-star" />}
                    </IconButton>
                    <SelectTextField
                        fullWidth
                        label={translateText(158).toUpperCase()}
                        value={`${categories?.find((x) => x.textId === activeArticle.category)?.textId}`}
                        maxLayerHeight={300}
                        customIcon={(props) => <Icon {...props} name="down" style={{ marginRight: "8px" }} />}
                        onChange={handleCategoryChange}
                        size="small"
                    >
                        {categories?.map((cat) => (
                            <MenuItem key={cat.textId} value={cat.textId}>
                                {translateText(cat.textId)}
                            </MenuItem>
                        )) || []}
                    </SelectTextField>
                    <TextField
                        id="garagePrice"
                        label={translateText(1620).toUpperCase()}
                        type="number"
                        variant="filled"
                        sx={{ minWidth: "100px" }}
                        value={`${activeArticle.garagePrice}`}
                        onChange={handleGaragePriceChange}
                    />
                    {customPartVatRates && (
                        <VatRateSelector
                            vatType={activeArticle.vatRate?.vatType}
                            vatRates={customPartVatRates}
                            onChange={(newVatRate) => handleSelectVatRateType(newVatRate)}
                        />
                    )}
                </StackRow>
                <ArticleNote saveInProgress={false} onChange={handleArticleNoteChange} note={articleNote} />
            </Stack>
            <EditorButtons canSave={!!activeArticle.description} onSave={saveArticleInformation} onAbort={closeEditor} />
        </StackRow>
    )
})
