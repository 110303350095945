import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Icon, SharePopover, Stack, Tooltip, Typography } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SharingData } from "."
import { bundleChannel } from "../../../../business"
import { getBundleParams } from "../../../../utils"

type CostEstimationActionsProps = PropsWithChildren<{
    disabled: boolean
    customerEmail: string | undefined
    sharingData: SharingData
    onChangeSharingData(sharingData: SharingData): void
    onPrintButtonClick(): void
    onSendCostEstimationButtonClick(): void
    onGenerateCostEstimationLinkButtonClick(): void
}>

export function CostEstimationActions({
    children,
    disabled,
    sharingData,
    customerEmail,
    onChangeSharingData,
    onPrintButtonClick,
    onSendCostEstimationButtonClick,
    onGenerateCostEstimationLinkButtonClick,
}: CostEstimationActionsProps) {
    const { translate, translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const unsubRefs = useRef<Array<() => void>>([])

    const [viewOptionsInSharePopoverOpened, setViewOptionsInSharePopoverOpened] = useState(false)

    const message = useMemo(() => {
        if (!sharingData.link) {
            return
        }

        let text = `${translateText(13353)}: ${sharingData.link}`

        if (sharingData.pin) {
            text += `\n${translateText(13354)}: ${sharingData.pin}`
        }

        return text
    }, [sharingData, translateText])

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            unsubRefs.current.forEach((unsub) => unsub())
        }
    }, [])

    const handleToggleViewOptionsInSharePopover = useCallback((_, expanded: boolean) => setViewOptionsInSharePopoverOpened(expanded), [])
    const handleSharePopoverClose = useCallback(() => setViewOptionsInSharePopoverOpened(false), [])

    const handleRequestLink = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            let errorTimeout = 0

            // TODO: This should be refactored somehow to not subscribe to the bundleChannel but `onGenerateCostEstimationLink` should return a Promise or likewise

            const unsub = bundleChannel().subscribe("COST_ESTIMATION_LINK_CREATED", ({ link, additionalData }) => {
                window.clearTimeout(errorTimeout)
                unsub()
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                unsub2()
                onChangeSharingData({ loading: false, link, pin: additionalData?.pin })
                resolve()
            })
            unsubRefs.current.push(unsub)

            const unsub2 = bundleChannel().subscribe("COST_ESTIMATION_LINK_CREATION_ERROR", ({ error }) => {
                window.clearTimeout(errorTimeout)
                unsub()
                unsub2()
                onChangeSharingData({ loading: false, error: error?.message ?? true })
                reject()
            })
            unsubRefs.current.push(unsub2)

            errorTimeout = window.setTimeout(() => {
                unsub()
                unsub2()
                onChangeSharingData({ loading: false, error: true })
                reject()
            }, 10000) // abort after 10 seconds

            onChangeSharingData({ loading: true })
            onGenerateCostEstimationLinkButtonClick()
        })
    }, [onGenerateCostEstimationLinkButtonClick, onChangeSharingData])

    function renderPrintButton() {
        return (
            <Tooltip title={translateText(49)}>
                <Box>
                    <Button disabled={disabled} onClick={onPrintButtonClick} startIcon={<Icon name="print" />} />
                </Box>
            </Tooltip>
        )
    }

    function renderSharePopover() {
        if (!getBundleParams().enableAdvancedSharingOptions) {
            return renderMicro("notifications", "send-cost-estimation-button", {
                disabled,
                onClick: onSendCostEstimationButtonClick,
            })
        }

        return (
            <SharePopover
                title={`${translateText(13355)}:`}
                link={sharingData.link}
                onRequestLink={handleRequestLink}
                isRequestingLink={sharingData.loading}
                errorMessage={sharingData.error === true ? translateText(13301) : sharingData.error}
                buttonProps={{
                    disabled,
                    variant: "outlined",
                    onClick: !sharingData.link ? handleRequestLink : undefined,
                }}
                popoverProps={{
                    onClose: handleSharePopoverClose,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                }}
            >
                <SharePopover.ShareButtonWrapper>
                    <SharePopover.WhatsAppButton message={message} />
                    <SharePopover.EmailButton recipient={customerEmail} subject={translateText(13356)} body={message} />
                    {renderMicro("notifications", "send-cost-estimation-button", {
                        asShareButton: true,
                        disabled,
                        onClick: onSendCostEstimationButtonClick,
                    })}
                    <SharePopover.DownloadButton
                        disabled={!sharingData.link}
                        href={sharingData.link}
                        {...{ download: true }} // TODO: Handle this correct in the DownloadButton itself
                    />
                </SharePopover.ShareButtonWrapper>
                <SharePopover.AdditionalPopoverContent>
                    <Accordion
                        expanded={viewOptionsInSharePopoverOpened}
                        onChange={handleToggleViewOptionsInSharePopover}
                        disableGutters
                        elevation={0}
                    >
                        <AccordionSummary>
                            <Typography>{translate(844)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ columns: 2 }}>{children}</Box>
                        </AccordionDetails>
                    </Accordion>
                </SharePopover.AdditionalPopoverContent>
            </SharePopover>
        )
    }

    return (
        <Toolbar title={translateText(177)}>
            <Stack direction="row" alignItems="center" spacing={1}>
                {renderPrintButton()}
                {renderSharePopover()}
            </Stack>
        </Toolbar>
    )
}
