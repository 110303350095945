import * as React from 'react'
import * as ReactDOM from "react-dom"
import { style } from 'typestyle'
import { percent, viewHeight } from 'csx'
import { RimItem, RimsPosition, ImagePaths } from '../../data/model'
import { Loader } from '@tm/controls'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { MainState } from '../main'
import { _2DRimSize } from '../../data/repositories/wheels-load2DRimSizes/model'

type Props = {
	loading?: boolean
	error?: boolean
	selectedRimItem?: RimItem
	selectedRimLoading?: boolean
	selectedSize?: string
	rimPositions?: RimsPosition
	rimSizes: Record<string, _2DRimSize[]>
	images: ImagePaths
	insideDialog?: boolean
	renderHidden?: boolean
}

export let loaded: boolean = false

const StandardConfigurator: React.FC<Props> = ({ renderHidden, loading, images, selectedRimLoading, insideDialog, selectedRimItem, rimPositions, rimSizes, selectedSize: selSize }) => {
	const bodyImageRef = React.useRef<HTMLImageElement | null>(null)
	const imageRef2 = React.useRef<HTMLImageElement | null>(null)
    const imageRef3 = React.useRef<HTMLImageElement | null>(null)
	const frontWheelRef = React.useRef<HTMLImageElement | HTMLDivElement | null>(null)
	const backWheelRef = React.useRef<HTMLImageElement | HTMLDivElement | null>(null)
    const frontWheelLoaderRef = React.useRef<HTMLImageElement | HTMLDivElement | null>(null)
	const backWheelLoaderRef = React.useRef<HTMLImageElement | HTMLDivElement | null>(null)
	const frontBrake = React.useRef<HTMLImageElement | null>(null)
	const backBrake = React.useRef<HTMLImageElement | null>(null)
	const container = React.useRef<HTMLDivElement | null>(null)
	let wrapper: HTMLDivElement | null

	let waitForBoundingRect: boolean
	let showConfiguratorTimeout: number | NodeJS.Timeout
	let showConfiguratorTimeout2: number | NodeJS.Timeout

    const [currentRimId, setCurrentRimId] = React.useState<number>()
    const [rimImageLoading, setRimImageLoading] = React.useState(false)
	const [imageAvailable, setImageAvailable] = React.useState(false)

    const rimsLoaded = () => {
        setRimImageLoading(false)
    }

	React.useEffect(() => {
		load()
	})

	React.useEffect(() => {
		if (selectedRimItem && selectedRimItem?.idRimDesign != currentRimId) {
			loaded = false
            setRimImageLoading(true)
            setCurrentRimId(selectedRimItem.idRimDesign)
			setImageAvailable(false)
			selectedRimItem.frontPicture?.length && checkImage(selectedRimItem.frontPicture)
		}
	}, [selectedRimItem])

	const checkImage = (imageUrl: string) => {
		let request = new XMLHttpRequest()
		request.open("GET", imageUrl, true)
		request.send()
		request.onload = () => {
			setImageAvailable(request.status == 200)
		}
	}

	const showOrCreateConfigurator = () => {
		wrapper = document.getElementById("2D-configurator") as HTMLDivElement

		if (!wrapper) {
			wrapper = document.createElement("div")
			wrapper.id = "2D-configurator"
			wrapper.style.position = "absolute"
			wrapper.style.display = "flex"
			wrapper.className = "standard-configurator"
			document.body.appendChild(wrapper)

		} else if (!waitForBoundingRect) {
			wrapper.style.display = "flex"
			wrapper.style.pointerEvents = renderHidden ? "none" : ""
			wrapper.style.zIndex = renderHidden ? "-2" : insideDialog ? "21" : "0"
		}
		handleBoundingRect()
	}

	const handleBoundingRect = () => {
		if (wrapper && container) {
			if (!container.current?.clientWidth) {
				setTimeout(handleBoundingRect.bind(this), 100)
				waitForBoundingRect = true
				return
			}
			waitForBoundingRect = false

			const containerBoundingRect = container.current.getBoundingClientRect()
			wrapper.style.left = containerBoundingRect.left + "px"
			wrapper.style.top = containerBoundingRect.top + "px"

			const height = containerBoundingRect.height > 0 ? containerBoundingRect.height : (insideDialog ? 600 : 300)

			wrapper.style.height = height + "px"
			wrapper.style.width = containerBoundingRect.width + "px"
			wrapper.style.display = "flex"
			wrapper.style.overflow = "hidden"
			wrapper.style.justifyContent = "center"
			wrapper.style.pointerEvents = renderHidden ? "none" : ""
			wrapper.style.zIndex = renderHidden ? "-2" : insideDialog ? "21" : "0"

			ReactDOM.render(renderContent(), wrapper)

			showConfiguratorTimeout = setTimeout(() => {
				updateRimsPositions()
				loaded = true
			}, 50)
		}
	}

	React.useEffect(() => {
		window.addEventListener("resize", handleBoundingRect)

		return () => {
			loaded = false
			window.removeEventListener("resize", handleBoundingRect)
			clearTimeout(showConfiguratorTimeout as number)
			clearTimeout(showConfiguratorTimeout2 as number)
			hideWrapper()
		}
	}, [handleBoundingRect])

	const load = () => {
		loaded = false

		if (insideDialog) {
			showConfiguratorTimeout2 = setTimeout(() => showOrCreateConfigurator(), 500)
		} else {
			showOrCreateConfigurator()
		}
		setRimImageLoading(false)
	}

	const updateRimsPositions =() => {
		const selectedSize = selSize || ""

		if (container.current && bodyImageRef.current?.parentElement && rimPositions && frontWheelRef.current && backWheelRef.current && bodyImageRef.current.clientHeight) {

			const { pixelBack, pixelFront, centerFrontX, centerBackX, centerBackY, centerFrontY, pixelBackBreakDisc, pixelFrontBreakDisc, rimSizePixel, defaultRimSize } = rimPositions

			const clientHeight = renderHidden ? 300 : container.current.clientHeight

			const scale = (clientHeight / rimPositions.naturalHeight) * (insideDialog ? 1.4 : 1.74)

			bodyImageRef.current.parentElement.style.height = rimPositions.naturalHeight * scale + "px"
			bodyImageRef.current.parentElement.style.width = rimPositions.naturalWidth * scale + "px"

			if (insideDialog || (container.current.clientWidth < rimPositions.naturalWidth * scale)) {
				bodyImageRef.current.parentElement.style.left = (container.current.clientWidth - rimPositions.naturalWidth * scale) / 2 + "px"
			} else {
				const containerLeft = container.current.getBoundingClientRect().left
				if (containerLeft < 100){
					bodyImageRef.current.parentElement.style.left = -4 - containerLeft + "px"
				}
			}

			bodyImageRef.current.style.height = rimPositions.naturalHeight * scale + "px"
			bodyImageRef.current.style.width = rimPositions.naturalWidth * scale + "px"

			imageRef2.current && (imageRef2.current.style.height = rimPositions.naturalHeight * scale + "px")
			imageRef2.current && (imageRef2.current.style.width = rimPositions.naturalWidth * scale + "px")

			imageRef3.current && (imageRef3.current.style.height = rimPositions.naturalHeight * scale + "px")
			imageRef3.current && (imageRef3.current.style.width = rimPositions.naturalWidth * scale + "px")

			const frontRimScale = rimSizes[selectedSize] && rimSizes[selectedSize][0].diameterRim / rimSizes[selectedSize][0].diameterTyre || 0.55
			const frontDefaultDiameterRim = defaultRimSize && rimSizes[defaultRimSize] && rimSizes[defaultRimSize][0]?.diameterRim
			const frontSelectedDiameterRim = selectedSize && rimSizes[selectedSize] && rimSizes[selectedSize][0]?.diameterRim
			const frontWheelWidth = rimSizePixel && defaultRimSize && frontDefaultDiameterRim && frontSelectedDiameterRim ?
										newCalculationForRimWidth(rimSizePixel, scale, frontDefaultDiameterRim, frontSelectedDiameterRim) :
										calculateRimWidth(pixelFront, scale, frontRimScale, insideDialog)
			frontWheelRef.current.style.width = frontWheelWidth + "px"
			frontWheelRef.current.style.height = frontWheelWidth + "px"
			frontWheelRef.current.style.left = calculateLeftPosition(centerFrontX, frontWheelWidth, scale) + "px"
			frontWheelRef.current.style.top = calculateTopPosition(centerFrontY, frontWheelWidth, scale) + "px"
			frontWheelRef.current.style.display = "block"

            if (frontWheelLoaderRef.current) {
                frontWheelLoaderRef.current.style.width = frontWheelRef.current.style.width
                frontWheelLoaderRef.current.style.height = frontWheelRef.current.style.height
                frontWheelLoaderRef.current.style.left = frontWheelRef.current.style.left
                frontWheelLoaderRef.current.style.top = frontWheelRef.current.style.top
                frontWheelLoaderRef.current.style.display = frontWheelRef.current.style.display
            }

			if (frontBrake.current) {
				const frontBrakeWidth = frontRimScale && calculateBrakeWidth(pixelFrontBreakDisc, scale, insideDialog)
				frontBrake.current.style.width = frontBrakeWidth + "px"
				frontBrake.current.style.left = calculateLeftPosition(centerFrontX, frontBrakeWidth, scale) + "px"
				frontBrake.current.style.top = calculateTopPosition(centerFrontY, frontBrakeWidth, scale) + "px"
				frontBrake.current.style.display = "block"
			}

			const backRimScale = rimSizes[selectedSize] && rimSizes[selectedSize][1].diameterRim / rimSizes[selectedSize][1].diameterTyre || 0.55
			const backDefaultDiameterRim = defaultRimSize && rimSizes[defaultRimSize] && rimSizes[defaultRimSize][1]?.diameterRim
			const backSelectedDiameterRim = selectedSize && rimSizes[selectedSize] && rimSizes[selectedSize][1]?.diameterRim
			const backWheelWidth = rimSizePixel && defaultRimSize && backDefaultDiameterRim && backSelectedDiameterRim ?
										newCalculationForRimWidth(rimSizePixel, scale, backDefaultDiameterRim, backSelectedDiameterRim) :
										calculateRimWidth(pixelBack, scale, backRimScale, insideDialog)
			backWheelRef.current.style.width = backWheelWidth + "px"
			backWheelRef.current.style.height = backWheelWidth + "px"
			backWheelRef.current.style.left = calculateLeftPosition(centerBackX, backWheelWidth, scale) + "px"
			backWheelRef.current.style.top = calculateTopPosition(centerBackY, backWheelWidth, scale) + "px"
			backWheelRef.current.style.display = "block"

            if (backWheelLoaderRef.current) {
                backWheelLoaderRef.current.style.width = backWheelRef.current.style.width
                backWheelLoaderRef.current.style.height = backWheelRef.current.style.height
                backWheelLoaderRef.current.style.left = backWheelRef.current.style.left
                backWheelLoaderRef.current.style.top = backWheelRef.current.style.top
                backWheelLoaderRef.current.style.display = backWheelRef.current.style.display
            }

			if (backBrake.current) {
				const backBrakeWidth = backRimScale && calculateBrakeWidth(pixelBackBreakDisc, scale, insideDialog)
				backBrake.current.style.width = backBrakeWidth + "px"
				backBrake.current.style.left = calculateLeftPosition(centerBackX, backBrakeWidth, scale) + "px"
				backBrake.current.style.top = calculateTopPosition(centerBackY, backBrakeWidth, scale) + "px"
				backBrake.current.style.display = "block"
			}
			bodyImageRef.current.parentElement.style.visibility = "visible"
		}
	}

	const hideWrapper = () => {
		loaded = false
		if (wrapper) {
			wrapper.style.display = "none"
			wrapper.style.pointerEvents = "none"
			wrapper.style.zIndex = "-2"
		}
	}

	const renderRimImage = () => {
		const noRimImage = "/bundles/wheels/images/no-rim.png" + "?ignorethis=" + selectedRimItem?.idRimDesign

		if (selectedRimItem?.frontPicture && imageAvailable)
			return (
				<>
					<img className="front-rim" onLoad={rimsLoaded} src={selectedRimItem.frontPicture} ref={ frontWheelRef as React.RefObject<HTMLImageElement> } />
					<img className="back-rim" onLoad={rimsLoaded} src={selectedRimItem.frontPicture} ref={backWheelRef as React.RefObject<HTMLImageElement>} />
				</>
			)
		else
			return (
				<>
					<img className="front-rim" onLoad={rimsLoaded} src={noRimImage} ref={frontWheelRef as React.RefObject<HTMLImageElement>} />
					<img className="back-rim" onLoad={rimsLoaded} src={noRimImage} ref={backWheelRef as React.RefObject<HTMLImageElement>} />
				</>
			)
	}

	const renderRimImages = () => {
		const noRimImage = "/bundles/wheels/images/no-rim.png" + "?ignorethis=" + selectedRimItem?.idRimDesign

		if (selectedRimLoading || rimImageLoading) {
			return (
				<>
					<div className="loader loader-spinner loader-spinner--visible front-loader" ref={frontWheelLoaderRef} />
					<div className="loader loader-spinner loader-spinner--visible back-loader" ref={backWheelLoaderRef} />
				</>
			)
		}
		else if (!selectedRimLoading && !rimImageLoading) {
			if (selectedRimItem?.frontPicture && imageAvailable) {
				return (
					<>
						<img className="front-rim" onLoad={rimsLoaded} src={selectedRimItem.frontPicture} ref={ frontWheelRef as React.RefObject<HTMLImageElement> } />
						<img className="back-rim" onLoad={rimsLoaded} src={selectedRimItem.frontPicture} ref={backWheelRef as React.RefObject<HTMLImageElement>} />
					</>
				)
			}
			else
				return (
					<>
						<img className="front-rim" onLoad={rimsLoaded} src={noRimImage} ref={frontWheelRef as React.RefObject<HTMLImageElement>} />
						<img className="back-rim" onLoad={rimsLoaded} src={noRimImage} ref={backWheelRef as React.RefObject<HTMLImageElement>} />
					</>
				)
			}
	}

	const renderContent = () => {
		const { chassisImage64, shadowImage64, bodyImage64, brakeImage64 } = images

		const height = insideDialog && viewHeight(80) || percent(200)  // todo implemnt trim logic in ws

		return (
			<>
				{(loading || !selectedRimItem) && <Loader />}
				{!loading && selectedRimItem && !!images &&
					<div className="standard-configurator-container" id="2d-configurator-container" style={{ height, width: percent(50), top: percent(insideDialog ? -20 : -47)}}>
						{chassisImage64 && <img className="image" src={`data:image/png;base64,${chassisImage64}`} ref={imageRef3 } />}
						{shadowImage64 && <img className="image" src={`data:image/png;base64,${shadowImage64}`} ref={imageRef2 } />}
						{bodyImage64 && <img className="image" src={`data:image/png;base64,${bodyImage64}`} ref={bodyImageRef } />}

						{selectedRimItem?.frontPicture && brakeImage64 && !selectedRimLoading && <>
							<img className="front-brake" src={`data:image/png;base64,${brakeImage64}`} ref={frontBrake } />
							<img className="back-brake" src={`data:image/png;base64,${brakeImage64}`} ref={backBrake } />
						</>}
						{renderRimImages()}
					</div>}
			</>
		)
	}

	const height = insideDialog ? percent(100) : (renderHidden ? 0 : 300)
		const className = style({ flex: 1, height, overflow: "hidden" })
		return (
			<div className={className} ref={container} />
		)
}

function calculateBrakeWidth(pixelSize: number, scale: number, insideDialog: boolean = false) {
	return ((insideDialog ? 1 : 2) * (pixelSize * scale)) * scale || 0
}

function calculateRimWidth(pixelSize: number, scale: number, rimScale: number = 1, insideDialog: boolean = false): number {
	if (!pixelSize || !scale)
		return 0

	return ((insideDialog ? 1 : 2) * (pixelSize * scale) * rimScale) * scale
}

function newCalculationForRimWidth(rimSizePixel: number, scale: number, defaultRimSize: number, selectedSize: number) : number {
	if (!rimSizePixel || !scale || !defaultRimSize || !selectedSize)
		return 0

		return (rimSizePixel * selectedSize * scale ) / defaultRimSize
	}

function calculateTopPosition(centerY: number, width: number, scale: number) {
	if (!centerY || !width || !scale)
		return 0

	const radius = width / 2
	return centerY * scale - radius
}

function calculateLeftPosition(centerX: number, width: number, scale: number,) {
	if (!centerX || !width || !scale)
		return 0

	const radius = width / 2
	return centerX * scale - radius

}

const selector2D = createSelector((s: MainState) => ({
	images: s.wheelsList.configurator._2D.images,
	loading: s.wheelsList.configurator._2D.loading,
	rimSizes: s.wheelsList.configurator._2D.rimSizes,
	rimPositions: s.wheelsList.configurator._2D.rimPositions,
	renderHidden: s.wheelsList.configurator.renderHidden,
	selectedRimItem: s.wheelsList.base.selectedRimItem,
	display: s.wheelsList.configurator.display,
	has2DConfig: s.wheelsList.configurator.has2DConfig,
	show3D: s.wheelsList.configurator.show3D,
    selectedRimLoading: s.wheelsList.base.selectedRimLoading,
    selectedSize: s.wheelsList.base.selectedSize
}), x => x)

export const Wrapper: React.FC<{ insideDialog?: boolean }> = ({ insideDialog }) => {
	const { display, has2DConfig, images, loading, renderHidden, rimPositions, rimSizes, selectedRimItem, show3D, selectedRimLoading, selectedSize } = useSelector(selector2D)

	if (!((display || renderHidden) && has2DConfig && !show3D))
		return null

	return <StandardConfigurator
            insideDialog={insideDialog}
            images={images}
            selectedRimItem={selectedRimItem}
            loading={loading}
            selectedSize={selectedSize}
            selectedRimLoading={selectedRimLoading}
            rimSizes={rimSizes}
            rimPositions={rimPositions}
            renderHidden={renderHidden}
	/>
}

export default StandardConfigurator
