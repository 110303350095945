import { Button, CellContentPosition, Icon, Loader, Table, TableCellData, TableColumnData, TableRowData, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useDrivemotiveStore } from "../../data/state"
import { useGetBookings } from "../../hooks/useGetBookings"
import { NoResultHint } from "./components/NoResult"
import { Booking, statusTypes } from "../../data/models"

export default function Summary() {
    const { translateText, currency } = useLocalization()
    const token = useDrivemotiveStore((state) => state.main.drivemotiveToken)
    const [selectedRow, setSelectedRow] = useState<number>(0)
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)

    if (!token) {
        throw new Error("The authorization token is missing.")
    }

    const { bookings, isLoading } = useGetBookings(token)

    if (isLoading) {
        return <StyledLoader />
    }

    if (!bookings?.length) {
        return <NoResultHint />
    }

    const tableColumns: TableColumnData[] = [
        {
            header: translateText(1759).toLocaleUpperCase(),
        },
        {
            header: translateText(13152).toLocaleUpperCase(),
        },
        {
            header: `${translateText(1930)}, ${translateText(108)}`.toLocaleUpperCase(),
        },
        {
            header: translateText(99).toLocaleUpperCase(),
        },
        {
            header: translateText(1204).toLocaleUpperCase(),
        },
        {
            header: translateText(62).toLocaleUpperCase(),
            alignContent: CellContentPosition.right,
        },
        {
            alignContent: CellContentPosition.right,
        },
    ]

    function getCells(booking: Booking) {
        const date = new Date(booking.date_created)
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
        const day = date.getUTCDate().toString().padStart(2, "0")
        const year = date.getUTCFullYear()
        const dateString = `${day}.${month}.${year}`
        const services = booking.services?.map((service) => service.services_id?.title).join(", ")

        const cellData: TableCellData[] = [
            { displayValue: booking.status.charAt(0).toUpperCase() + booking.status.slice(1), id: "status" },
            { displayValue: dateString, id: "date_created" },
            { displayValue: `${booking.first_name} ${booking.last_name} / FIRMA`, id: "name" },
            {
                displayValue: booking.vehicle?.vehicle_type
                    ? `${booking.vehicle?.vehicle_type?.manufacturer} ${booking.vehicle?.vehicle_type?.model}`
                    : "",
                id: "vehicle",
            },
            { displayValue: services, id: "service" },
            { displayValue: `${currency(booking.price_range_from, currencyString)}`, id: "price" },
            {
                displayValue: (
                    <Button disabled={false} startIcon={<Icon name="details" />}>
                        {translateText(43)}
                    </Button>
                ),
                id: "details",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = bookings?.map((booking: Booking) => ({
        cells: getCells(booking),
        id: `${booking.id}`,
        customRow: false,
        active: booking.id === selectedRow,
        leftRowIndicatorColor: statusTypes.find((status) => booking.status === status.name)?.color,
    }))

    const onRowClick = (row: TableRowData) => {
        const selectedBooking = bookings?.find((booking) => booking.id.toString() === row.id)
        if (selectedBooking?.id) {
            setSelectedRow(selectedBooking?.id)
        }
    }

    return (
        <Table
            columns={tableColumns}
            rows={displayData}
            headerBackground="transparent"
            fontSize={14}
            headerStyle="default"
            rowCap={1}
            onRowClick={onRowClick}
            overflowY="auto"
            rowStyle="uniform"
        />
    )
}

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
})
