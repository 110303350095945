import { PropsWithChildren, memo } from "react"
import { Article } from "@tm/models"
import { ArticleItemStateProvider, useArticleItemState } from "."
import { DefaultArticleItemState } from "./models"
import { useArticleItem } from "../../../hooks/useArticleItem"

export type PartsListProps = {
    article: Article
}

export const DefaultArticleItemStateProvider = memo(({ article, children }: PropsWithChildren<PartsListProps>) => {
    const state = useArticleItem(article)

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
})

export function useDefaultArticleItemState(): DefaultArticleItemState
export function useDefaultArticleItemState<TReturnType>(selector: (value: DefaultArticleItemState) => TReturnType): TReturnType
export function useDefaultArticleItemState<TReturnType>(
    selector?: (value: DefaultArticleItemState) => TReturnType
): DefaultArticleItemState | TReturnType {
    return useArticleItemState<DefaultArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
