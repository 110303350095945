import { getStoredAuthorization, ajax, TmaHelper } from "@tm/utils"
import { FastCalculation } from "@tm/models"
import { getBasketServiceUrl } from "../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/fastCalculation`
}

export function importFastCalculation(request: FastCalculation.ImportFastCalculationRequest) {
    const url = `${getServiceUrl()}/ImportCalculation`
    const authorization = getStoredAuthorization()

    request.log = TmaHelper.AddCustomToBasket.GetUserInteractionLog()

    return ajax({ url, body: request, authorization, method: "POST" })
}
