import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useParams } from "react-router"
import { renderRoute } from "@tm/utils"
import { ReactNode, useState } from "react"
import { Icon, LinkButton, Stack, Typography, Popover, useTheme } from "@tm/components"
import tinycolor from "tinycolor2"
import { CostEstimationTotals } from "../../../../data/model"
import CostEstimationTotalsTable from "./CostEstimationTotalsTable"
import { SummaryRouteProps } from "../../component"
import { LoaderSmall, StyledBox } from "../../../StyledComponents"
import ErrorButton from "../ErrorButton"

type CostEstimationTotalsProps = {
    workTaskId: string
    totals?: CostEstimationTotals
    repairTimeDivision: number
    currencyCode: string
    costEstimationRoute: string
    showRepairTimesInHours: boolean
    hasErrors: boolean
    isLoading: boolean
    hideHours?: boolean
    swapTotals?: boolean
}

export default function CostEstimationTotalsComponent(props: CostEstimationTotalsProps) {
    const {
        currencyCode,
        repairTimeDivision,
        totals,
        costEstimationRoute,
        hasErrors,
        isLoading,
        swapTotals,
        hideHours,
        showRepairTimesInHours,
        workTaskId,
    } = props
    const { userContext } = useUser() ?? {}
    const { translate, translateText, currency, languageId, number } = useLocalization()
    const matchParams = useParams<SummaryRouteProps>()

    const theme = useTheme()
    const componentBackgroundColor = theme.overwrites?.components?.basketSummary?.component?.backgroundColor
    const linkButtonVariant =
        theme.overwrites?.components?.basketSummary?.linkButton?.variant || tinycolor(componentBackgroundColor).isDark() ? "textLight" : "text"
    const contrastColor = linkButtonVariant === "textLight" ? theme.palette.common.white : theme.palette.common.black
    const linkButtonBorderRadius = theme.overwrites?.components?.basketSummary?.linkButton?.borderRadius
    const linkButtonFontWeight = theme.overwrites?.components?.basketSummary?.linkButton?.fontWeight
    const linkButtonFontFamily = theme.overwrites?.components?.basketSummary?.linkButton?.fontFamily?.replaceAll("'", "")
    const linkButtonSize = theme.overwrites?.components?.basketSummary?.linkButton?.size

    const [costEstimationPopoverEl, setCostEstimationPopoverEl] = useState<HTMLDivElement>()

    function getCostEstimationUrl(): string {
        return renderRoute(costEstimationRoute, { ...matchParams })
    }

    const logo = languageId === "1" ? "voucher-kva-summary" : "voucher-kva-international-summary"
    const repairTimesUnits = showRepairTimesInHours || repairTimeDivision === 1 ? ` ${translateText(60)}` : ""
    const disabled = userContext?.parameter.catalogLight
    let repairTimesButtonContent: ReactNode = number(0, 2) + repairTimesUnits
    let costEstimationButtonContent: ReactNode = currency(0, currencyCode)

    if (hasErrors) {
        repairTimesButtonContent = ""
        costEstimationButtonContent = <ErrorButton costEstimationWithErrors workTaskId={workTaskId} />
    } else if (isLoading) {
        repairTimesButtonContent = <LoaderSmall />
        costEstimationButtonContent = <LoaderSmall />
    } else if (totals) {
        repairTimesButtonContent = number(totals.repairTimeTotal, 2) + repairTimesUnits
        costEstimationButtonContent = totals.offerPriceVatIncluded ? currency(totals.offerPriceVatIncluded, currencyCode) : "-"
    }

    return (
        <Stack direction={swapTotals ? "row-reverse" : "row"} paddingRight={1} alignItems="center">
            {!hideHours && (
                <LinkButton
                    sx={{
                        borderRadius: 0,
                        border: 0,
                        fontWeight: linkButtonFontWeight,
                        fontFamily: linkButtonFontFamily,
                    }}
                    variant={linkButtonVariant}
                    startIcon={<Icon name="repairtimes-summary" color={contrastColor} />}
                    title={hasErrors ? "" : translateText(83)}
                    to={getCostEstimationUrl()}
                    disabled={disabled}
                    size={linkButtonSize || "extralarge"}
                >
                    {repairTimesButtonContent}
                </LinkButton>
            )}

            <div
                onMouseEnter={(event) => {
                    setCostEstimationPopoverEl(event.currentTarget)
                }}
                onMouseLeave={() => {
                    setCostEstimationPopoverEl(undefined)
                }}
            >
                <LinkButton
                    sx={{
                        borderRadius: linkButtonBorderRadius || 0,
                        fontWeight: linkButtonFontWeight,
                        fontFamily: linkButtonFontFamily,
                    }}
                    variant={linkButtonVariant}
                    startIcon={<Icon name={logo} color={contrastColor} />}
                    to={getCostEstimationUrl()}
                    disabled={disabled}
                    size={linkButtonSize || "extralarge"}
                >
                    {costEstimationButtonContent}
                </LinkButton>
                {!hasErrors && (
                    <Typography variant="label" color={contrastColor} display="inline-block" marginTop={2.5}>
                        {`* ${translate(263)}`}
                    </Typography>
                )}
                {totals && !hasErrors && (
                    <Popover
                        open={!!costEstimationPopoverEl}
                        anchorEl={costEstimationPopoverEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        sx={{ pointerEvents: "none" }}
                    >
                        <StyledBox>
                            <CostEstimationTotalsTable totals={totals} currencyCode={currencyCode} showRepairTimesInHours={showRepairTimesInHours} />
                        </StyledBox>
                    </Popover>
                )}
            </div>
        </Stack>
    )
}
