import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article, ConfigParams, EArticleModificationState, ModificationState, Note, NoteTypes, SystemType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, renderRoute } from "@tm/utils"
import { useCallback, useMemo } from "react"
import { useRouteMatch } from "react-router"
// import { useSnackbar } from "../../../../../../../apps/portal/src/components/Snackbar" // Import is not working here for typechecking - find a different way
import { Stack, Typography } from "@tm/components"
import { printArticleDetails } from "../../../../data/repositories"
import { getBundleParams } from "../../../../utils"
import { createPrintArticleDetailsRequest } from "../../../_shared/helper"
import { usePartsModuleState } from "../../PartsModuleState"
import { ArticleAction } from "../../models"
import { ArticleErpInfo } from "../useErpInfos"
import { useGetRepairTimesUrl } from "../useGetRepairTimesUrl"
import { useVehicle } from "../useVehicle"
import { useHandleClickDetails } from "./useHandleClickDetails"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { ShowFeedbackForArticleType } from "../../ArticleListConfiguration/useArticleFeedbackConfiguration"

/**
 * @returns a tuple with the action and if it will be shown in the ...-menu
 */
export function useArticleActions(
    article: Article,
    onClickDetails: ReturnType<typeof useHandleClickDetails>,
    articleErpInfo?: ArticleErpInfo,
    notes?: Note[],
    toggleNotes?: () => void,
    toggleExpand?: () => void
) {
    const { translateText } = useLocalization()
    const getRepairTimesUrl = useGetRepairTimesUrl()
    const vehicle = useVehicle()
    const { userContext } = useUser()
    const routeMatch = useRouteMatch()
    const { showArticleDocumentsAsButton } = getBundleParams()
    const { feedbackConfiguration } = useArticleListConfiguration()

    const repairTimeProviders = usePartsModuleState(
        (x) => x.productGroupRepairTimes?.productGroupRepairTimes?.productGroups?.[article.productGroup.id]
    )
    const startParams = usePartsModuleState((x) => x.params.startParams)
    const showDocumentsInline = usePartsModuleState((x) => x.options.showDocumentsInline)

    // const { addMessage: addSnackbarMessage } = useSnackbar()

    const articleErpInfoResponse = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined

    const isRedesignSystem = useMemo(() => userContext.system.systemType === SystemType.Redesign, [userContext])

    const handleClickDetails = useCallback(() => onClickDetails(), [onClickDetails])

    const onClickDocuments = useMemo(() => {
        if (article.existPdf && !showDocumentsInline && !showArticleDocumentsAsButton) {
            return () => onClickDetails("documents")
        }
    }, [showDocumentsInline, article, showArticleDocumentsAsButton, onClickDetails])

    const onClickRepairTimes = useMemo(() => {
        const hasRepairTimes = !!repairTimeProviders?.length
        if (hasRepairTimes && repairTimeProviders) {
            return () => {
                const url = getRepairTimesUrl(article, repairTimeProviders)
                if (url) {
                    Morpheus.showView("1", url)
                }
            }
        }
    }, [article, getRepairTimesUrl, repairTimeProviders])

    const onFeedback = useMemo(() => {
        const showArticleFeedback = () => {
            if (feedbackConfiguration.isEnabled && feedbackConfiguration.feedbackPath) {
                switch (feedbackConfiguration.showFeedbackForArticleType) {
                    case ShowFeedbackForArticleType.OnlyTraderArticles:
                        return !!article.traderArticleNo
                    case ShowFeedbackForArticleType.ArticlesWithoutTraderReference:
                        return !article.traderArticleNo
                    default:
                        return true
                }
            }
            return false
        }

        if (showArticleFeedback()) {
            return () => {
                const url =
                    renderRoute(feedbackConfiguration.feedbackPath, {
                        ...routeMatch.params,
                        productGroupId: article.productGroup.id,
                        supplierId: article.supplier.id,
                        supplierName: article.supplier.name,
                        supplierArticleNo: article.supplierArticleNo,
                        itemId: article.id,
                        quantityValue: article.quantity || 1,
                        productGroupName: article.productGroup.name,
                        traderArticleNo: article.traderArticleNo || undefined,
                    }) + createQueryString({ articleDescription: article.description })
                Morpheus.showView("1", url)
            }
        }
    }, [article, feedbackConfiguration, routeMatch])

    const onClickSearchAlternatives = useMemo(() => {
        if (
            startParams.type.startsWith("uni") ||
            Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp ||
            getBundleParams().hideAdditionalArticleAlternatives ||
            isRedesignSystem
        ) {
            return
        }
        return () => {
            // TODO: This Url shouldn't be hardoded. It should be taken from the ui config props
            const articleAlternativesRoute = "/:workTaskId/modal^/part-alternatives/list/alternatives"
            const { supplierArticleNo, productGroup } = article

            if (!articleAlternativesRoute) {
                return
            }

            const queryParams = new URLSearchParams()
            queryParams.set("query", supplierArticleNo)
            queryParams.set("productGroupIds", productGroup.id.toString())

            if (article.supplier?.manufacturerId) {
                queryParams.set("oeManufacturerIds", article.supplier.manufacturerId.toString())
            }

            const url = `${renderRoute(articleAlternativesRoute, { ...routeMatch.params })}?${queryParams.toString()}`

            Morpheus.showView("1", url)
        }
    }, [article, isRedesignSystem, routeMatch.params])

    const onClickPrint = useMemo(() => {
        return async () => {
            // const { removeMessage } = addSnackbarMessage(
            //     <Stack>
            //         <Box>{translateText(13510)}</Box>
            //         <LinearProgress />
            //     </Stack>,
            //     "info",
            //     <Icon name="print" color="#fff" />,
            //     false,
            //     false
            // )
            await printArticleDetails(
                createPrintArticleDetailsRequest(
                    article,
                    vehicle?.tecDocTypeId,
                    vehicle?.vehicleType,
                    vehicle?.registrationNo,
                    vehicle?.registrationTypeId
                ),
                translateText
            )
            // removeMessage()
        }
    }, [article, /* addSnackbarMessage, */ translateText, vehicle])

    const onClickPartsList = useMemo(() => {
        if (!article.isPartsListAvailable || isRedesignSystem) {
            return
        }
        return () => onClickDetails("partslist")
    }, [article.isPartsListAvailable, onClickDetails, isRedesignSystem])

    const onClickAccessoryList = useMemo(() => {
        if (!article.isAccessoryListAvailable || isRedesignSystem) {
            return
        }
        return () => onClickDetails("accessories")
    }, [article.isAccessoryListAvailable, onClickDetails, isRedesignSystem])

    const onClickErpAlternatives = useMemo(() => {
        if (
            (!Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp && !articleErpInfoResponse?.hasAlternatives) ||
            isRedesignSystem
        ) {
            return
        }
        return () => onClickDetails("alternative-articles")
    }, [articleErpInfoResponse?.hasAlternatives, onClickDetails, isRedesignSystem])

    const onClickAdditionalInformations = useMemo(() => {
        const { references, additionalReferenceArticleInformation, articleModificationState, referencedArticleModification } = article
        const hasModificationState =
            articleModificationState === EArticleModificationState.ArticleAdded ||
            articleModificationState === EArticleModificationState.VehicleLinkageAdded
        const hasAdditionalInfo =
            !!additionalReferenceArticleInformation?.textInformation.length || !!additionalReferenceArticleInformation?.links.length
        const hasReferences = references?.some((x) => x && x.modificationState === ModificationState.Added)
        const hasReferencedArticleModification = referencedArticleModification?.isAddedReferencedArticle || false

        const hasAdditionalInformation = hasModificationState || hasAdditionalInfo || hasReferences || hasReferencedArticleModification

        if (hasAdditionalInformation) {
            return toggleExpand
        }
    }, [article])

    const articleActions = useMemo(() => {
        const { showAdditionalArticleAlternativesAsButton, showArticleRepairTimesAsButton, showArticleNotesAsButton, showArticlePrintAsButton } =
            getBundleParams()

        const actionList: ArticleAction[] = []
        if (onClickAdditionalInformations) {
            actionList.push({
                id: "ims",
                area: "CENTER_ICONS",
                text: translateText(912),
                icon: "trader-icon",
                handler: onClickAdditionalInformations,
            })
        }
        if (onClickPartsList) {
            actionList.push({
                id: "partslist",
                area: "CENTER_ICONS",
                text: translateText(1525),
                icon: "article_partslist",
                handler: onClickPartsList,
            })
        }
        if (onClickAccessoryList) {
            actionList.push({
                id: "accessories",
                area: "CENTER_ICONS",
                text: translateText(349),
                icon: "accessories",
                handler: onClickAccessoryList,
            })
        }
        if (onClickErpAlternatives) {
            actionList.push({
                id: "erpAlternatives",
                area: "CENTER_BUTTONS",
                text: translateText(875),
                icon: "alternative-article",
                handler: onClickErpAlternatives,
            })
        }
        if (onClickSearchAlternatives) {
            actionList.push({
                id: "searchAlternatives",
                area: showAdditionalArticleAlternativesAsButton ? "RIGHT_ICONS" : "RIGHT_MENU",
                text: translateText(636),
                icon: "search",
                handler: onClickSearchAlternatives,
            })
        }
        if (onClickDocuments) {
            actionList.push({
                id: "showDocuments",
                area: showArticleDocumentsAsButton ? "RIGHT_ICONS" : "RIGHT_MENU",
                text: translateText(350),
                icon: "file-pdf",
                handler: onClickDocuments,
            })
        }
        if (onClickRepairTimes) {
            actionList.push({
                id: "showRepairTimes",
                area: showArticleRepairTimesAsButton ? "CENTER_BUTTONS" : "RIGHT_MENU",
                text: translateText(83),
                icon: "repairtimes",
                handler: onClickRepairTimes,
            })
        }
        if (onFeedback) {
            actionList.push({
                id: "showArticleFeedback",
                area: feedbackConfiguration.showFeedbackAsButton ? "CENTER_BUTTONS" : "RIGHT_MENU",
                text: translateText(41),
                icon: "feedback",
                handler: onFeedback,
            })
        }
        actionList.push({
            id: "details",
            area: "CENTER_BUTTONS",
            text: translateText(35),
            icon: "details",
            handler: handleClickDetails,
        })

        if (toggleNotes) {
            actionList.push({
                id: "notes",
                area: showArticleNotesAsButton ? "CENTER_BUTTONS" : "RIGHT_MENU",
                text: translateText(12899),
                icon: "note",
                handler: toggleNotes,
            })
            if (notes?.length) {
                const tooltip = (
                    <Stack gap={1}>
                        {notes.map((x) => (
                            <Stack key={x.noteId}>
                                <Typography variant="label" color="inherit">
                                    {translateText(x.type === NoteTypes.VEHICLE_ARTICLE ? 12875 : 12874)}
                                </Typography>
                                {x.text}
                            </Stack>
                        ))}
                    </Stack>
                )
                actionList.push({
                    id: "notes",
                    area: "RIGHT_ICONS",
                    text: translateText(12879),
                    tooltip,
                    icon: "notes-added",
                    handler: toggleNotes,
                    isHighlight: true,
                })
            }
        }
        if (onClickPrint) {
            actionList.push({
                id: "print",
                area: showArticlePrintAsButton ? "RIGHT_ICONS" : "RIGHT_MENU",
                text: translateText(49),
                icon: "print",
                handler: onClickPrint,
            })
        }
        return actionList
    }, [
        feedbackConfiguration,
        handleClickDetails,
        notes,
        onClickAccessoryList,
        onClickAdditionalInformations,
        onClickDocuments,
        onClickErpAlternatives,
        onClickPartsList,
        onClickPrint,
        onClickRepairTimes,
        onClickSearchAlternatives,
        onFeedback,
        showArticleDocumentsAsButton,
        toggleNotes,
        translateText,
    ])

    return articleActions
}
