import { ChangeItemsResponse, ImportCostEstimationVoucherRequest, Item } from "@tm/models"
import { getStoredAuthorization, ajax, convertBase64ImageToByteArray } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import { ChangeIsIncludedResponse, SendCostEstimationRequest, SendCostEstimationResponse, ShowTotalNumbersByWorkTaskResponse } from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/costEstimation`
}

// TODO: add in the request the vehicle id, so it is easier to identify if a vehicle indeed has changed for the same work task
export function attachVehicleImage(workTaskId: string, vehicleImageBase64: string) {
    const url = `${getServiceUrl()}/AttachVehicleImage`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, vehicleImage: convertBase64ImageToByteArray(vehicleImageBase64) }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function removeVehicleImage(workTaskId: string) {
    const url = `${getServiceUrl()}/RemoveVehicleImage`
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function resetCostEstimation(workTaskId: string) {
    const url = `${getServiceUrl()}/ResetCostEstimation `
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function showTotalNumbersByWorkTask(workTaskIdList: Array<string>): Promise<ShowTotalNumbersByWorkTaskResponse | undefined> {
    const url = `${getServiceUrl()}/ShowTotalNumbersByWorkTask`
    const authorization = getStoredAuthorization()
    const body = { workTaskIdList }

    return ajax({ url, body, authorization, method: "POST" })
}

export function includeParts(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeParts`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeIsIncludedResponse>({ url, body, authorization, method: "POST" })
}

export function excludeParts(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeParts`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeIsIncludedResponse>({ url, body, authorization, method: "POST" })
}

export function includeWorks(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeWorkList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function excludeWorks(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeWorkList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, itemList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function changeRepairTimeCalculation(workTaskId: string, useRepairTimeCalculation: boolean) {
    const url = `${getServiceUrl()}/ChangeRepairTimeCalculation`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, useRepairTimeCalculation }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function importCostEstimationVoucher(body: ImportCostEstimationVoucherRequest) {
    const url = `${getServiceUrl()}/ImportVoucher`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

// Check if this must be included in useBasketState
export function sendCostEstimation(body: SendCostEstimationRequest) {
    const url = `${getServiceUrl()}/SendCostEstimation`
    const authorization = getStoredAuthorization()

    return ajax<SendCostEstimationResponse>({ url, body, authorization, method: "POST" })
}
