import { Article, OE } from "@tm/models"
import { memo } from "react"
import { DefaultArticleItem, DefaultArticleItemProps } from "./variants/DefaultArticleItem"
import { OeInformationItem } from "./variants/OeInformationItem"
import { OeInformationArticle } from "../OeInformation"
import { DefaultArticleItemStateProvider } from "./ContextProviderStates/DefaultArticleItemState"
import { OeArticleItemStateProvider } from "./ContextProviderStates/OeArticleItemState"
import { OeInformationItemStateProvider } from "./ContextProviderStates/OeInformationItemState"
import { OeArticleItem } from "./variants/OeArticleItem"

/**
 * Based on the `variant` the properties are used from the underlying component.
 * If `variant` is not supplied it is assumed to be `PartsList`.
 */
type ArticleItemProps =
    | ({
          variant?: "default"
          article: Article
      } & DefaultArticleItemProps)
    | {
          variant: "KVA" | "Basket"
          article: Article
      }
    | {
          variant: "OE"
          article: OE.OePart
      }
    | {
          variant: "OeInformation"
          article: OeInformationArticle
      }

export const ArticleItem = memo(({ variant, article }: ArticleItemProps) => {
    switch (variant) {
        case "KVA":
            return null
        case "Basket":
            return null
        case "OE":
            return (
                <OeArticleItemStateProvider article={article}>
                    <OeArticleItem />
                </OeArticleItemStateProvider>
            )
        case "OeInformation":
            return (
                <OeInformationItemStateProvider article={article}>
                    <OeInformationItem />
                </OeInformationItemStateProvider>
            )
        case "default":
        default:
            return (
                <DefaultArticleItemStateProvider article={article}>
                    <DefaultArticleItem />
                </DefaultArticleItemStateProvider>
            )
    }
})
