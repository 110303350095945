import { ajax, getStoredAuthorization } from "@tm/utils"
import {
    CreateCustomArticleRequest as SaveCustomArticleRequest,
    DeleteCustomArticlesRequest,
    GetCustomArticlesCategoriesResponse,
    GetCustomArticlesRequest,
    GetCustomArticlesResponse,
} from "@tm/models"
import { getBundleParams } from "../../../utils"

function getServiceUrl() {
    return getBundleParams().customArticlesServiceUrl
}

export function getCustomArticles(body: GetCustomArticlesRequest) {
    return ajax<GetCustomArticlesResponse>({ url: `${getServiceUrl()}/Get`, body, authorization: getStoredAuthorization() }).then((response) => ({
        data: response?.articles ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.articles?.length ?? 0) < body.pageSize,
    }))
}

export function saveCustomArticle(body: SaveCustomArticleRequest) {
    return ajax<{ id: string }>({ url: `${getServiceUrl()}/Save`, body, authorization: getStoredAuthorization(), method: "POST" }).then(
        (response) => {
            return response?.id
        }
    )
}

export function deleteCustomArticles(body: DeleteCustomArticlesRequest) {
    return ajax<void>({ url: `${getServiceUrl()}/Delete`, body, authorization: getStoredAuthorization(), method: "POST" })
}

export function getCustomArticlesCategories() {
    return ajax<GetCustomArticlesCategoriesResponse>({ url: `${getServiceUrl()}/GetCategories`, authorization: getStoredAuthorization() }).then(
        (response) => response?.categories ?? []
    )
}
