import { ModuleTab, ViewState } from "@tm/models"
import { equals } from "@tm/utils"
import { ExtendedModuleTab, ModuleNavigationState } from "."
import { saveState } from "../../../data"

export function isModalUrl(url: string) {
    return decodeURIComponent(url).indexOf("?(1)=") !== -1
}

export function isSameTab(a: ModuleTab, b: ModuleTab): boolean {
    return a.key && b.key ? a.key === b.key : a.title === b.title && isModalUrl(a.url) === isModalUrl(b.url)
}

export function saveCurrentState(state: ModuleNavigationState) {
    const viewState: ViewState = {
        key: `${state.workTaskId}__module-navigation__`,
        value: {
            ...state,
            tabs: state.tabs.map((x) => ({ ...x, isSelected: undefined })),
        },
    }

    sessionStorage.setItem(`${state.workTaskId}_openModules`, JSON.stringify(state.tabs))
    saveState(viewState)
}

export function mergeTabs(state: ModuleNavigationState, loadedTabs: Array<ModuleTab>) {
    const openTabs = state.tabs.filter((x) => !!x.title).concat(state.more.filter((more) => more.title)) // Currently already opened tabs
    const tabs: Array<ModuleTab> = [] // Merged tabs

    // Add all loaded tabs
    loadedTabs
        .filter((loaded) => {
            return !!loaded.title && !state.tabs.some((x) => x.title === loaded.title)
        })
        .forEach((loadedTab) => {
            tabs.push({
                ...loadedTab,
                ...(openTabs.find((x) => isSameTab(x, loadedTab)) || {}), // If same tab is already opened, overwrite
            })
        })

    // Add already opened tabs which were are not already included in "tabs" and therefore were not in "loadedTabs"
    openTabs.filter((x) => !tabs.some((y) => isSameTab(y, x))).forEach((openTab) => tabs.push(openTab))

    const thisState = {
        ...state,
        initialized: true,
        splitPosition: undefined,
        loading: false,
        tabs: [...tabs.filter((x) => x.isDefault), ...tabs.filter((x) => !x.isDefault)],
        more: [],
    }

    saveCurrentState(thisState)

    return thisState
}

export function addTabToState(state: ModuleNavigationState, tab?: ModuleTab) {
    const tabs = [...state.tabs.concat(state.more).map((x) => ({ ...x, isSelected: false }))] as ExtendedModuleTab[]
    if (!tab) {
        return {
            ...state,
            tabs: [...tabs.filter((x) => state.activateDefaultTabs && x.isDefault), ...tabs.filter((x) => state.activateDefaultTabs && !x.isDefault)],
            more: [],
            splitted: false,
        }
    }
    const thisTab = { ...tab } as ModuleTab
    const { location } = window

    let pos = tabs.findIndex((x) => isSameTab(x, thisTab))
    if (pos === -1 && location.search && isModalUrl(location.search)) {
        // Check if a modal is opened and on of his components send a MODULE_OPENED. In this case, we have to take the existing parent tab.
        pos = tabs.findIndex((x) => x.title !== thisTab.title && x.url.indexOf(location.pathname) !== -1)
        if (pos !== -1) {
            thisTab.title = tabs[pos].title
        }
    }

    if (pos < 0) {
        // Tab does not exist
        pos = tabs.length
        tabs.push(thisTab)
    } else if (!equals(thisTab, tabs[pos])) {
        // Tab has changed
        tabs.splice(pos, 1, {
            ...thisTab,
            isDefault: tabs[pos].isDefault,
            vehicleDependency: tabs[pos].vehicleDependency,
            moduleGroupId: tabs[pos].moduleGroupId,
            moduleId: tabs[pos].moduleId,
        })
    }

    const thisState = {
        ...state,
        tabs: [...tabs.filter((x) => x.isDefault), ...tabs.filter((x) => !x.isDefault)],
        more: [],
        splitPosition: undefined,
    }

    if (thisState.initialized) {
        saveCurrentState(thisState)
    }

    tabs[pos].isSelected = true
    return thisState
}

export function removeTabFromState(state: ModuleNavigationState, tab: ModuleTab) {
    const thisState = {
        ...state,
        tabs: state.tabs.concat(state.more).filter((x) => x !== tab),
        more: [],
    }

    saveCurrentState(thisState)

    return thisState
}

export function removeTabFromStateById(state: ModuleNavigationState, id: string) {
    const thisState = {
        ...state,
        tabs: state.tabs.concat(state.more).filter((x) => x.url !== id),
        more: [],
    }

    saveCurrentState(thisState)

    return thisState
}
