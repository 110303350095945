import { Container, IModelContainer } from "@tm/nexus"
import { PrepareCalculationRequest, PrepareCalculationResponse } from "./prepareCalculation/model"
import { UpdateTotalPricesRequest, UpdateTotalPricesResponse } from "./updateTotalPrices"
// eslint-disable-next-line import/no-self-import
import { prepareCalculation, getVinSuggestions, updateTotalsPrices, importToCostEstimation, getCalculationData, getUrlForPage } from "."
import { CostEstimationRequest } from "./costEstimate"
import { GetCalculationDataRequest, GetCalculationDataResponse } from "./getCalculationData/model"

export * from "./costEstimate"
export * from "./prepareCalculation"
export * from "./updateTotalPrices"
export * from "./vinSuggestions"
export * from "./getCalculationData"
export * from "./getUrlForPage"

export const AsWegasKey = "dmg-calc-aswegas"

export const initRepositories = () => {
    Container.register({
        name: AsWegasKey,
        containerActions: {
            prepareCalculation,
            getVinSuggestions,
            importToCostEstimation,
            updateTotalsPrices,
            getCalculationData,
            getUrlForPage,
        },
    })
}

export const useContainer = () => Container.getInstance(AsWegasKey) as AsWegasContainer

type AsWegasContainerActions =
    | { name: "prepareCalculation"; action: (req: PrepareCalculationRequest) => Promise<PrepareCalculationResponse> }
    | { name: "getVinSuggestions"; action: (val: string, tecdoc?: number) => Promise<string[]> }
    | { name: "importToCostEstimation"; action: (req: CostEstimationRequest) => Promise<void> }
    | { name: "updateTotalsPrices"; action: (req: UpdateTotalPricesRequest) => Promise<UpdateTotalPricesResponse> }
    | { name: "getCalculationData"; action: (req: GetCalculationDataRequest) => Promise<GetCalculationDataResponse> }
    | { name: "getUrlForPage"; action: (taskId?: string) => Promise<string> }

export type AsWegasContainer = IModelContainer<void, AsWegasContainerActions>
