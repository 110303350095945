import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import FeatureSwitchComponent from "./feature-switch-component"

const widget: BundleComponent<unknown, typeof FeatureSwitchComponent> = {
    name: "widget",
    reduce,
    component: FeatureSwitchComponent,
    transmit,
}

export default widget
