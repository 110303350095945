import { Fragment } from "react"
import { Divider } from "@tm/components"
import { AdditionalMenuItemComponent, MenuItem, isLinkMenuItem } from "./AdditionalMenuItem"

export type AdditionalMenuItem = MenuItem &
    Partial<MenuItem> & {
        children?: MenuItem[]
    }

type Props = {
    items: AdditionalMenuItem[]
    onClick?(): void
}

function generateKey(item: MenuItem): string {
    if (isLinkMenuItem(item)) {
        return item.path
    }

    if (item.externalModuleConfiguration) {
        return `${item.externalModuleConfiguration.typeId}-${item.externalModuleConfiguration.documentTypeId}`
    }

    return ""
}

export function AdditionalMenuItems({ items, onClick }: Props) {
    return (
        <>
            {items.map((parent) => (
                <Fragment key={generateKey(parent)}>
                    <AdditionalMenuItemComponent item={parent} onClick={onClick} />
                    {parent.children?.map((x) => (
                        <AdditionalMenuItemComponent key={generateKey(x)} item={x} isSubMenuItem onClick={onClick} />
                    ))}
                    <Divider />
                </Fragment>
            ))}
        </>
    )
}
