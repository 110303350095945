import { CreateCustomArticleRequest as SaveCustomArticleRequest, CustomArticle, GetCustomArticlesRequest } from "@tm/models"
import { deleteCustomArticles, getCustomArticles, saveCustomArticle } from "../../repositories/custom-items/articles"
import { useCustomItems, useDeleteCustomItem, useEditCustomItem } from "./useCustomItems"

const QUERY_KEY = "basket__custom-articles"

export function useCustomArticles(request: Omit<GetCustomArticlesRequest, "pageIndex" | "pageSize">) {
    return useCustomItems(request, { queryKey: QUERY_KEY, dataFn: getCustomArticles })
}

export function useDeleteCustomArticles() {
    return useDeleteCustomItem<Array<string>, void, Array<CustomArticle>>({
        queryKey: QUERY_KEY,
        dataFn: (ids) => deleteCustomArticles({ ids }),
        filterFn: (_, ids, prevData) => prevData.filter((x) => !ids.includes(x.id)),
    })
}

export function useEditCustomArticle() {
    return useEditCustomItem<SaveCustomArticleRequest, string | undefined, Array<CustomArticle>>({
        queryKey: QUERY_KEY,
        dataFn: saveCustomArticle,
        mutateFn: (id, customArticle, prevData) => prevData.map((x) => (x.id === id ? { ...x, ...customArticle } : x)),
    })
}
