import { CustomRepairTime, GetCustomRepairTimesRequest, CreateCustomRepairTimeRequest as SaveCustomRepairTimeRequest } from "../../model/custom-items"
import { getCustomRepairTimes, deleteCustomRepairTimes, saveCustomRepairTime } from "../../repositories/custom-items/repair-times"
import { useCustomItems, useDeleteCustomItem, useEditCustomItem } from "./useCustomItems"

const QUERY_KEY = "basket__custom-repairTimes"

export function useCustomRepairTimes(request: Omit<GetCustomRepairTimesRequest, "pageIndex" | "pageSize">) {
    return useCustomItems(request, { queryKey: QUERY_KEY, dataFn: getCustomRepairTimes })
}

export function useDeleteCustomRepairTimes() {
    return useDeleteCustomItem<Array<string>, void, Array<CustomRepairTime>>({
        queryKey: QUERY_KEY,
        dataFn: (ids) => deleteCustomRepairTimes({ ids }),
        filterFn: (_, ids, prevData) => prevData.filter((x) => !ids.includes(x.id)),
    })
}

export function useEditCustomRepairTime() {
    return useEditCustomItem<SaveCustomRepairTimeRequest, string | undefined, Array<CustomRepairTime>>({
        queryKey: QUERY_KEY,
        dataFn: saveCustomRepairTime,
        mutateFn: (id, customRepairTime, prevData) => prevData.map((x) => (x.id === id ? { ...x, ...customRepairTime } : x)),
    })
}
