import { useUser } from "@tm/context-distribution"
import { Button, Dropdown, Headline, Loader, Radio, SearchField, SubTitle, Tag } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CisCustomerState, CisFindCustomerDto, CisQueryType, Customer, CustomerContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { getCollectiveCustomerParam } from "../../helpers"
import {
    INITIAL_STATE,
    loadViewState,
    reduce,
    resetPartner,
    searchCurrentActivePartner,
    SearchHistoryItem,
    searchPartner,
    setDefaultQueryType,
} from "./business"
import { ActiveCustomer } from "../shared/extra-search-buttons/ActiveCustomer"
import { PartnerList } from "./components/list"
import { NoResults } from "./components/no-results"
import { getComponentStyles } from "./styles"
import useWindowDimensions from "./useWindowDimensions"
import { useCreateWorktask } from "../../data/hooks/useShowWorktask"
import { useLoadQueryTypes } from "../../data/hooks/useQueries"
import { getBundleParams } from "../../utils"

export type Props = RouteComponentProps & {
    twoColumns?: boolean
    styles: NestedCSSProperties
    action?: React.ReactNode
}

function PartnerSearchComponent(props: Props) {
    const [state, dispatch] = React.useReducer(reduce, INITIAL_STATE)
    const { searchHistory, partnerList, partnerLoading, query, defaultQueryTypeId } = state
    const { queryTypes, isLoading: queryTypesLoading } = useLoadQueryTypes()
    const className = style(getComponentStyles(props.styles))
    const { translateText } = useLocalization()
    const { isLoading, createWorktask } = useCreateWorktask()
    const { userContext } = useUser()
    const { hideCollectivePartnerButton } = getBundleParams()

    const [queryType, setQueryType] = React.useState<CisQueryType>()
    const inputRef = React.useRef<HTMLDivElement>(null)
    const { width } = useWindowDimensions()

    // Calculate max window with for searchResults
    const resultlistSize = React.useMemo(() => {
        if (inputRef.current?.getBoundingClientRect().x && width) {
            return `${Math.round(width - inputRef.current?.getBoundingClientRect().x) - 50}px`
        }

        return "100%"
    }, [inputRef.current, width, query])

    React.useEffect(() => {
        loadViewState(dispatch)
    }, [])

    const hasCollectiveCustomer = React.useMemo(() => {
        return !!getCollectiveCustomerParam(userContext)
    }, [userContext])

    React.useEffect(() => {
        if (queryTypes && queryTypes.length) {
            let queryType = queryTypes[0]

            if (defaultQueryTypeId) {
                queryType = queryTypes.find((x) => x.id === defaultQueryTypeId) || queryType
            }

            setQueryType(queryType)
        }
    }, [queryTypes, defaultQueryTypeId])

    React.useEffect(() => {
        if (partnerList?.length === 1) {
            if (partnerList[0].state !== CisCustomerState.TSMBlocked) {
                handleSelectPartner(partnerList[0])
            }
        }
    }, [partnerList])

    function handleSearchStart(query: string) {
        if (query && queryType) {
            searchPartner(dispatch, state, query, queryType.id)
        } else {
            resetPartner(dispatch)
        }
    }

    function handleHistoryClick(item: SearchHistoryItem) {
        setQueryType(queryTypes?.find((x) => x.id == item.queryTypeId))
        searchPartner(dispatch, state, item.query, item.queryTypeId)
    }

    function handleSearchReset() {
        resetPartner(dispatch)
    }

    function handleQueryTypeChange(queryType: CisQueryType) {
        setDefaultQueryType(dispatch, state, queryType.id)
        setQueryType(queryType)
        resetPartner(dispatch)
    }

    const prepareWorktaskToBeCreated = (customer: Customer) => {
        if (isLoading) {
            return
        }

        createWorktask(customer.id).then((worktask) => {
            const url = `/${encodeUniqueId(worktask.workTaskId)}`
            props.history.push(url)
        })
    }

    function handleSelectPartner(partner: CisFindCustomerDto) {
        const container = Container.getInstance(RegisteredModels.Customer) as CustomerContainer

        container
            .action("findCustomer")({ refCustomerNo: partner.partnerNo })
            .then(
                (customer) => {
                    // save customer in case there's a difference in the next db
                    container
                        .action("saveCustomer")(
                            {
                                ...customer,
                                companyName: partner.company,
                                refCustomerNo: partner.partnerNo,
                                displayCustomerNo: partner.customerNumberToShow,
                                city: partner.city,
                                street: partner.street,
                                zip: partner.zip,
                                firstName: partner.contactFirstName,
                                lastName: partner.contactLastName,
                                phone: partner.phone,
                            },
                            true
                        )
                        .then(prepareWorktaskToBeCreated)
                },
                () => {
                    container
                        .action("saveCustomer")(
                            {
                                id: uniqueId(),
                                companyName: partner.company,
                                refCustomerNo: partner.partnerNo,
                                displayCustomerNo: partner.customerNumberToShow,
                                city: partner.city,
                                street: partner.street,
                                zip: partner.zip,
                                firstName: partner.contactFirstName,
                                lastName: partner.contactLastName,
                                phone: partner.phone,
                            },
                            true
                        )
                        .then(prepareWorktaskToBeCreated)
                }
            )
    }

    function renderQueryTypeItem(item: CisQueryType) {
        return (
            <div className={`${className}__querytype-item`}>
                <Radio
                    size="s"
                    checked={(queryType?.id ?? defaultQueryTypeId) === item.id}
                    value={item.id}
                    onCheck={() => {
                        /* do nothing */
                    }}
                />
                {item.description}
            </div>
        )
    }

    const handleActiveCustomerClick = React.useCallback(() => searchCurrentActivePartner(dispatch), [state])

    return (
        <div className={`${className} partner-search-component ${props.twoColumns ? "two-columns" : ""}`}>
            <div className="one-third" />

            <div className="one-third">
                <div className={`${className}__header`}>
                    <Headline size="s" className={`${className}__headline`}>
                        {translateText(1766)}
                    </Headline>
                    <Loader visible={partnerLoading} />
                </div>

                <div className={style({ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" })}>
                    <div className={`${className}__search-container `}>
                        <div
                            className={`${className}__field-container  ${partnerList ? "has-result" : ""} partner-searchfield-container`}
                            ref={inputRef}
                        >
                            <Dropdown
                                className={`${className}__querytype ${partnerList ? "has-result" : ""} partner-dropdown-button`}
                                layout={["iconRight"]}
                                items={queryTypes || []}
                                itemView={renderQueryTypeItem}
                                displayView={() => <div>{queryType?.description}</div>}
                                value={queryType}
                                onChange={handleQueryTypeChange}
                            />

                            <div className={`${className}__input__wrapper input`}>
                                <SearchField
                                    autoComplete="off"
                                    showSearch
                                    showClear
                                    size="xl"
                                    value={query}
                                    className={`${className}__search ${partnerList ? "has-result" : ""}`}
                                    onChangeConfirm={handleSearchStart}
                                    onChangeReset={handleSearchReset}
                                />
                            </div>

                            <Loader className={`${className}__querytype-loader`} visible={queryTypesLoading} />
                            <Loader className={`${className}__search-loader`} visible={partnerLoading} />
                        </div>
                        {partnerList && (
                            <div className={`${className}__search-resultlist ${style({ maxWidth: resultlistSize })}`}>
                                {partnerList.length > 0 ? (
                                    <PartnerList items={partnerList} onSelectItem={handleSelectPartner} />
                                ) : (
                                    <NoResults label={state.searchType === "CURRENT_CALLER" ? translateText(12822) : translateText(12446)} />
                                )}
                            </div>
                        )}
                    </div>

                    <ActiveCustomer onClick={handleActiveCustomerClick} label={translateText(12811)} isLoading={state.partnerLoading} />
                </div>
                {!props.twoColumns && (
                    <div className={`${className}__history`}>
                        {searchHistory && (
                            <>
                                <SubTitle size="s" className={`${className}__history-title`}>
                                    {translateText(1765)}
                                </SubTitle>
                                {searchHistory.map((item) => (
                                    <Tag key={`${item.queryTypeId}_${item.query}`} value={item.query} onClick={() => handleHistoryClick(item)} />
                                ))}
                            </>
                        )}
                    </div>
                )}

                <div className={`${className}__actions`}>
                    {hasCollectiveCustomer && !hideCollectivePartnerButton && (
                        <Button icon="plus" linkTo={`/${encodeUniqueId(uniqueId())}`} className={`${className}__partnerless`}>
                            {translateText(1764)}
                        </Button>
                    )}
                    {props.action}
                </div>
            </div>

            <div className="one-third">
                {props.twoColumns && (
                    <div className={`${className}__history`}>
                        {searchHistory && (
                            <>
                                <SubTitle size="s" className={`${className}__history-title`}>
                                    {translateText(1765)}
                                </SubTitle>
                                {searchHistory.map((item) => (
                                    <Tag key={`${item.queryTypeId}_${item.query}`} value={item.query} onClick={() => handleHistoryClick(item)} />
                                ))}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withRouter(PartnerSearchComponent)
