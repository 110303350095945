import { ShowCarModelDetailsRequest, ShowCarModelDetailsResponse } from "@tm/models"
import { Container, IModelContainer } from "@tm/nexus"
import { CostEstimationRequest } from "./costEstimate/model"
import { CustomerBillingResponse, handleCustomerBilling } from "./handleCustomerBilling"
import { DATVehicleVinSearchResponse } from "./datVehicleVinSearch/model"
import { GetCalculationDataRequest, GetCalculationDataResponse, getCalculationData } from "./getCalculationData"
import { BaseInfo, PrepareCalculationRequest, PrepareCalculationResponse, prepareCalculation } from "./prepareCalculation"
import { UpdateTotalPricesRequest, UpdateTotalPricesResponse, updateTotalsPrices } from "./updateTotalPrices"
import { CheckCustomerResponse, checkCustomer } from "./checkCustomer"
import { importToCostEstimation } from "./costEstimate"
import { datVehicleVinSearch } from "./datVehicleVinSearch"
import { prepareSettings } from "./prepareSettings"
import { showModelDetails } from "./showModelDetails"
import { getVinSuggestions } from "./vinSuggestions"

export * from "./checkCustomer"
export * from "./costEstimate"
export * from "./datVehicleVinSearch"
export * from "./getCalculationData"
export * from "./handleCustomerBilling"
export * from "./prepareCalculation"
export * from "./prepareSettings"
export * from "./updateTotalPrices"
export * from "./vinSuggestions"
export * from "./showModelDetails"

export const GlassKey = "dmg-calc-glass"

export const initRepositories = () => {
    Container.register({
        name: GlassKey,
        containerActions: {
            checkCustomer,
            prepareCalculation,
            prepareSettings,
            getVinSuggestions,
            getCalculationData,
            handleCustomerBilling,
            importToCostEstimation,
            updateTotalsPrices,
            datVehicleVinSearch,
            showModelDetails,
        },
    })
}

export const useContainer = () => Container.getInstance(GlassKey) as GlassContainer

type GlassContainerActions =
    | { name: "checkCustomer"; action: () => Promise<CheckCustomerResponse> }
    | { name: "prepareCalculation"; action: (req: PrepareCalculationRequest) => Promise<PrepareCalculationResponse> }
    | { name: "prepareSettings"; action: () => Promise<BaseInfo> }
    | { name: "getVinSuggestions"; action: (val: string, tecdoc?: number) => Promise<string[]> }
    | { name: "getCalculationData"; action: (req: GetCalculationDataRequest) => Promise<GetCalculationDataResponse> }
    | { name: "handleCustomerBilling"; action: () => Promise<CustomerBillingResponse> }
    | { name: "importToCostEstimation"; action: (req: CostEstimationRequest) => Promise<void> }
    | { name: "updateTotalsPrices"; action: (req: UpdateTotalPricesRequest) => Promise<UpdateTotalPricesResponse> }
    | { name: "datVehicleVinSearch"; action: (vin: string) => Promise<DATVehicleVinSearchResponse> }
    | { name: "showModelDetails"; action: (request: ShowCarModelDetailsRequest) => Promise<ShowCarModelDetailsResponse> }

export type GlassContainer = IModelContainer<void, GlassContainerActions>
