import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import Manager from "./components/manager"
import TyresSearchWidget from "./components/tyres-search-widget"
import TyresSearch from "./micros/tyres-search"
import TyresSearchWidgetCompact from "./components/tyres-search-widget-compact"
import { initTyresBundle, version } from "./utils"


const bundle: IBundle & {debug?: boolean} = {
    ...version,
    debug: true,
    components: [
        Main,
        Manager,
        TyresSearchWidget,
        TyresSearchWidgetCompact
    ],
    micros: [
        TyresSearch
    ],
    init: initTyresBundle
}

export default bundle