import { NestedCSSProperties } from "typestyle/lib/types"
import { textOverFlow } from "../../../../style-mixins"

export function getComponentStyles(): NestedCSSProperties {
    return {
        $nest: {
            "&__container": {
                padding: "1em .5em",
                ...textOverFlow,
            },
        },
    }
}
