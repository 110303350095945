import { useLocalization } from "@tm/localization"
import { VatRateType } from "@tm/models"
import { Divider, Stack } from "@tm/components"
import { CostEstimationTotals } from "../../../data/model"
import Total from "./Total"
import { TotalsLoader } from "./TotalsLoader"

type Props = {
    totals?: CostEstimationTotals
    currencyCode: string
    totalsLoading: boolean
}

export function AllTotals({ totals, totalsLoading, currencyCode }: Props) {
    const { translateText, currency, number } = useLocalization()
    const vatRateReplacement = totals?.vatAmounts?.find((vat) => vat.vatType === VatRateType.Replacement)
    const partsDescription = translateText(1720) + (totals?.alreadyTaxedPartCosts ? ` (${translateText(12830)})` : "")
    const worksDescription = translateText(1721) + (totals?.alreadyTaxedWorkCosts ? ` (${translateText(12830)})` : "")

    if (!totals) {
        return null
    }

    if (totalsLoading) {
        return <TotalsLoader />
    }

    return (
        <Stack alignSelf="flex-end" px={2} spacing={0.5} width="450px">
            <Total label={partsDescription} value={currency(totals?.partCosts || 0, currencyCode)} />
            <Total label={worksDescription} value={currency(totals?.workCosts || 0, currencyCode)} labelVariant="body2" valueVariant="body2" />
            <Divider />
            <Total label={translateText(650)} value={currency(totals?.offerPriceVatExcluded || 0, currencyCode)} labelVariant="h3" />
            <Divider />
            {totals?.vatAmounts
                .filter((vat) => vat.vatType !== VatRateType.Replacement)
                .map((vatAmount) => (
                    <Total
                        label={`${translateText(651)} ${number(vatAmount ? vatAmount.vatRate : 0)}%`}
                        value={currency(vatAmount.vatAmount || 0, currencyCode)}
                        labelVariant="body2"
                        valueVariant="body2"
                        key={vatAmount.vatType.toString()}
                    />
                ))}
            {vatRateReplacement && (
                <Total
                    label={translateText(12827)}
                    value={currency(vatRateReplacement.vatAmount || 0, currencyCode)}
                    labelVariant="body2"
                    valueVariant="body2"
                />
            )}
            {totals?.alreadyTaxedTotalCosts && (
                <Total
                    label={translateText(12828)}
                    value={currency(totals.alreadyTaxedTotalCosts, currencyCode)}
                    labelVariant="body2"
                    valueVariant="body2"
                />
            )}
            <Divider />
            <Total
                label={translateText(62)}
                value={currency(totals?.offerPriceVatIncluded || 0, currencyCode)}
                labelVariant="h2"
                valueVariant="body2"
                labelBold
                valueBold
            />
        </Stack>
    )
}
