import { memo } from "react"
import { Button } from "@tm/controls"
import { style } from "typestyle"

import { getBundleParams } from "../../../utils"

interface IProps {
    isLoading: boolean
    label: string
    size?: "big" | "small"
    onClick(): void
}

export const ActiveCustomer = memo<IProps>(({ isLoading, label, onClick, size = "small" }) => {
    if (!getBundleParams().activeCustomerSearchable) {
        return null
    }

    return (
        <Button
            icon="phone-speaker"
            skin="primary"
            onClick={onClick}
            disabled={isLoading}
            className={`${style({ marginLeft: "5px", height: size === "small" ? "35px" : "45px" })} active-customer-button`}
        >
            {label}
        </Button>
    )
})
