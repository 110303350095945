import { Box, LoadingContainer, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useEffect } from "react"
import LoginDialog from "../login/LoginDialog"
import { useGetReturnToken } from "../../hooks/useGetReturnToken"
import { mainActions, useDrivemotiveStore } from "../../data/state"
import { DrivemotiveLoginRequest, NavigationSteps } from "../../data/models"
import Summary from "../summary/Summary"
import { NavigationComponent } from "../navigation/component"

export default function Main() {
    const user = useUser()
    const drivemotiveToken = useDrivemotiveStore((state) => state.main.drivemotiveToken)
    const showLoginDialog = useDrivemotiveStore((state) => state.main.showLoginDialog)
    const navigationStep = useDrivemotiveStore((state) => state.main.navigationStep)
    const drivemotiveEmail = user.userSettings?.drivemotiveSettings.Email
    const drivemotivePassword = user.userSettings?.drivemotiveSettings.Password
    const hasDrivemotiveCredentialsInSettings = !!(drivemotiveEmail && drivemotivePassword)

    const loginToDrivemotive = useGetReturnToken({
        onLoginSuccess(token) {
            mainActions.setDrivemotiveToken(token)
            mainActions.setShowLoginDialog(false)
        },
    })

    useEffect(() => {
        if (drivemotiveToken || !user.userSettings) {
            return
        }

        if (hasDrivemotiveCredentialsInSettings) {
            const request: DrivemotiveLoginRequest = {
                email: drivemotiveEmail,
                password: drivemotivePassword,
            }

            loginToDrivemotive.mutate(request)
        } else {
            mainActions.setShowLoginDialog(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drivemotiveToken, user.userSettings])

    const masterLoading = !user.userSettings || !drivemotiveToken

    if (masterLoading) {
        return <LoadingContainer isLoading={masterLoading}>{showLoginDialog && <LoginDialog />}</LoadingContainer>
    }

    return (
        <StyledWrapper>
            <NavigationComponent />
            {navigationStep === NavigationSteps.Summary && <Summary />}
            {navigationStep === NavigationSteps.Calculation && <div>Calculation</div>}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "1.5em",
    width: "100%",
})
