import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"
import { useImportFastCalculation } from "../../data/hooks/workTaskBasket/imports/useImportFastCalculation"
import { useImportVoucherItems } from "../../data/hooks/workTaskBasket/imports/useImportVoucherItems"
import { useImportVouchers } from "../../data/hooks/workTaskBasket/imports/useImportVouchers"

async function resolve() {}

export function useBasketImports() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const importVoucherItems = useImportVoucherItems(handleBasketUpdateWorkflow)
    const importVoucher = useImportVouchers(handleBasketUpdateWorkflow)
    const importFastCalculation = useImportFastCalculation()
    return { importVoucherItems, importVoucher, importFastCalculation }
}
