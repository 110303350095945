import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, ConfigFunctions } from "@tm/utils"
import { Collapsible } from "@tm/controls"
import {
    WorkType,
    RepairTimeProviderConfig,
    ActiveVehicleDataProviders,
    UserContext,
    WorkCategory,
    CustomWork,
    VatRate,
    AddCustomWorkListRequest,
} from "@tm/models"
import { UserSettings, WorkTaskInfo } from "@tm/context-distribution"
import { Box, Icon, Loader, Stack, Typography, Button } from "@tm/components"
import { EditWorkMode } from "../../business"
import WorksTable from "./WorksTable"
import WorksSelection from "./works-selection"
import { BasketWork } from "../../../../models"
import { CustomRepairTimesComponent } from "../../../_shared/custom-basket-items/custom-repair-times"
import { CostEstimation, CostEstimationCalculation, CustomerDefaults } from "../../../../data/model"
import AddWork from "./AddWork"
import ErrorAlert from "../../../_shared/ErrorAlert"
import { WorksTotals } from "../WorksTotals"

type Props = {
    activeProviders?: ActiveVehicleDataProviders
    awProviders?: { [key: string]: RepairTimeProviderConfig }
    costEstimation?: CostEstimation
    creatingCostEstimation: boolean
    hasRepairTimesWithError: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    useRepairTimeCalculation?: boolean
    userContext: UserContext
    userSettings?: UserSettings
    vehicleId?: string
    workEstimationLoading: boolean
    workTask: WorkTaskInfo
    costEstimationCalculation?: CostEstimationCalculation
    workTaskBasketCalculationLoading: boolean
    workTaskBasketLoading: boolean
    works?: BasketWork[]
    showRepairTimesInHours: boolean
    currencyCode: string
    currencySymbol: string
    repairTimeDivision: number
    toggleWorkExpand(ids: string[]): void
    toggleWorkSelect(ids: string[]): void
    toggleWorkEditorOpen(ids: string[]): void
    onAddCustomWork(request: AddCustomWorkListRequest): void
    onEditWork(
        work: BasketWork,
        repairTimeDivision: number,
        categoryOfWork: WorkCategory,
        description: string,
        editWorkMode: EditWorkMode,
        customerDefaults?: CustomerDefaults,
        workNumber?: string,
        time?: number,
        hourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate
    ): void
    onIncludeExcludeWorks(works: BasketWork[]): void
    onRemoveWorks(workIds: string[]): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
    onResetRepairTimes(items: BasketWork[], customerDefaults?: CustomerDefaults): void
    onSelectAll(): void
    onUnselectAll(): void
    // onShowHideIncludes(workId: string): void
}

export default function WorksListComponent(props: Props) {
    const {
        activeProviders,
        awProviders,
        works,
        creatingCostEstimation,
        workTask,
        showSupplierArticleNumbers,
        showManufacturer,
        costEstimation,
        workTaskBasketLoading,
        workEstimationLoading,
        workTaskBasketCalculationLoading,
        useRepairTimeCalculation,
        costEstimationCalculation,
        vehicleId,
        userContext,
        userSettings,
        hasRepairTimesWithError,
        currencyCode,
        currencySymbol,
        repairTimeDivision,
        showRepairTimesInHours,
    } = props

    const { translateText } = useLocalization()

    const [customRepairTimesOpen, setCustomRepairTimesOpen] = useState(false)

    const selectedWorks = useMemo(() => {
        return works?.filter((item) => item.states.isSelected) ?? []
    }, [works])

    const [areAllWorksSelected, areAllWorksResetable] = useMemo(() => {
        return [works?.length === selectedWorks.length, !selectedWorks.some((work) => work.workItem.type !== WorkType.CustomMainWork)]
    }, [works, selectedWorks])

    function renderWorksSelection() {
        if (selectedWorks && selectedWorks?.length > 0) {
            return (
                <WorksSelection
                    selectedWorks={selectedWorks}
                    resetButtonEnabled={areAllWorksResetable}
                    allSelected={areAllWorksSelected}
                    loading={workTaskBasketLoading || workTaskBasketCalculationLoading || workEstimationLoading}
                    onSelectAll={props.onSelectAll}
                    onUnselectAll={props.onUnselectAll}
                    onRemoveWorks={props.onRemoveWorks}
                    onResetRepairTimes={props.onResetRepairTimes}
                    onIncludeExcludeWorks={props.onIncludeExcludeWorks}
                />
            )
        }
    }

    function renderCustomRepairTimesButton() {
        return (
            <Button
                startIcon={customRepairTimesOpen ? <Icon name="up" /> : <Icon name="down" />}
                variant="text"
                onClick={(e) => {
                    e?.stopPropagation?.()
                    setCustomRepairTimesOpen((prev) => !prev)
                }}
                size="small"
            >
                {translateText(852)}
            </Button>
        )
    }

    function renderWorksAddingTools() {
        let repairTimesUrl
        if (awProviders) {
            const awProvidersValues = Object.values(awProviders)
            let provider = activeProviders?.repairTimes

            if (!provider) {
                let temp: RepairTimeProviderConfig | undefined
                for (let i = 0; i < awProvidersValues.length; i++) {
                    const rtProvider = awProvidersValues[i]

                    let disabledFunction = rtProvider.disabledFunction
                        ? ConfigFunctions[rtProvider.disabledFunction as keyof typeof ConfigFunctions]
                        : undefined

                    if (!disabledFunction) {
                        try {
                            disabledFunction = eval(`[${rtProvider.disabled}][0]`)

                            if (typeof disabledFunction !== "function") {
                                disabledFunction = undefined
                            }
                        } catch {}
                    }

                    const disabled = disabledFunction?.(userContext, workTask.vehicle, workTask.customer) ?? false
                    if (userSettings?.activeVehicleDataProviders.repairTimes === rtProvider.id && !disabled) {
                        temp = rtProvider
                        break
                    } else if ((!temp || (temp.sort || 100) > (rtProvider.sort || 100)) && !disabled) {
                        temp = rtProvider
                    }
                }

                if (temp) {
                    provider = temp.id
                }
            }

            const route = awProvidersValues.find((x) => x.id === provider)?.route
            repairTimesUrl = route && renderRoute(route, { workTaskId: encodeUniqueId(workTask.id) })
        }

        return (
            <Box display="flex" justifyContent="center" pb={0.5}>
                <AddWork
                    showRepairTimesInHours={showRepairTimesInHours}
                    costEstimation={costEstimation}
                    repairTimeDivision={repairTimeDivision}
                    currencyCode={currencyCode}
                    repairTimesUrl={repairTimesUrl}
                    vehicleId={vehicleId}
                    workTaskId={workTask.id}
                    onAddCustomWork={props.onAddCustomWork}
                />
            </Box>
        )
    }

    function renderTable() {
        return works?.length && costEstimation?.hourlyRates ? (
            <WorksTable
                calculationLoading={workTaskBasketCalculationLoading}
                creatingCostEstimation={creatingCostEstimation}
                costEstimation={costEstimation}
                repairTimeDivision={repairTimeDivision}
                showManufacturer={showManufacturer}
                showRepairTimesInHours={showRepairTimesInHours}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                useRepairTimeCalculation={useRepairTimeCalculation}
                workEstimationLoading={workEstimationLoading}
                workTask={workTask}
                works={works}
                onEditWork={props.onEditWork}
                onIncludeExcludeWork={(work) => props.onIncludeExcludeWorks([work])}
                onRemoveWorks={props.onRemoveWorks}
                onResetRepairTimes={(work) => props.onResetRepairTimes([work])}
                onSelectWork={(id) => props.toggleWorkSelect([id])}
                onShowHideIncludes={props.toggleWorkExpand}
                onOpenCloseWorkEditor={(id) => props.toggleWorkEditorOpen([id])}
                onReplaceWorkWithCustomWork={props.onReplaceWorkWithCustomWork}
            />
        ) : (
            <Stack alignItems="center" spacing={2}>
                <Icon name="repairtimes" width="30px" height="30px" />
                <Typography>{translateText(919)}</Typography>
            </Stack>
        )
    }

    return (
        <Collapsible
            name={translateText(1668)}
            initiallyOpened
            skin="dark"
            renderHeaderAppendix={renderCustomRepairTimesButton}
            className="tk-basket cost-estimation__collabsible--small-caption"
        >
            {customRepairTimesOpen && costEstimation?.hourlyRates && (
                <CustomRepairTimesComponent
                    workTask={workTask}
                    currencySymbol={currencySymbol}
                    repairTimeDivision={repairTimeDivision}
                    showRepairTimesInHours
                    hourlyRates={costEstimation.hourlyRates}
                    vatRates={costEstimation?.defaultWorkVatRates}
                />
            )}
            <Box position="relative">
                {renderWorksSelection()}
                {hasRepairTimesWithError && <ErrorAlert />}
                <Box mb={1}>{workTaskBasketLoading ? <Loader size="small" /> : renderTable()}</Box>
            </Box>
            {renderWorksAddingTools()}
            <Box py={1} pr={1} display="flex" justifyContent="flex-end">
                <WorksTotals
                    currencyCode={currencyCode}
                    showRepairTimesInHours={showRepairTimesInHours}
                    totalsLoading={workTaskBasketCalculationLoading}
                    totals={costEstimationCalculation?.totals}
                />
            </Box>
        </Collapsible>
    )
}
