import { ErpInformation } from "@tm/models"
import { BasicErpInfo } from "../../../../data/repositories"

export function mapBasicErpInfo(erpInfo: ErpInformation): BasicErpInfo {
    const { dataSupplierArticleNumber, prices } = erpInfo

    return {
        dataSupplierArticleNumber,
        prices,
    }
}
