import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(baseStyles: NestedCSSProperties) {
    const { margin } = getStyleTheme()

    const styles: NestedCSSProperties = {
        width: "100%",
        margin: `0 ${margin.m}`,
        paddingTop: "3em",
        display: "flex",
        alignItems: "center",
        $nest: {
            ".one-third:first-child": {
                display: "none",
            },
            ".one-third:last-child": {
                marginLeft: margin.xl,
            },
            "&__partnerless": {
                marginTop: margin.l,
            },
        },
    }

    return deepAssign(baseStyles, styles)
}
