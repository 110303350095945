import { useState, useEffect } from "react"
import { equals, getCategoryOfWorkDescriptionTextId, setValue } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { HourlyRate, useUser } from "@tm/context-distribution"
import { Table, Button, Checkbox, NumberField } from "@tm/controls"
import { WorkCategory, UserModule, ReactQueryKeys } from "@tm/models"
import { useQueryClient } from "react-query"
import { HourlyRatesDictionary, TableDisplayType } from "../business"

type Props = {
    hourlyRates?: Array<HourlyRate>
    currencyCode: string
    enabledModules?: Array<UserModule>
}

const MAX_POSSIBLE_NUMBER = 99999.99
const KEY = ReactQueryKeys.basket_summary

function HourlyRatesComponent(props: Props) {
    const { translateText } = useLocalization()
    const { changeHourlyRates } = useUser() ?? {}
    const queryClient = useQueryClient()

    const [useDefault, setUseDefault] = useState<boolean>(true)
    const [defaultValue, setDefaultValue] = useState<number>(0)
    const [rates, setRates] = useState<HourlyRatesDictionary>({})
    const [dirty, setDirty] = useState<boolean>()
    const [allCategoriesAreValid, setAllCategoriesAreValid] = useState<boolean>()
    const [oneCategoryValueIsValid, setOneCategoryValueIsValid] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState(false)
    const ereModuleEnabled = props.enabledModules?.find((x) => x.type === 31)

    function getHourlyRateDictionaryFromHourlyRateArray(): HourlyRatesDictionary {
        const dict: HourlyRatesDictionary = {}
        props.hourlyRates?.forEach((rate) => {
            if (rate.category) {
                dict[rate.category.toString()] = rate.hourlyRate
            } else {
                dict.defaultValue = rate.hourlyRate
            }
        })

        return dict
    }

    useEffect(() => {
        if (props.hourlyRates) {
            setRates(getHourlyRateDictionaryFromHourlyRateArray())
            const defaultRate = props.hourlyRates.find((rate) => rate.category === undefined)
            if (defaultRate) {
                setDefaultValue(defaultRate.hourlyRate)
            }
            setUseDefault(props.hourlyRates.length === 1)
        } else {
            setRates({})
            setDefaultValue(0)
            setUseDefault(true)
        }
    }, [props.hourlyRates])

    useEffect(() => {
        setDirty(!equals(rates, props.hourlyRates ? getHourlyRateDictionaryFromHourlyRateArray() : {}))

        let validCategories = 0
        Object.keys(rates).forEach((key: string) => {
            const value = rates[key]
            if (value > 0) {
                setOneCategoryValueIsValid(true)
                validCategories++
            }
        })

        setAllCategoriesAreValid(Object.keys(rates).length === validCategories)
    }, [rates])

    function handleChange(newValue: string, id: string) {
        // Get the changed value from the changed model
        const stringValue = newValue || ""
        // Parse string to float (replace , with .)
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        // If the value is invalid, restore the old value
        if (isNaN(value)) {
            value = 0
        }

        const model = { ...rates }
        // Update the parsed float value
        setValue(model, [id], value)
        setRates(model)
    }

    function handleToggleUseDefaultValue() {
        setUseDefault(!useDefault)
        const hourlyRates = {
            [WorkCategory.ElectronicWork.toString()]: defaultValue,
            [WorkCategory.ElectricalWork.toString()]: defaultValue,
            [WorkCategory.BodyWork.toString()]: defaultValue,
            [WorkCategory.PaintWork.toString()]: defaultValue,
            [WorkCategory.WorkshopWork.toString()]: defaultValue,
            [WorkCategory.AccessoryWork.toString()]: defaultValue,
            [WorkCategory.Diagnose.toString()]: defaultValue,
        }

        if (ereModuleEnabled) {
            hourlyRates[WorkCategory.SaddlerWork.toString()] = defaultValue
            hourlyRates[WorkCategory.SmartRepair.toString()] = defaultValue
        }

        setRates(hourlyRates)
    }

    function handleDefaultChange(newValue: string) {
        // Get the changed value from the changed model
        const stringValue = newValue || ""
        // Parse string to float (replace , with .)
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        // If the value is invalid, restore the old value
        if (isNaN(value)) {
            value = 0
        }

        setRates((prevRates) => {
            return { ...prevRates, defaultValue: value }
        })
        setDefaultValue(value)
    }

    function handleSave() {
        setIsSaving(true)
        const arr: Array<HourlyRate> = []

        if (defaultValue > 0) {
            arr.push({ category: undefined, hourlyRate: defaultValue })
        }

        if (!useDefault) {
            Object.keys(rates).forEach((key: string) => {
                const value = rates[key]
                if (value > 0) {
                    arr.push({ category: parseInt(key), hourlyRate: value })
                }
            })
        }

        changeHourlyRates?.(arr, props.currencyCode)
            .then(() => {
                queryClient.resetQueries(KEY)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    function handleReset() {
        if (props.hourlyRates) {
            setRates(getHourlyRateDictionaryFromHourlyRateArray())
            let defaultValue = true
            props.hourlyRates.forEach((rate) => {
                if (!rate.category) {
                    setDefaultValue(rate.hourlyRate)
                } else {
                    defaultValue = false
                }
            })
            setUseDefault(defaultValue)
        } else {
            setRates({})
            setDefaultValue(0)
            setUseDefault(true)
        }
    }

    function renderDescription(data: TableDisplayType) {
        return <Table.Cell>{data.description}</Table.Cell>
    }

    function renderValue(data: TableDisplayType) {
        return (
            <Table.Cell>
                <NumberField
                    disabled={isSaving || useDefault}
                    onChange={(value) => {
                        handleChange(value, data.id)
                    }}
                    value={useDefault ? defaultValue : rates[data.id]}
                    label={props.currencyCode}
                    maximum={MAX_POSSIBLE_NUMBER}
                    minimum={0.0}
                    stepSize={0.01}
                    placeholder="0"
                />
            </Table.Cell>
        )
    }

    const data: Array<TableDisplayType> = [
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.ElectronicWork)), id: WorkCategory.ElectronicWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.ElectricalWork)), id: WorkCategory.ElectricalWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.BodyWork)), id: WorkCategory.BodyWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.PaintWork)), id: WorkCategory.PaintWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.WorkshopWork)), id: WorkCategory.WorkshopWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.AccessoryWork)), id: WorkCategory.AccessoryWork.toString() },
        { description: translateText(getCategoryOfWorkDescriptionTextId(WorkCategory.Diagnose)), id: WorkCategory.Diagnose.toString() },
    ]

    if (ereModuleEnabled) {
        data.push(
            { description: translateText(929), id: WorkCategory.SaddlerWork.toString() },
            { description: translateText(930), id: WorkCategory.SmartRepair.toString() }
        )
    }

    return (
        <>
            <div className="user-settings__hourly-rates__actions">
                <Button
                    onClick={handleSave}
                    disabled={
                        isSaving || !dirty || (useDefault ? defaultValue == 0 : defaultValue == 0 ? !allCategoriesAreValid : !oneCategoryValueIsValid)
                    }
                    skin="success"
                >
                    {translateText(9)}
                </Button>
                <Button onClick={handleReset} disabled={isSaving || !dirty} skin="danger">
                    {translateText(48)}
                </Button>
            </div>
            <div className="user-settings__hourly-rates__default">
                <Checkbox label={`${translateText(861)}:`} checked={useDefault} disabled={isSaving} onToggle={handleToggleUseDefaultValue} />
                <NumberField
                    onChange={(value) => {
                        handleDefaultChange(value)
                    }}
                    value={defaultValue}
                    label={props.currencyCode}
                    maximum={MAX_POSSIBLE_NUMBER}
                    minimum={0}
                    stepSize={0.01}
                    placeholder="0"
                    disabled={isSaving}
                />
            </div>
            <Table
                columns={[
                    <Table.Column key="description" className="user-settings__hourly-rates__description" renderItemContent={renderDescription}>
                        {translateText(860)}
                    </Table.Column>,
                    <Table.Column key="value" className="user-settings__hourly-rates__value" renderItemContent={renderValue}>
                        {translateText(622)}
                    </Table.Column>,
                ]}
                data={data}
                getRowClassName={() => "hourly-rate"}
            />
        </>
    )
}

export default HourlyRatesComponent
