import { ajax, getStoredAuthorization } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { CreateCostEstimationRequest, VouchersBundleParams } from "@tm/models"

function getServiceUrl() {
    return `${Morpheus.getParams<VouchersBundleParams>("vouchers").vouchersServiceUrl}/Vouchers`
}

export function createCostEstimation(body: CreateCostEstimationRequest) {
    const url = `${getServiceUrl()}/Create`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}
