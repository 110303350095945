import { useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { Widget, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, SearchTreeProductGroup } from "@tm/models"
import { useMultiWidgetState } from "@tm/utils"
import { getBundleParams } from "../../utils"
import UniSearchWidgetContent from "../_shared/uni-search-widget-content"
import { IActions, Actions, UniSearchWidgetState } from "../_shared/uni-search-widget-content/business"
import { SearchTree } from "../widget/business"
import { UniBestsellerSearchTree } from "../_shared/uni-search-top-products/component"

type Props = {
    state: UniSearchWidgetState
    actions: IActions
    className?: string
    showComponentKey?: string
    universalListRoute: string
    universalSearchRoute: string
    hideTree?: boolean
    tree?: SearchTree
    storeId?: string
    hideBestseller?: boolean
    contentOnly?: boolean
}

const UniSearchWidgetComponent = (props: Props) => {
    const { showComponentKey, state, actions, hideTree, hideBestseller, storeId, contentOnly } = props
    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId })
    const { translateText } = useLocalization()
    const treeId = getBundleParams().universalTreeId

    useEffect(() => {
        actions.initialize(props.tree)
        actions.resetBreadcrumbs()
    }, [])

    useEffect(() => {
        if (!hideTree && state.tree.treeId && showComponentKey && selectedMultiWidgetTab === showComponentKey) {
            actions.loadUniversalPartsTreeNodes()
        }
    }, [hideTree, state.tree.treeId, selectedMultiWidgetTab])

    function handleApplyProductGroups(productGroups: Array<SearchTreeProductGroup>) {
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            uniProductGroups: { ids: productGroups.map((x) => x.id).orderBy((x) => x) },
        })
    }

    const renderContent = () => {
        if (!treeId) {
            return null
        }
        return (
            <>
                <UniSearchWidgetContent
                    loading={state.loading}
                    nodes={state.nodes}
                    universalListRoute={props.universalListRoute}
                    universalSearchRoute={props.universalSearchRoute}
                    universalTreeId={treeId}
                    onApplyProductGroups={handleApplyProductGroups}
                    onChangeBreadcrumbs={actions.changeBreadcrumbs}
                    tree={state.tree}
                    hideBestseller={hideBestseller}
                />
                <UniBestsellerSearchTree />
            </>
        )
    }

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const className = `tk-parts uni-search-widget ${props.className || ""}`

    if (contentOnly) {
        return renderContent()
    }

    return (
        <Widget id="parts__uni-search-widget" className={className} iconName="catalog" title={translateText(993)} size="6x3" active>
            <Text className="label">{translateText(1094)}</Text>
            {renderContent()}
        </Widget>
    )
}

export default connectComponent(Actions, UniSearchWidgetComponent)
