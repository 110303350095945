import { IBundle } from "@tm/morpheus"

import ApplyButton from "./components/apply-button"
import ArticleReferencesManufacturers from "./components/article-references-manufacturers"
import ArticleReferencesModelSeries from "./components/article-references-model-series"
import ArticleReferencesTypes from "./components/article-references-types"
import BikeWidget from "./components/bike-widget-start"
import CommmercialWidget from "./components/commercial-widget-start"
import CustomerInfo from "./components/customer-info"
import Details from "./components/details"
import Filters from "./components/filters"
import MainModelSeries from "./components/mainmodelseries"
import Manager from "./components/manager"
import Manufacturers from "./components/manufacturers"
import Models from "./components/models"
import Modelseries from "./components/modelseries"
import Navigation from "./components/navigation"
import Search from "./components/search"
import SearchTreeWidget from "./components/search-tree-widget"
import SearchField from "./components/searchfield"
import Selections from "./components/selections"
import VehicleInfo from "./components/vehicle-info"
import VrcScan from "./components/vrc-scan"
import VrmLookupDetailsOnly from "./components/vrm-lookup-details-only"
import Widget from "./components/widget"
import SidebarWidget from "./components/widget-sidebar"
import DatVinRequest from "./micros/dat-vin-request"
import VehicleWidget from "./micros/vehicle-widget"
import { initVehicleBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        ApplyButton,
        ArticleReferencesManufacturers,
        ArticleReferencesModelSeries,
        ArticleReferencesTypes,
        BikeWidget,
        CommmercialWidget,
        CustomerInfo,
        Details,
        Filters,
        MainModelSeries,
        Manager,
        Manufacturers,
        Models,
        Modelseries,
        Navigation,
        Search,
        SearchField,
        SearchTreeWidget,
        Selections,
        SidebarWidget,
        VehicleInfo,
        VrcScan,
        VrmLookupDetailsOnly,
        Widget,
    ],
    micros: [DatVinRequest, VehicleWidget],
    init: initVehicleBundle,
}

export default bundle
