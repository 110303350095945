import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()

    const styles: NestedCSSProperties = {
        $nest: {
            "&__input__wrapper": {
                padding: 0,
                width: "100%",
                border: "unset",
            },
            "&__field-container": {
                border: "none",
            },
            "&__querytype": {
                $nest: {
                    "&.has-result": {
                        $nest: {
                            ">.btn": {
                                borderRadius: "0",
                            },
                        },
                    },
                    ">.btn": {
                        borderRadius: "0",
                    },
                },
            },
            "&__actions": {
                position: "absolute",
                bottom: 0,
                display: "flex",
            },
            "&__search": {
                $nest: {
                    ".btn:last-child": {
                        padding: "0.5em 1.16em",
                        borderRadius: "unset",
                        $nest: {
                            ".icon": {
                                fill: colors.light,
                            },
                        },
                    },
                },
            },
        },
    }

    return deepAssign(baseStyles, styles)
}
