import Morpheus from "@tm/morpheus"

export type BundleParams = {
    serviceUrl: string
    telematicsUrl: string
    fastCalculatorRoute?: string
    batteryCalculationUrl?: string
    providerLogos?: Array<string>
    useAttachToWorktask?: boolean
}

export const getBundleParams = (): BundleParams => {
    const bundleParams = {
        ...Morpheus.getParams("carmunication")
    }

    if (!bundleParams)
        console.warn(`The toolkit params have to be set in order to use the bundle "TMtires"`)
    return bundleParams
}