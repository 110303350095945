import { useEffect, useState } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import { useWorkTask } from "@tm/context-distribution"
import { DirectSearchStartParams, ListParams, OeInformationData } from "../models"
import { OeInformation, OeInformationRequest } from "../../../data/model"
import * as PartsRepository from "../../../data/repositories"

const QUERY_KEY = "OeInformation"

type QueryKey = [string, OeInformationRequest]

export function useOeInformation(params: ListParams<DirectSearchStartParams>, isEnabled: boolean): OeInformationData {
    const workTask = useWorkTask()

    const [request, setRequest] = useState<OeInformationRequest>()

    useEffect(() => {
        if (!isEnabled || params.startParams.isOeReplacement || !workTask?.workTask) {
            return
        }

        setRequest({
            oeNumber: params.startParams.query,
            modelId: workTask?.workTask?.vehicle?.tecDocTypeId,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled, params.startParams.query, workTask?.workTask?.vehicle])

    const oeInfoQuery = useQuery({
        enabled: isEnabled && !!request,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryKey: [QUERY_KEY, request!],
        queryFn: getOeInformation,
    })

    return { isLoading: oeInfoQuery.isLoading, oeInformation: oeInfoQuery.data }
}

function getOeInformation({ queryKey: [, request] }: QueryFunctionContext<QueryKey>): Promise<OeInformation[]> {
    return PartsRepository.getOeInformation(request)
}
