export enum ECalcButtonState {
    None = 0,
    Submit = 1,
    Reset = 2,
    AddToBasket = 3,
    CreateQuote = 4,
    PrintMaintenancePlan = 5,
    FastCockpit = 6,
    VehicleType = 7,
    MaintenanceSystem = 8,
    Interval = 9,
    Calculation = 10,
    AdditionalWork = 11,
    FollowupWork = 12,
    DeletePart = 13,
    DeleteRepairTime = 14,
    DeleteConsumable = 15,
    DeleteConsumablePart = 16,
    Close = 17,
    IncludeSuggestedWorks = 18,
    OpenProvider = 19,
    Link = 20,
    ToCalculationNext = 21,
    CreateKva = 22,
    FastCockpitNext = 23,
    CalculationNext = 24,
    StartCalculation = 25,
    StartNewCalculation = 27,
}
