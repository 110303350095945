import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FSCalculation, mapCalculationData } from "./mapper"
import { GetCalculation } from "./model"

export function getCalculation(data: GetCalculation): Promise<FSCalculation> {
    const url = `${getServiceUrl()}/fastcalculator/FastServiceCalculate`
    const authorization = getStoredAuthorization()
    const body = data

    return ajax({ url, body, authorization, method: "POST" }).then((res) => {
        return mapCalculationData(res)
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
