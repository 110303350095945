import { Box, Icon, Typography, Stack } from "@tm/components"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

export function Descriptions() {
    const { description, missingVehicleConnectionTitle, additionalDescriptions } = useDefaultArticleItemState((x) => x.displayArticle.description)

    return (
        <Box>
            {additionalDescriptions?.map((additionalDescription) => (
                <Stack key={additionalDescription} direction="row" gap={0.5}>
                    <Icon name="plus" />
                    <Typography>{additionalDescription}</Typography>
                </Stack>
            ))}
            {!!description && <Typography>{description}</Typography>}
            {missingVehicleConnectionTitle && (
                <Box>
                    <Typography>{missingVehicleConnectionTitle}</Typography>
                    <Icon name="missing-vehicle-connection" size="l" />
                </Box>
            )}
        </Box>
    )
}
