import { Vehicle, RepairTimeProvider, CustomWork, WorkCategory, CustomPart } from "@tm/models"
import { CostEstimationRequest } from "."
import { ExtendedOePart, ICalculationItem, Part, Work } from "../../models"
import { mapCustomPart } from "./mappers/mapCustomPart"

export function createCostEstimationRequest(
    workTaskId: string,
    items: ICalculationItem[],
    works: Work[],
    vehicle?: Vehicle,
    memo?: string
): CostEstimationRequest {
    const selectedItems = items.filter((x) => x.isSelected)

    const parts: Part[] = []
    const oeArticles: ExtendedOePart[] = []
    const customParts: CustomPart[] = []
    const validWorks: Work[] = []
    const customWorks: CustomWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            const { ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeArticles.push({ ...item.oeArticle, memo, oePriceValue: item.oeArticle.oePriceValue || undefined })
        }
    })

    works?.forEach((work) => {
        if (work.providerWorkId?.length) {
            validWorks.push(work)
        } else {
            customWorks.push({
                customWorkNumber: "",
                categoryOfWork: WorkCategory.NotCategorized,
                description: work.description,
                time: work.calculatedTime,
                hourlyRate: work.hourlyRate,
                fixedPriceValue: work.total,
            })
        }
    })

    return {
        workTaskId,
        vehicleId: vehicle?.id ?? "",
        oeParts: oeArticles,
        parts,
        customParts,
        works: validWorks,
        customWorks,
        repairTimesProvider: RepairTimeProvider.None,
    }
}
