import { useMemo } from "react"
import { ArticlesByQueryRequest } from "../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../data/repositories/uni-parts"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { fetchUniversalPartsArticles } from "../../../data/fetchUniversalPartsArticles"
import { useArticlesBase } from "../../../hooks/useArticlesBase"
import { ArticleData, ListParams, UniversalPartsStartParams } from "../../../models"

const QUERY_KEY = "UniversalParts.useArticlesByQuery"

export function useArticlesByQuery(params: ListParams<UniversalPartsStartParams>, isEnabled: boolean): ArticleData {
    const { productGroups, suppliers, attributes, startParams, extendedAssortment } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<ArticlesByQueryRequest | null>(() => {
        if (!isEnabled || startParams.type !== "unisearch" || !startParams.query) {
            return null
        }

        return {
            pageSize,
            query: startParams.query,
            selectedProductGroups: productGroups,
            selectedSuppliers: suppliers,
            selectedCriteria: attributes,
            extendedAssortment,
            productGroupOrdering: productGroups.map((x) => x.id),
        }
    }, [isEnabled, pageSize, productGroups, suppliers, startParams, attributes, extendedAssortment])

    return useArticlesBase(request, (context) => fetchUniversalPartsArticles(PartsRepository.getArticlesByQuery, context), QUERY_KEY, params)
}
