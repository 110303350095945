import { useLocalization } from "@tm/localization"
import { CostEstimationVoucherTotals } from "@tm/models"
import Total from "../../Total"

type Props = {
    costEstimationTotals: CostEstimationVoucherTotals
}
export default function PartsTotals({ costEstimationTotals }: Props) {
    const { translateText, currency } = useLocalization()

    return (
        <Total
            label={translateText(1720)}
            labelAlignedRight
            subLabel={translateText(12830)}
            value={
                costEstimationTotals.totalPartsCosts
                    ? currency(costEstimationTotals.totalPartsCosts, costEstimationTotals.currencySymbol || costEstimationTotals.currencySymbol)
                    : "-"
            }
            valueBold
        />
    )
}
