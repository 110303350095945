import { Box, Typography, VehicleImage, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { dateToString } from "../../../data/helpers"
import { customerSelector, vehicleSelector } from "../../main/business"

const selector = initSelector(vehicleSelector, customerSelector)

export default function Header() {
    const { translateText } = useLocalization()

    const [vehicle, customer] = useSelector(selector)

    if (!vehicle) {
        return null
    }

    return (
        <StyledBox>
            <Box display="flex" gap=".3%" flex="1">
                <Typography variant="body2" fontWeight="600">
                    {`${translateText(1575)} ${translateText(1481)}`}
                </Typography>
                {vehicle.modelThumbnail && (
                    <VehicleImage
                        modelImage={vehicle.modelThumbnail}
                        modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                        vehicleType={vehicle.vehicleType}
                        style={{ width: "3.75em", margin: "0 1em" }}
                    />
                )}
                {vehicle.manufacturer && <Typography variant="body2">{vehicle.manufacturer}</Typography>}
                {vehicle.modelSeries && <Typography variant="body2">{vehicle.modelSeries}</Typography>}
                {vehicle.engineCode && <Typography variant="body2">{`${translateText(105)}: ${vehicle.engineCode}`}</Typography>}
                {vehicle.initialRegistration && (
                    <Typography variant="body2">{`${translateText(124)}: ${dateToString(vehicle.initialRegistration)}`}</Typography>
                )}
                {vehicle.vin && <Typography variant="body2">{`${translateText(101)}: ${vehicle.vin}`}</Typography>}
                {customer && <Typography variant="body2">{`${customer?.firstName} ${customer?.lastName}`}</Typography>}
            </Box>
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    padding: "1em 1em 0 1em",
})
