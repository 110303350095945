import { ImportCostEstimationVoucherRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"

export function useImportVouchers(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: importOrderVouchers } = useMutation(
        (importCostEstimationVoucherRequest: ImportCostEstimationVoucherRequest) =>
            Data.importCostEstimationVoucher(importCostEstimationVoucherRequest),
        {
            onSuccess: (response, request) => {
                if (response) {
                    handleBasketUpdateWorkflow(request.workTaskId, response)
                }
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const importVoucher = useCallback(
        (importOrderVoucherItemsRequest: ImportCostEstimationVoucherRequest) => {
            return importOrderVouchers(importOrderVoucherItemsRequest)
        },
        [importOrderVouchers]
    )

    return importVoucher
}
