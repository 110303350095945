import { ajax, getStoredAuthorization } from "@tm/utils"
import { KVABasketWork, ShowCostEstimationRequest, ShowCostEstimationResponse } from "../model"
import { getBundleParams } from "../../utils"

export function showCostEstimation(workTaskId: string): Promise<Array<KVABasketWork> | undefined> {
    const url = `${getBaseUrl()}/ShowCostEstimation`
    const authorization = getStoredAuthorization()
    const body: ShowCostEstimationRequest = { workTaskId }
    return new Promise<Array<KVABasketWork> | undefined>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response: ShowCostEstimationResponse) => {
            if (response) {
                resolve(mapData(response))
            } else {
                reject()
            }
        }, reject)
    )
}

const mapData = (res: ShowCostEstimationResponse): KVABasketWork[] => {
    return res.calculatedWorks.map((x: KVABasketWork) => ({
        repairTimeProviderWorkId: x.repairTimeProviderWorkId,
        description: x.description,
    }))
}

const getBaseUrl = (): string => {
    return getBundleParams().costEstimationSvcUrl
}
