import { useQuery } from "react-query"
import { BookingRequest } from "../data/models"
import { getBookingResponse } from "../data/repositories"

export function useGetBookings(token: string) {
    const request: BookingRequest = {
        token,
        limit: 10,
    }
    const { data, isLoading } = useQuery([request.token, request.limit, request.priceFrom], () => getBookingResponse(request), {
        staleTime: Infinity,
    })
    return { bookings: data, isLoading }
}
