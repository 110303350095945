import { ArticleAttributes, PartsRoutes, RequestArticleDetailsPayload, RequestArticleListPayload } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { getArticleDetailsRoute, getArticleListRoute, Route } from "../components/manager/business"
import { GetOeAttributesResponse } from "../data/model/partsList"
import * as Helpers from "../components/list/business/helpers"
import { Repositories } from "../data"

const openRoute = (routeToOpen: Route, params: { workTaskId?: string }, inModal: boolean) => {
    if (!params.workTaskId) {
        params = { ...params, workTaskId: encodeUniqueId(uniqueId()) }
    }

    let url = renderRoute(routeToOpen.route, params)
    if (routeToOpen.queryString) {
        url += `?${routeToOpen.queryString}`
    }

    if (inModal) {
        Morpheus.showView("1", url)
    } else {
        Morpheus.closeView("1", url)
    }
}

export function openPartDetailsInModal(request: RequestArticleDetailsPayload, params: { workTaskId?: string }, routes?: Partial<PartsRoutes>) {
    const detailsRoute = getArticleDetailsRoute(request, true, routes)
    if (detailsRoute) {
        openRoute(detailsRoute, params, true)
    }
}

export function openPartsSearch(request: RequestArticleListPayload, params: { workTaskId?: string }, routes?: PartsRoutes) {
    if (!!request.oePositions?.length && request.tecDocTypeId) {
        const promises: Promise<GetOeAttributesResponse | ArticleAttributes[] | undefined>[] = []

        request.oePositions?.forEach((oePosition) => {
            oePosition.parts?.forEach((part) => {
                if (!part.attributes && !!part.number) {
                    const req = Helpers.createGetOeAttributesRequest(request.tecDocTypeId!, part.number, [])
                    promises.push(Repositories.getOeAttributes(req).then((res) => (part.attributes = res?.articleAttributes)))
                }
            })
        })

        Promise.allSettled(promises)
    }

    const listRoute = getArticleListRoute(request, routes)
    if (listRoute) {
        openRoute(listRoute, params, request.inModal ?? false)
    }
}
