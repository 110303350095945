import { Box, Icon, Stack, Typography, styled } from "@tm/components"
import { getLocalization } from "@tm/localization"
import { memo, useMemo } from "react"
import { TmaHelper } from "@tm/utils"
import { AttributeItem } from "./AttributeItem"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export const AttributesBlock = styled(Stack)(({ theme }) => ({
    backgroundColor: "#f0f0f0",
    borderLeft: `4px solid ${theme.palette.highlight.main}`,
    marginTop: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",

    "&:first-of-type": {
        marginTop: 0,
    },
}))

export const Attributes = memo(() => {
    const { translateText } = getLocalization()
    const expanded = useDefaultArticleItemState((x) => x.expanded)
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const attributes = useDefaultArticleItemState((x) => x.displayArticle.attributes)
    const handleClickAttribute = useDefaultArticleItemState((x) => x.handleClickAttribute)

    const handleClick = useMemo<typeof handleClickAttribute>(
        () =>
            handleClickAttribute
                ? (is, value) => {
                      TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
                      return handleClickAttribute(is, value)
                  }
                : undefined,
        [handleClickAttribute]
    )

    return (
        <Stack spacing="4px">
            {attributes?.map(({ topArticleAttributes, additionalAttributes }, blockIndex) => (
                <Stack direction="row" key={`vehicleAttribute_${blockIndex}`} alignItems="start">
                    {attributes?.length > 1 && (
                        <Box alignItems="center" mt={0.5} mr={1} whiteSpace="nowrap">
                            <Typography variant="label">{blockIndex + 1}.</Typography>&nbsp;
                            <Icon name="car" sx={{ verticalAlign: "top" }} />
                        </Box>
                    )}
                    <AttributesBlock>
                        {topArticleAttributes?.map((attr) => (
                            <AttributeItem key={`${attr.id}:${attr.key}`} attribute={attr} onClickAttributeValue={handleClick} />
                        ))}

                        {additionalAttributes?.map((attr, attrIndex) => (
                            <AttributeItem
                                key={`${attr.id}:${attr.key}`}
                                hideDivider={attrIndex + 1 === additionalAttributes?.length}
                                hide={!expanded}
                                attribute={attr}
                                onClickAttributeValue={handleClick}
                            />
                        ))}

                        {additionalAttributes && additionalAttributes?.length > 0 && (
                            <Typography variant="label" color="primary" sx={{ cursor: "pointer" }} onClick={toggleExpand} pl={0.5}>
                                {expanded ? translateText(1208) : translateText(44)}
                            </Typography>
                        )}
                    </AttributesBlock>
                </Stack>
            ))}
        </Stack>
    )
})
