import { useEffect, useCallback, useMemo, useRef } from "react"
import { Article, CustomArticle } from "@tm/models"
import { Genart, PrintImage, RepairTime, Work } from "../../../data/models"
import { ActiveButtons } from "../../../models"
import { Panels } from "../../_shared"
import { maintenanceReviewActions, useFastServiceStore, worksActions } from "../../../data"
import { getTranslationByLocation, useIsFirstRender } from "../../../helpers"

type Props = {
    step: string
    setCompleted: (isCompleted: boolean) => void
    workWhiteList?: string[]
}

export default function WorksList({ step, setCompleted, workWhiteList }: Props) {
    const isFirstMount = useIsFirstRender()
    const nextPanel = useFastServiceStore((state) => state.worksState.nextPanel)
    const workData = useFastServiceStore((state) => state.worksState.works[step])
    const filteredWorkData = useMemo(
        () => (workWhiteList ? workData?.filter((w) => workWhiteList.includes(w.providerId)) : workData),
        [workData, workWhiteList]
    )
    const oeArticles = useRef<Genart[]>([])

    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const selectedItemForStep = selectedItems[step] ?? []

    useEffect(() => {
        const isComplete =
            Object.values(selectedItemForStep).length === workData?.length &&
            Object.values(selectedItemForStep).every((x) => x.selectedStatusButton !== "none")
        setCompleted?.(isComplete)
    }, [selectedItems])

    useEffect(() => {
        Object.entries(selectedItemForStep).forEach(([key, value]) => {
            const oeArticle = filteredWorkData.find((x) => x.label === key)
            const oeArticleGenart = oeArticle?.genArts.find(
                (genArt) =>
                    genArt.isMandatory &&
                    genArt.fsArticle &&
                    genArt.fsArticle.oeId &&
                    !genArt.fsArticle.supplierId &&
                    !genArt.fsArticle.supplierArtNr &&
                    !oeArticles.current.some((y) => y.label === genArt.label)
            )
            if (oeArticleGenart && oeArticle) {
                handleGenArtSelection(oeArticleGenart, oeArticle.label)
                oeArticles.current = [...oeArticles.current, oeArticleGenart]
            }
        })

        if (isFirstMount) {
            return
        }

        maintenanceReviewActions.sendItemsToMaintenanceReview(getTranslationByLocation(step), selectedItemForStep)
    }, [selectedItemForStep])

    const getActivePanel = useCallback((work: Work) => work.label === nextPanel?.label, [selectedItemForStep])

    function handleGenArtSelection(item: Genart, path: string) {
        worksActions.updateSelectedGenarts(item, path, step)
    }

    function handleRepairTimeSelection(item: RepairTime, path: string) {
        worksActions.updateSelectedRepairtimes(item, path, step)
    }

    function handleLubricantSelection(item: string, path: string) {
        worksActions.updateSelectedLubricant(item, path, step)
    }

    function handleLubricantSalvation(item: string, path: string) {
        worksActions.updateSavedLubricant(item, path, step)
    }

    function handleStatusButtonSelection(buttonType: ActiveButtons, path: string) {
        worksActions.updateWorkField({ key: "selectedStatusButton", value: buttonType }, path, step)
    }

    function handleComment(comment: string, path: string) {
        worksActions.updateWorkField({ key: "comment", value: comment }, path, step)
    }

    function handleSafetyStatus(buttonType: number, path: string) {
        worksActions.updateWorkField({ key: "safetyStatus", value: buttonType }, path, step)
    }

    function handleSaveImages(image: PrintImage, path: string, replaceImage: boolean) {
        worksActions.updateImages(image, path, replaceImage, step)
    }

    function handleQuantityChange(articleId: string, quantity: number, path: string) {
        worksActions.updateArticleQuantity(path, step, quantity, articleId)
    }

    function handleOeArticleChange(fsArticleId: string, quantity: number, price: number, designation: string, path: string) {
        worksActions.updateOeArticle(path, step, quantity, price, designation, fsArticleId)
    }

    function handleArticleReplace(articleId: string, newArticle: Article, path: string) {
        worksActions.replaceArticle(path, step, articleId, newArticle)
    }

    function handleOnConsumableReplace(consumableId: string, newConsumableArticle: CustomArticle) {
        worksActions.replaceConsumable(step, consumableId, newConsumableArticle)
    }

    const renderPanels = (work: Work, idx: number) => {
        return (
            <Panels
                key={idx}
                item={work}
                onSelectGenArt={handleGenArtSelection}
                onSelectRepairTime={handleRepairTimeSelection}
                onSelectLubricant={handleLubricantSelection}
                onSaveLubricant={handleLubricantSalvation}
                selectedItems={selectedItemForStep}
                onStatusButtonClick={handleStatusButtonSelection}
                onSaveImages={handleSaveImages}
                onSaveComment={handleComment}
                onSafetyStatus={handleSafetyStatus}
                activePanel={getActivePanel(work)}
                onQuantityChange={handleQuantityChange}
                onOeArticleChange={handleOeArticleChange}
                onArticleReplace={handleArticleReplace}
                onConsumableReplace={handleOnConsumableReplace}
            />
        )
    }

    return <>{filteredWorkData?.map(renderPanels)}</>
}
