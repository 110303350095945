import { ModuleGroupId, ModuleId, ModuleTab } from "@tm/models"
import { ActionDispatch } from "@tm/morpheus"
import { renderRoute } from "@tm/utils"
import { BundleActionType } from "../../../business"
import { getNextUrlComparable } from "../helpers"
import { closeTab, init, rearangeTabs, rewriteTabUrl, splitTabs, openTab } from "./actions"
import { addTabToState, mergeTabs, removeTabFromState, removeTabFromStateById, saveCurrentState } from "./helpers"

export type ModuleNavigationState = {
    initialized: boolean
    loading: boolean
    workTaskId?: string
    workTaskTab?: ModuleTab
    tabs: Array<ModuleTab>
    more: Array<ModuleTab>
    splitPosition?: number
    activateDefaultTabs?: boolean
}

export type ExtendedModuleTab = ModuleTab & {
    vehicleDependency?: string
    moduleGroupId?: ModuleGroupId
    moduleId?: ModuleId
}

export type DefaultTab = {
    icon: string
    name: string
    url: string
    vehicleDependency?: string
    moduleGroupId?: ModuleGroupId
    moduleId?: ModuleId
}

export type ComponentActionType =
    | BundleActionType
    | { type: "MODULE_OPENED"; payload: ModuleTab }
    | { type: "SET_TABS"; payload: { tabs: ModuleTab[] } }
    | { type: "CLOSE_TAB"; payload: ModuleTab }
    | { type: "CLOSE_TAB_BY_ID"; payload: string }
    | { type: "CHANGE_WORKTASK"; payload: [string, DefaultTab[]] }
    | { type: "STATE_LOADING" }
    | { type: "STATE_LOADED"; payload?: ModuleNavigationState }
    | { type: "REARANGE_TABS" }
    | { type: "SPLIT_TABS"; payload: number }
    | { type: "REWRITE_TAB_URL"; payload: { oldUrl: string; newUrl: string } }
    | { type: "INIT"; payload: { activateDefaultTabs?: boolean } }

export type IActions = typeof Actions
export const Actions = {
    init,
    closeTab,
    rearangeTabs,
    splitTabs,
    rewriteTabUrl,
    openTab,
}

const DEFAULT_STATE: ModuleNavigationState = {
    initialized: false,
    loading: false,
    tabs: [],
    more: [],
}

let onOpenWorktask: ((state: ModuleNavigationState) => ModuleNavigationState) | undefined

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ModuleNavigationState {
    switch (action.type) {
        case "INIT": {
            return {
                ...state,
                activateDefaultTabs: action.payload.activateDefaultTabs,
            }
        }
        case "SET_TABS": {
            return {
                ...state,
                tabs: action.payload.tabs,
                more: [],
            }
        }
        case "REARANGE_TABS": {
            return {
                ...state,
                splitPosition: undefined,
                tabs: state.tabs.concat(state.more),
                more: [],
            }
        }
        case "MODULE_OPENED": {
            if (!action.payload || !action.payload.title) {
                return state
            }

            const { location } = window

            const tab: ModuleTab = {
                ...action.payload,
                ...(!action.payload.preventURLRewrite && { url: `${location.pathname}${location.search}` }),
            }

            if (tab.isWorkTask) {
                return {
                    ...state,
                    workTaskTab: tab,
                }
            }
            // Get the requested worktask id to know if it's already been loaded
            const match = /^\/([^/]*)/.exec(location.pathname)
            const locationWorktaskId = match && match[1]

            if (!state.workTaskId || (locationWorktaskId && locationWorktaskId !== state.workTaskId)) {
                onOpenWorktask = (state) => addTabToState(state, tab)
                return state
            }
            return addTabToState(state, tab)
        }
        case "CLOSE_TAB": {
            return removeTabFromState(state, action.payload)
        }
        case "CLOSE_TAB_BY_ID": {
            return removeTabFromStateById(state, action.payload)
        }
        case "CHANGE_WORKTASK": {
            const [workTaskId, defaultTabs] = action.payload
            let returnState: ModuleNavigationState = {
                ...DEFAULT_STATE,
                workTaskId,
                activateDefaultTabs: state.activateDefaultTabs,
                tabs: state.activateDefaultTabs
                    ? defaultTabs.map((x) => {
                          const url = renderRoute(x.url, { workTaskId })
                          return {
                              icon: x.icon,
                              title: x.name,
                              url,
                              isSelected: false,
                              isDefault: true,
                              vehicleDependency: x.vehicleDependency,
                              moduleGroupId: x.moduleGroupId,
                              moduleId: x.moduleId,
                          }
                      })
                    : [],
            }
            if (onOpenWorktask) {
                returnState = onOpenWorktask(returnState)
                onOpenWorktask = undefined
            }

            return returnState
        }
        case "STATE_LOADING": {
            return {
                ...state,
                initialized: true,
                loading: true,
            }
        }
        case "STATE_LOADED": {
            return mergeTabs(state, action.payload ? action.payload.tabs : DEFAULT_STATE.tabs)
        }
        case "SPLIT_TABS": {
            if (state.splitPosition === action.payload) {
                return state
            }

            let tabs = state.tabs.concat(state.more)
            const defaultTabs = tabs.filter((x) => x.isDefault)
            const otherTabs = tabs.filter((x) => !x.isDefault)

            tabs = defaultTabs.concat(otherTabs)
            const selectedIndex = tabs.findIndex((x) => x.isSelected)
            if (selectedIndex >= action.payload && action.payload > 0) {
                return {
                    ...state,
                    tabs: tabs.slice(0, action.payload - 1).concat(selectedIndex >= 0 ? tabs[selectedIndex] : []),
                    more: tabs.slice(action.payload - 1).filter((tab) => !tab.isSelected),
                    splitPosition: action.payload,
                }
            }
            return {
                ...state,
                tabs: tabs.slice(0, action.payload),
                more: tabs.slice(action.payload),
                splitPosition: action.payload,
            }
        }
        case "REWRITE_TAB_URL": {
            const { oldUrl, newUrl } = action.payload
            return {
                ...state,
                tabs: state.tabs.map((tab) => {
                    const comparableOldUrl = getNextUrlComparable(oldUrl)
                    if (comparableOldUrl && tab.url.startsWith(comparableOldUrl)) {
                        return {
                            ...tab,
                            url: newUrl,
                        }
                    }
                    return tab
                }),
            }
        }
        default:
    }
    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>, getState: () => ModuleNavigationState) {
    switch (action.type) {
        case "RESET_MODULE_NAVIGATION": {
            let state = getState()
            state = {
                ...state,
                tabs: state.tabs.filter((x) => x.isDefault),
            }
            saveCurrentState(state)
            dispatch({ type: "SET_TABS", payload: { tabs: state.tabs } })
            break
        }
        case "REMOVE_TABS": {
            let state = getState()
            const { payload } = action
            state = {
                ...state,
                tabs: state.tabs.filter((x) => !payload.includes(x.title)),
            }
            saveCurrentState(state)
            dispatch({ type: "SET_TABS", payload: { tabs: state.tabs } })
            break
        }
        case "MODULE_OPENED": {
            dispatch(action)
            break
        }
        default:
    }
}
