import { OePart } from "@tm/models"
import { ICustomPartsItem } from "./model"

export const mapCustomPart = (oePart: OePart): ICustomPartsItem => {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.quantityValue,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}
