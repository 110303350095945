import { CustomPart } from "@tm/models"
import { eOEArtSubType } from "../../../enums"
import { ExtendedOePart } from "../../../models"

export function mapCustomPart(oePart: ExtendedOePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.datProcessId !== eOEArtSubType.SurchargeOrDiscount ? oePart.quantityValue : 1,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}
