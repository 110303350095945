import { Icon, Stack, StackProps, Tooltip, Typography, getContrastText, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ModificationState } from "@tm/models"
import { useMemo } from "react"
import { usePartsModuleState } from "../../../PartsModuleState"
import { DisplayArticleAttribute } from "../../../models"

type AttributeValueElementProps = StackProps & {
    isClickable?: boolean
    isSelected?: boolean
    isRemoved?: boolean
    isHighlighted?: boolean
}

const AttributeValueElement = styled(Stack, {
    shouldForwardProp: (prop: string) => !["isClickable", "isSelected", "isRemoved", "isHighlighted"].includes(prop),
})<AttributeValueElementProps>(({ theme, isClickable, isSelected, isRemoved, isHighlighted }) => ({
    fontWeight: 600,
    opacity: 1,
    padding: "2px 4px",
    border: "1px solid transparent",
    borderRadius: theme.radius?.default,
    fontFamily: "Roboto Condensed", // theme.font?.fontFamily?.secondary,
    whiteSpace: "nowrap",

    svg: {
        marginRight: 4,
    },

    ...(isRemoved && {
        textDecoration: "line-through",
    }),
    ...(isClickable && {
        cursor: "pointer",
        ...(!isSelected && {
            "&:hover *": {
                color: theme.palette.primary.main,
            },
        }),
    }),
    ...(isHighlighted && {
        backgroundColor: theme.palette.highlight.light,
        borderColor: theme.palette.highlight.main,
    }),
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        borderColor: "transparent",
        "*": {
            color: getContrastText(theme.palette.primary.main),
        },
    }),
}))
AttributeValueElement.defaultProps = { direction: "row" }

export function AttributeValue({ attribute, onClick }: { attribute: DisplayArticleAttribute; onClick?: (val: string) => void }) {
    const attributes = usePartsModuleState((x) => x.params.attributes)
    const { translateText } = useLocalization()

    const handleClick = useMemo(
        () => (onClick && attribute.value ? () => attribute.value && onClick(attribute.value) : undefined),
        [attribute.value, onClick]
    )

    const isSelected = attributes.some((x) => x.id === attribute.id && x.value === attribute.value)
    const isRemoved = attribute.modificationState === ModificationState.Removed

    let content = <Typography variant="label">{attribute.displayValue}</Typography>

    if (attribute.alternatives.length) {
        content = (
            <Tooltip
                sx={{ whiteSpace: "pre-wrap" }}
                title={`${translateText(attribute.isOptimized ? 1642 : 1643)}:\n${attribute.alternatives.join("\n")}`}
            >
                {content}
            </Tooltip>
        )
    }

    return (
        <AttributeValueElement
            onClick={handleClick}
            isHighlighted={attribute.isHighlighted}
            isSelected={isSelected}
            isRemoved={isRemoved}
            isClickable={!!onClick && !isRemoved}
        >
            {(() => {
                switch (attribute.modificationState) {
                    case ModificationState.Added:
                        return (
                            <Tooltip title={translateText(886)}>
                                <Icon name="plus" size="12px" />
                            </Tooltip>
                        )
                    case ModificationState.Removed:
                        return (
                            <Tooltip title={translateText(895)}>
                                <Icon name="minus" size="12px" />
                            </Tooltip>
                        )
                    default:
                }
            })()}
            {content}
        </AttributeValueElement>
    )
}
