import { CostEstimationTotals } from "../../../../data/model"
import CostEstimationDetails from "./CostEstimationDetails"
import Totals from "./Totals"

type Props = {
    totals: CostEstimationTotals
    currencyCode: string
    showRepairTimesInHours: boolean
}

export default function CostEstimationTotalsTable({ totals, currencyCode, showRepairTimesInHours }: Props) {
    return (
        <>
            <CostEstimationDetails totals={totals} currencyCode={currencyCode} showRepairTimesInHours={showRepairTimesInHours} />
            <Totals totals={totals} currencyCode={currencyCode} />
        </>
    )
}
