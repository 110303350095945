import { isEqual, sortBy } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useWorkTask } from "@tm/context-distribution"
import { notUndefinedOrNull } from "@tm/utils"
import { mapCriterionFilter, mapDataSupplierFilter, mapListFilterToQuery, mapProductGroupFilter } from "../../../helpers"
import { FilterActions, FiltersData, ListFilter, ListFilterGroup, ListParams, UniversalPartsStartParams } from "../../../models"
import { useFiltersByNode } from "./useFiltersByNode"
import { useFiltersByQuery } from "./useFiltersByQuery"
import {
    ExtendedAssortmentAtom,
    FixedAttributeFilterQueriesAtom,
    SelectedAttributeFilterQueriesAtom,
    SelectedProductGroupIdsAtom,
    SelectedSupplierIdsAtom,
} from "../../../states"
import { useFilterStorageKey } from "../../../hooks/useFilterStorageKey"
import { useFiltersByProductGroups } from "./useFiltersByProductGroups"

export const FILTERS_QUERY = "ARTICLE_UNI_FILTER_QUERY"
export const CHECK_DELAY = 500

export function useFilters(params: ListParams<UniversalPartsStartParams>, isEnabled: boolean): FiltersData & FilterActions {
    const { startParams } = params
    const storageKey = useFilterStorageKey(startParams)

    const [showOnlyAvailable, setShowOnlyAvailable] = useState(!!params.showAvailable)
    const [extendedAssortmentEnabledByUser, setExtendedAssortmentEnabledByUser] = useState(!!params.extendedAssortment)
    const [extendedAssortmentEnabled, setExtendedAssortmentEnabled] = useRecoilState(ExtendedAssortmentAtom(storageKey))
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useRecoilState(SelectedProductGroupIdsAtom(storageKey))
    const [selectedSupplierIds, setSelectedSupplierIds] = useRecoilState(SelectedSupplierIdsAtom(storageKey))
    const setSelectedAttributeFilterQueries = useSetRecoilState(SelectedAttributeFilterQueriesAtom(storageKey))
    const fixedAttributeFilterQueries = useRecoilValue(FixedAttributeFilterQueriesAtom(storageKey))

    const filtersByQuery = useFiltersByQuery(params, isEnabled)
    const filtersByNode = useFiltersByNode(params, isEnabled)
    const filtersByProductGroups = useFiltersByProductGroups(params, isEnabled)

    const {
        data: loadedFilters,
        isLoading,
        isRefetching,
        isSuccess,
    } = useMemo(() => {
        switch (startParams.type) {
            case "uninode":
                return filtersByNode
            case "uniproductgroups":
                return filtersByProductGroups
            case "unisearch":
            default:
                return filtersByQuery
        }
    }, [filtersByNode, filtersByProductGroups, filtersByQuery, startParams.type])

    const extendedAssortmentForced = useMemo(() => {
        if (!loadedFilters) {
            return false
        }
        const anyExtendedProductGroupsIsSelected =
            loadedFilters.productGroupFilters.some((x) => !x.hasTopPrioritySuppliers && selectedProductGroupIds.includes(x.id)) ||
            (!loadedFilters.productGroupFilters.some((x) => x.hasTopPrioritySuppliers) && !selectedProductGroupIds.length)
        const anyExtendedSupplierIsSelected = loadedFilters.supplierFilters.some((x) => !x.isTopPriority && selectedSupplierIds.includes(x.id))
        const noResult = isSuccess && !loadedFilters.productGroupFilters.length && !loadedFilters.supplierFilters.length // TODO: clarify if extended assortment should really be enabled automatically in this case (example: no filter results for current search query)

        return anyExtendedProductGroupsIsSelected || anyExtendedSupplierIsSelected || noResult
    }, [loadedFilters, selectedSupplierIds, selectedProductGroupIds, isSuccess])

    const productGroupFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.productGroupFilters) {
            return []
        }
        return loadedFilters.productGroupFilters.map((filter) => [mapProductGroupFilter(filter), selectedProductGroupIds.includes(filter.id)])
    }, [selectedProductGroupIds, loadedFilters?.productGroupFilters])

    const dataSupplierFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.supplierFilters) {
            return []
        }
        return loadedFilters.supplierFilters.map((filter) => [mapDataSupplierFilter(filter), selectedSupplierIds.includes(filter.id)])
    }, [selectedSupplierIds, loadedFilters?.supplierFilters])

    const groupedAttributeFilters = useMemo<ListFilterGroup[]>(() => {
        if (!loadedFilters?.criterionFilterGroups) {
            return []
        }
        // TODO : ADD FILTERSCHANGED - TmaHelper.UniParts.List.FilterChanged({ criterionFilters: (request.selectedCriteria as CriterionFilter[]) ?? [] })
        return loadedFilters.criterionFilterGroups.map((group) => ({
            ...group,
            filters: group.criterionFilters.map((crit) => {
                const filter = mapCriterionFilter(crit)
                if (!!filter.query && fixedAttributeFilterQueries.includes(filter.query)) {
                    filter.isSelectable = false
                }
                return [filter, !!filter.isSelected]
            }),
        }))
    }, [loadedFilters?.criterionFilterGroups, fixedAttributeFilterQueries])

    useEffect(
        function transferSelectedFiltersToParams() {
            if (!loadedFilters) {
                return
            }

            if (!selectedProductGroupIds.length) {
                params.setProductGroups(
                    (extendedAssortmentForced ? loadedFilters.productGroupFilters : loadedFilters.productGroupFilters.filter((x) => x.showOnTop))
                        .map(mapProductGroupFilter)
                        .map(mapFilterForParams)
                )
            } else {
                params.setProductGroups(
                    loadedFilters.productGroupFilters
                        .filter((filter) => selectedProductGroupIds.includes(filter.id))
                        .map(mapProductGroupFilter)
                        .map(mapFilterForParams)
                )
            }
            params.setSuppliers(
                loadedFilters.supplierFilters
                    .filter((filter) => selectedSupplierIds.includes(filter.id))
                    .map(mapDataSupplierFilter)
                    .map(mapFilterForParams)
            )
            params.setAttributes(
                loadedFilters.criterionFilterGroups
                    .flatMap((x) => x.criterionFilters)
                    .filter((x) => x.isSelected)
                    .map(mapCriterionFilter)
                    .map(mapFilterForParams)
            )
            params.setExtendedAssortment(extendedAssortmentEnabled)
        },
        [loadedFilters, extendedAssortmentEnabled]
    )

    useEffect(
        function checkAndSetAttributes() {
            setSelectedAttributeFilterQueries((state) => {
                const queries = sortBy(params.attributes.map(mapListFilterToQuery).filter(notUndefinedOrNull))
                if (!isEqual(state, queries)) {
                    return queries
                }
                return state
            })
        },
        [params.attributes]
    )

    useEffect(
        () => setExtendedAssortmentEnabled(extendedAssortmentEnabledByUser || extendedAssortmentForced),
        [extendedAssortmentEnabledByUser, extendedAssortmentForced, setExtendedAssortmentEnabled]
    )

    const toggleProductGroup = useCallback(
        (id: number, exclusive?: boolean) => {
            setSelectedProductGroupIds((state) => {
                if (exclusive) {
                    if (state.length === 1 && state.includes(id)) {
                        return []
                    }
                    return [id]
                }
                if (state.includes(id)) {
                    return state.filter((x) => x !== id)
                }
                return sortBy([...state, id])
            })
        },
        [setSelectedProductGroupIds]
    )

    const toggleSupplier = useCallback(
        (id: number, exclusive?: boolean) => {
            setSelectedSupplierIds((state) => {
                if (exclusive) {
                    if (state.length === 1 && state.includes(id)) {
                        return []
                    }
                    return [id]
                }
                if (state.includes(id)) {
                    return state.filter((x) => x !== id)
                }
                return sortBy([...state, id])
            })
        },
        [setSelectedSupplierIds]
    )

    const toggleAttribute = useCallback(
        (attribute: ListFilter, exclusive?: boolean) => {
            setSelectedAttributeFilterQueries((state) => {
                if (!attribute.query) {
                    return state
                }

                if (exclusive) {
                    if (state.length === 1 && state.some((filter) => filter === attribute.query)) {
                        return []
                    }
                    return [attribute.query]
                }
                const index = state.findIndex((filter) => filter === attribute.query)
                if (index !== -1) {
                    return sortBy(state.filter((_, idx) => index !== idx))
                }
                return sortBy([...state, attribute.query])
            })
        },
        [setSelectedAttributeFilterQueries]
    )

    const toggleExtendedAssortment = useCallback(() => {
        setExtendedAssortmentEnabledByUser((state) => !state)
    }, [])

    const toggleAvailability = useCallback(() => {
        setShowOnlyAvailable((state) => !state)
    }, [])

    const resetProductGroups = useCallback(() => setSelectedProductGroupIds((prev) => (prev.length ? [] : prev)), [setSelectedProductGroupIds])
    const resetSuppliers = useCallback(() => setSelectedSupplierIds((prev) => (prev.length ? [] : prev)), [setSelectedSupplierIds])
    const resetAttributes = useCallback(
        () => setSelectedAttributeFilterQueries((prev) => (prev.length ? [] : prev)),
        [setSelectedAttributeFilterQueries]
    )

    return useMemo<FiltersData & FilterActions>(
        () => ({
            extendedAssortment: {
                enabled: extendedAssortmentEnabledByUser,
                forced: extendedAssortmentForced,
            },
            showOnlyAvailable,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters: [],
            groupedAttributeFilters,
            isLoading,
            isRefetching,
            showExtendedAssortmentFilter: loadedFilters?.showExtendedAssortmentFilter ?? false,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        }),
        [
            extendedAssortmentEnabledByUser,
            extendedAssortmentForced,
            showOnlyAvailable,
            productGroupFilters,
            dataSupplierFilters,
            groupedAttributeFilters,
            isLoading,
            isRefetching,
            loadedFilters?.showExtendedAssortmentFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        ]
    )
}

// things like counts don't need to be stored for requesting new articles
function mapFilterForParams(filter: ListFilter): ListFilter {
    return {
        ...filter,
        articleCount: undefined,
        topPriorityArticleCount: undefined,
        isSelected: undefined,
    }
}
