import * as React from 'react';
import { RouteComponentProps } from 'react-router'
import { useLocalization } from '@tm/localization'
import { Actions } from './business'
import { withRouter } from '@tm/utils'
import { PanelSection, Table, Text, SubTitle, Loader } from '@tm/controls'
import { EFilterNames } from "@tm/models"
import ApplyButton from "../_shared/applyBtn"
import { WheelSelectionSteps } from '../../data/enums'
import { CarParkAttribute, CarPark } from '../../data/model'
import { batch, useDispatch, useSelector } from 'react-redux'
import { MainState } from '../main'
import { registrationNosSelector, vehicleSelector } from '../../data/helpers'
import { MainActions } from '../main/business'
import CertificateTypeFiels from '../_shared/certificateTypeFiels'
import { VehicleImage } from '@tm/components'
import { ContactWheels } from '../_shared'
import { getBundleParams } from '../../utils';

type Props = RouteComponentProps & {}

const VehicleSelection: React.FC<Props> = ({ }) => {

	const dispatch = useDispatch()
	const { translateText } = useLocalization()
	const vehicle = useSelector(vehicleSelector)
	const isHostettler = getBundleParams()?.isHostettler
	const { carParkItems, carParkHeaders, selectedCarPark, sendSelectedCarpark, selectedRegistationNr, sentRegistrationNumber, selectedSizeFilter } = useSelector((s: MainState) => ({
		carParkItems: s.loadingScreen.carParkItems,
		carParkHeaders: s.loadingScreen.carParkHeaders,
		selectedCarPark: s.vehicleSelection.selectedCarPark,
		sendSelectedCarpark: s.vehicleSelection.sendSelectedCarpark,
		selectedRegistationNr: s.vehicleSelection.selectedRegistrationNo,
		sentRegistrationNumber: s.vehicleSelection.sentRegistrationNumber,
		selectedSizeFilter: s.tyresList.selectedFilters.tyreSize
	}))
	const { registrationNos } = useSelector(registrationNosSelector)
	const handleGetRegistrationNo = (): Array<string> => {
		return registrationNos ? registrationNos : vehicle?.registrationNo ? [vehicle.registrationNo] : []
	}

	const disabledApplyBtn = React.useMemo(() => !(isHostettler ? (selectedRegistationNr && selectedCarPark) : selectedCarPark), [selectedRegistationNr, selectedCarPark])

	const { loading, error } = useSelector((s: MainState) => s.loadingScreen)

	const handleSelectedCarPark = () => {
		if (isHostettler ? (selectedRegistationNr == sentRegistrationNumber && sendSelectedCarpark?.carparkId == selectedCarPark?.carparkId) :
			sendSelectedCarpark?.carparkId == selectedCarPark?.carparkId) return
		batch(() => {
			dispatch(Actions.sendSelectedCarParkToWheelsList())
			selectedSizeFilter && dispatch(MainActions.updateFilter(EFilterNames.tyreSize, {...selectedSizeFilter, value: `${selectedSizeFilter?.value} ${selectedCarPark?.loadIndex}${selectedCarPark?.speedIndex}` }))
		})
	}

	const handleButton = () => {
		handleSelectedCarPark()
		dispatch(MainActions.changeStep({ step: WheelSelectionSteps.WHEELSLIST, disableNextSteps: true }, true))
	}

	const renderTableItem = (header: CarParkAttribute, item: CarPark) => {
		let value = ""
		var attribute = item.attributes.find(x => x.attributeId == header.attributeId)
		if (attribute)
			value = attribute.value || ""

		return (
			<Table.Cell>
				<div>{value}</div>
			</Table.Cell>
		)
	}

	const handleClickRow = (item: CarPark) => {
		dispatch(Actions.selectCarParkItem(item))
	}

	const renderCarparkCollums = (item: CarParkAttribute) => {
		return <Table.Column renderItemContent={renderTableItem.bind(this, item)}>{item.description}</Table.Column>
	}

	const handleDoubleClickSelection = (item: CarPark) => {
		if (isHostettler && !selectedRegistationNr) return

		dispatch(Actions.selectCarParkItem(item))
		handleSelectedCarPark()
		dispatch(MainActions.changeStep({ step: WheelSelectionSteps.WHEELSLIST, disableNextSteps: true }, true))
	}

	const renderContent = () => {

		if (error) {
			return (
				<div className="vehicle-selection-container article-list__panel article-list__status">
					<Text>{translateText(163)}</Text>
				</div>
			)
		}

		if (loading) {
			return <Loader className="loader" />
		}

		return (
			<div className="vehicle-info-selections">
				<Table
					className={(isHostettler && !selectedRegistationNr) ? "disabled-table" : "not-disabled"}
					data={carParkItems.filter(x => x.attributes?.some(x => x.description != null))}
					columns={carParkHeaders.map(renderCarparkCollums)}
					getRowClassName={data => selectedCarPark && (selectedCarPark.carparkId == data.carparkId) ? "is-clickable is-selected" : "is-clickable"}
					onClickRow={handleClickRow}
					onDoubleClickRow={handleDoubleClickSelection}>
				</Table>
			</div>
		)
	}

	if (!vehicle) return null

	return (
		<div className="vehicle-selection-container">
			<ApplyButton
				disabledApplyBtn={disabledApplyBtn}
				showApplyBtn
				onApply={handleButton} />
			<div className="selections">
				<PanelSection >
					<SubTitle>{translateText(71)}</SubTitle>
					<Text size="l" modifiers="strong">{vehicle.manufacturer}</Text>
				</PanelSection>
				<PanelSection className="model-series" >
					<SubTitle>{translateText(73)}</SubTitle>
					<Text size="l" modifiers="strong">{vehicle.modelSeries}</Text>
				</PanelSection>
				<PanelSection >
					<SubTitle>{translateText(74)}</SubTitle>
					<Text size="l" modifiers="strong">{vehicle.model}</Text>
				</PanelSection>
			</div>
			<div className="selections">
				<PanelSection>
					<VehicleImage
						modelImage={vehicle.modelSeriesImage || ""}
						modelSeriesImage={vehicle.modelSeriesThumbnail?.replace('kmodthumb', 'kmod') || ""}
						vehicleType={vehicle.vehicleType}
					/>
				</PanelSection>
				<PanelSection className="selection-wrapper">
					{isHostettler && <CertificateTypeFiels />}
					{renderContent()}
				</PanelSection>
				<div />
			</div>
			{(isHostettler && !handleGetRegistrationNo().length) &&
				<div className='error'>
					<ContactWheels />
				</div>
			}
		</div>
	)
}

export default withRouter(VehicleSelection)