import * as React from "react"
import { em, padding } from "csx"
import { useStyle } from "@tm/context-distribution"
import { Text, Button, MoreMenu } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { styled } from "@tm/components"
import { CalcGenArt, NotFoundArticle } from "../../data/model"
import ArticleCell from "./articleCell"
import CalcInputComponent from "./calcInput"
import TotalPrice from "./totalPrice"
import ReplaceButton from "./replaceButton"

type Props = {
    article: NotFoundArticle
    genArt?: CalcGenArt
    hideAlternatives?: boolean
    showProductGroupAlternatives(genArt: CalcGenArt): void
}

const NoArticleFound: React.FC<Props> = ({ article, genArt, hideAlternatives, showProductGroupAlternatives }) => {
    const { translate } = useLocalization()

    function showAlternatives() {
        genArt && showProductGroupAlternatives(genArt)
    }

    return (
        <StyledDiv className={classes("article-list__item", "article-list__panel")}>
            <ArticleCell bemModifier="description">
                <Text modifiers="strong">{article.label || "not found"}</Text>
            </ArticleCell>

            <ArticleCell bemModifier="actions">
                <div className={classes("article-actions", style.actions)}>
                    <CalcInputComponent item={article.price} />
                    <TotalPrice totalPrice={article.totalPrice} />
                    <CalcInputComponent item={article.quantity} />
                    <CalcInputComponent item={article.removeInput} />
                    <ReplaceButton disabled={!genArt} onClick={showAlternatives} />
                </div>
                {/* just show these two items without functionality to position the other buttons the same way as in the other articles */}
                <Button disabled>{translate(43)}</Button>
                <MoreMenu disabled items={[]} onMenuItemSelect={() => {}} />
            </ArticleCell>
        </StyledDiv>
    )
}

const StyledDiv = styled("div")({
    padding: padding(em(0.25), 0, 0, em(0.5)),
})

const style = useStyle({
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        $nest: {
            "> :not(:last-child)": {
                marginRight: em(0.25),
                marginLeft: 0,
            },
        },
    },
})(NoArticleFound)

export default NoArticleFound
