import { useUser } from "@tm/context-distribution"
import { Image } from "@tm/controls"

export const CustomerLogo = () => {
    const repairShop = useUser()?.userSettings?.repairShop
    let logo = repairShop?.printLogo ? repairShop?.logo : undefined

    if (!logo) {
        return null
    }

    logo = logo.replace("image/*", "image/png") /* IE11 & Edge Fix */

    return (
        <div className="customer-info__logo">
            <Image url={logo} />
        </div>
    )
}
