import {
    CategoryType,
    channel,
    GetSearchTreeRequest,
    RequestArticleListPayload,
    SearchFilters,
    SearchTreeProductGroup,
    TreeNode,
    Vehicle,
    CatalogContextType,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Dictionary, TmaHelper } from "@tm/utils"

import { BundleActions, BundleActionType, TabInfo } from "../../../business"
import { Models, Repositories } from "../../../data"
import { getTreeIdFromCategoryType, getVehicleTreeIdFromVehicleType } from "../../../helper"
import { getQueryHistory } from "../../_shared/helper/queryhistory"

import { TreeConfig, WidgetState } from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "INITIALIZE_TREES"; payload: { trees: Dictionary<TreeConfig>; vehicle?: Vehicle } }
    | { type: "SET_ACTIVE_CATEGORY"; payload: { category: CategoryType; manuallySelected: boolean } }
    | { type: "SEARCH_TREE_LOADING"; payload: CategoryType }
    | {
          type: "SEARCH_TREE_LOADED"
          payload: {
              category: CategoryType
              nodes: Array<TreeNode>
              topProductGroups: Array<SearchTreeProductGroup>
              parentNode?: TreeNode
          }
      }
    | { type: "CHANGE_BREADCRUMBS"; payload: Array<TreeNode> }

export type IActions = typeof Actions

function initializeTrees(trees: Dictionary<TreeConfig>, vehicle?: Vehicle): ComponentActionType {
    return { type: "INITIALIZE_TREES", payload: { trees, vehicle } }
}

function loadVehicleNodes(
    vehicle: Vehicle | undefined,
    parentNode?: TreeNode,
    loadSecondLevel?: boolean,
    onlyTopProductGroups?: boolean
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch) => {
        if (!vehicle) {
            return
        }
        const treeId = getVehicleTreeIdFromVehicleType(vehicle.vehicleType)
        if (!treeId) {
            return
        }

        const request: GetSearchTreeRequest = {
            modelId: vehicle.tecDocTypeId,
            vehicleType: vehicle.vehicleType,
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: {
                        category: "vehicleParts",
                        nodes: response.nodes,
                        topProductGroups: response.topProductGroups,
                        parentNode,
                    },
                }),
            () =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: { category: "vehicleParts", nodes: [], topProductGroups: [] },
                })
        )

        dispatch({ type: "SEARCH_TREE_LOADING", payload: "vehicleParts" })
    }
}

function loadUniPartsNodes(
    treeId: number,
    parentNode?: TreeNode,
    loadSecondLevel?: boolean,
    onlyTopProductGroups?: boolean
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch) => {
        const request: Models.UniParts.SearchTreeRequest = {
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.UniParts.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: {
                        category: "universalParts",
                        nodes: response.nodes,
                        topProductGroups: response.topProductGroups,
                        parentNode,
                    },
                }),
            () =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: { category: "universalParts", nodes: [], topProductGroups: [] },
                })
        )

        dispatch({ type: "SEARCH_TREE_LOADING", payload: "universalParts" })
    }
}

function loadSearchTreeNodes(
    vehicle: Vehicle | undefined,
    parentNode?: TreeNode,
    onlyTopProductGroups?: boolean
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory, trees } = getState()

        const activeTree = trees[activeCategory]

        // Don't load tree nodes if the tree is not initialized or already loaded
        if (!activeTree || (parentNode ? parentNode.childNodes && parentNode.topProductGroups : activeTree.groups.length)) {
            return
        }

        switch (activeCategory) {
            case "vehicleParts": {
                dispatch(loadVehicleNodes(vehicle, parentNode, activeTree.showSecondLevel, onlyTopProductGroups))
                break
            }
            case "universalParts": {
                dispatch(loadUniPartsNodes(activeTree.treeId, parentNode, activeTree.showSecondLevel, onlyTopProductGroups))
                break
            }
            default:
        }
    }
}

function setActiveCategory(
    vehicle: Vehicle | undefined,
    category: CategoryType,
    manuallySelected: boolean
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory } = getState()

        // If the supplied tree type is already active exit
        if (category === activeCategory) {
            return
        }

        dispatch({ type: "SET_ACTIVE_CATEGORY", payload: { category, manuallySelected } })
        dispatch(loadSearchTreeNodes(vehicle, undefined, undefined))
    }
}

function requestArticleList(request: RequestArticleListPayload): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { trees } = getState()

        Object.keys(trees).forEach((key) => {
            const tree = trees[key]
            if (!tree) {
                return
            }

            // Pass the current state of the searchTree to the searchTree component on the article list
            // Give a little delay for remount
            window.setTimeout(() => {
                dispatch(BundleActions.setSearchTreeData(key as CategoryType, tree.groups, tree.breadcrumbs, tree.selectedNode))
            }, 250)
        })

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}

function changeBreadcrumbs(
    vehicle: Vehicle | undefined,
    breadcrumbs: Array<TreeNode>,
    startSearch?: boolean
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory, trees } = getState()
        const activeTree = trees[activeCategory]

        dispatch({ type: "CHANGE_BREADCRUMBS", payload: breadcrumbs })

        const activeParentNode = breadcrumbs.last()

        // If no breadcrumbs is selected / all are removed
        // just exit after updating the breadcrumbs
        if (!activeParentNode) {
            return
        }

        const isLastNode = !activeParentNode.hasChildNodes

        // Check if the child nodes (childNodes array is undefined)
        // or the top product groups (topProductGroups array is undefined) have to be loaded
        if (!activeParentNode.childNodes || !activeParentNode.topProductGroups) {
            dispatch(loadSearchTreeNodes(vehicle, activeParentNode, !!activeParentNode.childNodes || isLastNode))
        }

        // If is the last node in tree or the "startSearch" parameter is provided
        // request to open the article list
        if (isLastNode || startSearch) {
            const history = breadcrumbs.map((b) => b.id)
            switch (activeCategory) {
                case "vehicleParts": {
                    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.NodeSelected({
                        mode: activeTree.mode,
                        catalogContext: CatalogContextType.Dashbord,
                        nodeId: activeParentNode.id,
                        treeId: activeTree.treeId,
                    })

                    dispatch(requestArticleList({ node: { treeId: activeTree.treeId, nodeId: activeParentNode.id, history } }))
                    break
                }
                case "universalParts": {
                    TmaHelper.UniParts.SearchTree.NodeSelected({
                        mode: activeTree.mode,
                        catalogContext: CatalogContextType.Dashbord,
                        nodeId: activeParentNode.id,
                        treeId: activeTree.treeId,
                    })
                    dispatch(requestArticleList({ uniNode: { treeId: activeTree.treeId, nodeId: activeParentNode.id, history } }))
                    break
                }
                default:
            }
        }
    }
}

function applyProductGroups(
    productGroups: Array<SearchTreeProductGroup>,
    vehicle: Vehicle | undefined
): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory, trees } = getState()
        const activeTree = trees[activeCategory]

        if (activeTree?.selectedNode) {
            // Clear the selectedNode by changing the breadcrumbs
            dispatch({ type: "CHANGE_BREADCRUMBS", payload: activeTree.breadcrumbs })
        }

        switch (activeCategory) {
            case "vehicleParts": {
                dispatch(
                    requestArticleList({
                        productGroups: {
                            ids: productGroups.map((x) => x.id).orderBy((x) => x),
                            treeId: getTreeIdFromCategoryType(activeCategory, vehicle?.vehicleType),
                            nodeId: activeTree?.breadcrumbs.last()?.id,
                        },
                    })
                )
                break
            }
            case "universalParts": {
                dispatch(requestArticleList({ uniProductGroups: { ids: productGroups.map((x) => x.id).orderBy((x) => x) } }))
                break
            }
            case "predictive": {
                dispatch(requestArticleList({ productGroups: { ids: productGroups.map((x) => x.id).orderBy((x) => x) } }))
                break
            }
            default:
        }
    }
}

function searchArticles(query: string, searchFilter?: SearchFilters, extendedSearch?: boolean): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory } = getState()
        const request: RequestArticleListPayload & { direct?: { extendedSearch?: boolean } } = {}

        const tabInfo: TabInfo = {
            moduleFilter: `"${query}"`,
        }

        switch (activeCategory) {
            case "vehicleParts": {
                request.synonym = { query }
                tabInfo.moduleView = "{{389}}"
                break
            }
            case "universalParts": {
                request.uniSearch = { query }
                tabInfo.moduleView = "{{1009}}"
                break
            }
            case "directSearch": {
                request.direct = { query, searchFilter, extendedSearch }
                tabInfo.moduleView = "{{389}}"
                break
            }
            default:
        }
        dispatch({ type: "SET_TAB_INFO", payload: tabInfo })
        dispatch(requestArticleList(request))
    }
}

function loadQueryHistory(): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        getQueryHistory().then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

export const Actions = {
    ...BundleActions,

    initializeTrees,
    setActiveCategory,

    loadSearchTreeNodes,
    changeBreadcrumbs,
    applyProductGroups,

    searchArticles,
    loadQueryHistory,
}
