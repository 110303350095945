import { SearchType } from "../../business"
import { getBundleParams } from "../../utils"

export function useGetSearchteeVersion(searchType?: SearchType): "searchtreeV2" | "searchtree" {
    return getSearchtreeVersion(searchType)
}

// Support for classComponents
export function getSearchtreeVersion(searchType?: SearchType) {
    const withBreadCrumbs = getBundleParams().searchtreeWithBreadcrumbs
    // not the nicest solution but a "simple" one! will hopefully be solved nicer after article list refactoring
    const enableForSearchtype =
        (searchType !== SearchType.DIRECT && !window.location?.pathname?.includes("universal/list") && searchType !== SearchType.NONE) ||
        // Empty vehiclelist
        (searchType === SearchType.NONE && window.location?.pathname?.includes("vehicles/list"))
    return withBreadCrumbs && enableForSearchtype ? "searchtreeV2" : "searchtree"
}
