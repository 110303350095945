import { Box, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

export function NoResultHint() {
    const { translate } = useLocalization()

    return (
        <Box mt={2} display="flex" justifyContent="center">
            <Typography> {translate(12775)}</Typography>
        </Box>
    )
}
