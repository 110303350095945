import { Suspense } from "react"
import { Divider, Stack, useTheme } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { getBundleParams } from "../../utils"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { LoaderSmall } from "../StyledComponents"
import { PartsTotals } from "./components/BasketTotals"
import CostEstimationTotals from "./components/CostEstimationTotals"
import { useCostEstimationVisible } from "../../hooks/basketState/useCostEstimationVisible"

type Props = {
    basketPopover?: boolean
    costEstimationRoute: string
    hideBasketButton?: boolean
    hideBasketButtonText?: boolean
    partsBasketRoute: string
    swapTotals?: boolean
}

export type SummaryRouteProps = {
    page?: string
}

function Summary(props: Props & { workTaskId: string }) {
    const { hideBasketButton, hideBasketButtonText, swapTotals, basketPopover, partsBasketRoute, costEstimationRoute, workTaskId } = props
    const { costEstimationVisible } = useCostEstimationVisible(workTaskId)
    const { hideBasket, hideCostEstimationHours } = getBundleParams()
    const { basket, costEstimation, workTaskBasketCalculation } = useWorkTaskBasketState(workTaskId)
    const { basketLoading, basketWithErrors } = basket.state
    const { costEstimationLoading, costEstimationWithErrors, currencyCode, repairTimeDivision, showRepairTimesInHours } = costEstimation.state

    const theme = useTheme()
    const backgroundColor = theme.overwrites?.components?.basketSummary?.component?.backgroundColor
    const boxShadow = theme.overwrites?.components?.basketSummary?.component?.boxShadow || "none"

    return (
        <Stack
            direction={swapTotals ? "row-reverse" : "row"}
            divider={<Divider orientation="vertical" />}
            alignItems="center"
            bgcolor={backgroundColor}
            boxShadow={boxShadow}
        >
            {!hideBasketButton && !hideBasket && (
                <PartsTotals
                    workTaskId={workTaskId}
                    calculatedOrder={workTaskBasketCalculation?.calculatedOrder}
                    isLoading={basketLoading}
                    hasErrors={basketWithErrors}
                    showPopover={basketPopover}
                    hideButtonText={hideBasketButtonText}
                    partsBasketRoute={partsBasketRoute}
                />
            )}
            {!!costEstimationVisible && (
                <CostEstimationTotals
                    workTaskId={workTaskId}
                    totals={workTaskBasketCalculation?.calculatedCostEstimation?.totals}
                    showRepairTimesInHours={showRepairTimesInHours}
                    isLoading={costEstimationLoading}
                    hasErrors={costEstimationWithErrors}
                    costEstimationRoute={costEstimationRoute}
                    hideHours={hideCostEstimationHours}
                    swapTotals={swapTotals}
                    currencyCode={currencyCode}
                    repairTimeDivision={repairTimeDivision}
                />
            )}
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    if (!workTaskId) {
        return null
    }
    return (
        <Suspense fallback={<LoaderSmall />}>
            <Summary {...props} workTaskId={workTaskId} />
        </Suspense>
    )
}
