import { styled } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { classes } from "@tm/utils"
import { Button, ButtonSkins } from "@tm/controls"

type Props = {
    skin?: ButtonSkins
    disabled?: boolean
    onClick(): void
}
type StyledButtonProps = {
    isWm?: boolean
}

export default function ReplaceButton({ onClick, disabled, skin }: Props) {
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    return (
        <StyledButton
            skin={skin || "highlight"}
            disabled={disabled}
            className={classes("replace", "add-to-basket__button", isWM ? "wm-replace" : "")}
            icon={isWM ? "wm-logo" : "replace"}
            onClick={onClick}
            isWm={isWM}
        />
    )
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "isWm",
})<StyledButtonProps>(({ isWm }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: isWm ? "13em" : "6em",
    marginLeft: "auto",
}))
