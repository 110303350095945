import { Button, Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, styled } from "@tm/components"
import { mainActions, useDrivemotiveStore } from "../../data/state"
import { NavigationSteps } from "../../data/models"

export function NavigationComponent() {
    const { translateText } = useLocalization()
    const navigationStep = useDrivemotiveStore((state) => state.main.navigationStep)

    return (
        <StyledWrapper>
            <Headline size="s">{translateText(13689)}</Headline>
            <StyledNavigationTabs>
                <Button onClick={() => mainActions.changeStep(NavigationSteps.Summary)} isActive={navigationStep === NavigationSteps.Summary}>
                    {translateText(409)}
                </Button>
                <Button onClick={() => mainActions.changeStep(NavigationSteps.Calculation)} isActive={navigationStep === NavigationSteps.Calculation}>
                    {translateText(613)}
                </Button>
            </StyledNavigationTabs>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
})

const StyledNavigationTabs = styled(Box)({
    marginLeft: "1em",
})
