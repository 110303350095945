import { StateCreator } from "zustand"
import { FastServiceStore, maintenancePlanActions, useFastServiceStore } from "../.."
import { createEndPageSlice } from "../end-page"
import { createInspectionWorkSlice } from "../inspection-work"
import { createMaintenancePlanSlice } from "../maintenance-plan"
import { createNavigationSlice } from "../navigation"
import { createStepNavigationSlice } from "../steps-navigation"
import { createTechnicianSlice } from "../technician"
import { createTestDriveSlice } from "../test-drive"
import { createTesterSlice } from "../tester"
import { createWorksSlice } from "../works"
import { ManagerSlice, ManagerState } from "./model"
import { createMaintenanceReviewSlice } from "../maintenance-review"
import { createTyresWheelsSlice } from "../tyres-wheels"
import { CalculationHistoryStatus } from "../../models"

function getInitialState(): ManagerState {
    return {
        historyLoading: false,
        calculationStatus: CalculationHistoryStatus.None,
        calculationHistoryId: undefined,
        version: 1,
    }
}

export const createManagerSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], ManagerSlice> = (set, get, store) => ({
    ...getInitialState(),
    setFullScreen: (isFullScreen) => set((state) => setFullScreen(state, isFullScreen), false, `Set full screen ${isFullScreen}`),
    setWorktaskId: (worktaskId) => set((state) => setWorktaskId(state, worktaskId), false, "Set worktask id"),
    setCalculationStatus: (calculationStatus) => set(() => setCalculationStatus(calculationStatus), false, "Set calculation status"),
    setCalculationHistoryId: (calculationId) => set(setCalculationHistoryId(calculationId), false, "Set calculation history id"),
    setHistoryLoading: (loading) => set(setHistoryLoading(loading), false, "Calculation History Loading"),
    reInitializeStore: () => reInitializeStore(get()),
    resetStore: () => {
        createMaintenancePlanSlice(set, get, store).maintenancePlanActions.reset()
        createStepNavigationSlice(set, get, store).stepNavigationActions.reset()
        createEndPageSlice(set, get, store).reset()
        createInspectionWorkSlice(set, get, store).reset()
        createNavigationSlice(set, get, store).reset()
        createTestDriveSlice(set, get, store).testDriveActions.reset()
        createTesterSlice(set, get, store).testerActions.reset()
        createTechnicianSlice(set, get, store).technicianActions.reset()
        createWorksSlice(set, get, store).worksActions.reset()
        createMaintenanceReviewSlice(set, get, store).maintenanceReviewActions.reset()
        createTyresWheelsSlice(set, get, store).tyresWheelsActions.reset()
        set(getInitialState(), false, "Reset manager slice")
    },
})

function setHistoryLoading(loading: boolean): Partial<ManagerSlice> {
    return { historyLoading: loading }
}

function setCalculationHistoryId(calculationId: string): Partial<ManagerSlice> {
    return { calculationHistoryId: calculationId }
}

function setCalculationStatus(calculationStatus: CalculationHistoryStatus): Partial<ManagerSlice> {
    return { calculationStatus }
}

function reInitializeStore(get: FastServiceStore) {
    const {
        resetStore,
        maintenancePlan: { stateVehicle, modelDetails },
    } = get
    useFastServiceStore.persist.clearStorage()
    resetStore()

    if (stateVehicle && modelDetails) {
        maintenancePlanActions.setVehicle(stateVehicle, modelDetails)
    }
}

function setFullScreen(state: ManagerSlice, isFullScreen: boolean): Partial<ManagerSlice> {
    return {
        isFullScreen,
    }
}

function setWorktaskId(state: ManagerSlice, worktaskId: string): Partial<ManagerSlice> {
    return {
        worktaskId,
    }
}
