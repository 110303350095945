import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { WorkItem } from "@tm/models"

type Props = {
    workItem: WorkItem
}

export default function HourlyRate({ workItem }: Props) {
    const { translate, currency } = useLocalization()

    return (
        <Stack justifyContent="flex-end">
            {workItem.fixedPriceValue ? (
                <>
                    <Typography variant="label">{translate(246)}</Typography>
                    {currency(workItem.fixedPriceValue, workItem.currencySymbol || workItem.currencyCode)}{" "}
                </>
            ) : (
                currency(workItem.hourlyRate ? workItem.hourlyRate : 0, workItem.currencySymbol || workItem.currencyCode)
            )}
        </Stack>
    )
}
