import { WorkTaskInfo } from "@tm/context-distribution"
import { useCallback } from "react"
import { AddCustomWorkListRequest } from "@tm/models"
import { mapCustomRepairTimeToCustomWork } from "../../../../../data/mapper"
import { CustomRepairTime } from "../../../../../data/model/custom-items"
import { useDeleteCustomRepairTimes } from "../../../../../data/hooks/custom-basket-items/useCustomRepairTimes"
import { CustomItemActions } from "../../CustomItemActions"

type Props = {
    workTask: WorkTaskInfo
    repairTime: CustomRepairTime
    repairTimeDivision: number
    isSelected: boolean
    onAddCustomWorks(request: AddCustomWorkListRequest): Promise<unknown>
    onEditClick(repairTime: CustomRepairTime | undefined): void
    onToggleSelection(repairTime: CustomRepairTime): void
}

export function CustomRepairTimesActions(props: Props) {
    const { workTask, repairTime, repairTimeDivision, onEditClick, isSelected, onToggleSelection, onAddCustomWorks } = props
    const { mutateAsync: deleteRepairTimes } = useDeleteCustomRepairTimes()

    const handleDelete = useCallback((item: CustomRepairTime) => deleteRepairTimes([item.id]), [deleteRepairTimes])

    const handleAddToBasket = useCallback(
        (item: CustomRepairTime) =>
            onAddCustomWorks({
                workTaskId: workTask.id,
                repairTimeDivision,
                customWorks: [mapCustomRepairTimeToCustomWork(item)],
                vehicleId: workTask.vehicle?.id,
                usePercentageValues: true,
            }),
        [workTask.id, workTask.vehicle?.id, repairTimeDivision, onAddCustomWorks]
    )

    return (
        <CustomItemActions
            item={repairTime}
            onDelete={handleDelete}
            onEditClick={onEditClick}
            onAddToBasket={handleAddToBasket}
            isSelected={isSelected}
            onToggleSelection={onToggleSelection}
        />
    )
}
