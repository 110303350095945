import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Checkbox, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ClickableText, ListItemMaxWidth } from "./FiltersStyledComponents"
import { ListFilter } from "../../models"

export function FilterItem({
    filter,
    hideAbbreviation,
    checked,
    onChange,
}: {
    filter: ListFilter
    hideAbbreviation?: boolean
    checked: boolean
    onChange: (filter: ListFilter, exclusive?: boolean) => void
}) {
    const { translateText } = useLocalization()
    const [compChecked, setCompChecked] = useState(checked)

    const handleChange = useCallback(() => {
        setCompChecked(!compChecked)
        onChange(filter)
    }, [onChange, filter, compChecked])

    useEffect(() => {
        setCompChecked(checked)
    }, [checked])

    const handleChangeExclusive = useCallback(() => onChange(filter, true), [onChange, filter])

    const text = useMemo(() => {
        if (filter.abbreviation !== undefined) {
            let value = hideAbbreviation ? "" : filter.abbreviation
            if (filter.value) {
                if (value) {
                    value += ": "
                }
                value += filter.value
                if (filter.unit) {
                    value += ` ${filter.unit}`
                }
            }
            return value
        }
        if (filter.text !== undefined) {
            return filter.text
        }
        return filter.name
    }, [filter, hideAbbreviation])

    return (
        <ListItemMaxWidth>
            <Checkbox sx={{ p: "0 8px 0 4px" }} size="small" checked={compChecked} onChange={handleChange} disabled={filter.isSelectable === false} />
            <ClickableText
                onClick={handleChangeExclusive}
                title={`${text}\n(${translateText(936)})`}
                highPriority={filter.showOnTop && filter.isTopPriority}
            >
                {text}
                {filter.articleCount && ` (${filter.articleCount})`}
            </ClickableText>
        </ListItemMaxWidth>
    )
}
