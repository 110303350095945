import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Button, Scrollbar, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import NextStep from "../_shared/nextStep"
import { CustomModal, VehicleAndCustomer } from "../_shared"
import { getComponentStyles } from "../_shared/styles"
import { stepNavigationActions, technicianActions, useFastServiceStore } from "../../data"

const MaintenanceCompletition: FC = () => {
    const { translateText } = useLocalization()
    const [showModal, setShowModal] = useState(false)
    const firstName = useFastServiceStore((state) => state.technician.firstName)
    const lastName = useFastServiceStore((state) => state.technician.lastName)
    const technicianSignature = useFastServiceStore((state) => state.technician.signature)
    const comment = useFastServiceStore((state) => state.technician.comment)

    const classNames = getComponentStyles()
    const [disabledSignature, setDisabledSignature] = useState(true)
    const currentStepName = "maintenanceComplete"

    const authenthicationComplete = useMemo(() => !!firstName && !!lastName && !!technicianSignature, [firstName, lastName, technicianSignature])

    useEffect(() => {
        if (authenthicationComplete) {
            stepNavigationActions.updateNextStep(currentStepName)
            stepNavigationActions.completeStep(currentStepName)
        }
    }, [authenthicationComplete])

    const handleSignClick = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleFormat = useCallback((value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }, [])

    const handleLastNameValidation = (value: string) => {
        setDisabledSignature(!(firstName && value?.length > 2))
    }

    const saveTechnicianSignature = useCallback((signature: string) => {
        technicianActions.setSignature(signature)
        setShowModal(false)
    }, [])

    const saveFirstName = useCallback((newFirstName: string) => {
        technicianActions.setFirstName(newFirstName)
    }, [])

    const saveLastName = useCallback((newLastName: string) => {
        technicianActions.setLastName(newLastName)
    }, [])

    const saveComment = useCallback((newComment: string) => {
        technicianActions.setComment(newComment)
    }, [])

    return (
        <>
            <Scrollbar>
                <div className={classes(style.wrapper, classNames.wrapper, classNames.wrapperMinHeight)}>
                    <VehicleAndCustomer headerText={translateText(12537)} showServiceText />
                    <TextField size="xl" className={style.service} placeholder={translateText(1700)} value={comment} onChangeConfirm={saveComment} />
                    <Text className={style.technicianText} size="xl">
                        {translateText(12664)}
                    </Text>
                    <div className={classNames.flexContainerWithGap}>
                        <TextField
                            className={style.inputField}
                            size="xl"
                            value={firstName}
                            placeholder={translateText(119)}
                            onChangeConfirm={saveFirstName}
                            formatter={handleFormat}
                        />
                        <TextField
                            className={style.inputField}
                            size="xl"
                            value={lastName}
                            placeholder={translateText(104)}
                            onChangeConfirm={saveLastName}
                            formatter={handleFormat}
                            onChange={handleLastNameValidation}
                        />
                        <Button skin="highlight" className={classNames.signButton} onClick={handleSignClick} disabled={disabledSignature}>
                            {translateText(12967)}
                        </Button>
                    </div>
                    <div className={classNames.infoText}>
                        <Text size="m" modifiers="strong">
                            {translateText(12968)}
                        </Text>
                        <Text size="m" modifiers="sub">
                            {translateText(12969)}
                        </Text>
                    </div>
                </div>
                <NextStep currentStepName={currentStepName} buttonTextId={414} icon="arrows_down" active={authenthicationComplete} />
                <CustomModal
                    open={showModal}
                    headLineText={translateText(12970)}
                    headerText={`${firstName} ${lastName}`}
                    onClose={handleCloseModal}
                    onSave={saveTechnicianSignature}
                    savedSignature={technicianSignature}
                />
            </Scrollbar>
        </>
    )
}

export default MaintenanceCompletition

const style = useStyle({
    wrapper: {
        padding: "0 1em 1em",
    },
    service: {
        marginTop: "0.5em",
    },
    technicianText: {
        paddingTop: "4em",
        paddingBottom: "1em",
    },
    inputField: {
        flex: ".3",
    },
})(MaintenanceCompletition)
