import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Total from "./Total"
import { CostEstimationTotals } from "../../../data/model"
import { TotalsLoader } from "./TotalsLoader"

type Props = {
    totals?: CostEstimationTotals
    totalsLoading: boolean
    currencyCode: string
}

export function PartsTotals({ totals, totalsLoading, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()

    if (!totals) {
        return null
    }

    if (totalsLoading) {
        return <TotalsLoader />
    }

    return (
        <Stack py={1} pr={1} display="flex" justifyContent="flex-end" spacing={0.5} width="400px">
            <Total label={translateText(1720)} value={currency(totals?.partCosts || 0, currencyCode)} valueBold />
            {totals?.alreadyTaxedPartCosts && (
                <Total label={translateText(12850)} value={currency(totals.alreadyTaxedPartCosts, currencyCode)} valueBold />
            )}
        </Stack>
    )
}
