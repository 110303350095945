import { percent } from "csx"
import { ChangeEvent, useMemo, useState } from "react"
import { Box, List, ListItem, ListItemButton, ListItemIcon, TextField, styled } from "@tm/components"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { Checkbox, Icon, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { CollapsibleWrapper, PartItem } from ".."
import { useFastServiceStore } from "../../../data"
import { Genart } from "../../../data/models"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"

type Props = {
    items: Genart[]
    onSelect: (item: Genart) => void
    selectedGenArts?: Genart[]
    isDefect?: boolean
    onQuantityArticleChange: (articleId: string, quantity: number) => void
    onOeArticleChange: (fsArticleId: string, quantity: number, price: number, designation: string) => void
    showPartAlternatives: (genArt: Genart, article: FSArticle) => void
}

export default function GenArts({
    items,
    onSelect,
    selectedGenArts,
    isDefect,
    onQuantityArticleChange,
    onOeArticleChange,
    showPartAlternatives,
}: Props) {
    const style = useMemo(() => getStyle(), [])
    const loading = useFastServiceStore((state) => state.articlesLoading)
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const updateOeArticle = useFastServiceStore((state) => state.setUpdateOeArticle)
    const [quantity, setQuantity] = useState<number>(0)
    const [price, setPrice] = useState<string>("0")
    const [designation, setDesignation] = useState<string>("")
    const [checkOeArticleChange, setCheckOeArticleChange] = useState<boolean>(false)

    const handleSetDesignation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (designation !== event.target.value) {
            setDesignation(event.target.value)
            setCheckOeArticleChange(true)
        }
    }

    const handleSetPrice = (event: ChangeEvent<HTMLInputElement>) => {
        const inputPrice = event.target.value
        const regex = /^[0-9]*([.|,][0-9]{0,2})?$/

        if (regex.test(inputPrice) || inputPrice === "0" || inputPrice === "") {
            setPrice(inputPrice)
            setCheckOeArticleChange(true)
        }
    }

    const handleSetDefaultQuantity = (item: Genart) => {
        if (!item.fsArticle!.quantity || item.fsArticle!.quantity === 0) {
            setQuantity(1)
            return
        }
        setQuantity(item.fsArticle!.quantity)
    }

    const handleSetQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (quantity !== +event.target.value) {
            setQuantity(+event.target.value)
            setCheckOeArticleChange(true)
        }
    }

    const handleOeChange = (item: Genart, userQuantity: number, userPrice: number, userDesignation: string) => {
        onOeArticleChange(item.fsArticle!.id, userQuantity, userPrice, userDesignation)
        updateOeArticle(item.fsArticle!, userQuantity, userPrice, userDesignation)
    }

    const handleSelection = (item: Genart) => () => {
        onSelect(item)
    }

    const renderItems = (item: Genart, idx: number) => {
        if (item.article) {
            return (
                <PartItem
                    key={idx}
                    genArt={item}
                    fsArticle={item.fsArticle}
                    article={item.article}
                    onQuantityArticleChange={onQuantityArticleChange}
                    showPartAlternatives={showPartAlternatives}
                    disableCheckToggle={item.isMandatory}
                />
            )
        }

        if (!item.isSelected && !isDefect) {
            return null
        }

        if (item.isMandatory && item.fsArticle && item.fsArticle.oeId && !item.fsArticle.supplierId && !item.fsArticle.supplierArtNr) {
            return renderOeArticle(item, idx)
        }

        return (
            <ListItemComponent key={idx} disablePadding>
                <ListItemButton role={undefined} onClick={handleSelection(item)} dense disabled={item.isMandatory}>
                    <ListItemIcon>
                        <Checkbox
                            size="l"
                            checked={selectedGenArts?.some((x) => x.label === item.label && x.genArtNr === item.genArtNr) || false}
                            disabled={item.isMandatory}
                        />
                    </ListItemIcon>
                    <Text size="l" id={item.label}>
                        {loading ? <Loader /> : item.label}
                    </Text>
                </ListItemButton>
            </ListItemComponent>
        )
    }

    const renderOeArticle = (item: Genart, idx: number) => {
        if (designation === "") {
            if (item.fsArticle?.oeLabel) {
                setDesignation(item.fsArticle?.oeLabel)
            } else if (item.fsArticle?.supplierName) {
                setDesignation(`${item.fsArticle?.supplierName} ${item.fsArticle?.oeNrs[0]}`)
            }
        }

        if (price === "0" && item.fsArticle?.price) {
            setPrice(item.fsArticle?.price.toString())
        }

        if (quantity === 0) {
            handleSetDefaultQuantity(item)
        }

        if (checkOeArticleChange) {
            handleOeChange(item, quantity, +price.replace(/,/g, "."), designation)
            setCheckOeArticleChange(false)
        }

        return (
            <div style={{ boxShadow: "0 1px 5px rgb(0 0 0 / 20%)", width: "100%", borderRadius: "3px", paddingTop: "8px", paddingBottom: "8px" }}>
                <div
                    style={{
                        display: "flex",
                        marginLeft: ".5em",
                        alignItems: "center",
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            size="l"
                            onToggle={handleSelection(item)}
                            checked={selectedGenArts?.some((x) => x.label === item.label && x.genArtNr === item.genArtNr) || item.isMandatory}
                            disabled={item.isMandatory}
                        />
                    </ListItemIcon>
                    <Text size="l" id={item.label}>
                        {loading ? <Loader /> : item.label}
                    </Text>
                </div>
                <ListOeArticles>
                    <TextField label={translateText(13467)} onChange={handleSetDesignation} value={designation} size="medium" />
                    <TextField label={currencyCode} onChange={handleSetPrice} value={price} size="medium" />
                    <TextField label={translateText(89)} type="number" onChange={handleSetQuantity} size="medium" value={quantity} />
                </ListOeArticles>
            </div>
        )
    }

    return (
        <div>
            <IconComponent name="fast-calculator" size="l" className={style.icon} skin="primary" />
            <CollapsibleWrapper textId={1667}>
                <List>{items.map(renderItems)}</List>
            </CollapsibleWrapper>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        text: {
            marginBottom: theme.margin.xs,
            display: "flex",
        },
        multiselectWrapper: {
            width: percent(100),
            marginBottom: theme.margin.l,
        },
        icon: {
            opacity: 1,
            float: "left",
            marginRight: theme.margin.s,
            marginTop: theme.margin.s,
        },
        description: {
            flex: 1,
        },
    })(GenArts)
}

const ListItemComponent = styled(ListItem)(({ theme }) => ({
    border: "1px solid transparent",
    borderLeftWidth: "2px",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
    borderRadius: "3px",
    "&:hover": {
        borderColor: theme.palette.primary.main,
    },
    flexWrap: "wrap",
}))

const IconComponent = styled(Icon)(({ theme }) => ({
    opacity: 1,
    float: "left",
    marginRight: theme.margin?.s ?? "0.25em",
    marginTop: theme.margin?.s ?? "0.25em",
}))

const ListOeArticles = styled(Box)(() => ({
    display: "flex",
    padding: ".5em",
    borderRadius: "3px",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputBase-input": {
        paddingTop: "1em",
    },
    "& .MuiFormControl-root:first-child": {
        width: "20em",
    },
    flexBasis: "100%",
}))
