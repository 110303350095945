import { BundleActionType } from "../../../business"

export type ComponentActionType = BundleActionType

function saveCostEstimation(): ComponentActionType {
    return { type: "START_CREATE_COST_ESTIMATION", payload: {} }
}

export type IActions = typeof Actions

export const Actions = {
    saveCostEstimation,
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "START_CREATE_COST_ESTIMATION":
            return action
        default:
            break
    }
}
