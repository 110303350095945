import {
    CatalogPart,
    DATOePart,
    eOEArtType,
    ErpInformation,
    MicroBundles,
    OE,
    RepairTimeProvider,
    RequestArticleListPayload,
    UserContext,
    Vehicle,
} from "@tm/models"
import { clone } from "@tm/utils"
import { DATCatalogPart, ICalculationItem } from "../../../data/model"
import { GetDatCalculationDataRequest } from "../../../data/repositories/getCalculationData"
import { CostEstimationRequest, ICustomPartsItem } from "../../../data/repositories/importToCostEstimation"
import { mapCustomPart } from "../../../data/repositories/importToCostEstimation/mapper"
import { UpdateTotalPricesRequest } from "../../../data/repositories/updateTotalPrices"
import { mapBasicErpInfo, mapDatOePartToOePart, mapItemToCatalogPart, mapItemToDatCatalogPart } from "./mappers"
import { CalculationState } from "./model"

export const createDATGetCalculationDataRequest = (contractId: string): GetDatCalculationDataRequest => {
    return { contractId }
}

export const createDATCostEstimationRequest = (
    state: CalculationState,
    vehicle?: Vehicle,
    workTaskId?: string,
    memo?: string,
    division?: number
): CostEstimationRequest | undefined => {
    if (!workTaskId) {
        return
    }

    const selectedItems = state.items.filter((x) => x.isSelected)
    const works = state.works.filter((x) => x.isSelected)
    const { erpArticles } = state

    const parts: CatalogPart[] = []
    const oeArticles: DATOePart[] = []
    const customParts: ICustomPartsItem[] = []

    selectedItems.forEach((item) => {
        if (item.selectedPart) {
            const erpArticle = erpArticles.find((x) => x.dataSupplierArticleNumber === item.selectedPart!.supplierArticleNo)

            parts.push(mapItemToCatalogPart(item, erpArticle))
        } else if (!item.oeArticle.oeArticleNumber) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeArticles.push({ ...item.oeArticle, memo, oePriceValue: item.oeArticle.oePriceValue || undefined })
        }
    })

    return {
        workTaskId,
        customParts,
        vehicleId: vehicle?.id ?? "",
        oeParts: oeArticles,
        works,
        parts,
        repairTimesProvider: RepairTimeProvider.Dat,
        repairTimeDivision: division ?? 1,
    }
}

export const createUpdateTotalsRequest = (state: CalculationState, erpInfo?: ErpInformation[]): UpdateTotalPricesRequest => {
    const alternatives: DATCatalogPart[] = []
    const items = clone(state.items)

    items?.forEach((x) => {
        if (x.isSelected && !!x.selectedPart) {
            const alternative = mapItemToDatCatalogPart(x)
            alternatives.push(alternative)
        }
    })

    const smallPartsPercentage = items?.find((x) => x.oeArticle.type === eOEArtType.SmallPartsAndConsumables) && state.smallPartsPercentage
    const selectedItems = items?.filter((x) => x.isSelected && !x.selectedPart)

    return {
        oeArticles: selectedItems.map((x) => x.oeArticle),
        alternatives,
        works: state.works?.filter((x) => x.isSelected),
        basicErpInfo: erpInfo?.map(mapBasicErpInfo),
        discounts: state.discounts,
        smallPartsPercentage,
    }
}

const getReplacements = (replacements?: string[]) => {
    const result: OE.OeNumber[] = []

    if (replacements?.length) {
        replacements.forEach((x) => result.push({ number: x }))
    }

    return result
}
export const createRequestArticleListPayload = (item: ICalculationItem, userContext?: UserContext): RequestArticleListPayload => {
    return {
        oePositions: [
            {
                number: item.oeArticle.oeArticleNumber,
                description: item.oeArticle.description,
                additionalDescription: item.oeArticle.additionalDescription,
                isSelected: item.isSelected,
                replacements: getReplacements(item.oeArticle.replacements),
                ...(!!item.selectedPart && {
                    parts: [mapDatOePartToOePart(item.oeArticle, userContext)],
                }),
            },
        ],
        replaceButtonMicro: MicroBundles.DMGDat,
        forceReload: true,
        inModal: true,
    }
}
