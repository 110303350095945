import { TyreSearchSource, channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSearchTyre(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { searchTyre, sourceId } = data

    if (!searchTyre?.size) {
        return
    }

    let source: TyreSearchSource | undefined = "tyres"

    switch (sourceId) {
        case "TM_MVC_TECRMI_DIRECT_SEARCH":
            source = "tyresTecRmi"
            break
        default:
            break
    }

    channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { ...searchTyre, source })
}
