import { Box } from "@tm/components"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CategoryType } from "@tm/models"
import * as React from "react"
import { SearchType } from "../../../business"
import { useGetSearchteeVersion } from "../../../hooks/breadCrumbhandler/useGetSeachtreeVersion"
import { FiltersAnchor } from "./FilterAnchor"
import { SearchTreeAnchor } from "./SearchTreeAnchor"

type Props = {
    articleFiltersInLeft: boolean
    searchType?: SearchType
    categoryType?: CategoryType
    clippedFiltersClassName?: string
}

export const NoSearch: React.FC<Props> = (props) => {
    const { translate } = useLocalization()
    const className = useGetSearchteeVersion(props.searchType)
    const { articleFiltersInLeft, categoryType, searchType, clippedFiltersClassName } = props

    let text: React.ReactNode

    switch (categoryType) {
        case "vehicleParts":
        case "universalParts": {
            text = translate(1012)
            break
        }
        case "directSearch": {
            text = translate(1013)
            break
        }
        default:
    }

    return (
        <Box className={`${className} tk-parts`}>
            {articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
            <Box className="list">
                <Box className="article-list__panel article-list__status" mt={className === "searchtreeV2" ? "50px" : undefined}>
                    <Box className="article-list__no-search">
                        <Icon name="bulb" />
                        <Text size="l">{text}</Text>
                    </Box>
                </Box>
                <SearchTreeAnchor />
            </Box>
            {!articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
        </Box>
    )
}
