import { useCallback, useRef, useState } from "react"
import { Box, Icon, Stack, Button, Tooltip, LinkButton, Paper } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { AddCustomPartListRequest, ArticleInfoType, NoteTypes, VatRate } from "@tm/models"
import { useSaveNote } from "@tm/utils"
import { createCustomPartObject } from "../../../../data/mapper"
import { saveCustomArticle } from "../../../../data/repositories/custom-items/articles"
import { useBasketMemo } from "../../../../hooks/useBasketMemo"
import { ArticleNoteRefProps, ControllableArticleNote } from "../../../_shared/article-note"
import PartFields from "./part-fields"
import { CostEstimation } from "../../../../data/model"

type Props = {
    workTask: WorkTaskInfo
    costEstimation?: CostEstimation
    vehicleId?: string
    customerId?: string
    partsDirectListUrl: string
    partsVehicleListUrl: string
    currencyCode: string
    onAddCustomPart(request: AddCustomPartListRequest): void
}

export default function AddPartComponent(props: Props) {
    const { workTask, customerId, partsVehicleListUrl, partsDirectListUrl, vehicleId, currencyCode, costEstimation } = props
    const { translateText } = useLocalization()
    const { saveNote } = useSaveNote()
    const [articleNote, setArticleNote] = useState<string>()
    const articleNoteRef = useRef<ArticleNoteRefProps>(null)
    const basketMemo = useBasketMemo(workTask)

    const [customPartEditorOpen, setCustomPartEditorOpen] = useState<boolean>(false)
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)

    function toggleCustomPartEditorOpen() {
        setCustomPartEditorOpen((prev) => !prev)
        setOpenTooltip(false)
    }

    async function handleAddCustomPartToBasketClick(
        saveAsCustomArticle: boolean,
        articleNumber: string | undefined,
        garagePrice: number | undefined,
        description: string,
        quantityValue: number,
        rebate: number | undefined,
        surcharge: number | undefined,
        category?: number,
        vatRate?: VatRate
    ) {
        props.onAddCustomPart({
            workTaskId: workTask.id,
            customParts: [
                createCustomPartObject(articleNumber, garagePrice, description, quantityValue, rebate, surcharge, vatRate, basketMemo.position),
            ],
            vehicleId,
            customerId,
            usePercentageValues: true,
        })
        setCustomPartEditorOpen(false)

        if (saveAsCustomArticle) {
            const customArticleId = await saveCustomArticle({ articleNumber, description, garagePrice, category, vatRate })

            if (articleNote && customArticleId) {
                await saveNote({
                    noteId: customArticleId,
                    type: NoteTypes.CUSTOM_ARTICLE,
                    text: articleNote,
                })
            }
        }
    }

    const handleSaveAsCustomArticle = useCallback(() => {
        if (articleNoteRef.current) {
            articleNoteRef.current.show({
                onChange: (value) => {
                    setArticleNote(value)
                },
            })
        }
    }, [])

    if (customPartEditorOpen) {
        return (
            <Paper>
                <PartFields
                    defaultCurrencyCode={currencyCode}
                    surcharge={costEstimation?.customerDefaults?.partsSurcharge}
                    rebate={costEstimation?.customerDefaults?.partsRebate}
                    articleInfoType={ArticleInfoType.CustomArticle}
                    customPartVatRates={costEstimation?.defaultPartVatRates}
                    onClose={toggleCustomPartEditorOpen}
                    onAddCustomPart={handleAddCustomPartToBasketClick}
                    onSaveAsCustomArticle={handleSaveAsCustomArticle}
                />
                <Box py={1} px={1.5}>
                    <ControllableArticleNote ref={articleNoteRef} />
                </Box>
            </Paper>
        )
    }

    return (
        <Tooltip
            title={
                <Stack spacing={0.5}>
                    <Button startIcon={<Icon name="individual-article" />} variant="outlined" onClick={toggleCustomPartEditorOpen}>
                        {translateText(757)}
                    </Button>
                    <LinkButton startIcon={<Icon name="catalog" />} variant="outlined" to={vehicleId ? partsVehicleListUrl : partsDirectListUrl}>
                        {translateText(136)}
                    </LinkButton>
                </Stack>
            }
            variant="light"
            disableHoverListener
            open={openTooltip}
            onClickAway={() => setOpenTooltip(false)}
        >
            <Button
                // remove title from button if used inside from Tooltip to prevent double title use MUI errors
                // correct way is to use a Popper and a Tooltiop outside around the Button
                aria-label={translateText(1507)}
                onClick={() => setOpenTooltip(!openTooltip)}
                startIcon={<Icon name="plus" />}
                variant="contained"
                color="highlight"
            />
        </Tooltip>
    )
}
