import { ChangeEvent, useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { SwitchProps, Typography } from "@tm/components"
import { getUIA } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { CostEstimationActions } from "./CostEstimationActions"
import { ViewOption } from "./ViewOption"
import { CostEstimationAreas } from "./CostEstimationAreas"
import { CostEstimationOptions } from "./CostEstimationOptions"
import { BoxWithAlignContent } from "../../../StyledComponents"

type Props = {
    hasItems?: boolean
    customerEmail?: string
    partsDetailRoute: string
    useRepairTimeCalculation?: boolean
    creatingCostEstimation: boolean
    canBeCreated: boolean
    costEstimationUrl: string
    costEstimationIsActive: boolean
    detailsIsActive: boolean
    onToggleShowManufacturers(state: boolean): void
    onToggleShowArticleNumbers(state: boolean): void
    onToggleShowWholesalerArticleNumbers(state: boolean): void
    onTogglePrintVehicleImage(state: boolean): void
    onPrintCostEstimation(): void
    onSendCostEstimation(): void
    onGenerateCostEstimationLink(): void
    onChangeRepairTimeCalculation(useRepairTimeCalculation: boolean): void
}

export type SharingData = {
    loading: boolean
    link?: string
    pin?: string
    error?: string | true
}

export function CostEstimationNavigation(props: Props) {
    const {
        customerEmail,
        useRepairTimeCalculation,
        creatingCostEstimation,
        canBeCreated,
        costEstimationUrl,
        costEstimationIsActive,
        detailsIsActive,
        partsDetailRoute,
        hasItems,
        onToggleShowArticleNumbers,
        onToggleShowWholesalerArticleNumbers,
        onToggleShowManufacturers,
        onTogglePrintVehicleImage,
        onChangeRepairTimeCalculation,
        onGenerateCostEstimationLink,
        onPrintCostEstimation,
        onSendCostEstimation,
    } = props

    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const hasWheelsModule = userContext.modules?.some((x) => x.type === UserModuleType.TMWheels) ?? false

    const [showSupplierArticleNumbers, setShowSupplierArticleNumbers] = useState(true)
    const [showWholesalerArticleNumbers, setShowWholesalerArticleNumbers] = useState(true)
    const [showManufacturer, setShowManufacturer] = useState(true)
    const [printVehicleImage, setPrintVehicleImage] = useState(hasWheelsModule)

    const [sharingData, setSharingData] = useState<SharingData>({ loading: false })

    const handleToggleShowSupplierArticleNumbers = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setShowSupplierArticleNumbers(event.target.checked)
            onToggleShowArticleNumbers(event.target.checked)
            setSharingData({ loading: false })
        },
        [onToggleShowArticleNumbers]
    )
    const handleToggleShowWholesalerArticleNumbers = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setShowWholesalerArticleNumbers(event.target.checked)
            onToggleShowWholesalerArticleNumbers(event.target.checked)
            setSharingData({ loading: false })
        },
        [onToggleShowWholesalerArticleNumbers]
    )
    const handleToggleShowManufacturers = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setShowManufacturer(event.target.checked)
            onToggleShowManufacturers(event.target.checked)
            setSharingData({ loading: false })
        },
        [onToggleShowManufacturers]
    )
    const handleTogglePrintVehicleImage = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setPrintVehicleImage(event.target.checked)
            onTogglePrintVehicleImage(event.target.checked)
            setSharingData({ loading: false })
        },
        [onTogglePrintVehicleImage]
    )
    const handleChangeRepairTimeCalculation = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChangeRepairTimeCalculation(event.target.checked)
            setSharingData({ loading: false })
        },
        [onChangeRepairTimeCalculation]
    )

    function renderViewOptions(switchProps?: SwitchProps) {
        return (
            <>
                <ViewOption
                    {...switchProps}
                    label={translateText(87)}
                    checked={showSupplierArticleNumbers}
                    onChange={handleToggleShowSupplierArticleNumbers}
                    {...getUIA("CostestimationNavbarDisplayoptionsPartnumber")}
                />
                <ViewOption
                    {...switchProps}
                    label={translateText(91)}
                    checked={showWholesalerArticleNumbers}
                    onChange={handleToggleShowWholesalerArticleNumbers}
                    {...getUIA("CostestimationNavbarDisplayoptionsDealerPartNumber")}
                />
                <ViewOption
                    {...switchProps}
                    label={translateText(71)}
                    checked={showManufacturer}
                    onChange={handleToggleShowManufacturers}
                    {...getUIA("CostestimationNavbarDisplayoptionsManufacturer")}
                />
                {hasWheelsModule && (
                    <ViewOption
                        {...switchProps}
                        label={translateText(1632)}
                        checked={printVehicleImage}
                        onChange={handleTogglePrintVehicleImage}
                        {...getUIA("CostestimationNavbarDisplayoptionsPrintImage")}
                    />
                )}
                <ViewOption
                    {...switchProps}
                    label={translateText(3161)}
                    checked={useRepairTimeCalculation}
                    onChange={handleChangeRepairTimeCalculation}
                    {...getUIA("CostestimationNavbarDisplayoptionsCalculateLaborVal")}
                />
            </>
        )
    }

    return (
        <BoxWithAlignContent>
            <Typography variant="h1" mr={2}>
                {translateText(82)}
            </Typography>
            {partsDetailRoute && (
                <CostEstimationAreas
                    costEstimationUrl={costEstimationUrl}
                    costEstimationIsActive={costEstimationIsActive}
                    detailsIsActive={detailsIsActive}
                />
            )}
            <CostEstimationActions
                disabled={!canBeCreated || !hasItems || creatingCostEstimation}
                customerEmail={customerEmail}
                sharingData={sharingData}
                onChangeSharingData={setSharingData}
                onPrintButtonClick={onPrintCostEstimation}
                onSendCostEstimationButtonClick={onSendCostEstimation}
                onGenerateCostEstimationLinkButtonClick={onGenerateCostEstimationLink}
            >
                {renderViewOptions({ formControlLabelSx: (theme) => ({ display: "flex", paddingRight: theme.spacing(2) }) })}
            </CostEstimationActions>
            <CostEstimationOptions disabled={creatingCostEstimation}>{renderViewOptions()}</CostEstimationOptions>
        </BoxWithAlignContent>
    )
}
