import { useCallback } from "react"
import { WorkTaskInfo } from "@tm/context-distribution"
import { AddCustomPartListRequest, CustomArticle } from "@tm/models"
import { mapCustomArticleToCustomPart } from "../../../../../data/mapper"
import { useBasketMemo } from "../../../../../hooks/useBasketMemo"
import { useDeleteCustomArticles } from "../../../../../data/hooks/custom-basket-items/useCustomArticles"
import { CustomItemActions } from "../../CustomItemActions"

type Props = {
    workTask: WorkTaskInfo
    article: CustomArticle
    articleNote?: string
    quantity: number
    isSelected: boolean
    onAddCustomParts(request: AddCustomPartListRequest): Promise<unknown>
    onEditClick(article: CustomArticle | undefined): void
    onQuantityChange(article: CustomArticle, quantity: number): void
    onToggleSelection(article: CustomArticle): void
}

export function CustomArticlesActions(props: Props) {
    const { workTask, article, articleNote, onEditClick, quantity, onQuantityChange, isSelected, onToggleSelection, onAddCustomParts } = props
    const basketMemo = useBasketMemo(workTask)
    const { mutateAsync: deleteArticle } = useDeleteCustomArticles()

    const handleDelete = useCallback((item: CustomArticle) => deleteArticle([item.id]), [deleteArticle])

    const handleAddToBasket = useCallback(
        (item: CustomArticle, newQuantity: number) =>
            onAddCustomParts({
                workTaskId: workTask.id,
                customParts: [mapCustomArticleToCustomPart(item, newQuantity, basketMemo.position)],
                vehicleId: workTask.vehicle?.id,
                customerId: workTask.customer?.id,
                usePercentageValues: true,
            }),
        [workTask.id, workTask.vehicle?.id, workTask.customer?.id, basketMemo.position, onAddCustomParts]
    )

    return (
        <CustomItemActions
            item={article}
            itemNote={articleNote}
            onEditClick={onEditClick}
            onDelete={handleDelete}
            quantity={quantity}
            onQuantityChange={onQuantityChange}
            onAddToBasket={handleAddToBasket}
            isSelected={isSelected}
            onToggleSelection={onToggleSelection}
        />
    )
}
