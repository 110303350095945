import { Grid, GridProps } from "@tm/components"
import { PropsWithChildren } from "react"
import { ADDITIONAL_INFO_ICON_SIZE } from "."

type Props = GridProps

export function PriceGrid({ children, ...rest }: PropsWithChildren<Props>) {
    return (
        <Grid display="grid" gridTemplateColumns={`1fr auto ${ADDITIONAL_INFO_ICON_SIZE}px`} alignItems="center" columnGap="4px" {...rest}>
            {children}
        </Grid>
    )
}
