import { useRef, useCallback, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useExternalCatalogUrl, useSelection, mapVoucherTypeFromUrl } from "@tm/utils"
import { Dialog } from "@tm/controls"
import { VoucherType } from "@tm/models"
import { useCountryCodeToLicensePlate, useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { Button, Box, Loader, TableVariants, Typography, Icon } from "@tm/components"
import { useRecoilState } from "recoil"
import { useLocation, useParams } from "react-router"
import CostEstimationsSelection from "./lists/cost-estimations/CostEstimationsSelection"
import { useDeleteCostEstimation, useVoucherList } from "../../../data/hooks/useVoucherList"
import { NoResultHint } from "../../_shared/NoResultHint"
import { voucherListFilterAtom } from "../../../data/states/listFilters"
import { VoucherRouteParams } from "../../../business"
import { useShowOnlyUserVouchers } from "../../../data/hooks/useShowOnlyUserVouchers"
import { useOpenDetails } from "../../../hooks/useOpenDetails"
import { RowStack } from "../../_shared/StyledComponents"
import OrderList from "./lists/orders/OrdersList"
import CompactOrdersList from "./lists/orders/CompactOrdersList"
import CostEstimationsList from "./lists/cost-estimations/CostEstimationsList"
import CompactCostEstimationsList from "./lists/cost-estimations/CompactCostEstimationsList"
import ReturnsList from "./lists/returns/ReturnsList"
import CompactReturnsList from "./lists/returns/CompactReturnsList"

type Props = {
    externalSystemId: number | undefined
    isExpanded: boolean
}

const MAX_WIDTH = "100%"
const COMPACT_WIDTH = "440px"

export default function ExpandableVoucherList({ externalSystemId, isExpanded }: Props) {
    const { translateText } = useLocalization()
    const location = useLocation()
    const { workTask } = useWorkTask() ?? {}

    const { showOnlyUserVouchers, showOnlyUserVouchersLoaded } = useShowOnlyUserVouchers()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)
    const externalOrdersDialogRef = useRef<Dialog>(null)

    const params = useParams<VoucherRouteParams>()
    const { externalCatalogUrl, loadingExternalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })

    const voucherType = mapVoucherTypeFromUrl(params.type)
    const [listFilters] = useRecoilState(voucherListFilterAtom(params.workTaskId || ""))

    const { openList } = useOpenDetails()

    const queryParams = new URLSearchParams(location.search)
    const searchQuery = queryParams.get("searchQuery") || undefined

    const { costEstimations, costEstimationsLoading, orders, ordersLoading, returns, returnsLoading } = useVoucherList(
        voucherType,
        workTask?.customer?.id,
        listFilters.selectedVehicleId,
        undefined,
        undefined,
        showOnlyUserVouchersLoaded && enableServiceCalls,
        showOnlyUserVouchers,
        searchQuery
    )

    const { deleteCostEstimations } = useDeleteCostEstimation()

    const getAllCostEstimationIds = useCallback(() => {
        return costEstimations?.map((x) => x.id) ?? []
    }, [costEstimations])

    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllCostEstimationIds)

    useEffect(() => {
        unselectAll()
    }, [costEstimations, unselectAll])

    const listVariant: TableVariants = isExpanded ? "normal" : "small"

    function renderCostEstimation() {
        if (costEstimationsLoading) {
            return <Loader />
        }

        if (!costEstimations?.length) {
            return <NoResultHint />
        }

        if (listVariant === "small") {
            return <CompactCostEstimationsList costEstimations={costEstimations} shortCountryCode={plateCode} />
        }

        return (
            <CostEstimationsList
                costEstimations={costEstimations}
                selectedIds={selectedIds}
                shortCountryCode={plateCode}
                onSelectCostEstimation={toggleSelected}
                onDeleteCostEstimations={deleteCostEstimations}
            />
        )
    }

    function renderOrder() {
        if (externalSystemId) {
            return (
                <>
                    <Button onClick={() => externalOrdersDialogRef.current?.show()} disabled={loadingExternalCatalogUrl}>
                        {translateText(6)}
                    </Button>
                    <Dialog className="form-confirmation" ref={externalOrdersDialogRef} text={translateText(31)} iconName="voucher" layout="stretch">
                        <iframe className="PDF-IFrame" src={externalCatalogUrl} title="External vouchers" />
                    </Dialog>
                </>
            )
        }

        if (ordersLoading) {
            return <Loader />
        }

        if (!orders?.length) {
            return <NoResultHint />
        }

        if (listVariant === "small") {
            return <CompactOrdersList orders={orders} shortCountryCode={plateCode} />
        }
        return <OrderList orders={orders} shortCountryCode={plateCode} />
    }

    function renderReturns() {
        if (returnsLoading) {
            return <Loader />
        }

        if (!returns?.length) {
            return <NoResultHint />
        }

        if (listVariant === "small") {
            return <CompactReturnsList returns={returns} />
        }

        return <ReturnsList returns={returns} />
    }

    function renderList() {
        if (voucherType === VoucherType.CostEstimation) {
            return renderCostEstimation()
        }
        if (voucherType === VoucherType.Order) {
            return renderOrder()
        }
        if (voucherType === VoucherType.Return) {
            return renderReturns()
        }
    }

    return (
        <Box width={isExpanded ? MAX_WIDTH : COMPACT_WIDTH} height="100%" display="grid" gridTemplateRows="auto 1fr" p={2}>
            <RowStack justifyContent="space-between" alignItems="flex-start" spacing={0} marginBottom={2}>
                <Typography variant="h3">{translateText(13646)}</Typography>
                {!isExpanded && (
                    <Button size="small" color="highlight" onClick={() => openList(voucherType)} startIcon={<Icon name="arrow-right" />} />
                )}
            </RowStack>
            {voucherType === VoucherType.CostEstimation && workTask?.id && !!selectedIds.length && (
                <Box position="relative">
                    <CostEstimationsSelection
                        allSelected={selectedIds.length === costEstimations?.length}
                        selectedIds={selectedIds}
                        onSelectAll={selectAll}
                        onUnselectAll={unselectAll}
                        onDeleteCostEstimations={deleteCostEstimations}
                    />
                </Box>
            )}
            {renderList()}
        </Box>
    )
}
