import * as React from "react"
import { memo, useMemo, useEffect, useState, useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { equals, debounce } from "@tm/utils"
import { Tooltip, styled, tooltipClasses, Button, Icon, Box } from "@tm/components"

import CompilationItemWm from "./components/wm/compilation-item-wm"
import CreateCompilation from "./components/create-compilation"
import { useAddArticle, useCompilations, useCompilationsContains, useCreateCompilation, useDeleteArticles } from "../../data/hooks/useCompilations"
import { getBundleParams, isWM } from "../../utils"
import { CompilationItemDefault } from "./components/default/compilation-item"
import { sortByCreatedAtDesc } from "../../utils/time"
import { ArticleInterface } from "../../data/interfaces/compilation-article-interface"

export type AddingState = "ADDING" | "ERROR" | "ADDED"

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: 0,
        minWidth: "17.5em",
    },
}))

type Props = {
    articles: ArticleInterface[]
    disabled?: boolean
    highlightListsIfItContainsArticle?: boolean
    enableRemoveFromList?: boolean
    variant?: "small" | "normal"
}

export const AddToCompilation = memo<Props>(({ articles, disabled, highlightListsIfItContainsArticle, enableRemoveFromList, variant }) => {
    const { containedArticles, loading: containedArticlesLoading } = useCompilationsContains(articles)
    const { translateText } = useLocalization()
    const { compilations } = useCompilations()
    const { createCompilation } = useCreateCompilation()
    const { compilationsIcon, disableWidgetButtonActiveState } = getBundleParams()
    const { addArticle, isLoading: addArticleLoading } = useAddArticle()
    const { deleteArticles, isLoading: deleteArticleLoading } = useDeleteArticles()

    const [addingState, setAddingState] = useState<Record<string, AddingState>>({})

    const [tooltipOpen, setTooltipOpen] = React.useState(false)

    const activeCompilations = useMemo((): string[] => {
        return !containedArticlesLoading && highlightListsIfItContainsArticle ? containedArticles.map((article) => article.compilationId) : []
    }, [highlightListsIfItContainsArticle, containedArticles, containedArticlesLoading])

    // delete items from state if we handle it over our api. needed change for WM -> we should split WM and our implementation
    useEffect(() => {
        if (!highlightListsIfItContainsArticle) {
            return
        }

        const newState = { ...addingState }
        containedArticles.forEach((article) => {
            if (Object.keys(addingState).includes(article.compilationId)) {
                delete newState[article.compilationId]
            }
        })

        if (!equals(addingState, newState)) {
            setAddingState(newState)
        }
    }, [activeCompilations, addingState, highlightListsIfItContainsArticle])

    const includedInCompilationList = useMemo((): boolean => {
        return !!(compilations && compilations.find(({ id }) => addingState[id])) || containedArticles.length > 0
    }, [compilations, addingState, containedArticles, highlightListsIfItContainsArticle])

    const handleTooltipOpen = React.useCallback(() => {
        setTooltipOpen(true)
    }, [setTooltipOpen])

    const handleTooltipClose = React.useCallback(() => {
        setTooltipOpen(false)
    }, [setTooltipOpen])

    const handleCreateCompilation = (name: string) => {
        createCompilation({ name, description: "", isPublic: true }).then((compilationId) => {
            handleClickItem(compilationId)
        })
    }

    const handleClickItem = (compilationId: string, articleId?: string) => {
        if (addingState[compilationId] === "ADDED" && !enableRemoveFromList) {
            return
        }
        if (!articles || addArticleLoading || deleteArticleLoading || containedArticlesLoading) {
            return
        }

        if (includedInCompilationList && articleId && enableRemoveFromList) {
            setAddingState((prev) => ({ ...prev, [compilationId]: "ADDING" }))
            deleteArticles({ compilationId, articleIds: [articleId] }).then(() => {
                const newState = { ...addingState }
                delete newState[compilationId]
                setAddingState(newState)
            })
        } else {
            setAddingState((prev) => ({ ...prev, [compilationId]: "ADDING" }))
            addArticle({
                compilationId,
                articles,
            }).then(
                () => setAddingState((prev) => ({ ...prev, [compilationId]: "ADDED" })),
                () => setAddingState((prev) => ({ ...prev, [compilationId]: "ERROR" }))
            )
        }
    }

    const debouncedHandleClick = useCallback(debounce(handleClickItem, 100), [handleClickItem])

    const renderCompilationList = () => {
        if (articles && tooltipOpen && compilations) {
            return compilations.sort(sortByCreatedAtDesc).map((compilation) => {
                return isWM() ? (
                    <CompilationItemWm
                        key={compilation.id}
                        compilation={compilation}
                        state={addingState[compilation.id]}
                        onClick={debouncedHandleClick}
                    />
                ) : (
                    <CompilationItemDefault
                        key={compilation.id}
                        compilation={compilation}
                        state={addingState[compilation.id]}
                        articleId={containedArticles.find((list) => list.compilationId === compilation.id)?.id}
                        containedInList={
                            highlightListsIfItContainsArticle
                                ? containedArticles.findIndex((list) => list.compilationId === compilation.id) !== -1
                                : false
                        }
                        onClick={debouncedHandleClick}
                    />
                )
            })
        }

        return null
    }

    const isActive = includedInCompilationList && highlightListsIfItContainsArticle && !disableWidgetButtonActiveState
    const iconButton = useMemo((): string => {
        if (compilationsIcon === "wm") {
            return "wishlist"
        }

        return isActive ? "filled-star" : "vote-star"
    }, [compilationsIcon, isActive])

    const width = useMemo(() => {
        if (variant !== "small" && compilationsIcon === "wm") {
            return "6.25em"
        }
    }, [variant, compilationsIcon])

    return (
        <div className="bdl-compilations add-to-compilation">
            <StyledTooltip
                PopperProps={{
                    disablePortal: true,
                }}
                variant="light"
                color="primary"
                onClose={handleTooltipClose}
                open={tooltipOpen}
                onClickAway={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom-end"
                title={
                    <>
                        <div className="compilations__list">{renderCompilationList()}</div>
                        <CreateCompilation onSave={handleCreateCompilation} />
                    </>
                }
            >
                <Box width={width}>
                    <Button
                        disabled={disabled}
                        fullWidth
                        startIcon={<Icon name={iconButton} />}
                        title={translateText(1330)}
                        variant={compilationsIcon === "wm" ? undefined : "text"}
                        onClick={handleTooltipOpen}
                    />
                </Box>
            </StyledTooltip>
        </div>
    )
})
