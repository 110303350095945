import { IBundle } from "@tm/morpheus"

import Banner from "./components/banner"
import BrandInformation from "./components/brand-information"
import CrmButton from "./components/crm-button"
import DemoAlert from "./components/demo-module-alert"
import DemoAlertTruck from "./components/demo-module-alert-truck"
import DemoModuleWidget from "./components/demo-module-widget"
import ExternalCatalogs from "./components/external-catalogs"
import ExternalCatalogsInIframe from "./components/external-catalogs-iframe"
import ExternalWidgetsContainer from "./components/externalWidgetsContainer"
import FastCheck from "./components/fastcheck"
import FormConfirmation from "./components/form-confirmation"
import HeaderButton from "./components/header-button"
import Headline from "./components/headline"
import IFrameModule from "./components/iframe-module"
import IFrameModuleExternalId from "./components/iframe-module-extId"
import IFrameUniversalTagbased from "./components/iframe-universal-tagbased"
import IFrameWidget from "./components/iframe-widget"
import Icon from "./components/icon"
import ImageWidget from "./components/image-widget"
import LkqWebkat from "./components/lkq-webkat"
import ModuleGroup from "./components/module-group"
import ModuleNavigation from "./components/module-navigation"
import PageNavigation from "./components/page-navigation"
import ShortcutsKey from "./components/shortcuts-key"
import StartPageButton from "./components/startpage-button"
import TabComponent from "./components/tab-component"
import TabControl from "./components/tab-control"
import Text from "./components/text"
import ToolbarNavigationDropdown from "./components/toolbar-navigation-dropdown"
import WidgetConceptPage from "./components/widget-concept-page"
import WidgetCoverOnly from "./components/widget-cover-only"
import WidgetNavigationDropdown from "./components/widget-navigation-dropdown"
import WidgetOpen from "./components/widget-open"
import WorktaskTabIcon from "./components/worktask-tab-icon"
import WidgetButton from "./components/widget-button"

import ActivateModule from "./micros/activate-module"

import DevspireCmsLoader from "./plugins/DevspireCmsLoader"
import ModuleMountMessengerPlugin from "./plugins/module-mount-messenger"
import MountWorktaskMessenger from "./plugins/worktask-message-on-mount"
import ShouldComopnentRender from "./plugins/should-render-by-mdm-module"
import ShouldRenderByExtModule from "./plugins/should-render-by-ext-module"

import { initMiscBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Banner,
        BrandInformation,
        CrmButton,
        DemoAlert,
        DemoAlertTruck,
        DemoModuleWidget,
        ExternalCatalogs,
        ExternalCatalogsInIframe,
        ExternalWidgetsContainer,
        FastCheck,
        FormConfirmation,
        HeaderButton,
        Headline,
        IFrameModule,
        IFrameModuleExternalId,
        IFrameUniversalTagbased,
        IFrameWidget,
        Icon,
        ImageWidget,
        LkqWebkat,
        ModuleGroup,
        ModuleNavigation,
        PageNavigation,
        ShortcutsKey,
        StartPageButton,
        TabComponent,
        TabControl,
        Text,
        ToolbarNavigationDropdown,
        WidgetButton,
        WidgetConceptPage,
        WidgetCoverOnly,
        WidgetNavigationDropdown,
        WidgetOpen,
        WorktaskTabIcon,
    ],
    micros: [ActivateModule],
    plugins: [DevspireCmsLoader, ModuleMountMessengerPlugin, MountWorktaskMessenger, ShouldComopnentRender, ShouldRenderByExtModule],
    init: initMiscBundle,
}

export default bundle
