import { Article } from "@tm/models"
import { notUndefinedOrNull, uniqueId, useErpConfig } from "@tm/utils"
import { atom, useRecoilState } from "recoil"
import { useEffect, useMemo } from "react"
import { entries, uniqBy } from "lodash"
import { SupplierArticleWithId, loadMultiWholesalerArticleNosByTraderId } from "../../../data/repositories"

export type TradeReferenceNumber = {
    erpSystemId: number
    tradeReferenceId: number
    traderArticleNumber: string
}
export type ArticleTradeReferences = [SupplierArticleWithId, TradeReferenceNumber[] | undefined]

const tradeReferenceNumbersState = atom<ArticleTradeReferences[]>({
    key: "tradeReferenceNumbers",
    default: [],
})

export function useTradeReferences(articles: Article[], enabled: boolean) {
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const [tradeReferenceNumbers, setTradeReferenceNumbers] = useRecoilState(tradeReferenceNumbersState)
    const importantErpSystems = erpSystemConfigs?.slice(0, 2)

    const tradeReferenceIds = useMemo(() => {
        if (useOrderByDistributor && !!erpSystemConfigs) {
            // MRE is only active when useOrderByDistributor is true
            return erpSystemConfigs.map((x) => x.tradeReferenceId).filter(notUndefinedOrNull)
        }
    }, [erpSystemConfigs, useOrderByDistributor])

    useEffect(() => {
        if (!useOrderByDistributor) {
            return
        }
        setTradeReferenceNumbers((prev) => {
            const requests: SupplierArticleWithId[] = uniqBy(
                articles
                    .filter(
                        (article) =>
                            !tradeReferenceNumbers.some(
                                ([key]) => key.supplierArticleNo === article.supplierArticleNo && key.supplierId === article.supplier.id
                            )
                    )
                    .map((article) => ({
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo,
                        id: uniqueId(),
                    })),
                (x) => [x.supplierArticleNo, x.supplierId].join("|")
            )
            if (requests) {
                return [...prev, ...requests.map((request) => [request, undefined] as ArticleTradeReferences)]
            }
            return prev
        })
    }, [articles])

    useEffect(
        function loadData() {
            const fetchData = async (requests: SupplierArticleWithId[]) => {
                if (!tradeReferenceIds) {
                    return
                }
                const response = await loadMultiWholesalerArticleNosByTraderId(requests, tradeReferenceIds)
                setTradeReferenceNumbers((prev) => {
                    let loadedData = prev
                    entries(response.wholesalerArticleNosDict).forEach(([articleId, articleResponse]) => {
                        const loadedKey = loadedData.find(([key]) => key.id === articleId)?.[0]
                        if (loadedKey) {
                            const entryNumbers = entries(articleResponse)
                                .map(([tradeReferenceIdString, traderArticleNumber]) => {
                                    const tradeReferenceId = parseInt(tradeReferenceIdString) || 0
                                    const erpSystemId = erpSystemConfigs?.find((x) => x.tradeReferenceId === tradeReferenceId)?.id
                                    if (erpSystemId) {
                                        return {
                                            erpSystemId,
                                            tradeReferenceId,
                                            traderArticleNumber,
                                        }
                                    }
                                })
                                .filter(notUndefinedOrNull)

                            // add primary and or secondary tarderReference entries if they are not loaded
                            // Is it really necessary. We don't get any infos from these systems
                            if (importantErpSystems?.length) {
                                const supplierArticleWithId = prev.find(([key]) => key.id === articleId)?.[0]
                                const article = articles.find(
                                    (x) =>
                                        x.supplier.id === supplierArticleWithId?.supplierId &&
                                        x.supplierArticleNo === supplierArticleWithId.supplierArticleNo
                                )
                                if (article?.traderArticleNo) {
                                    importantErpSystems.forEach((system) => {
                                        if (system.tradeReferenceId && !entryNumbers.some((x) => x.erpSystemId === system.id)) {
                                            entryNumbers.push({
                                                erpSystemId: system.id,
                                                tradeReferenceId: system.tradeReferenceId,
                                                traderArticleNumber: article.traderArticleNo,
                                            })
                                        }
                                    })
                                }
                            }

                            loadedData = [[loadedKey, entryNumbers], ...loadedData.filter((x) => x[0] !== loadedKey)]
                        }
                    })

                    return loadedData
                })
            }
            const newRequests = tradeReferenceNumbers.filter(([_, numbers]) => !numbers).map(([key]) => key)
            if (enabled && newRequests.length) {
                fetchData(newRequests)
            }
        },
        [tradeReferenceNumbers, tradeReferenceIds, enabled, erpSystemConfigs]
    )

    return tradeReferenceNumbers
}
