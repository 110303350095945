import { Switch, Tooltip, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"

const ExtendedAssortmentSwitch = styled(Switch)({
    width: 55,
    marginLeft: 1,
    ".MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(18px)",
    },
    ".MuiSwitch-track:before": {
        left: 10,
    },
})

type Props = {
    state: boolean
    disabled: boolean
    onChange(enabled: boolean): void
}

export function ExtendedAssortmentFilter({ state, disabled, onChange }: Props) {
    const { translateText } = useLocalization()

    const content = (
        <ExtendedAssortmentSwitch
            label={translateText(227)}
            checked={state}
            disabled={disabled}
            onChange={(_, value) => onChange(value)}
            size="small"
        />
    )

    if (!disabled) {
        return content
    }

    return (
        <Tooltip
            title="Das erweiterte Sortiment ist aufgrund der gewählten Filter aktiviert." // TODO: clarify if text should be displayed and add translations
            enterDelay={1000}
        >
            <span>{content}</span>
        </Tooltip>
    )
}
