import { Box, NumberSelect, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article, ArticleIdentifier, channel, eOEArtType, IMicros, OE } from "@tm/models"
import Morpheus, { useActions, useMicro } from "@tm/morpheus"
import { clone, getCurrencyFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { DatSelectionSteps, getStatusByExternalTool } from "../../../../../data/enums"
import { bundleChannel } from "../../../../../data/helpers/bundleChannel"
import { ICalculationItem } from "../../../../../data/model"
import { calculationSelector, externSelector, managerSelector } from "../../../../../selectors"
import { MainActions } from "../../../../main/business"
import { createRequestArticleListPayload } from "../../../business/helper"
import { mapArticleToArticleIdentifier, mapDatOePartToOE } from "../../../business/mappers"
import { ReplaceButton } from "../../_shared"

type Props = {
    item: ICalculationItem
}

export default function ArticleItem({ item }: Props) {
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const user = useUser()
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const { vehicle, contractId } = useSelector(managerSelector)
    const { erpArticles } = useSelector(calculationSelector)
    const { page } = useSelector(externSelector)
    const { oeArticle, selectedPart, isSelected } = item
    const actions = useActions(
        MainActions,
        "updateTotals",
        "selectArticle",
        "replaceArticle",
        "changeOeArticleQuantity",
        "changeStep",
        "setErpArticles"
    )

    const handleDetailsClick = (article: Article) => {
        const params = {
            contractId,
            status: getStatusByExternalTool(page),
            productGroupId: article.productGroup.id?.toString(),
            supplierId: article.supplier.id?.toString(),
            supplierArticleNo: article.supplierArticleNo,
            partsDetailsSubPage: "overview",
        }

        actions.changeStep(DatSelectionSteps.DETAILS, params)
    }

    const onReplaceArticle = (part: Article | OE.OePart) => {
        actions.replaceArticle(item, part)
    }

    const onArticleSelect = (partId: string) => {
        actions.selectArticle(translateText, item)
    }

    const onQuantityChange = (quantity: number) => {
        actions.changeOeArticleQuantity(translateText, item, quantity)
    }

    const requestAlternatives = () => {
        bundleChannel().clear("REPLACE_PART")
        bundleChannel().clearSubscriptions("REPLACE_PART")

        bundleChannel().subscribeOnce("REPLACE_PART", (props) => {
            const { part } = props

            Morpheus.closeView("1")
            onReplaceArticle(part)

            let filteredErpArticles = clone(erpArticles)

            if ((part as OE.OePart).number) {
                if (selectedPart) {
                    filteredErpArticles = filteredErpArticles.filter((x) => x.dataSupplierArticleNumber !== selectedPart.supplierArticleNo).distinct()
                }

                actions.updateTotals(translateText, filteredErpArticles)
            } else {
                channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                    const newErpArticle = erpInfo.filter((x) => x.dataSupplierArticleNumber === (part as Article).supplierArticleNo)

                    if (isSelected) {
                        actions.updateTotals(translateText, [...erpArticles, ...newErpArticle].distinct())
                    } else {
                        actions.setErpArticles([...erpArticles, ...newErpArticle])
                    }
                })
            }
        })

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestArticleListPayload(item, user?.userContext))
    }

    const renderReplaceButton = () => {
        let content = null

        if (oeArticle.type === eOEArtType.MaterialPosition) {
            content = (
                <Box display="flex" gap="3%">
                    <NumberSelect minAmount={0} value={oeArticle.quantityValue} onValueChange={onQuantityChange} />
                    <ReplaceButton disabled={!item.hasAlternatives} onClick={requestAlternatives} />
                </Box>
            )
        } else {
            content = <StyledAdditionalCostActions />
        }

        return <Box margin={`.1725em ${!isWM ? "auto" : ".1725em"} .1725em 0`}>{content}</Box>
    }

    const renderOePart = () => {
        const oePart: OE.OePart = mapDatOePartToOE(oeArticle, getCurrencyFromUserContext(user?.userContext), vehicle)

        const enableSelector =
            oeArticle.type !== eOEArtType.SmallPartsAndConsumables ||
            (oeArticle.type === eOEArtType.SmallPartsAndConsumables && !!oeArticle.oePriceValue && oeArticle.oePriceValue > 0)

        return (
            <Box marginTop=".5em" sx={{ ".checkbox": { marginLeft: !isWM ? "1em" : "none" } }}>
                {renderMicro("parts", "oe-part-item", {
                    oePart,
                    renderBasketButton: renderReplaceButton,
                    hiddenFakeActions: {
                        costEstimation: true,
                        selector: enableSelector,
                        moreMenu: true,
                        details: true,
                    },
                    onArticleSelect: enableSelector ? onArticleSelect : undefined,
                    isSelected,
                })}
            </Box>
        )
    }

    const renderPart = (selectedArticlePart: Article) => {
        const partToLoad: ArticleIdentifier = mapArticleToArticleIdentifier(selectedArticlePart, vehicle?.tecDocTypeId)

        return (
            <Box marginTop=".5em">
                {renderMicro("parts", "part-item", {
                    partToLoad,
                    renderBuyActions: renderReplaceButton,
                    onRequestArticleDetails: () => handleDetailsClick(selectedArticlePart),
                    onArticleSelect,
                    isSelected,
                    showActions: true,
                    showArticleImage: true,
                    canFilterArticleAttributes: false,
                } as any)}
            </Box>
        )
    }

    if (selectedPart) {
        return renderPart(selectedPart)
    }

    return renderOePart()
}

const StyledAdditionalCostActions = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    width: "11.5em",
}))
