import { Typography, SelectChangeEvent, Select, MenuItem } from "@tm/components"
import { memo, useCallback } from "react"
import { useOeInformationItemState } from "./ContextProviderStates/OeInformationItemState"

export const OeInfoDescription = memo(() => {
    const handlePartChange = useOeInformationItemState((x) => x.handlePartChange)
    const description = useOeInformationItemState((x) => x.description)
    const parts = useOeInformationItemState((x) => x.parts)
    const partId = useOeInformationItemState((x) => x.article.id)

    const onPartChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const value = event.target.value as string
            handlePartChange?.(value)
        },
        [handlePartChange]
    )

    if (parts && parts.length > 1) {
        return (
            <Select onChange={onPartChange} size="medium" value={partId} variant="filled">
                {parts.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                        {p.description}
                    </MenuItem>
                ))}
            </Select>
        )
    }

    return <Typography>{description}</Typography>
})
