import { BundleComponent } from "@tm/morpheus"
import { reduce } from "../_shared/uni-search-widget-content/business"
import component from "./component"

const SidebarSearchWidget: BundleComponent = {
    name: "sidebar-search-widget",
    reduce,
    component,
}

export default SidebarSearchWidget
