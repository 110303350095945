import { Stack } from "@tm/components"
import { useMemo } from "react"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { AddToBasket } from "./AddToBasket"
import { CostEstimationButton } from "./CostEstimationButton"
import { PartsListButton } from "./PartsListButton"

export function Transactions() {
    const { hideCostEstimationButton, highlightCostEstimationButton } = useDefaultArticleItemState((x) => x.options)
    const showAddToBasket = useDefaultArticleItemState((x) => x.article.showAddToBasket)
    const isPartsListAvailable = useDefaultArticleItemState((x) => x.article.isPartsListAvailable)
    const originalQuantity = useDefaultArticleItemState((x) => x.originalQuantity)
    const hasSuggestedQuantity = useDefaultArticleItemState((x) => x.hasSuggestedQuantity)
    const handleChangeQuantity = useDefaultArticleItemState((x) => x.handleChangeQuantity)
    const handleAddToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)
    const handleRemoveFromBasket = useDefaultArticleItemState((x) => x.handleRemoveFromBasket)
    const basketQuantity = useDefaultArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleAddToCostEstimation = useDefaultArticleItemState((x) => x.handleAddToCostEstimation)
    const defaultArticleErpInfo = useDefaultArticleItemState((x) => x.articleErpInfos.default)

    const division = useMemo(
        () => (defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo.response.quantity?.division : 1) || 1,
        [defaultArticleErpInfo]
    )

    return (
        <Stack spacing={0.5} direction="row" justifyContent="end">
            {showAddToBasket ? (
                <AddToBasket
                    originalQuantity={originalQuantity}
                    division={division}
                    basketQuantity={basketQuantity}
                    onAddToBasket={handleAddToBasket}
                    handleChangeQuantity={handleChangeQuantity}
                    onRemoveFromBasket={handleRemoveFromBasket}
                    hasSuggestedQuantity={hasSuggestedQuantity}
                />
            ) : (
                isPartsListAvailable && <PartsListButton />
            )}
            {!hideCostEstimationButton && (
                <CostEstimationButton
                    onAddToCostEstimation={handleAddToCostEstimation}
                    highlightCostEstimationButton={highlightCostEstimationButton}
                    disabled={!showAddToBasket}
                />
            )}
        </Stack>
    )
}
