import { AddOePartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { reloadOeArticleQuantities } from "../../.."

export function useAddOePartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: addOePartList } = useMutation((addWOePartListRequest: AddOePartListRequest) => Data.addOePartList(addWOePartListRequest), {
        onSuccess: (response, request) => {
            if (response) {
                handleBasketUpdateWorkflow(request.workTaskId, response)
            }

            // Save work task in the DB
            Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

            // The Add to basket buttons require the quantites added in the basket for the badge
            request.oeParts.forEach((article) => {
                if (article.oeArticleNumber) {
                    reloadOeArticleQuantities(request.workTaskId, article)
                }
            })
        },
    })
    const addWOeParts = useCallback(
        (request: AddOePartListRequest) => {
            return addOePartList(request)
        },
        [addOePartList]
    )

    return addWOeParts
}
