import { BundleMicro } from "@tm/morpheus"
import { reduce } from "../../components/main/business"
import { withStoreProvider } from "../../data/helpers"
import component from "./replaceButtonMicro"

const ReplaceButtonMicro: BundleMicro = {
    name: "replace-button",
    component: withStoreProvider(component as any),
    reduce,
}

export default ReplaceButtonMicro
