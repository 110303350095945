import { ErpInformationRequest, ErpInformation, ErpInformationRequestItem, ErpInformationResponse, GetErpInfosRequest } from "@tm/models"
import { ajax, getStoredAuthorization, TmaHelper, notUndefinedOrNull, Overwrite, Required } from "@tm/utils"
import { getServiceUrl } from "../.."
import { mapErpInformation } from "../../mapper"
import { getValidCacheItems, storeCacheEntry } from "./cache"

type QueueRequest = {
    request: ErpInformationRequestItem
    resolve(response: ErpInformation): void
    reject(error: string): void
}

type QueueItem = {
    requests: QueueRequest[]
    bufferTimeout: number
}

const BUFFER_TIMESPAN_MS = 25 // defines the timespan (in ms) in which the requests will be buffered
const QUEUE: Map<string, QueueItem> = new Map()

/**
 * Get erp information with buffering so multiple nearly simultaneous requests would only result in one single service call
 */
export async function getErpInfo(request: Omit<ErpInformationRequest, "items"> & { item: ErpInformationRequestItem }): Promise<ErpInformation> {
    return new Promise<ErpInformation>((resolve, reject) => {
        const { item, distributorId = 0, telesalesCustomerNo, foundBySearchTerm, maxItemsPerRequest } = request

        const queueRequest: QueueRequest = {
            request: item,
            resolve,
            reject,
        }

        // MLE - 09.02.23 - NEXT-22945
        //
        // Theoretically "foundBySearchTerm" must be included in the key, because if two requests were made
        // (one with and one without "foundBySearchTerm" set) we should also make two service calls.
        //
        // But currently this would cause two ERP requests being made all the time (and also causing subsequent bugs like NEXT-23269).
        //
        // TODO: To fix this, we have to change all usages of "getErpInfo" to always supply the correct "foundBySearchTerm".
        // Idea: Maybe we can use React Context for this to provide the current search term and not have to pass it down a few layers.
        const key = [distributorId, telesalesCustomerNo /* , foundBySearchTerm */].join("_")
        let queueItem = QUEUE.get(key)

        if (!queueItem) {
            queueItem = { requests: [], bufferTimeout: -1 }
            QUEUE.set(key, queueItem)
        }

        queueItem.requests.push(queueRequest)

        window.clearTimeout(queueItem.bufferTimeout)

        queueItem.bufferTimeout = window.setTimeout(() => {
            if (!queueItem) {
                return
            }

            getErpInfoInternal(
                queueItem.requests.splice(0), // splice to remove request items from queue
                distributorId,
                telesalesCustomerNo,
                foundBySearchTerm,
                maxItemsPerRequest
            )
        }, BUFFER_TIMESPAN_MS)
    })
}

export async function getErpInfos(request: GetErpInfosRequest) {
    const { items, distributorId = 0, telesalesCustomerNo, foundBySearchTerm } = request
    const response = await ajax<ErpInformationResponse, ErpInformationRequest>({
        method: "POST",
        url: `${getServiceUrl()}/GetErpInfos`,
        authorization: getStoredAuthorization(),
        body: {
            items,
            distributorId,
            telesalesCustomerNo,
            foundBySearchTerm,
        },
    })

    const erpInfoItems = response?.items?.map(mapErpInformation).filter(notUndefinedOrNull)
    TmaHelper.Erp.AddErpInfoAndSend(distributorId, erpInfoItems)
    return { ...response, items: erpInfoItems, distributorId }
}

async function getErpInfoInternal(
    requests: QueueRequest[],
    distributorId: number,
    telesalesCustomerNo: string | undefined,
    foundBySearchTerm: string | undefined,
    maxItemsPerRequest: number | undefined
) {
    const { clientErpCachingTimespan: cachingParameter } = window.userContext?.parameter ?? {}
    const cachingDurationInSeconds = cachingParameter && cachingParameter > 0 ? cachingParameter : undefined

    let notCachedRequests = requests

    if (cachingDurationInSeconds) {
        notCachedRequests = handleCaching(distributorId, telesalesCustomerNo, requests)
    }

    const promises: Promise<unknown>[] = []

    if (notCachedRequests.length > 0) {
        const distinctItems: { request: ErpInformationRequestItem; queueRequests: QueueRequest[] }[] = []

        notCachedRequests.forEach((queueRequest) => {
            const match = distinctItems.find((x) => x.request.itemId === queueRequest.request.itemId)

            if (!match) {
                distinctItems.push({ request: queueRequest.request, queueRequests: [queueRequest] })
            } else {
                match.queueRequests.push(queueRequest)
            }
        })

        const maxArticlesPerRequest = getMaxArticlesPerRequest(distributorId) || maxItemsPerRequest

        if (maxArticlesPerRequest && distinctItems.length > maxArticlesPerRequest) {
            for (let i = 0; i < distinctItems.length; i += maxArticlesPerRequest) {
                promises.push(
                    callService(
                        { items: distinctItems.slice(i, i + maxArticlesPerRequest), distributorId, telesalesCustomerNo, foundBySearchTerm },
                        cachingDurationInSeconds
                    )
                )
            }
        } else {
            promises.push(callService({ items: distinctItems, distributorId, telesalesCustomerNo, foundBySearchTerm }, cachingDurationInSeconds))
        }
    }

    await Promise.allSettled(promises)

    TmaHelper.Erp.SendEvent(distributorId)
}

async function callService(
    {
        items: requestItems,
        distributorId,
        telesalesCustomerNo,
        foundBySearchTerm,
    }: Overwrite<
        Required<ErpInformationRequest, "distributorId">,
        {
            items: { request: ErpInformationRequestItem; queueRequests: QueueRequest[] }[]
        }
    >,
    cachingDurationInSeconds: number | undefined
) {
    try {
        const response = await ajax<ErpInformationResponse, ErpInformationRequest>({
            method: "POST",
            url: `${getServiceUrl()}/GetErpInfos`,
            authorization: getStoredAuthorization(),
            body: {
                items: requestItems.map((x) => x.request),
                distributorId: distributorId > 0 ? distributorId : undefined, // Only send valid values for distributorId
                telesalesCustomerNo,
                foundBySearchTerm,
            },
        })

        const erpInfo: ErpInformation[] = []

        if (!response || response.hasErrors) {
            requestItems.forEach((x) => x.queueRequests.forEach((y) => y.reject(response?.errorText ?? "")))
            return
        }

        const responseItems = response.items?.map(mapErpInformation).filter(notUndefinedOrNull) ?? []

        requestItems.forEach((item) => {
            const responseItem = responseItems.find((x) => x.itemId === item.request.itemId)

            if (responseItem) {
                erpInfo.push(responseItem)

                if (cachingDurationInSeconds) {
                    storeCacheEntry(distributorId, telesalesCustomerNo, item.request, responseItem, cachingDurationInSeconds)
                }
            }

            item.queueRequests.forEach((request) => {
                if (responseItem) {
                    request.resolve(responseItem)
                } else {
                    request.reject("No corresponding item in response")
                }
            })
        })

        TmaHelper.Erp.AddErpResponse(distributorId, erpInfo)
    } catch (error: any) {
        requestItems.forEach((x) => x.queueRequests.forEach((y) => y.reject(error)))
    }
}

function getMaxArticlesPerRequest(distributorId: number) {
    const { erpSystemConfigs } = window.erpConfig ?? {}
    const erpSystemConfig = distributorId ? erpSystemConfigs?.find((x) => x.id === distributorId) : erpSystemConfigs?.first()

    return erpSystemConfig?.erpRequestArticleCount
}

function handleCaching(distributorId: number, telesalesCustomerNo: string | undefined, requestItems: QueueRequest[]) {
    const cachedItems = getValidCacheItems(
        distributorId,
        telesalesCustomerNo,
        requestItems.map((x) => x.request)
    )

    const notCachedRequests: QueueRequest[] = []

    requestItems.forEach((item) => {
        const cacheEntry = cachedItems.find((x) => x.itemId === item.request.itemId)

        if (cacheEntry) {
            item.resolve(cacheEntry)
        } else {
            notCachedRequests.push(item)
        }
    })

    TmaHelper.Erp.AddErpResponse(distributorId, cachedItems)

    return notCachedRequests
}
