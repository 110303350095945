import { useState, useRef, useEffect } from "react"

import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { Button, LicensePlateField, SuggestionFieldButtonGroup, Table, Text, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CustomerShortInfo, FindCustomersResponse, RegistrationNoType } from "@tm/models"
import {
    CancelablePromise,
    RouteComponentProps,
    TmaHelper,
    createQueryString,
    encodeUniqueId,
    makeCancelable,
    renderRoute,
    uniqueId,
    useMultiWidgetState,
    withRouter,
} from "@tm/utils"
import { CustomerRepository } from "../../data"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    listRoute: string
    detailsRoute: string
    showComponentKey?: string
    className?: string
    storeId?: string
}

function WidgetComponent({ listRoute, detailsRoute, showComponentKey, className, match, history, storeId }: Props) {
    const { translate, translateText } = useLocalization()
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [suggestions, setSuggestions] = useState<Array<CustomerShortInfo>>([])
    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId })
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)
    const { params } = match
    const cancelPreviousSuggestion = useRef<CancelablePromise<FindCustomersResponse | undefined>>()

    useEffect(() => {
        return () => {
            cancelPreviousSuggestion.current?.cancel()
        }
    }, [])

    function getListUrl() {
        return renderRoute(listRoute, { ...params, workTaskId: params.workTaskId || encodeUniqueId(uniqueId()) })
    }

    function getDetailsUrl(id?: string) {
        return renderRoute(detailsRoute, {
            ...params,
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
            customerId: id ? encodeUniqueId(id) : undefined,
        })
    }

    function handleSearch(query?: string) {
        if (suggestions.length === 1) {
            return handleSelectCustomer(suggestions[0])
        }

        const customerSearchUrl = renderRoute(getListUrl(), match.params) + createQueryString({ query })
        history.push(customerSearchUrl)
    }

    function handleSearchButtonClick() {
        handleSearch(searchQuery)
    }

    function handleQueryOnChange(query: string) {
        setSearchQuery(query)
    }

    function handleRedirectToCustomerDetails(customer: CustomerShortInfo) {
        const detailsUrl = getDetailsUrl(customer.id)
        history.push(detailsUrl)
    }

    function handleSelectCustomer(customer: CustomerShortInfo) {
        // Remove this hack after we have an actual worktask manager :|
        // When pressing enter, the redirect to a new worktask used to only (semi-) work, because handleSearch was called too (because of changeConfirm)
        // Otherwise the worktask manager wouldn't be rendered on the page and no redirect would happen to the actually new worktask
        // I have changed the control, so that changeConfirm is only called, if there is more than one Suggestion.
        // Now a new worktask has to be opened manually
        const { vehicleCount } = customer

        if (vehicleCount && vehicleCount > 1) {
            return handleRedirectToCustomerDetails(customer)
        }

        if (!match.params.workTaskId) {
            history.push(`${encodeUniqueId(uniqueId())}`)

            setTimeout(() => {
                TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
                attachToWorkTask?.({ customer: customer.id, vehicle: customer.vehicleId })
            }, 500)
        } else {
            TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
            attachToWorkTask?.({ customer: customer.id, vehicle: customer.vehicleId })
        }
    }

    function loadSuggestions(query: string) {
        cancelPreviousSuggestion.current?.cancel()

        const searchOptions = {
            query: query.trim(),
            pageIndex: 1,
            pageSize: 10,
        }

        cancelPreviousSuggestion.current = makeCancelable(CustomerRepository.findCustomers(searchOptions))

        cancelPreviousSuggestion.current?.promise.then((result) => {
            if (result?.customers) {
                setSuggestions(result.customers)
            }
        })
    }

    function renderVehicleDescription(data: CustomerShortInfo) {
        return data.model && [data.manufacturer, data.modelSeries, data.model].join(" ")
    }

    function renderAutosuggestColumns() {
        return [
            <Table.Column key="customerNo" className="no" renderItemContent={(data) => <Table.Cell>{data.customerNo}</Table.Cell>}>
                {translate(156)}
            </Table.Column>,
            <Table.Column
                key="name"
                className="name"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {data.firstName || ""} {data.lastName || ""}
                    </Table.Cell>
                )}
            >
                {translate(155)}
            </Table.Column>,
            <Table.Column
                key="city"
                className="addr"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {data.zip || ""} {data.city || ""}
                    </Table.Cell>
                )}
            >
                {translate(112)} / {translate(113)}
            </Table.Column>,
            <Table.Column
                key="vehicle"
                className="vehicle"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {!!data.plateId && (
                            <div className="plate">
                                <LicensePlateField readonly size="xs" shortCountryCode={plateCode} value={data.plateId} />
                            </div>
                        )}
                        {renderVehicleDescription(data)}
                    </Table.Cell>
                )}
            >
                {translate(183)}
            </Table.Column>,
            <Table.Column
                key="action"
                className="action"
                renderItemContent={(data) => (
                    <Table.Cell>
                        <Button
                            icon="paperclip"
                            size="xs"
                            className="applyCustomerAndVehicle"
                            layout={["ghost"]}
                            onClick={(e) => {
                                e.stopPropagation()
                                return handleSelectCustomer(data)
                            }}
                        />
                    </Table.Cell>
                )}
            />,
        ]
    }

    function renderSearchContent() {
        return (
            <div className="search-container">
                <Text className="label">{translate(232)}</Text>
                <SuggestionFieldButtonGroup
                    value={searchQuery}
                    onChange={handleQueryOnChange}
                    suggestions={suggestions}
                    onChangeConfirm={handleSearch}
                    onSuggestionSelect={handleSelectCustomer}
                    size="l"
                    placeholder={translateText(184)}
                    tooltip={translateText(572)}
                    className="customer"
                    requestSuggestions={loadSuggestions}
                    renderTableColumns={renderAutosuggestColumns}
                    showClear
                    handleSearchButtonClick={handleSearchButtonClick}
                    minCharactersToSuggest={2}
                    suggestDelay={500}
                />
            </div>
        )
    }

    function renderFooter() {
        return (
            <div className="btn-container">
                <Button linkTo={getListUrl()} icon="users">
                    {translate(1108)}
                    <br />
                    <small>{translate(1109)}</small>
                </Button>
                <Button linkTo={getDetailsUrl()} icon="user-car">
                    {translate(1110)}
                    <br />
                    <small>{translate(1111)}</small>
                </Button>
            </div>
        )
    }

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    return (
        <Widget id="crm__widget-start" size="4x2" active className={`tk-crm widget-start ${className || ""}`}>
            {renderSearchContent()}
            <div className="line" />
            {renderFooter()}
        </Widget>
    )
}

export default withRouter(WidgetComponent)
