import { ActionDispatch } from "@tm/morpheus"
import { BundleActionType } from "../../../business"
import { SearchTreeState } from "./model"
import { ComponentActionType } from "./actions"

export * from "./actions"
export * from "./model"

const DEFAULT_STATE: SearchTreeState = {
    clipped: false,
    loading: false,
    groups: [],
    breadcrumbs: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): SearchTreeState {
    switch (action.type) {
        case "SET_CLIPPED": {
            return {
                ...state,
                clipped: action.payload,
            }
        }
        case "SET_CONFIG_PROPS": {
            const { treeType, showSecondLevel } = action.payload

            return {
                ...state,
                treeType,
                showSecondLevel,
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "SET_SEARCHTREE_DATA": {
            const { groups, breadcrumbs, selectedNode } = action.payload

            return {
                ...state,
                groups,
                breadcrumbs,
                selectedNode,
            }
        }
        case "SEARCH_TREE_LOADING": {
            return {
                ...state,
                loading: true,
            }
        }
        case "SEARCH_TREE_LOADED": {
            let groups = state.groups.slice(0)
            const { nodes, topProductGroups, parentNode } = action.payload

            if (parentNode) {
                // This also updates the groups array, since its the same object reference
                if (!parentNode.childNodes) {
                    parentNode.childNodes = nodes
                }
                if (!parentNode.topProductGroups) {
                    parentNode.topProductGroups = topProductGroups
                }
            }
            // Only do this for the first level (no parentNode)
            else {
                nodes.forEach((x) => (x.isGroup = true)) // Nodes from the first level are groups
                groups = nodes
            }

            return {
                ...state,
                loading: false,
                groups,
            }
        }
        case "CHANGE_BREADCRUMBS": {
            let breadcrumbs = action.payload
            let lastBreadcrumb = breadcrumbs.last()

            if (lastBreadcrumb) {
                // If the last breadcrumb is no group and has no child nodes
                // it should not appear as breadcrumb
                if (!lastBreadcrumb.isGroup && !lastBreadcrumb.hasChildNodes) {
                    breadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
                } else {
                    // Only keep last breadcrumb if it really is the last one from the tree
                    lastBreadcrumb = undefined
                }
            }

            return {
                ...state,
                breadcrumbs,
                selectedNode: lastBreadcrumb,
            }
        }
        case "RESET_SELECTION": {
            return {
                ...state,
                breadcrumbs: [],
                selectedNode: undefined,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: BundleActionType, dispatch: ActionDispatch<ComponentActionType, SearchTreeState>, getState: () => SearchTreeState) {
    switch (action.type) {
        case "SET_SEARCHTREE_DATA":
            if (getState().treeType == action.payload.treeType) {
                dispatch(action)
            }
            break
        default:
            break
    }
}
