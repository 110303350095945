import { useRef, useEffect, Fragment, Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, createQueryString, getRepairTimesProvider, encodeUniqueId, useActiveVehicleDataProviders } from "@tm/utils"
import Morpheus, { connectComponent, useMicro } from "@tm/morpheus"
import { Collapsible } from "@tm/controls"
import {
    FittingPosition,
    RegisteredModels,
    RepairTimeProvider,
    ChangeCostEstimationDetailsRequest,
    ShowCostEstimationDetailsResponse,
    RepairTimeProviderConfig,
    RepairTimesProvidersNames,
    IMicros,
    CisVoucherType,
} from "@tm/models"
import { useUser, useWorkTask, WorkTaskInfo, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Container } from "@tm/nexus"
import { Box, Typography, Paper, Loader, Stack } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { OfferState } from "./business"
import { IActions, Actions } from "./business/actions"
import PartsList from "./components/parts-list/index"
import WorksList from "./components/works-list/index"
import TotalNumbers from "./components/TotalNumbers"
import Note from "./components/note"
import Details from "./components/details"
import { bundleChannel } from "../../business"
import { useTransferCostEstimation } from "../../data/hooks/useTransferCostEstimation"
import TransferCompleted from "../_shared/transfer-completed"
import { ESendCostEstimationMode } from "../../data/model"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { BasketPart } from "../../models"
import { useCreateCostEstimationVoucher } from "../../data/hooks/useCreateCostEstimationVoucher"
import ErrorAlert from "../_shared/ErrorAlert"
import { AllTotals } from "./components/AllTotals"

type Props = {
    state: OfferState
    actions: IActions
    awProviders?: { [key: string]: RepairTimeProviderConfig }
    cisVoucherUrl?: string
    partsDirectListRoute: string
    partsVehicleListRoute: string
    partRepairTimesRoute: string
    showAllPrices?: boolean
    voucherTypeId?: CisVoucherType
}

function CostEstimationComponent(props: Props & { workTask: WorkTaskInfo }) {
    const {
        actions,
        state,
        partRepairTimesRoute,
        partsDirectListRoute,
        partsVehicleListRoute,
        showAllPrices,
        cisVoucherUrl,
        awProviders,
        workTask,
        voucherTypeId,
    } = props
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const history = useHistory()
    const matchParams = useParams<{ workTaskId?: string }>()
    const { userContext, userSettings } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { response, resetState } = useTransferCostEstimation(workTask.id, telesalesCustomerNo, ESendCostEstimationMode.costEstitmation)
    const savingDetails = useRef<boolean>(false)
    const { activeProviders } = useActiveVehicleDataProviders(workTask.id)

    const {
        costEstimation: costEstimationState,
        basket: basketState,
        erp,
        useRepairTimeCalculation,
        workEstimationLoading,
        workTaskBasketCalculationLoading,
        workTaskBasketLoading,
        workTaskBasketCalculation,
        workTaskBasketWithError,
        workTaskBasketCalculationWithError,
    } = useWorkTaskBasketState(workTask.id, awProviders)
    const { createCostEstimationVoucher, creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask, state)

    const {
        costEstimationParts,
        works,
        costEstimation,
        hasRepairTimesWithError,
        currencyCode,
        currencySymbol,
        repairTimeDivision,
        showRepairTimesInHours,
    } = costEstimationState.state
    const {
        addCustomWorks,
        changePartQuantity,
        editPart,
        editWork,
        removeParts,
        removeWorks,
        replaceWorkWithCustomWork,
        resetRepairTimes,
        selectAllCostEstimationParts,
        selectAllWorks,
        toggleCostEstimationPartSelect,
        toggleIncludeCostEstimationParts,
        toggleIncludeWorks,
        togglePartEditorOpen,
        toggleWorkEditorOpen,
        toggleWorkExpand,
        toggleWorkSelect,
        unselectAllCostEstimationParts,
        unselectAllWorks,
    } = costEstimationState.actions

    const { toggleIncludeBasketParts, addCustomParts } = basketState.actions

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []

        actions.setWorkTask(workTask)

        // the worktask should exist in the DB
        if (workTask.no) {
            const nexusSub = Container.getInstance<ShowCostEstimationDetailsResponse>(RegisteredModels.Worktask_CostEstimationDetails).subscribe({
                workTaskId: workTask.id,
            })

            unsubscriptions.push(
                nexusSub.addListener("loaded", (response) => {
                    actions.setCostEstimationDetails(response)
                    savingDetails.current = false
                }),
                nexusSub.addListener("updated", () => {
                    nexusSub.load()
                    savingDetails.current = false
                })
            )

            nexusSub.load()
        }

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTask])

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            bundleChannel().subscribe("CREATE_COST_ESTIMATION", ({ postCreateAction }) => {
                createCostEstimationVoucher(postCreateAction)
            })
        )
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [createCostEstimationVoucher])

    // TODO Basket V2: can this be removed?
    // actions, createCostEstimationVoucher, state, workTask

    // useEffect(() => {
    //     if (costEstimation) {
    //         actions.setCostEstimation(workTask, telesalesCustomerNo, costEstimation, awProviders)
    //     }
    // }, [workTask, telesalesCustomerNo])

    function saveCostEstimationDetails(workTaskId: string, request: ChangeCostEstimationDetailsRequest) {
        const costEstimationDetialsSubscription = Container.getInstance<ShowCostEstimationDetailsResponse>(
            RegisteredModels.Worktask_CostEstimationDetails
        ).subscribe({ workTaskId })
        if (costEstimationDetialsSubscription.save && !savingDetails.current) {
            savingDetails.current = true
            costEstimationDetialsSubscription.save(request)
        }
    }

    function updateClientAdvisor(clientAdvisor: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || clientAdvisor === offerDetails.clientAdvisor) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function handleModifyCostEstimationNote(memo: string) {
        actions.setCostEstimationNote(memo)
    }

    function handleUpdateCostEstimationNote(memo: string) {
        const { workTask, offerDetails } = state
        if (!workTask || !offerDetails.version) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateDueDate(dueDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || dueDate === offerDetails.dueDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateEndDate(endDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || endDate === offerDetails.endDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateMechanic(mechanic: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || mechanic === offerDetails.mechanic) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateReferenceNumber(referenceNumber: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || referenceNumber === offerDetails.referenceNumber) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateStartDate(startDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || startDate === offerDetails.startDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function openCisVoucherUrl(voucherTypeId: CisVoucherType, id: string, subId?: string) {
        if (cisVoucherUrl) {
            const openOrdersUrlParams = {
                workTaskId: matchParams.workTaskId,
                voucherTypeId,
                id,
                subId,
            }
            let url = renderRoute(cisVoucherUrl, openOrdersUrlParams)
            url += createQueryString({ singleSearch: true })
            history.push(url)
        }
    }

    function getArticleRelatedRepairTimesProviderName(repairTimeAvailabilities: Array<RepairTimeProvider>): RepairTimesProvidersNames | undefined {
        let provider = activeProviders?.repairTimes
        if (!provider && userSettings) {
            provider = userSettings.activeVehicleDataProviders.repairTimes
        }

        if (awProviders) {
            const providers = Object.keys(awProviders)
                .map((key) => ({ ...awProviders[key] }))
                .filter((x) => !!x.id)
            if (repairTimeAvailabilities) {
                provider = getRepairTimesProvider(repairTimeAvailabilities, providers, provider)
            }
        }

        return provider
    }

    function getRepairTimesUrlPerPart(
        productGroupId: number,
        supplierId: number,
        supplierArticleNo: string,
        fittingPosition: FittingPosition,
        repairTimeAvailabilities?: Array<RepairTimeProvider>
    ): string {
        if (repairTimeAvailabilities) {
            return decodeURIComponent(
                renderRoute(partRepairTimesRoute, {
                    ...matchParams,
                    provider: getArticleRelatedRepairTimesProviderName(repairTimeAvailabilities),
                    productGroupId,
                    supplierId,
                    supplierArticleNo: supplierArticleNo.replace(/\//g, "%252F"),
                    position: fittingPosition,
                })
            )
        }
        return ""
    }

    function handleOpenRepairTimes(part: BasketPart) {
        const { productGroupId } = part.partItem.articleInformation
        const { supplierId } = part.partItem.articleInformation
        const { articleNumber } = part.partItem.articleInformation
        if (!productGroupId || !supplierId || !articleNumber) {
            return
        }
        const container = Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
        const subscribe = container.subscribe("COST_ESTIMATION_VISIBLE")
        subscribe.save && subscribe.save({ key: `${encodeUniqueId(workTask.id)}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        Morpheus.showView(
            "1",
            getRepairTimesUrlPerPart(
                productGroupId,
                supplierId,
                articleNumber,
                part.partItem.fittingSide ?? FittingPosition.None,
                part.repairTimeProviders
            ) as string
        )
    }

    function openArticleDirectSearchUrl(query: string) {
        if (partsDirectListRoute) {
            const url = `${renderRoute(partsDirectListRoute, { ...matchParams })}/direct${createQueryString({ query })}`
            history.push(url)
        }
    }

    function renderTotalNumbers() {
        return (
            <Fragment key="vehicleTotals">
                <TotalNumbers
                    calculationLoading={workTaskBasketCalculationLoading}
                    costEstimationTotals={workTaskBasketCalculation?.calculatedCostEstimation?.totals}
                />
            </Fragment>
        )
    }

    function renderVehicleImage() {
        if (!costEstimation?.vehicleImage) {
            return
        }

        return (
            <Collapsible name={translateText(1633)} initiallyOpened skin="dark">
                <Box display="flex" justifyContent="center">
                    <img src={`data:image;base64,${costEstimation.vehicleImage}`} />
                </Box>
            </Collapsible>
        )
    }

    function renderParts() {
        return (
            <PartsList
                cisVoucherUrl={cisVoucherUrl}
                costEstimationParts={costEstimationParts}
                creatingCostEstimation={creatingCostEstimationVoucher}
                costEstimation={costEstimation}
                basketErpIndicator={erp.basketErpInfosIndicator}
                partsDirectListRoute={partsDirectListRoute}
                partsVehicleListRoute={partsVehicleListRoute}
                showAllPrices={!!showAllPrices}
                showManufacturer={state.showManufacturer}
                showPurchasePrice={userSettings?.showPurchasePrice}
                showSupplierArticleNumbers={state.showSupplierArticleNumbers}
                showWholesalerArticleNumbers={state.showWholesalerArticleNumbers}
                voucherTypeId={voucherTypeId}
                workTask={workTask}
                totals={workTaskBasketCalculation?.calculatedCostEstimation?.totals}
                workTaskBasketCalculationLoading={workTaskBasketCalculationLoading}
                workTaskBasketLoading={workTaskBasketLoading}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                onAddCustomPart={addCustomParts}
                onRemoveParts={removeParts}
                onChangeQuantity={changePartQuantity}
                onToggleIncludeParts={toggleIncludeCostEstimationParts}
                onToggleIncludeBasketParts={toggleIncludeBasketParts}
                onEditPart={editPart}
                onOpenArticleSearch={(query: string) => openArticleDirectSearchUrl(query)}
                onOpenPartRepairTimes={handleOpenRepairTimes}
                onOpenCisVoucher={(voucherTypeId: CisVoucherType, id: string, subId: string) => {
                    openCisVoucherUrl(voucherTypeId, id, subId)
                }}
                toggleCostEstimationPartSelect={toggleCostEstimationPartSelect}
                togglePartEditorOpen={togglePartEditorOpen}
                onSelectAll={selectAllCostEstimationParts}
                onUnselectAll={unselectAllCostEstimationParts}
            />
        )
    }

    function renderWorks() {
        return (
            <WorksList
                activeProviders={activeProviders}
                awProviders={awProviders}
                works={works}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                repairTimeDivision={repairTimeDivision}
                creatingCostEstimation={creatingCostEstimationVoucher}
                costEstimation={costEstimation}
                hasRepairTimesWithError={hasRepairTimesWithError}
                showManufacturer={state.showManufacturer}
                showSupplierArticleNumbers={state.showSupplierArticleNumbers}
                useRepairTimeCalculation={useRepairTimeCalculation}
                userContext={userContext}
                userSettings={userSettings}
                workEstimationLoading={workEstimationLoading}
                workTask={workTask}
                showRepairTimesInHours={showRepairTimesInHours}
                costEstimationCalculation={workTaskBasketCalculation?.calculatedCostEstimation}
                workTaskBasketCalculationLoading={workTaskBasketCalculationLoading}
                workTaskBasketLoading={workTaskBasketLoading}
                vehicleId={workTask.vehicle?.id}
                onAddCustomWork={addCustomWorks}
                onEditWork={editWork}
                onIncludeExcludeWorks={toggleIncludeWorks}
                onRemoveWorks={removeWorks}
                onReplaceWorkWithCustomWork={replaceWorkWithCustomWork}
                onResetRepairTimes={resetRepairTimes}
                toggleWorkExpand={toggleWorkExpand}
                toggleWorkSelect={toggleWorkSelect}
                toggleWorkEditorOpen={toggleWorkEditorOpen}
                onSelectAll={selectAllWorks}
                onUnselectAll={unselectAllWorks}
            />
        )
    }

    function renderTransferCompleted() {
        if (response) {
            return (
                <TransferCompleted
                    workTaskId={workTask.id}
                    telesalesCustomerNo={telesalesCustomerNo}
                    mode={ESendCostEstimationMode.costEstitmation}
                    onClearCostEstimationSent={() => resetState()}
                />
            )
        }
    }

    function renderVehicleInformation() {
        return (
            <Paper>
                <Collapsible name={translateText(99)} initiallyOpened renderHeaderAppendix={renderTotalNumbers}>
                    <Box pb={1}>{renderMicro("crm", "vehicle-overview", {})}</Box>
                    {renderVehicleImage()}
                    {workTaskBasketCalculationWithError && <ErrorAlert />}
                    {renderParts()}
                    {renderWorks()}
                </Collapsible>
            </Paper>
        )
    }

    function renderAggregate() {
        return (
            <AllTotals
                currencyCode={currencyCode}
                totalsLoading={workTaskBasketCalculationLoading}
                totals={workTaskBasketCalculation?.calculatedCostEstimation?.totals}
            />
        )
    }

    function renderOfferDetails() {
        return (
            <Box display="flex" flexDirection="column">
                <Details
                    details={state.offerDetails}
                    hasWorktask={!!workTask.no}
                    disabled={workTask.no ? savingDetails.current : true}
                    onUpdateClientAdvisor={updateClientAdvisor}
                    onUpdateDueDate={updateDueDate}
                    onUpdateEndDate={updateEndDate}
                    onUpdateMechanic={updateMechanic}
                    onUpdateReferenceNumber={updateReferenceNumber}
                    onUpdateStartDate={updateStartDate}
                />
            </Box>
        )
    }

    function renderNote() {
        if (!workTask.no) {
            return null
        }
        return (
            <Box display="flex" flexDirection="column">
                <Typography variant="h2" pb="2">
                    {translateText(1700)}
                </Typography>
                <Note
                    disabled={workTask.no ? savingDetails.current : true}
                    note={state.note}
                    onUpdateNote={handleUpdateCostEstimationNote}
                    onModifyNote={handleModifyCostEstimationNote}
                />
            </Box>
        )
    }

    return (
        <Stack alignSelf="stretch" flex={1} spacing={2}>
            {renderTransferCompleted()}
            {workTaskBasketWithError ? (
                <ErrorAlert />
            ) : (
                <>
                    {renderVehicleInformation()}
                    {renderAggregate()}
                </>
            )}
            {renderOfferDetails()}
            {renderNote()}
        </Stack>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={<Loader />}>
            <CostEstimationComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
