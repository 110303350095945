import { AddCustomWorkListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { bundleChannel } from "../../../../business"

export function useAddCustomWorkList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: addCustomWorkList } = useMutation(
        (request: { addCustomWorkListRequest: AddCustomWorkListRequest; isExternalCall?: boolean }) =>
            Data.addCustomWorkList(request.addCustomWorkListRequest),
        {
            onSuccess: (response, request) => {
                if (response) {
                    handleBasketUpdateWorkflow(request.addCustomWorkListRequest.workTaskId, response)
                    if (request.isExternalCall) {
                        Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                        if (
                            !request.addCustomWorkListRequest.customWorks?.find(
                                (customWork) => customWork.time !== undefined && customWork.time !== 0
                            )
                        ) {
                            bundleChannel().publish("ENABLE_COST_ESTIMATION", {})
                        }
                    }
                }
            },
        }
    )

    const addCustomWorks = useCallback(
        (request: AddCustomWorkListRequest, isExternalCall?: boolean) => {
            return addCustomWorkList({ addCustomWorkListRequest: request, isExternalCall })
        },
        [addCustomWorkList]
    )

    return addCustomWorks
}
