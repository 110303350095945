import { UserSettings } from "@tm/context-distribution"
import { Customer } from "@tm/models"
import { Repositories, useFastServiceStore } from "../../../data"
import { PrintImage, PrintOptions, SelectedItems } from "../../../data/models"
import { PrintModel } from "../../../models"
import { createPrintRequest } from "./helper"
import { UploadRequestPartFile } from "../../../models/image"
import { getTransferInfo, waveUpload } from "../../../data/repositories/image-upload"

export function printMaintenance(
    translateText: (key: string | number) => string,
    printOptions: PrintOptions,
    userSettings?: UserSettings,
    customer?: Customer,
    printPdf?: () => void
) {
    useFastServiceStore.getState().setPrintLoading()
    const { selectedWorks, itemsForPrint } = useFastServiceStore.getState().maintenanceReview

    const items = itemsForPrint
    // create a new map to keep the right order
    // we can't use the selectedWorks because we need to set a higher es target (es6)
    // in this way can use for of for the await and we use the itemsForPrint collection
    // then we use the itemsForPrint to create a new map set in order to use it for Print
    const completeOrderedWorks: typeof selectedWorks = new Map([])

    const invokePrint = (request: PrintModel) => {
        Repositories.printMaintenance(request).then(
            (res) => {
                useFastServiceStore.getState().setPrintLoaded(res.pdfUrl)
                printPdf?.()
            },
            () => {
                useFastServiceStore.getState().setPrintFailed()
            }
        )
    }

    if (printOptions.images) {
        mapData(items).then((sectionItems) => {
            Array.from(selectedWorks, ([key, _]) => completeOrderedWorks.set(key, sectionItems[key]))
            const request = createPrintRequest(translateText, completeOrderedWorks, printOptions, userSettings, customer)
            invokePrint(request)
        })
    } else {
        Array.from(selectedWorks, ([key, _]) => completeOrderedWorks.set(key, items[key]))
        const request = createPrintRequest(translateText, completeOrderedWorks, printOptions, userSettings, customer)
        invokePrint(request)
    }
}

const mapData = async (data: Record<string, Record<string, SelectedItems>>) => {
    const totalItems: Record<string, Record<string, SelectedItems>> = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const totalItemsRaw of Object.entries(data)) {
        const [key, value] = totalItemsRaw
        // eslint-disable-next-line no-await-in-loop
        totalItems[key] = await mapWorks(Object.entries(value))
    }
    return totalItems
}

const mapWorks = async (rawWorks: [string, SelectedItems][]) => {
    const works: Record<string, SelectedItems> = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const work of rawWorks) {
        const [key, value] = work
        // eslint-disable-next-line no-await-in-loop
        works[key] = { ...value, selectedImages: value.selectedImages ? await getImages(value.selectedImages) : [] }
    }
    return works
}

const getImages = async (images: PrintImage[]) => {
    const transferData = await getTransferInfo(images?.length).catch(() => undefined)

    let requestPartFile: UploadRequestPartFile[] | undefined
    if (transferData) {
        requestPartFile = await waveUpload(images, transferData.id, transferData.parts[0].id, transferData.uploadKey)
            .then((res) => {
                return res?.parts[0].files
            })
            .catch(() => undefined)
    }

    return images.map((x, idx) => ({ ...x, imageUrl: requestPartFile?.[idx]?.id ?? "" }))
}
