import * as React from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Demo, Headline, Icon, Loader, SelectionList, SelectionListItem, Text } from "@tm/controls"
import { Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CategoryType, ModuleOptionType, SearchTreeProductGroup, TreeNode, VehicleType } from "@tm/models"
import { classes, equals, getUIA, TmaHelper, useGetActivatableModules, usePrevious } from "@tm/utils"
import { isClassicalSearchTree } from "../../../helper"
import { TreeMode } from "../../widget/business"
import Node from "./components/node"
import { getBundleParams } from "../../../utils"
import ClassicFastClickSearchTree from "./ClassicFastClick"

export type SearchTreeProps = {
    /* eslint-disable react/no-unused-prop-types */
    mode?: TreeMode

    treeId: number | undefined
    loading: boolean
    groups: Array<TreeNode>

    breadcrumbs: Array<TreeNode>
    selectedNode: TreeNode | undefined
    onChangeBreadcrumbs(breadcrumbs: Array<TreeNode>, thumbnailClicked?: boolean, selectedNode?: TreeNode): void
    onApplyProductGroups(productGroups: Array<SearchTreeProductGroup>): void

    className?: string
    showSecondLevel?: boolean
    treeVisible?: boolean

    onRequestCalculation?(productGroupss: Array<SearchTreeProductGroup>): void
    canRequestCalculation?: boolean
    treeContext?: CategoryType
    hideBestseller?: boolean
    clipped?: boolean
}

const SearchTree: React.FC<SearchTreeProps> = (props) => {
    const user = useUser()
    const localization = useLocalization()
    const workTask = useWorkTask()
    const { translateText, translate } = localization

    const treeRef = React.createRef<HTMLDivElement>()
    const prevSelectedNode = usePrevious(props.selectedNode)
    const prevBreadcrumbs = usePrevious(props.breadcrumbs)
    const [selectedProductGroups, setSelectedProductGroups] = React.useState<Array<SelectionListItem>>([])

    const activatableModulesLoadable = useGetActivatableModules(user?.userContext)

    const hideBestsellers = user?.userContext.parameter.hideBestsellers || props.hideBestseller

    React.useEffect(() => {
        if (!equals(getBreadcrumbs(prevBreadcrumbs), getBreadcrumbs()) || (props.selectedNode && !equals(prevSelectedNode, props.selectedNode))) {
            // Reset selected product groups if the breadcrumbs changed
            setSelectedProductGroups([])
        }
    }, [props.selectedNode, props.breadcrumbs])

    const isClassicalSearchTree1 = () => {
        const { treeId } = props
        return isClassicalSearchTree(treeId)
    }

    /** Only use "mode" through this function and not directly from props */
    const getMode = (nextProps?: SearchTreeProps) => {
        let { mode } = nextProps ?? props

        // Classical search tree (e.g. "NKW/Commercial") only supports "fast-click-2.0"
        if (isClassicalSearchTree1()) {
            mode = "fast-click-2.0"
        }

        return mode
    }

    /** Only use "breadcrumbs" through this function and not directly from props */
    const getBreadcrumbs = (prevBreadcrumbs?: Array<TreeNode>) => {
        const breadcrumbs = prevBreadcrumbs ?? props.breadcrumbs

        // If the mode is FastClick 1.0 don't supply breadcrums as no node should be selected
        if (getMode() === "fast-click") {
            return []
        }

        return breadcrumbs
    }

    const showFirstLevelJumpOff = () => {
        return !isClassicalSearchTree1()
    }
    /**
     * Adds the given node (and optional group (added before the node)) to the breadcrumbs
     */
    const handleSelectNode = (node: TreeNode, group?: TreeNode, thumbnailClicked?: boolean) => {
        const { onChangeBreadcrumbs } = props
        const mode = getMode()
        let breadcrumbs = getBreadcrumbs()
        TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected({ nodeId: node.id, mode })

        // If the mode is FastClick 1.0
        // only one node can be selected at a time
        if (mode === "fast-click") {
            breadcrumbs = [node]
        }
        // Only add the node if it is not already a breadcrumb
        else if (!breadcrumbs.some((x) => x.id === node.id)) {
            breadcrumbs = breadcrumbs
                // add selected node (with optional group)
                .concat(group ? [group, node] : node)
        }

        onChangeBreadcrumbs(breadcrumbs, thumbnailClicked, node)
    }

    const handleThumbnailClick = (node: TreeNode, group?: TreeNode) => {
        handleSelectNode(node, group, true)
    }

    /**
     * Replaces the last breadcrumb with the given one
     */
    const handleChangeBreadcrumb = (breadcrumb: TreeNode) => {
        const { onChangeBreadcrumbs } = props
        let breadcrumbs = getBreadcrumbs()

        breadcrumbs = breadcrumbs
            // remove the last breadcrumb
            .slice(0, breadcrumbs.length - 1)
            // add the selected breadcrumb
            .concat(breadcrumb)

        const mode = getMode()
        TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected({ nodeId: breadcrumb.id, mode })

        onChangeBreadcrumbs(breadcrumbs)
    }

    /**
     * Removes the given and all following nested breadcrumbs
     */
    const handleRemoveBreadcrumb = (breadcrumb: TreeNode) => {
        const { onChangeBreadcrumbs } = props
        let breadcrumbs = getBreadcrumbs()

        breadcrumbs = breadcrumbs
            // remove all breadcrumbs after and including the selected one
            .slice(
                0,
                breadcrumbs.findIndex((x) => x.id === breadcrumb.id)
            )

        onChangeBreadcrumbs(breadcrumbs)
    }

    /**
     * Removes all following nested breadcrumbs after the given
     */
    const handleRemoveChildBreadcrumbs = (breadcrumb: TreeNode) => {
        const { onChangeBreadcrumbs } = props
        let breadcrumbs = getBreadcrumbs()

        breadcrumbs = breadcrumbs
            // remove all breadcrumbs after selected one
            .slice(0, breadcrumbs.findIndex((x) => x.id === breadcrumb.id) + 1)

        onChangeBreadcrumbs(breadcrumbs)
    }

    const handleSelectProductGroup = (items: Array<SelectionListItem>) => {
        setSelectedProductGroups(items)
    }

    const handleApplyProductGroups = () => {
        const { onApplyProductGroups } = props

        if (!selectedProductGroups.length) {
            return
        }

        onApplyProductGroups(selectedProductGroups.map(mapSelectionListItemToProductGroup))
    }

    const handleRequestCalculation = () => {
        const { onRequestCalculation, canRequestCalculation } = props

        if (!selectedProductGroups.length || !onRequestCalculation || !canRequestCalculation) {
            return
        }

        onRequestCalculation(selectedProductGroups.map(mapSelectionListItemToProductGroup))
    }

    const renderNode = (
        node: TreeNode,
        isSelected?: boolean,
        onSelect?: (node: TreeNode) => void,
        onDeselect?: (node: TreeNode) => void,
        onThumbnailClick?: (node: TreeNode) => void,
        indentLvl?: number,
        size: "s" | "m" | "l" | "xl" = "m",
        hideIcon?: boolean,
        hideChildIndicator?: boolean,
        withBottomBorder?: boolean
    ) => {
        return (
            <Node
                treeId={props.treeId}
                node={node}
                key={node.id}
                isSelected={isSelected}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onThumbnailClick={onThumbnailClick}
                indentLvl={indentLvl}
                size={size}
                thumbnailTitle={onThumbnailClick ? translateText(1003) : undefined}
                hideThumbnailOnly={isClassicalSearchTree1() || hideIcon}
                underline={withBottomBorder}
                hideChildIndicator={hideChildIndicator}
            />
        )
    }

    const renderGroupView = () => {
        const { loading, groups, showSecondLevel } = props
        const isVehicleParts = props.treeContext === "vehicleParts"
        return (
            <>
                {groups.map((group, idx) => (
                    <div className="tree__group" key={idx} {...getUIA("SearchtreeGroup", group.id)}>
                        {renderNode(
                            group,
                            undefined,
                            isVehicleParts ? undefined : handleSelectNode,
                            undefined,
                            isVehicleParts ? undefined : handleThumbnailClick,
                            undefined,
                            "xl",
                            isVehicleParts,
                            isVehicleParts
                        )}

                        {showSecondLevel && (
                            <div className="tree__group__nodes">
                                {(group.childNodes || []).map((node) =>
                                    renderNode(
                                        node,
                                        undefined,
                                        (node) => handleSelectNode(node, group),
                                        undefined,
                                        (node) => handleThumbnailClick(node, group),
                                        1
                                    )
                                )}
                            </div>
                        )}
                    </div>
                ))}
                <Loader visible={loading} />
            </>
        )
    }

    const renderBreadcrumbs = () => {
        const { groups } = props
        const breadcrumbs = getBreadcrumbs()
        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]
        const isVehicleParts = props.treeContext === "vehicleParts"

        let otherNodes: React.ReactNode

        if (breadcrumbs.length === 1) {
            // If only one breadcrumb (meaning only a group) is selected
            // show the other groups next to it
            // Filter out the active group
            const breadcrumbGroups = groups.filter((x) => x.id !== lastBreadcrumb.id)

            otherNodes = breadcrumbGroups.map((group) =>
                renderNode(
                    group,
                    lastBreadcrumb.id === group.id,
                    handleChangeBreadcrumb,
                    lastBreadcrumb.id === group.id ? handleRemoveBreadcrumb : undefined,
                    showFirstLevelJumpOff() ? handleThumbnailClick : undefined,
                    undefined,
                    "xl",
                    isVehicleParts
                )
            )
        } else {
            // If more than one breadcrumb (meaning also a normal node) is selected
            // show the other nodes from the same depth next to it

            // Get the parent of the last breadcrumb ...
            const activeParent = breadcrumbs[breadcrumbs.length - 2]

            if (activeParent.childNodes) {
                const childNodes = activeParent.childNodes.filter((x) => x.id !== lastBreadcrumb.id)

                // ... to render its children next to it ...
                otherNodes = childNodes.map((node) =>
                    renderNode(
                        node,
                        lastBreadcrumb.id === node.id,
                        handleChangeBreadcrumb,
                        lastBreadcrumb.id === node.id ? handleRemoveBreadcrumb : undefined,
                        handleThumbnailClick,
                        breadcrumbs.length === 1 ? undefined : breadcrumbs.length === 2 ? 1 : 2,
                        "l"
                    )
                )
            }
        }

        return (
            <div className="tree__breadcrumbs" {...getUIA("SearchtreeBreadcrumbs")}>
                {breadcrumbs.map((crumb, idx) => {
                    return renderNode(
                        crumb,
                        idx === breadcrumbs.length - 1,
                        idx > 0 ? handleRemoveChildBreadcrumbs : undefined,
                        handleRemoveBreadcrumb,
                        idx > 0 ? handleThumbnailClick : undefined,
                        idx === 0 ? undefined : idx === 1 ? 1 : 2,
                        idx === 0 ? "xl" : "l",
                        isVehicleParts ? idx === 0 : false,
                        true
                    )
                })}
                <div className="tree__breadcrumbs__other" {...getUIA("SearchtreeBreadcrumbsOther")}>
                    {otherNodes}
                </div>
            </div>
        )
    }

    const renderNodes = () => {
        const { loading, selectedNode } = props
        const breadcrumbs = getBreadcrumbs()
        const activeParent = breadcrumbs[breadcrumbs.length - 1]

        return (
            <div className="tree__nodes">
                {
                    // If the active parent has child nodes render them
                    activeParent.childNodes &&
                        !!activeParent.childNodes.length &&
                        activeParent.childNodes.map((x) =>
                            renderNode(
                                x,
                                selectedNode && selectedNode.id === x.id,
                                handleSelectNode,
                                undefined,
                                handleThumbnailClick,
                                undefined,
                                breadcrumbs.length >= 2 ? "m" : "l"
                            )
                        )
                }
                {
                    // If the active parent has no child nodes render a message
                    activeParent.childNodes && !activeParent.childNodes.length && <Text className="text--italic">{translate(1004)}</Text>
                }
                {/* If the active parent child nodes were not loaded yet and are loaded now render the loader */}
                <Loader visible={!activeParent.childNodes && loading} />
            </div>
        )
    }

    const renderTopProductGroups = () => {
        const { loading, selectedNode, onRequestCalculation } = props
        const breadcrumbs = getBreadcrumbs()
        const activeParent = selectedNode || breadcrumbs[breadcrumbs.length - 1]
        const isFCDemo = activatableModulesLoadable.response?.moduleGroups
            ?.find((x) => x.id === "fastCalculator")
            ?.modules?.find((x) => x.id === "topmotive")
            ?.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)?.active

        const maxCount = getBundleParams().maxSelectedCategoriesForFastCalculation || 0
        const isDisabledBySlectedItems = maxCount !== 0 ? selectedProductGroups?.length > maxCount : false

        return (
            <div className="tree__top-productgroups" {...getUIA("SearchtreeBestseller")}>
                <Headline size="xs">
                    <Icon name="category-bestseller" />
                    <strong>{translate(158)} </strong>
                    <strong className="text--highlight">{translate(1005)}</strong>
                </Headline>
                {
                    // If the active parent has top product groups render them
                    activeParent.topProductGroups && !!activeParent.topProductGroups.length && (
                        <>
                            <SelectionList
                                groups={[
                                    {
                                        priority: "high",
                                        items: activeParent.topProductGroups.map(mapProductGroupToSelectionListItem),
                                    },
                                ]}
                                selected={selectedProductGroups}
                                onChange={handleSelectProductGroup}
                                size="m"
                                localization={localization}
                            />
                            <Tooltip title={translateText(1041)} enterDelay={1000}>
                                <div>
                                    <Button
                                        className="btn--apply"
                                        icon="check"
                                        disabled={!selectedProductGroups.length}
                                        onClick={handleApplyProductGroups}
                                    >
                                        {translate(1041)}
                                    </Button>
                                </div>
                            </Tooltip>
                            {
                                // If the link to the service calculator if needed
                                onRequestCalculation && workTask?.workTask?.vehicle?.vehicleType === VehicleType.PassengerCar && (
                                    <Tooltip
                                        title={
                                            isDisabledBySlectedItems ? translateText(13025).replace("{0}", maxCount.toString()) : translateText(1042)
                                        }
                                        enterDelay={isDisabledBySlectedItems ? 0 : 1000}
                                    >
                                        <div>
                                            <Button
                                                className={classes("btn--calculate", isFCDemo && "version-button-demo")}
                                                icon="fastclick"
                                                layout={["holo"]}
                                                disabled={!props.canRequestCalculation || !selectedProductGroups.length || isDisabledBySlectedItems}
                                                onClick={handleRequestCalculation}
                                            >
                                                {translate(1042)}
                                                {isFCDemo && <Demo displayMode="edge" />}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </>
                    )
                }
                {
                    // If the active parent has no top product groups render a message
                    activeParent.topProductGroups && !activeParent.topProductGroups.length && (
                        <Text className="text--italic">{localization.translate(1006)}</Text>
                    )
                }
                {/* If the active parent top product groups were not loaded yet and are loaded now render the loader */}
                <Loader visible={!activeParent.topProductGroups && loading} />
            </div>
        )
    }

    const renderSplitView = () => {
        return (
            <>
                {renderBreadcrumbs()}
                {renderNodes()}
                {!hideBestsellers && renderTopProductGroups()}
            </>
        )
    }

    const breadcrumbs = getBreadcrumbs()
    const mode = getMode()

    let className = "indicate-overflow tree "
    let content

    if (mode === "fast-click") {
        className += "tree--fast-click "
        return <ClassicFastClickSearchTree {...props} onSelect={handleThumbnailClick} ref={treeRef} />
    }
    if (!breadcrumbs.length) {
        className += "tree--group-view "
        content = renderGroupView()
    } else {
        className += "tree--split-view "
        className += hideBestsellers ? "two-column " : "tree-column "
        content = renderSplitView()
    }

    if (props.className) {
        className += props.className
    }

    return (
        <div className={className} ref={treeRef} {...getUIA("SearchtreeContainer", props.treeContext)}>
            {content}
        </div>
    )
}

export default SearchTree

function mapProductGroupToSelectionListItem(productGroup: SearchTreeProductGroup): SelectionListItem {
    return {
        query: productGroup.id.toString(),
        name: productGroup.name || "-",
    }
}

function mapSelectionListItemToProductGroup(item: SelectionListItem): SearchTreeProductGroup {
    return {
        id: parseInt(item.query),
        name: item.name,
    }
}
