import { useCallback, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { AmountField, AmountItem, Button, Checkbox } from "@tm/controls"
import { Article, IMicros, RequestArticleDetailsPayload, channel } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { bem, classes } from "@tm/utils"
import { useFastServiceStore } from "../../../data"
import { Genart } from "../../../data/models"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"

type Props = {
    genArt: Genart
    fsArticle?: FSArticle
    article: Article
    onQuantityArticleChange: (articleId: string, quantity: number) => void
    showPartAlternatives: (genArt: Genart, article: FSArticle) => void
    disableCheckToggle?: boolean
}

export default function PartItem({ genArt, fsArticle, article, onQuantityArticleChange, showPartAlternatives, disableCheckToggle }: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const selectedArticles = useFastServiceStore((state) => state.selectedArticles)
    const updateArticles = useFastServiceStore((state) => state.setUpdateSelectedArticles)
    const updateArticleQuantity = useFastServiceStore((state) => state.setUpdateArticleQuantity)
    const [opened, setOpened] = useState(false)
    const style = useMemo(() => getStyle(), [])

    const handleToggleOpened = useCallback(() => {
        setOpened(!opened)
    }, [opened])

    const handleOnSelect = useCallback(() => {
        updateArticles(article)
    }, [article])

    const handelArticleDetails = (request: RequestArticleDetailsPayload) => {
        channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
    }

    const handleQuantityChange = (item: AmountItem) => {
        onQuantityArticleChange(article.id, item.value)
        updateArticleQuantity(article, item.value)
    }

    const handleShowPatAlternative = () => {
        if (fsArticle) {
            showPartAlternatives(genArt, fsArticle)
        }
    }

    const renderActions = () => {
        return (
            <>
                <AmountField
                    // disabled={item.isDisabled}
                    value={article.quantity}
                    // stepSize={stepSize}
                    onChange={handleQuantityChange}
                />
                <Button className={style.button} icon="replace" skin="highlight" onClick={handleShowPatAlternative} disabled={!fsArticle} />
            </>
        )
    }

    return (
        <div className="tk-parts">
            <div className={classes(style.wrapper, "article-list list")}>
                <Checkbox
                    size="l"
                    onToggle={handleOnSelect}
                    checked={selectedArticles.some((x) => x.internalId === article.internalId && x.id === article.id) || false}
                    disabled={disableCheckToggle}
                />
                {renderMicro?.("parts", "part-item", {
                    hiddenCells: {
                        additionalButtons: true,
                        additionalActions: true,
                        documents: true,
                    },
                    part: article,
                    vehicleId: stateVehicle?.id,
                    className: bem(style.actions, Morpheus.getParams("parts")?.templates?.articleItem?.bundle),
                    // vehicleEngineCode: vehicle?.engineCode,
                    // vehicleRecordsComparisons: vehicleRecords,
                    shouldLoadVehicleRecords: false,
                    // previouslyOrdered: part ? orderHistory?.some(x => x.supplierId == part?.supplier.id && x.supplierArticleNumber == part.supplierArticleNo) : undefined,
                    isCompact: !opened,
                    showActions: true,
                    showArticleImage: true,
                    onRequestArticleDetails: handelArticleDetails,
                    onToggleCollapse: handleToggleOpened,
                    renderBuyActions: renderActions,
                    // ...isTotalPriceHeader && { renderTotalPrice: renderTotalPrice },
                    canFilterArticleAttributes: false,
                    renderCustomErpInformation: () => null,
                })}
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        actions: {
            flex: 1,
            $nest: {
                "&--wm .article-actions button.price": {
                    marginRight: "0.5em",
                    width: "6.25em",
                },
                "> :not(:last-child)": {
                    marginRight: ".25em",
                },
                ".article-actions_price": {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: ".5",
                    paddingRight: "1.5em",
                    fontSize: theme.font.subtitleSize.m,

                    $nest: {
                        ".text": {
                            padding: 0,
                            color: theme.colors.primary,
                            fontWeight: theme.font.boldWeight,
                        },
                    },
                },
            },
        },
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "6em",
            marginLeft: "auto",
            marginRight: "unset",
        },
        wrapper: {
            display: "flex",
            gap: "1em",
        },
    })(PartItem)
}
