import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { Icon, Stack, StackProps, Tooltip, Typography, styled } from "@tm/components"
import { ModificationState } from "@tm/models"
import { ArticleReferenceParms } from "../../../models"

type ClickableReferenceProps = StackProps & {
    isClickable?: boolean
    isRemoved?: boolean
}

const ClickableReference = styled(Stack, {
    shouldForwardProp: (prop: string) => !["isClickable", "isRemoved"].includes(prop),
})<ClickableReferenceProps>(({ theme, isClickable, isRemoved }) => ({
    svg: {
        marginRight: 2,
    },
    ...(isRemoved && {
        textDecoration: "line-through",
    }),
    ...(isClickable && {
        cursor: "pointer",
        "&:hover *": {
            color: theme.palette.primary.main,
        },
    }),
}))

ClickableReference.defaultProps = { direction: "row" }

type ReferenceProps = { reference: ArticleReferenceParms; onClick: (reference: ArticleReferenceParms) => void }

export function Reference(props: ReferenceProps) {
    const { onClick, reference } = props
    const { translateText } = useLocalization()

    const handleClick = () => onClick(reference)

    return (
        <ClickableReference onClick={handleClick} isClickable>
            {(() => {
                switch (reference.modificationState) {
                    case ModificationState.Added:
                        return (
                            <Tooltip title={translateText(886)}>
                                <Icon name="plus" size="12px" />
                            </Tooltip>
                        )
                    case ModificationState.Removed:
                        return (
                            <Tooltip title={translateText(895)}>
                                <Icon name="minus" size="12px" />
                            </Tooltip>
                        )
                    default:
                }
            })()}
            <Typography variant="label" noWrap>
                {props.reference.label}
            </Typography>
        </ClickableReference>
    )
}
