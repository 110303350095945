import { ArticleIdentifier, ArticleInfoType } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle, useSelection } from "@tm/utils"
import { useCallback, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { useLocalization } from "@tm/localization"
import * as Data from "../../../../data"
import { mapVoucherCatalogPartToArticleIdentifier } from "../../../../data/mapper"
import { ShowCostEstimationDetailsResponse } from "../../../../data/model"

export function useCostEstimationDetails(voucherId: string | undefined) {
    const { language } = useLocalization()
    const { data, isLoading: costEstimationDetailsLoading } = useQuery(
        ["vouchers__useCostEstimationDetails", voucherId],
        () => (voucherId ? Data.showCostEstimationDetails(voucherId) : undefined),
        {
            enabled: !!voucherId,
            staleTime: 60 * 1000, // 1 minute
        }
    )

    const getAllPartIds = useCallback(() => {
        return data?.calculatedParts.filter((item) => item.articleType === ArticleInfoType.TecdocArticle).map((item) => item.id) || []
    }, [data?.calculatedParts])

    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllPartIds)

    useEffect(() => {
        unselectAll()
    }, [unselectAll, voucherId])

    const articleIdentifiers = useMemo((): Array<ArticleIdentifier> | undefined => {
        const filteredParts = data?.calculatedParts.filter(
            (part) => part.articleType === ArticleInfoType.TecdocArticle || part.articleType === ArticleInfoType.WholesalerArticle
        )
        return filteredParts && mapVoucherCatalogPartToArticleIdentifier(filteredParts)
    }, [data?.calculatedParts])

    const articles = useArticlesByArticleNumbersWithOptionalVehicle(articleIdentifiers, language)

    const costEstimationDetails = useMemo((): ShowCostEstimationDetailsResponse | undefined => {
        if (data && articles.length) {
            return {
                ...data,
                calculatedParts: data.calculatedParts.map((part) => {
                    const match = articles.find(
                        (article) =>
                            part.dataSupplier?.id === article.supplier.id &&
                            part.articleNumber === article.supplierArticleNo &&
                            part.productGroup?.id === article.productGroup.id
                    )

                    return match
                        ? {
                              ...part,
                              thumbnail: match.thumbnail,
                              productGroup: match.productGroup,
                              description: match.description,
                          }
                        : part
                }),
            }
        }
        return data
    }, [data, JSON.stringify(articles)])

    return { costEstimationDetails, costEstimationDetailsLoading, selectedIds, toggleSelected, selectAll, unselectAll }
}
