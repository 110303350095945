import { OrderItem, Item } from "@tm/models"
import { MessageBus, Channel } from "@tm/hermes"

export type BundleActionType =
    | { type: "EXPORT_URI_CHANGED"; payload: { uri?: string; exportLabel?: string } }
    | { type: "START_CREATE_COST_ESTIMATION"; payload: { postCreateAction?: PostCreateActionType } }
    | { type: "TOGGLE_PRINT_VEHICLE_IMAGE"; payload: { state: boolean } }
    | { type: "TOGGLE_SHOW_ARTICLE_NUMBERS"; payload: { state: boolean } }
    | { type: "TOGGLE_SHOW_MANUFACTURERS"; payload: { state: boolean } }
    | { type: "TOGGLE_SHOW_WHOLESALER_ARTICLE_NUMBERS"; payload: { state: boolean } }

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel("BUNDLE")
}

export enum PostCreateActionType {
    ShowPdf,
    SendChatMessage,
    PublishLink,
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { CREATE_COST_ESTIMATION: { postCreateAction?: PostCreateActionType } } & {} & {
    WORKTASK_BASKET_PARTS_CHANGING: { workTaskId: string; partIds: Array<string> }
} & { WORKTASK_BASKET_PART_CHANGED: { workTaskId: string; partId: string } } & { ENABLE_COST_ESTIMATION: {} } & { ANY_ORDERS_SENDING: {} } & {
    ALL_ORDERS_SENT: {}
} & {
    COST_ESTIMATION_LINK_CREATED: { link: string; additionalData?: { pin?: string } }
} & {
    COST_ESTIMATION_LINK_CREATION_ERROR: { error?: Error }
}

export function mapOrderItemToItem(orderItem: OrderItem): Item {
    return { id: orderItem.id, version: orderItem.version }
}
