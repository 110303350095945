import { useUser, useWorkTask } from "@tm/context-distribution"
import { Icon, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { memo, useMemo } from "react"
import { getUIA } from "@tm/utils"
import { GroupHeaderIconButton } from "./GroupHeaderIconButton"

type Props = {
    provider: string
    topicIds: number[]
    showButtonText?: boolean
}

export const ToolRentalButton = memo((props: Props) => {
    const { translateText, translate, languageId } = useLocalization()
    const { userContext } = useUser()
    const { workTask } = useWorkTask() ?? {}

    const url = useMemo<string | undefined>(() => {
        const toolRentModule = userContext.modules?.find((x) => x.type === UserModuleType.ToolRent)
        const provider = toolRentModule?.parameters?.find((x) => x.key === "Provider")?.value
        const urlTemplate = toolRentModule?.parameters?.find((x) => x.key === "URL_Homepage")?.value

        if (provider !== props.provider || !urlTemplate) {
            return
        }

        const { traderId } = userContext.principal ?? {}
        const { customerNo } = userContext.account ?? {}

        // Mobility market
        if (provider === "1") {
            if (!props.topicIds.length) {
                return
            }

            const mobilityMarketId = props.topicIds.join(",")

            let mmUrl = replacePlaceholder(urlTemplate, "traderId", traderId?.toString())
            mmUrl = replacePlaceholder(mmUrl, "customerNr", customerNo)
            mmUrl = replacePlaceholder(mmUrl, "language", languageId)
            mmUrl = replacePlaceholder(mmUrl, "KTypNr", workTask?.vehicle?.tecDocTypeId?.toString())
            mmUrl = replacePlaceholder(mmUrl, "MMID", mobilityMarketId)

            return removePlaceholderParameters(mmUrl)
        }
    }, [props.provider, props.topicIds, languageId, userContext, workTask?.vehicle?.tecDocTypeId])

    if (!url) {
        return null
    }

    return (
        <Tooltip title={translateText(13282)}>
            <GroupHeaderIconButton
                href={url}
                startIcon={<Icon name="rental-tools" />}
                className="article-group__description__left__td-button"
                variant="outlined"
                target="__blank"
                {...getUIA("ArticelListHeaderToolRental")}
            >
                {props.showButtonText && translate(13282)}
            </GroupHeaderIconButton>
        </Tooltip>
    )
})

function replacePlaceholder(url: string, placeholder: string, value: string | undefined): string {
    return url.replace(new RegExp(`{#${placeholder}}`, "i"), value ?? "")
}

function removePlaceholderParameters(url: string): string {
    const [host, query] = url.split("?")
    const params = new URLSearchParams(query)

    params.forEach((value, key) => {
        if (/{#.*}/.test(value)) {
            params.delete(key)
        }
    })

    return `${host}?${params.toString()}`
}
