import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const searchWidget: BundleComponent = {
    name: "vehicle-search-widget",
    reduce,
    component,
}

export default searchWidget
