import { ArticleNumber, Box, Button, Image, Stack, Typography, styled } from "@tm/components"
import { memo } from "react"
import { useLocalization } from "@tm/localization"
import { AddOeToBasket } from "../Transactions/AddOeToBasket"
import { OeInfoAttributes } from "../Attributes/OeInfoAttributes"
import { OePrices } from "../ErpContainer/OePrices"
import { useOeInformationItemState } from "../ContextProviderStates/OeInformationItemState"
import { OeInfoDescription } from "../OeInfoDescription"

export const ArticleContainer = styled(Box)(() => ({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
}))

const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "45px",
    height: "45px",
    zIndex: 2,
    img: {
        objectFit: "contain",
    },
}))

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

export const OeInformationItem = memo(() => {
    const { translateText } = useLocalization()

    const thumbnailUrl = useOeInformationItemState((x) => x.thumbnailUrl)
    const oeArticleNo = useOeInformationItemState((x) => x.oeArticleNo)
    const showAddToBasket = useOeInformationItemState((x) => x.showAddToBasket)

    return (
        <ArticleContainer>
            <Stack spacing={1} mb={0.5} direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ImageContainer>
                        <Image type="article" src={thumbnailUrl} width="45px" height="45px" loading="eager" />
                    </ImageContainer>
                    <StyledButton>
                        <ArticleNumber articleNumber={oeArticleNo} articleNumberType="oe" />
                    </StyledButton>
                    <StyledButton color="highlight">
                        <Typography>{translateText(803)}</Typography>
                    </StyledButton>
                    <OeInfoDescription />
                </Stack>
                <Stack direction="row" alignItems="flex-end">
                    <OePrices />
                    {showAddToBasket && (
                        <Box padding=".5em">
                            <AddOeToBasket />
                        </Box>
                    )}
                </Stack>
            </Stack>
            <OeInfoAttributes />
        </ArticleContainer>
    )
})
