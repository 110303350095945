import { useMemo } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import { GetSearchFiltersRequest, SearchFilter } from "../../../../data/model"
import * as PartsRepository from "../../../../data/repositories"
import { DirectSearchStartParams, ListParams, SearchData } from "../../models"

const QUERY_KEY = "articlesSearch"

type QueryKey = [string, GetSearchFiltersRequest]

export function useSearchFilters(params: ListParams<DirectSearchStartParams>, isEnabled: boolean): SearchData {
    const request = useMemo<GetSearchFiltersRequest>(() => {
        return {
            query: params.startParams.query,
            searchFilter: params.startParams.searchFilter,
            extendedAssortment: false,
        }
    }, [params.startParams])

    const searchQuery = useQuery({
        enabled: isEnabled,
        queryKey: [QUERY_KEY, request],
        queryFn: getSearchFilters,
    })

    return {
        isLoading: searchQuery.isLoading,
        searchFilter: searchQuery.data,
    }
}

function getSearchFilters({ queryKey: [, request] }: QueryFunctionContext<QueryKey>): Promise<SearchFilter[]> {
    return PartsRepository.getSearchFilters(request)
}
