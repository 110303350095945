import { memo, useCallback, useEffect, useState } from "react"
import { Icon, Typography, Alert, Paper, styled, Collapse } from "@tm/components"
import { useKeyValue } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useVideoUrlParam } from "../../helpers"

const AlertPaper = styled(Paper)(({ theme }) => ({
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(14),
}))

const VideoIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

export const VideoInfo = memo(() => {
    const [open, setOpen] = useState(false)
    const [value, setValue, state] = useKeyValue({ key: "TELESALES_VIDEO_TOAST_DISABLED", ownedByRepairShop: false })
    const { translateText } = useLocalization()

    const linkToVideoLibrary = useVideoUrlParam()

    useEffect(() => {
        if (state === "hasValue") {
            setOpen(!value)
        }
    }, [state, value, open])

    const onDisableToast = useCallback(() => {
        if (setValue) {
            setValue("true")
        }
    }, [setValue])

    if (!linkToVideoLibrary) {
        return null
    }

    return (
        <Collapse in={open}>
            <AlertPaper elevation={1}>
                <Alert severity="success" icon={<VideoIcon name="video" />} closeText={translateText(13015)} onClose={onDisableToast}>
                    <Typography>{translateText(13009)}</Typography>
                    <Typography variant="body2">
                        <a target="_blank" rel="noopener noreferrer nofollow" href={linkToVideoLibrary}>
                            <u>{translateText(13010)}</u>
                        </a>
                    </Typography>
                </Alert>
            </AlertPaper>
        </Collapse>
    )
})
