import { useLocalization } from "@tm/localization"
import { CostEstimationVoucherTotals } from "@tm/models"
import { Box } from "@tm/components"
import Total from "../../Total"

type Props = {
    costEstimationTotals: CostEstimationVoucherTotals
    showRepairTimesInHours: boolean
}
export default function WorksTotals({ costEstimationTotals, showRepairTimesInHours }: Props) {
    const { translateText, currency, number } = useLocalization()

    const repairTimesUnits = showRepairTimesInHours ? ` ${translateText(60)}` : ""
    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Total
                label={translateText(259)}
                labelAlignedRight
                value={costEstimationTotals?.totalWorkTime ? number(costEstimationTotals.totalWorkTime) + repairTimesUnits : "-"}
            />
            <Total
                label={translateText(1721)}
                labelAlignedRight
                subLabel={translateText(12830)}
                value={
                    costEstimationTotals?.totalWorksCosts
                        ? currency(costEstimationTotals.totalWorksCosts, costEstimationTotals.currencyCode || costEstimationTotals.currencySymbol)
                        : "-"
                }
            />
        </Box>
    )
}
