import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import { ArticleInfoType, ChangeItemsResponse, CustomWork, FittingGroupRequest, Item, RegisteredModels, VatRate, WorkCategory } from "@tm/models"
import { Container } from "@tm/nexus"
import { KEY as ERP_INFO_KEY } from "../queries/useErpInfosBasketData"
import { BasketPart, BasketWork } from "../../../../models"
import {
    ChangeIsIncludedResponse,
    ChangeQuantityResponse,
    PartItem,
    ReplaceByCustomWorkRequest,
    ShowWorkEstimationResponse,
    ShowWorkTaskBasketResponse,
    ChangeWorkItemResponse,
    ChangeWorkItemListResponse,
    CustomerDefaults,
    ResetRepairTimeListRequest,
    WorkItem,
    GetErpInformationBasketResponse,
} from "../../../model"
import * as Data from "../../.."
import { mapBasketPartsToItems, mapBasketWorksToItems, mapChangeIsIncludedResponse, mapChangeQuantityResponse } from "../../../mapper"
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useAddCustomWorkList } from "../works/useAddCustomWorkList"
import { useAddRepairTimeList } from "../works/useAddRepairTimeList"
import { EditWorkMode } from "../../../../components/cost-estimation/business"

export type EditPartRequest = {
    item: Item
    rebate?: number
    surcharge?: number
    regularPrice?: number
    customPartNumber?: string
    customPartDescription?: string
    vatRate?: VatRate
    isCustomPart?: boolean
}

export function useCostEstimationsMutations(
    workTaskId: string,
    workTaskBasketQueryKey: any[],
    workEstimationQueryKey: any[],
    handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>
) {
    const queryClient = useQueryClient()
    const addCustomWorks = useAddCustomWorkList(handleBasketUpdateWorkflow)
    const addRepairTimes = useAddRepairTimeList(handleBasketUpdateWorkflow)

    const { mutateAsync: includeParts } = useMutation((items: Item[]) => Data.includeParts(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeCostEstimationPartsResponse(response),
    })

    const { mutateAsync: excludeParts } = useMutation((items: Item[]) => Data.excludeParts(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeCostEstimationPartsResponse(response),
    })

    const { mutateAsync: removePartList } = useMutation((partIds: string[]) => Data.removePartList(workTaskId, partIds), {
        onSuccess: handleRemovePartsResponse,
    })

    const { mutateAsync: changePartQuantity } = useMutation(
        (request: { item: Item; quantityValue: number }) => Data.changePartQuantity(request.item, request.quantityValue),
        {
            onSuccess: handleChangePartQuantityResponse,
        }
    )

    const { mutateAsync: editPart } = useMutation(
        (request: EditPartRequest) => {
            return request.customPartDescription && request.isCustomPart
                ? Data.editCustomPart({
                      item: request.item,
                      description: request.customPartDescription,
                      articleNumber: request.customPartNumber,
                      rebate: request.rebate,
                      garagePrice: request.regularPrice,
                      surcharge: request.surcharge,
                      vatRate: request.vatRate,
                      usePercentageValues: true,
                  })
                : Data.editPartPrice({
                      item: request.item,
                      rebate: request.rebate,
                      surcharge: request.surcharge,
                      garagePrice: request.regularPrice,
                      usePercentageValues: true,
                  })
        },
        {
            onSuccess: handleEditPartResponse,
        }
    )

    const { mutateAsync: includeWorks } = useMutation((items: Item[]) => Data.includeWorks(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeWorksResponse(response, true),
    })

    const { mutateAsync: excludeWorks } = useMutation((items: Item[]) => Data.excludeWorks(workTaskId, items), {
        onSuccess: (response) => handleToggleIncludeWorksResponse(response, false),
    })

    const { mutateAsync: removeWorkList } = useMutation(
        (request: { workIds: string[]; isExternalCall?: boolean }) => Data.removeWorkList(workTaskId, request.workIds),
        {
            onSuccess: (response, request) => {
                handleRemoveWorksResponse(response, request.workIds, request.isExternalCall)
            },
        }
    )

    const { mutateAsync: changeRepairTimeCalculation } = useMutation(
        (useRepairTimeCalculation: boolean) => Data.changeRepairTimeCalculation(workTaskId, useRepairTimeCalculation),
        {
            onSuccess: handleCalculateWorksResponse,
        }
    )

    const { mutateAsync: resetCostEstimation } = useMutation(() => Data.resetCostEstimation(workTaskId), {
        onSuccess: (response) => response && handleResetCostEstimationResponse(response),
    })

    const { mutateAsync: resetRepairTimeList } = useMutation((request: ResetRepairTimeListRequest) => Data.resetRepairTimeList(request), {
        onSuccess: handleResetRepairTimesResponse,
    })

    const { mutateAsync: attachVehicleImage } = useMutation((vehicleImageBase64: string) => Data.attachVehicleImage(workTaskId, vehicleImageBase64), {
        onSuccess: (response) => response && handleBasketUpdateWorkflow(workTaskId, response), // TODO Basket V2: new handleAttachVehicleImageResponse to set the vehicle image
    })

    const { mutateAsync: removeVehicleImage } = useMutation(() => Data.removeVehicleImage(workTaskId), {
        onSuccess: (response) => response && handleRemoveVehicleImageResponse(response),
    })

    const { mutateAsync: editWork } = useMutation(
        (request: {
            item: Item
            repairTimeDivision: number
            description: string
            editWorkMode: EditWorkMode
            customerDefaults?: CustomerDefaults
            customWorkNumber?: string
            categoryOfWork: WorkCategory
            customTime?: number
            customHourlyRate?: number
            fixedPriceValue?: number
            customRebate?: number
            customSurcharge?: number
            vatRate?: VatRate
        }) => {
            if (request.editWorkMode === EditWorkMode.editCustomWork) {
                return Data.editCustomWork({
                    categoryOfWork: request.categoryOfWork,
                    customHourlyRate: request.customHourlyRate,
                    customRebate: request.customRebate,
                    customSurcharge: request.customSurcharge,
                    customTime: request.customTime,
                    customWorkNumber: request.customWorkNumber,
                    customerDefaults: request.customerDefaults,
                    description: request.description,
                    fixedPriceValue: request.fixedPriceValue,
                    item: request.item,
                    repairTimeDivision: request.repairTimeDivision,
                    usePercentageValues: true,
                    vatRate: request.vatRate,
                })
            }
            return Data.editRepairTimeCalculation({
                customHourlyRate: request.customHourlyRate,
                customRebate: request.customRebate,
                customSurcharge: request.customSurcharge,
                customTime: request.customTime,
                customerDefaults: request.customerDefaults,
                fixedPriceValue: request.fixedPriceValue,
                item: request.item,
                repairTimeDivision: request.repairTimeDivision,
                usePercentageValues: true,
                vatRate: request.vatRate,
            })
        },
        {
            onSuccess: handleEditWorkResponse,
        }
    )

    const { mutateAsync: replaceByCustomWork } = useMutation(
        (addCustomWorkListRequest: ReplaceByCustomWorkRequest) => Data.replaceByCustomWork(addCustomWorkListRequest),
        {
            onSuccess: (response) => response && handleBasketUpdateWorkflow(workTaskId, response),
        }
    )

    const toggleIncludeCostEstimationParts = useCallback(
        (parts: BasketPart[], partItems?: PartItem[]) => {
            const items = mapBasketPartsToItems(parts, partItems)
            const somePartsAreIncludedInOffer = parts.some((part) => part.partItem.costEstimationItem?.isIncluded)
            if (somePartsAreIncludedInOffer) {
                return excludeParts(items)
            }
            return includeParts(items)
        },
        [includeParts, excludeParts]
    )

    const changeCostEstimationPartQuantity = useCallback(
        (part: BasketPart, quantityValue: number) => {
            const item = mapBasketPartsToItems([part])[0]
            return changePartQuantity({ item, quantityValue })
        },
        [changePartQuantity]
    )

    const editCostEstimationPart = useCallback(
        (
            part: BasketPart,
            rebate?: number,
            surcharge?: number,
            regularPrice?: number,
            customPartNumber?: string,
            customPartDescription?: string,
            vatRate?: VatRate,
            isCustomPart?: boolean
        ) => {
            const item = mapBasketPartsToItems([part])[0]
            return editPart({ item, rebate, surcharge, regularPrice, customPartNumber, customPartDescription, vatRate, isCustomPart })
        },
        [editPart]
    )

    const toggleIncludeWorks = useCallback(
        (works: BasketWork[]) => {
            const items = mapBasketWorksToItems(works)
            const someWorksAreIncludedInOffer = works.some((work) => work.workItem.isIncluded)
            if (someWorksAreIncludedInOffer) {
                return excludeWorks(items)
            }
            return includeWorks(items)
        },
        [excludeWorks, includeWorks]
    )

    const resetRepairTimes = useCallback(
        (works: BasketWork[], customerDefaults: CustomerDefaults) => {
            const items = mapBasketWorksToItems(works)
            return resetRepairTimeList({
                workTaskId,
                customerDefaults,
                itemList: items,
                usePercentageValues: true,
            })
        },
        [resetRepairTimeList, workTaskId]
    )

    const attachVehicleImg = useCallback(
        (vehicleImageBase64: string) => {
            return attachVehicleImage(vehicleImageBase64)
        },
        [attachVehicleImage]
    )

    const calculateWorks = useCallback(
        (useRepairTimeCalculation: boolean) => {
            return changeRepairTimeCalculation(useRepairTimeCalculation)
        },
        [changeRepairTimeCalculation]
    )

    const editCostEstimationWork = useCallback(
        (
            work: BasketWork,
            repairTimeDivision: number,
            categoryOfWork: WorkCategory,
            description: string,
            editWorkMode: EditWorkMode,
            customerDefaults?: CustomerDefaults,
            customWorkNumber?: string,
            customTime?: number,
            customHourlyRate?: number,
            fixedPriceValue?: number,
            customRebate?: number,
            customSurcharge?: number,
            vatRate?: VatRate
        ) => {
            const item = mapBasketWorksToItems([work])[0]
            return editWork({
                item,
                repairTimeDivision,
                categoryOfWork,
                description,
                editWorkMode,
                customWorkNumber,
                customTime,
                fixedPriceValue,
                customHourlyRate,
                customRebate,
                customSurcharge,
                vatRate,
                customerDefaults,
            })
        },
        [editWork]
    )

    const replaceWorkWithCustomWork = useCallback(
        (workToReplaceId: string, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string) => {
            return replaceByCustomWork({ customWork, workTaskId, workToReplaceId, repairTimeDivision, vehicleId, usePercentageValues: true })
        },
        [replaceByCustomWork, workTaskId]
    )

    function handleResetCostEstimationResponse(response: ChangeItemsResponse | undefined) {
        if (!response) {
            return
        }

        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if ((!prev?.parts && !prev?.works) || !prev?.costEstimation) {
                return
            }
            return {
                ...prev,
                parts: [],
                works: [],
                // TODO: check if this is necessary. The useWorkTaskBasketState has as dependency the whole ShowWorkTaskBasketResponse
                orderGroups: prev.orderGroups?.map((orderGroup) => ({
                    ...orderGroup,
                    partIds: [],
                })),
                costEstimation: {
                    ...prev.costEstimation,
                    partIds: [],
                    workIds: [],
                },
            }
        })

        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleRemoveVehicleImageResponse(response: ChangeItemsResponse | undefined) {
        if (!response) {
            return
        }

        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.costEstimation) {
                return
            }
            return {
                ...prev,
                costEstimation: {
                    ...prev.costEstimation,
                    vehicleImage: undefined,
                },
            }
        })

        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleToggleIncludeCostEstimationPartsResponse(response: ChangeIsIncludedResponse | undefined) {
        if (!response) {
            return
        }

        if (response.changedIsIncludedParts) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.parts) {
                    return
                }
                return {
                    ...prev,
                    parts: mapChangeIsIncludedResponse(prev.parts, response.changedIsIncludedParts),
                }
            })
        }
        handleBasketUpdateWorkflow(workTaskId, {
            basketUpdateWorkflow: response.basketUpdateWorkflow,
            changedItems: response.changedIsIncludedParts.map((part) => ({ id: part.id, version: part.version })),
        })
    }

    function handleRemovePartsResponse(response: ChangeItemsResponse | undefined, partIds: string[]) {
        if (!response) {
            return
        }
        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.parts) {
                return
            }

            return {
                ...prev,
                parts: prev.parts.filter((part) => !partIds.includes(part.id)),
            }
        })

        queryClient.getQueriesData<GetErpInformationBasketResponse>([ERP_INFO_KEY, workTaskId]).forEach(([key]) => {
            queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(key, (prev) => {
                if (!prev?.items) {
                    return
                }

                return {
                    ...prev,
                    items: prev.items.filter((item) => !partIds.includes(item.itemId)),
                }
            })
        })
        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleChangePartQuantityResponse(response: ChangeQuantityResponse | undefined) {
        if (!response) {
            return
        }
        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.parts) {
                return
            }
            return {
                ...prev,
                parts: prev.parts.map((part) => {
                    const item = response.changedQuantityPart.id === part.id ? response.changedQuantityPart : undefined
                    if (item) {
                        const mappedPart = mapChangeQuantityResponse(part, item)
                        return mappedPart ?? part
                    }
                    return part
                }),
            }
        })
        handleBasketUpdateWorkflow(
            workTaskId,
            {
                basketUpdateWorkflow: response.basketUpdateWorkflow,
                changedItems: [{ id: response.changedQuantityPart.id, version: response.changedQuantityPart.version }],
            },
            response.changedQuantityPart.quantity.value
        )
    }

    function handleEditPartResponse(
        response: ChangeItemsResponse | undefined,
        request: {
            item: Item
            rebate?: number
            surcharge?: number
            regularPrice?: number
            customPartNumber?: string
            customPartDescription?: string
            vatRate?: VatRate
        }
    ) {
        if (!response) {
            return
        }

        if (response.changedItems) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.parts) {
                    return
                }
                return {
                    ...prev,
                    parts: prev.parts.map((part) => {
                        const item = response.changedItems.find((x) => x.id === part.id)
                        const isCustomPart = part.articleInformation.articleInfoType === ArticleInfoType.CustomArticle
                        if (item && part.costEstimationItem) {
                            return {
                                ...part,
                                version: item.version,
                                costEstimationItem: {
                                    ...part.costEstimationItem,
                                    rebate: request.rebate,
                                    surcharge: request.surcharge,
                                    regularPrice: request.regularPrice,
                                    vatRates: part.costEstimationItem.vatRates?.map((vatRate) => {
                                        if (vatRate.vatType === request.vatRate?.vatType) {
                                            return {
                                                ...vatRate,
                                                isSelected: true,
                                            }
                                        }
                                        return {
                                            ...vatRate,
                                            isSelected: false,
                                        }
                                    }),
                                },
                                articleInformation: {
                                    ...part.articleInformation,
                                    articleNumber: isCustomPart ? request.customPartNumber ?? "" : part.articleInformation.articleNumber,
                                    description: isCustomPart ? request.customPartDescription ?? "" : part.articleInformation.description,
                                },
                            }
                        }
                        return part
                    }),
                }
            })
        }
        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleToggleIncludeWorksResponse(response: ChangeItemsResponse | undefined, isIncluded: boolean) {
        if (!response) {
            return
        }

        if (response.changedItems) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.works) {
                    return
                }
                return {
                    ...prev,
                    works: prev.works.map((work) => {
                        const item = response.changedItems.find((x) => x.id === work.id)
                        if (item) {
                            return {
                                ...work,
                                version: item.version,
                                isIncluded,
                            }
                        }
                        return work
                    }),
                }
            })
        }
        handleBasketUpdateWorkflow(workTaskId, response)
    }

    function handleRemoveWorksResponse(response: ChangeItemsResponse | undefined, workIds: string[], isExternalCall?: boolean) {
        if (!response) {
            return
        }
        let worksToBeDeleted: WorkItem[] = []

        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.works) {
                return
            }
            worksToBeDeleted = prev.works.filter((part) => workIds.includes(part.id))

            return {
                ...prev,
                works: prev.works.filter((work) => !workIds.includes(work.id)),
            }
        })
        queryClient.setQueryData<ShowWorkEstimationResponse | undefined>(workEstimationQueryKey, (prev) => {
            if (!prev?.works) {
                return
            }
            return {
                ...prev,
                works: prev.works.filter((work) => !workIds.includes(work.id)),
            }
        })

        handleBasketUpdateWorkflow(workTaskId, response)

        if (isExternalCall && worksToBeDeleted) {
            Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

            // Only one work can be deleted from external
            const repairTime = worksToBeDeleted[0]
            Container.getInstance(RegisteredModels.Basket_HasRepairTimes)
                .subscribe({
                    workTaskId,
                    repairTimesProvider: repairTime.provider,
                    repairTimeProviderWorkId: repairTime.providerWorkId,
                })
                .loadIfRequired()

            const containerPG = Container.getInstance(RegisteredModels.Basket_HasRepairTimesForProductGroup)
            if (repairTime.fittingSide && repairTime.productGroupIds && repairTime.productGroupIds.length) {
                repairTime.productGroupIds.forEach((productGroupId) => {
                    const fittingGroupRequest: FittingGroupRequest = {
                        fittingSide: repairTime.fittingSide,
                        productGroupId,
                    }

                    containerPG
                        .subscribe({
                            workTaskId,
                            request: fittingGroupRequest,
                        })
                        .loadIfRequired()
                })
            }
        }
    }

    function handleEditWorkResponse(response: ChangeWorkItemResponse | undefined) {
        if (!response) {
            return
        }

        const { changedWork } = response
        if (changedWork) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.works) {
                    return
                }
                return {
                    ...prev,
                    works: prev.works.map((work) => {
                        if (work.id === changedWork.id) {
                            return {
                                ...changedWork,
                                rebate: changedWork.rebate,
                                surcharge: changedWork.surcharge,
                            }
                        }
                        return work
                    }),
                }
            })
            queryClient.setQueryData<ShowWorkEstimationResponse | undefined>(workEstimationQueryKey, (prev) => {
                if (!prev?.works) {
                    return
                }

                return {
                    ...prev,
                    works: prev.works.map((work) => {
                        if (work.id === changedWork.id && work.displayTime) {
                            return {
                                ...work,
                                timeInMinutes: changedWork.timeInMinutes ?? work.timeInMinutes,
                                providerWorkId: changedWork.providerWorkId ?? work.providerWorkId,
                                description: changedWork.description ?? work.description,
                            }
                        }

                        return work
                    }),
                }
            })
        }

        handleBasketUpdateWorkflow(workTaskId, {
            basketUpdateWorkflow: response.basketUpdateWorkflow,
            changedItems: [{ id: response.changedWork.id, version: response.changedWork.version }],
        })
    }

    function handleResetRepairTimesResponse(response: ChangeWorkItemListResponse | undefined) {
        if (!response) {
            return
        }

        if (response.changedWorks) {
            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.works) {
                    return
                }
                return {
                    ...prev,
                    works: prev.works.map((work) => {
                        const changedWork = response.changedWorks.find((x) => x.id === work.id)
                        if (changedWork) {
                            return {
                                ...changedWork,
                            }
                        }
                        return work
                    }),
                }
            })
        }

        handleBasketUpdateWorkflow(workTaskId, {
            basketUpdateWorkflow: response.basketUpdateWorkflow,
            changedItems: response.changedWorks.map((work) => ({ id: work.id, version: work.version })),
        })
    }

    function handleCalculateWorksResponse(response: ChangeItemsResponse | undefined) {
        if (!response) {
            return
        }

        queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
            if (!prev?.costEstimation) {
                return
            }
            return {
                ...prev,
                costEstimation: {
                    ...prev.costEstimation,
                    useRepairTimeCalculation: !prev.costEstimation.useRepairTimeCalculation,
                },
            }
        })

        handleBasketUpdateWorkflow(workTaskId, response)
    }

    return {
        addCustomWorks,
        addRepairTimes,
        attachVehicleImg,
        changeCostEstimationPartQuantity,
        calculateWorks,
        editCostEstimationPart,
        editCostEstimationWork,
        removePartList,
        removeVehicleImage,
        removeWorkList,
        replaceWorkWithCustomWork,
        resetCostEstimation,
        resetRepairTimes,
        toggleIncludeCostEstimationParts,
        toggleIncludeWorks,
    }
}
