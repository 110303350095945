import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import {
    CreateCustomRepairTimeRequest as SaveCustomRepairTimeRequest,
    DeleteCustomRepairTimesRequest,
    GetCustomRepairTimesRequest,
    GetCustomRepairTimesResponse,
} from "../../model/custom-items"

function getServiceUrl() {
    return getBundleParams().customRepairTimesServiceUrl
}

export function getCustomRepairTimes(body: GetCustomRepairTimesRequest) {
    return ajax<GetCustomRepairTimesResponse>({ url: `${getServiceUrl()}/Get`, body, authorization: getStoredAuthorization() }).then((response) => ({
        data: response?.repairTimes ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.repairTimes?.length ?? 0) < body.pageSize,
    }))
}

export function saveCustomRepairTime(body: SaveCustomRepairTimeRequest) {
    return ajax<{ id: string }>({ url: `${getServiceUrl()}/Save`, body, authorization: getStoredAuthorization(), method: "POST" }).then(
        (response) => {
            return response?.id
        }
    )
}

export function deleteCustomRepairTimes(body: DeleteCustomRepairTimesRequest) {
    return ajax<void>({ url: `${getServiceUrl()}/Delete`, body, authorization: getStoredAuthorization(), method: "POST" })
}
