import { MessageBus, Channel } from "@tm/hermes"
import { ReplaceButton } from "@tm/models"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }

    return mb.channel("BUNDLE")
}
export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { REPLACE_PART: ReplaceButton }
