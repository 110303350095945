import { useLocalization } from "@tm/localization"
import {
    Article,
    ArticleAttribute,
    AttributeFilterModel,
    OrderItem,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    SearchFilters,
    ConfigParams,
} from "@tm/models"
import { NumberDictionary } from "@tm/utils"
import * as React from "react"
import Morpheus from "@tm/morpheus"
import { orderBy } from "lodash"
import { SearchType } from "../../../business"
import { Models } from "../../../data"
import { IActions, ListState } from "../business"
import { getArticleUniqueId, mergeDbAlternatives } from "../business/helpers"
import { ContentGroup } from "./ContentGroup"
import { RouteParams } from "../wrapper"

type Props = {
    state: ListState
    partToReplaceData?: { orderItem: OrderItem; article?: Article }
    partToReplaceErpAlternatives?: Array<Article>
    handleSelectProductGroupAttributeFilter: (productGroupFilter: AttributeFilterModel, previousProductGroupFilter?: AttributeFilterModel) => void
    handleDeselectProductGroupAttributeFilter: (productGroupFilter: AttributeFilterModel) => void
    handleDeselectAllProductGroupFilter: (productGroupFilters: Array<AttributeFilterModel>) => void
    calculatorRoute?: string
    actions: IActions
    handleArticleAttributeSelect: (article: Article, attribute: ArticleAttribute) => void
    handleRequestArticleDetails: (request: RequestArticleDetailsPayload) => void
    handleRequestArticleDirectSearch: (query: string, searchFilter?: SearchFilters, inModal?: boolean) => void
    articleAlternativesRoute?: string
    handleRequestArticleAlternatives: (article: Article) => void
    handleAddArticleToBasket: (article: Article) => void
    getRepairTimesUrl: (article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => string | undefined
    showActions: boolean
    handleShowArticleFeedback: (article: Article) => void
    showAdditionalPrices?: boolean
    showErpPawnItems?: boolean
    showDocumentsInline?: boolean
    openDocumentsAsModal?: boolean
    showReferenceLinksInCompact?: boolean
    showDocumentsInCompact?: boolean
    matchParams: RouteParams
    isCentralOrder: () => boolean
    partToReplaceId?: string
}

export const ListContent: React.FC<Props> = (props) => {
    const { state, partToReplaceData, partToReplaceErpAlternatives } = props
    const { translateText, date } = useLocalization()
    const articles = props.state.result.parts

    // If new oe result (e.g. Autonet) is loading in parallel wait for it to finish to render (prevent items jumping)
    //
    if (!articles.length) {
        return null
    }

    const { searchType, result, groupParts, vehicleRecords, usedFilters } = state
    const { selected } = result

    if (searchType === SearchType.DIRECT || searchType === SearchType.ALTERNATIVES) {
        let exactMatches = articles.filter((part) => part.isExactMatch)

        if (exactMatches.length) {
            let exactParts = articles
            if (partToReplaceData?.article) {
                const { internalId } = partToReplaceData.article
                exactMatches = exactMatches.filter((part) => part.internalId !== internalId)

                if (
                    Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp &&
                    searchType === SearchType.ALTERNATIVES &&
                    partToReplaceErpAlternatives?.length
                ) {
                    // pass in new array, because the array gets mutated
                    exactParts = mergeDbAlternatives([...partToReplaceErpAlternatives], articles, undefined, false)
                }
            }

            const notExactMatches = exactParts.filter((part) => !part.isExactMatch)
            const firstDescription = translateText(exactMatches.length === 1 ? 806 : 807).replace("%0", exactMatches.length.toString())
            const secondDescription = searchType === SearchType.ALTERNATIVES ? `${translateText(1283)}:` : translateText(808)
            return (
                <>
                    {!!exactMatches.length && (
                        <ContentGroup {...props} sectionHeadline={firstDescription} articles={exactMatches} groupParts={groupParts} />
                    )}
                    {!!notExactMatches.length && (
                        <ContentGroup {...props} sectionHeadline={secondDescription} articles={notExactMatches} groupParts={groupParts} />
                    )}
                </>
            )
        }
        return <ContentGroup {...props} sectionHeadline={translateText(13188)} articles={articles} groupParts={groupParts} />
    }

    if (searchType === SearchType.ARTICLE_IDENTIFIER) {
        const { articleIdentifier } = usedFilters
        if (articleIdentifier && articleIdentifier.some((identifier) => !!identifier?.date)) {
            const groups: {
                date: Date | undefined
                header: string
                productGroups: {
                    id: number
                    parts: Article[]
                }[]
            }[] = []
            articleIdentifier.forEach((identifier) => {
                const matchingArticle = articles.find(
                    (part) =>
                        identifier.supplierId === part.supplier.id &&
                        identifier.supplierArticleNo === part.supplierArticleNo &&
                        identifier.productGroupId === part.productGroup.id &&
                        !!part.vehicleLinkageId
                )
                if (!matchingArticle) {
                    return
                }

                const header = identifier.date ? date(identifier.date, "dd.MM.yyyy HH:mm:ss") : translateText(869)
                let entry = groups.find((x) => x.header === header)
                if (!entry) {
                    entry = {
                        date: identifier.date,
                        header,
                        productGroups: [],
                    }
                    groups.push(entry)
                }
                let productGroup = entry.productGroups.find((x) => x.id === identifier.productGroupId)
                if (!productGroup) {
                    productGroup = { id: identifier.productGroupId, parts: [] }
                    entry.productGroups.push(productGroup)
                }
                productGroup.parts.push(matchingArticle)
            })

            return (
                <>
                    {orderBy(groups, (x) => x.date, "desc").map(({ header, productGroups }) => {
                        const parts = productGroups.flatMap((x) => x.parts)
                        return <ContentGroup {...props} key={header} sectionHeadline={header} articles={parts} />
                    })}
                </>
            )
        }
    }

    if (selected && selected.length && searchType === SearchType.PARTSLIST) {
        const selectedParts = articles.filter((part) => selected.indexOf(getArticleUniqueId(part)) !== -1)
        if (selectedParts && selectedParts.length) {
            const unselectedParts = articles.filter((part) => selected.indexOf(getArticleUniqueId(part)) === -1)
            return (
                <>
                    <ContentGroup sectionHeadline={translateText(752)} articles={selectedParts} groupParts {...props} />
                    <ContentGroup sectionHeadline={translateText(753)} articles={unselectedParts} groupParts {...props} />
                </>
            )
        }
    }

    return (
        <ContentGroup
            groupParts={groupParts}
            articles={articles}
            isLoading={(state.filters.isLoading || state.result.loading || state.articleListIsLoading) && !state.loadingNextPage}
            {...props}
        />
    )
}
