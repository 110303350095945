import { em } from "csx"
import { Button } from "@tm/controls"
import { styled } from "@tm/components"

type Props = {
    onClick(): void
    isInModal?: boolean
    disabled?: boolean
}

const ReplaceButton: React.FC<Props> = ({ onClick, disabled, isInModal }) => {
    return <StyledButton isInModal={isInModal || false} className="price" icon="replace" skin="highlight" onClick={onClick} disabled={disabled} />
}

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== "isInModal" })<{ isInModal: boolean }>(({ isInModal }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: (isInModal && em(6.25)) || em(6),
    marginLeft: "auto",
    marginRight: (isInModal && em(0.5)) || "unset",
}))

export default ReplaceButton
