import { useCallback, useEffect, useMemo, Suspense } from "react"
import { useWorkTask, WorkTaskInfo } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegisteredModels } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { copyToClipboard, renderRoute } from "@tm/utils"
import { useHistory, useLocation, useParams } from "react-router"
import { Button } from "@tm/components"
import { bundleChannel } from "../../business"
import { ShellExportButton } from "./components/ShellExportButton"
import { NavigationState } from "./business"
import { Actions, IActions } from "./business/actions"
import BasketNavigation from "./components/BasketNavigation"
import { CostEstimationNavigation } from "./components/CostEstimationNavigation"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { BasketPart } from "../../models"
import { useShowWorkTaskOrderPdf } from "../../data/hooks/useShowWorkTaskOrderPdf"
import { useCreateCostEstimationVoucher } from "../../data/hooks/useCreateCostEstimationVoucher"
import { useInvalidateWorkTaskBasketQueries } from "../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { useCostEstimationVisible } from "../../hooks/basketState/useCostEstimationVisible"

type Props = {
    state: NavigationState
    actions: IActions
    costEstimationRoute: string
    exportTarget?: string
    mode: "basket" | "checkout" | "cost-estimation"
    partsBasketRoute?: string
    partsDetailBasketRoute: string
    showCopyToClipboardButton?: boolean
    showExportVoucherButton?: boolean
}

function getNumbersAndQuantity(part: BasketPart) {
    const articleNumber = part.partItem.articleInformation.wholesalerArticleNumber ?? part.partItem.articleInformation.articleNumber
    return `${articleNumber || ""};${part.partItem.quantity.value}`
}

function NavigationComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { state, partsDetailBasketRoute, showCopyToClipboardButton, actions, partsBasketRoute, costEstimationRoute, workTask, mode, exportTarget } =
        props
    const { translateText } = useLocalization()

    const matchParams = useParams<{ workTaskId: string; view?: string }>()
    const location = useLocation()
    const history = useHistory()
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const { costEstimation, basket } = useWorkTaskBasketState(workTask.id)
    const { costEstimationVisible } = useCostEstimationVisible(workTask.id)

    const { showWorkTaskOrderPdf, loadingWorkTaskOrderPdf } = useShowWorkTaskOrderPdf(workTask.id)
    const { creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask)

    const showExportVoucherButton = useMemo(() => {
        return props.showExportVoucherButton || props.showExportVoucherButton === undefined
    }, [props.showExportVoucherButton])

    useEffect(() => {
        showExportVoucherButton && actions.loadExportInfos(workTask.id)
    }, [workTask.id, showExportVoucherButton])

    const copyPartsNumberAndQuantityToClipboard = useCallback(() => {
        const parts: Array<string> = []
        const basketParts = basket.state.basketOrderGroups?.flatMap((basketOrderGroup) => basketOrderGroup.basketParts)
        basketParts?.forEach((part) => {
            parts.push(getNumbersAndQuantity(part))
            part.linkedItems?.forEach((linkedItem) => {
                parts.push(getNumbersAndQuantity(linkedItem))
            })
        })

        if (parts.length) {
            copyToClipboard(parts.join("\n"))
        }
    }, [basket.state.basketOrderGroups])

    function handleCostEstimationButtonClick() {
        Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
            .subscribe("COST_ESTIMATION_VISIBLE")
            .save?.({ key: `${matchParams.workTaskId}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        bundleChannel().publish("ENABLE_COST_ESTIMATION", {})
        const url = renderRoute(costEstimationRoute, { ...matchParams })
        history.push(url)

        // When the KVA is opened for the first time, we need to invalidate both queries.
        if (costEstimationVisible === false) {
            invalidateAllWorkTaskBasketRequests(workTask.id)
        }
    }

    function renderExportToolbar() {
        const buttons = []

        if (state.exportUri) {
            buttons.push(
                <Button
                    onClick={() => {
                        actions.closeWorkTask(workTask.id)
                        window.location.href = state.exportUri!
                    }}
                    key="uri-export"
                >
                    {state.exportLabel || exportTarget || translateText(1875)}
                </Button>
            )
        }

        const shellExportButtonRenders = !!ShellExportButton({})
        if (shellExportButtonRenders) {
            buttons.push(<ShellExportButton key="shell-export" />)
        }

        if (!buttons.length) {
            return
        }

        return <Toolbar title={translateText(1209)}>{buttons}</Toolbar>
    }

    function renderNavigation() {
        if (mode === "cost-estimation") {
            const costEstimationUrl = renderRoute(costEstimationRoute, matchParams)
            const costEstimationIsActive = location.pathname === costEstimationUrl
            const detailsIsActive = matchParams.view === partsDetailBasketRoute

            return (
                <CostEstimationNavigation
                    canBeCreated={!costEstimation.state.costEstimationLoading}
                    costEstimationIsActive={costEstimationIsActive}
                    costEstimationUrl={costEstimationUrl}
                    creatingCostEstimation={creatingCostEstimationVoucher}
                    customerEmail={workTask.customer?.email}
                    detailsIsActive={detailsIsActive}
                    hasItems={costEstimation.state.hasItems}
                    partsDetailRoute={partsDetailBasketRoute}
                    useRepairTimeCalculation={costEstimation.state.costEstimation?.useRepairTimeCalculation}
                    onChangeRepairTimeCalculation={costEstimation.actions.calculateWorks}
                    onGenerateCostEstimationLink={actions.generateCostEstimationLink}
                    onPrintCostEstimation={actions.printCostEstimation}
                    onSendCostEstimation={actions.sendCostEstimation}
                    onTogglePrintVehicleImage={actions.togglePrintVehicleImage}
                    onToggleShowArticleNumbers={actions.toggleShowArticleNumbers}
                    onToggleShowManufacturers={actions.toggleShowManufacturers}
                    onToggleShowWholesalerArticleNumbers={actions.toggleShowWholesalerArticleNumbers}
                />
            )
        }

        const partsUrl = renderRoute(partsBasketRoute!, matchParams)
        const partsIsActive = location.pathname === partsUrl
        const detailsIsActive = matchParams.view === partsDetailBasketRoute
        return (
            <BasketNavigation
                costEstimationRoute={costEstimationRoute}
                detailsIsActive={detailsIsActive}
                hasParts={basket.state.hasItems ?? false}
                loadingWorkTaskOrderPdf={loadingWorkTaskOrderPdf}
                basketLoading={basket.state.basketLoading}
                partsDetailBasketRoute={partsDetailBasketRoute}
                partsIsActive={partsIsActive}
                partsUrl={partsUrl}
                showCopyToClipboardButton={showCopyToClipboardButton}
                onCopyPartsNumberAndQuantityToClipboard={copyPartsNumberAndQuantityToClipboard}
                onCostEstimationButtonClick={handleCostEstimationButtonClick}
                onPartsWarningConfirm={() => basket.actions.removeAllParts()}
                onPrintButtonClick={() => showWorkTaskOrderPdf()}
                onAddWholesalerArticlesToBasket={basket.actions.addWholesalerParts}
            />
        )
    }

    return (
        <>
            {renderNavigation()}
            {renderExportToolbar()}
        </>
    )
}

function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <NavigationComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
