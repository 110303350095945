import { Button, Icon, styled } from "@tm/components"

export const BasketButton = styled(Button)({
    width: 90,
    "&.MuiButton-bordered": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
})
BasketButton.defaultProps = {
    color: "highlight",
    variant: "bordered",
    startIcon: <Icon name="cart" />,
}
