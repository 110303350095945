import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import component from "./component"

const navigation: BundleComponent<unknown, typeof component> = {
    name: "navigation",
    reduce,
    component,
    transmit,
}

export default navigation
