import { useWorkTask } from "@tm/context-distribution"
import { useCallback, useMemo } from "react"
import { Article, ErpInformation } from "@tm/models"
import { mapArticleToAddCatalogPartListRequest, useErpConfig } from "@tm/utils"
import { useBasketMemo } from "../../../../../../basket/src/hooks/useBasketMemo"
import { useWarehouse } from "../../../../../../basket/src/data/hooks/useWarehouse"
import { SearchType } from "../../../../business"
import { useVehicle } from "../useVehicle"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"
import { useDefaultErpSystem } from "../useDefaultErpSystem"

export function useHandleAddToBasket(article: Article | Article[]) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { customer } = workTask ?? {}
    const vehicle = useVehicle()
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const defaultErpSystem = useDefaultErpSystem()

    const inputArticle = useMemo(() => {
        return Array.isArray(article) ? article : [article]
    }, [article])

    // TODO: Searchtyp anpassen,
    const basketMemo = useBasketMemo(workTask, SearchType.PARTSLIST)
    const warehouseData = useWarehouse(inputArticle[0]?.id, defaultErpSystem?.id)
    const { addCatalogPartList } = useBasketParts()

    const handleAddToBasket = useCallback(
        (quantity: number, erpInfo?: ErpInformation[] | ErpInformation | null, erpSystemId?: number) => {
            if (!workTaskId) {
                return Promise.reject()
            }

            // send erpSystem only when useOrderByDistributor is true
            const erpSystem = useOrderByDistributor ? erpSystemConfigs?.find((x) => x.id === erpSystemId) ?? defaultErpSystem : undefined

            const addToBasketArticle = inputArticle.length === 1 ? [{ ...inputArticle[0], quantity }] : inputArticle

            let erpInfoArray = erpInfo as ErpInformation[]
            if (erpInfo && !Array.isArray(erpInfo)) {
                erpInfoArray = [erpInfo]
            }
            const request = mapArticleToAddCatalogPartListRequest(
                addToBasketArticle,
                workTaskId,
                vehicle?.id,
                customer?.id,
                undefined,
                basketMemo.position,
                erpInfoArray,
                warehouseData.warehouse,
                undefined,
                erpSystem?.id,
                erpSystem?.description
            )
            return addCatalogPartList(request)
        },
        [
            workTaskId,
            useOrderByDistributor,
            erpSystemConfigs,
            defaultErpSystem,
            inputArticle,
            vehicle?.id,
            customer?.id,
            basketMemo.position,
            warehouseData.warehouse,
            addCatalogPartList,
        ]
    )
    return handleAddToBasket
}
