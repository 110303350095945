import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { OE } from "@tm/models"
import { ArticleItemStateProvider, useArticleItemState } from "."
import { OeArticleItemState } from "./models"
import { useOeBasketQuantities } from "../../../hooks/useBasketQuantities"
import { useHandleAddOeToBasket } from "../../../hooks/useArticleItem/useHandleAddOeToBasket"
import { useHandleRemoveFromBasket } from "../../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { isSameOeArticle, mapOeArticleDtoFromOePart } from "../../../helpers"

type Props = {
    article: OE.OePart
}

export function OeArticleItemStateProvider({ article, children }: PropsWithChildren<Props>) {
    const [quantity, setQuantity] = useState<number>(article?.quantity || 1)
    const articleList = useMemo(() => [article], [article])

    const { basketQuantities, updateBasketQuantities } = useOeBasketQuantities(articleList, true)

    const handleChangeQuantity = useCallback((value: number) => setQuantity(value), [])
    const handleAddToBasketInternal = useHandleAddOeToBasket(article)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(() => {
        const oeArticleDto = mapOeArticleDtoFromOePart(article)
        return basketQuantities.find((q) => isSameOeArticle(q.article, oeArticleDto))
    }, [basketQuantities, article])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            updateBasketQuantities([article])
        },
        [handleAddToBasketInternal, quantity, article, updateBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateBasketQuantities([article])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateBasketQuantities, article])

    const state: OeArticleItemState = {
        type: "OE",
        article,
        isVehicleDependent: false,
        quantity,
        originalQuantity: quantity,
        basketQuantity,
        oeArticleNo: article.number,
        wholesalerArticleNumber: article.traderNumber ?? "",
        thumbnailUrl: "/styles/base/images/oe-part-logo.svg",
        manufacturerThumbnail: article.thumbnailUrl,
        handleChangeQuantity,
        handleAddToBasket,
        handleRemoveFromBasket,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}

export function useOeArticleItemState(): OeArticleItemState
export function useOeArticleItemState<TReturnType>(selector: (value: OeArticleItemState) => TReturnType): TReturnType
export function useOeArticleItemState<TReturnType>(selector?: (value: OeArticleItemState) => TReturnType): OeArticleItemState | TReturnType {
    return useArticleItemState<OeArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
