import { Button, Loader, Scrollbar, Toolbar } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { useActions } from '@tm/morpheus'
import { classes, clone, getValue, setValue } from '@tm/utils'
import * as React from 'react'
import { batch, useSelector } from 'react-redux'
import { RimFilter } from '../../../data/model'
import { MainState } from '../../main'
import { AttributeSelectionCheckboxes, DropDownWrapper, FilterComponent, ResetButtonFromState, SelectionCheckbox } from '../../_shared'
import { Actions } from '../business'
import { addOrRemoveFilter } from '../business/helper'
import { SelectedFilters } from '../business/model'
import { FilterType } from '../../../data/helpers'
import { AvailabilityFilterType } from '../../../business'
import { getBundleParams } from '../../../utils'
import { useUser } from '@tm/context-distribution'

type Props = {}

const selector = (s: MainState) => ({
    selectedFilters: s.wheelsList.base.selectedFilters,
    filters: s.wheelsList.base.filters,
    articlesLoading: s.wheelsList.base.articles.loading,
    articlesError: s.wheelsList.base.articles.error,
    displayStateReset: s.wheelsList.base.displayStateReset,
    availabilityFilter: s.wheelsList.base.availabilityFilter
})

const RimFilters: React.FC<Props> = ({ }) => {
    const { translateText } = useLocalization()
    const isHostettler = getBundleParams()?.isHostettler
    const actions = useActions(Actions, "updateSelectedFilters", "loadRimFilterAndArticles", "changeAvailabilityFilter", "loadRimFilters")
    const { selectedFilters, filters, articlesLoading, articlesError, displayStateReset, availabilityFilter } = useSelector(selector)
    const { manufacturers, widths, offsets, inches, colors, designs, winterprofed, loading, snowChainsCompatible, noModification, ece, OE, VSA } = filters
    const [isOpen, setIsOpen] = React.useState<boolean>(true)
    const displayAvailabilityFilter = getBundleParams().priceAvailabilityInformation ? !useUser()?.userSettings?.hideWheelsAvailability : false
    
    const handleDeselectFilter = (path: string, updatedSelectedFilters?: SelectedFilters) => {
        const newSelectedFilters = updatedSelectedFilters ?? setValue({ ...selectedFilters }, [path], "")
        batch(() => {
            actions.updateSelectedFilters(newSelectedFilters)
            actions.loadRimFilterAndArticles(false)
        })
    }

    function handleSelectFilter(path: string, filter: string): void
    function handleSelectFilter(path: string, filter: RimFilter): void
    function handleSelectFilter(path: string, filter: RimFilter | string): void {
        if (typeof (filter) !== "string" && filter.attributeId == -1) //reset when we click on inch/width/offset
            filter.attributeValue = ""

        const filterValue = typeof (filter) === "string" ? filter : filter.attributeValue

        let newSelectedFilters = clone(selectedFilters)
        if (["-100", "-104", "-105"].includes(path)) {
            const lastManufacurers: string = getValue({ ...selectedFilters }, [path])
            newSelectedFilters = setValue({ ...selectedFilters }, [path], addOrRemoveFilter(filterValue, lastManufacurers))
        }
        else
            newSelectedFilters = setValue({ ...selectedFilters }, [path], filterValue)

        batch(() => {
            actions.updateSelectedFilters(newSelectedFilters)
            actions.loadRimFilters()
            actions.loadRimFilterAndArticles(false)
        })
    }

    const handleResetButton = (e: React.MouseEvent<HTMLElement>, filter: string) => {
        e.stopPropagation()
        handleDeselectFilter(filter)
    }

    const handleDimensionsReset = React.useCallback(() => {
        let resetFilters = { ...selectedFilters };
        ["74", "73", "2217"].forEach(x => setValue(resetFilters, [x], ""))

        handleDeselectFilter("", resetFilters)
    }, [selectedFilters])

    const handleDropDownSelectionReset = (path: string) => {
        handleDeselectFilter(path)
    }

    const handleCollapsibleChange = () => {
		setIsOpen(!isOpen)
	}

    const renderAttributeSelectionCheckboxes = () => {
        const manufacturerFilter = 
            <AttributeSelectionCheckboxes
                collapsibleText={translateText(71)}
                text={translateText(1558)}
                filters={manufacturers}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const designFilter = 
            <AttributeSelectionCheckboxes
                initiallyClosed
                collapsibleText={translateText(994)}
                text={translateText(994)}
                filters={designs}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const colorFilter = 
            <AttributeSelectionCheckboxes
                initiallyClosed
                collapsibleText={translateText(944)}
                text={translateText(944)}
                filters={colors}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const VSAOEFilter =
            <AttributeSelectionCheckboxes
                collapsibleText={translateText(397)}
                text={translateText(1558)}
                filters={[...winterprofed, ...snowChainsCompatible, ...noModification, ...ece, ...(OE || []), ...(VSA || [])].filter((thing, i, arr) => {
                    return arr.indexOf(arr.find(t => t.attributeId === thing.attributeId && t.attributeValue != "0") || arr[0]) === i
                })}
                onChange={handleSelectFilter}
                selectedFilters={selectedFilters}
                disabledCollapsible={!manufacturers.length}
            />

        const renderAvailabilityFilter = () => {
            const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()

            if (!availabilityFilterItemsToShow?.length && !availabilityFilterItemsToShowSecondary?.length) {
                return null
            }
    
            const primarySelected = availabilityFilter === AvailabilityFilterType.Primary
            const secondarySelected = availabilityFilter === AvailabilityFilterType.Secondary
    
            return (
                <FilterComponent
                    filterId={FilterType.Availability}
                    title={translateText(412)}
                    onCollapsibleChange={handleCollapsibleChange}
                    active={isOpen}
                    onReset={() => actions.changeAvailabilityFilter(AvailabilityFilterType.None)}
                    loading={filters.loading}
                >
                    {
                        !!availabilityFilterItemsToShow?.length &&
                        <SelectionCheckbox
                            label={translateText(1623).toUpperCase()}
                            selected={primarySelected}
                            onChange={() => actions.changeAvailabilityFilter(primarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Primary)}
                            blockModifier
                            compactStyle
                        />
                    }
                    {
                        !!availabilityFilterItemsToShowSecondary?.length &&
                        <SelectionCheckbox
                            label={translateText(12860).toUpperCase()}
                            selected={secondarySelected}
                            onChange={() => actions.changeAvailabilityFilter(secondarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Secondary)}
                            blockModifier
                            compactStyle
                        />
                    }
                </FilterComponent>
            )
        }

        if (isHostettler) {
            return <> {VSAOEFilter} {colorFilter} {designFilter} {manufacturerFilter} {displayAvailabilityFilter && renderAvailabilityFilter()}</>
        } else {
            return <> {manufacturerFilter} {colorFilter} {designFilter} {VSAOEFilter} {displayAvailabilityFilter && renderAvailabilityFilter()}</>
        }
    }

    if (articlesError)
        return null

    if (loading)
        return <div className="wheels-list_filters">
            <div className="article-list__panel article-list__status">
                <Loader />
            </div >
        </div>

    return (
        <div className={classes(`wheels-list_filters`, articlesLoading && "loading_cover")}>
            {displayStateReset && <ResetButtonFromState onReset={handleDimensionsReset} />}
            <Scrollbar>
                <Toolbar className="diameter-filters">
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={"2217"}
                        items={[{ attributeValue: translateText(715), attributeId: -1, info: "2217" }, ...inches]}
                        selectedValue={inches.find(x => x.attributeValue == selectedFilters[2217])}
                        onChange={handleSelectFilter}
                    />
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={"74"}
                        items={[{ attributeValue: translateText(713), attributeId: -1, info: "74" }, ...widths]}
                        selectedValue={widths.find(x => x.attributeValue == selectedFilters[74])}
                        onChange={handleSelectFilter}
                    />
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={"73"}
                        items={[{ attributeValue: translateText(982), attributeId: -1, info: "73" }, ...offsets]}
                        onChange={handleSelectFilter}
                        selectedValue={offsets.find(x => x.attributeValue == selectedFilters[73])}
                    />
                    <Button layout={['ghost']} size="s" disabled={!selectedFilters[74] && !selectedFilters[2217] && !selectedFilters[73]} onClick={handleDimensionsReset} icon="synchronize" />
                </Toolbar>
                {renderAttributeSelectionCheckboxes()}
            </Scrollbar>
        </div>
    )
}

export default RimFilters