import { Skeleton, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErpInformation, ErpSystemConfig, PriceType } from "@tm/models"
import { useMemo, useState } from "react"
import Availability from "../../../../../../../erp/src/_shared/availability-wrapper"
import { useTraderErpInfos } from "../../../hooks/useArticleItem/useTraderErpInfos"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { PriceValue } from "../ErpContainer/PriceValue"
import { AddToBasket } from "../Transactions/AddToBasket"
import { CostEstimationButton } from "../Transactions/CostEstimationButton"

type TraderActionsColumnProps = {
    erpSystem: ErpSystemConfig
    traderArticleNumber: string
    erpInformation?: ErpInformation
}

export function TraderActionsColumn({ erpInformation, erpSystem }: TraderActionsColumnProps) {
    const { translateText } = useLocalization()
    const article = useDefaultArticleItemState((x) => x.article)
    const tradeReferences = useDefaultArticleItemState((x) => x.tradeReferences)
    const [quantity, setQuantity] = useState(article.quantity)
    const hasSuggestedQuantity = useDefaultArticleItemState((x) => x.hasSuggestedQuantity)
    const addToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)
    const addToCostEstimation = useDefaultArticleItemState((x) => x.handleAddToCostEstimation)
    const removeFromBasket = useDefaultArticleItemState((x) => x.handleRemoveFromBasket)
    const { hideCostEstimationButton, highlightCostEstimationButton } = useDefaultArticleItemState((x) => x.options)
    const articleErpInfo = useDefaultArticleItemState((x) => x.articleErpInfos).default
    const basketQuantities = useDefaultArticleItemState((x) => x.basketQuantity)
    const traderErpInfos = useTraderErpInfos(article, tradeReferences)
    const purchasePrice = erpInformation?.prices?.find((x) => x.type === PriceType.Purchase)
    const retailPrice = erpInformation?.prices?.find((x) => x.type === PriceType.Retail || x.type === PriceType.RecommendedRetail)

    const division = useMemo(() => (articleErpInfo?.state === "success" ? articleErpInfo.response.quantity?.division : 1) || 1, [articleErpInfo])
    const basketQuantity = useMemo(
        () => basketQuantities?.articleQuantities?.orderQuantityGroups.find((x) => x.distributorId === erpSystem.id),
        [basketQuantities, erpSystem.id]
    )

    return (
        <Stack direction="row" gap={2} alignItems="center">
            {traderErpInfos.isLoading && (
                <Stack direction="row" gap={1}>
                    <Stack gap="2px">
                        <Skeleton variant="rounded" width={60} height={20} />
                        <Skeleton variant="rounded" width={60} height={20} />
                    </Stack>
                    <Skeleton variant="rounded" width={60} height={42} />
                </Stack>
            )}
            {!traderErpInfos.isLoading && erpInformation && (
                <>
                    <Stack alignItems="flex-end">
                        {purchasePrice && (
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
                                    {translateText(55)}
                                </Typography>
                                <PriceValue price={purchasePrice} />
                            </Stack>
                        )}
                        {retailPrice && (
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
                                    {translateText(1620)}
                                </Typography>
                                <PriceValue price={retailPrice} />
                            </Stack>
                        )}
                    </Stack>
                    <Availability
                        availability={erpInformation.availability}
                        requestedQuantity={erpInformation.quantity?.requestedValue}
                        warehouses={erpInformation.warehouses}
                        tour={erpInformation.tour}
                        erpSystem={erpSystem}
                    />
                </>
            )}
            <Stack direction="row" gap={0.5}>
                <AddToBasket
                    originalQuantity={quantity}
                    division={division}
                    handleChangeQuantity={setQuantity}
                    onAddToBasket={() => addToBasket(quantity, erpInformation, erpSystem.id)}
                    onRemoveFromBasket={() => basketQuantity?.partItemIds && removeFromBasket(basketQuantity.partItemIds)}
                    basketQuantity={basketQuantity?.quantityValue}
                    hasSuggestedQuantity={hasSuggestedQuantity}
                />
                {!hideCostEstimationButton && (
                    <CostEstimationButton
                        onAddToCostEstimation={() => addToCostEstimation(quantity, erpInformation, erpSystem.id)}
                        highlightCostEstimationButton={highlightCostEstimationButton}
                    />
                )}
            </Stack>
        </Stack>
    )
}
