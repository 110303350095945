import { useState, useEffect } from "react"
import { Button, Loader, NumberField, Badge } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import UserSettingsArea from "./UserSettingsArea"
import { SetDutchOrdersMDMParamsRequest } from "../../../data/model"

type Props = {
    handleSetMDMParams(request: SetDutchOrdersMDMParamsRequest): void
    loaded?: boolean
    infoLoading?: boolean
    companyInfoError?: boolean
}

export default function RdwCompanyInfo(props: Props) {
    const [clientNumber, setClientNumber] = useState<number>(0)
    const [customerRef, setCustomerRef] = useState<number>(0)

    const { translateText } = useLocalization()

    useEffect(() => {
        if (props.loaded) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                skin: "success",
                icon: "info",
                message: translateText(519),
            })
        }

        if (props.companyInfoError) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                skin: "danger",
                icon: "danger",
                message: translateText(787),
            })
        }
    }, [props.loaded, props.companyInfoError])

    const handleReset = () => {
        setCustomerRef(0)
        setClientNumber(0)
    }

    function handleSave() {
        const mandatorNumber = clientNumber.toString()
        const customerReferenceNumber = customerRef.toString()

        props.handleSetMDMParams({ mandatorNumber, customerReferenceNumber })
    }

    return (
        <UserSettingsArea id="user-settings__area__rdw-company-info" title="RDW Company Info">
            <Button onClick={handleSave} disabled={!clientNumber || !customerRef || props.infoLoading} skin="success">
                {props.infoLoading && <Badge skin="dark" value={<Loader />} />}
                {translateText(9)}
            </Button>
            <Button onClick={handleReset} disabled={!clientNumber && !customerRef} skin="danger">
                {translateText(48)}
            </Button>

            <div className="user-settings__area__row">
                <NumberField value={clientNumber} onChange={setClientNumber} disabled={false} label={translateText(12477)} floatingLabel />
                <NumberField value={customerRef} onChange={setCustomerRef} disabled={false} label={translateText(12478)} floatingLabel />
            </div>
        </UserSettingsArea>
    )
}
