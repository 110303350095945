import { Article } from "@tm/models"
import { useErpConfig } from "@tm/utils"
import { useMemo } from "react"
import { useRecoilValue } from "recoil"
import { ErpInfosState } from "../../states"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleErpInfoFactory, ArticleErpInfos } from "../useErpInfos"

/**
 * Try to get the already loaded ERP data for a list of articles.
 * For one article only use `useArticleErpInfo` instead.
 */
export function useArticlesErpInfos(articles: Article[], quantity: number): ArticleErpInfos[] {
    const erpInfos = useRecoilValue(ErpInfosState)
    const { erpSystemConfigs } = useErpConfig()
    const defaultErpSystemId = useDefaultErpSystem()?.id
    const alternativeErpSystemId = erpSystemConfigs?.find((x) => !x.isDefaultGetErpInfoSystem)?.id

    return useMemo(() => {
        if (!defaultErpSystemId) {
            return []
        }

        return articles.map<ArticleErpInfos>((article) => {
            const defaultErpInfo = erpInfos.find(
                ({ key }) => key.id === ArticleErpInfoFactory.createKeyFromArticle({ ...article, quantity }, defaultErpSystemId).id
            )
            const alternativeErpInfo = alternativeErpSystemId
                ? erpInfos.find(
                      ({ key }) => key.id === ArticleErpInfoFactory.createKeyFromArticle({ ...article, quantity }, alternativeErpSystemId).id
                  )
                : undefined

            return {
                default: defaultErpInfo,
                alternative: alternativeErpInfo,
            }
        })
    }, [defaultErpSystemId, articles, erpInfos, alternativeErpSystemId, quantity])
}

/**
 * Try to get the already loaded ERP data for one article.
 * For multiple articles use `useArticlesErpInfo` instead.
 */
export function useArticleErpInfos(article: Article, quantity: number): ArticleErpInfos {
    const articles = useMemo(() => [article], [article])

    return useArticlesErpInfos(articles, quantity).first() ?? { default: undefined, alternative: undefined }
}
