import { PropsWithChildren, UIEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Scrollbar } from "@tm/controls"
import { initSelector } from "@tm/utils"
import { useParams } from "react-router"
import { Box, styled } from "@tm/components"
import { EreSelectionSteps, ICalculationItem } from "../../data/models"
import Calculation from "../calculation/component"
import Extern from "../extern/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Summary from "../summary/component"
import { initialSparePartsPriceSelector, itemsSelector } from "./business"
import { MatchParams } from "../summary/components/models"

type Props = {
    defaultRoute: string
    detailsTabRoute: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
function WrappedModule({ children }: PropsWithChildren<{}>) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "MODULE_OPENED",
            payload: {
                icon: "damage-calculation",
                title: "{{13553}}",
            },
            broadcast: true,
        })
    }, [])

    return <>{children}</>
}

const selector = initSelector(itemsSelector, initialSparePartsPriceSelector)

export default function Main({ defaultRoute, detailsTabRoute }: Props) {
    const matchParams = useParams<MatchParams>()
    let scrollTimeoutId: number | undefined
    const [showPricesInHeader, setShowPricesInHeader] = useState(false)
    const [items, initialSparePartsPrice] = useSelector(selector)
    const [hasAdditional, setHasAdditional] = useState(false)
    const showInitialSparePartsPrice = items ? items?.filter((x: ICalculationItem) => !!x?.selectedPart)?.length > 0 : false

    useEffect(() => {
        initialSparePartsPrice && setHasAdditional(showInitialSparePartsPrice)
    }, [initialSparePartsPrice, showInitialSparePartsPrice])

    const handleScroll = (e: UIEvent<HTMLElement> | UIEvent) => {
        window.clearTimeout(scrollTimeoutId)

        scrollTimeoutId = window.setTimeout(() => {
            setShowPricesInHeader((e.target as HTMLElement).scrollTop >= 125)
        }, 100)
    }

    return (
        <StyledWrapperBox>
            <Navigation defaultRoute={defaultRoute} detailsTabRoute={detailsTabRoute} showPrice={showPricesInHeader} hasAdditional={hasAdditional} />
            <ModuleManager />
            <Box className="content">
                {matchParams.view === EreSelectionSteps.EXTERN && (
                    <WrappedModule>
                        <Extern />
                    </WrappedModule>
                )}
                {matchParams.view === EreSelectionSteps.SUMMARY && (
                    <WrappedModule>
                        <Summary />
                    </WrappedModule>
                )}
                {matchParams.view === EreSelectionSteps.CALCULATION && (
                    <WrappedModule>
                        <Scrollbar onScroll={handleScroll}>
                            <Calculation />
                        </Scrollbar>
                    </WrappedModule>
                )}
            </Box>
        </StyledWrapperBox>
    )
}

const StyledWrapperBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    ".content": {
        padding: theme.margin?.m,
        flex: 1,
        display: "flex",

        "& .loader-spinner--visible": {
            left: "50%",
            top: "50%",
            position: "absolute",
            height: "2em",
            width: "2em",
        },
    },
}))
