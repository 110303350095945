import { em, percent } from "csx"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStyleTheme, useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Demo, Loader, Widget, WidgetProps, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModuleOptionType } from "@tm/models"
import { RouteComponentProps, classes, renderRoute, useGetActivatableModules, withRouter } from "@tm/utils"
import { ECalcState } from "../../data/enums"
import { SelectedCalcStateSelector, StatusSelector, VehicleSelector, bem, getLabourRate } from "../../data/helpers"
import { CalculationWidgetContext } from "../../data/model"
import { hasFastCalculatorData } from "../../helpers"
import { CalcStateButton, ErrorMessage } from "../_shared"
import { MainState } from "../main"
import { Actions } from "./business"
import LastCalculationComponent from "./components/lastCalculation"
import SummaryPreview from "./components/summaryPreview"

type Props = RouteComponentProps & {
    fastCalculatorRoute: string
    height?: number
    className?: string
    size?: WidgetSizes
}

const useDemo = (): boolean => {
    const { userContext } = useUser() ?? {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    return !!activatableModulesLoadable.response?.moduleGroups
        ?.find((x) => x.id == "fastCalculator")
        ?.modules?.find((x) => x.id.toLowerCase() == "topmotive")
        ?.moduleOptions.find((moduleOption) => moduleOption.type == ModuleOptionType.Demo)?.active
}

function WidgetComp(props: Props) {
    const activeWorkTask = useWorkTask()
    const userSettings = useUser()
    const localization = useLocalization()
    const isFCDemo = useDemo()
    const dispatch = useDispatch()
    const style = useMemo(() => getStyle(), [])

    const { selectedCalcState } = useSelector(SelectedCalcStateSelector)
    const { vehicle } = useSelector(VehicleSelector)
    const { error: isError, loading: isLoading } = useSelector(StatusSelector)
    const { engineCode, initialRegistration, longLife, mileage } = useSelector((s: MainState) => s.widget.vehicleDetails)

    const [activeState, setActiveState] = useState(false)
    const [hasFastCalculator, setHasFastCalculator] = useState(false)

    const workTaskVehicle = activeWorkTask?.workTask?.vehicle

    useEffect(() => {
        if (workTaskVehicle?.dataAvailabilities) {
            setHasFastCalculator(hasFastCalculatorData(workTaskVehicle))
        }
    }, [workTaskVehicle])

    useEffect(() => {
        if (activeState) {
            if (workTaskVehicle?.id != vehicle?.id) {
                dispatch(Actions.setVehicle(workTaskVehicle!))
                dispatch(Actions.initFastCalculator(true))
            } else if (
                vehicle &&
                selectedCalcState &&
                !(selectedCalcState?.type & ECalcState.PresentableCalcState) &&
                selectedCalcState?.type != ECalcState.InitialData
            ) {
                // special case
                dispatch(Actions.initFastCalculator(true))
            }
            // when switching tabs FastCalc should be invoked to retrieve the calc state for widget
            else if (
                selectedCalcState?.type &&
                selectedCalcState?.type != ECalcState.InitialData &&
                !(ECalcState.WidgetCalcState & selectedCalcState?.type)
            ) {
                dispatch(Actions.initFastCalculator(true))
            }
            // usually when selected calc state is 1 we need to invoke submitInitialData
            else {
                shouldInitialDataSubmit() && dispatch(Actions.setVehicle(workTaskVehicle!))
                setInitialData()
            }
        }
    }, [activeState, selectedCalcState?.type])

    const shouldInitialDataSubmit = (): boolean => {
        if (!workTaskVehicle || !vehicle) {
            return false
        }

        return (
            (workTaskVehicle.engineCode || "") != (vehicle.engineCode || "") ||
            (workTaskVehicle.engineCode || "") != (engineCode || "") ||
            workTaskVehicle.initialRegistration?.toString() != vehicle.initialRegistration?.toString() ||
            workTaskVehicle.initialRegistration?.toString() != initialRegistration?.toString() ||
            workTaskVehicle.mileAge != vehicle.mileAge ||
            workTaskVehicle.mileAge != mileage ||
            workTaskVehicle.longlife != vehicle.longlife ||
            workTaskVehicle.longlife != longLife
        )
    }

    const setInitialData = () => {
        if (selectedCalcState?.type == ECalcState.InitialData || shouldInitialDataSubmit()) {
            dispatch(Actions.initialData(localization.languageId, getLabourRate(userSettings?.userSettings), true))
        }
    }
    // #region useless
    const handleWidgetClick = () => {
        if (!activeState) {
            setActiveState(true)
        }
    }

    const handleChangeRoute = (calcStateType?: number) => {
        const { match, history, fastCalculatorRoute } = props

        if (calcStateType == ECalcState.InitialData) {
            setInitialData()
        }

        const fastCalcUrl = renderRoute(fastCalculatorRoute, { ...match.params })
        history.push(fastCalcUrl)
    }

    const renderCover = () => {
        return (
            <div className={style.centered}>
                {isFCDemo && <Demo className={style.demo} displayMode="edge" />}
                <div style={{ width: workTaskVehicle ? "12em" : "50%" }} className={classes("fast-calculator-next_logo", style.logo)} />
            </div>
        )
    }

    const renderFooter = () => {
        const { fastCalculatorRoute, match } = props
        const fastCalcUrl = renderRoute(fastCalculatorRoute, { ...match.params })
        const buttonText = selectedCalcState?.context?.chooseServiceButtonLabel
        const buttons = selectedCalcState?.buttonGroups

        if (selectedCalcState?.type == ECalcState.LastCalcPreviewNext || !buttons?.length) {
            return
        }

        return (
            <>
                {!isLoading && (
                    <div className={style.widgetActions}>
                        {buttons.map((buttonGroup: any) =>
                            buttonGroup?.buttons?.map((button: any) => (
                                <CalcStateButton item={button} key={button.id} changeFCRoute={handleChangeRoute} isFromWidget />
                            ))
                        )}
                        <Button linkTo={fastCalcUrl} size="m">
                            {buttonText}
                        </Button>
                    </div>
                )}
            </>
        )
    }

    const getWidgetProps = () => {
        const widgetClassName = bem(style.widget, props.className, !hasFastCalculator && "disabled", activeState && isLoading && "loading")

        let widgetProps: WidgetProps = {
            className: widgetClassName,
            cover: renderCover(),
            size: props.size || "4x2",
        }

        if (workTaskVehicle) {
            widgetProps = {
                ...widgetProps,
                iconName: "service-calculator",
                title: "Fast Calculator",
                active: hasFastCalculator && activeState,
                onClick: handleWidgetClick,
                footer: renderFooter(),
            }
        }

        return widgetProps
    }

    const renderContent = () => {
        let content

        switch (selectedCalcState?.type) {
            case ECalcState.FastCockpitPreviewNext:
                content = workTaskVehicle && (
                    <div className={style.centered}>
                        <SummaryPreview />
                    </div>
                )
                break
            case ECalcState.LastCalcPreviewNext:
                content = (
                    <div className={style.centered}>
                        <LastCalculationComponent
                            fastCalculatorRoute={props.fastCalculatorRoute}
                            changeFCRoute={handleChangeRoute}
                            selectedContext={selectedCalcState?.context as CalculationWidgetContext | undefined}
                            selectedCalcState={selectedCalcState}
                        />
                    </div>
                )
                break
            default:
                break
        }

        if (isError) {
            content = <ErrorMessage titleID={787} descriptionID={401} />
        }

        return content
    }
    // #endregion

    if (!workTaskVehicle || !hasFastCalculator) {
        return <Widget height={props.height} {...getWidgetProps()} />
    }
    // else if ( isLoading)
    //     return <Widget active height={props.height} className={bem(style.widget, "loading")}><Loader visible={activeState} /></Widget>

    return (
        <Widget height={props.height} {...getWidgetProps()}>
            {isFCDemo && <Demo className={style.demo} displayMode="edge" />}
            {isLoading ? <Loader className={style.widgetLoader} /> : renderContent()}
        </Widget>
    )
}

export default withRouter(WidgetComp)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        widget: {
            $nest: {
                "&--loading .widget__content": {},
                "&--disabled": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                },
                "&--loading": {
                    pointerEvents: "none",
                    $nest: {
                        ">:not(.loader)": {
                            opacity: theme.opacity.disabled,
                        },
                    },
                },
                ".widget__content > div": {
                    flexWrap: "wrap",
                },
                "&--hostettler-fast-calc": {
                    flex: "1 1 auto",
                },
            },
        },
        centered: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
        },
        logo: {
            height: em(12),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        },
        widgetActions: {
            display: "flex",
            flexWrap: "wrap",
            $nest: {
                ".btn": {
                    marginBottom: theme.margin.m,
                },
            },
        },
        widgetLoader: {
            width: em(1.5),
            height: em(1.5),
            position: "absolute",
            top: percent(50),
            left: percent(50),
            zIndex: 1,
        },
        demo: {
            zoom: 3,
            position: "absolute",
            top: 0,
            right: 0,
        },
    })(WidgetComp)
}
