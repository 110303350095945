import { channel, RegistrationNoType, SystemType, TitleType, Vehicle } from "@tm/models"
import { ModelState, validateField } from "@tm/utils"
import { ReactText } from "react"
import { DatFields } from ".."
import { getBundleParams } from "../../../../utils"

export const validateFields = (
    fields: DatFields,
    translateText: (key: ReactText) => string,
    vehicle?: Vehicle,
    isGermanOrAustrianVehicle?: boolean
): ModelState => {
    const { enableDATVinSearch, useAttachToWorktask } = getBundleParams()
    const [userContext] = channel("GLOBAL")
        .last(1, "USER/CONTEXT_LOADED")
        .map((x) => x.content.context)

    return {
        processName: validateField(fields.processName).required(translateText(37)).messages,
        ...(fields.repairCoverage !== "Complete" &&
            fields.repairCoverage !== "More" &&
            fields.repairCoverage !== "OneStoneChip" &&
            fields.repairCoverage !== "TwoStoneChips" && {
                repairCoverage: [translateText(37)],
            }),
        mileage: validateField(fields.mileage).required(translateText(37)).messages,
        ...(isGermanOrAustrianVehicle && {
            keyNumber: validateField(fields.keyNumber).required(translateText(37)).messages,
        }),
        initialRegistration: validateField(fields.initialRegistration && fields.initialRegistration.toString()).min(8, translateText(289)).messages,
        plateNumber: validateField(fields.plateNumber).required(translateText(37)).messages,

        ...((!fields.vin ||
            fields.vin.length < 17 ||
            (userContext?.system.systemType === SystemType.Next &&
                useAttachToWorktask &&
                enableDATVinSearch &&
                (vehicle?.vin !== fields.vin || vehicle?.registrationTypeId !== RegistrationNoType.DatVin))) && {
            vin: ((fields.vin?.length ?? 0) < 17 && [translateText(289).replace("#1#", "17")]) || [translateText(1881)],
        }),

        titleType: validateField(fields.titleType).required(translateText(37)).messages,
        ...(fields.titleType !== TitleType.Company && {
            firstName: validateField(fields.firstName).required(translateText(37)).messages,
            lastName: validateField(fields.lastName).required(translateText(37)).messages,
        }),
        ...(fields.titleType === TitleType.Company && {
            companyName: validateField(fields.companyName).required(translateText(37)).messages,
        }),
        street: validateField(fields.street).required(translateText(37)).messages,
        streetNo: validateField(fields.streetNo).required(translateText(37)).messages,
        // "addressAddition": validateField(fields.addressAddition).required(translateText(37)).messages,
        zip: validateField(fields.zip).required(translateText(37)).messages,
        city: validateField(fields.city).required(translateText(37)).messages,
        country: validateField(fields.country).required(translateText(37)).messages,
    }
}
