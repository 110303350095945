import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, uniqueId, encodeUniqueId, concat, useHeighAdjustment, ModulePropsMultiWidget, useMultiWidgetState } from "@tm/utils"
import { Widget, Button, WidgetSizes, Icon, Loader } from "@tm/controls"
import { AdvertisementCategory } from "@tm/models"
import { useHistory, useParams } from "react-router"
import { Box } from "@tm/components"
import { ConfigQueryParams } from "../shared"
import { getBundleParams } from "../../utils"
import { OffersFrame } from "./components/OffersFrame"
import { OffersWidgetPagination } from "./components/OffersWidgetPagination"
import { OfferItems } from "./components/OfferItems"
import { openPartsList } from "./business/helpers"
import { OfferTabs } from "./components/OfferTabs"
import { useAdvertisementCategories } from "./business/useAdvertisementCategories"
import { useAdvertisementParts } from "./business/useAdvertisementParts"
import { useDisabledFunction } from "../../hooks/useDisabledFuntion"
import { useInvalidateWorkTaskBasketQueries } from "../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

type Props = {
    showNormalOffers: boolean
    showCarouselOffers?: boolean
    params: ConfigQueryParams
    className?: string
    widgetSize?: WidgetSizes
    height?: number
    headerButtonSize?: "s"
    hideShowAllButton?: boolean
    disabledFunction?: string
    alignHeightToWidgets?: string[]
    showComponentKey?: string
    storeId?: string
    variant?: "onlyContent" | "widget"
}

const GENERATED_WORKTASK_ID = uniqueId()
const ITEMS_PER_PAGE = 3
const MAX_NAVIGATION_PAGES = 10

export function WidgetComponent(props: Props) {
    const {
        showComponentKey,
        showNormalOffers,
        showCarouselOffers,
        className,
        widgetSize,
        height,
        params: configParams,
        headerButtonSize,
        hideShowAllButton,
        variant,
        storeId,
    } = props
    const { detailsUrl: detailsRoute, basketRoute } = getBundleParams()
    const { isDisabled } = useDisabledFunction({ disabledFunction: props.disabledFunction })
    const { translateText } = useLocalization()
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()
    const history = useHistory()
    const params = useParams<{ workTaskId?: string }>()
    const removeHeightAdjustment = useRef<() => void>()

    const [selectedMultiwidgtTab] = useMultiWidgetState({ storeId })

    useEffect(() => {
        return () => {
            removeHeightAdjustment.current?.()
        }
    }, [])

    const basketUrl = useMemo(
        () => (basketRoute ? renderRoute(basketRoute, { workTaskId: encodeUniqueId(GENERATED_WORKTASK_ID) }) : ""),
        [basketRoute]
    )
    const detailsUrl = useMemo(
        () => renderRoute(detailsRoute, { workTaskId: params.workTaskId ?? encodeUniqueId(GENERATED_WORKTASK_ID) }),
        [detailsRoute, params.workTaskId]
    )

    const handleRef = (el: HTMLDivElement | null) => {
        const { alignHeightToWidgets } = props
        if (el && alignHeightToWidgets) {
            removeHeightAdjustment.current = useHeighAdjustment().setHeightAdjustment(el, alignHeightToWidgets)
        }
    }

    const {
        data: advertisementCategories = [],
        isLoading: advertisementCategoriesLoading,
        isFetchedAfterMount: advertisementCategoriesInitialized,
    } = useAdvertisementCategories(showCarouselOffers)

    const normalOffersCategory: AdvertisementCategory = useMemo(
        () => ({
            id: "IFRAME-OFFERS",
            description: translateText(1276),
        }),
        [translateText]
    )

    const tabItems = useMemo(
        () => (showNormalOffers ? [normalOffersCategory, ...advertisementCategories] : advertisementCategories),
        [showNormalOffers, normalOffersCategory, advertisementCategories]
    )
    const [activeCategory, setActiveCategory] = useState(showNormalOffers ? normalOffersCategory : undefined)
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const normalOffersTabActive = activeCategory?.id === normalOffersCategory.id

    const {
        advertisementItems,
        parts,
        isLoading: advertisementItemsLoading,
        totalPages,
    } = useAdvertisementParts(activeCategory?.id !== normalOffersCategory.id ? activeCategory?.id : undefined, currentPageIndex, ITEMS_PER_PAGE)

    useEffect(() => {
        if (!showNormalOffers && advertisementCategoriesInitialized && advertisementCategories.length) {
            setActiveCategory(advertisementCategories[0])
        }
    }, [showNormalOffers, advertisementCategoriesInitialized, advertisementCategories])

    const handleChangeCategory = useCallback((category) => {
        setActiveCategory(category)
        setCurrentPageIndex(0)
    }, [])

    function requestPartList(showBasket?: boolean) {
        if (activeCategory) {
            openPartsList(invalidateAllWorkTaskBasketRequests, history, GENERATED_WORKTASK_ID, activeCategory, showBasket ? basketUrl : undefined)
        }
    }

    function renderWidgetHeader() {
        return (
            <Widget.Header key="widget__header">
                <Widget.Header.Title>
                    <Icon name="sales" size="s" className="widget__icon" />
                    <Widget.Header.Title.Text>{translateText(1276)}</Widget.Header.Title.Text>
                </Widget.Header.Title>
                <Widget.Header.Title.Options>
                    {!hideShowAllButton && (showNormalOffers || (showCarouselOffers && advertisementCategories.length)) && (
                        <Button
                            linkTo={normalOffersTabActive ? detailsUrl : undefined}
                            onClick={!normalOffersTabActive ? () => requestPartList() : undefined}
                            size={headerButtonSize}
                        >
                            {translateText(1275)}
                        </Button>
                    )}
                </Widget.Header.Title.Options>
            </Widget.Header>
        )
    }

    function renderWidgetFooter() {
        return (
            <Widget.Footer key="widget__footer">
                {!normalOffersTabActive && !!advertisementItems.length && (
                    <div className="widget-footer">
                        <OffersWidgetPagination
                            currentPageIndex={currentPageIndex}
                            onChangePageIndex={setCurrentPageIndex}
                            totalPages={totalPages}
                            disabled={advertisementItemsLoading}
                            maxPages={MAX_NAVIGATION_PAGES}
                        />
                    </div>
                )}
            </Widget.Footer>
        )
    }

    function renderOffersContent() {
        if (normalOffersTabActive) {
            return <OffersFrame params={configParams} />
        }

        return (
            <>
                {(advertisementCategoriesLoading || advertisementItemsLoading) && <Loader className="content-loader" />}
                {!advertisementCategoriesLoading && !advertisementItemsLoading && !advertisementItems.length && translateText(1010)}

                {!!activeCategory?.id && !!parts.length && (
                    <OfferItems
                        workTaskId={GENERATED_WORKTASK_ID}
                        parts={parts}
                        advertisementCategoryId={activeCategory.id}
                        onRequestPartList={requestPartList}
                    />
                )}
            </>
        )
    }

    function renderContent() {
        return (
            <Box flex={1} height={height || "20em"}>
                {showCarouselOffers && (
                    <OfferTabs
                        loading={advertisementCategoriesLoading}
                        advertisementCategories={tabItems}
                        activeCategory={activeCategory}
                        onChangeCategory={handleChangeCategory}
                    />
                )}

                {renderOffersContent()}
            </Box>
        )
    }

    if (showComponentKey && selectedMultiwidgtTab !== showComponentKey) {
        return null
    }

    if (variant === "onlyContent") {
        return renderContent()
    }

    return !isDisabled ? (
        <Widget
            size={widgetSize ?? "4x4"}
            active
            className={concat(" ", "tk-offers", "widget-frame", className)}
            title={translateText(1276)}
            iconName="sales"
            header={renderWidgetHeader()}
            footer={renderWidgetFooter()}
            height={height}
            forwardedRef={handleRef}
        >
            {renderContent()}
        </Widget>
    ) : null
}
