import * as React from "react"
import { Button, Checkbox, Collapsible, SearchField, Text } from "@tm/controls"
import { RimFilter } from "../../data/model"
import { SelectedFilters } from "../wheels-list/business"
import { FilterCriterias } from "../../data/enums"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { MainState } from "../main"

type Props = {
    selected: boolean
    onChange: (item: RimFilter) => void
    element: RimFilter
}

const AttributeCheckbox: React.FC<Props> = ({ selected, onChange, element }) => {

    const handleOnChange = () => {
        onChange(element)
    }

    const { translateText } = useLocalization()

    // TODO hardcoded EFilterCriterias
    const getTranslatedText = (id: number) => {
        switch (id) {
            case -101:
                return translateText(1136)
            case -102:
                return translateText(1558)
            case -103:
                return translateText(1568)
            case -106:
                return "ECE"
            case -111:
                return translateText(13364)
            default:
                return undefined
        }
    }
    return (
        <div className="filter-item selection-list__item selection-list__item--low-priority">
            <Checkbox
                size="s"
                checked={selected}
                onToggle={handleOnChange}
            />
            <div onClick={handleOnChange} className="selection-list__item__value-wrapper">
                <Text size="s" className="selection-list__item__value">
                    {getTranslatedText(element.attributeId)?.toUpperCase() ?? element.info.toUpperCase()}
                </Text>
            </div>
        </div>
    )
}

type SelectionCheckboxes = {
    text: string
    filters: RimFilter[]
    collapsibleText: string
    displaySearchButton?: boolean
    disabledCollapsible?: boolean
    selectedFilters: SelectedFilters
    initiallyClosed?: boolean
    onChange: (path: string, filter: string) => void
    onReset?: (e: React.MouseEvent<HTMLElement>, filter: string) => void
}

const selector = (s: MainState) => s.wheelsList.base.isAdditionalManufacturerSelected

export const AttributeSelectionCheckboxes: React.FC<SelectionCheckboxes> = ({ filters, collapsibleText, displaySearchButton, onChange, onReset, disabledCollapsible, selectedFilters, initiallyClosed }) => {
    const { translateText } = useLocalization()
    const [searchValue, setSearchValue] = React.useState("")
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const isAdditionalManufacturerSelected = useSelector(selector)
    const [showMore, setShowMore] = React.useState<boolean>(isAdditionalManufacturerSelected)

    const filteredManufacturers = filters.filter(x => !searchValue || x.info.toUpperCase().includes(searchValue.toUpperCase()))
    let topPriorityList: RimFilter[] = filteredManufacturers
    let notSoPriorityList: RimFilter[] = []

    if (filteredManufacturers.some(filter => filter.hasTradeReference)) {
        topPriorityList = filteredManufacturers.filter(x => x.hasTradeReference)
        notSoPriorityList = filteredManufacturers.filter(x => !x.hasTradeReference)
    }
    
    const handleShowMore = () => {
        setShowMore(prevState => !prevState)
    }

    const handleOnChange = (item: RimFilter) => {
        //nice workaround because of the data..
        if ([FilterCriterias.rimAttribute1, FilterCriterias.rimAttribute2, FilterCriterias.rimAttribute3, FilterCriterias.rimAttribute4, FilterCriterias.OE, FilterCriterias.VSA].includes(item.attributeId)) {
            if (selectedFilters[item.attributeId].includes(item.attributeValue))
                onChange(item.attributeId.toString(), "")
            else
                onChange(item.attributeId.toString(), item.attributeValue)
        } 
        else if (FilterCriterias.rimColor == item.attributeId && item.attributeKey) {
            onChange(item.attributeId.toString(), item.attributeKey)
        }
        else
            onChange(item.attributeId.toString(), item.attributeValue)
    }

    const handleSearchButton = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        setShowSearchInput(x => !x)
    }
    const handleOnResetClick = (e: any) => {
        onReset && onReset(e, filters.first()?.attributeId.toString() || "")
    }

    const renderHeader = () => {
        return (
            <>
                <Button layout={["ghost"]} icon="search" onClick={handleSearchButton} />
                <Button onClick={handleOnResetClick} layout={["ghost"]} icon="synchronize" />
            </>
        )
    }

    const renderItem = (item: RimFilter, idx: number) => {
        const selectedValue = item.attributeId == FilterCriterias.rimColor && item.attributeKey ? item.attributeKey : item.attributeValue
        const selected = selectedFilters[item.attributeId].includes(selectedValue)

        return (
            <AttributeCheckbox key={idx} element={item} selected={selected} onChange={handleOnChange} />
        )
    }

    return (
        <Collapsible name={collapsibleText} initiallyOpened={!initiallyClosed} renderHeaderAppendix={displaySearchButton && renderHeader || undefined} disabled={disabledCollapsible}>
            {showSearchInput && <SearchField className="manufacturers-search" showClear value={searchValue} onChange={setSearchValue} />}
            {topPriorityList.map(renderItem)}
            {notSoPriorityList.length > 0 &&
                <div className="selection-list__indent">
                    <Text className="is-clickable" size="xs" onClick={handleShowMore}>{translateText(showMore ? 169 : 168)}</Text>
                    {showMore && notSoPriorityList.map(renderItem)}
                </div>
            }
        </Collapsible>
    )
}

export default AttributeSelectionCheckboxes