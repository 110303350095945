import { CreateCostEstimationRequest, RegisteredModels, RegistrationNoType, channel } from "@tm/models"
import { useCallback, useMemo } from "react"
import { getVehicleRegistrationNoLabelTextIds, openStreamPdf, uniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { WorkTaskInfo } from "@tm/context-distribution"
import { atomFamily, useRecoilState } from "recoil"
import { Container } from "@tm/nexus"
import * as Data from ".."
import { PostCreateActionType, bundleChannel } from "../../business"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { mapCreateCostEstimationRequest } from "../mapper"
import { OfferState } from "../../components/cost-estimation/business"
import { sendCostEstimation } from "../repositories/dms/iqt"

function showCostEstimationPdf(costEstimationId: string, translateText: ReturnType<typeof useLocalization>["translateText"]) {
    Container.getInstance<Array<number>>(RegisteredModels.Vouchers_ShowCostEstimationPdf)
        .subscribe(costEstimationId)
        .load()
        .then((costEstimationPdf) => {
            if (costEstimationPdf) {
                openStreamPdf(costEstimationPdf, translateText)
            }
        })
}

type State = {
    loading: boolean
    error: any
}

const createCostEstimationVoucherAtom = atomFamily<State, WorkTaskInfo>({
    key: "basket_createCostEstimationVoucherAtom",
    default: { loading: false, error: {} },
})

export function useCreateCostEstimationVoucher(workTask: WorkTaskInfo, offerState?: OfferState) {
    const { translateText } = useLocalization()
    const { workTaskBasketCalculation, costEstimation } = useWorkTaskBasketState(workTask.id)

    const [state, setState] = useRecoilState(createCostEstimationVoucherAtom(workTask))

    const registrationNoLabel = useMemo(() => {
        const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(workTask.vehicle?.registrationTypeId || RegistrationNoType.Kba)
        return itemLabelTextIds?.label ? translateText(itemLabelTextIds.label) : undefined
    }, [workTask.vehicle])

    const request: Omit<CreateCostEstimationRequest, "id"> | undefined = useMemo(() => {
        if (!offerState) {
            return
        }
        return mapCreateCostEstimationRequest(
            workTask,
            offerState,
            costEstimation.state.repairTimeDivision,
            costEstimation.state.currencyCode,
            costEstimation.state.currencySymbol,
            registrationNoLabel,
            costEstimation.state.costEstimation,
            costEstimation.state.costEstimationParts,
            costEstimation.state.works,
            workTaskBasketCalculation?.calculatedCostEstimation?.totals
        )
    }, [offerState, workTask, registrationNoLabel, costEstimation, workTaskBasketCalculation?.calculatedCostEstimation])

    const createCostEstimationVoucher = useCallback(
        (postCreateAction?: PostCreateActionType) => {
            if (!request) {
                return
            }
            setState((prev) => ({ ...prev, loading: true }))
            const id = uniqueId()
            Data.createCostEstimation({ ...request, id })
                .then((result) => {
                    switch (postCreateAction) {
                        case PostCreateActionType.ShowPdf:
                            showCostEstimationPdf(id, translateText)
                            break
                        case PostCreateActionType.SendChatMessage:
                            channel("WORKTASK", workTask.id).publish("NOTIFICATIONS/SEND_COST_ESTIMATION_CHAT_MESSAGE", {
                                costEstimationId: id,
                            })
                            break
                        case PostCreateActionType.PublishLink:
                            sendCostEstimation({ voucherId: id, workTaskId: workTask.id })
                                .then((pdfUrl) => {
                                    if (pdfUrl) {
                                        bundleChannel().publish("COST_ESTIMATION_LINK_CREATED", { link: pdfUrl })
                                    } else {
                                        bundleChannel().publish("COST_ESTIMATION_LINK_CREATION_ERROR", {})
                                    }
                                })
                                .catch((error: Error) => bundleChannel().publish("COST_ESTIMATION_LINK_CREATION_ERROR", { error }))
                            break
                        default:
                            break
                    }
                })
                .catch((e) => setState((prev) => ({ ...prev, error: e })))
                .finally(() => setState((prev) => ({ ...prev, loading: false })))
        },
        [request, workTask.id]
    )

    return { createCostEstimationVoucher, creatingCostEstimationVoucher: state.loading, errorCreatingCostEstimationVoucher: state.error }
}
