import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { Grid, Icon, Typography, lighten, styled, useTheme } from "@tm/components"
import { useMemo } from "react"
import { OrderCalculation } from "../../../../data/model"
import { StyledBox } from "../../../StyledComponents"

type Props = {
    calculatedOrder: OrderCalculation
    priceType: PriceType
}

const LightContrastTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

const HighlightContrastTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.overwrites?.components?.basketSummary?.costEstimationTotals?.color ||
        theme.palette.getContrastText(
            theme.overwrites?.components?.basketSummary?.costEstimationTotals?.backgroundColor ||
                theme.overwrites?.components?.basketSummary?.component?.backgroundColor ||
                theme.palette.highlight.main
        ),
    opacity: 1,
}))

const LightColoredIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

export default function PartsTotalsTable({ calculatedOrder, priceType }: Props) {
    const { erpTotals, numberOfItems } = calculatedOrder
    const { translateText, currency, number } = useLocalization()

    const theme = useTheme()
    const basketSummaryTheme = theme.overwrites?.components?.basketSummary
    const highlightColor =
        basketSummaryTheme?.costEstimationTotals?.backgroundColor || basketSummaryTheme?.component?.backgroundColor || theme.palette.highlight.main
    const styledBoxBackgroundColor =
        theme.overwrites?.components?.basketSummary?.costEstimationDetails?.backgroundColor || lighten(theme.palette.common.black, 0.3)
    const styledBoxColor = theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3))
    const styledBoxPadding = theme.spacing(1.5, 2)

    const totalPrice = useMemo(() => {
        if (!erpTotals) {
            return
        }
        const totalPricesAllParts = Object.values(erpTotals)
        let result = totalPricesAllParts.find((price) => price?.type === priceType)
        if (!result && priceType === PriceType.Retail) {
            result = totalPricesAllParts.find((price) => price?.type === PriceType.RecommendedRetail)
        }
        return result
    }, [erpTotals, priceType])

    if (!totalPrice || !erpTotals) {
        return null
    }

    function renderTableHeadings() {
        return (
            <>
                <Grid item xs={5.5} />
                <Grid item xs={2} textAlign="right">
                    <LightContrastTypography variant="label">{translateText(89)}</LightContrastTypography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3.5} textAlign="right">
                    <LightContrastTypography variant="label">{translateText(694)}</LightContrastTypography>
                </Grid>
            </>
        )
    }

    return (
        <>
            <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding} color={styledBoxColor}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 1 }}>
                    {renderTableHeadings()}
                    <Grid item xs={1}>
                        <LightColoredIcon name="cart-summary" />
                    </Grid>
                    <Grid item xs={4.5}>
                        {translateText(90)}
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        {number(numberOfItems, 2) + (numberOfItems || "")}
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3.5} textAlign="right">
                        {currency(totalPrice?.value || 0, totalPrice?.currencyCode_Iso_4217)}
                    </Grid>
                </Grid>
            </StyledBox>

            <StyledBox sx={{ backgroundColor: highlightColor }}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                    {totalPrice?.value && (
                        <>
                            <Grid item xs={6}>
                                <HighlightContrastTypography>
                                    {translateText(62)}
                                    <HighlightContrastTypography variant="label">{` ${translateText(694)}`}</HighlightContrastTypography>
                                </HighlightContrastTypography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={5} textAlign="right">
                                <HighlightContrastTypography>
                                    {currency(totalPrice?.value, totalPrice?.currencyCode_Iso_4217 ?? "-")}
                                </HighlightContrastTypography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="right">
                        <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                    </Grid>
                </Grid>
            </StyledBox>
        </>
    )
}
