import { Box, Stack, Typography } from "@tm/components"

type variants = "body2" | "h2" | "h3"

type Props = {
    label: string
    labelAlignedRight?: boolean
    labelBold?: boolean
    subLabel?: string
    subLabelBeside?: boolean
    value: string
    valueBold?: boolean
    labelVariant?: variants
    valueVariant?: variants
}

export default function Total({
    label,
    subLabel,
    labelVariant,
    valueVariant,
    value,
    valueBold,
    labelBold,
    labelAlignedRight,
    subLabelBeside,
}: Props) {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box flex={1} textAlign={labelAlignedRight ? "right" : undefined} display="flex" flexDirection={subLabelBeside ? "row" : "column"}>
                <Typography variant={labelVariant} fontWeight={labelBold ? "bold" : undefined}>
                    {label}
                </Typography>
                {subLabel && <Typography variant="body2">{subLabel}</Typography>}
            </Box>
            <Typography flex="0 0 8rem" textAlign="right" fontWeight={valueBold ? "bold" : undefined} variant={valueVariant}>
                {value}
            </Typography>
        </Stack>
    )
}
