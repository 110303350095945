import { Box, Typography } from "@tm/components"

type Props = {
    isHeader?: boolean
    label: string
    labelAlignedRight?: boolean
    labelBold?: boolean
    subLabel?: string
    subLabelBeside?: boolean
    value: string
    valueBold?: boolean
}

export default function Total({ label, subLabel, isHeader, value, valueBold, labelBold, labelAlignedRight, subLabelBeside }: Props) {
    return (
        <Box pr={1} pb={1.5} display="flex" flex={1} padding="0.3em 0">
            <Box flex={1} textAlign={labelAlignedRight ? "right" : undefined} display="flex" flexDirection={subLabelBeside ? "row" : "column"}>
                <Typography variant={isHeader ? "h4" : undefined} fontWeight={labelBold ? "bold" : undefined}>
                    {label}
                </Typography>
                {subLabel && <Typography variant="body2">{subLabel}</Typography>}
            </Box>
            <Typography flex="0 0 8rem" textAlign="right" fontWeight={valueBold ? "bold" : undefined} variant={isHeader ? "h4" : undefined}>
                {value}
            </Typography>
        </Box>
    )
}
