import { useMutation } from "react-query"
import { Repositories } from "../data"
import { mainActions } from "../data/state"
import { DrivemotiveLoginRequest } from "../data/models"

type Props = {
    onLoginSuccess: (token: string) => void
}

export function useGetReturnToken({ onLoginSuccess }: Props) {
    return useMutation({
        mutationFn: (request: DrivemotiveLoginRequest) => Repositories.getDrivemotiveLoginResponse(request),
        onSuccess(data) {
            if (data?.token) {
                onLoginSuccess(data?.token)
            }
        },
        onError() {
            mainActions.setShowLoginDialog(true)
        },
    })
}
