import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { matchPath } from "react-router"
import { Badge, Icon, Popover } from "@tm/controls"
import { RouteComponentProps, registerOutsideClick, renderRoute, withRouter } from "@tm/utils"
import { Message, useLastMessages, useLastReadInfoDate, useOwnUserId, useSignalRMessage } from "../../../../data/hooks"
import { getBundleParams } from "../../../../utils"
import { PopoverContent } from "./popover-content"
import { getComponentStyles } from "./styles"

type Props = RouteComponentProps

function NotificationCenter(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const [showPopover, setShowPopover] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const ownUserId = useOwnUserId()
    const [hasNewMessage, setHasNewMessage] = useState(false)

    const { chats } = useLastMessages(1)
    const { lastReadInfoDate } = useLastReadInfoDate()

    const handler = useCallback(
        (message: Message) => {
            if (message.authorId !== ownUserId) {
                setHasNewMessage(true)
            }
        },
        [ownUserId]
    )

    useSignalRMessage(handler)

    useEffect(() => {
        if (wrapperRef.current && showPopover) {
            return registerOutsideClick(wrapperRef.current, () => setShowPopover(false))
        }
    }, [wrapperRef.current, setShowPopover, showPopover])

    const lastMessageDate = chats?.[0]?.lastMessage?.inserted
    const isNew = lastMessageDate && lastReadInfoDate && new Date(lastMessageDate) > lastReadInfoDate

    const isSelected = matchPath(props.location.pathname, { path: getBundleParams().chatDetailsRoute })

    return (
        <div className={classNames.main} ref={wrapperRef}>
            <div
                className={`global-navigation tab tab--worktask${isSelected ? " is-selected" : ""}`}
                onClick={() => {
                    setShowPopover(!showPopover)
                    setHasNewMessage(false)
                }}
                onDoubleClick={
                    isSelected
                        ? undefined
                        : () => {
                              props.history.push(renderRoute(getBundleParams().chatDetailsRoute, {}))
                          }
                }
            >
                <div className="tab__content">
                    <Icon name="chat" size="l" />
                </div>
                {(isNew || hasNewMessage) && <Badge skin="danger" value=" " className={classNames.newMessageBadge} />}
            </div>

            {showPopover && (
                <Popover active alignArrow={["top", "right"]} className={classNames.popover}>
                    <PopoverContent lastReadInfoDate={lastReadInfoDate} onClose={() => setShowPopover(false)} />
                </Popover>
            )}
        </div>
    )
}

export default withRouter(NotificationCenter)
