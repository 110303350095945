import { Alert, Box, Button, Snackbar, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useLocalStorage } from "@tm/utils"
import { useState } from "react"

export function DialogSettings() {
    const { translateText } = useLocalization()
    const { removeKey } = useLocalStorage("confirmationDialog")
    const [showToastmessage, setShowToastmessage] = useState(false)

    function resetDialogSettings() {
        setShowToastmessage(true)
        removeKey()
    }

    const onCloseToast = () => {
        setShowToastmessage(false)
    }

    return (
        <Box>
            <Stack spacing={1}>
                <Typography variant="body2">{translateText(13630)}</Typography>
                <Stack mt={0.5} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                    <Button onClick={resetDialogSettings}>{translateText(48)}</Button>
                </Stack>
            </Stack>

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                open={showToastmessage}
                autoHideDuration={3000}
                onClose={onCloseToast}
            >
                <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
                    {translateText(1825)}
                </Alert>
            </Snackbar>
        </Box>
    )
}
