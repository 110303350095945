import { OePart, RepairTimeProvider } from "@tm/models"
import { getBundleParams } from "../../../utils"
import { Part } from "../../../data/models"
import { mapCustomPart } from "../../../data/repositories/costEstimate-importRepairTimes/mapper"
import { CostEstimationRequest, ICustomPartsItem } from "../../../data/repositories/costEstimate-importRepairTimes/model"
import { GetCalculationDataRequest } from "../../../data/repositories/getCalculationData/model"
import { MainState } from "../../main"

export const createEurotaxGetCalculationDataRequest = (historyId: number, state: MainState, sendEreSession?: boolean): GetCalculationDataRequest => {
    const { vehicle } = state.manager
    return {
        contReturnUrl: getBundleParams().redirectURL,
        ...(sendEreSession && {
            ereSession: state.extern.externalSessionId,
        }),
        historyId,
        vehicleManufacturerId: vehicle?.tecDocManufacturerId,
        vehicleManufacturerName: vehicle?.manufacturer,
    }
}

export const createCostEstimationRequest = (
    state: MainState,
    repairTimeDivision: number,
    workTaskId?: string,
    memo?: string
): CostEstimationRequest | undefined => {
    if (!workTaskId) {
        return
    }

    const { items, works } = state.calculation

    const selectedItems = items?.filter((x) => x.isSelected)
    const selectedWorks = works?.filter((x) => x.isSelected)

    const parts: Part[] = []
    const oeArticles: OePart[] = []
    const customParts: ICustomPartsItem[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { warehouseId, ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber || !item.oeArticle.oePriceValue) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeArticles.push({ ...item.oeArticle, memo, oePriceValue: item.oeArticle.oePriceValue || undefined })
        }
    })

    const updatedWorks = selectedWorks?.map((x) => ({ ...x, ...(x.calculatedTime && { calculatedTime: x.calculatedTime / 10 }) })) ?? []

    return {
        workTaskId,
        customParts,
        vehicleId: state.manager.vehicle?.id ?? "",
        oeParts: oeArticles,
        works: updatedWorks,
        parts,
        repairTimesProvider: RepairTimeProvider.Eurotax,
        repairTimeDivision,
    }
}
