import { style } from "typestyle"
import { memo } from "react"
import { getComponentStyles } from "./styles"

interface IProps {
    label: string
}

export const NoResults = memo<IProps>(({ label }) => {
    const className = style(getComponentStyles())
    return (
        <div className={`${className}__container`}>
            <div>{label}</div>
        </div>
    )
})
