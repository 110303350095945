import { Tooltip, Icon } from "@tm/components"

type Props = {
    text: string
}

export default function AmountInfo({ text }: Props) {
    return (
        <Tooltip placement="top" variant="dark" withoutBordercolor title={text} noDelay>
            <Icon name="info" size="16px" />
        </Tooltip>
    )
}
