import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { classes, renderRoute, TmaHelper } from "@tm/utils"
import { em } from "csx"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { channel, ECalcOrigin } from "@tm/models"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../utils"
import { Actions } from "../main/business"
import { MainState } from "../main/business/model"
import { FastDtcRouteParams } from "../../models"
import ErrorSearch from "../_shared/ErrorSearch"

const Navigation: React.FC = () => {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const actions = bindActionCreators(Actions, dispatch)

    const history = useHistory()
    const params = useParams<FastDtcRouteParams>()

    const selectedData = useSelector((s: MainState) => s.selectedData)
    const productGroupIds = selectedData?.map((x) => x.genartnr)?.join(",")

    const workTask = useWorkTask()?.workTask
    const vehicle = workTask?.vehicle
    const vehicleTecDocTypeId = vehicle?.tecDocTypeId
    const vehicleManufacturerId = vehicle?.tecDocManufacturerId

    const { hidePartsListButton } = getBundleParams()

    const handleOpenFastCalculator = React.useCallback(() => {
        const route = renderRoute(getBundleParams().calculationModalRoute, {
            ...params,
            productGroupIds,
            origin: ECalcOrigin.FastDTC,
        })

        Morpheus.showView("1", route)
    }, [productGroupIds, params])

    const handleOpenArticleList = React.useCallback(() => {
        const route = `${getBundleParams().articleListModalRoute}${productGroupIds}`
        Morpheus.showView("1", renderRoute(route, { ...params }))
    }, [productGroupIds, params])

    const handleAddErrors = React.useCallback(
        (errorString: string) => {
            if (errorString.length >= 2 && vehicleTecDocTypeId && vehicleManufacturerId) {
                TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("dtcSearch", productGroupIds, workTask?.id, false)

                if (errorString.split(",").length > 0) {
                    actions.addNewSearch(
                        vehicleTecDocTypeId,
                        vehicleManufacturerId,
                        errorString.split(","),
                        history,
                        translateText(809),
                        translateText(12996)
                    )
                }
            } else if (!errorString) {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(812).replace("{0}", "2"),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        },
        [actions, history, translateText, vehicleTecDocTypeId, vehicleManufacturerId, productGroupIds, workTask?.id]
    )

    const hasFastCalculatorData = React.useMemo(() => {
        if (!vehicle?.id) {
            return false
        }

        return !!vehicle?.dataAvailabilities?.fastCalculator.hasAny
    }, [vehicle?.id, vehicle?.dataAvailabilities])

    return (
        <div className={style.nav}>
            <div className={classes("logo-fast-dtc", style.logo)} />
            <Toolbar title={translateText(135)} className={style.searchToolbar}>
                <ErrorSearch icon="plus" onSubmit={handleAddErrors} />
            </Toolbar>
            <div className={style.buttons}>
                <Button size="l" disabled={!selectedData.length || !hasFastCalculatorData} onClick={handleOpenFastCalculator} fakeButton>
                    {translateText(13000)}
                </Button>
                {!hidePartsListButton && (
                    <Button size="l" disabled={!selectedData.length} onClick={handleOpenArticleList} skin="highlight" fakeButton>
                        {translateText(152)}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default Navigation

// eslint-disable-next-line react-hooks/rules-of-hooks
const style = useStyle({
    searchToolbar: {
        margin: "0rem 1rem 0rem 1rem",
        width: em(32),
        flex: 1,
        $nest: {
            ".error-search": {
                width: em(32),
            },
        },
    },
    nav: {
        flex: 0,
        display: "flex",
    },
    title: {
        margin: "0rem 1rem 0rem 1rem",
    },
    buttons: {
        marginTop: em(0.75),
        justifySelf: "end",
    },
    logo: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        width: em(10),
        marginLeft: em(0.5),
        marginRight: em(0.5),
        marginTop: em(0.75),
        backgroundSize: "contain",
    },
})(Navigation)
